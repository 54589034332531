import {
  addIndex,
  filter,
  find,
  flatten,
  length,
  map,
  path,
  pathOr,
  reduce
} from 'ramda';
import {
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from 'react-dates';

import { types as sdkTypes } from '../../util/sdkLoader';
import { moment } from '../../util/dates';
import { getTimeAsObject } from '../../util/time';

const { UUID } = sdkTypes;
const mapIndexed = addIndex(map);

export const isOutsideRange = day => {
  const endOfRange = 360;
  return (
    !isInclusivelyAfterDay(day, moment()) ||
    !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
  );
}

export const formatAvailabilityException = ({ listingId, date, seats, startTime, endTime }) => {
  const startTimeAsObject = getTimeAsObject(startTime);
  const endTimeAsObject = getTimeAsObject(endTime);
  const listingIdMaybe = listingId ? { listingId: new UUID(listingId) } : {};
  const seatsMaybe = seats >= 0 ? { seats } : {};
  const start = moment(date).set('hour', startTimeAsObject.hours).toISOString();
  const end = endTimeAsObject.hours === 0 ? moment(date).add(1, 'days').startOf('day').toISOString() : moment(date).set('hour', endTimeAsObject.hours).toISOString();

  return {
    ...listingIdMaybe,
    ...seatsMaybe,
    start,
    end,
  }
};

export const getAvailabilityExceptionsOverlapped = (newAvailabilityException, availabilityExceptions) => {
  const { start, end } = newAvailabilityException;
  const range = moment.range(start, end);

  return reduce((acc, { attributes, id: { uuid } }) => {
    const { seats, start, end } = attributes;
    const rangeToCompare = moment.range(moment(start), moment(end));

    if (!range.overlaps(rangeToCompare)) return acc;
  
    const availabilityException = {
      id: uuid,
      rangeToCompare,
      seats,
    };

    return [...acc, availabilityException];
  }, [], availabilityExceptions);
};

export const getAvailabilityExceptionsToDelete = (params, availabilityExceptions) => flatten(map(date => {
    const availabilityException = formatAvailabilityException({ date, ...params });
    
    return getAvailabilityExceptionsOverlapped(availabilityException, availabilityExceptions);
  }, params.selectedDates));

export const isUnavailable = (date, availabilityExceptions) => find(exception => {
  if (!date) return;

  const { seats, start, end } = exception.attributes;
  const dateStartOfDay = moment(date).startOf('day');
  const dateEndOfDay = moment(date).add(1, 'days').startOf('day');

  return seats === 0 && dateStartOfDay.isSame(start) && dateEndOfDay.isSame(end);
}, availabilityExceptions);

export const getDayExceptions = (date, availabilityExceptions) =>
  filter(exception => {
    const startIsSameDay = moment(date).isSame(exception.attributes.start, 'day');
    const includeParams = startIsSameDay ? '[]' : '[)';

    return moment(date).isBetween(exception.attributes.start, exception.attributes.end, 'day', includeParams);
  }, availabilityExceptions);

export const getTimeException = (date, time, availabilityExceptions) => {
  const timeObj = getTimeAsObject(time);
  const dateWithTime = moment(date).set({ hour: timeObj.hours });

  return find(exception => moment(dateWithTime).isBetween(exception.attributes.start, exception.attributes.end, undefined, '[)'), availabilityExceptions);
};

export const dayIsAvailableInPlan = (date, availabilityPlan) => {
  const dayStr = moment(date).locale('en').format('ddd').toLowerCase();

  return find(entry => entry.dayOfWeek === dayStr && entry.seats > 0, pathOr([], ['entries'], availabilityPlan));
}

export const timeIsAvailableInPlan = (date, time, availabilityPlan) => {
  const dayStr = moment(date).locale('en').format('ddd').toLowerCase();
  const timeObj = getTimeAsObject(time);

  return find(entry => {
    if (entry.dayOfWeek !== dayStr || entry.seats === 0) return false;

    const startTimeObj = getTimeAsObject(entry.startTime);
    const endTimeObj = getTimeAsObject(entry.endTime);
    const endTimeHours = endTimeObj.hours === 0 ? 24 : endTimeObj.hours;

    return timeObj.hours >= startTimeObj.hours && timeObj.hours < endTimeHours;
  }, path(['entries'], availabilityPlan));
};

export const dayIsAvailableInExceptions = (dayExceptions) =>
  find(exception => exception.attributes.seats > 0, dayExceptions);

export const timeIsAvailableInExceptions = (date, time, dayExceptions) => {
  if (!length(dayExceptions)) return undefined;

  const timeException = getTimeException(date, time, dayExceptions);

  if (!timeException) return undefined;
  
  return timeException.attributes.seats > 0;
}

export const dayIsUnavailableInExceptions = (dayExceptions) =>
  find(exception => {
    const { seats, start, end } = exception.attributes;

    if (moment(start).hours() > 9 || moment(end).hours() !== 0) return false;
      
    return seats === 0;
  }, dayExceptions);

export const timeIsUnavailableInExceptions = (date, time, availabilityExceptions) => {
  const timeObj = getTimeAsObject(time);
  const dateWithTime = moment(date).set({ hour: timeObj.hours });
  const dayExceptions = getDayExceptions(dateWithTime, availabilityExceptions);

  if (!length(dayExceptions)) return undefined;

  const timeException = getTimeException(date, time, dayExceptions);

  if (!timeException) return undefined;
  
  return timeException.attributes.seats === 0;
}

export const filteredAvailabilityPlan = (dayString, availabilityPlan) =>
  filter(entry => entry.dayOfWeek === dayString, availabilityPlan.entries);


export const formatPlanInitialValues = (dayString, availabilityPlan) => {
  const dayEntries = filteredAvailabilityPlan(dayString, availabilityPlan);

  return reduce((acc, entry) => {
    const { endTime, seats, startTime } = entry;

    if (!seats) return acc;

    const startTimeObj = getTimeAsObject(startTime);
    const endTimeObj = getTimeAsObject(endTime);

    const newArray = new Array(endTimeObj.hours - startTimeObj.hours);

    return [...acc, ...mapIndexed((val, idx) => ({ seats: 1, time: `${startTimeObj.hours + idx}:00` }), newArray)];
  }, [], dayEntries)
}

export const getDayTransactions = (date, transactions) =>
  filter(transaction => moment(date).isSame(transaction.attributes.protectedData.date, 'day'), transactions);

export const timeIsBooked = (date, time, transactions) => {
  const timeObj = getTimeAsObject(time);
  const dateWithTime = moment(date).set({ hours: timeObj.hours });

  return find(transaction => dateWithTime.isBetween(transaction.attributes.protectedData.date, transaction.attributes.protectedData.endDate, 'hours', '[)'), transactions)
}
