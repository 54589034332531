import React from 'react';
import { string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { addIndex, map } from 'ramda';

import config from '../../../../../../config';
import {
  ExpandableBox,
  ExternalLink,
  IconArrow,
} from '../../../../../../components';

import css from './SectionFaq.module.css';

const mapIndexed = addIndex(map);

const SectionFaq = props => {
  const { className, intl } = props;
  const faqArray = [
    {
      question: intl.formatMessage({ id: 'SimulatorPage.question1' }),
      answer: intl.formatMessage({ id: 'SimulatorPage.answer1' }),
    },
    {
      question: intl.formatMessage({ id: 'SimulatorPage.question2' }),
      answer: intl.formatMessage({ id: 'SimulatorPage.answer2' })
    },
    {
      question: intl.formatMessage({ id: 'SimulatorPage.question3' }),
      answer: intl.formatMessage({ id: 'SimulatorPage.answer3' }),
    },
    {
      question: intl.formatMessage({ id: 'SimulatorPage.question4' }),
      answer: intl.formatMessage({ id: 'SimulatorPage.answer4' }),
    },
    {
      question: intl.formatMessage({ id: 'SimulatorPage.question5' }),
      answer: intl.formatMessage({ id: 'SimulatorPage.answer5' }),
    },
    {
      question: intl.formatMessage({ id: 'SimulatorPage.question6' }),
      answer: intl.formatMessage({ id: 'SimulatorPage.answer6' }),
    },
    {
      question: intl.formatMessage({ id: 'SimulatorPage.question7' }),
      answer: intl.formatMessage({ id: 'SimulatorPage.answer7' }),
    },
    {
      question: intl.formatMessage({ id: 'SimulatorPage.question8' }),
      answer: intl.formatMessage({ id: 'SimulatorPage.answer8' }),
    },
  ];
  const ctaText = intl.formatMessage({ id: 'SimulatorPage.faqCTA' });
  const classes = classNames(css.root, className);

  return (
    <section className={classes}>
      {mapIndexed((faq, index) => (
        <ExpandableBox key={index} className={css.faqExpandableBox}>
          <span>{faq.question}</span>
          <p>{faq.answer}</p>
        </ExpandableBox>
      ), faqArray)}
      <ExternalLink
        className={css.faqCTA}
        href={config.custom.helpCenterUrl}
        openInSameWindow
      >
        <span>{ctaText}</span>
        <IconArrow direction='right' />
      </ExternalLink>
    </section>
  )
}

SectionFaq.defaultProps = {
  className: null,
};

SectionFaq.propTypes = {
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(SectionFaq);