import merge from 'lodash/merge';

import { denormalisedResponseEntities } from '../util/data';
import { storableError } from '../util/errors';
import { fetchCurrentUser, currentUserShowSuccess } from '../ducks/user.duck';

// ================ Action types ================ //

export const UPLOAD_IMAGE_REQUEST = 'app/userSettings/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/userSettings/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/userSettings/UPLOAD_IMAGE_ERROR';

export const UPDATE_PROFILE_REQUEST = 'app/userSettings/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/userSettings/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/userSettings/UPDATE_PROFILE_ERROR';

export const CHANGE_EMAIL_REQUEST = 'app/userSettings/CHANGE_EMAIL_REQUEST';
export const CHANGE_EMAIL_SUCCESS = 'app/userSettings/CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_ERROR = 'app/userSettings/CHANGE_EMAIL_ERROR';

export const CHANGE_EMAIL_CLEAR = 'app/userSettings/CHANGE_EMAIL_CLEAR';

export const CLEAR_UPDATED_FORM = 'app/userSettings/CLEAR_UPDATED_FORM';

// ================ Reducer ================ //

const initialState = {
  changeEmailError: null,
  changeEmailInProgress: false,
  personalInfosChanged: false,
  image: null,
  uploadImageError: null,
  uploadInProgress: false,
  updateInProgress: false,
  updateProfileError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_IMAGE_REQUEST:
      // payload.params: { id: 'tempId', file }
      return {
        ...state,
        image: { ...payload.params },
        uploadInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS: {
      // payload: { id: 'tempId', uploadedImage }
      const { id, uploadedImage } = payload;
      const { file } = state.image || {};
      const image = { id, imageId: uploadedImage.id, file, uploadedImage };
      return { ...state, image, uploadInProgress: false };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, image: null, uploadInProgress: false, uploadImageError: payload.error };
    }

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateProfileError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        image: null,
        updateInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        image: null,
        updateInProgress: false,
        updateProfileError: payload,
      };

    case CHANGE_EMAIL_REQUEST:
      return {
        ...state,
        changeEmailInProgress: true,
        changeEmailError: null,
        personalInfosChanged: false,
      };
    case CHANGE_EMAIL_SUCCESS:
      return { ...state, changeEmailInProgress: false, personalInfosChanged: true };
    case CHANGE_EMAIL_ERROR:
      return { ...state, changeEmailInProgress: false, changeEmailError: payload };

    case CHANGE_EMAIL_CLEAR:
      return {
        ...state,
        changeEmailInProgress: false,
        changeEmailError: null,
        personalInfosChanged: false,
      };

    case CLEAR_UPDATED_FORM:
      return { ...state, updateProfileError: null, uploadImageError: null };

    default:
      return state;
  }
}

// ================ Action creators ================ //

// SDK method: images.upload
export const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = result => ({ type: UPLOAD_IMAGE_SUCCESS, payload: result.data });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR,
  payload: error,
  error: true,
});

// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = () => ({ type: UPDATE_PROFILE_REQUEST });
export const updateProfileSuccess = () => ({ type: UPDATE_PROFILE_SUCCESS });
export const updateProfileError = error => ({
  type: UPDATE_PROFILE_ERROR,
  payload: error,
  error: true,
});

// SDK method: sdk.currentUser.changeEmail
export const changeEmailRequest = () => ({ type: CHANGE_EMAIL_REQUEST });
export const changeEmailSuccess = () => ({ type: CHANGE_EMAIL_SUCCESS });
export const changeEmailError = error => ({
  type: CHANGE_EMAIL_ERROR,
  payload: error,
  error: true,
});

export const changeEmailClear = () => ({ type: CHANGE_EMAIL_CLEAR });

export const clearUpdatedForm = () => ({ type: CLEAR_UPDATED_FORM });

// ================ Thunks ================ //

// Images return imageId which we need to map with previously generated temporary id
export function uploadImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImageRequest(actionPayload));

    const bodyParams = {
      image: actionPayload.file,
    };
    const queryParams = {
      expand: true,
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.images
      .upload(bodyParams, queryParams)
      .then(resp => {
        const uploadedImage = resp.data.data;
        dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

const requestUpdateProfile = actionPayload => {
  return (dispatch, getState, sdk) => {
    dispatch(updateProfileRequest());

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.currentUser
      .updateProfile(actionPayload, queryParams)
      .then(response => {
        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }

        const currentUser = entities[0];

        return currentUser
      })
      .catch(e => dispatch(updateProfileError(storableError(e))));
  };
};

const requestChangeEmail = params => (dispatch, getState, sdk) => {
  const { email, currentPassword } = params;

  return sdk.currentUser
    .changeEmail(
      { email, currentPassword },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
      }

      const currentUser = entities[0];

      return currentUser;
    })
    .catch(e => {
      dispatch(changeEmailError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

const changeEmail = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestChangeEmail(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(changeEmailSuccess());
      })
      // error action dispatched in requestSaveEmail
      .catch(e => null)
  );
};

export const updateProfile = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestUpdateProfile(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(updateProfileSuccess());
      })
      .catch(e => null)
  );
};

const changeEmailAndUpdateProfile = params => (dispatch, getState, sdk) => {
  const { email, profileData, currentPassword } = params;

  const promises = [
    dispatch(requestChangeEmail({ email, currentPassword })),
    dispatch(updateProfile(profileData)),
  ];

  return Promise.all(promises)
    .then(values => {
      const saveEmailUser = values[0];
      const entities = denormalisedResponseEntities(values[1]);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
      const updatedUser = entities[0];
      const currentUser = merge(saveEmailUser, updatedUser);

      dispatch(currentUserShowSuccess(currentUser));
      dispatch(changeEmailSuccess());
    })
    .catch(e => null);
};

export const savePersonalInfos = params => (dispatch, getState, sdk) => {
  const {
    firstName,
    currentFirstName,
    lastName,
    currentLastName,
    birthdayDate,
    currentBirthdayDate,
    email,
    currentEmail,
    phoneNumber,
    currentPhoneNumber,
    currentPassword,
    language,
    currentLanguage,
    howDidYouKnowUs,
  } = params;
  const firstNameChanged = firstName !== currentFirstName;
  const lastNameChanged = lastName !== currentLastName;
  const birthdayDateChanged = birthdayDate !== currentBirthdayDate;
  const emailChanged = email !== currentEmail;
  const phoneNumberChanged = phoneNumber !== currentPhoneNumber;
  const languageChanged = language !== currentLanguage;
  const profileChanged = firstNameChanged || lastNameChanged || birthdayDateChanged || phoneNumberChanged || languageChanged || howDidYouKnowUs;
  const shouldUpdateProtectedData = birthdayDateChanged || howDidYouKnowUs || phoneNumberChanged;
  const updatedProtectedData = {
    ...birthdayDateChanged && { birthdayDate },
    ...phoneNumberChanged && { phoneNumber },
    ...howDidYouKnowUs && { howDidYouKnowUs },
  };
  const profileData = {
    ...firstNameChanged && { firstName },
    ...lastNameChanged && { lastName },
    ...shouldUpdateProtectedData && { protectedData: updatedProtectedData },
    ...languageChanged && {
      privateData: {
        language,
      },
    },
  };

  if (emailChanged && profileChanged) {
    return dispatch(changeEmailAndUpdateProfile({ email, currentPassword, profileData }));
  } else if (emailChanged) {
    return dispatch(changeEmail({ email, currentPassword }));
  } else if (profileChanged) {
    return dispatch(updateProfile(profileData));
  }
};

export const loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};
