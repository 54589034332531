import React from 'react';
import {FormattedMessage} from "react-intl";

import {ExternalLink, NamedLink} from '../../components';

import css from './SimulatorContentWill.css';

const SimulatorContentWill = props => {
  const {insuranceLogo} = props;

  return (
    <div className={css.sectionSimulatorContentWill}>
      <div className={css.containerSimulatorContentWill}>

        <h2>
          <FormattedMessage id="SimulatorPage.SimulatorContentWillTitle"/>

          <span className={css.SimulatorContentWillSpan}>
            <FormattedMessage id="SimulatorPage.SimulatorContentWillSpan"/>
          </span>
        </h2>

        <p className={css.SimulatorContentWillContentTop}>
          <FormattedMessage id="SimulatorPage.SimulatorContentWillContentTop"/>
        </p>

        <div className={css.SimulatorContentWillContentVisual}>
          <img src={insuranceLogo} alt='insurance'/>
        </div>

        <p className={css.SimulatorContentWillContentBottom}>
          <FormattedMessage id="SimulatorPage.SimulatorContentWillContentBottom"/>
        </p>

        <NamedLink
          className={css.SimulatorContentWillLink}
          name='InsurancePage'
        >
          <FormattedMessage id="SimulatorPage.SimulatorContentWillLink"/>
        </NamedLink>
      </div>
    </div>
  );
}

export default SimulatorContentWill;
