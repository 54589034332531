import React from 'react';
import { FormattedMessage } from 'react-intl';
import dropWhile from 'lodash/dropWhile';

import * as log from '../../util/log';
import { 
  TRANSITION_ACCEPT,
  TRANSITION_CANCEL_AFTER_DELIVERED,
  TRANSITION_CANCEL_BEFORE_DELIVERED,
  TRANSITION_CANCEL_BY_CUSTOMER,
  TRANSITION_COMPLETE,
  TRANSITION_DECLINE,
  TRANSITION_ENQUIRE,
  TRANSITION_EXPIRE,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_INSTANT_ACCEPT,
  TRANSITION_REFUND_BY_PROVIDER,
  TRANSITION_REFUND_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_CUSTOMER_AFTER_PAYOUT,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_PROVIDER_AFTER_PAYOUT,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER_AFTER_PAYOUT,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER_AFTER_PAYOUT,
  txIsDelivered,
  txIsInFirstReviewBy,
  txIsPaidOutAfterDelivered,
  txIsReviewed,
  txRoleIsCustomer,
} from '../../util/transactions';
import { InlineTextButton } from '../../components';

import css from './ActivityFeed.module.css';

export const isMessage = item => item && item.type === 'message';

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item => (isMessage(item) ? item.attributes.createdAt : item.createdAt);
  
  return itemDate(a) - itemDate(b);
};

export const organizedItems = (messages, transitions, hideOldTransitions) => {
  const items = messages.concat(transitions).sort(compareItems);
  if (hideOldTransitions) {
    // Hide transitions that happened before the oldest message. Since
    // we have older items (messages) that we are not showing, seeing
    // old transitions would be confusing.
    return dropWhile(items, i => !isMessage(i));
  } else {
    return items;
  }
};

export const resolveTransitionMessage = (
  transaction,
  transition,
  listingTitle,
  ownRole,
  otherUsersName,
  intl,
  onOpenReviewModal
) => {
  const isOwnTransition = transition.by === ownRole;
  const currentTransition = transition.transition;
  const displayName = otherUsersName;
  const isCustomer = txRoleIsCustomer(ownRole);

  switch (currentTransition) {
    case TRANSITION_CONFIRM_PAYMENT:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionRequest" values={{ listingTitle }} />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionRequest"
          values={{ displayName, listingTitle }}
        />
      );
    case TRANSITION_ACCEPT:
    case TRANSITION_INSTANT_ACCEPT:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionAccept" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionAccept" values={{ displayName }} />
      );
    case TRANSITION_DECLINE:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionDecline" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionDecline" values={{ displayName }} />
      );
    case TRANSITION_ENQUIRE:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionEnquire" values={{ listingTitle }} />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionEnquire"
          values={{ displayName, listingTitle }}
        />
      );
    case TRANSITION_EXPIRE:
      return !isCustomer ? (
        <FormattedMessage id="ActivityFeed.ownTransitionExpire" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionExpire" values={{ displayName }} />
      );
    case TRANSITION_CANCEL_BEFORE_DELIVERED:
    case TRANSITION_CANCEL_AFTER_DELIVERED:
      return <FormattedMessage id="ActivityFeed.transitionCancel" />;
    case TRANSITION_REFUND_BY_PROVIDER:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionRefundForProvider" values={{ displayName }} />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionRefundForCustomer" values={{ displayName }} />
      );
    case TRANSITION_CANCEL_BY_CUSTOMER:
    case TRANSITION_REFUND_BY_CUSTOMER: {
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionRefundForCustomer" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionRefundForProvider" values={{ displayName }} />
      );
    }
    case TRANSITION_COMPLETE:
      // Show the leave a review link if the state is delivered and if the current user is the first to leave a review
      const reviewPeriodJustStarted = txIsDelivered(transaction) || txIsPaidOutAfterDelivered(transaction);

      const reviewAsFirstLink = reviewPeriodJustStarted ? (
        <InlineTextButton className={css.reviewButton} onClick={onOpenReviewModal}>
          <FormattedMessage id="ActivityFeed.leaveAReview" values={{ displayName }} />
        </InlineTextButton>
      ) : null;

      return (
        <FormattedMessage
          id="ActivityFeed.transitionComplete"
          values={{ reviewLink: reviewAsFirstLink }}
        />
      );

    case TRANSITION_REVIEW_1_BY_PROVIDER:
    case TRANSITION_REVIEW_1_BY_PROVIDER_AFTER_PAYOUT:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
    case TRANSITION_REVIEW_1_BY_CUSTOMER_AFTER_PAYOUT:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />;
      } else {
        // show the leave a review link if current user is not the first
        // one to leave a review
        const reviewPeriodIsOver = txIsReviewed(transaction);
        const userHasLeftAReview = txIsInFirstReviewBy(transaction, isCustomer);
        const reviewAsSecondLink = !(reviewPeriodIsOver || userHasLeftAReview) ? (
          <InlineTextButton className={css.reviewButton} onClick={onOpenReviewModal}>
            <FormattedMessage id="ActivityFeed.leaveAReviewSecond" values={{ displayName }} />
          </InlineTextButton>
        ) : null;
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: reviewAsSecondLink }}
          />
        );
      }
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_REVIEW_2_BY_PROVIDER_AFTER_PAYOUT:
    case TRANSITION_REVIEW_2_BY_CUSTOMER:
    case TRANSITION_REVIEW_2_BY_CUSTOMER_AFTER_PAYOUT:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />;
      } else {
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: null }}
          />
        );
      }

    default:
      log.error(new Error('Unknown transaction transition type'), 'unknown-transition-type', {
        transitionType: currentTransition,
      });
      return '';
  }
};

export const reviewByAuthorId = (transaction, userId) => {
  return transaction.reviews.filter(
    r => !r.attributes.deleted && r.author.id.uuid === userId.uuid
  )[0];
};
