import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconCalendarViewWeek.module.css';

const IconCalendarViewWeek = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M4 20q-.825 0-1.412-.587Q2 18.825 2 18V6q0-.825.588-1.412Q3.175 4 4 4h16q.825 0 1.413.588Q22 5.175 22 6v12q0 .825-.587 1.413Q20.825 20 20 20Zm9-2h2.5V6H13v12Zm-4.5 0H11V6H8.5v12ZM4 18h2.5V6H4v12Zm13.5 0H20V6h-2.5v12Z"/>
    </svg>
  );
};

IconCalendarViewWeek.defaultProps = {
  rootClassName: null,
  className: null,
};

IconCalendarViewWeek.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCalendarViewWeek;
