import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { isBooleanString, stringToBoolean } from '../../../util/data';
import { IconCheckmark, IconClose } from '../../../components';

import css from './ButtonBoolean.module.css';

const ButtonBoolean = props => {
  const {
    rootClassName,
    className,
    value,
    onClickTrue,
    onClickFalse,
    revertOrder,
  } = props;
  const [booleanValue, setBooleanValue] = useState();
  useEffect(() => {
    if (isBooleanString(value)) {
      const valueAsBoolean = stringToBoolean(value);
      setBooleanValue(valueAsBoolean);
    } else {
      setBooleanValue(value)
    };
  }, [value])
  const isFalse = booleanValue === false;
  const classes = classNames(rootClassName || css.root, className, revertOrder && css.revertOrder);
  const buttonTrueClasses = classNames(css.button, {
    [css.buttonTrue]: booleanValue,
  });
  const buttonFalseClasses = classNames(css.button, {
    [css.buttonFalse]: isFalse,
  });

  return (
    <div className={classes}>
      <button className={buttonFalseClasses} type="button" onClick={onClickFalse}>
        <IconClose />
      </button>
      <button className={buttonTrueClasses} type="button" onClick={onClickTrue}>
        <IconCheckmark size='small' />
      </button>
    </div>
  )
};

export default ButtonBoolean;
