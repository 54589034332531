import React from 'react';
import { string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { path } from 'ramda';

import { propTypes } from '../../util/types';
import { formatDateToText } from '../../util/dates';
import { DataWithLabel } from '../../components';

import css from './BookingTimeBreakdown.module.css';

const BookingTimeBreakdown = (props) => {
  const {
    className,
    intl,
    transaction,
  } = props;
  const bookingStart = path(['booking', 'attributes', 'start'], transaction);
  const bookingEnd = path(['booking', 'attributes', 'end'], transaction);

  if (!bookingStart || !bookingEnd) return null;

  const bookingStartAsText = formatDateToText(intl, bookingStart, 'UTC');
  const bookingEndAsText = formatDateToText(intl, bookingEnd, 'UTC');
  const dateLabelText = intl.formatMessage({ id: 'BookingTimeBreakdown.dateLabel' });
  const startTimeLabelText = intl.formatMessage({ id: 'BookingTimeBreakdown.startTimeLabel' });
  const endTimeLabelText = intl.formatMessage({ id: 'BookingTimeBreakdown.endTimeLabel' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <DataWithLabel className={css.date} label={dateLabelText} value={bookingStartAsText.dateLong} />
      <div className={css.timeWrapper}>
        <DataWithLabel className={css.time} label={startTimeLabelText} value={bookingStartAsText.time} />
        <DataWithLabel className={css.time} label={endTimeLabelText} value={bookingEndAsText.time} />
      </div>
    </div>
  )
}

BookingTimeBreakdown.defaultProps = {
  className: null,
};

BookingTimeBreakdown.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  transaction: propTypes.transaction.isRequired,
};

export default injectIntl(BookingTimeBreakdown);