import React from 'react';
import { bool, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { pathOr } from 'ramda';
import ReactTooltip from 'react-tooltip';

import { propTypes } from '../../util/types';
import { IconAward, IconCancel, IconCheck } from '../../components/Icons';

import { getIdentityData } from './VerifiedBadges.Helpers';
import css from './VerifiedBadges.module.css';

const VerifiedBadges = (props) => {
  const {
    className,
    intl,
    user,
    withEmail,
    withSuperhost,
    withTooltip,
  } = props;
  const displayName = pathOr('', ['attributes', 'profile', 'displayName'], user);
  const publicData = pathOr({}, ['attributes', 'profile', 'publicData'], user);
  const isSuperHost = publicData.isSuperHost;
  const emailVerified = publicData.emailVerified;
  const identityStatus = publicData.identityVerified;
  const identityVerified = identityStatus === 'success';
  const identityData = getIdentityData(identityStatus, intl);
  const withIdentityTooltip = withTooltip && identityVerified;
  const superhostText = intl.formatMessage({ id: 'Marketplace.superhost' });
  const identityText = intl.formatMessage({ id: 'VerifiedBadges.identity' });
  const identityTooltipText = intl.formatMessage({ id: 'VerifiedBadges.identityTooltip' }, { username: displayName });
  const emailText = intl.formatMessage({ id: 'VerifiedBadges.email' });
  const emailUnverifiedText = intl.formatMessage({ id: 'VerifiedBadges.emailUnverified' });
  const classes = classNames(css.root, className);
  const identityLabelClasses = classNames(css.identityLabel, {
    [css.labelUnderlined]: withIdentityTooltip,
  });

  return (
    <div className={classes}>
      {withSuperhost && isSuperHost && (
        <div className={css.wrapper}>
          <IconAward className={css.iconAward} />
          <div className={css.textWrapper}>
            <span className={css.superhostLabel}>{superhostText}</span>
          </div>
        </div>
      )}
      <div className={css.wrapper}>
        {identityData.icon}
        <div
          className={css.textWrapper}
          data-tip={identityTooltipText}
          data-event="click focus"
          data-place="right"
          data-background-color="#FFFFFF"
          data-border={true}
          data-border-color="#B2B2B2"
          data-offset="{'left': -4}"
        >
          <span className={identityLabelClasses}>{identityText}</span>
          {identityData.sublabel}
        </div>
        {withIdentityTooltip && (
          <ReactTooltip
            className={css.tooltip}
            effect="solid"
            type="light"
            globalEventOff="click"
            multiline
          />
        )}
      </div>
      {withEmail && (
        <div className={css.wrapper}>
          {emailVerified ? (
            <IconCheck className={css.iconCheck} />
          ) : (
            <IconCancel className={css.iconCancel} />
          )}
          <div className={css.textWrapper}>
            <span className={css.emailLabel}>{emailText}</span>
            {!emailVerified && <span className={css.sublabel}>{emailUnverifiedText}</span>}
          </div>
        </div>
      )}
    </div>
  )
}

VerifiedBadges.defaultProps = {
  className: null,
  user: null,
  withEmail: false,
  withSuperhost: false,
};

VerifiedBadges.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  user: propTypes.user,
  withEmail: bool,
  withSuperhost: bool,
};

export default injectIntl(VerifiedBadges);