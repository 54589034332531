import React, { Fragment, useEffect, useRef, useState } from 'react';
import { bool, func, string } from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { path } from 'ramda';
import { CSSTransition } from 'react-transition-group';

import routeConfiguration from '../../routeConfiguration';
import { useFocus } from '../../hooks';
import { createResourceLocatorString } from '../../util/routes';
import { parse } from '../../util/urlHelpers';
import {
  ADULTS,
  DATE,
  END_TIME,
  LOCATION,
  START_TIME,
  getInitialSearchValues,
  getSearchParams,
} from '../../util/search';
import { Form } from '../../components';

import { blurAllFields } from './SearchDesktop.helpers';
import {
  DateInput,
  LocationInput,
  ParticipantsInput,
  SearchButton,
  SearchButtonWithValues,
  SubmitButton,
  TimeSlotInput,
} from './components';
import css from './SearchDesktop.module.css';

const searchButtonAnimationClasses = {
  enter: css.buttonEnter,
  enterActive: css.buttonEnterActive,
  enterDone: css.buttonEnterDone,
  exit: css.buttonExit,
  exitActive: css.buttonExitActive,
  exitDone: css.buttonExitDone,
};

const searchFormAnimationClasses = {
  enter: css.formEnter,
  enterActive: css.formEnterActive,
  enterDone: css.formEnterDone,
  exit: css.formExit,
  exitActive: css.formExitActive,
  exitDone: css.formExitDone,
};

const SearchDesktop = (props) => {
  const {
    currentPage,
    handleHideSearch,
    handleShowSearch,
    showSearch,
    showSearchForm,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const finalFormRef = useRef();
  const formRef = useRef();
  const [locationInputRef, setLocationInputFocus] = useFocus();
  const isSearchPage = currentPage === 'SearchPage';
  const [canShowSearchForm, setCanShowSearchForm] = useState(!isSearchPage);
  useEffect(() => {
    if (showSearch || !finalFormRef.current) return;
    blurAllFields(finalFormRef.current.form);
  }, [showSearch]);
  const handleClickOutside = (e) => {
    if (!formRef.current) return;
    if (formRef.current.contains(e.target)) return;

    blurAllFields(finalFormRef.current.form);
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [formRef]);
  const handleSearchButtonClick = (e) => {
    const currentTargetId = e.currentTarget.id;
    const finalFormApi = path(['current', 'form'], finalFormRef);
    if (!finalFormApi) return;
    handleShowSearch();

    switch (currentTargetId) {
      case 'dateButton':
        return finalFormApi.focus(DATE);
      case 'timeSlotButton':
        return finalFormApi.focus(START_TIME);
      case 'participantsButton':
        return finalFormApi.focus(ADULTS);
      default:
        return setTimeout(() => {
          setLocationInputFocus()
        }, 50);
    }
  };
  const parsedSearch = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const handleSubmit = (values) => {
    const hasValidLocation = path(['location', 'selectedPlace'], values)
    if (finalFormRef.current && !hasValidLocation) return setLocationInputFocus();

    handleHideSearch();
    setCanShowSearchForm(false);
    const searchParams = getSearchParams(parsedSearch, values);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };
  const initialValues = getInitialSearchValues(parsedSearch);

  return (
    <Fragment>
      <CSSTransition
        in={!showSearchForm}
        timeout={showSearchForm ? 200 : 0}
        classNames={searchButtonAnimationClasses}
        mountOnEnter
      >
        {isSearchPage ? (
          <SearchButtonWithValues
            className={css.searchButton}
            onClick={handleSearchButtonClick}
            searchParams={parsedSearch}
          />
        ) : (
          <SearchButton className={css.searchButton} onClick={handleSearchButtonClick} />
        )}
      </CSSTransition>
      <CSSTransition
        in={showSearchForm}
        timeout={showSearchForm ? 0 : 200}
        classNames={searchFormAnimationClasses}
        {...!canShowSearchForm && { onEnter: () => setCanShowSearchForm(true)}}
      >
        <FinalForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          ref={finalFormRef}
          render={(formRenderProps) => {
            const { active, dirty, form, handleSubmit, values } = formRenderProps;
            const handleInputClick = (fieldName) => {
              if (active === fieldName) return form.blur(fieldName);
              blurAllFields(form, fieldName);
              form.focus(fieldName);
            };
            const formClasses = classNames(css.form, {
              [css.formActive]: active || showSearch,
              [css.formWithoutShadow]: showSearch,
              [css.formHidden]: !canShowSearchForm,
            });
            const getInputClasses = (fieldName) => {
              const isActive = active === fieldName;
              return isActive ? css.inputActive : '';
            };
            const rightContainerClasses = classNames(css.rightContainer, {
              [css.rightContainerActive]: values.location,
            }, getInputClasses(ADULTS));

            return (
              <Form
                className={formClasses}
                onSubmit={handleSubmit}
                contentRef={node => formRef.current = node}
              >
                <LocationInput
                  className={getInputClasses(LOCATION)}
                  dirty={dirty}
                  finalFormApi={form}
                  formValues={values}
                  inputRef={locationInputRef}
                />
                <DateInput
                  className={getInputClasses(DATE)}
                  onClick={() => handleInputClick(DATE)}
                  activeField={active}
                  finalFormApi={form}
                  formValues={values}
                />
                <TimeSlotInput
                  className={classNames(getInputClasses(START_TIME), getInputClasses(END_TIME))}
                  onClick={() => handleInputClick(START_TIME)}
                  activeField={active}
                  finalFormApi={form}
                  formValues={values}
                />
                <div className={rightContainerClasses}>
                  <ParticipantsInput
                    onClick={() => handleInputClick(ADULTS)}
                    activeField={active}
                    finalFormApi={form}
                    formValues={values}
                  />
                  <SubmitButton formValues={values} />
                </div>
              </Form>
            )
          }}
        />
      </CSSTransition>
    </Fragment>
  )
}

SearchDesktop.defaultProps = {};

SearchDesktop.propTypes = {
  currentPage: string.isRequired,
  handleHideSearch: func.isRequired,
  handleShowSearch: func.isRequired,
  showSearch: bool.isRequired,
  showSearchForm: bool.isRequired,
};

export default SearchDesktop;