import React, { Fragment, useState } from 'react';
import { func, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';

import {
  IconCalendarViewWeek,
  IconEditCalendar,
  IconTipsAndUpdates
} from '../../../../components/Icons';
import {
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalSwimmy
} from '../../../../components/ModalSwimmy';
import { PrimaryButton, SecondaryButton } from '../../../../components';

import css from './Tutorial.module.css';

const Tutorial = (props) => {
  const {
    className,
    intl,
    onManageDisableScrolling,
  } = props;
  const [isOpen, setIsOpen] = useState(!window.localStorage.getItem('calendarTutorialSeen'));
  const handleSubmitClick = (e) => {
    window.localStorage.setItem('calendarTutorialSeen', 'true');
    setIsOpen(false);
  }
  const openButtonText = intl.formatMessage({ id: 'CalendarPage.Tutorial.openButton' });
  const headingText = intl.formatMessage({ id: 'CalendarPage.Tutorial.heading' });
  const planningHeadingText = intl.formatMessage({ id: 'CalendarPage.Tutorial.planningHeading' });
  const planningInfoText = intl.formatMessage({ id: 'CalendarPage.Tutorial.planningInfo' });
  const calendarHeadingText = intl.formatMessage({ id: 'CalendarPage.Tutorial.calendarHeading' });
  const calendarInfoText = intl.formatMessage({ id: 'CalendarPage.Tutorial.calendarInfo' });
  const tipsHeadingText = intl.formatMessage({ id: 'CalendarPage.Tutorial.tipsHeading' });
  const tipsInfoText = intl.formatMessage({ id: 'CalendarPage.Tutorial.tipsInfo' });
  const submitText = intl.formatMessage({ id: 'CalendarPage.Tutorial.submit' });
  const classes = classNames(css.root, className);

  return (
    <Fragment>
      <SecondaryButton className={classes} onClick={() => setIsOpen(true)}>
        <span>{openButtonText}</span>
      </SecondaryButton>
      <ModalSwimmy
        id="tutorial"
        animationType="fade"
        handleClickOutside={false}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ModalOverlay />
        <ModalContent
          className={css.modalContent}
          animationType="slideBottom"
          show={isOpen}
        >
          <ModalHeader className={css.modalHeader}>
            <span className={css.heading}>{headingText}</span>
          </ModalHeader>
          <ModalBody className={css.modalBody}>
            <section className={css.section}>
              <div className={css.headingWrapper}>
                <IconCalendarViewWeek className={css.icon} />
                <span className={css.sectionHeading}>{planningHeadingText}</span>
              </div>
              <span className={css.info}>{planningInfoText}</span>
            </section>
            <section className={css.section}>
              <div className={css.headingWrapper}>
                <IconEditCalendar className={css.icon} />
                <span className={css.sectionHeading}>{calendarHeadingText}</span>
              </div>
              <span className={css.info}>{calendarInfoText}</span>
            </section>
            <section className={css.section}>
              <div className={css.headingWrapper}>
                <IconTipsAndUpdates className={css.icon} />
                <span className={css.sectionHeading}>{tipsHeadingText}</span>
              </div>
              <span className={css.info}>{tipsInfoText}</span>
            </section>
          </ModalBody>
          <ModalFooter className={css.modalFooter}>
            <PrimaryButton
              className={css.submitButton}
              onClick={handleSubmitClick}
            >
              {submitText}
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </ModalSwimmy>
    </Fragment>
  )
}

Tutorial.defaultProps = {
  className: null,
};

Tutorial.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onManageDisableScrolling: func.isRequired,
};

export default Tutorial;
