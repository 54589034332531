import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { number, shape, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { withViewport } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '../../containers';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  AccountView,
} from '../../components';

import PasswordChangeTab from './components/PasswordChangeTab/PasswordChangeTab';
import css from './AccountSecurityPage.module.css';

const MOBILE_MAX_WIDTH = 767;

const AccountSecurityPage = (props) => {
  const {
    currentTab,
    currentUser,
    scrollingDisabled,
    viewport,
    intl,
  } = props;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const tabs = {
    'PasswordChange': {
      tabName: 'PasswordChange',
      routeName: 'AccountPasswordChangePage',
      title: intl.formatMessage({ id: 'AccountSecurityPage.passwordChangeTabTitle' }),
      component: <PasswordChangeTab currentUser={ensuredCurrentUser} />,
      tipTitle: intl.formatMessage({ id: 'AccountSecurityPage.passwordChangeTabTipTitle' }),
      tip: intl.formatMessage({ id: 'AccountSecurityPage.passwordChangeTabTip' }),
    },
  };
  const pageTitle = intl.formatMessage({ id: 'AccountSecurityPage.pageTitle' });
  const viewTitle = intl.formatMessage({ id: 'AccountSecurityPage.viewTitle' });
  const isMobile = viewport.width <= MOBILE_MAX_WIDTH;
  
  return (
    <Page title={pageTitle} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="AccountSecurityPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <AccountView
            title={viewTitle}
            tabs={tabs}
            currentTab={currentTab}
            intl={intl}
          />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          {!isMobile && (
            <Footer />
          )}
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  )
};

AccountSecurityPage.defaultProps = {
};

AccountSecurityPage.propTypes = {
  currentTab: string.isRequired,
  currentUser: propTypes.currentUser,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

export default compose(connect(mapStateToProps), withViewport, injectIntl)(AccountSecurityPage);
