import React, { useState } from 'react';
import classNames from 'classnames';

import { Button, IconArrow } from '../../components';

import css from './ExpandableBox.css';

const ExpandableBox = props => {
  const {
    rootClassName,
    className,
    children,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  if (children.length < 2 || children.length > 2) return null;
  
  const handleOnClick = () => {
    setIsExpanded(prev => !prev);
  };

  const iconArrowDirection = isExpanded ? 'up' : 'down';

  const buttonClasses = classNames(css.button, { [css.buttonExpanded]: isExpanded });
  const arrowClasses = classNames(css.iconArrow, { [css.iconArrowExpanded]: isExpanded });
  const contentClasses = classNames(css.content, { [css.contentExpanded]: isExpanded });
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <Button
        rootClassName={buttonClasses}
        onClick={handleOnClick}
      >
        {children[0]}
        <IconArrow className={arrowClasses} direction={iconArrowDirection} />
      </Button>
      <div className={contentClasses}>
        {children[1]}
      </div>
    </div>
  )
}

export default ExpandableBox;