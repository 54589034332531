import axios from 'axios';

import config from '../../config';
import { newMessageAction } from './constants';

export const sendEventByAction = async (transactionId, eventType) => {
  if (!config.eventServiceUri) return ;

  const url = `${config.eventServiceUri}/api/events`;
  try {
    return await axios({
      method: 'post',
      url,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      data: `transactionId=${transactionId}&eventType=${eventType}`
    });
  } catch (e) {
    console.error(e);
  }
};

export const sendNewMessageEvent = async (transactionId, messageSender, messageContent) => {
  if (!config.eventServiceUri) return ;

  const url = `${config.eventServiceUri}/api/events`;
  try {
    return await axios({
      method: 'post',
      url,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      data: `transactionId=${transactionId}&eventType=${newMessageAction}&messageSender=${messageSender}&messageContent=${messageContent}`
    });
  } catch (e) {
    console.error(e);
  }
};

export const sendOpenedEvent = async (eventType, transactionId) => {
  if (!config.eventServiceUri) return ;

  const url = `${config.eventServiceUri}/api/events`;
  try {
    return await axios({
      method: 'post',
      url,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      data: `transactionId=${transactionId}&eventType=${eventType}`
    });
  } catch (e) {
    console.error(e);
  }
};

