import React from 'react';
import { string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';

import config from '../../../../config';
import { formatMoney } from '../../../../util/currency';
import { propTypes } from '../../../../util/types';
import { DataWithLabel } from '../../../../components';

import css from './ListingInformations.module.css';

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const ListingInformations = (props) => {
  const {
    className,
    intl,
    listing,
  } = props;
  const { price, publicData } = listing.attributes;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const priceLabelText = intl.formatMessage({ id: 'ManageListingsPage.ManageListingCard.priceLabel' });
  const capacityLabelText = intl.formatMessage({ id: 'ManageListingsPage.ManageListingCard.capacityLabel' });
  const capacityValueText = intl.formatMessage({ id: 'ManageListingsPage.ManageListingCard.capacityValue' }, { number: publicData.maxCapacityToGuest });
  const minHoursToBookLabelText = intl.formatMessage({ id: 'ManageListingsPage.ManageListingCard.minHoursToBookLabel' });
  const minHoursToBookValueText = intl.formatMessage({ id: 'ManageListingsPage.ManageListingCard.minHoursToBookValue' }, { count: publicData.minimumHoursPerBooking || 1 });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <div className={css.priceWrapper}>
        <DataWithLabel className={css.dataWithLabel} label={priceLabelText} value={formattedPrice} />
        <DataWithLabel className={css.dataWithLabel} label={capacityLabelText} value={capacityValueText} />
      </div>
      <DataWithLabel className={css.minHoursToBook} label={minHoursToBookLabelText} value={minHoursToBookValueText} />
    </div>
  )
}

ListingInformations.defaultProps = {
  className: null,
};

ListingInformations.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  listing: propTypes.ownListing.isRequired,
};

export default ListingInformations;