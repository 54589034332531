import React from 'react';
import { bool, number } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { map } from 'ramda';

import config from '../../config';
import {
  hoursArray,
  hoursArray12hour,
} from '../../util/time';
import { FieldSelect } from '../../components';

import css from './FieldTimePicker.module.css';

const use12Hours = config.locale === 'en';

const FieldTimePicker = props => {
  const {
    currentDate,
    intl,
    minHour,
    minHourBetween,
    maxHour,
    withoutPlaceholder,
    ...rest
  } = props;
  const selectDefaultText = intl.formatMessage({ id: 'FieldTimePicker.selectDefault' });

  return (
    <FieldSelect icon="time" {...rest}>
      {!withoutPlaceholder && <option value=''>{selectDefaultText}</option>}
      {map(option => {
        const { key, text, value } = option;

        if (key < minHour || key > maxHour) return;

        return (
          <option
            key={key}
            value={value}
          >
            {text}
          </option>
        )
      }, use12Hours ? hoursArray12hour : hoursArray)}
    </FieldSelect>
  )
}

FieldTimePicker.defaultProps = {
  maxHour: 24,
  minHour: 0,
  minHourBetween: 1,
  withoutPlaceholder: false,
};

FieldTimePicker.propTypes = {
  intl: intlShape.isRequired,
  minHour: number,
  minHourBetween: number,
  maxHour: number,
  withoutPlaceholder: bool,
};

export default injectIntl(FieldTimePicker);
