import React from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';

import { IconArrow } from '../../components';

import css from './styles.css';

const SampleNextArrow = (props) => {
  const { className, customClassName, style, onClick } = props;

  return (
    <div
      className={classNames(css.sliderArrows, className, customClassName)}
      style={{ ...style, right: '0px' }}
      onClick={onClick}
    >
      <IconArrow className={css.sliderArrows} direction='right' />
    </div>
  )
};

const SamplePrevArrow = (props) => {
  const { className, customClassName, style, onClick } = props;

  return (
    <div
      className={classNames(css.sliderArrows, className, customClassName)}
      style={{ ...style, left: '0px', zIndex: '1' }}
      onClick={onClick}
    >
      <IconArrow className={css.sliderArrows} direction='left' />
    </div>
  )
};

const SwimmySlider = (props) => {
  const {
    className,
    nextArrowClassName,
    prevArrowClassName,
    settings,
    children,
    sliderRef,
    ...rest
  } = props;
  const carouselSettings = {
    nextArrow: <SampleNextArrow customClassName={nextArrowClassName} />,
    prevArrow: <SamplePrevArrow customClassName={prevArrowClassName} />,
    adaptiveHeight: true,
    ...settings,
  };
  
  return (
    <Slider className={className} ref={sliderRef} {...carouselSettings} {...rest}>
      {children}
    </Slider>
  );
};

export default SwimmySlider;
