import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { propTypes } from '../../util/types';
import {
  composeValidators,
  autocompleteSearchRequired,
  autocompletePlaceSelected,
} from '../../util/validators';
import {
  Form,
  PrimaryButton,
  LocationAutocompleteInputField,
  FieldNumericStepper
} from '../../components';

import css from './EditListingPresentationForm.module.css';

const EditListingPresentationFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;
      const locationLabel = intl.formatMessage({ id: 'EditListingPresentationForm.locationLabel' });
      const locationPlaceholder = intl.formatMessage({ id: 'EditListingPresentationForm.locationPlaceholder' });
      const locationRequiredMessage = intl.formatMessage({ id: 'EditListingPresentationForm.locationRequired' });
      const locationErrorMessage = intl.formatMessage({ id: 'EditListingPresentationForm.locationError' });
      const maxCapacityLabel = intl.formatMessage({ id: 'EditListingPresentationForm.maxCapacityLabel' });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError  ? (
        <p className={css.error}>
          <FormattedMessage id='EditListingPresentationForm.updateFailed'/>
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPresentationForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <LocationAutocompleteInputField
            className={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            name="location"
            label={locationLabel}
            placeholder={locationPlaceholder}
            useDefaultPredictions={false}
            format={null}
            valueFromForm={values.location}
            validate={composeValidators(
              autocompleteSearchRequired(locationRequiredMessage),
              autocompletePlaceSelected(locationErrorMessage),
            )}
          />

          <div className={css.capacityContainer}>
            <FieldNumericStepper
              className={css.capacityItem}
              min={1}
              id="maxCapacityToGuest"
              name="maxCapacityToGuest"
              label={maxCapacityLabel}
            />
          </div>

          <div className={css.buttonContainer}>
            <PrimaryButton
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPresentationFormComponent.defaultProps = {
  className: null,
  selectedPlace: null,
  fetchErrors: null,
};

EditListingPresentationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  className: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPresentationFormComponent);
