import React from 'react';
import { string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { Logo, NamedLink } from '../../components';

import css from './DummyTopbar.module.css';

const DummyTopbar = (props) => {
  const { className, intl } = props;
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <NamedLink className={css.home} name="LandingPage">
        <Logo
          className={css.logoDesktop}
          alt={intl.formatMessage({ id: 'DummyTopbar.goToLandingPage' })}
          format="desktop"
        />
      </NamedLink>
    </div>
  )
}

DummyTopbar.defaultProps = {
  className: null,
};

DummyTopbar.propTypes = {
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(DummyTopbar);
