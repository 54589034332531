import React from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

import config from '../../config';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  AffilaeTrackingTag,
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  SimulatorForm,
  CriteoRentPool,
  HeatmapTag,
  IconAdd,
  NamedLink,
  SimulatorBanner,
  SimulatorContentWill,
  SimulatorSlideSwimPool,
} from '../../components';
import { TopbarContainer } from '../../containers';

import SectionInfos from './components/SectionInfos/SectionInfos';
import SectionReviews from './SectionReviews';
import css from './styles.css';

const SimulatorPageComponent = (props) => {
  const { intl, scrollingDisabled } = props;
  const { hasInsurance, insuranceLogo } = config.custom;

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'SimulatorPage.schemaTitle' }, { siteTitle });
  const ctaText = intl.formatMessage({ id: 'SimulatorPage.SimulatorBannerContentLink' });

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <CriteoRentPool/>
      <HeatmapTag />
      <AffilaeTrackingTag/>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="SimulatorPage"/>
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.layoutWrapperMain}>

          <SimulatorBanner />

          <section className={css.simulatorSection}>
            <SimulatorForm/>
            <NamedLink
              className={css.mainCTA}
              name='NewListingPage'
            >
              <span className={css.buttonText}>{ctaText}</span>
              <IconAdd className={css.buttonIcon} />
            </NamedLink>
          </section>

          {hasInsurance ? (
            <SimulatorContentWill insuranceLogo={insuranceLogo} />
          ) : null}

          {config.custom.showReviews ? (
            <SectionReviews />
          ) : null}

          <SimulatorSlideSwimPool />

          <SectionInfos className={css.section} />

        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

SimulatorPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const Index = compose(
  connect(mapStateToProps, null),
  injectIntl,
)(SimulatorPageComponent);

export default Index;
