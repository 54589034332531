import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';

import { isServer } from '../../util/sdkLoader';
import { IconClose } from '../../components';

import css from './ModalSwimmy.css';

const KEY_CODE_ESCAPE = 27;

const ModalSwimmy = (props) => {
  const {
    className,
    id,
    isOpen,
    onClose,
    onManageDisableScrolling,
    heading,
    content,
    footer,
  } = props;
  const modalRef = useRef();
  useEffect(() => {
    onManageDisableScrolling(id, isOpen);
    const modalRefCurrent = modalRef.current;
    if (!modalRefCurrent) return;

    modalRefCurrent.focus();
  }, [isOpen]);
  const handleClose = (e) => {
    onManageDisableScrolling(id, false);
    onClose(e);
  };
  const handleCloseByPressingKey = (e) => {
    if (e.keyCode !== KEY_CODE_ESCAPE) return;
    handleClose(e);
  };
  const handleCloseByClickingOutside = (e) => {
    if (e.target !== e.currentTarget) return;
    handleClose(e);
  };
  const classes = classNames(css.container, className)

  return !isServer() && isOpen ? ReactDOM.createPortal((
    <div
      className={css.root}
      ref={modalRef}
      onClick={handleCloseByClickingOutside}
      onKeyDown={handleCloseByPressingKey}
      tabIndex={0}
    >
      <div className={classes} >
        <header className={css.header}>
          <button className={css.closeButton} type="button" onClick={handleClose}>
            <IconClose className={css.icon} />
          </button>
          <h1 className={css.heading}>{heading}</h1>
        </header>
        <div className={css.content}>
          {content}
        </div>
        {footer && (
          <footer className={css.footer}>
            {footer}
          </footer>
        )}
      </div>
    </div>
    ), document.body
  ) : null;
}

ModalSwimmy.defaultProps = {
  className: null,
  footer: null,
}

ModalSwimmy.propTypes = {
  className: string,
  id: string.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  heading: string.isRequired,
  content: object.isRequired,
  footer: object,
}

export default ModalSwimmy;
