import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconArrowNavigation.module.css';

const IconArrowNavigation = props => {
  const { rootClassName, className, direction } = props;
  const arrowForward = direction === 'right';
  const classes = classNames(rootClassName || css.root, className);

  return arrowForward ? (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/>
      <path d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31c.39-.39.39-1.02 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z"/>
    </svg>
  ) : (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px">
      <rect fill="none" height="24" width="24"/>
      <g>
        <path d="M16.88,2.88L16.88,2.88c-0.49-0.49-1.28-0.49-1.77,0l-8.41,8.41c-0.39,0.39-0.39,1.02,0,1.41l8.41,8.41 c0.49,0.49,1.28,0.49,1.77,0l0,0c0.49-0.49,0.49-1.28,0-1.77L9.54,12l7.35-7.35C17.37,4.16,17.37,3.37,16.88,2.88z"/>
      </g>
    </svg>
  );
};

IconArrowNavigation.defaultProps = {
  rootClassName: null,
  className: null,
  direction: 'right',
};

IconArrowNavigation.propTypes = {
  rootClassName: string,
  className: string,
  direction: string,
};

export default IconArrowNavigation;