import React from 'react';
import { string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { pathOr } from 'ramda';

import { propTypes } from '../../../../util/types';

import { getResponseTimeValueTextId } from './ResponseInfo.helpers';
import css from './ResponseInfo.module.css';

const ResponseInfo = (props) => {
  const {
    className,
    intl,
    listing
  } = props;
  const responseInfo = pathOr({}, ['attributes', 'metadata', 'responseInfo'], listing);
  const responseRateText = responseInfo.responseRate
    ? intl.formatMessage({ id: 'ListingPage.responseRate' }, { percentage: Math.round(responseInfo.responseRate) })
    : intl.formatMessage({ id: 'ListingPage.responseRateUndefined' });
  const responseTimeText = intl.formatMessage({ id : 'ListingPage.responseTime' });
  const responseTimeValueTextId = getResponseTimeValueTextId(responseInfo.responseTimeAverage);
  const responseTimeValueText = intl.formatMessage({ id: `ListingPage.${responseTimeValueTextId}` })
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <span className={css.text}>{responseRateText}</span>
      <span className={css.text}>{`${responseTimeText} ${responseTimeValueText}`}</span>
    </div>
  )
}

ResponseInfo.defaultProps = {
  className: null,
  listing: null
};

ResponseInfo.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing
};

export default ResponseInfo;
