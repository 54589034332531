import React from 'react';
import { injectIntl} from 'react-intl';

import { Card } from '../../components';
import senlisImg from '../../assets/clarisses-de-senlis.png';
import fugainImg from '../../assets/laurette-fugain.png';
import lazareImg from '../../assets/lazare.png';

import css from './WhoWeArePage.css'

const SectionAssociations = (props) => {
  const { intl } = props;
  const fugainLinkProps = { href:"https://www.laurettefugain.org/" };
  const senlisLinkProps = { href: "https://clarissesdesenlis.fr/" };
  const lazareLinkProps = { href: "https://lazare.eu/" };
  const associationSentence = intl.formatMessage({ id : "WhoWeArePage.associationSentence" });
  const associationButton = intl.formatMessage({ id : "WhoWeArePage.associationButton" });
  const associationFugain = intl.formatMessage({ id : "WhoWeArePage.associationFugain" });
  const associationSenlis = intl.formatMessage({ id : "WhoWeArePage.associationSenlis" });
  const associationLazare = intl.formatMessage({ id : "WhoWeArePage.associationLazare" });

  return (
    <section className={css.section}>
      <div className={css.assoContent}>
        <span className={css.textContainer}>{associationSentence}</span>
        <div className={css.cardContainer}>
          <Card
            className={css.associationCard}
            imgClassName={css.associationCardImg}
            linkClassName={css.associationLink}
            img={fugainImg}
            text={associationFugain}
            linkProps={fugainLinkProps}
            linkText={associationButton}
            isExternalLink
          />
          <Card
            className={css.associationCard}
            imgClassName={css.associationCardImg}
            linkClassName={css.associationLink}
            img={senlisImg}
            text={associationSenlis}
            linkProps={senlisLinkProps}
            linkText={associationButton}
            isExternalLink
          />
          <Card
            className={css.associationCard}
            imgClassName={css.associationCardImg}
            linkClassName={css.associationLink}
            img={lazareImg}
            text={associationLazare}
            linkProps={lazareLinkProps}
            linkText={associationButton}
            isExternalLink
          />
        </div>
      </div>
    </section>
  );
}

export default injectIntl(SectionAssociations);