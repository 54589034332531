import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';

import { isServer } from '../../util/cookies';

const GoogleAnalyticsTag = () => {
  if (isServer()) return null;

  const googleAnalyticsScript = process.env.REACT_APP_GOOGLE_ANALYTICS_ID ? (
      <Fragment>
        <script>
          {`
            window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
            ga('create', '${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}', 'auto');
          `}
        </script>
        <script async src="https://www.google-analytics.com/analytics.js"></script>
      </Fragment>
    ) : null;

  // Google Tag Manager
  const gtmScript =
    process.env.REACT_APP_ENV === 'production'
      ? (<script>{`
      window.dataLayer = window.dataLayer || []; window.dataLayer.push({ originalLocation: document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search });
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-M343NLD');`}</script>)
      : null;
  const gtmNoScript =
    process.env.REACT_APP_ENV === 'production'
      ? (<noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M343NLD" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>)
      : null;

  return (
    <div>
      <Helmet>
        {googleAnalyticsScript}
        {gtmScript}
        {/*{gtmNoScript}*/}
      </Helmet>
    </div>
  )
};

export default GoogleAnalyticsTag;
