import isEmpty from 'lodash/isEmpty';
import { find, length, map, path, pathOr } from 'ramda';

import { clearCurrentUser, fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from './user.duck';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import {
  TRANSITION_COMPLETE,
  TRANSITION_COMPLETE_BY_OPERATOR,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_CUSTOMER_AFTER_PAYOUT,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_PROVIDER_AFTER_PAYOUT,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER_AFTER_PAYOUT,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER_AFTER_PAYOUT,
} from '../util/transactions';
import { types as sdkTypes } from '../util/sdkLoader';
import { getIpGeolocation } from '../util/swimmyOnLoginActions';

const { UUID } = sdkTypes;

const authenticated = authInfo => authInfo  && authInfo.isAnonymous === false;
// const authenticated = authInfo => authInfo && authInfo.isAnonymous === false;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return { ...state, authInfoLoaded: true, isAuthenticated: authenticated(payload) };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
    case LOGOUT_SUCCESS:
      return { ...state, logoutInProgress: false, isAuthenticated: false };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return { ...state, signupInProgress: true, loginError: null, signupError: null };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.Auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({ type: LOGIN_ERROR, payload: error, error: true });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = error => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const userLogout = () => ({ type: USER_LOGOUT });

// ================ Thunks ================ //

export const getNumberOfFinishedBookings = async (sdk) => {
  const params = {
    only: 'sale',
    lastTransitions: [
      TRANSITION_COMPLETE,
      TRANSITION_COMPLETE_BY_OPERATOR,
      TRANSITION_REVIEW_1_BY_CUSTOMER,
      TRANSITION_REVIEW_1_BY_CUSTOMER_AFTER_PAYOUT,
      TRANSITION_REVIEW_1_BY_PROVIDER,
      TRANSITION_REVIEW_1_BY_PROVIDER_AFTER_PAYOUT,
      TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
      TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
      TRANSITION_EXPIRE_REVIEW_PERIOD,
      TRANSITION_REVIEW_2_BY_CUSTOMER,
      TRANSITION_REVIEW_2_BY_CUSTOMER_AFTER_PAYOUT,
      TRANSITION_REVIEW_2_BY_PROVIDER,
      TRANSITION_REVIEW_2_BY_PROVIDER_AFTER_PAYOUT,
    ],
  };

  try {
    const response = await sdk.transactions.query(params);
    return length(pathOr([], ['data', 'data'], response));
  }
  catch (e) {
    return 0;
  }
};

export const markOwnListingsAsSuperHosted = async (sdk) => {
  try {
    const response = await sdk.ownListings.query({});
    const listings = pathOr([], ['data', 'data'], response);
    const listingNotSuperHostMarked = find((listing) => !path(['attributes', 'publicData', 'isHostedBySuperHost'], listing), listings);
    if (!listingNotSuperHostMarked) return;

    Promise.all(map(async (listing) => {
      const id = new UUID(listing.id.uuid);
      await sdk.ownListings.update({
        id,
        publicData: {
          isHostedBySuperHost: true,
        },
      });
    }, listings));
  }
  catch (e) {
    return null;
  }
};

export const markCurrentUserAsSuperHost = async (sdk) => {
  try {
    await sdk.currentUser.updateProfile({
      publicData: {
        isSuperHost: true,
      },
    });
  } catch (e) {
    return null;
  }
};

export const handleSuperHost = async (sdk) => {
  const numberOfFinishedBookings = await getNumberOfFinishedBookings(sdk);
  const numberOfFinishedBookingsToBeSuperHost = 25;
  if (numberOfFinishedBookings < numberOfFinishedBookingsToBeSuperHost) return null;

  await markOwnListingsAsSuperHosted(sdk);
  await markCurrentUserAsSuperHost(sdk);
};

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then(info => dispatch(authInfoSuccess(info)))
    .catch(e => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(authInfoSuccess(null));
    });
};

export const login = (username, password) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.
  return sdk
    .login({ username, password })
    .then(() => {
      dispatch(loginSuccess());
      dispatch(fetchCurrentUser());
      handleSuperHost(sdk);
    })
    .catch(e => dispatch(loginError(storableError(e))));
};

export const logout = () => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(() => {
      // The order of the dispatched actions
      dispatch(logoutSuccess());
      dispatch(clearCurrentUser());
      log.clearUserId();
      dispatch(userLogout());
    })
    .catch(e => dispatch(logoutError(storableError(e))));
};

export const signup = params => async (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());
  const trackingPayload = path(['LandingPage', 'trackingPayload'], getState());
  const {
    email,
    password,
    firstName,
    lastName,
    privateData = {},
    ...rest
  } = params;
  const isPoolOwner = path(['isPoolOwner'], rest);
  const ipGeolocation = isPoolOwner && await getIpGeolocation();
  const ipGeolocationDataMaybe = ipGeolocation ? { region: path(['region'], ipGeolocation), city: path(['city'], ipGeolocation) } : {};
  const privateDataMerged = {
    ...privateData,
    ...ipGeolocationDataMaybe,
    trackingPayload
  };
  const createUserParams = isEmpty(rest)
    ? { email, password, firstName, lastName, privateData: privateDataMerged }
    : { email, password, firstName, lastName, privateData: privateDataMerged, protectedData: { ...rest } };

  // We must login the user if signup succeeds since the API doesn't
  // do that automatically.
  return sdk.currentUser
    .create(createUserParams)
    .then(() => {
      dispatch(signupSuccess());
      dispatch(login(email, password));
    })
    .catch(e => {
      dispatch(signupError(storableError(e)));
      log.error(e, 'signup-failed', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
      });
    });
};
