import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import classNames from 'classnames';
import { path } from 'ramda';

import { propTypes } from '../../../util/types';
import * as validators from '../../../util/validators';
import { FieldTextInput, UserCard } from '../../../components';

import css from './InitialMessageSection.module.css';

const InitialMessageSection = (props) => {
  const { className, provider, formId, intl } = props;
  const providerDisplayName = path(['attributes', 'profile', 'displayName'], provider);
  const infoHeadingText = intl.formatMessage({ id: 'StripePaymentForm.InitialMessageSection.infoHeading' });
  const infoText = intl.formatMessage({ id: 'StripePaymentForm.InitialMessageSection.info' });
  const messagePlaceholder = intl.formatMessage({ id: 'StripePaymentForm.messagePlaceholder' }, { name: providerDisplayName });
  const initialMessageLabel = intl.formatMessage({ id: 'StripePaymentForm.messageLabel' });
  const initalMessageRequiredText = intl.formatMessage({ id: 'StripePaymentForm.messageRequired' });
  const initialMessageRequired = validators.required(initalMessageRequiredText);
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <h3 className={css.heading}>
        <FormattedMessage id="StripePaymentForm.messageHeading" />
      </h3>
      <div className={css.infoWrapper}>
        <span className={css.infoHeading}>{infoHeadingText}</span>
        <span className={css.info}>{infoText}</span>
      </div>
      <UserCard className={css.userCard} user={provider} />
      <FieldTextInput
        className={css.fieldMessage}
        type="textarea"
        id={`${formId}-message`}
        name="initialMessage"
        label={initialMessageLabel}
        placeholder={messagePlaceholder}
        validate={initialMessageRequired}
      />
    </div>
  )
}

InitialMessageSection.defaultProps = {
  className: null,
};

InitialMessageSection.propTypes = {
  className: string,
  formId: string.isRequired,
  intl: intlShape.isRequired,
  provider: propTypes.user.isRequired,
};

export default InitialMessageSection;
