import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconMap.module.css';

const IconMap = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.791672 4.74992V17.4166L6.33334 14.2499L12.6667 17.4166L18.2083 14.2499V1.58325L12.6667 4.74992L6.33334 1.58325L0.791672 4.74992Z" strokeWidth="1.5113" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.33333 1.58325V14.2499" strokeWidth="1.5113" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.6667 4.75V17.4167" strokeWidth="1.5113" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

IconMap.defaultProps = {
  rootClassName: null,
  className: null,
};

IconMap.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconMap;
