import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';

import {
  IconArrowHead,
  IconFilter,
  IconSearch,
  NamedLink,
} from '../../../../components';

import css from './SearchButton.module.css';

const SearchButton = (props) => {
  const {
    className,
    handleShowFilters,
    hasActiveFilters,
    intl,
    isSearchPage,
    mapSearch,
    searchParams,
    ...buttonProps
  } = props;
  const { address, adults, children, city, bookingDate, infants } = searchParams;
  const totalParticipants = adults + (children || 0);
  const hasParticipants = totalParticipants > 0;
  const buttonText = intl.formatMessage({ id: 'Search.SearchButton.text' });
  const addressPlaceholderText = intl.formatMessage({ id: 'Search.SearchButton.addressPlaceholder' });
  const datePlaceholderText = intl.formatMessage({ id: 'Search.SearchButton.datePlaceholder' });
  const participantsPlaceholderText = intl.formatMessage({ id: 'Search.SearchButton.participantsPlaceholder' })
  const totalParticipantsText = intl.formatMessage({ id: 'Search.totalParticipants' }, { count: totalParticipants });
  const infantsText = intl.formatMessage({ id: 'Search.infants' }, { count: infants });
  const classes = classNames(css.root, className);
  const filterButtonClasses = classNames(css.filterButton, {
    [css.activeFilters]: hasActiveFilters,
  });

  return isSearchPage ? (
    <div className={css.searchPageButtonWrapper}>
      <NamedLink className={css.backLink} name="LandingPage">
        <IconArrowHead direction="left" />
      </NamedLink>
      <div className={css.searchButtonContainer}>
        <button className={css.searchButton} type="button" {...buttonProps}>
          <div className={css.valueWrapper}>
            <span className={css.address}>{mapSearch ? addressPlaceholderText : (city || address)}</span>
            <span className={css.infos}>
              {bookingDate ? moment(bookingDate).format('ddd D MMM') : datePlaceholderText}
              {!hasParticipants && bookingDate && ` • ${participantsPlaceholderText}`}
              {hasParticipants && ` • ${totalParticipantsText}`}
              {infants && `, ${infantsText}`}
            </span>
          </div>
        </button>
      </div>
      <button className={filterButtonClasses} type="button" onClick={handleShowFilters}>
        <IconFilter />
      </button>
    </div>
  ) : (
    <button className={classes} type="button" {...buttonProps}>
      <IconSearch className={css.iconSearch} />
      <span className={css.text}>{buttonText}</span>
    </button>
  )
};

SearchButton.defaultProps = {
  className: null,
  mapSearch: false,
  searchParams: {},
};

SearchButton.propTypes = {
  className: string,
  handleShowFilters: func.isRequired,
  intl: intlShape.isRequired,
  isSearchPage: bool.isRequired,
  mapSearch: bool,
  searchParams: object,
};

export default injectIntl(SearchButton);