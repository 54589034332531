import classNames from 'classnames';
import { string } from 'prop-types';
import React from 'react';

import css from './Divider.module.css';

const Divider = (props) => {
  const { className } = props;
  const classes = classNames(css.root, className);

  return (
    <div className={classes} />
  )
}

Divider.defaultProps = {
  className: null,
}

Divider.propTypes = {
  className: string,
}

export default Divider;
