import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { handleIFrameVisibility } from './ZendeskWidget.Helpers';

const LAUNCHER_ID = '[id="launcher"]';
const WEB_WIDGET_ID = '[id="webWidget"]';

const ZendeskWidget = (props) => {  
  const { currentPage } = props;
  const [wigdetLoaded, setWidgetLoaded] = useState(document.querySelectorAll(LAUNCHER_ID)[0]);
  const [stylesheetsLoaded, setStylesheetsLoaded] = useState(false);
  const intervalRef = useRef();

  const addStylesheet = (iFrame) => {
    const stylesheet = iFrame.contentDocument.getElementById('ze-styling');
    if (stylesheet) return;
    const link = document.createElement('link');
    link.id = "ze-styling"
    link.href = `/static/zendeskWidget.css`;
    link.rel = "stylesheet";
    link.type = "text/css";
    iFrame.contentDocument.head.appendChild(link);
  };

  const handleLauncherClick = () => {
    setTimeout(() => {
      const webWidget = document.querySelectorAll(WEB_WIDGET_ID)[0];

      if (!webWidget) return;

      addStylesheet(webWidget);
    }, 1000);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const widget = document.querySelectorAll(LAUNCHER_ID)[0];
      if (widget) {
        setWidgetLoaded(true);
        const buttonLauncher = widget.contentDocument.querySelectorAll('[data-testid="launcher"]')[0];

        if (!buttonLauncher) return;

        buttonLauncher.onclick = handleLauncherClick;
      };
    }, 500);
    intervalRef.current = interval;

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!wigdetLoaded) return;

    clearInterval(intervalRef.current);
    const iFrameLauncher = document.querySelectorAll(LAUNCHER_ID)[0];
    if (iFrameLauncher && !stylesheetsLoaded) {
      addStylesheet(iFrameLauncher);
      setStylesheetsLoaded(true);
    }
  }, [wigdetLoaded]);

  useEffect(() => {
    if (!wigdetLoaded) return;

    const iFrameLauncher = document.querySelectorAll(LAUNCHER_ID)[0];

    setTimeout(() => handleIFrameVisibility(iFrameLauncher, currentPage), 500);
  }, [currentPage, wigdetLoaded]);

  return (
    <Helmet>
      <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=6ddc8b85-44b0-486d-90db-b8cac1db8001"></script>
    </Helmet>
  )
}

export default ZendeskWidget;