import React from 'react';
import { string, object } from 'prop-types';
import classNames from 'classnames';
import { compose, map, values } from 'ramda';

import { NamedLink } from '../../../components';

import css from './AccountTabNav.module.css';

const AccountTabNav = props => {
  const { className, tabs, currentTab } = props;
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      {compose(
        values,
        map(tab => {
          const isCurrentTab = tab.tabName === currentTab;
          const tabTitle = tab.title;
  
          return (
            <NamedLink
              key={tabTitle}
              className={isCurrentTab ? css.currentTabContainer : css.tabContainer}
              name={tab.routeName}
            >
              <span className={css.tabTitle}>{tabTitle}</span>
            </NamedLink>
          )
        })
      )(tabs)}
    </div>
  )
};

AccountTabNav.defaultProps = {
  className: null,
};

AccountTabNav.propTypes = {
  className: string,
  tabs: object.isRequired,
  currentTab: string.isRequired,
};

export default AccountTabNav;