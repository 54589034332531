import React from 'react';
import { string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { propTypes } from '../../../../../../util/types';
import { formatMoney } from '../../../../../../util/currency';
import { txIsEnquired } from '../../../../../../util/transactions';

import { getTxType } from '../../../../InboxPage.Helpers';
import css from './DetailsPrice.module.css';

const DetailsPrice = props => {
  const {
    className,
    currentUser,
    transaction,
    intl,
  } = props;
  const txType = getTxType(transaction, currentUser);
  const isEnquiry = txIsEnquired(transaction);
  const isOrder = txType === 'order';
  const bookingPrice = isOrder ? transaction.attributes.payinTotal : transaction.attributes.payoutTotal;
  const price = bookingPrice && formatMoney(intl, bookingPrice, true);
  const priceText = intl.formatMessage({ id: isOrder ? 'InboxPage.price' : 'InboxPage.income' });
  const classes = classNames(css.root, className);

  return !isEnquiry ? (
    <div className={classes}>
      <div className={css.priceContainer}>
        <span className={css.text}>{priceText}</span>
        <span className={css.price}>{price}</span>
      </div>
    </div>
  ) : null;
}

DetailsPrice.defaultProps = {
  className: null,
};

DetailsPrice.propTypes = {
  className: string,
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
  transaction: propTypes.transaction.isRequired,
};

export default injectIntl(DetailsPrice);
