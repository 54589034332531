import React from 'react';
import { bool } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import css from './GenericError.module.css';

const GenericError = props => {
  const { intl, show } = props;
  const errorText = intl.formatMessage({ id: 'Topbar.genericError' });
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });

  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>{errorText}</p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  intl: intlShape.isRequired,
  show: bool.isRequired,
};

export default injectIntl(GenericError)