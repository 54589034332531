import React from 'react';
import { Helmet } from 'react-helmet-async';

import { isServer, isCookieAccepted } from '../../util/cookies';

const AffilaeConversionTag = props => {
  if (isServer()) return null;
  if (!isCookieAccepted('swimmy_affilaeAccepted')) return null;

  const {listingId} = props;
  if (!listingId) return null;

  return (
    <div>
      <Helmet>
        <script type="text/javascript">
          {`
              var aeEvent = {};
            /* {{KEY}} must be updated for each rule */
            aeEvent.key = '5f9a93515504a53dbcfa7e17-5f9a931a5504a53dbcfa7e15';
            aeEvent.Conversion = {};
            /* Values below must be updated */
            aeEvent.Conversion.id = '${listingId}';
            aeEvent.Conversion.amount = '0';
            aeEvent.Conversion.subid = 'N/A';
            aeEvent.Conversion.payment = 'N/A';
            aeEvent.Conversion.voucher = "N/A";
            ('AeTracker' in window)
              ? AeTracker.sendConversion(aeEvent)
            : (window.AE = window.AE || []).push(aeEvent);
         `}
        </script>
      </Helmet>
    </div>
  );
};

AffilaeConversionTag.defaultProps = { listingId: null };

export default AffilaeConversionTag;
