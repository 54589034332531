import React, { PureComponent } from 'react';
import { bool  } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import config from '../../config';
import { propTypes } from '../../util/types';
import {
  AffilaeTrackingTag,
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  CriteoFinishCreateNewProduct,
  AffilaeConversionTag,
} from '../../components';
import { TopbarContainer } from '../../containers';

import css from './styles.css';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { parse } from '../../util/urlHelpers';

class CongratAfterAdCreationPageComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      slug: null,
    };
  }

  componentDidMount() {
    const { search } = this.props.location;
    const { id, slug } = parse(search);
    this.setState({
      id,
      slug,
    });
  }

  getRedirectLink = () => {
    const { id, slug } = this.state;
    const redirectLinkProps = (id && slug) ? ({
      name: 'CalendarPage',
      params: {
        id,
      },
    }) : ({
      name: 'ManageListingsPage',

    });

    return (
      <NamedLink {...redirectLinkProps}>
        <FormattedMessage id="word.here"/>
      </NamedLink>
    );
  };

  render() {
    const { intl, scrollingDisabled } = this.props;
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'CongratPoolPage.schemaTitle' }, { siteTitle });
    const content = <FormattedMessage id="CongratPool.Content"/>;
    const { id } = this.state;

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
        }}
      >
        <CriteoFinishCreateNewProduct listingId={id}/>
        <AffilaeTrackingTag/>
        <AffilaeConversionTag listingId={id}/>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="CongratPoolPage"/>
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            <div className={css.root}>
              <div className={css.container}>
                <h1>{content}
                  {this.getRedirectLink()}
                </h1>.
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer/>
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  };
};

CongratAfterAdCreationPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};


// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const CongratAfterAdCreationPage = compose(
  connect(mapStateToProps, null),
  injectIntl
)(CongratAfterAdCreationPageComponent);

export default CongratAfterAdCreationPage;
