import React from 'react';
import { injectIntl } from 'react-intl';

import { Card } from '../../components';
import sponsorGPJeanImg from '../../assets/grand-pere-jean-sponsor.png';
import sponsorJeanPaulImg from '../../assets/jean-paul-sponsor.png';
import sponsorThibaultImg from '../../assets/thibault-sponsor.png';

import css from './WhoWeArePage.css';

const SectionSponsors = (props) => {
  const { intl } = props;
  const sponsorThibault = intl.formatMessage({ id : 'WhoWeArePage.sponsorThibault' });
  const sponsorJeanPaul = intl.formatMessage({ id: 'WhoWeArePage.sponsorJeanPaul'});
  const sponsorJean = intl.formatMessage({ id: 'WhoWeArePage.sponsorJean' });

  return (
    <section className={css.section}>
      <div className={css.sponsorContent}>
        <Card
          className={css.sponsorCard}
          imgClassName={css.sponsorCardImg}
          img={sponsorThibaultImg}
          text={sponsorThibault}
        />
        <Card
          className={css.sponsorCard}
          imgClassName={css.sponsorCardImg}
          img={sponsorGPJeanImg}
          text={sponsorJean}
        />
        <Card
          className={css.sponsorCard}
          imgClassName={css.sponsorCardImg}
          img={sponsorJeanPaulImg}
          text={sponsorJeanPaul}
        />
      </div>
    </section>
  );
};

export default injectIntl(SectionSponsors);