import React from 'react';
import { object, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { path } from 'ramda';

import { propTypes } from '../../../util/types';
import { getDistanceBetweenCities, getLatLngFromOrigin } from '../../../util/listingHelpers';

import css from './GeolocationInfo.module.css';

const GeolocationInfo = (props) => {
  const {
    className,
    intl,
    listing,
    searchParams,
  } = props;
  const { city: searchCity, origin } = searchParams;
  const searchLatLng = searchParams && getLatLngFromOrigin(origin);
  const geolocation = path(['attributes', 'geolocation'], listing);
  const cityLatLng = geolocation &&  {
    latitude: geolocation.lat,
    longitude: geolocation.lng,
  };
  const distanceFromSearch = searchLatLng && cityLatLng && getDistanceBetweenCities(searchLatLng, cityLatLng, intl.locale)
  const city = path(['attributes', 'publicData', 'location', 'city'], listing);
  const distanceMessage = distanceFromSearch && searchCity !== null && city !== searchCity ?
    intl.formatMessage({ id: 'ListingCard.differentCityDistance' }, { distance: distanceFromSearch, city: searchCity }) :
    distanceFromSearch && distanceFromSearch !== 0 ?
    intl.formatMessage({ id: 'ListingCard.sameCityDistance' }, { distance: distanceFromSearch }) :
    null;
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <span className={css.city}>{city}</span>
      {searchCity && city && distanceMessage && <span className={css.separator}>•</span>}
      {searchCity && distanceMessage && <span className={css.distance}>{distanceMessage}</span>}
    </div>
  )
}

GeolocationInfo.defaultProps = {
  className: null,
  listing: null,
  searchParams: {},
};

GeolocationInfo.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing,
  searchParams: object,
};

export default GeolocationInfo;
