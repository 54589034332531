import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import SectionContact from './components/SectionContact/SectionContact';
import SectionFaq from './components/SectionFaq/SectionFaq';
import css from './SectionInfos.module.css';

const SectionInfos = (props) => {
  const { className, intl } = props;
  const titleHighlight = (
    <span className={css.headingHightlight}>
      <br/>{intl.formatMessage({ id: 'SimulatorPage.faqTitleHighlight' })}
    </span>
  );
  const title = (<FormattedMessage id='SimulatorPage.faqTitle' values={{ highlight: titleHighlight }} />);
  const classes = classNames(css.root, className);

  return (
    <section className={classes}>
      <div className={css.header}>
        <h2 className={css.heading}>{title}</h2>
      </div>
      <div className={css.sectionContainer}>
        <SectionFaq className={css.section} />
        <SectionContact className={css.section} />
      </div>
    </section>
  )
}

SectionInfos.defaultProps = {
  className: null,
};

SectionInfos.propTypes = {
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(SectionInfos);