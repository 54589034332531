import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { ListingCard, IconSpinner, NamedLink } from '../../components';
import css from './TransactionPanel.module.css';

const SectionSimilarListings = props => {
  const { listings, searchInProgress, searchListingsError, suggestionSearchParams } = props;
  const morePoolsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id='ListingPage.morePoolsError' />
    </h2>
  );
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  return (
    <div className={css.sectionMorePools}>
      <div className={css.similarListingsTitle}>
        <span>
          <span className={css.similarListingsTitlePart1}>
             <FormattedMessage id='TransactionPanel.similarListingsTitlePart1' />
          </span>
              <FormattedMessage id='TransactionPanel.similarListingsTitle' />
        </span>
      </div>
      {searchListingsError ? morePoolsError : null}
      <div className={css.similarListingsContainer}>
        {searchInProgress ? (
          <div className={css.centerContent}>
            <IconSpinner />
          </div>
        ) : (
          <Fragment>
            {listings.map(l => (
              <ListingCard
                className={css.similarListingCard}
                key={l.id.uuid}
                listing={l}
                renderSizes={cardRenderSizes}
              />
            ))}
          </Fragment>
        )}
        <div />
      </div>
      <div className={css.searchMoreLinkContainer}>
        <NamedLink
          className={css.searchMoreLink}
          name='SearchPage'
          to={{ search: suggestionSearchParams }}
        >
        <span>
              <FormattedMessage id='TransactionPanel.SectionMorePoolsSearchMore' />
        </span>
        </NamedLink>
      </div>
    </div>
  );
};

export default SectionSimilarListings;
