import React, { Fragment } from 'react';
import { func, object, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import moment from 'moment';
import { getTotalHours } from '../../../../util/time';
import { IconSearch } from '../../../../components';

import css from './SearchButtonWithValues.module.css';

const SearchButtonWithValues = (props) => {
  const {
    className,
    onClick,
    intl,
    searchParams,
  } = props;
  const {
    address,
    adults,
    children,
    city,
    bookingDate,
    endTime,
    infants,
    mapSearch,
    startTime,
  } = searchParams;
  const totalParticipants = adults + (children || 0);
  const hasParticipants = totalParticipants > 0;
  const locationPlaceholderText = intl.formatMessage({ id: 'SearchDesktop.SearchButtonWithValues.locationPlaceholder' });
  const datePlaceholderText = intl.formatMessage({ id: 'SearchDesktop.SearchButtonWithValues.datePlaceholder' });
  const timeSlotPlaceholderText = intl.formatMessage({ id: 'SearchDesktop.SearchButtonWithValues.timeSlotPlaceholder' });
  const timeSlotText = intl.formatMessage({ id: 'SearchDesktop.TimeSlotInput.value' }, { hours: getTotalHours(startTime, endTime) });
  const participantsPlaceholderText = intl.formatMessage({ id: 'SearchDesktop.SearchButtonWithValues.participantsPlaceholder' })
  const totalParticipantsText = intl.formatMessage({ id: 'SearchDesktop.totalParticipants' }, { count: totalParticipants });
  const infantsText = intl.formatMessage({ id: 'SearchDesktop.infants' }, { count: infants });
  const classes = classNames(css.root, className);
  const dateTextClasses = bookingDate ? css.text : css.placeholder;
  const timeSlotTextClasses = startTime ? css.text : css.placeholder;
  const participantsTextClasses = hasParticipants ? css.text : css.placeholder;

  return (
    <div className={classes}>
      <button className={css.addressButton} id="locationButton" type="button" onClick={onClick}>
        <span className={css.text}>{mapSearch ? locationPlaceholderText : (city || address)}</span>
      </button>
      <div className={css.separator} />
      <button className={css.dateButton} id="dateButton" type="button" onClick={onClick}>
        <span className={dateTextClasses}>
          {bookingDate ? moment(bookingDate).format('ddd D MMM') : datePlaceholderText}
        </span>
      </button>
      {bookingDate && (
        <Fragment>
          <div className={css.separator} />
          <button className={css.timeSlotButton} id="timeSlotButton" type="button" onClick={onClick}>
            <span className={timeSlotTextClasses}>
              {startTime ? timeSlotText : timeSlotPlaceholderText}
            </span>
          </button>
        </Fragment>
      )}
      <div className={css.separator} />
      <div className={css.rightContainer}>
        <button className={css.participantsButton} id="participantsButton" type="button" onClick={onClick}>
          <span className={participantsTextClasses}>
            {hasParticipants ? totalParticipantsText : participantsPlaceholderText}
            {infants && `, ${infantsText}`}
          </span>
        </button>
        <button className={css.searchButton} type="button" onClick={onClick}>
          <IconSearch className={css.iconSearch} />
        </button>
      </div>
    </div>
  )
}

SearchButtonWithValues.defaultProps = {
  className: null,
  searchParams: {},
};

SearchButtonWithValues.propTypes = {
  className: string,
  onClick: func.isRequired,
  intl: intlShape.isRequired,
  searchParams: object,
};

export default injectIntl(SearchButtonWithValues);
