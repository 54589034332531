
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { string } from 'prop-types';
import classNames from 'classnames';
import { pathOr } from 'ramda';

import { Avatar, ReviewRating } from '../../components';

import css from './styles.css';

const authorDisplayName = (review, intl) => {
  return review.author.attributes.banned
    ? intl.formatMessage({ id: 'Reviews.bannedUserDisplayName' })
    : review.author.attributes.profile.displayName;
};

const ReviewCard = props => {
  const { className, rootClassName, review, intl } = props;

  const reviewDate = review.review.createdAt;
  const reviewDateFormatted = intl.formatDate(reviewDate, {
    year: 'numeric',
    month: 'long',
  });
  const reviewContent = "“" + pathOr('', ['review', 'content'], review) + "“";

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <span className={css.review}>{reviewContent}</span>
      <div className={css.reviewInfoContainer}>
        <Avatar user={review.author} disableProfileLink />
        <div className={css.reviewInfo}>
          <span className={css.reviewAuthor}>{authorDisplayName(review, intl)}</span>
          <span className={css.reviewDate}>{reviewDateFormatted}</span>
          <ReviewRating
            rating={review.review.rating}
            reviewStarClassName={css.reviewRatingStar}
          />
        </div>
      </div>
    </div>
  );
}

ReviewCard.defaultProps = {
  className: null,
  rootClassName: null,
};

ReviewCard.propTypes = {
  className: string,
  rootClassName: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ReviewCard);
