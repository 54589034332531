import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { IconInfo } from '../../components/Icons';

import css from './TextContainer.module.css';

const TextContainer = (props) => {
  const {
    className,
    rootClassName,
    iconClassName,
    text,
    type,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const iconClasses = classNames(iconClassName || css.icon);

  return (
    <div className={classes}>
      <IconInfo className={iconClasses} />
      <span className={css.text}>{text}</span>
    </div>
  )
};

TextContainer.defaultProps = {
  className: null,
  rootClassName: null,
  iconClassName: null,
  type: 'information',
};

TextContainer.propTypes = {
  className: string,
  rootClassName: string,
  iconClassName: string,
  text: string.isRequired,
  type: string,
};

export default TextContainer;
