import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { Modal, SecondaryButton } from '../../components';

import css from './styles.css';

class InsertUserPasswordModal extends Component {
  render() {
    const {
      className,
      rootClassName,
      id,
      intl,
      isOpen,
      onOkModal,
      onCloseModal,
      onManageDisableScrolling,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const closeButtonMessage = intl.formatMessage({ id: 'Actions.cancel' });
    const passwordLabel = intl.formatMessage({
      id: 'ContactDetailsForm.passwordLabel',
    });
    const passwordPlaceholder = intl.formatMessage({
      id: 'ContactDetailsForm.passwordPlaceholder',
    });

    return (
      <Modal
        id={id}
        containerClassName={classes}
        contentClassName={css.modalContent}
        isOpen={isOpen}
        onClose={onCloseModal}
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={closeButtonMessage}
      >
        <p className={css.modalTitle}>
          <FormattedMessage id="InsertUserPasswordModal.title"/>
        </p>
        <div>
          <h3 className={css.confirmChangesTitle}>
            <FormattedMessage id="ContactDetailsForm.confirmChangesTitle"/>
          </h3>
          <p className={css.confirmChangesInfo}>
            <FormattedMessage id="ContactDetailsForm.confirmChangesInfo"/>
          </p>
          <label htmlFor={id}>
            {passwordLabel}
          </label>
          <input
            type="password"
            name="currentPassword"
            id={'currentPassword'}
            autoComplete="current-password"
            placeholder={passwordPlaceholder}
            ref={node => {
              this.input = node;
            }}
            onChange={this.onChange}
            required
          />
        </div>
        <SecondaryButton className={css.modalButtonOk} onClick={() => {
          onOkModal(this.input.value);
        }}>
          <FormattedMessage id="ProfileSettings.confirm"/>
        </SecondaryButton>
      </Modal>
    );
  }
};

InsertUserPasswordModal.defaultProps = {
  className: null,
  rootClassName: null,
  id: null,
  isOpen: false,
  onOkModal: Function.prototype,
  onCloseModal: Function.prototype,
};

InsertUserPasswordModal.propTypes = {
  className: string,
  rootClassName: string,
  id: string,
  isOpen: bool,
  onOkModal: func,
  onCloseModal: func,
  intl: intlShape.isRequired,
};

export default injectIntl(InsertUserPasswordModal);
