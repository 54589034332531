import React from 'react';
import { bool, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { path } from 'ramda';

import { propTypes } from '../../../util/types';
import { getGuestCountFromTx } from '../../../util/transactions';
import {
  AvatarMedium,
  BookingTimeInfo,
  UserDisplayName,
} from '../../../components';

import css from './BookingInfo.module.css';

const BookingInfo = (props) => {
  const {
    className,
    intl,
    isOrder,
    tx,
  } = props;
  const { customer, provider } = tx;
  const otherUser = isOrder ? provider : customer;
  const adultCount = path(['attributes', 'protectedData', 'quantity', 'adults'], tx);
  const childCount = path(['attributes', 'protectedData', 'quantity', 'children'], tx);
  const infantCount = path(['attributes', 'protectedData', 'quantity', 'infants'], tx);
  const guestCount = getGuestCountFromTx(tx);
  const adultsText = intl.formatMessage({ id: 'Marketplace.adultCount' }, { count: adultCount });
  const childrenText = intl.formatMessage({ id: 'Marketplace.childCount' }, { count: childCount });
  const infantsText = intl.formatMessage({ id: 'Marketplace.infantCount' }, { count: infantCount });
  const guestsStr = `${adultCount ? adultsText : ''}${childCount ? `, ${childrenText}` : ''}${infantCount ? `, ${infantsText}` : ''}`;
  const otherUserLabelText = intl.formatMessage({ id: `Marketplace.${isOrder ? 'provider' : 'customer'}` });
  const scheduleLabelText = intl.formatMessage({ id: 'ReservationsPage.BookingInfo.scheduleLabel' });
  const guestsLabelText = intl.formatMessage({ id: 'ReservationsPage.BookingInfo.guestsLabel' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <div className={css.infoContainer}>
        <div className={css.infoWrapper}>
          <span className={css.label}>{otherUserLabelText} :</span>
          <UserDisplayName className={css.infoText} user={otherUser} intl={intl} />
        </div>
        <div className={css.infoWrapper}>
          <span className={css.label}>{scheduleLabelText} :</span>
          <BookingTimeInfo bookingClassName={css.bookingTimeInfo} tx={tx} />
        </div>
        {guestCount && (
          <div className={css.infoWrapper}>
            <span className={css.label}>{guestsLabelText} :</span>
            <span className={css.infoText}>{guestsStr}</span>
          </div>
        )}
      </div>
      <AvatarMedium className={css.avatar} user={otherUser} />
    </div>
  )
}

BookingInfo.defaultProps = {
  className: null,
};

BookingInfo.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  isOrder: bool.isRequired,
  tx: propTypes.transaction.isRequired,
};

export default BookingInfo;