import React from 'react';

import { createSlug } from '../../util/urlHelpers';
import { NamedLink } from '../../components';

import AddressLinkMaybe from './AddressLinkMaybe';
import css from './TransactionPanel.module.css';

// Functional component as a helper to build detail card headings
const DetailCardHeadingsMaybe = props => {
  const {
    showDetailCardHeadings,
    listingTitle,
    location,
    geolocation,
    showAddress,
    listing,
  } = props;

  const slug = createSlug(listingTitle)
  const params = { id: listing.id.uuid, slug };

  return showDetailCardHeadings ? (
    <div className={css.detailCardHeadings}>
      <h2 className={css.detailCardTitle}>
        <NamedLink
          className={css.listingLink}
          name="ListingPage"
          params={params}
        >
          {listingTitle}
        </NamedLink>
      </h2>
      <p className={css.detailCardSubtitle}></p>
      <AddressLinkMaybe location={location} geolocation={geolocation} showAddress={showAddress} />
    </div>
  ) : null;
};

export default DetailCardHeadingsMaybe;
