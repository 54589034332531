import React from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import ReactTooltip from 'react-tooltip';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import { isBooleanString, stringToBoolean } from '../../util/data';
import { Switch, ValidationError } from '../../components';
import informationIcon from '../../assets/information.png';

import ButtonBoolean from './ButtonBoolean/ButtonBoolean';
import css from './FieldBoolean.css';



const FieldBooleanComponent = props => {
	const {
		rootClassName,
		className,
		id,
		input,
		label,
		customErrorText,
		informationTooltipContent,
		helper,
		variant,
		revertOrder,
		meta,
		intl,
		...rest
	} = props;

	if (label && !id) {
		throw new Error('id required when a label is given');
	}

	const handleClickSwitch = () => {
		if (isBooleanString(input.value)) {
			const valueAsBoolean = stringToBoolean(input.value);
			input.onChange(!valueAsBoolean);
		} else {
			input.onChange(!input.value);
		};
	};
	const handleTrueClick = () => {
		input.onChange(true);
	};
	const handleFalseClick = () => {
		input.onChange(false);
	};

	const stringifiedValue = stringToBoolean(input.value);
	const classes = classNames(rootClassName || css.root, className);
	const isButtonVariant = variant === 'button';
	const isSwitchVariant = variant === 'switch';
	const inputProps = {
		className: css.input,
		id,
		type: "text",
		...input,
		...rest
	};

	return (
		<div className={classes}>
			<div className={css.leftContainer}>
				<div className={css.labelContainer}>
					{label ? <label className={css.label} htmlFor={id}>{label}</label> : null}
					{helper ? <span className={css.helper}>{helper}</span> : null}
				</div>
				{informationTooltipContent && (
					<div className={css.information}>
						<a data-tip={informationTooltipContent}>
							<img src={informationIcon} className={css.informationIcon} />
						</a>
						<ReactTooltip place="top" type="info" effect="solid" className={css.informationTooltip} />
					</div>
				)}
			</div>
			<div className={css.boolean}>
				{isButtonVariant && (
					<ButtonBoolean
						value={stringifiedValue}
						onClickTrue={handleTrueClick}
						onClickFalse={handleFalseClick}
						revertOrder={revertOrder}
					/>)}
				{isSwitchVariant && (
					<Switch
						value={stringifiedValue}
						onClick={handleClickSwitch}
						highlight
					/>)}
				<input {...inputProps} />
			</div>
			<ValidationError fieldMeta={meta} />
		</div>
  )
};

FieldBooleanComponent.defaultProps = {
	rootClassName: null,
	className: null,
	customErrorText: null,
	id: null,
	label: null,
	informationTooltipContent: null,
	helper: null,
};
  
FieldBooleanComponent.propTypes = {
	rootClassName: string,
	className: string,

	// Error message that can be manually passed to input field,
	// overrides default validation message
	customErrorText: string,

	// Label is optional, but if it is given, an id is also required so
	// the label can reference the input in the `for` attribute
	id: string,
	label: string,
	helper: string,
	informationTooltipContent: string,

	// Generated by final-form's Field component
	input: shape({
			onChange: func.isRequired,
	}).isRequired,
	meta: object.isRequired,
};

const FieldBoolean = props => {
  return <Field component={FieldBooleanComponent} {...props} />;
};
  
export default injectIntl(FieldBoolean);
