import React, { useEffect, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { path } from 'ramda';

import { isSeason } from '../../../../util/dates';
import {
  ModalContent,
  ModalHeader,
  ModalBody,
} from '../../../../components/ModalSwimmy';
import {
  IconArrowHead,
  IconClose,
  LocationAutocompleteInput,
} from '../../../../components';

import css from './LocationView.module.css';

const LocationView = (props) => {
  const {
    className,
    dirty,
    formProps,
    formValues,
    handleView,
    intl,
    isSearchPage,
  } = props;
  const { reset } = formProps;
  const [locationInput, setLocationInput] = useState();
  const selectedPlace = path(['location', 'selectedPlace'], formValues);
  const handleReset = () => {
    if (isSearchPage) {
      formProps.change('location', undefined);
    } else {
      reset();
    }

    locationInput.focus();
  };

  useEffect(() => {
    if (!locationInput) return;

    locationInput.focus();
  }, [locationInput]);

  useEffect(() => {
    if (!selectedPlace || (!selectedPlace && dirty) || (selectedPlace && !dirty)) return;

    if (isSearchPage) {
      formProps.submit();
    } else {
      handleView(isSeason() ? 'DateView' : 'TypeView');
    }
  }, [selectedPlace]);

  const handlePrevious = () => {
    handleView();
  };
  const classes = classNames(css.modalContent, className);

  return (
    <ModalContent className={classes}>
      <ModalHeader className={css.modalHeader}>
        <button
          className={css.backButton}
          type="button"
          onClick={handlePrevious}
        >
          {isSearchPage ? (
            <IconClose />
          ) : (
            <IconArrowHead className={css.iconArrow} direction="left" />
          )}
        </button>
        <Field
          name="location"
          render={({ input, meta }) => {
            const { onChange, ...restInput } = input;
            const searchOnChange = value => onChange(value);
            const locationInput = { ...restInput, onChange: searchOnChange };

            return (
              <LocationAutocompleteInput
                className={css.inputRoot}
                iconClassName={css.iconLocation}
                inputClassName={css.input}
                predictionsClassName={css.predictions}
                predictionsListClassName={css.predictionsList}
                predictionsAttributionClassName={css.predictionsAttribution}
                placeholder={intl.formatMessage({ id: 'Search.LocationView.placeholder' })}
                inputRef={node => setLocationInput(node)}
                input={locationInput}
                meta={meta}
                permanentPredictions
              />
            );
          }}
        />
        <button className={css.resetButton} onClick={handleReset}>
          <IconClose className={css.iconClose} />
        </button>
      </ModalHeader>
      <ModalBody />
    </ModalContent>
  )
}

LocationView.defaultProps = {
  className: null,
  initialLocation: {},
};

LocationView.propTypes = {
  className: string,
  formProps: object.isRequired,
  formValues: object.isRequired,
  initialLocation: object,
  intl: intlShape.isRequired,
  isSearchPage: bool.isRequired,
  handleView: func.isRequired,
};

export default injectIntl(LocationView);
