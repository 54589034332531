import React, { Fragment } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { path, pathOr } from 'ramda';

import { propTypes } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink, ResponsiveImage } from '..';

import css from './ListingInfo.module.css';

const ListingInfo = (props) => {
  const {
    className,
    linkToListing,
    listing,
  } = props;
  const firstImage = pathOr([], ['images'], listing).length > 0 ? listing.images[0] : null;
  const title = path(['attributes', 'title'], listing);
  const classes = classNames(css.root, className);
  const listingInfo = (
    <Fragment>
      <ResponsiveImage
        className={css.responsiveImage}
        alt={title}
        image={firstImage}
        variants={['landscape-crop', 'landscape-crop2x']}
      />
      <span className={css.title}>{title}</span>
    </Fragment>
  );

  return (
    <div className={classes}>
      {linkToListing ? (
        <NamedLink
          name="ListingPage"
          params={{ id: listing.id.uuid, slug: createSlug(title) }}
        >
          {listingInfo}
        </NamedLink>
      ) : (
        listingInfo
      )}
    </div>
  )
}

ListingInfo.defaultProps = {
  className: null,
  linkToListing: false,
};

ListingInfo.propTypes = {
  className: string,
  linkToListing: bool,
  listing: propTypes.listing.isRequired,
};

export default ListingInfo;