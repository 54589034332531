import React from 'react';
import { node, string } from 'prop-types';

import css from './styles.css';
import informationIcon from '../../assets/information.png';
import ReactTooltip from 'react-tooltip';

const InformationTooltip = props => {
  const { contentText } = props;
  if (!contentText) return null;

  return (
    <div className={css.information}>
      <a data-tip={contentText} data-for="information-tooltip">
        <img src={informationIcon} alt='information-about-children' className={css.informationIcon}/>
      </a>
      <ReactTooltip multiline  id="information-tooltip" place="top" type="info" effect="solid" className={css.informationTooltip}/>
    </div>
  );
};

InformationTooltip.propTypes = {
  contentText: string.isRequired,
};

export default InformationTooltip;
