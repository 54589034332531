import { path, pathOr } from 'ramda';

import {
  ADULTS,
  BOOKING_DATE,
  CHILDREN,
  END_TIME,
  INFANTS,
  IS_FLEXIBLE,
  START_TIME
} from '../constants/booking';
import { moment } from '../util/dates';
import { getTimeAsObject } from '../util/time';

export const formatFormValuesAsBookingData = (formValues) => {
  const {
    bookingDate,
    startTime,
    endTime,
    adults = 1,
    children = 0,
    infants = 0,
  } = formValues;
  const startTimeAsObject = getTimeAsObject(startTime);
  const endTimeAsObject = getTimeAsObject(endTime === '00:00' ? '24:00' : endTime);

  return {
    bookingStart: moment(bookingDate).hours(startTimeAsObject.hours),
    bookingEnd: moment(bookingDate).hours(endTimeAsObject.hours),
    quantity: {
      adults,
      children,
      infants,
    },
    quantityHours: endTimeAsObject.hours - startTimeAsObject.hours,
  }
}

export const getInitialValuesFromSearch = (searchParams) => {
  const isFlexible = path([IS_FLEXIBLE], searchParams);
  const startTime = isFlexible ? undefined : pathOr(undefined, [START_TIME], searchParams);
  const endTime = isFlexible ? undefined : pathOr(undefined, [END_TIME], searchParams);

  return {
    bookingDate: pathOr(undefined, [BOOKING_DATE], searchParams),
    startTime: startTime,
    endTime: endTime,
    adults: pathOr(undefined, [ADULTS], searchParams) || 1,
    children: pathOr(undefined, [CHILDREN], searchParams),
    infants: pathOr(undefined, [INFANTS], searchParams),
  }
};
