import React from 'react';
import { func, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { SecondaryButton } from '../../components';

import css from './Error.module.css';

const Error = props => {
  const { rootClassName, className, buttonText, errorText, reloadFunction, intl } = props;
  const classes = classNames(rootClassName || css.root, className)

  return (
    <div className={classes}>
      <span className={css.text}>{errorText}</span>
      {reloadFunction && (
        <SecondaryButton className={css.button} onClick={reloadFunction}>
          {buttonText ? buttonText : intl.formatMessage({ id: 'Marketplace.retry' })}
        </SecondaryButton>
      )}
    </div>
  )
};

Error.defaultProps = {
  rootClassName: null,
  className: null,
  buttonText: null,
  reloadFunction: null,
};

Error.propTypes = {
  rootClassName: string,
  className: string,
  buttonText: string,
  errorText: string.isRequired,
  reloadFunction: func,
  int: intlShape.isRequired,
};



export default injectIntl(Error);
