import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './SectionTitle.module.css';

const SectionTitle = props => {
  const {
    className,
    title,
  } = props;
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <h1 className={css.title}>{title}</h1>
    </div>
  );
};

SectionTitle.defaultProps = {
  className: null,
}

SectionTitle.propTypes = {
  className: string,
  title: string.isRequired,
}

export default SectionTitle;
