import pick from 'lodash/pick';
import { find, path, reduce } from 'ramda';

import config from '../config';
import { withEveningBookingModeDate } from '../marketplace-custom-config';
import { isTransactionsTransitionInvalidTransition, storableError } from '../util/errors';
import {
  TRANSITION_ACCEPT,
  TRANSITION_DECLINE,
  TRANSITION_REFUND_BY_PROVIDER,
  TRANSITION_REFUND_BY_CUSTOMER,
  TRANSITION_CANCEL_BY_CUSTOMER,
  getReview1Transition,
  getReview2Transition,
  txIsEnquired,
  txIsInFirstReviewBy,
} from '../util/transactions';
import * as log from '../util/log';
import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
} from '../util/data';
import { getDisplayDates, moment } from '../util/dates';
import { changeStateTransactionTracking } from '../util/transactionTrackerHelpers';
import {
  acceptAction,
  bookingOpenedByCustomerEventType,
  bookingOpenedByProviderEventType,
  declineAction,
} from '../util/eventsHelpers/constants';
import { sendEventByAction, sendNewMessageEvent, sendOpenedEvent } from '../util/eventsHelpers';
import { maskContactDetails } from '../util/contactDetails';
import { sendDeclineBookingsOnSameTimeslot } from '../util/bookingsHelpers';
import { addMarketplaceEntities, getMarketplaceEntities } from '../ducks/marketplaceData.duck';
import { fetchMonthlyTimeSlots } from '../ducks/listing.duck';

const MESSAGES_PAGE_SIZE = 100;
const CUSTOMER = 'customer';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/transaction/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST = 'app/transaction/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/transaction/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/transaction/FETCH_TRANSACTION_ERROR';

export const FETCH_TRANSITIONS_REQUEST = 'app/transaction/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/transaction/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/transaction/FETCH_TRANSITIONS_ERROR';

export const ACCEPT_SALE_REQUEST = 'app/transaction/ACCEPT_SALE_REQUEST';
export const ACCEPT_SALE_SUCCESS = 'app/transaction/ACCEPT_SALE_SUCCESS';
export const ACCEPT_SALE_ERROR = 'app/transaction/ACCEPT_SALE_ERROR';

export const DECLINE_SALE_REQUEST = 'app/transaction/DECLINE_SALE_REQUEST';
export const DECLINE_SALE_SUCCESS = 'app/transaction/DECLINE_SALE_SUCCESS';
export const DECLINE_SALE_ERROR = 'app/transaction/DECLINE_SALE_ERROR';

export const CANCEL_BOOKING_REQUEST = 'app/transaction/CANCEL_BOOKING_REQUEST';
export const CANCEL_BOOKING_SUCCESS = 'app/transaction/CANCEL_BOOKING_SUCCESS';
export const CANCEL_BOOKING_ERROR = 'app/transaction/CANCEL_BOOKING_ERROR';

export const FETCH_MESSAGES_REQUEST = 'app/transaction/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/transaction/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/transaction/FETCH_MESSAGES_ERROR';

export const OPENED_ENQUIRY_REQUEST = 'app/transaction/OPENED_ENQUIRY_REQUEST';
export const OPENED_ENQUIRY_SUCCESS = 'app/transaction/OPENED_ENQUIRY_SUCCESS';
export const OPENED_ENQUIRY_ERROR = 'app/transaction/OPENED_ENQUIRY_ERROR';

export const NEW_MESSAGE_ENQUIRY_REQUEST = 'app/transaction/NEW_MESSAGE_ENQUIRY_REQUEST';
export const NEW_MESSAGE_ENQUIRY_SUCCESS = 'app/transaction/NEW_MESSAGE_ENQUIRY_SUCCESS';
export const NEW_MESSAGE_ENQUIRY_ERROR = 'app/transaction/NEW_MESSAGE_ENQUIRY_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/transaction/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/transaction/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/transaction/SEND_MESSAGE_ERROR';

export const SEND_REVIEW_REQUEST = 'app/transaction/SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS = 'app/transaction/SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_ERROR = 'app/transaction/SEND_REVIEW_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  acceptInProgress: false,
  acceptSaleError: null,
  declineInProgress: false,
  declineSaleError: null,
  cancelInProgress: false,
  cancelBookingError: null,
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  totalMessages: 0,
  totalMessagePages: 0,
  oldestMessagePageFetched: 0,
  messages: [],
  initialMessageFailedToTransaction: null,
  sendMessageInProgress: false,
  sendMessageError: null,
  sendReviewInProgress: false,
  sendReviewError: null,
  fetchTransitionsInProgress: false,
  fetchTransitionsError: null,
  processTransitions: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

    case FETCH_TRANSITIONS_REQUEST:
      return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
    case FETCH_TRANSITIONS_SUCCESS:
      return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
    case FETCH_TRANSITIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };

    case ACCEPT_SALE_REQUEST:
      return { ...state, acceptInProgress: true, acceptSaleError: null, declineSaleError: null };
    case ACCEPT_SALE_SUCCESS:
      return { ...state, acceptInProgress: false };
    case ACCEPT_SALE_ERROR:
      return { ...state, acceptInProgress: false, acceptSaleError: payload };

    case DECLINE_SALE_REQUEST:
      return { ...state, declineInProgress: true, declineSaleError: null, acceptSaleError: null };
    case DECLINE_SALE_SUCCESS:
      return { ...state, declineInProgress: false };
    case DECLINE_SALE_ERROR:
      return { ...state, declineInProgress: false, declineSaleError: payload };

    case CANCEL_BOOKING_REQUEST:
      return { ...state, cancelInProgress: true, cancelBookingError: null };
    case CANCEL_BOOKING_SUCCESS:
      return { ...state, cancelInProgress: false };
    case CANCEL_BOOKING_ERROR:
      return { ...state, cancelInProgress: false, cancelBookingError: payload };

    case FETCH_MESSAGES_REQUEST:
      return { ...state, fetchMessagesInProgress: true, fetchMessagesError: null };
    case FETCH_MESSAGES_SUCCESS: {
      const oldestMessagePageFetched =
        state.oldestMessagePageFetched > payload.page
          ? state.oldestMessagePageFetched
          : payload.page;
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: payload.messages,
        totalMessages: payload.totalItems,
        totalMessagePages: payload.totalPages,
        oldestMessagePageFetched,
      };
    }
    case FETCH_MESSAGES_ERROR:
      return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
        sendMessageError: null,
        initialMessageFailedToTransaction: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false };
    case SEND_MESSAGE_ERROR:
      return { ...state, sendMessageInProgress: false, sendMessageError: payload };

    case SEND_REVIEW_REQUEST:
      return { ...state, sendReviewInProgress: true, sendReviewError: null };
    case SEND_REVIEW_SUCCESS:
      return { ...state, sendReviewInProgress: false };
    case SEND_REVIEW_ERROR:
      return { ...state, sendReviewInProgress: false, sendReviewError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const acceptOrDeclineInProgress = state => {
  return state.transaction.acceptInProgress || state.transaction.declineInProgress;
};

export const acceptOrDeclineOrCancelInProgress = state => {
  return acceptOrDeclineInProgress(state) || state.transaction.cancelInProgress;
};

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
  type: FETCH_TRANSITIONS_SUCCESS,
  payload: response,
});
const fetchTransitionsError = e => ({ type: FETCH_TRANSITIONS_ERROR, error: true, payload: e });

const acceptSaleRequest = () => ({ type: ACCEPT_SALE_REQUEST });
const acceptSaleSuccess = () => ({ type: ACCEPT_SALE_SUCCESS });
const acceptSaleError = e => ({ type: ACCEPT_SALE_ERROR, error: true, payload: e });

const declineSaleRequest = () => ({ type: DECLINE_SALE_REQUEST });
const declineSaleSuccess = () => ({ type: DECLINE_SALE_SUCCESS });
const declineSaleError = e => ({ type: DECLINE_SALE_ERROR, error: true, payload: e });

const cancelBookingRequest = () => ({ type: CANCEL_BOOKING_REQUEST });
const cancelBookingSuccess = () => ({ type: CANCEL_BOOKING_SUCCESS });
const cancelBookingError = e => ({ type: CANCEL_BOOKING_ERROR, error: true, payload: e });

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (messages, pagination) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages, ...pagination },
});
const fetchMessagesError = e => ({ type: FETCH_MESSAGES_ERROR, error: true, payload: e });

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendMessageError = e => ({ type: SEND_MESSAGE_ERROR, error: true, payload: e });

const sendReviewRequest = () => ({ type: SEND_REVIEW_REQUEST });
const sendReviewSuccess = () => ({ type: SEND_REVIEW_SUCCESS });
const sendReviewError = e => ({ type: SEND_REVIEW_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const updateBookingOpenedEvent = (currentUserIsProvider, id) => async (dispatch, getState, sdk) => {
  try {
    if (!id) return;

    const eventType = currentUserIsProvider ? bookingOpenedByProviderEventType : bookingOpenedByCustomerEventType;
    await sendOpenedEvent(eventType, id);
  } catch (error) {
    console.log('er', error);
  }
};

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

const getCurrentState = getState => {
  return getState();
};

const getCurrentTx = state => {
  const txRef = state.transaction.transactionRef;
  const entities = getMarketplaceEntities(state, [txRef]);
  return entities[0];
};

export const fetchTransaction = (id, txRole, canUpdateOpeningEvent) => (dispatch, getState, sdk) => {
  dispatch(setInitialValues({}));

  dispatch(fetchTransactionRequest());
  let txResponse = null;

  return sdk.transactions
    .show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...IMAGE_VARIANTS,
      },
      { expand: true },
    )
    .then(response => {
      const responseWithEveningModeMaybe = withEveningModeMaybe(response);
      txResponse = responseWithEveningModeMaybe;
      const handleCanUpdateOpeningEvent = () => {
        if (!canUpdateOpeningEvent) return;

        const state = getCurrentState(getState);
        const currentUserId = path(['user', 'currentUser', 'id', 'uuid'], state);
        const providerId = path(['data', 'data', 'relationships', 'provider', 'data', 'id', 'uuid'], txResponse);
        const currentUserIsProvider = currentUserId === providerId;
        const hasNotBeenOpenedBy = path(['data', 'data', 'attributes', 'metadata', currentUserIsProvider ? 'hasNotBeenOpenedByProvider' : 'hasNotBeenOpenedByCustomer'], txResponse);
        if (!hasNotBeenOpenedBy) return;

        dispatch(updateBookingOpenedEvent(currentUserIsProvider, id.uuid));
      };
      handleCanUpdateOpeningEvent();
      const listingId = listingRelationship(responseWithEveningModeMaybe).id;
      const entities = updatedEntities({}, responseWithEveningModeMaybe.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];
      const transaction = denormalised[1];

      // Fetch time slots for transactions that are in enquired state
      const canFetchTimeslots =
        config.fetchAvailableTimeSlots &&
        transaction &&
        txIsEnquired(transaction);

      if (canFetchTimeslots) {
        fetchMonthlyTimeSlots(dispatch, listing, path(['attributes', 'protectedData', 'date'], transaction));
      }

      const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
      if (canFetchListing) {
        return sdk.listings.show({
          id: listingId,
          include: ['author', 'author.profileImage', 'images'],
          ...IMAGE_VARIANTS,
        });
      } else {
        return responseWithEveningModeMaybe;
      }
    })
    .then(response => {
      dispatch(addMarketplaceEntities(txResponse));
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionSuccess(txResponse));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

export const acceptSale = (txId, listingId) => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }

  const ownEntities = getState().ownListings.ownEntities;
  const address = path(['ownListing', path(['uuid'], listingId), 'attributes', 'privateData', 'location', 'address'], ownEntities);
  dispatch(acceptSaleRequest());

  return sdk.transactions
    .transition({
      id: txId,
      transition: TRANSITION_ACCEPT,
      params: {
        protectedData: {
          address,
        },
      } }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(acceptSaleSuccess());
      sendEventByAction(txId.uuid, acceptAction);
      sendDeclineBookingsOnSameTimeslot(txId.uuid);
      changeStateTransactionTracking(txId.uuid, 'accepted');
      return response;
    })
    .catch(e => {
      dispatch(acceptSaleError(storableError(e)));
      log.error(e, 'accept-sale-failed', {
        txId,
        transition: TRANSITION_ACCEPT,
      });
      throw e;
    });
};

export const declineSale = id => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(declineSaleRequest());

  return sdk.transactions
    .transition({ id, transition: TRANSITION_DECLINE, params: {} }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(declineSaleSuccess());
      changeStateTransactionTracking(id.uuid, 'declined');
      sendEventByAction(id.uuid, declineAction);
      return response;
    })
    .catch(e => {
      dispatch(declineSaleError(storableError(e)));
      log.error(e, 'reject-sale-failed', {
        txId: id,
        transition: TRANSITION_DECLINE,
      });
      throw e;
    });
};

const getCancelTransition = (actor, transactionIsAccepted) => {
  if (actor === 'provider') {
    return TRANSITION_REFUND_BY_PROVIDER;
  }

  return transactionIsAccepted ? TRANSITION_REFUND_BY_CUSTOMER : TRANSITION_CANCEL_BY_CUSTOMER;
};

export const cancelBooking = (id, actor, transactionIsAccepted) => (dispatch, getState, sdk) => {
  if (acceptOrDeclineOrCancelInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(cancelBookingRequest());
  const transition = getCancelTransition(actor, transactionIsAccepted);

  return sdk.transactions
    .transition({ id, transition, params: {} }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(cancelBookingSuccess());
      return response;
    })
    .catch(e => {
      dispatch(cancelBookingError(storableError(e)));
      log.error(e, 'cancel-booking-failed', {
        txId: id,
        transition,
      });
    });
};

export const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
  const paging = { page, per_page: MESSAGES_PAGE_SIZE };
  dispatch(fetchMessagesRequest());

  return sdk.messages
    .query({
      transaction_id: txId,
      include: ['sender', 'sender.profileImage'],
      ...IMAGE_VARIANTS,
      ...paging,
    })
    .then(response => {
      const messages = denormalisedResponseEntities(response);
      const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
      const pagination = { totalItems, totalPages, page: fetchedPage };
      const totalMessages = getState().transaction.totalMessages;

      // Original fetchMessages call succeeded
      dispatch(fetchMessagesSuccess(messages, pagination));

      // Check if totalItems has changed between fetched pagination pages
      // if totalItems has changed, fetch first page again to include new incoming messages.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      if (totalItems > totalMessages && page > 1) {
        dispatch(fetchMessages(txId, 1))
          .then(() => {
            // Original fetch was enough as a response for user action,
            // this just includes new incoming messages
          })
          .catch(() => {
            // Background update, no need to to do anything atm.
          });
      }
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

export const fetchMoreMessages = txId => (dispatch, getState, sdk) => {
  const state = getState();
  const { oldestMessagePageFetched, totalMessagePages } = state.transaction;
  const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

  // In case there're no more old pages left we default to fetching the current cursor position
  const nextPage = hasMoreOldMessages ? oldestMessagePageFetched + 1 : oldestMessagePageFetched;

  return dispatch(fetchMessages(txId, nextPage));
};

export const sendMessage = (txId, messageRaw) => (dispatch, getState, sdk) => {
  const message = maskContactDetails(messageRaw.trim());
  dispatch(sendMessageRequest());

  return sdk.messages
    .send({ transactionId: txId, content: message }, {
      expand: true,
      include: ['sender'],
    })
    .then(response => {
      const messageId = response.data.data.id;
      const messageSender = path(['data', 'data', 'relationships', 'sender', 'data', 'id', 'uuid'], response);
      const messageContent = path(['data', 'data', 'attributes', 'content'], response);
      sendNewMessageEvent(txId.uuid, messageSender, messageContent);

      const state = getCurrentState(getState);
      const tx = getCurrentTx(state);

      // We fetch the first page again to add sent message to the page data
      // and update possible incoming messages too.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      return dispatch(fetchMessages(txId, 1))
        .then(() => {
          dispatch(sendMessageSuccess());
          return messageId;
        })
        .catch(() => dispatch(sendMessageSuccess()));
    })
    .catch(e => {
      dispatch(sendMessageError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject'];
const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

// If other party has already sent a review, we need to make transition to
// TRANSITION_REVIEW_2_BY_<CUSTOMER/PROVIDER>
const sendReviewAsSecond = (tx, params, role, dispatch, sdk) => {
  const transition = getReview2Transition(role === CUSTOMER, tx);

  const include = REVIEW_TX_INCLUDES;
  const txId = tx.id;

  return sdk.transactions
    .transition({ id: txId, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));

      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

// If other party has not yet sent a review, we need to make transition to
// TRANSITION_REVIEW_1_BY_<CUSTOMER/PROVIDER>
// However, the other party might have made the review after previous data synch point.
// So, error is likely to happen and then we must try another state transition
// by calling sendReviewAsSecond().
const sendReviewAsFirst = (tx, params, role, dispatch, sdk) => {
  const transition = getReview1Transition(role === CUSTOMER, tx);
  const include = REVIEW_TX_INCLUDES;
  const txId = tx.id;

  return sdk.transactions
    .transition({ id: txId, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .catch(e => {
      // If transaction transition is invalid, lets try another endpoint.
      if (isTransactionsTransitionInvalidTransition(e)) {
        return sendReviewAsSecond(tx, params, role, dispatch, sdk);
      } else {
        dispatch(sendReviewError(storableError(e)));

        // Rethrow so the page can track whether the sending failed, and
        // keep the message in the form for a retry.
        throw e;
      }
    });
};

export const sendReview = (role, tx, reviewRating, reviewContent) => (dispatch, getState, sdk) => {
  const params = { reviewRating, reviewContent };

  const txStateOtherPartyFirst = txIsInFirstReviewBy(tx, role !== CUSTOMER);

  dispatch(sendReviewRequest());

  return txStateOtherPartyFirst
    ? sendReviewAsSecond(tx, params, role, dispatch, sdk)
    : sendReviewAsFirst(tx, params, role, dispatch, sdk);
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransitionsRequest());

  return sdk.processTransitions
    .query({ transactionId: id })
    .then(res => {
      dispatch(fetchTransitionsSuccess(res.data.data));
    })
    .catch(e => {
      dispatch(fetchTransitionsError(storableError(e)));
    });
};

export const withEveningModeMaybe = (response) => reduce((acc, curr) => {
  const { type } = curr;
  if (type !== 'booking') return {
    ...acc,
    data: {
      ...acc.data,
      included: [...acc.data.included, curr],
    },
  };

  const bookingAttributes = curr.attributes;
  const start = path(['start'], bookingAttributes);
  const end = path(['end'], bookingAttributes);
  const tx = find(tx => path(['relationships', 'booking', 'data', 'id', 'uuid'], tx) === curr.id.uuid, response.data.data);
  const txCreatedAt = path(['attributes', 'createdAt'], tx);
  const isEveningMode = start && moment(txCreatedAt).isAfter(withEveningBookingModeDate);
  const displayDateFromTx = {
    start: path(['data', 'data', 'attributes', 'protectedData', 'bookingStartDisplay'], acc),
    end: path(['data', 'data', 'attributes', 'protectedData', 'bookingEndDisplay'], acc),
  };
  const {
    start: displayStart,
    end: displayEnd,
  } = getDisplayDates(isEveningMode, displayDateFromTx, { start, end }, bookingAttributes.protectedData);
  const booking = {
    ...curr,
    attributes: {
      ...bookingAttributes,
      isEveningMode,
      displayStart,
      displayEnd,
    },
  };

  return {
    ...acc,
    data: {
      ...acc.data,
      included: [...acc.data.included, booking],
    },
  };
}, {
  ...response,
  data: {
    ...response.data,
    included: [],
  },
}, response.data.included);
