import React, { Fragment, useEffect } from 'react';
import { bool, object, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { path } from 'ramda';

import { DATE, LOCATION } from '../../../../util/search';
import {
  FieldResetButton,
  LocationAutocompleteInput,
} from '../../../../components';

import { blurAllFields } from '../../SearchDesktop.helpers';
import css from './LocationInput.module.css';

const LocationInput = (props) => {
  const {
    className,
    dirty,
    finalFormApi,
    formValues,
    inputRef,
    intl,
  } = props;
  const value = path(['location', 'selectedPlace', 'address'], formValues);
  useEffect(() => {
    const isValid = value && dirty;
    if (!isValid) return;
    
    finalFormApi.blur(LOCATION);
    inputRef.current.blur();
    finalFormApi.focus(DATE);
  }, [value]);
  const handleReset = () => {
    finalFormApi.change(LOCATION, undefined);
    inputRef.current.focus();
  };
  const labelText = intl.formatMessage({ id: 'SearchDesktop.LocationInput.label' });
  const placeholderText = intl.formatMessage({ id: 'SearchDesktop.LocationInput.placeholder' });
  const classes = classNames(css.root, className);
  const inputClasses = classNames(css.input,{
    [css.active]: value,
  });

  return (
    <Fragment>
      <Field
        name={LOCATION}
        render={({ input, meta }) => {
          const { onFocus, ...restInput } = input;
          const locationOnFocus = (e) => {
            blurAllFields(finalFormApi, LOCATION);
            onFocus(e);
          };
          const locationInput = { ...restInput, onFocus: locationOnFocus };

          return (
            <LocationAutocompleteInput
              className={classes}
              labelClassName={css.label}
              iconClassName={css.iconLocation}
              inputClassName={inputClasses}
              predictionsClassName={css.predictions}
              predictionsListClassName={css.predictionsList}
              predictionsAttributionClassName={css.predictionsAttribution}
              label={labelText}
              placeholder={placeholderText}
              inputRef={node => inputRef.current = node}
              input={locationInput}
              meta={meta}
            />
          )
        }}
      />
      {value && (
        <div className={css.resetContainer}>
          <FieldResetButton
            className={css.fieldResetButton}
            fieldName={LOCATION}
            finalFormApi={finalFormApi}
            onClick={handleReset}
          />
        </div>
      )}
    </Fragment>
  )
};

LocationInput.defaultProps = {
  className: null,
};

LocationInput.propTypes = {
  className: string,
  dirty: bool.isRequired,
  finalFormApi: object.isRequired,
  formValues: object.isRequired,
  inputRef: object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(LocationInput);