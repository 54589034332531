import { pathOr } from 'ramda';

import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

// ================ Action types ================ //

export const FETCH_FAVORITES_REQUEST = 'app/FavoritesPage/FETCH_FAVORITES_REQUEST';
export const FETCH_FAVORITES_SUCCESS = 'app/FavoritesPage/FETCH_FAVORITES_SUCCESS';
export const FETCH_FAVORITES_ERROR = 'app/FavoritesPage/FETCH_FAVORITES_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  fetchFavoritesInProgress: false,
  fetchFavoritesError: null,
  fetchFavoritesSuccess: false,
};

const favoritesReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_FAVORITES_REQUEST:
      return {
        ...state,
        fetchFavoritesInProgress: true,
        fetchFavoritesError: null,
        fetchFavoritesSuccess: false,
      };
    case FETCH_FAVORITES_SUCCESS:
      return {
        ...state,
        fetchFavoritesInProgress: false,
        fetchFavoritesSuccess: true,
      };
    case FETCH_FAVORITES_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchFavoritesInProgress: false,
        fetchFavoritesError: payload,
        fetchFavoritesSuccess: false,
      };

    default:
      return state;
  }
};

export default favoritesReducer;


// ================ Action creators ================ //

export const fetchFavoritesRequest = queryParams => ({
  type: FETCH_FAVORITES_REQUEST,
  payload: { queryParams },
});

export const fetchFavoritesSuccess = () => ({
  type: FETCH_FAVORITES_SUCCESS,
});

export const fetchFavoritesError = e => ({
  type: FETCH_FAVORITES_ERROR,
  error: true,
  payload: e,
});

export const fetchFavoriteListings = (myFavorites) => async (dispatch, getState, sdk) => {
  dispatch(fetchFavoritesRequest());

  return sdk.listings.query({
    ids: myFavorites,
    include: ['images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchFavoritesSuccess())
    })
    .catch(e => {
      dispatch(fetchFavoritesError(storableError(e)));
      throw e;
    })
};

export const loadData = () => (dispatch, getState, sdk) => {
  return Promise.all([dispatch(fetchCurrentUser())])
    .then(response => {
      const currentUser = getState().user.currentUser;
      const myFavorites = pathOr([], ['attributes', 'profile', 'protectedData', 'myFavorites'], currentUser);
      if (currentUser && myFavorites.length > 0) {
        dispatch(fetchFavoriteListings(myFavorites));
      }
      return response;
    })
    .catch(e => {
      throw e;
    });
};
