import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { path, pathOr } from 'ramda';

import config from '../../../../config';
import {
  convertMoneyToNumber,
  convertUnitToSubUnit,
  getCurrencySymbol,
  unitDivisor,
} from '../../../../util/currency';
import * as validators from '../../../../util/validators';
import { stringValuesToNumbers } from '../../../../util/data';
import { types as sdkTypes } from '../../../../util/sdkLoader';
import {
  FieldNumericStepper,
  FieldStepperEditable,
  Form,
  PrimaryButton,
} from '../../../../components';

import css from './BasePricingForm.module.css';

const { Money } = sdkTypes;

const BasePricingForm = (props) => {
  const {
    className,
    intl,
    isMobile,
    listing,
    onSubmit,
    updated,
    updateInProgress,
  } = props;
  const unitDivisorValue = unitDivisor(config.currency);

  const handleSubmit = (values) => {
    const valuesAsNumber = stringValuesToNumbers(values);
    const { minimumHoursPerBooking, price } = valuesAsNumber;
    const priceWithSubUnit = convertUnitToSubUnit(price, unitDivisorValue);
    const publicData = {
      minimumHoursPerBooking: minimumHoursPerBooking || 1,
    };
    const updatedValues = {
      price: new Money(priceWithSubUnit, config.currency),
      publicData,
    };
    onSubmit(updatedValues);
  };

  const minPriceValue = config.listingMinimumPriceSubUnits / unitDivisorValue;
  const currencySymbol = getCurrencySymbol(config.currencyConfig);
  const listingPrice = path(['attributes', 'price'], listing);
  const listingPublicData = pathOr({}, ['attributes', 'publicData'], listing);
  const { minimumHoursPerBooking, pricePerExtraGuest } = listingPublicData;
  const initialValues = {
    minimumHoursPerBooking: minimumHoursPerBooking || 1,
    price: listingPrice ? `${convertMoneyToNumber(listingPrice)}${currencySymbol}` : undefined,
    pricePerExtraGuest: pricePerExtraGuest ? `${pricePerExtraGuest / unitDivisorValue}${currencySymbol}` : `0${currencySymbol}`,
  };
  const priceLabelText = intl.formatMessage({ id : `EditListingPricingPanel.BasePricingForm.priceLabel${isMobile ? '' : 'Desktop'}` });
  const minimumHoursPerBookingLabelText = intl.formatMessage({ id : 'EditListingPricingPanel.BasePricingForm.minimumHoursPerBookingLabel' });
  const submitText = intl.formatMessage({ id : 'Marketplace.save' });
  const minPriceErrorText = intl.formatMessage(
    { id: 'EditListingPricingPanel.BasePricingForm.minPriceError' },
    { amount: minPriceValue, currency: currencySymbol },
  );
  const priceValidators = validators.minNumberAsString(2, minPriceErrorText);
  const classes = classNames(css.root, className);

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={formRenderProps => {
        const {
          handleSubmit,
          invalid,
          pristine,
        } = formRenderProps;
        const submitReady = (updated && pristine);
        const submitDisabled = invalid || pristine || updateInProgress;

        return (
          <Form className={classes} id="basePricingForm" onSubmit={handleSubmit}>
            <div className={css.inputContainer}>
              <FieldStepperEditable
                className={css.input}
                name="price"
                initialValue={initialValues.price}
                label={priceLabelText}
                min={minPriceValue}
                placeholder={`00${currencySymbol}`}
                unit={currencySymbol}
                validate={priceValidators}
                withButtons={isMobile}
              />
              <FieldNumericStepper
                className={css.input}
                max={8}
                min={1}
                id="minimumHoursPerBooking"
                name="minimumHoursPerBooking"
                label={minimumHoursPerBookingLabelText}
              />
            </div>
            <div className={css.buttonContainer}>
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                form="basePricingForm"
                inProgress={updateInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                <span>{submitText}</span>
              </PrimaryButton>
            </div>
          </Form> 
        )
      }}
    />
  )
}

BasePricingForm.defaultProps = {
  className: null,
  listing: null,
};

BasePricingForm.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  isMobile: bool.isRequired,
  listing: object,
  onSubmit: func.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
};

export default BasePricingForm;