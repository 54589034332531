import { parse } from '../../util/urlHelpers';
import { queryOwnListings } from '../../ducks/ownListings.duck';

export const loadData = (params, search) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  return queryOwnListings({
    ...queryParams,
    page,
    perPage: 100,
    include: ['images'],
    'fields.image': ['variants.square-small'],
    'limit.images': 1,
  });
};
