import React from 'react';
import { bool, number, shape } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { map, pathOr } from 'ramda';
import classNames from 'classnames';

import config from '../../config';
import { withViewport } from '../../util/contextHelpers';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { getMissingInfos } from '../../util/user';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '../../containers';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  IconProfile,
  IconPersonalInfo,
  IconPayment,
  IconPrivate,
  IconSecurity,
} from '../../components';

import AccountItem from './components/AccountItem/AccountItem';
import MissingInfos from './components/MissingInfos/MissingInfos';
import css from './AccountPage.module.css';

const MOBILE_MAX_WIDTH = 767;

const AccountPage = (props) => {
  const {
    currentUser,
    currentUserHasPublishedListings,
    currentUserLoading,
    currentUserShowError,
    viewport,
    scrollingDisabled,
    intl,
  } = props;
  const userLoaded = !currentUserLoading && !currentUserShowError && currentUser;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const firstName = pathOr("", ['attributes', 'profile', 'firstName'], ensuredCurrentUser);
  const lastName = pathOr("", ['attributes', 'profile', 'lastName'], ensuredCurrentUser);
  const email = pathOr("", ['attributes', 'email'], ensuredCurrentUser);
  const isMobile = viewport.width <= MOBILE_MAX_WIDTH;
  const missingInfos = getMissingInfos(ensuredCurrentUser);
  const accountItems = [
    {
      icon: <IconProfile />,
      title: intl.formatMessage({ id: 'AccountPage.profileTitle' }),
      description: intl.formatMessage({ id: 'AccountPage.profileDescription' }),
      linkProps: { name: 'AccountProfilePage' },
    },
    {
      icon: <IconPersonalInfo />,
      title: intl.formatMessage({ id: 'AccountPage.personalInformationsTitle' }),
      description: intl.formatMessage({ id: 'AccountPage.personalInformationsDescription' }),
      linkProps: { name: 'AccountPersonalInfosPage' },
    },
    {
      icon: <IconPayment />,
      title: intl.formatMessage({ id: 'AccountPage.paymentTitle' }),
      description: intl.formatMessage({ id: 'AccountPage.paymentDescription' }),
      linkProps: { name: currentUserHasPublishedListings ? 'AccountPayoutDetailsPage' : 'AccountPaymentMethodsPage' },
    },
    {
      icon: <IconSecurity />,
      title: intl.formatMessage({ id: 'AccountPage.securityTitle' }),
      description: intl.formatMessage({ id: 'AccountPage.securityDescription' }),
      linkProps: { name: 'AccountSecurityPage' },
    },
    {
      icon: <IconPrivate />,
      title: intl.formatMessage({ id: 'AccountPage.privacyTitle' }),
      description: intl.formatMessage({ id: 'AccountPage.privacyDescription' }),
      linkProps: { name: 'AccountPrivacyPage' },
    },
  ];
  const pageTitle = intl.formatMessage({ id: 'AccountPage.pageTitle' });
  const phoneNumber = config.custom.supportPhoneNumber;
  const phoneNumberReadable = config.custom.supportPhoneNumberReadable;
  const mailContact = config.custom.mailContact;
  const headingText = intl.formatMessage({ id : 'AccountPage.heading' });
  const helpMessage = intl.formatMessage({ id : 'AccountPage.helpMessage' });
  const helpPhoneNumber = intl.formatMessage({ id : 'AccountPage.helpPhoneNumber' });
  const helpEmail = intl.formatMessage({ id : 'AccountPage.helpEmail' });
  const mainSubtitleClasses = classNames(css.mainSubtitleContainer, {
    [css.mainSubtitleHidden]: !userLoaded,
  })

  return (
    <Page title={pageTitle} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage='AccountPage' />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.header}>
            <div className={css.headerContent}>
              <h1 className={css.mainTitle}>{headingText}</h1>
              <div className={mainSubtitleClasses}>
                <span className={css.username}>{`${firstName} ${lastName}`}</span>
                <span className={css.email}>, {email}</span>
              </div>
            </div>
          </div>
          <div className={css.content}>
            {userLoaded && missingInfos && <MissingInfos className={css.missingInfos} missingInfos={missingInfos} intl={intl} />}
            <div className={css.cardWrapper}>
              {map((itemProps) => (
                <AccountItem key={itemProps.title} className={css.item} isMobile={isMobile} {...itemProps} />
              ), accountItems)}
            </div>
          </div>
          <div className={css.helpContainer}>
            <span>{helpMessage}</span>
            <span className={css.helpPhoneNumber}>{helpPhoneNumber} <a href={`tel:${phoneNumber}`}>{phoneNumberReadable}</a></span>
            <span className={css.helpEmail}>{helpEmail} <a href={`mailto:${mailContact}`}>{mailContact}</a></span>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          {!isMobile && (
            <Footer />
          )}
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

AccountPage.defaultProps = {
  currentUser: null,
}

AccountPage.propTypes = {
  currentUser: propTypes.currentUser,
  currentUserHasPublishedListings: bool.isRequired,
  currentUserLoading: bool.isRequired,
  currentUserShowError: propTypes.error,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserHasPublishedListings,
    currentUserLoading,
    currentUserShowError,
  } = state.user;

  return {
    currentUser,
    currentUserHasPublishedListings,
    currentUserLoading,
    currentUserShowError,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

export default compose(connect(mapStateToProps), withViewport, injectIntl)(AccountPage);
