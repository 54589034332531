import React, { Component, Fragment } from 'react';
import { func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Button, ModalSwimmy } from '../../components';
import css from './SearchFiltersSecondary.css';

class SearchFiltersSecondaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { currentQueryParams: props.urlQueryParams };

    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { applyFilters, onClose } = this.props;

    if (applyFilters) {
      applyFilters();
    }

    // Ensure that panel closes (if now changes have been made)
    onClose();
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    const { cancelFilters } = this.props;

    if (cancelFilters) {
      cancelFilters();
    }

    this.props.onClose();
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { resetAll, onClose } = this.props;

    if (resetAll) {
      resetAll(e);
    }

    // Ensure that panel closes (if now changes have been made)
    onClose();

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  render() {
    const {
      rootClassName,
      className,
      isOpen,
      onClose,
      children,
      onManageDisableScrolling,
      intl
    } = this.props;
    const heading = intl.formatMessage({ id: 'SearchFiltersSecondary.heading' });
    const classes = classNames(rootClassName || css.root, className);

    return (
      <ModalSwimmy
        id="SearchFiltersSecondary"
        isOpen={isOpen}
        onClose={onClose}
        onManageDisableScrolling={onManageDisableScrolling}
        heading={heading}
        content={(
          <div className={classes}>
            <div className={css.filtersWrapper}>{children}</div>
          </div>
        )}
        footer={(
          <Fragment>
            <Button rootClassName={css.resetAllButton} onClick={this.resetAll}>
              <FormattedMessage id={'SearchFiltersSecondary.resetAll'} />
            </Button>
            <Button rootClassName={css.cancelButton} onClick={this.cancelFilters}>
              <FormattedMessage id={'SearchFiltersSecondary.cancel'} />
            </Button>
            <Button rootClassName={css.applyButton} onClick={this.applyFilters}>
              <FormattedMessage id={'SearchFiltersSecondary.apply'} />
            </Button>
          </Fragment>
        )}
      />
    );
  }
}

SearchFiltersSecondaryComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

SearchFiltersSecondaryComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  applyFilters: func.isRequired,
  resetAll: func.isRequired,
  onClose: func.isRequired,
};

const SearchFiltersSecondary = SearchFiltersSecondaryComponent;

export default injectIntl(SearchFiltersSecondary);
