import React from 'react';
import classNames from 'classnames';

import css from './styles.css';

const Loader = (props) => {
  const { light } = props;

  const classes = classNames(css.ldsFacebook, { [css.lightLoader]: light });

  return (
    <div className={classes}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
};


export default Loader;
