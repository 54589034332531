import React, { Fragment } from 'react';
import { bool, func, object, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { path } from 'ramda';
import { Form as FinalForm, FormSpy } from 'react-final-form';

import { BOOKING_DATE, END_TIME, START_TIME } from '../../../constants/booking';
import { propTypes } from '../../../util/types';
import {
  DiscountBanner,
  FieldDateAndTimeInput,
  FieldParticipants,
  Form,
  PrimaryButton,
} from '../../../components';

import css from './BookingForm.module.css';

const BookingForm = (props) => {
  const {
    className,
    exposeFormValues,
    formattedPrice,
    initialValues,
    intl,
    isOwnListing,
    listingId,
    monthlyTimeSlots,
    onFetchTimeSlots,
    onSubmit,
    publicData,
    timeZone,
  } = props;
  const {
    enfantsBienvenue: childrenAllowed,
    maxCapacityToGuest = 0,
    minimumHoursPerBooking = 1,
    pricingExtraHours,
  } = publicData;
  const discountText = intl.formatMessage({ id: 'BookingPanel.BookingForm.discount' }, { percentage: path(['variation'], pricingExtraHours) });
  const submitText = intl.formatMessage(
    { id: `BookingPanel.BookingForm.${formattedPrice ? 'submitWithPrice' : 'submitWithoutPrice'}` },
    {...formattedPrice && { price: formattedPrice }});
  const classes = classNames(css.root, className);

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values)}
      render={formRenderProps => {
        const {
          active,
          form,
          handleSubmit,
          values
        } = formRenderProps;
        const { bookingDate, startTime, endTime, adults, children } = values;
        const canSubmit = bookingDate && startTime && endTime && adults >= 1;
        const handleClickWithoutValues = (e) => {
          if (!bookingDate) {
            form.focus(BOOKING_DATE)
          } else if (!startTime) {
            form.focus(START_TIME)
          } else if (!endTime) {
            form.focus(END_TIME)
          }
        }

        return (
          <Fragment>
            <Form className={classes} id="bookingForm" onSubmit={handleSubmit}>
              <FormSpy
                subscription={{ values: true }}
                onChange={(state) => exposeFormValues(state.values)}
              />
              {monthlyTimeSlots && timeZone ? (
                <div className={css.dateTimeContainer}>
                  <FieldDateAndTimeInput
                    className={css.fieldDateAndTimeInput}
                    activeField={active}
                    finalFormApi={form}
                    formValues={values}
                    listingId={listingId}
                    minimumHoursPerBooking={minimumHoursPerBooking}
                    monthlyTimeSlots={monthlyTimeSlots}
                    onFetchTimeSlots={onFetchTimeSlots}
                    timeZone={timeZone}
                  />
                  {pricingExtraHours && <DiscountBanner className={css.discountBanner} text={discountText} />}
                </div>
              ) : null}
              <FieldParticipants
                className={css.fieldParticipants}
                adultsValue={adults}
                childrenAllowed={childrenAllowed}
                childrenValue={children}
                maxParticipants={maxCapacityToGuest}
              />
              <div className={css.submitButtonContainer}>
                <PrimaryButton
                  className={css.submitButton}
                  type={canSubmit ? 'submit' : 'button'}
                  {...!canSubmit && { onClick: handleClickWithoutValues }}
                  disabled={isOwnListing}
                >
                  {submitText}
                </PrimaryButton>
              </div>
            </Form>
          </Fragment>
        )
      }}
    />
  )
}

BookingForm.defaultProps = {
  className: null,
  formattedPrice: null,
  initialValues: null,
  isOwnListing: false,
  listingId: null,
  monthlyTimeSlots: null,
};

BookingForm.propTypes = {
  className: string,
  exposeFormValues: func.isRequired,
  formattedPrice: string,
  initialValues: object,
  intl: intlShape.isRequired,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  onSubmit: func.isRequired,
  publicData: object.isRequired,
};

export default BookingForm;