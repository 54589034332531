import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import { IconArrowHead, NamedLink } from '../../../../components';

import css from './AccountItem.module.css';

const AccountItem = (props) => {
  const { className, isMobile, icon, title, description, linkProps } = props;
  const classes = classNames(css.link, className);

  return (
    <NamedLink className={classes} {...linkProps}>
      <div className={css.container}>
        <div className={css.textContainer}>
          <div className={css.titleContainer}>
            <span className={css.title}>{title}</span>
            {!isMobile && <IconArrowHead className={css.iconArrow} direction="right" />}
          </div>
          {!isMobile && <span className={css.description}>{description}</span>}
        </div>
        <div className={css.icons}>
          {icon}
        </div>
      </div>
    </NamedLink>
  );
};

AccountItem.defaultProps = {
  className: null,
};

AccountItem.propTypes = {
  className: string,
  isMobile: bool,
};

export default AccountItem;