import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconCall.module.css';

const IconCall = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M19.95 21Q16.725 21 13.663 19.562Q10.6 18.125 8.238 15.762Q5.875 13.4 4.438 10.337Q3 7.275 3 4.05Q3 3.6 3.3 3.3Q3.6 3 4.05 3H8.1Q8.45 3 8.725 3.225Q9 3.45 9.05 3.8L9.7 7.3Q9.75 7.65 9.688 7.937Q9.625 8.225 9.4 8.45L6.975 10.9Q8.025 12.7 9.613 14.275Q11.2 15.85 13.1 17L15.45 14.65Q15.675 14.425 16.038 14.312Q16.4 14.2 16.75 14.25L20.2 14.95Q20.55 15.025 20.775 15.287Q21 15.55 21 15.9V19.95Q21 20.4 20.7 20.7Q20.4 21 19.95 21ZM6.025 9 7.675 7.35Q7.675 7.35 7.675 7.35Q7.675 7.35 7.675 7.35L7.25 5Q7.25 5 7.25 5Q7.25 5 7.25 5H5.025Q5.025 5 5.025 5Q5.025 5 5.025 5Q5.15 6.025 5.375 7.025Q5.6 8.025 6.025 9ZM19 18.95Q19 18.95 19 18.95Q19 18.95 19 18.95V16.75Q19 16.75 19 16.75Q19 16.75 19 16.75L16.65 16.275Q16.65 16.275 16.65 16.275Q16.65 16.275 16.65 16.275L14.975 17.95Q15.95 18.375 16.963 18.625Q17.975 18.875 19 18.95ZM15 17.925Q15 17.925 15 17.925Q15 17.925 15 17.925Q15 17.925 15 17.925Q15 17.925 15 17.925Q15 17.925 15 17.925Q15 17.925 15 17.925Q15 17.925 15 17.925Q15 17.925 15 17.925ZM6.025 9Q6.025 9 6.025 9Q6.025 9 6.025 9Q6.025 9 6.025 9Q6.025 9 6.025 9Q6.025 9 6.025 9Q6.025 9 6.025 9Q6.025 9 6.025 9Q6.025 9 6.025 9Z"/>
    </svg>
  );
};

IconCall.defaultProps = {
  rootClassName: null,
  className: null,
};

IconCall.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCall;
