import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { pathOr } from 'ramda';

import { withViewport } from '../../util/contextHelpers';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { SlideAnimation } from '../../components/Animations';

import { loadData } from './InboxPage.duck';
import ActivityFeedSection from './components/ActivityFeedSection/ActivityFeedSection';
import DetailsSection from './components/DetailsSection/DetailsSection';
import TransactionsSection from './components/TransactionsSection/TransactionsSection';
import css from './InboxPage.module.css';

const MAX_MOBILE_WIDTH = 767;
const MAX_TABLET_WIDTH = 1279;

const InboxPage = props => {
  const {
    currentUser,
    scrollingDisabled,
    onUpdateProfile,
    viewport,
    intl,
  } = props;
  const [showActivityFeed, setShowActivityFeed] = useState(true);
  const [showDetails, setShowDetails] = useState(true);
  const params = useParams();
  const { width } = viewport;
  const hasViewport = width > 0;
  const isMobile = hasViewport && width <= MAX_MOBILE_WIDTH;
  const isTablet = hasViewport && width > MAX_MOBILE_WIDTH && width <= MAX_TABLET_WIDTH;
  const hasOpenedPanel = showActivityFeed || showDetails;
  const emailVerified = pathOr(false, ['attributes', 'emailVerified'],currentUser);

  useEffect(() => {
    if (params.id && isMobile) {
      setShowActivityFeed(true);
      setShowDetails(false);
    } else if (isMobile) {
      setShowActivityFeed(false);
      setShowDetails(false);
    } else if (isTablet) {
      setShowActivityFeed(true);
      setShowDetails(false);
    } else {
      setShowActivityFeed(true);
      setShowDetails(true);
    }
  }, [viewport.width]);

  useEffect(() => {
    if (isMobile && !params.id) {
      setShowActivityFeed(false);
      setShowDetails(false);
    }
  }, [params.id]);

  useEffect(() => {
    onUpdateProfile({ privateData: { messagesBadge: 0 } });
  }, []);

  const title = intl.formatMessage({ id: 'InboxPage.title' });
  const layoutWrapperTopbarClasses = emailVerified || hasOpenedPanel ? css.layoutWrapperTopbar : '';

  return (
    <Page scrollingDisabled={scrollingDisabled} title={title}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar className={layoutWrapperTopbarClasses}>
          <TopbarContainer currentPage="InboxPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <TransactionsSection
            className={css.transactionsSection}
            currentUser={currentUser}
            hasViewport={hasViewport}
            isMobile={isMobile}
            setShowActivityFeed={setShowActivityFeed}
          />
          <SlideAnimation inProps={showActivityFeed} from="right" mountOnEnter>
            <ActivityFeedSection
              className={css.activityFeedSection}
              currentUser={currentUser}
              isMobile={isMobile}
              isTablet={isTablet}
              setShowDetails={setShowDetails}
              showDetails={showDetails}
            />
          </SlideAnimation>
          <SlideAnimation inProps={showDetails} from="right" mountOnEnter>
            <DetailsSection
              className={css.detailsSection}
              currentUser={currentUser}
              isMobile={isMobile}
              setShowDetails={setShowDetails}
            />
          </SlideAnimation>
        </LayoutWrapperMain>
      </LayoutSingleColumn>
    </Page>
  )
};

InboxPage.defaultProps = {};

InboxPage.propTypes = {
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateProfile: (...data) => dispatch(updateProfile(...data)),
  };
};

InboxPage.loadData = loadData;

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withViewport,
  injectIntl,
)(InboxPage);
