import React from 'react';
import { object, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';

import config from '../../../../config';
import { formatMoney } from '../../../../util/currency';
import { types as sdkTypes } from '../../../../util/sdkLoader';
import {
  IconBarbecue,
  IconExtraCustom,
  IconSnack,
  IconTowel,
} from '../../../../components/Icons';

import css from './ExtraItem.module.css';

const { Money } = sdkTypes;
const currency = config.currency;

const ExtraItem = (props) => {
  const {
    className,
    extraData,
    intl,
    onClick,
  } = props;
  const { amount, id, information, isByUnit, label } = extraData;
  const amountAsMoney = new Money(amount, currency);
  const formattedPrice = formatMoney(intl, amountAsMoney);
  const perUnitText = intl.formatMessage({ id: 'CheckoutExtraPage.ExtraPanel.perUnit' });
  const classes = classNames(css.root, className);
  const icon = () => {
    switch (id) {
      case 'barbecueFee':
        return <IconBarbecue className={css.icon} />
      case 'towel':
        return <IconTowel className={css.icon} />
      case 'snack':
        return <IconSnack className={css.icon} />

      default:
        return <IconExtraCustom className={css.icon} />
    }
  }

  return (
    <div className={classes}>
      <div className={css.iconContainer}>
        {icon()}
      </div>
      <div className={css.infoContainer}>
        <div className={css.labelWrapper}>
          <span className={css.label}>{label}</span>
        </div>
        {information && <span className={css.information}>{information}</span>}
        <div className={css.priceWrapper}>
          <span className={css.price}>{formattedPrice}</span>
          {isByUnit && <span className={css.perUnit}>{perUnitText}</span>}
        </div>
      </div>
    </div>
  )
}

ExtraItem.defaultProps = {
  className: null,
};

ExtraItem.propTypes = {
  className: string,
  extraData: object.isRequired,
  intl: intlShape.isRequired,
};

export default ExtraItem;
