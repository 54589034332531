import React, { useEffect, useState } from 'react';
import { bool, func, node, number, object, string } from 'prop-types';
import classNames from 'classnames';
import {
  DayPickerSingleDateController,
} from 'react-dates';

import { LEFT, RIGHT } from '../../../../constants/ui';
import { withViewport } from '../../../../util/contextHelpers';
import { getMonthStartInTimeZone, moment } from '../../../../util/dates';
import { useKeyPress } from '../../../../hooks';
import { IconArrowNavigation } from '../../../../components/Icons';

import { isOutsideRange } from '../../CalendarPage.helpers';
import DayContent from './DayContent/DayContent';
import css from './Calendar.module.css';

const Nav = (props) => {
  const { direction, disabled } = props;
  const classes = classNames(css.navButton, {
    [css.previous]: direction === LEFT,
    [css.next]: direction === RIGHT,
    [css.disabled]: disabled
  })

  return (
    <button className={classes} disabled={disabled}>
      <IconArrowNavigation className={css.iconArrow} direction={direction} />
    </button>
  )
}

const propTypes = {
  initialDate: object,

  keepOpenOnDateSelect: bool,
  isOutsideRange: func,
  isDayBlocked: func,
  isDayHighlighted: func,

  // DayPicker props
  enableOutsideDays: bool,
  numberOfMonths: number,
  withPortal: bool,
  initialVisibleMonth: func,
  renderCalendarInfo: func,

  navPrev: node,
  navNext: node,
  renderNavPrevButton: func,
  renderNavNextButton: func,

  onPrevMonthClick: func,
  onNextMonthClick: func,
  onOutsideClick: func,
  renderCalendarDay: func,
  renderDayContents: func,

  // i18n
  monthFormat: string,
  monthFormat: string,

  isRTL: bool,
};

const defaultProps = {
  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: (day) => <DayContent day={day} />,
  isDayBlocked: () => false,
  isOutsideRange,
  isDayHighlighted: () => false,
  enableOutsideDays: false,

  // calendar presentation and interaction related props
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 1,
  onOutsideClick() {},
  keepOpenOnDateSelect: true,
  renderCalendarInfo: null,
  isRTL: false,
  hideKeyboardShortcutsPanel: true,

  // navigation related props
  navPrev: <Nav direction={LEFT} />,
  navNext: <Nav direction={RIGHT} />,
  renderNavPrevButton: null,
  renderNavNextButton: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // internationalization
  monthFormat: 'MMMM YYYY',
  weekDayFormat: 'dddd',
};

const Calendar = (props) => {
  const {
    className,
    availabilityExceptions,
    availabilityPlan,
    dates,
    intl,
    listingId,
    onChange,
    onFetchAvailabilityExceptions,
    transactions,
    viewport,
    ...rest
  } = props;
  const [currentMonth, setCurrentMonth] = useState(getMonthStartInTimeZone(new Date()));
  const ctrlPressed = useKeyPress('Control');
  const cmdPressed = useKeyPress('Meta');
  const handleDateChange = (date) => {
    const wasPreviouslyPicked = dates.some((d) => d.isSame(date));
    if (wasPreviouslyPicked) {
      onChange((previousDates) =>
          previousDates.filter((d) => !d.isSame(date))
      );
    } else {
      onChange((previousDates) => ctrlPressed || cmdPressed ? [...previousDates, date] : [date]);
    }
  };
  const onNextMonthClick = () => {
    onChange([]);
    const start = moment(currentMonth).add(1, 'months').isBefore(moment())
      ? moment().startOf('day').toISOString()
      : moment(currentMonth).add(1, 'months').toISOString();
    const queryParams = {
      listingId,
      start,
      end: moment(currentMonth).add(2, 'months').toISOString()
    };
    setCurrentMonth(prev => moment(prev).add(1, 'months'));
    onFetchAvailabilityExceptions(queryParams);
  };
  const onPrevMonthClick = () => {
    onChange([]);
    const start = moment(currentMonth).subtract(1, 'months').isBefore(moment())
      ? moment().startOf('day').toISOString()
      : moment(currentMonth).subtract(1, 'months').toISOString();
    const queryParams = {
      listingId,
      start,
      end: moment(currentMonth).toISOString()
    };
    setCurrentMonth(prev => moment(prev).subtract(1, 'months'));
    onFetchAvailabilityExceptions(queryParams);
  };
  const viewportWidth = viewport ? viewport.width : 1280;
  const isMobile = viewportWidth < 1024;
  const isTablet = viewportWidth >= 1024 && viewportWidth < 1280;
  const daySize = Math.floor(isMobile ? (viewportWidth - 48) / 7 : 88);
  const classes = classNames(css.root, className);
  
  return (
    <div className={classes}>
      <DayPickerSingleDateController
        {...rest}
        onDateChange={handleDateChange}
        focused={true}
        date={null}
        daySize={daySize >= 0 ? daySize : 0}
        isDayHighlighted={(day) => dates.some((d) => d.isSame(day, "day"))}
        navNext={<Nav direction={RIGHT} disabled={moment().add(364, 'days').isSame(currentMonth, 'month')} />}
        navPrev={<Nav direction={LEFT} disabled={moment().isSame(currentMonth, 'month')} />}
        onNextMonthClick={onNextMonthClick}
        onPrevMonthClick={onPrevMonthClick}
        renderDayContents={(day) => (
          <DayContent
            availabilityExceptions={availabilityExceptions}
            availabilityPlan={availabilityPlan}
            day={day}
            transactions={transactions}
          />
        )}
        weekDayFormat={(isMobile || isTablet) ? 'dd' : 'dddd'}
      />
    </div>
  )
}

Calendar.propTypes = propTypes;
Calendar.defaultProps = defaultProps;

export default withViewport(Calendar);