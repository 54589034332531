import React, { Fragment, useState } from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { pathOr } from 'ramda';

import { ensureCurrentUser } from '../../../../util/data';
import { propTypes } from '../../../../util/types';
import { isScrollingDisabled } from '../../../../ducks/UI.duck';
import { stripeAccountClearError } from '../../../../ducks/stripeConnectAccount.duck';
import { PayoutDetailsForm } from '../../../../forms';

import { savePayoutDetails } from './PayoutDetailsTab.duck';
import css from './PayoutDetailsTab.module.css';

const PayoutDetailsTab = props => {
  const {
    currentUser,
    createStripeAccountError,
    onPayoutDetailsFormChange,
    onPayoutDetailsFormSubmit,
    payoutDetailsSaveInProgress,
    payoutDetailsSaved,
    showBirthdayDateField,
    intl,
  } = props;

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!ensuredCurrentUser.id;
  const { stripeConnected } = ensuredCurrentUser.attributes;
  const [cniFormData, setCniFormData] = useState(null);
  const [proofOfAddressFormData, setProofOfAddressFormData] = useState(null);
  const formDisabled = !currentUserLoaded || stripeConnected || payoutDetailsSaved;
  let message = <FormattedMessage id="PayoutDetailsTab.loadingData" />;

  if (currentUserLoaded && payoutDetailsSaved) {
    message = <FormattedMessage id="PayoutDetailsTab.payoutDetailsSaved" />;
  } else if (currentUserLoaded && stripeConnected) {
    message = <FormattedMessage id="PayoutDetailsTab.stripeAlreadyConnected" />;
  } else if (currentUserLoaded && !stripeConnected) {
    message = <FormattedMessage id="PayoutDetailsTab.stripeNotConnected" />;
  }
  const setCNIDocument = cniFormDataValue => {
    setCniFormData(cniFormDataValue);
  };
  const setProofOfAddressDocument = proofOfAddressFormDataValue => {
    setProofOfAddressFormData(proofOfAddressFormDataValue);
  };
  const handlePayoutDetailsSubmit = values => {
    const { fname: firstName, lname: lastName, birthdayDate, ...rest } = values;

    onPayoutDetailsFormSubmit({ firstName, lastName, birthdayDate, cniFormData, proofOfAddressFormData, ...rest });
  };
  const showForm =
    currentUserLoaded && (payoutDetailsSaveInProgress || payoutDetailsSaved || !stripeConnected);

  return (
    <Fragment>
      <p className={css.message}>{message}</p>
      {showForm ? (
        <PayoutDetailsForm
          disabled={formDisabled}
          inProgress={payoutDetailsSaveInProgress}
          ready={payoutDetailsSaved}
          submitButtonText={intl.formatMessage({ id: 'PayoutDetailsTab.submitButtonText' })}
          createStripeAccountError={createStripeAccountError}
          onChange={onPayoutDetailsFormChange}
          onSubmit={handlePayoutDetailsSubmit}
          setCNIDocument={setCNIDocument}
          setProofOfAddressDocument={setProofOfAddressDocument}
          showBirthdayDateField={showBirthdayDateField}
        />
      ) : null}
    </Fragment>
  );
};

PayoutDetailsTab.defaultProps = {
  currentUser: null,
  createStripeAccountError: null,
};

PayoutDetailsTab.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  payoutDetailsSaveInProgress: bool.isRequired,
  createStripeAccountError: propTypes.error,
  payoutDetailsSaved: bool.isRequired,

  onPayoutDetailsFormChange: func.isRequired,
  onPayoutDetailsFormSubmit: func.isRequired,
  currentUserHasListings: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { createStripeAccountError, currentUser, currentUserHasListings } = state.user;
  const { payoutDetailsSaveInProgress, payoutDetailsSaved } = state.PayoutDetailsTab;
  const birthdayDate = pathOr(false, ['currentUser', 'attributes', 'profile', 'protectedData', 'birthdayDate'], state.user);
  return {
    currentUser,
    createStripeAccountError,
    payoutDetailsSaveInProgress,
    payoutDetailsSaved,
    scrollingDisabled: isScrollingDisabled(state),
    showBirthdayDateField: !birthdayDate,
    currentUserHasListings,
  };
};

const mapDispatchToProps = dispatch => ({
  onPayoutDetailsFormChange: () => dispatch(stripeAccountClearError()),
  onPayoutDetailsFormSubmit: values => dispatch(savePayoutDetails(values)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(PayoutDetailsTab);
