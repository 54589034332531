import React, { useEffect, useState } from 'react';
import { node, string, bool } from 'prop-types';
import classNames from 'classnames';

import { IconSpinner, IconCheckmark } from '../../components';

import css from './Button.css';

const Button = (props) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const {
    children,
    className,
    rootClassName,
    spinnerClassName,
    checkmarkClassName,
    inProgress,
    ready,
    disabled,
    withShadow,
    ...rest
  } = props;

  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className, {
    [css.ready]: ready,
    [css.inProgress]: inProgress,
    [css.withShadow]: withShadow,
  });

  // All buttons are disabled until the component is mounted. This
  // prevents e.g. being able to submit forms to the backend before
  // the client side is handling the submit.
  const buttonDisabled = mounted ? disabled : true;

  return (
    <button className={classes} {...rest} disabled={buttonDisabled}>
      {inProgress ? (
        <IconSpinner rootClassName={spinnerClassName || css.spinner} />
      ) : ready ? (
        <IconCheckmark rootClassName={checkmarkClassName || css.checkmark} />
      ) : children}
    </button>
  );
}

Button.defaultProps = {
  rootClassName: null,
  className: null,
  spinnerClassName: null,
  checkmarkClassName: null,
  inProgress: false,
  ready: false,
  disabled: false,
  children: null,
  withShadow: false,
};

Button.propTypes = {
  rootClassName: string,
  className: string,
  spinnerClassName: string,
  checkmarkClassName: string,

  inProgress: bool,
  ready: bool,
  disabled: bool,

  children: node,
  withShadow: bool,
};

export default Button;

export const PrimaryButton = props => {
  const classes = classNames(props.rootClassName || css.primaryButtonRoot, css.primaryButton);

  return <Button {...props} rootClassName={classes} />;
};
PrimaryButton.displayName = 'PrimaryButton';

export const SecondaryButton = props => {
  const classes = classNames(props.rootClassName || css.secondaryButtonRoot, css.secondaryButton);

  return <Button {...props} rootClassName={classes} />;
};
SecondaryButton.displayName = 'SecondaryButton';

export const InlineTextButton = props => {
  const classes = classNames(props.rootClassName || css.inlineTextButtonRoot, css.inlineTextButton);

  return <Button {...props} rootClassName={classes} />;
};
InlineTextButton.displayName = 'InlineTextButton';

export const IconButton = (props) => {
  const classes = classNames(props.rootClassName || css.iconButtonRoot, css.iconButton);

  return <Button {...props} rootClassName={classes} />;
}

export const TextButton = (props) => {
  const classes = classNames(props.rootClassName || css.textButtonRoot, css.textButton, {
    [css.hideIcon]: props.hideIconOnMobile,
  });
  const { hideIconOnMobile, ...restProps } = props;

  return <Button {...restProps} rootClassName={classes} />;
}
