import React, { Fragment } from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { intlShape } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { path, pathOr } from 'ramda';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';

import routeConfiguration from '../../../../routeConfiguration';
import { CONGRATS_ENQUIRY_PAGE } from '../../../../constants/page';
import { createResourceLocatorString } from '../../../../util/routes';
import { propTypes } from '../../../../util/types';
import * as validators from '../../../../util/validators';
import { formatFormValuesAsBookingData, getInitialValuesFromSearch } from '../../../../util/booking';
import { maskContactDetails } from '../../../../util/contactDetails';
import { parse } from '../../../../util/urlHelpers';
import { sendEnquiry } from '../../../../ducks/listing.duck';
import {
  Divider,
  FieldDateAndTimeInput,
  FieldParticipants,
  FieldTextInput,
  Form,
  PrimaryButton,
  UserCard,
} from '../../../../components';

import { handleTrackingTransaction } from '../../ContactPage.helpers';
import css from './ContactForm.module.css';

const ContactForm = (props) => {
  const {
    className,
    author,
    currentUser,
    intl,
    listingId,
    monthlyTimeSlots,
    onFetchTimeSlots,
    onSendEnquiry,
    publicData,
    sendEnquiryError,
    sendEnquiryInProgress,
    timeZone,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const handleSubmit = (formValues) => {
    const routes = routeConfiguration();
    const message = path(['message'], formValues);
    const messageFormatted = maskContactDetails(message.trim(), intl);
    const bookingData = formatFormValuesAsBookingData(formValues);
    const totalParticipants = pathOr(0, ['quantity', 'adults'], bookingData) + pathOr(0, ['quantity', 'children'], bookingData);
    const providerId = path(['id', 'uuid'], author);

    onSendEnquiry(path(['uuid'], listingId), messageFormatted, bookingData, location.search)
      .then(txId => {
        const { uuid } = txId;
        handleTrackingTransaction(uuid, currentUser, totalParticipants, bookingData.bookingDate, providerId);

        // Redirect to CongratsEnquiryPage
        history.push(
          createResourceLocatorString(CONGRATS_ENQUIRY_PAGE, routes, { id: uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  };
  const { enfantsBienvenue: childrenAllowed, maxCapacityToGuest = 0, minimumHoursPerBooking = 1 } = publicData;
  const authorDisplayName = path(['attributes', 'profile', 'displayName'], author);
  const searchParams = parse(location.search);
  const initialValues = getInitialValuesFromSearch(searchParams);
  const messageHeadingText = intl.formatMessage({ id: 'ContactPage.EnquiryPanel.messageHeading' });
  const messageInfoText = intl.formatMessage({ id: 'ContactPage.EnquiryPanel.messageInfo' });
  const messagePlaceholderText = intl.formatMessage({ id: 'ContactPage.EnquiryPanel.messagePlaceholder' }, { name: authorDisplayName });
  const messageLabelText = intl.formatMessage({ id: 'ContactPage.EnquiryPanel.messageLabel' });
  const messageRequiredText = intl.formatMessage({ id: 'ContactPage.EnquiryPanel.messageRequired' });
  const messageRequired = validators.required(messageRequiredText);
  const submitText = intl.formatMessage({ id: 'ContactPage.EnquiryPanel.submit' });
  const sendEnquiryErrorText = intl.formatMessage({ id: 'ContactPage.EnquiryPanel.sendEnquiryError' });
  const classes = classNames(css.root, className);

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={formRenderProps => {
        const { active, form, handleSubmit, values } = formRenderProps;
        const { adults, children } = values;
        
        return (
          <Fragment>
            <Form
              className={classes}
              id="contactForm"
              onSubmit={handleSubmit}
            >
              <div className={css.bookingSection}>
                <div className={css.dateTimeContainer}>
                  {monthlyTimeSlots && timeZone ? (
                    <div className={css.dateTimeContainer}>
                      <FieldDateAndTimeInput
                        className={css.fieldDateAndTimeInput}
                        activeField={active}
                        finalFormApi={form}
                        formValues={values}
                        listingId={listingId}
                        minimumHoursPerBooking={minimumHoursPerBooking}
                        monthlyTimeSlots={monthlyTimeSlots}
                        onFetchTimeSlots={onFetchTimeSlots}
                        timeZone={timeZone}
                      />
                    </div>
                  ) : null}
                </div>
                <FieldParticipants
                  className={css.fieldParticipants}
                  adultsValue={adults}
                  childrenAllowed={childrenAllowed}
                  childrenValue={children}
                  maxParticipants={maxCapacityToGuest}
                />
              </div>
              <Divider className={css.divider} />
              <div className={css.messageSection}>
                <div className={css.infoWrapper}>
                  <span className={css.infoHeading}>{messageHeadingText}</span>
                  <span className={css.info}>{messageInfoText}</span>
                </div>
                <UserCard className={css.userCard} user={author} />
                <FieldTextInput
                  className={css.fieldMessage}
                  type="textarea"
                  id="message"
                  name="message"
                  label={messageLabelText}
                  placeholder={messagePlaceholderText}
                  validate={messageRequired}
                />
              </div>
              <Divider className={css.divider} />
              <div className={css.submitContainer}>
                {sendEnquiryError && <span className={css.error}>{sendEnquiryErrorText}</span>}
                <PrimaryButton
                  className={css.submitButton}
                  disabled={sendEnquiryInProgress}
                  inProgress={sendEnquiryInProgress}
                  type="submit"
                >
                  {submitText}
                </PrimaryButton>
              </div>
            </Form>
          </Fragment>
        )
      }}
    />
  )
}

ContactForm.defaultProps = {
  className: null,
  currentUser: null,
  sendEnquiryError: null,
  monthlyTimeSlots: null,
};

ContactForm.propTypes = {
  className: string,
  author: propTypes.user.isRequired,
  currentUser: propTypes.currentUser,
  intl: intlShape.isRequired,
  listingId: object.isRequired,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  onSendEnquiry: func.isRequired,
  publicData: object.isRequired,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
};

const mapDispatchToProps = dispatch => ({
  onSendEnquiry: (listingId, message, bookingData, searchParams) =>
    dispatch(sendEnquiry(listingId, message, bookingData, searchParams)),
});

const mapStateToProps = (state, props) => {
  const { id, sendEnquiryInProgress, sendEnquiryError } = state.listing;
  const { currentUser } = state.user;

  return {
    currentUser,
    listingId: id,
    sendEnquiryInProgress,
    sendEnquiryError,
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(ContactForm);