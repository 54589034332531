import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconFilter.module.css';

const IconFilter = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g strokeWidth="1.64389" strokeLinecap="round" strokeLinejoin="round">
        <path d="M3.16666 16.625V11.0834" />
        <path d="M3.16666 7.91667V2.375" />
        <path d="M9.5 16.625V9.5" />
        <path d="M9.5 6.33333V2.375" />
        <path d="M15.8333 16.625V12.6666" />
        <path d="M15.8333 9.5V2.375" />
        <path d="M0.791664 11.0834H5.54166" />
        <path d="M7.125 6.33337H11.875" />
        <path d="M13.4583 12.6666H18.2083" />
      </g>
    </svg>
  )
}

IconFilter.defaultProps = {
  rootClassName: null,
  className: null,
};

IconFilter.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconFilter;