import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { AvatarLarge, AvatarMedium, NamedLink } from '../../../../components';

import css from './HostAvatar.module.css';

const HostAvatar = props => {
  const { className, user, params } = props;
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <NamedLink name="ListingPage" params={params} to={{ hash: '#host' }}>
        <AvatarLarge user={user} className={css.avatarDesktop} disableProfileLink />
      </NamedLink>
      <NamedLink name="ListingPage" params={params} to={{ hash: '#host' }}>
        <AvatarMedium user={user} className={css.avatarMobile} disableProfileLink />
      </NamedLink>
    </div>
  );
};

HostAvatar.defaultProps = {
  className: null,
}

HostAvatar.propTypes = {
  className: string,
}

export default HostAvatar;
