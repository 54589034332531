import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedHTMLMessage, intlShape } from 'react-intl';
import classNames from 'classnames';

import css from './ConfirmationInfo.module.css';

const ConfirmationInfo = (props) => {
  const { className, applyInstantBooking, intl } = props;
  const textId = applyInstantBooking ? 'StripePaymentForm.ConfirmationInfo.infoInstantBooking' : 'StripePaymentForm.ConfirmationInfo.info';
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <span className={css.info}>
        <FormattedHTMLMessage id={textId} />
      </span>
    </div>
  )
}

ConfirmationInfo.defaultProps = {
  className: null,
  applyInstantBooking: false,
};

ConfirmationInfo.propTypes = {
  className: string,
  applyInstantBooking: bool,
  intl: intlShape.isRequired,
};

export default ConfirmationInfo;
