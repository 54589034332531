import React from 'react';
import { string } from 'prop-types';
import { FormattedHTMLMessage, intlShape } from 'react-intl';
import classNames from 'classnames';

import css from './CancelationPolicyInfo.module.css';

const CancelationPolicyInfo = (props) => {
  const { className, intl } = props;
  const headingText = intl.formatMessage({ id: 'StripePaymentForm.CancelationPolicyInfo.heading' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <h3 className={css.heading}>{headingText}</h3>
      <span className={css.cancelationPolicy}>
        <FormattedHTMLMessage id="StripePaymentForm.CancelationPolicyInfo.cancelationPolicy" />
      </span>
    </div>
  )
}

CancelationPolicyInfo.defaultProps = {
  className: null,
};

CancelationPolicyInfo.propTypes = {
  className: string,
  intl: intlShape.isRequired,
};

export default CancelationPolicyInfo;
