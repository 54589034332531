export const CUSTOMER = 'customer';
export const PROVIDER = 'provider';

export const DATE_TYPE_DATETIME = 'datetime';

export const BOOKING_DATE = 'bookingDate';
export const START_TIME = 'startTime';
export const END_TIME = 'endTime';

export const ADULTS = 'adults';
export const CHILDREN = 'children';
export const INFANTS = 'infants';

export const IS_FLEXIBLE = 'isFlexible';