import React, {useEffect} from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { keys, map, pathOr, reduce, reverse } from 'ramda';
import sortBy from 'lodash/sortBy';

import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { getMorningOrAfternoonByDate, getMorningOrAfternoonLabelByDate, moment } from '../../util/dates';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { updateProfile } from '../../ducks/userSettings.duck';
import {
  Page,
  PaginationLinks,
  TabNav,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  IconSpinner,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';

import { loadData } from './ReservationsPage.duck';
import ReservationCard from './ReservationCard/ReservationCard';
import css from './ReservationsPage.css';

const sortedTransactions = txs =>
  sortBy(txs, tx => {
    return tx.booking ? tx.booking.attributes.start : null;
  });

const formatDate = (intl, date, isEveningMode = false) => {
  const dateWithTz = moment(date);
  return {
    date: intl.formatDate(date, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }),
    timeOfTheDay: isEveningMode ? getMorningOrAfternoonLabelByDate(dateWithTz, intl) : getMorningOrAfternoonByDate(dateWithTz, intl),
  };
};

const sortedTransactionsByDate = (txs, intl) => {
  return reduce((acc, tx) => {
    const dateKey = formatDate(intl, tx.booking.attributes.start).date;
    if (!acc[dateKey]) acc[dateKey] = [];
    acc[dateKey].push(tx);

    return acc;
  }, {}, txs);
};

export const upcomingTab = 'upcoming';
export const pastTab = 'past';
export const pendingTab = 'pending';
export const othersTab = 'others';

export const ReservationsPageComponent = props => {
  const {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    scrollingDisabled,
    transactions,
    currentUserHasListings,
    bookingsIncomeBadge,
    bookingsRequestBadge,
    bookingsOthersBadge,
    updateProfile,
  } = props;
  const { tab } = params;
  const isUpcomingTab = tab === upcomingTab;
  const isPastTab = tab === pastTab;
  const isPendingTab = tab === pendingTab;
  const isOthersTab = tab === othersTab;
  useEffect(() => {
    const bookingTabBadgeToUpdate = getBookingTabBadgeToUpdate();
    if (!bookingTabBadgeToUpdate) return;

    updateProfile({privateData: {...bookingTabBadgeToUpdate}});
  }, [tab]);
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const getBookingTabBadgeToUpdate = () => {
    switch (tab) {
      case pendingTab:
        return bookingsRequestBadge > 0 ? {bookingsRequestBadge: 0} : null;
      case upcomingTab:
        return bookingsIncomeBadge > 0 ? {bookingsIncomeBadge: 0} : null;
      case othersTab:
        return bookingsOthersBadge > 0 ? {bookingsOthersBadge: 0} : null;
      default:
        return null;
    }
  }
  const validTab = isPendingTab || isPastTab || isUpcomingTab || isOthersTab;
  if (!validTab) {
    return <NotFoundPage />;
  }

  const title = intl.formatMessage({ id: 'ReservationsPage.title' });

  const toTxItem = tx => {
    const currentUserId = pathOr(null, ['id', 'uuid'], ensuredCurrentUser);
    const txProviderId = pathOr(null, ['provider', 'id', 'uuid'], tx);
    const type = currentUserId && txProviderId && currentUserId === txProviderId ? 'sale' : 'order';

    return (
      <li key={tx.id.uuid} className={css.listItem}>
        <ReservationCard type={type} transaction={tx} intl={intl} />
      </li>
    );
  };

  const error = fetchOrdersOrSalesError ? (
    <p className={css.error}>
      {intl.formatMessage({ id: 'ReservationsPage.fetchFailed' })}
    </p>
  ) : null;

  const noResults =
    !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError ? (
      <li key="noResults" className={css.noResults}>
        {intl.formatMessage(
          { id: isUpcomingTab
              ? 'ReservationsPage.noUpcomingReservations'
              : isPastTab
              ? 'ReservationsPage.noPastReservations'
              : 'ReservationsPage.noPendingReservations'
          })}
      </li>
    ) : null;

  const hasTransactions =
    !fetchInProgress && transactions.length > 0;
  const pagingLinks =
    hasTransactions && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="ReservationsPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const tabs = [
    {
      text: (intl.formatMessage({ id: 'ReservationsPage.pendingTabTitle'})),
      selected: isPendingTab,
      notificationCount: bookingsRequestBadge,
      linkProps: {
        name: 'ReservationsPage',
        params: { tab: 'pending' },
      },
    },
    {
      text: (intl.formatMessage({ id: 'ReservationsPage.upcomingTabTitle'})),
      selected: isUpcomingTab,
      notificationCount: bookingsIncomeBadge,
      linkProps: {
        name: 'ReservationsPage',
        params: { tab: 'upcoming' },
      },
    },
    {
      text: (intl.formatMessage({ id: 'ReservationsPage.pastTabTitle'})),
      selected: isPastTab,
      linkProps: {
        name: 'ReservationsPage',
        params: { tab: 'past' },
      },
    },
    {
      text: (intl.formatMessage({ id: 'ReservationsPage.othersTabTitle'})),
      selected: isOthersTab,
      notificationCount: bookingsOthersBadge,
      linkProps: {
        name: 'ReservationsPage',
        params: { tab: 'others' },
      },
    },
  ];
  const nav = <TabNav className={css.tabs} tabs={tabs} />;
  const transactionsByDate = sortedTransactionsByDate(isPastTab ? reverse(transactions) : transactions, intl);
  const dates = keys(transactionsByDate);
  const items = map((date) => {
    return (
      <li key={date} className={css.listDate}>
        <div className={css.dateContainer}>{date}</div>
        <ul className={css.itemList}>
          {map(toTxItem, isPastTab ? sortedTransactions(transactionsByDate[date]) : transactionsByDate[date])}
        </ul>
      </li>
    )
  }, dates)

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer className={css.topbar} currentPage="ReservationsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav>
          <h1 className={css.title}>
            <FormattedMessage id="ReservationsPage.title" />
          </h1>
          {nav}
        </LayoutWrapperSideNav>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          {error}
          <ul className={css.dateList}>
            {!fetchInProgress ? (
              items
            ) : (
              <li className={css.listItemsLoading}>
                <IconSpinner />
              </li>
            )}
            {noResults}
          </ul>
          {pagingLinks}
        </LayoutWrapperMain>
      </LayoutSideNavigation>
    </Page>
  );
};

ReservationsPageComponent.defaultProps = {
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  sendVerificationEmailError: null,
  bookingsIncomeBadge: 0,
  bookingsRequestBadge: 0,
  bookingsOthersBadge: 0,
};

ReservationsPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,
  currentUserHasListings: bool.isRequired,
  bookingsIncomeBadge: propTypes.number,
  bookingsRequestBadge: propTypes.number,
  bookingsOthersBadge: propTypes.number,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchOrdersOrSalesError, pagination, transactionRefs } = state.ReservationsPage;
  const { currentUser, currentUserHasListings } = state.user;
  const bookingsIncomeBadge = pathOr(0, ['attributes', 'profile', 'privateData', 'bookingsIncomeBadge'], currentUser);
  const bookingsRequestBadge = pathOr(0, ['attributes', 'profile', 'privateData', 'bookingsRequestBadge'], currentUser);
  const bookingsOthersBadge = pathOr(0, ['attributes', 'profile', 'privateData', 'bookingsOthersBadge'], currentUser);
  return {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: sortedTransactions(getMarketplaceEntities(state, transactionRefs)),
    currentUserHasListings,
    bookingsIncomeBadge,
    bookingsRequestBadge,
    bookingsOthersBadge
  };
};

const mapDispatchToProps = {
  updateProfile
};

const ReservationsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ReservationsPageComponent);

ReservationsPage.loadData = loadData;

export default ReservationsPage;
