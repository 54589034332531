import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';

import config from '../../../../config';
import { formatMoney } from '../../../../util/currency';
import { types as sdkTypes } from '../../../../util/sdkLoader';
import {
  IconBarbecue,
  IconEdit,
  IconExtraCustom,
  IconSnack,
  IconTowel,
} from '../../../../components/Icons';

import css from './ExtraItem.module.css';

const { Money } = sdkTypes;
const currency = config.currency;

const ExtraItem = (props) => {
  const {
    className,
    disabled,
    extraData,
    id,
    intl,
    label,
    onClick,
    sublabel,
    withEdit,
  } = props;
  const onClickMaybe = !disabled && { onClick };
  const { amount, isByUnit } = extraData;
  const amountAsMoney = amount && new Money(amount, currency);
  const priceText = amount && intl.formatMessage(
    { id: `EditListingPricingPanel.PaidExtrasView.${isByUnit ? 'perUnit' : 'perBooking'}` },
    { amount: formatMoney(intl, amountAsMoney) },
  );
  const sublabelText = sublabel || priceText;
  const classes = classNames(css.root, className, { [css.disabled]: disabled });
  const icon = () => {
    switch (id) {
      case 'barbecueFee':
        return <IconBarbecue className={css.icon} />
      case 'towel':
        return <IconTowel className={css.icon} />
      case 'snack':
        return <IconSnack className={css.icon} />

      default:
        return <IconExtraCustom className={css.icon} />
    }
  }

  return (
    <button className={classes} {...onClickMaybe}>
      <div className={css.iconContainer}>
        {icon()}
      </div>
      <div className={css.infoContainer}>
        <span className={css.label}>{label}</span>
        <span className={css.sublabel}>{sublabelText}</span>
      </div>
      {withEdit && (
        <div className={css.editContainer}>
          <IconEdit className={css.iconEdit} />
        </div>
      )}
    </button>
  )
}

ExtraItem.defaultProps = {
  className: null,
  disabled: false,
  extraData: {},
  sublabel: null,
  withEdit: false,
};

ExtraItem.propTypes = {
  className: string,
  disabled: bool,
  extraData: object,
  intl: intlShape.isRequired,
  label: string.isRequired,
  onClick: func.isRequired,
  sublabel: string,
  withEdit: bool,
};

export default ExtraItem;