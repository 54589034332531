import React from 'react';
import { string, oneOfType } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { ensureUser, ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { ExpandableText, NamedLink, UserCard } from '../../components';

import css from './ProviderUserCard.css';

const BIO_MAX_LENGTH_DESKTOP = 180;
const BIO_MAX_LENGTH_MOBILE = 110;

const ProviderUserCard = props => {
  const { rootClassName, className, currentUser, intl, user } = props;
  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isCurrentUser =
    ensuredUser.id && ensuredCurrentUser.id && ensuredUser.id.uuid === ensuredCurrentUser.id.uuid;
  const { profile } = ensuredUser.attributes;
  const { bio } = profile;
  const hasBio = !!bio;
  const classes = classNames(rootClassName || css.root, className);
  const linkClasses = classNames(css.links, {
    [css.withBioMissingAbove]: !hasBio,
  });
  const separator = isCurrentUser && <span className={css.linkSeparator}>•</span>;
  const editProfile = (
      <NamedLink name="AccountProfilePage">
        <FormattedMessage id="ProviderUserCard.editProfileLink" />
      </NamedLink>
  );
  const links = ensuredUser.id && (
    <p className={linkClasses}>
      <NamedLink className={css.link} name="ProfilePage" params={{ id: ensuredUser.id.uuid }}>
        <FormattedMessage id="ProviderUserCard.viewProfileLink" />
      </NamedLink>
      {isCurrentUser && separator}
      {isCurrentUser && editProfile}
    </p>
  );

  return (
    <div className={classes}>
      <UserCard className={css.userCard} user={user} />
      <div className={css.contentContainer}>
        <div className={css.mainContent}>
          {hasBio && <ExpandableText className={css.desktopBio} text={bio} length={BIO_MAX_LENGTH_DESKTOP} />}
          {hasBio && <ExpandableText className={css.mobileBio} text={bio} length={BIO_MAX_LENGTH_MOBILE} />}
          {links}
        </div>
      </div>
    </div>
  );
};

ProviderUserCard.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  user: null,
};

ProviderUserCard.propTypes = {
  rootClassName: string,
  className: string,
  currentUser: propTypes.currentUser,
  intl: intlShape.isRequired,
  user: oneOfType([propTypes.user, propTypes.currentUser]),
};

export default injectIntl(ProviderUserCard);
