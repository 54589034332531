import React from 'react';
import { func, string } from 'prop-types';
import classNames from 'classnames';

import { IconTrash } from '../../../../components/Icons';
import { IconButton } from '../../../../components';

import css from './RemoveImageButton.module.css';

const RemoveImageButton = props => {
  const { className, onClick } = props;
  const classes = classNames(css.root, className);
  
  return (
    <IconButton
      className={classes}
      onClick={onClick}
      withShadow
    >
      <IconTrash className={css.icon} />
    </IconButton>
  );
};

RemoveImageButton.defaultProps = {
  className: null,
};

RemoveImageButton.propTypes = {
  className: string,
  onClick: func.isRequired,
};

export default RemoveImageButton;
