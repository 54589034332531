import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { find, map, without } from 'ramda';
import camelCase from 'lodash/camelCase';

import config from '../../../../config';
import { getCurrencySymbol } from '../../../../util/currency';
import { subUnitDivisors } from '../../../../currency-config';
import * as validators from '../../../../util/validators';
import {
  booleanToString,
  stringToBoolean,
  stringValuesToNumbers,
} from '../../../../util/data';
import {
  Divider,
  FieldRadioButton,
  FieldStepperEditable,
  FieldTextInput,
  Form,
  PrimaryButton,
} from '../../../../components';

import css from './PaidExtrasForm.module.css';

const subUnitDivisorValue = subUnitDivisors[config.currencyConfig.currency];

const PaidExtrasForm = (props) => {
  const {
    className,
    currentExtra,
    isEdit,
    intl,
    listingPublicData,
    onCloseModal,
    onSubmit,
    updateInProgress,
  } = props;
  const { paidExtras, paidExtrasData } = listingPublicData;
  const isPresetExtra = ['towel', 'barbecueFee', 'snack'].includes(currentExtra.id);
  const handleDelete = (e) => {
    const publicData = {
      paidExtras: without([currentExtra.id], paidExtras),
      paidExtrasData: without([find(({ id }) => id === currentExtra.id, paidExtrasData)], paidExtrasData),
    };

    onSubmit({ publicData });
    onCloseModal(e);
  };
  
  const handleSubmit = (values) => {
    const valuesAsNumber = stringValuesToNumbers(values);
    const { name, information, isByUnit, amount } = valuesAsNumber;
    const formattedExtraData = {
      amount: amount * subUnitDivisorValue,
      isByUnit: stringToBoolean(isByUnit),
      label: name,
      information: information,
      id: isPresetExtra ? currentExtra.id : camelCase(name),
    };
    const updatedPaidExtras = isEdit ? (
        map(extraId => {
          if (extraId !== currentExtra.id) return extraId;

          return isPresetExtra ? currentExtra.id : camelCase(name);
        }, paidExtras)
      ) : isPresetExtra ? currentExtra.id : camelCase(name);
    const updatedPaidExtrasData = isEdit ? (
        map(extra => {
          if (extra.id !== currentExtra.id) return extra;

          return {
            ...extra,
            ...formattedExtraData,
          }
        }, paidExtrasData)
      ) : (formattedExtraData);
    const publicData = {
      paidExtras: isEdit ? updatedPaidExtras : [...paidExtras || [], updatedPaidExtras],
      paidExtrasData: isEdit ? updatedPaidExtrasData : [...paidExtrasData || [], updatedPaidExtrasData],
    };
    const updatedValues = { publicData };
    onSubmit(updatedValues);
    onCloseModal();
  };

  const handleIsByUnitClick = (input) =>  input.onChange(input.value);
  const currencySymbol = getCurrencySymbol(config.currencyConfig);
  const initialName = isEdit ? currentExtra.label : isPresetExtra ? currentExtra.label : undefined;
  const initialValues = {
    name: initialName,
    information: isEdit ? currentExtra.information : undefined,
    isByUnit: isEdit ? booleanToString(currentExtra.isByUnit) : "false",
    amount: isEdit ? `${currentExtra.amount / subUnitDivisorValue}${currencySymbol}`  : undefined,
  };
  const nameErrorText = intl.formatMessage({ id: 'EditListingPricingPanel.PaidExtrasForm.nameError' });
  const nameValidate = validators.requiredAndNonEmptyString(nameErrorText);
  const amountErrorText = intl.formatMessage(
    { id: 'EditListingPricingPanel.PaidExtrasForm.amountError' },
    { amount: 2, currency: currencySymbol },
  );
  const amountValidate = validators.minNumberAsString(2, amountErrorText);
  const nameLabelText = intl.formatMessage({ id : 'EditListingPricingPanel.PaidExtrasForm.nameLabel' });
  const namePlaceholderText = intl.formatMessage({ id : 'EditListingPricingPanel.PaidExtrasForm.namePlaceholder' });
  const informationLabelText = intl.formatMessage({ id : 'EditListingPricingPanel.PaidExtrasForm.informationLabel' });
  const isByUnitLabelText = intl.formatMessage({ id : 'EditListingPricingPanel.PaidExtrasForm.isByUnitLabel' });
  const byUnitLabelText = intl.formatMessage({ id : 'EditListingPricingPanel.PaidExtrasForm.byUnitLabel' });
  const byBookingLabelText = intl.formatMessage({ id : 'EditListingPricingPanel.PaidExtrasForm.byBookingLabel' });
  const deleteText = intl.formatMessage({ id: 'Marketplace.delete' });
  const submitText = intl.formatMessage({ id: 'Marketplace.save' });
  const classes = classNames(css.root, className);
  const submitButtonClasses = classNames(css.submitButton, { [css.submitButtonWithEdit]: isEdit });

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={formRenderProps => {
        const {
          handleSubmit,
          invalid,
          pristine,
          values,
        } = formRenderProps;
        const { isByUnit } = values;
        const submitDisabled = invalid || pristine || updateInProgress;

        return (
          <Form className={classes} id="paidExtrasForm" onSubmit={handleSubmit}>
            <FieldTextInput
              className={css.field}
              disabled={isPresetExtra}
              type="text"
              name="name"
              id="name"
              label={nameLabelText}
              placeholder={namePlaceholderText}
              validate={nameValidate}
            />
            <FieldTextInput
              className={css.field}
              type="textarea"
              name="information"
              id="information"
              label={informationLabelText}
            />
            <Divider className={css.divider} />
            <div className={css.isByUnitContainer}>
              <span className={css.isByUnitLabel}>{isByUnitLabelText}</span>
              <div className={css.fieldRadioContainer}>
                <FieldRadioButton
                  rootClassName={css.fieldRadio}
                  selectedClassName={css.fieldRadioSelected}
                  labelClassName={css.fieldRadioLabel}
                  name="isByUnit"
                  onClick={handleIsByUnitClick}
                  label={byBookingLabelText}
                  value="false"
                />
                <FieldRadioButton
                  rootClassName={css.fieldRadio}
                  selectedClassName={css.fieldRadioSelected}
                  labelClassName={css.fieldRadioLabel}
                  name="isByUnit"
                  onClick={handleIsByUnitClick}
                  label={byUnitLabelText}
                  value="true"
                />
              </div>
            </div>
            <FieldStepperEditable
              className={css.fieldStepper}
              name="amount"
              initialValue={initialValues.pricingVariation}
              label={stringToBoolean(isByUnit) ? byUnitLabelText : byBookingLabelText}
              min={2}
              placeholder={`00${currencySymbol}`}
              unit={currencySymbol}
              validate={amountValidate}
              withButtons
            />
            <div className={css.buttonContainer}>
              {isEdit && (
                <button className={css.deleteButton} onClick={handleDelete} type="button">
                  <span className={css.deleteText}>{deleteText}</span>
                </button>
              )}
              <PrimaryButton
                className={submitButtonClasses}
                type="submit"
                form="paidExtrasForm"
                inProgress={updateInProgress}
                disabled={submitDisabled}
              >
                <span>{submitText}</span>
              </PrimaryButton>
            </div>
          </Form>
        )
      }}
    />
  )
}

PaidExtrasForm.defaultProps = {
  className: null,
  currentExtra: {},
  isEdit: false,
  listingPublicData: null,
};

PaidExtrasForm.propTypes = {
  className: string,
  currentExtra: object,
  intl: intlShape.isRequired,
  isEdit: bool,
  listingPublicData: object,
  onCloseModal: func.isRequired,
  onSubmit: func.isRequired,
  updateInProgress: bool.isRequired,
};

export default PaidExtrasForm;