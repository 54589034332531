import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import { find, path, pathOr, reduce } from 'ramda';

import { withEveningBookingModeDate } from '../../marketplace-custom-config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getDisplayDates, moment } from '../../util/dates';
import { sendOpenedEvent } from '../../util/eventsHelpers';
import {
  messageOpenedByCustomerEventType,
  messageOpenedByProviderEventType,
} from '../../util/eventsHelpers/constants';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchMessages, fetchTransaction, setInitialValues } from '../../ducks/transaction.duck';

import { getLastTransitions } from './InboxPage.Helpers';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

const { UUID } = sdkTypes;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    }),
  );

// ================ Action types ================ //

export const FETCH_TRANSACTIONS_REQUEST = 'app/InboxPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/InboxPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/InboxPage/FETCH_TRANSACTIONS_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchTransactionsError: null,
  pagination: null,
  transactionRefs: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TRANSACTIONS_REQUEST:
      return { ...state, fetchInProgress: true, fetchTransactionsError: null };
    case FETCH_TRANSACTIONS_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_TRANSACTIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchTransactionsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchTransactionsRequest = () => ({ type: FETCH_TRANSACTIONS_REQUEST });
const fetchTransactionsSuccess = response => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload: response,
});
const fetchTransactionsError = e => ({
  type: FETCH_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const updateMessageBookingEvent = (currentUserIsProvider, id) => async (dispatch, getState, sdk) => {
  try {
    if (!id) return;

    const userState = getState().user;
    const currentUser = userState.currentUser;
    const messagesBadge = pathOr(0, ['attributes', 'profile', 'privateData', 'messagesBadge'], currentUser);

    const eventType = currentUserIsProvider ? messageOpenedByProviderEventType : messageOpenedByCustomerEventType;
    await sendOpenedEvent(eventType, id.uuid);

    if (messagesBadge > 0) {
      await updateProfile({
        privateData: {
          messagesBadge: messagesBadge - 1,
        },
      });
    };
    dispatch(fetchTransaction(id));
  } catch (error) {
    console.log('er', error);
  }
}

const INBOX_PAGE_SIZE = 20;

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

export const withEveningModeMaybe = (response) => reduce((acc, curr) => {
  const { type } = curr;
  if (type !== 'booking') return {
    ...acc,
    data: {
      ...acc.data,
      included: [...acc.data.included, curr],
    },
  };

  const bookingAttributes = curr.attributes;
  const start = path(['start'], bookingAttributes);
  const end = path(['end'], bookingAttributes);
  const tx = find(tx => path(['relationships', 'booking', 'data', 'id', 'uuid'], tx) === curr.id.uuid, response.data.data);
  const txCreatedAt = path(['attributes', 'createdAt'], tx);
  const isEveningMode = start && moment(txCreatedAt).isAfter(withEveningBookingModeDate);
  const displayDateFromTx = {
    start: path(['data', 'data', 'attributes', 'protectedData', 'bookingStartDisplay'], acc),
    end: path(['data', 'data', 'attributes', 'protectedData', 'bookingEndDisplay'], acc),
  };
  const {
    start: displayStart,
    end: displayEnd,
  } = getDisplayDates(isEveningMode, displayDateFromTx, { start, end }, bookingAttributes.protectedData);
  const booking = {
    ...curr,
    attributes: {
      ...bookingAttributes,
      isEveningMode,
      displayStart,
      displayEnd,
    },
  };

  return {
    ...acc,
    data: {
      ...acc.data,
      included: [...acc.data.included, booking],
    },
  };
}, {
  ...response,
  data: {
    ...response.data,
    included: [],
  },
}, response.data.included);

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { filter, page = 1 } = parse(search);
  const id = params.id && new UUID(params.id);

  dispatch(setInitialValues({}));
  dispatch(fetchTransactionsRequest());

  const lastTransitions = getLastTransitions(filter);
  const apiQueryParams = {
    lastTransitions,
    include: [
      'booking',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'listing',
      'listing.images',
      'messages',
      'messages.createdAt',
      'reviews',
      'reviews.author',
      'reviews.subject',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'processVersion',
      'protectedData',
      'lineItems',
      'metadata',
      'createdAt'
    ],
    'fields.image': ['variants.square-small', 'variants.square-small2x', 'variants.landscape-crop'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const responseWithEveningModeMaybe = response.data.data.length > 0 ? withEveningModeMaybe(response) : response;
      dispatch(addMarketplaceEntities(responseWithEveningModeMaybe));
      dispatch(fetchTransactionsSuccess(responseWithEveningModeMaybe));
      if (id) {
        Promise.all([
          dispatch(fetchTransaction(id)),
          dispatch(fetchMessages(id, 1)),
        ]);
      };

      return responseWithEveningModeMaybe;
    })
    .catch(e => {
      dispatch(fetchTransactionsError(storableError(e)));
      throw e;
    });
};
