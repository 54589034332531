import React from 'react';
import { Helmet } from 'react-helmet-async';
import { pathOr } from 'ramda';

import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isServer, isCookieAccepted } from '../../util/cookies';
import config from '../../config';

const CriteoCategoryListing = props => {
  if (isServer()) return null;
  if (!isCookieAccepted('swimmy_criteoAccepted')) return null;
  
  const { currentUser } = props;
  const user = ensureCurrentUser(currentUser);
  const currentEmail = pathOr('', ['attributes', 'email'], user);

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="https://static.criteo.net/js/ld/ld.js" async="true"/>
        <script type="text/javascript">
          {`
            window.criteo_q = window.criteo_q || [];
            var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
            window.criteo_q.push(
              { event: "setAccount", account: ${config.criteoPartnerId}},
              { event: "setEmail", email: "${currentEmail}" },
              { event: "setSiteType", type: deviceType},
              { event: "viewItem", item: ["3"] }
            );
          `}
        </script>
      </Helmet>
    </div>
  );
};

CriteoCategoryListing.defaultProps = { currentUser: null };

CriteoCategoryListing.propTypes = {
  currentUser: propTypes.currentUser
};

export default CriteoCategoryListing;
