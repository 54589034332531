import React from 'react';
import { FormattedMessage } from 'react-intl';

import config from '../../config';

import css from './styles.css';

const NeedHelpAdCreation = () => {
  const phoneNumber = config.custom.supportPhoneNumber;
  const phoneNumberReadable = config.custom.supportPhoneNumberReadable;
  const startSupportHours = config.custom.supportHours.start; 
  const endSupportHours = config.custom.supportHours.end; 

  return (
    <div className={css.container}>
      <FormattedMessage id="NeedHelpAdCreation.text" 
        values={
          { start: startSupportHours,
            end:  endSupportHours,
          }}
        />
      <a className={css.numberPhone} href={`tel:${phoneNumber}`}>{phoneNumberReadable}</a>
    </div>
  )
};

export default NeedHelpAdCreation;
