import React from 'react';
import { Helmet } from 'react-helmet-async';
import { pathOr } from 'ramda';

import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isNilOrEmpty } from '../../util/validators';
import { convertMoneyToNumber } from '../../util/currency';
import { isServer, isCookieAccepted } from '../../util/cookies';
import config from '../../config';

const CriteoSales = props => {
  if (isServer()) return null;
  if (!isCookieAccepted('swimmy_criteoAccepted')) return null;
  
  const {
    currentUser,
    poolId,
    price,
  } = props;
  if (isNilOrEmpty(currentUser) || !poolId || !price) return null;

  const user = ensureCurrentUser(currentUser);
  const currentEmail = pathOr('', ['attributes', 'email'], user);
  const listingPrice = convertMoneyToNumber(price);

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="https://static.criteo.net/js/ld/ld.js" async="true"/>
        <script type="text/javascript">
          {`
            window.criteo_q = window.criteo_q || [];
            var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
            window.criteo_q.push(
              { event: "setAccount", account: ${config.criteoPartnerId}},
              { event: "setEmail", email: "${currentEmail}" },
              { event: "setSiteType", type: deviceType},
              { event: "trackTransaction", id: "${poolId}", item: [ {id: "${poolId}", price: ${listingPrice}, quantity:1} ]}
            );
          `}
        </script>
      </Helmet>
    </div>
  );
};

CriteoSales.defaultProps = { currentUser: null, poolId: null };

CriteoSales.propTypes = {
  currentUser: propTypes.currentUser
};

export default CriteoSales;
