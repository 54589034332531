import React from 'react';
import { object, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { pathOr } from 'ramda';
import { getWeekdayPricingVariation } from '@swimmy_/helpers';

import css from './PriceAndCancellation.module.css';

const PriceAndCancellation = (props) => {
  const {
    className,
    headingClassName,
    listClassName,
    intl,
    listingPublicData,
  } = props;
  const pricingVariationByPeriod = pathOr([], ['pricingVariationByPeriod'], listingPublicData);
  const hasWeekdayReduction = pathOr([], ['pricingVariationByPeriod'], listingPublicData).length > 0;
  const weekdayReduction = hasWeekdayReduction && getWeekdayPricingVariation(pricingVariationByPeriod);
  const headingText = intl.formatMessage({ id: 'ContactPage.InformationPanel.priceHeading' });
  const weekReductionText = weekdayReduction && intl.formatMessage({ id: 'ContactPage.InformationPanel.weekReduction' }, { percentage: weekdayReduction.variation });
  const cancelationPolicyText = intl.formatMessage({ id: 'ContactPage.InformationPanel.cancelationPolicy' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <span className={headingClassName}>{headingText}</span>
      <ul className={listClassName}>
        {weekdayReduction && <li>{weekReductionText}</li>}
        <li>{cancelationPolicyText}</li>
      </ul>
    </div>
  )
}

PriceAndCancellation.defaultProps = {
  className: null,
};

PriceAndCancellation.propTypes = {
  className: string,
  headingClassName: string.isRequired,
  listClassName: string.isRequired,
  intl: intlShape.isRequired,
  listingPublicData: object.isRequired,
};

export default PriceAndCancellation;