import React, { Fragment, useEffect, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { find, map } from 'ramda';

import { IconClose } from '../../../../components/Icons';
import {
  ModalSwimmy,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from '../../../../components/ModalSwimmy';
import { Divider } from '../../../../components';

import ExtraItem from '../ExtraItem/ExtraItem';
import PaidExtrasForm from '../PaidExtrasForm/PaidExtrasForm';
import css from './EditExtraModal.module.css';

const EditExtraModal = (props) => {
  const {
    className,
    currentExtra,
    intl,
    isEdit,
    isMobile,
    isOpen,
    listingPublicData,
    onCloseModal,
    onManageDisableScrolling,
    onSubmit,
    setCurrentExtra,
    updated,
    updateInProgress,
  } = props;
  const [showExtraForm, setShowExtraForm] = useState(isEdit);
  useEffect(() => {
    if (!isEdit) return;

    setShowExtraForm(isEdit);
  }, [isEdit]);
  const { paidExtras } = listingPublicData;
  const handleExtraClick = (extraData) => {
    setShowExtraForm(true);
    setCurrentExtra(extraData);
  };
  const handleCloseModal = (e) => {
    onCloseModal(e);
    setShowExtraForm(false);
  };
  const hasExtra = (extraId) => paidExtras && find((id) => id === extraId, paidExtras);
  const headingText = intl.formatMessage({ id: 'EditListingPricingPanel.PaidExtrasView.addExtra' });
  const headingEditText = intl.formatMessage({ id: 'EditListingPricingPanel.PaidExtrasView.editExtra' });
  const listLabelText = intl.formatMessage({ id: 'EditListingPricingPanel.PaidExtrasView.chooseExtra' });
  const classes = classNames(css.root, className, { [css.rootWithForm]: showExtraForm });
  const extraItems = [
    {
      key: 1,
      id: 'barbecueFee',
      label: intl.formatMessage({ id: 'EditListingPricingPanel.PaidExtrasView.barbecueLabel' }),
      sublabel: intl.formatMessage({ id: 'EditListingPricingPanel.PaidExtrasView.barbecueSublabel' }),
    },
    {
      key: 2,
      id: 'towel',
      label: intl.formatMessage({ id: 'EditListingPricingPanel.PaidExtrasView.towelLabel' }),
      sublabel: intl.formatMessage({ id: 'EditListingPricingPanel.PaidExtrasView.towelSublabel' }),
    },
    {
      key: 3,
      id: 'snack',
      label: intl.formatMessage({ id: 'EditListingPricingPanel.PaidExtrasView.snackLabel' }),
      sublabel: intl.formatMessage({ id: 'EditListingPricingPanel.PaidExtrasView.snackSublabel' }),
    },
    {
      key: 4,
      id: 'custom',
      label: intl.formatMessage({ id: 'EditListingPricingPanel.PaidExtrasView.customLabel' }),
      sublabel: intl.formatMessage({ id: 'EditListingPricingPanel.PaidExtrasView.customSublabel' }),
    }
  ];

  return (
    <ModalSwimmy
      id="editExtraModal"
      animationType={isMobile ? 'slideBottom' : 'fade'}
      isOpen={isOpen}
      onClose={handleCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <ModalOverlay />
      <ModalContent
        className={css.modalContent} 
        animationType="slideBottom"
        show={isOpen}
      >
        <ModalHeader className={css.modalHeader}>
          <button className={css.backButton} onClick={handleCloseModal}>
            <IconClose />
          </button>
          <span className={css.heading}>{isEdit ? headingEditText : headingText}</span>
          <div className={css.spacer} />
        </ModalHeader>
        <ModalBody className={classes}>
          {showExtraForm ? (
            <PaidExtrasForm
              className={css.extraForm}
              currentExtra={currentExtra}
              intl={intl}
              isEdit={isEdit}
              listingPublicData={listingPublicData}
              onCloseModal={handleCloseModal}
              onSubmit={onSubmit}
              updateInProgress={updateInProgress}
            />
          ) : (
            <Fragment>
              <span className={css.listLabel}>{listLabelText}</span>
              <div className={css.extraList}>
                {map((item) => (
                  <Fragment key={item.key}>
                    {item.id === 'custom' && <Divider className={css.divider} />}
                    <ExtraItem
                      className={css.extraItem}
                      disabled={!!hasExtra(item.id)}
                      id={item.id}
                      intl={intl}
                      label={item.label}
                      onClick={() => handleExtraClick(item)}
                      sublabel={item.sublabel}
                    />
                  </Fragment>
                ), extraItems)}
              </div>
            </Fragment>
          )}
        </ModalBody>
      </ModalContent>
    </ModalSwimmy>
  );
}

EditExtraModal.defaultProps = {
  className: null,
  currentExtra: {},
  listingPublicData: {},
};

EditExtraModal.propTypes = {
  className: string,
  currentExtra: object,
  intl: intlShape.isRequired,
  isMobile: bool.isRequired,
  isOpen: bool.isRequired,
  listingPublicData: object,
  onCloseModal: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSubmit: func.isRequired,
  setCurrentExtra: func.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
};

export default EditExtraModal;