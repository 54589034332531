import { loadData as AccountPageLoader } from './AccountPage/AccountPage.duck';
import { loadData as AccountPaymentMethodsPageLoader } from './AccountPaymentsPage/components/PaymentMethodsTab/PaymentMethodsTab.duck';
import { loadData as AccountPayoutDetailsPageLoader } from './AccountPaymentsPage/components/PayoutDetailsTab/PayoutDetailsTab.duck';
import { loadData as AccountPersonalInfosPageLoader } from './AccountPersonalInfosPage/components/PersonalInfosTab/PersonalInfosTab.duck';
import { loadData as CalendarPageLoader } from './CalendarPage/CalendarPage.duck';
import { setInitialValues as CheckoutExtraPageInitialValues } from './CheckoutExtraPage/CheckoutExtraPage.duck';
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as CongratsEnquiryPageLoader } from './CongratsEnquiryPage/CongratsEnquiryPage.duck';
import { loadData as ContactPageLoader } from './ContactPage/ContactPage.duck';
import { setInitialValues as ContactPageInitialValues } from '../ducks/listing.duck';
import { loadData as FavoritesPageLoader } from './FavoritesPage/FavoritesPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as ListingPageLoader} from './ListingPage/ListingPage.duck';
import { setInitialValues as ListingPageInitialValues } from '../ducks/listing.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.helpers';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as TransactionPageLoader } from './TransactionPage/TransactionPage.duck';
import { setInitialValues as TransactionPageInitialValues } from '../ducks/transaction.duck';

const getPageDataLoadingAPI = () => {
  return {
    AccountPage: {
      loadData: AccountPageLoader,
    },
    AccountPaymentMethodsPage: {
      loadData: AccountPaymentMethodsPageLoader,
    },
    AccountPayoutDetailsPage: {
      loadData: AccountPayoutDetailsPageLoader,
    },
    AccountPersonalInfosPage: {
      loadData: AccountPersonalInfosPageLoader,
    },
    CalendarPage: {
      loadData: CalendarPageLoader,
    },
    CheckoutExtraPage: {
      setInitialValues: CheckoutExtraPageInitialValues,
    },
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    CongratsEnquiryPage: {
      loadData: CongratsEnquiryPageLoader,
    },
    ContactPage: {
      loadData: ContactPageLoader,
      setInitialValues: ContactPageInitialValues,
    },
    FavoritesPage: {
      loadData: FavoritesPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
      setInitialValues: ListingPageInitialValues,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
  }
};

export default getPageDataLoadingAPI;
