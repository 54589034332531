import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { pathOr } from 'ramda';

import config from '../../../../config';
import { getCurrencySymbol } from '../../../../util/currency';
import * as validators from '../../../../util/validators';
import { stringValuesToNumbers } from '../../../../util/data';
import {
  FieldStepperEditable,
  Form,
  PrimaryButton,
} from '../../..';

import css from './InstantBookingForm.module.css';

const MIN_PRICE = 10;

const InstantBookingForm = (props) => {
  const {
    className,
    intl,
    isMobile,
    listing,
    onSubmit,
    updated,
    updateInProgress,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const handleDeactivate = () => {
    const publicData = { instantBookingFrom: undefined };

    onSubmit({ publicData });
    history.push(`${pathname}`);
  };
  const handleSubmit = (values) => {
    const valuesAsNumber = stringValuesToNumbers(values);
    const { instantBookingFrom } = valuesAsNumber;
    const publicData = { instantBookingFrom };
    const updatedValues = { publicData };
    onSubmit(updatedValues);
  };
  const listingPublicData = pathOr({}, ['attributes', 'publicData'], listing);
  const currencySymbol = getCurrencySymbol(config.currencyConfig);
  const { instantBookingFrom } = listingPublicData;
  const initialValues = { instantBookingFrom: `${instantBookingFrom || MIN_PRICE}${currencySymbol}` };
  const instantBookingFromErrorText = intl.formatMessage(
    { id: 'EditListingPricingPanel.InstantBookingForm.instantBookingFromError' },
    { amount: `${MIN_PRICE}${currencySymbol}` },
  );
  const minValidator = validators.minNumberAsString(MIN_PRICE, instantBookingFromErrorText);
  const validate = validators.composeValidators(minValidator);
  const labelText = intl.formatMessage({ id : 'EditListingPricingPanel.InstantBookingForm.instantBookingFromLabel' });
  const labelDesktopText = intl.formatMessage({ id : 'EditListingPricingPanel.InstantBookingForm.instantBookingFromLabelDesktop' });
  const deactivateText = intl.formatMessage({ id: 'Marketplace.deactivate' });
  const submitText = intl.formatMessage({ id: `Marketplace.${instantBookingFrom ? 'save' : 'activate'}` });
  const classes = classNames(css.root, className);

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={formRenderProps => {
        const {
          handleSubmit,
          invalid,
          pristine,
          values,
        } = formRenderProps;
        const submitReady = (updated && pristine);
        const submitDisabled = invalid || updateInProgress;
        const sublabelText = intl.formatMessage(
          { id: 'EditListingPricingPanel.InstantBookingForm.instantBookingFromSublabel' },
          { amount: values.instantBookingFrom || 0 },
        );

        return (
          <Form className={classes} id="instantBookingForm" onSubmit={handleSubmit}>
            <FieldStepperEditable
              className={css.field}
              name="instantBookingFrom"
              initialValue={initialValues.instantBookingFrom}
              label={isMobile ? labelText : labelDesktopText}
              min={MIN_PRICE}
              placeholder={`${MIN_PRICE}${currencySymbol}`}
              step={5}
              sublabel={sublabelText}
              unit={currencySymbol}
              validate={validate}
              withButtons={isMobile}
            />
            <div className={css.buttonContainer}>
              <button className={css.deactivateButton} onClick={handleDeactivate} type="button">
                <span className={css.deactivateText}>{deactivateText}</span>
              </button>
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                form="instantBookingForm"
                inProgress={updateInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                <span>{submitText}</span>
              </PrimaryButton>
            </div>
          </Form>
        )
      }}
    />
  )
}

InstantBookingForm.defaultProps = {
  className: null,
  listing: null,
};

InstantBookingForm.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  isMobile: bool.isRequired,
  listing: object,
  onSubmit: func.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
};

export default InstantBookingForm;