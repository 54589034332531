import { pathOr } from 'ramda';

export const getMissingInfos = (user) => {
  const emailVerified = pathOr(false, ['attributes', 'emailVerified'], user);
  const profileImage = pathOr(null, ['profileImage', 'id'], user);
  const bio = pathOr(null, ['attributes', 'profile', 'bio'], user);
  const birthdayDate = pathOr(null, ['attributes', 'profile', 'protectedData', 'birthdayDate'], user);
  const phoneNumber = pathOr(null, ['attributes', 'profile', 'protectedData', 'phoneNumber'], user);
  const language = pathOr(null, ['attributes', 'profile', 'privateData', 'language'], user);
  const hasMissingInfos = !emailVerified || !profileImage || !bio || !birthdayDate || !phoneNumber || !language;

  return hasMissingInfos ? {
    emailInfo: [...!emailVerified ? ['emailVerified'] : []],
    profileInfos: [
      ...!profileImage ? ['profileImage'] : [],
      ...!bio ? ['bio'] : [],
    ],
    personalInfos: [
      ...!birthdayDate ? ['birthdayDate'] : [],
      ...!phoneNumber ? ['phoneNumber'] : [],
      ...!language ? ['language'] : [],
    ],
  } : null;
};
