import React from 'react';
import { array, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { includes, pathOr, reject } from 'ramda';

import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { updateProfile } from '../../ducks/userSettings.duck';
import { IconHeart } from '../../components';

import css from './FavoritesButton.module.css';

const FavoritesButtonComponent = (props) => {
  const {
    rootClassName,
    className,
    isAuthenticated,
    listingId,
    myFavorites,
    text,
    onUpdateFavoriteListings,
    history,
  } = props;
  const isFavorite = includes(listingId, myFavorites);
  const handleUpdateFavorites = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const routes = routeConfiguration();
    if (!isAuthenticated) {
      return history.push(createResourceLocatorString('LoginPage', routes, {}));
    }

    const favoritesUpdated = isFavorite ? reject(fav => fav === listingId, myFavorites) : [listingId, ...myFavorites];
    const queryParams = {
      protectedData: {
        myFavorites: favoritesUpdated,
      }
    };

    onUpdateFavoriteListings(queryParams);
  };
  const classes = classNames(rootClassName || css.root, className);
  const iconClasses = classNames(isFavorite ? css.removeIcon : css.addIcon);

  return (
    <button className={classes} onClick={handleUpdateFavorites}>
      <IconHeart className={iconClasses} />
      {text && <span className={css.text}>{text}</span>}
    </button>
  )
};

FavoritesButtonComponent.defaultProps = {
  className: null,
  rootClassName: null,
  myFavorites: [],
  text: null,
};

FavoritesButtonComponent.propTypes = {
  className: string,
  rootClassName: string,
  myFavorites: array,
  text: string,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const myFavorites = pathOr([], ['attributes', 'profile', 'protectedData', 'myFavorites'], currentUser);

  return {
    myFavorites,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateFavoriteListings: (data) => dispatch(updateProfile(data)),
});

const FavoritesButton = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(FavoritesButtonComponent);

export default FavoritesButton;