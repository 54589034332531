import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { array, bool, func, string } from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { map, path } from 'ramda';

import { propTypes } from '../../../../util/types';
import { parse } from '../../../../util/urlHelpers';
import { getMarketplaceEntities } from '../../../../ducks/marketplaceData.duck';
import { Error, IconSpinner, PaginationLinks } from '../../../../components';

import { handleMessageEvent } from '../../InboxPage.Helpers';
import { loadData, updateMessageBookingEvent } from '../../InboxPage.duck';
import TransactionItem from './components/TransactionItem/TransactionItem';
import TransactionFilter from './components/TransactionFilter/TransactionFilter';
import css from './TransactionsSection.module.css';

const getHeadingText = (intl, filter) => {
  switch (filter) {
    case 'booking':
      return intl.formatMessage({ id: 'InboxPage.TransactionsSection.headingBooking' });
    case 'information':
      return intl.formatMessage({ id: 'InboxPage.TransactionsSection.headingInformation' });

    default:
      return intl.formatMessage({ id: 'InboxPage.TransactionsSection.heading' });
  }
};

const TransactionsSection = props => {
  const {
    className,
    currentUser,
    fetchInProgress,
    fetchTransactionsError,
    hasViewport,
    isMobile,
    onFetchTransactions,
    onUpdateMessageBookingEvent,
    pagination,
    setShowActivityFeed,
    transactions,
    intl,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const handleTxClick = (tx) => {
    history.push(`/messages/${tx.id.uuid}${location.search}`);
    if (isMobile) {
      setShowActivityFeed(true);
    }
    handleMessageEvent(tx, currentUser, onUpdateMessageBookingEvent);
  };
  useEffect(() => {
    if (!hasViewport || isMobile || fetchInProgress || params.id) return;

    if (!isMobile && transactions.length > 0) {
      handleTxClick(transactions[0]);
    };
  }, [fetchInProgress]);
  const parsedSearch = parse(location.search);
  const { filter, page: currentPage } = parsedSearch;
  const totalItems = path(['totalItems'], pagination);
  const totalPages = path(['totalPages'], pagination);
  const page = path(['page'], pagination);
  const isLoadingFirstPage = pagination && !currentPage;
  const isLoadingNewPage = currentPage ? currentPage !== page : true;
  const isLoading = fetchInProgress && !isLoadingFirstPage && isLoadingNewPage && !fetchTransactionsError;
  const hasTransactions = totalItems > 0 && !fetchTransactionsError;

  const heading = getHeadingText(intl, filter);
  const errorText = intl.formatMessage({ id: 'InboxPage.TransactionsSection.error' });
  const noResultsText = intl.formatMessage({ id: 'InboxPage.TransactionsSection.noResults' });

  const pagingLinks =
    hasTransactions && totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName='InboxPage'
        pageSearchParams={parsedSearch}
        pagination={pagination}
      />
    ) : null;

  return (
    <section className={className}>
      <div className={css.header}>
        <h1 className={css.heading}>{heading}</h1>
        <TransactionFilter className={css.filter} search={location.search} />
      </div>
      <div className={css.content}>
        {isLoading && <IconSpinner />}
        {!isLoading && fetchTransactionsError && (
          <Error
            className={css.error}
            errorText={errorText}
            reloadFunction={() => onFetchTransactions({}, location.search)}
          />
        )}
        {!isLoading && !hasTransactions && <span className={css.noResults}>{noResultsText}</span>}
        {!isLoading && hasTransactions && (
          <ul className={css.transactionsList}>
            {map(tx => (
              <TransactionItem
                key={tx.id.uuid}
                currentUser={currentUser}
                handleTxClick={handleTxClick}
                tx={tx}
              />
            ), transactions)}
          </ul>
        )}
      </div>
      {pagingLinks}
    </section>
  )
};

TransactionsSection.defaultProps = {
  currentUser: null,
  fetchTransactionsError: null,
  transactions: [],
};

TransactionsSection.propTypes = {
  className: string.isRequired,
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchTransactionsError: propTypes.error,
  hasViewport: bool.isRequired,
  intl: intlShape.isRequired,
  isMobile: bool.isRequired,
  onFetchTransactions: func.isRequired,
  onUpdateMessageBookingEvent: func.isRequired,
  pagination: propTypes.pagination,
  setShowActivityFeed: func.isRequired,
  transactions: array,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    fetchInProgress,
    fetchTransactionsError,
    pagination,
    transactionRefs,
  } = state.InboxPage;

  return {
    currentUser,
    fetchInProgress,
    fetchTransactionsError,
    pagination,
    transactions: getMarketplaceEntities(state, transactionRefs),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchTransactions: (params, search) => dispatch(loadData(params, search)),
    onUpdateMessageBookingEvent: (...args) => dispatch(updateMessageBookingEvent(...args)),
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl,
)(TransactionsSection);
