import React from 'react';
import { func, string, object, bool, array } from 'prop-types';
import classNames from 'classnames';
import { head, last, move, path } from 'ramda';

import { ImageFromFile, ResponsiveImage, IconSpinner } from '../../../../components';

import { ReorderActions, RemoveImageButton } from '../../components';
import css from './ThumbnailWrapper.module.css';

const ThumbnailWrapper = props => {
  const {
    className,
    formApi,
    image,
    images,
    index,
    isNew,
    savedImageAltText,
    onRemoveImage,
  } = props;
  const handleRemoveClick = e => {
    e.stopPropagation();
    onRemoveImage(image.id);
  };
  const handleFirstImage = (e) => {
    e.stopPropagation();
    formApi.change('images', (move(index, 0, images)));
  }
  const handleHigher = (e) => {
    e.stopPropagation();
    formApi.change('images', (move(index, index - 1, images)));
  }
  const handleLower = (e) => {
    e.stopPropagation();
    formApi.change('images', (move(index, index + 1, images)));
  }
  const isFirstImage = path(['id', 'uuid'] ,head(images)) === image.id.uuid;
  const isLastImage = path(['id', 'uuid'] ,last(images)) === image.id.uuid;

  if (image.file) {
    // Add remove button only when the image has been uploaded and can be removed
    const removeButton = image.imageId ? <RemoveImageButton onClick={handleRemoveClick} /> : null;

    // While image is uploading we show overlay on top of thumbnail
    const uploadingOverlay = !image.imageId ? (
      <div className={css.thumbnailLoading}>
        <IconSpinner />
      </div>
    ) : null;

    return (
      <ImageFromFile
        id={image.id}
        className={className}
        rootClassName={css.root}
        file={image.file}
      >
        {removeButton}
        {uploadingOverlay}
      </ImageFromFile>
    );
  } else {
    const classes = classNames(css.root, className);

    return (
      <div className={classes}>
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapper}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              image={image}
              alt={savedImageAltText}
              variants={['landscape-crop', 'landscape-crop2x']}
            />
          </div>
          {!isNew && (
            <ReorderActions
              className={css.reorderActions}
              handleFirstImage={handleFirstImage}
              handleHigher={handleHigher}
              handleLower={handleLower}
              isFirstImage={isFirstImage}
              isLastImage={isLastImage}
            />
          )}
          <RemoveImageButton className={css.removeImageButton} onClick={handleRemoveClick} />
        </div>
      </div>
    );
  }
};

ThumbnailWrapper.defaultProps = {
  className: null,
};

ThumbnailWrapper.propTypes = {
  className: string,
  formApi: object.isRequired,
  image: object.isRequired,
  images: array.isRequired,
  isNew: bool.isRequired,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
};

export default ThumbnailWrapper;