import React from 'react';
import { func } from 'prop-types';
import { useParams } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { DATE_TYPE_DATETIME, propTypes } from '../../../../../../util/types';
import { getGuestCountFromTx, getUserTxRole } from '../../../../../../util/transactions';
import {
  AlertBadge,
  Avatar,
  BookingTimeInfo,
  UserDisplayName,
} from '../../../../../../components';

import { getTxType, hasNewMessages, txState } from '../../../../InboxPage.Helpers';
import css from './TransactionItem.module.css';

const TransactionItem = props => {
  const {
    currentUser,
    handleTxClick,
    tx,
    intl,
  } = props;
  const params = useParams();
  const txProtectedData = tx.attributes.protectedData;
  const currentUserRole = currentUser && getUserTxRole(currentUser.id, tx);
  const enquiryDate = txProtectedData.date || txProtectedData.bookingStartDisplay;
  const guestCount = getGuestCountFromTx(tx);
  const isProvider = currentUserRole === 'provider';
  const otherUser = isProvider ? tx.customer : tx.provider;
  const txType = getTxType(tx, currentUser);
  const unreadMessages = hasNewMessages(tx, currentUser);
  const stateData = txState(intl, tx, txType, unreadMessages);
  const participantsText = intl.formatMessage({ id: 'InboxPage.quantity' }, { guestCount });
  const listItemClasses = classNames(css.listItem, {
    [css.listItemSelected]: params.id === tx.id.uuid,
  });

  return (
    <li className={listItemClasses}>
      <button className={css.button} onClick={() => handleTxClick(tx)}>
        <Avatar user={otherUser} disableProfileLink />
        <div className={css.infoWrapper}>
          <div className={classNames(css.username, stateData.nameClassName)}>
            {unreadMessages && (<AlertBadge />)}
            <UserDisplayName user={otherUser} intl={intl} />
          </div>
          {tx.booking || enquiryDate ? (
            <div className={classNames(css.bookingInfoWrapper, stateData.bookingClassName)}>
              <BookingTimeInfo
                bookingClassName={stateData.bookingClassName}
                dateType={DATE_TYPE_DATETIME}
                tx={tx}
                intl={intl}
              />
            </div>
          ) : null}
          <div className={classNames(css.state, stateData.stateClassName)}>
            {stateData.state}
            {isProvider && guestCount && (
              <span className={css.participants}>{participantsText}</span>
            )}
          </div>
        </div>
      </button>
    </li>
  )
};

TransactionItem.defaultProps = {};

TransactionItem.propTypes = {
  currentUser: propTypes.currentUser.isRequired,
  handleTxClick: func.isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(TransactionItem);
