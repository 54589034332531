import React from 'react';
import { bool, func  } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { ensureCurrentUser } from '../../../../util/data';
import { propTypes } from '../../../../util/types';
import { PasswordChangeForm } from '../../../../forms'

import { changePassword, changePasswordClear } from './PasswordChangeTab.duck';
import css from './PasswordChangeTab.module.css';

const PasswordChangeTab = props => {
  const {
    changePasswordError,
    changePasswordInProgress,
    currentUser,
    onChange,
    onSubmitChangePassword,
    passwordChanged,
  } = props;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  return (
    <PasswordChangeForm
      className={css.form}
      changePasswordError={changePasswordError}
      currentUser={ensuredCurrentUser}
      onSubmit={onSubmitChangePassword}
      onChange={onChange}
      inProgress={changePasswordInProgress}
      ready={passwordChanged}
    />
  )
};

PasswordChangeTab.defaultProps = {
  changePasswordError: null,
  currentUser: null,
};

PasswordChangeTab.propTypes = {
  changePasswordError: propTypes.error,
  changePasswordInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  onChange: func.isRequired,
  onSubmitChangePassword: func.isRequired,
  passwordChanged: bool.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    changePasswordError,
    changePasswordInProgress,
    passwordChanged,
  } = state.passwordChange;

  return {
    changePasswordError,
    changePasswordInProgress,
    currentUser,
    passwordChanged,
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: () => dispatch(changePasswordClear()),
  onSubmitChangePassword: values => dispatch(changePassword(values)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PasswordChangeTab);
