import React, { useEffect } from 'react';
import { bool, func, object, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { pathOr } from 'ramda';

import {
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '../../../../components/ModalSwimmy';
import {
  FieldStepper,
  IconArrowHead,
  IconClose,
  IconSearch,
  PrimaryButton,
} from '../../../../components';

import css from './ParticipantsView.module.css';

const ParticipantsView = (props) => {
  const {
    className,
    formProps,
    formValues,
    handleView,
    intl,
    isSearchPage,
  } = props;
  const { adults, children, infants } = formValues;
  const adultsCount = pathOr(0, [], adults);
  const childrenCount = pathOr(0, [], children);
  const totalParticipants = adultsCount + childrenCount;
  const hasParticipants = totalParticipants > 0;
  const infantsCount = pathOr(0, [], infants);
  useEffect(() => {
    if (adultsCount > 0 || (childrenCount === 0 && infantsCount === 0)) return;

    formProps.change('adults', 1);
  }, [childrenCount, infantsCount])
  const handleErase = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isSearchPage) {
      formProps.change('date', undefined);
      formProps.change('timeSlot', undefined);
    };

    if (!hasParticipants) return;

    formProps.change('adults', undefined);
    formProps.resetFieldState('adults');
    formProps.change('children', undefined);
    formProps.resetFieldState('children');
    formProps.change('infants', undefined);
    formProps.resetFieldState('infants');
  };
  const handlePrevious = (e) => {
    handleErase(e);
    handleView('DateView');
  };
  const currentCity = pathOr('', ['location', 'selectedPlace', 'city'], formValues);
  const minAdults = childrenCount > 0 || infantsCount > 0 ? 1 : 0;
  const headingText = intl.formatMessage({ id: 'Search.ParticipantsView.heading' });
  const totalParticipantsText = intl.formatMessage({ id: 'Search.totalParticipants' }, { count: totalParticipants });
  const infantsCountText = intl.formatMessage({ id: 'Search.infants' }, { count: infantsCount });
  const adultsLabelText = intl.formatMessage({ id: 'Search.ParticipantsView.adultsLabel' });
  const adultsSublabelText = intl.formatMessage({ id: 'Search.ParticipantsView.adultsSublabel' });
  const childrenLabelText = intl.formatMessage({ id: 'Search.ParticipantsView.childrenLabel' });
  const childrenSublabelText = intl.formatMessage({ id: 'Search.ParticipantsView.childrenSublabel' });
  const infantsLabelText = intl.formatMessage({ id: 'Search.ParticipantsView.infantsLabel' });
  const infantsSublabelText = intl.formatMessage({ id: 'Search.ParticipantsView.infantsSublabel' });
  const eraseText = intl.formatMessage({ id: 'Marketplace.erase' });
  const passText = intl.formatMessage({ id: 'Marketplace.pass' });
  const searchText = intl.formatMessage({ id: 'Search.ParticipantsView.search' });
  const classes = classNames(css.modalContent, {
    [css.modalContentFullScreen]: isSearchPage,
  }, className);
  const secondaryButtonClasses = classNames(css.secondaryButton, {
    [css.disabled]: !hasParticipants,
  });

  return (
    <ModalContent className={classes}>
      <ModalHeader className={css.modalHeader}>
        <h1 className={css.heading}>{headingText}</h1>
        <button
          className={css.backButton}
          type="button"
          onClick={isSearchPage ? () => handleView() : handlePrevious}
        >
          {isSearchPage ? (
            <IconClose />
          ) :  (
            <IconArrowHead className={css.iconArrow} direction="left" />
          )}
        </button>
        <div className={css.infoWrapper}>
          <span className={css.location}>{currentCity}</span>
          <span className={css.participants}>
            {totalParticipants > 0 && totalParticipantsText}
            {infantsCount > 0 && ', '}
            {infantsCount > 0 && infantsCountText}
          </span>
        </div>
      </ModalHeader>
      <ModalBody className={css.modalBody}>
        <FieldStepper
          className={css.fieldStepper}
          label={adultsLabelText}
          min={minAdults}
          name="adults"
          sublabel={adultsSublabelText}
        />
        <FieldStepper
          className={css.fieldStepper}
          label={childrenLabelText}
          min={0}
          name="children"
          sublabel={childrenSublabelText}
        />
        <FieldStepper
          className={css.fieldStepper}
          label={infantsLabelText}
          min={0}
          name="infants"
          sublabel={infantsSublabelText}
        />
      </ModalBody>
      <ModalFooter className={css.modalFooter}>
        {hasParticipants || isSearchPage ? (
          <button className={secondaryButtonClasses} onClick={handleErase} disabled={!hasParticipants}>
            {eraseText}
          </button>
        ) : (
          <button className={css.secondaryButton} type="submit" onClick={formProps.submit}>
            {passText}
          </button>
        )}
        <PrimaryButton
          className={css.nextButton}
          type="submit"
          onClick={formProps.submit}
        >
          <IconSearch className={css.iconSearch} />
          <span className={css.buttonText}>{searchText}</span>
        </PrimaryButton>
      </ModalFooter>
    </ModalContent>
  )
}

ParticipantsView.defaultProps = {
  className: null,
  formProps: {},
  formValues: {},
};

ParticipantsView.propTypes = {
  className: string,
  formProps: object,
  formValues: object,
  handleView: func.isRequired,
  intl: intlShape.isRequired,
  isSearchPage: bool.isRequired,
};

export default injectIntl(ParticipantsView);
