import React, { Fragment } from 'react';
import { bool, shape, string } from 'prop-types';
import { intlShape } from 'react-intl';
import { filter, keys, map, sort, type } from 'ramda';
import classNames from 'classnames';

import { TextContainer } from '../../../components';

import PolicyItem from './PolicyItem/PolicyItem';
import css from './SectionRulesMaybe.module.css';

const SectionRulesMaybe = props => {
  const { className, rootClassName, titleClassName, publicData, intl } = props;
  const classes = classNames(rootClassName || css.root, className);
  const {
    enfantsBienvenue,
    policyAlcohol,
    policyEvent,
    policyMusic,
    policyPets,
    policySmoking,
    rules
  } = publicData;
  const titleText = intl.formatMessage({ id: 'ListingPage.rulesTitle'});
  const childrenText = enfantsBienvenue ? intl.formatMessage({ id: 'ListingPage.childrenText'}) : intl.formatMessage({ id: 'ListingPage.noChildrenText'});
  const childrenHelper = intl.formatMessage({ id: 'ListingPage.noChildrenHelper'});
  const eventText = policyEvent ? intl.formatMessage({ id: 'ListingPage.eventText'}) : intl.formatMessage({ id: 'ListingPage.noEventText'});
  const alcoholText = policyAlcohol ? intl.formatMessage({ id: 'ListingPage.alcoholText'}) : intl.formatMessage({ id: 'ListingPage.noAlcoholText'});
  const smokingText = policySmoking ? intl.formatMessage({ id: 'ListingPage.smokingText'}) : intl.formatMessage({ id: 'ListingPage.noSmokingText'});
  const musicText = policyMusic ? intl.formatMessage({ id: 'ListingPage.musicText'}) : intl.formatMessage({ id: 'ListingPage.noMusicText'});
  const petsText = policyPets ? intl.formatMessage({ id: 'ListingPage.petsText'}) : intl.formatMessage({ id: 'ListingPage.noPetsText'});
  const additionalRules = intl.formatMessage({ id: 'ListingPage.additionalRules' });

  const hasPolicies = type(enfantsBienvenue) === "Boolean"
    || type(policyAlcohol) === "Boolean"
    || type(policyEvent) === "Boolean"
    || type(policyMusic) === "Boolean"
    || type(policyPets) === "Boolean"
    || type(policySmoking) === "Boolean";
  const policies = {
    alcohol: policyAlcohol,
    event: policyEvent,
    music: policyMusic,
    pets: policyPets,
    smoking: policySmoking,
  };

  const policiesObj = {
    event: {
      symbol: 0x1F382,
      text: eventText,
    },
    alcohol: {
      symbol: 0x1F942,
      text: alcoholText,
    },
    smoking: {
      symbol: 0x1F6AD,
      text: smokingText,
    },
    music: {
      symbol: 0x1F3B6,
      text: musicText,
    },
    pets: {
      symbol: 0x1F993,
      text: petsText,
    },
  };

  const orderedPolicies = [ 'event', 'alcohol', 'smoking', 'music', 'pets' ];
  const acceptedPolicies = map(key => ({ symbol: policiesObj[key].symbol, text: policiesObj[key].text }),
    sort((a, b) => orderedPolicies.indexOf(a) - orderedPolicies.indexOf(b), keys(filter(policy => policy === true, policies))));
  const noPolicies = map(key => ({ symbol: policiesObj[key].symbol, text: policiesObj[key].text }),
    sort((a, b) => orderedPolicies.indexOf(a) - orderedPolicies.indexOf(b), keys(filter(policy => policy === false, policies))));

  return publicData && (hasPolicies || rules) ? (
    <div className={classes}>
      <h2 className={titleClassName}>{titleText}</h2>
      {type(enfantsBienvenue) === "Boolean" ? (
        <div className={css.policiesContainer}>
          <PolicyItem symbol={0x1F9D2} text={childrenText} helper={childrenHelper} isForbidden={!enfantsBienvenue} />
        </div>
      ) : null}
      {noPolicies.length > 0 && (
        <div className={css.policiesContainer}>
          {map(policy => <PolicyItem key={policy.text} symbol={policy.symbol} text={policy.text} isForbidden />, noPolicies)}
        </div>
      )}
      {acceptedPolicies.length > 0 && (
        <div className={css.policiesContainer}>
          {map(policy => <PolicyItem key={policy.text} symbol={policy.symbol} text={policy.text} />, acceptedPolicies)}
        </div>
      )}
      {rules ? (
        <Fragment>
          <span className={css.additionalRules}>{additionalRules}</span>
          <TextContainer className={css.textContainer} text={rules} />
        </Fragment>
      ) : null}
    </div>
  ) : null;
};

SectionRulesMaybe.defaultProps = { className: null, rootClassName: null };

SectionRulesMaybe.propTypes = {
  className: string,
  titleClassName: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  publicData: shape({
    enfantsBienvenue: bool,
    policyAlcohol: bool,
    policyEvent: bool,
    policyMusic: bool,
    policyPets: bool,
    policySmoking: bool,
    rules: string,
  }),
};

export default SectionRulesMaybe;
