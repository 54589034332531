import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconAdd.css';

const IconAdd = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.5 3.95837V15.0417" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.95834 9.5H15.0417" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    
  );
};

const { string } = PropTypes;

IconAdd.defaultProps = {
  className: null,
  rootClassName: null,
};

IconAdd.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconAdd;
