import React from 'react';
import { string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { path, pathOr } from 'ramda';

import { propTypes } from '../../../util/types';
import { txIsAccepted } from '../../../util/transactions';
import { createSlug } from '../../../util/urlHelpers';
import { formatMoney } from '../../../util/currency';
import { NamedLink, ResponsiveImage } from '../../../components';

import BookingInfo from '../BookingInfo/BookingInfo';
import ContactActions from '../ContactActions/ContactActions';
import css from './ReservationCard.module.css';

const ReservationCard = (props) => {
  const {
    className,
    intl,
    transaction,
    type,
  } = props;
  const listing = path(['listing'], transaction);
  const isOrder = type === 'order';
  const hasNotBeenOpened = isOrder
    ? pathOr(false, ['attributes', 'metadata', 'hasNotBeenOpenedByCustomer'], transaction)
    : pathOr(false, ['attributes', 'metadata', 'hasNotBeenOpenedByProvider'], transaction);
  const showPrivateInfo = transaction && txIsAccepted(transaction);
  const listingUuid = pathOr('', ['id', 'uuid'], listing);
  const listingTitle = pathOr('', ['attributes', 'title'], listing);
  const slug = createSlug(listingTitle);
  const listingImg = listing.images && listing.images.length > 0 ? listing.images[0] : null;
  const bookingPrice = isOrder ? transaction.attributes.payinTotal : transaction.attributes.payoutTotal;
  const price = formatMoney(intl, bookingPrice, true);
  const priceLabel = intl.formatMessage({ id: 'ReservationsPage.ReservationCard.priceLabel' })
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <div className={css.mainContent}>
        <BookingInfo
          className={css.bookingInfo}
          intl={intl}
          isOrder={isOrder}
          tx={transaction}
          withPrice={!isOrder}
        />
        <ContactActions
          className={css.contactActions}
          intl={intl}
          isOrder={isOrder}
          showPrivateInfo={showPrivateInfo}
          transaction={transaction}
        />
      </div>
      {isOrder ? (
        <NamedLink
          className={css.listingLink}
          name="ListingPage"
          params={{ id: listingUuid, slug }}
        >
          <ResponsiveImage
            className={css.listingImage}
            alt={"listingImg"}
            image={listingImg}
            variants={['landscape-crop']}
          />
        </NamedLink>
      ) : (
        <div className={css.priceContainer}>
          <div className={css.priceWrapper}>
            <span className={css.priceLabel}>{priceLabel}</span>
            <span className={css.price}>{price}</span>
          </div>
        </div>
      )}
    </div>
  )
}

ReservationCard.defaultProps = {
  className: null,
};

ReservationCard.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  transaction: propTypes.transaction,
  type: string.isRequired,
};

export default ReservationCard;