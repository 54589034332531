import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPhone.css';

const IconPhone = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.4166 13.3708V15.7458C17.4175 15.9663 17.3724 16.1845 17.284 16.3866C17.1957 16.5886 17.0662 16.7699 16.9037 16.919C16.7412 17.068 16.5494 17.1815 16.3406 17.2521C16.1317 17.3228 15.9104 17.349 15.6908 17.3292C13.2547 17.0645 10.9147 16.232 8.85871 14.8987C6.94591 13.6833 5.32419 12.0615 4.10871 10.1487C2.77078 8.08345 1.93816 5.73203 1.6783 3.28499C1.65851 3.06607 1.68453 2.84543 1.75469 2.63711C1.82485 2.4288 1.93762 2.23737 2.08582 2.07503C2.23402 1.91268 2.41439 1.78297 2.61546 1.69416C2.81654 1.60534 3.0339 1.55937 3.25371 1.55916H5.62871C6.01291 1.55538 6.38538 1.69143 6.67669 1.94196C6.968 2.19248 7.15827 2.54039 7.21205 2.92083C7.31229 3.68088 7.49819 4.42715 7.76621 5.14541C7.87273 5.42877 7.89578 5.73672 7.83264 6.03277C7.7695 6.32883 7.62281 6.60058 7.40996 6.81583L6.40455 7.82124C7.53153 9.80322 9.17257 11.4443 11.1545 12.5712L12.16 11.5658C12.3752 11.353 12.647 11.2063 12.943 11.1432C13.2391 11.08 13.547 11.1031 13.8304 11.2096C14.5486 11.4776 15.2949 11.6635 16.055 11.7637C16.4395 11.818 16.7907 12.0117 17.0418 12.308C17.2929 12.6043 17.4263 12.9826 17.4166 13.3708Z" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

IconPhone.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconPhone.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconPhone;
