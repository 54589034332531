import React from 'react';
import css from './SimulatorSlideSwimPool.css';
import { injectIntl } from 'react-intl';

import iconFirstSlide from "../../assets/pictures/fin.png";
import iconSecondSlide from "../../assets/pictures/balance.png";
import iconThirdSlide from "../../assets/pictures/piano.png";

const TipsCard = props => {
  const { image, title, content } = props;

  return (
    <div className={css.tipsCardContainer}>
      <img className={css.tipsCardImg} src={image} alt={title} />
      <span className={css.tipsCardTitle}>{title}</span>
      <span className={css.tipsCardContent}>{content}</span>
    </div>
  )
}

const SimulatorSlideSwimPool = props => {
  const { intl } = props;

  const title = intl.formatMessage({ id: 'SimulatorPage.SimulatorSwimPoolTitle' });
  const subtitle = intl.formatMessage({ id: 'SimulatorPage.SimulatorSwimPoolSubTitle' });

  const firstTipsTitle = intl.formatMessage({ id: 'SimulatorPage.SimulatorSwimPoolFirstTitle' });
  const firstTipsContent = intl.formatMessage({ id: 'SimulatorPage.SimulatorSwimPoolFirstText' });
  const secondTipsTitle = intl.formatMessage({ id: 'SimulatorPage.SimulatorSwimPoolSecondTitle' });
  const secondTipsContent = intl.formatMessage({ id: 'SimulatorPage.SimulatorSwimPoolSecondText' });
  const thirdTipsTitle = intl.formatMessage({ id: 'SimulatorPage.SimulatorSwimPoolThirdTitle' });
  const thirdTipsContent = intl.formatMessage({ id: 'SimulatorPage.SimulatorSwimPoolThirdText' });

  return (
    <section className={css.sectionSwimPool}>
      <div className={css.sectionSwimPoolContainer}>
        <span className={css.sectionSwimPoolTitle}>{title}</span>
        <span className={css.sectionSwimPoolSubTitle}>{subtitle}</span>
        <div className={css.howItWorksSlider}>
          <TipsCard image={iconFirstSlide} title={firstTipsTitle} content={firstTipsContent} />
          <TipsCard image={iconSecondSlide} title={secondTipsTitle} content={secondTipsContent} />
          <TipsCard image={iconThirdSlide} title={thirdTipsTitle} content={thirdTipsContent} />
        </div>
      </div>
    </section>
  );
}

export default injectIntl(SimulatorSlideSwimPool);
