import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconRemove.module.css';

const IconRemove = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d="M18 13H6c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1s-.45 1-1 1z"/>
    </svg>
  );
};

IconRemove.defaultProps = {
  rootClassName: null,
  className: null,
};

IconRemove.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconRemove;