import { TRANSITIONS_TO_MASK } from '../util/transactions';

const NUM_WORDS_FR = 'zéro|zero|un|deux|trois|quatre|cinq|six|sept|huit|neuf|dix|onze|douze|treize|quatorze|quinze|seize|dix|vingt|vingts|trente|quarante|cinquante|soixante|cent|mille';
const NUM_WORDS_ES = 'cero|uno|dos|tres|cuatro|cinco|seis|siete|ocho|nuevo|diez|once|doce|trece|catorce|quince|dieci|veinte|veinti|treinta|cuarenta|cincuenta|sesenta|setenta|ochenta|noventa|cien|ciento|mil';
const NUM_WORDS_DE = 'eins|zwei|drei|vier|fünf|sechs|sieben|acht|neun|zehn|elf|zwölf|dreizehn|vierzehn|fünfzehn|sechzehn|siebzehn|achtzehn|neunzehn|zwanzig|dreißig|vierzig|fünfzig|sechzig|siebzig|achtzig|neunzig|hundert|tausend';
const NUM_WORDS_IT = 'zero|uno|due|tre|quattro|cinque|sei|sette|otto|nove|dieci|undici|dodici|tredici|quattordici|quindici|sedici|diciassette|diciotto|diciannove|venti|ventuno|ventitré|trenta|quaranta|cinquanta|sessanta|settanta|ottanta|novanta|cento|mille|mila';
const NUM_WORDS_EN = 'one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve|thirteen|fourteen|fifteen|sixteen|seventeen|eighteen|nineteen|twenty|thirty|forty|fifty|sixty|seventy|eighty|ninety|hundred|thousand';
const NUM_WORDS = NUM_WORDS_FR + NUM_WORDS_ES + NUM_WORDS_DE + NUM_WORDS_IT + NUM_WORDS_EN;
const SPECIAL_CHARACTERS = '\\s|\\+|\\-|\\.|\\/|[\]|\\(|\\)|\\*|\\_|\\%|\\@|\\!|x';
const BANNED_WORD = 'puis';
const MASK_REPLACEMENT = '_contact_mask_';
const PHONE_RE = new RegExp(`(\\d|\\+|\\(|${NUM_WORDS})(?:(\\d|${NUM_WORDS}|${SPECIAL_CHARACTERS}|${BANNED_WORD})){5,}(\\d|\\)|${NUM_WORDS})|^(?!\\b${MASK_REPLACEMENT}\\b)(?:(\\d|${NUM_WORDS}|${SPECIAL_CHARACTERS}|${BANNED_WORD})(?!.*\\d*h)){1,10}`, 'gis');
const EMAIL_RE = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}/gi;
const URL_RE = /(https?:\/\/)?(www.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/;
const SOCIAL_RE = /\b(insta|instagr|instagra|instagram|facebook|snapchat|snap)\b/gi;

export const replaceEmailAddresses = (text, replacement) => {
  if (!text) {
    return text;
  }
  return text.replace(EMAIL_RE, replacement);
};

export const replacePhoneNumbers = (text, replacement) => {
  if (!text) {
    return text;
  }
  return text.replace(PHONE_RE, replacement);
};

export const replaceURLs = (text, replacement) => {
  if (!text) {
    return text;
  }
  return text.replace(URL_RE, replacement);
};

export const replaceSocialMedia = (text, replacement) => {
  if (!text) {
    return text;
  }
  return text.replace(SOCIAL_RE, replacement);
}

export const replaceContactDetails = (text, replacement) => {
  return replaceEmailAddresses(
    replacePhoneNumbers(
      replaceURLs(text, replacement),
      replacement),
    replacement
  );
};

const MASK_REGEX = /_contact_mask_/g;

export const maskContactDetails = (messageContent) => replaceContactDetails(messageContent, MASK_REPLACEMENT);

export const maskContactDetailsByTx = (messageContent, tx, intl) => {
  const lastTransition = tx && tx.attributes.lastTransition;
  if (!lastTransition || TRANSITIONS_TO_MASK.includes(lastTransition)) {
    return maskContactDetails(messageContent, intl);
  }

  return messageContent;
};

export const replaceTextMask = (text, intl) => text.replace(MASK_REGEX, intl.formatMessage({ id: 'SwimmyActivityFeed.contactDetailsReplacement' }));
