import Decimal from 'decimal.js';
import { reduce } from 'ramda';

import { LINE_ITEM_UNITS, LINE_ITEM_UNITS1, LINE_ITEM_UNITS2 } from './types';
import { types as sdkTypes } from './sdkLoader';
import config from '../config';

const { LatLng, LatLngBounds, Money } = sdkTypes;

// Default multiplier for expanding search bounding box
const BOUNDS_MULTIPLIER = 3;

// earth's radius
const R = 6371000;

/**
 * Convert degrees to radians.
 *
 * @param {Number} degrees An angle in degrees
 *
 * @return {Numbers} angle converted to radians
 */
const toRadians = degrees => degrees * Math.PI / 180;

/**
 * Convert radians to degrees.
 *
 * @param {Number} radians An angle in radians
 *
 * @return {Numbers} angle converted to degrees
 */
const toDegrees = radians => radians * 180 / Math.PI;

/**
 * Calculates a destination point that is in a given distance
 * from a point in the given bearing.
 *
 * See Destination point in https://www.movable-type.co.uk/scripts/latlong.html
 *
 * @param {LatLng} point Point from which the destination is calculated
 * @param {Number} bearing The direction in which the destination point
 *  is located to from the original point
 * @param {Number} distance Distance of the destination in meters
 *
 * @return {LatLng} destination point
 */
const destinationPoint = (point, bearing, distance) => {
  const lat1Rad = toRadians(point.lat);
  const lng1Rad = toRadians(point.lng);
  const bearingRad = toRadians(bearing);
  const angularDistance = distance / R;

  const lat2Rad = Math.asin(
    Math.sin(lat1Rad) * Math.cos(angularDistance) +
      Math.cos(lat1Rad) * Math.sin(angularDistance) * Math.cos(bearingRad)
  );

  const lng2Rad =
    lng1Rad +
    Math.atan2(
      Math.sin(bearingRad) * Math.sin(angularDistance) * Math.cos(lat1Rad),
      Math.cos(angularDistance) - Math.sin(lat1Rad) * Math.sin(lat2Rad)
    );

  return {
    lat: toDegrees(lat2Rad),
    lng: toDegrees(lng2Rad),
  };
};

/**
 * Calculate the distance between two points on a sphere.
 *
 * See Distance in https://www.movable-type.co.uk/scripts/latlong.html
 *
 * @param {LatLng} point1
 * @param {LatLng} point2
 *
 * @return initial bearing in degrees
 */
const distanceBetween = (point1, point2) => {
  const lat1Rad = toRadians(point1.lat);
  const lat2Rad = toRadians(point2.lat);
  const deltaLatRad = toRadians(point2.lat - point1.lat);
  const deltaLngRad = toRadians(point2.lng - point1.lng);

  const a =
    Math.sin(deltaLatRad / 2) * Math.sin(deltaLatRad / 2) +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(deltaLngRad / 2) * Math.sin(deltaLngRad / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

/**
 * Calculate initial bearing between two points on a sphere.
 *
 * See Bearing in https://www.movable-type.co.uk/scripts/latlong.html
 *
 * @param {LatLng} point1
 * @param {LatLng} point2
 *
 * @return {Number} initial bearing in degrees
 */
const initialBearing = (point1, point2) => {
  const lat1Rad = toRadians(point1.lat);
  const lng1Rad = toRadians(point1.lng);
  const lat2Rad = toRadians(point2.lat);
  const lng2Rad = toRadians(point2.lng);

  const y = Math.sin(lng2Rad - lng1Rad) * Math.cos(lat2Rad);
  const x =
    Math.cos(lat1Rad) * Math.sin(lat2Rad) -
    Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(lng2Rad - lng1Rad);

  const bearingRad = Math.atan2(y, x);

  return toDegrees(bearingRad);
};

/**
 * Calculate final bearing between two points on a sphere.
 *
 * See Bearing in https://www.movable-type.co.uk/scripts/latlong.html
 *
 * @param {LatLng} point1
 * @param {LatLng} point2
 *
 * @return {Number} final bearing in degrees
 */
const finalBearing = (point1, point2) => {
  const bearing = initialBearing(point2, point1);
  return (bearing + 180) % 360;
};

/**
 * Expand the bounds that is move the SW and NE point
 * that define the bounds further away from each other.
 *
 * The expansion is limited only to bounds where the distance
 * between SW and NE is more then 500000 meters.
 *
 * @param {LatLngBounds} bounds Rectangle bounds defined
 *  with south-west and north-east corners
 * @param {String} address Address returned for given place
 * by the Google Maps API
 *
 * @return {LatLngBounds} expanded bounds
 */
const expandBounds = (bounds, address) => {
  const { sw, ne } = bounds;

  // distance between the bounding points
  const distance = distanceBetween(sw, ne);

  const customMultiplier = config.custom.customBoundsMultiplier[address];

  // if the distance is less than 200000 meters expand the bounds
  if (distance < 200000 || !!customMultiplier) {
    // if a custom multiplier can not be found use multiplier
    const multiplier = customMultiplier || BOUNDS_MULTIPLIER;

    // both points are moved further away the amount of the distanceIncrement
    const distanceIncrement = new Decimal(distance).times(multiplier).toNumber();

    // initial bearing when resolving the new sw point
    // is the final bearing from ne to sw
    const swInitialBearing = finalBearing(ne, sw);

    const newSw = destinationPoint(sw, swInitialBearing, distanceIncrement);

    // initial bearing when resolving the new ne point
    // is the final bearing from sw to ne
    const neInitialBearing = finalBearing(sw, ne);

    const newNe = destinationPoint(ne, neInitialBearing, distanceIncrement);

    return new LatLngBounds(new LatLng(newNe.lat, newNe.lng), new LatLng(newSw.lat, newSw.lng));
  } else {
    return new LatLngBounds(new LatLng(ne.lat, ne.lng), new LatLng(sw.lat, sw.lng));
  }
};

/**
 * Expands bounds of a place. Like expandBounds but takes and returns
 * a place object.
 *
 * @param {util.proptypes.place} place - a place object
 *
 * @return {util.proptypes.place} a place object with expanded bounds
 */
export const expandPlace = place => {
  const { address, origin, bounds, city, addressDetails } = place;
  console.log(place)

  return {
    address,
    origin: new LatLng(origin.lat, origin.lng),
    bounds: expandBounds(bounds, address),
    city,
    addressDetails,
  };
};

/**
 * Returns true or false depending if given transaction is created with a
 * booking process version that has one type of units (number of people).
 */
export const isOneUnitTransaction = tx => {
  const unitsLineItem = tx.attributes.lineItems.find(item => item.code === LINE_ITEM_UNITS);
  return !!unitsLineItem;
};

/**
 * Returns true or false depending if given transaction is created with a
 * booking process version that has two types of units (number of adults
 * and children).
 */
export const isTwoUnitsTransaction = tx => {
  const units1LineItem = tx.attributes.lineItems.find(item => item.code === LINE_ITEM_UNITS1);
  const units2LineItem = tx.attributes.lineItems.find(item => item.code === LINE_ITEM_UNITS2);

  return !!units1LineItem && !!units2LineItem;
};

/**
 * Plus operation for two Money objects.
 *
 * @param {Money} money1 money
 * @param {Money} money2 money
 *
 * @return {Money} sum of the parameters
 */
export const addMoney = (money1, money2) => {
  const amount1 = new Decimal(money1.amount);
  const amount2 = new Decimal(money2.amount);
  const currency = money1.currency;
  const totalAmount = amount1.plus(amount2);
  return new Money(totalAmount, currency);
};

/**
 * Plus operation for two Money objects.
 *
 * @param {Money} allMoney money
 *
 * @return {Money} sum of the parameters
 */
export const addMultipleMoney = (...allMoney) => {
  const { currency } = allMoney[0];
  const totalAmount = reduce((acc, { amount }) => {
    const amountDecimal = new Decimal(amount);
    return acc.plus(amountDecimal);
  }, new Decimal(0), allMoney);
  return new Money(totalAmount, currency);
};
