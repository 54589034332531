import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconError.module.css';

const IconError = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 11c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z"/>
    </svg>
  );
};

IconError.defaultProps = {
  rootClassName: null,
  className: null,
};

IconError.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconError;