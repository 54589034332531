import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './DataWithLabel.module.css';

const DataWithLabel = (props) => {
  const {
    className,
    labelClassName,
    valueClassName,
    label,
    value,
  } = props;
  const classes = classNames(css.root, className);
  const labelClasses = classNames(labelClassName || css.label);
  const valueClasses = classNames(valueClassName || css.value);

  return (
    <div className={classes}>
      <span className={labelClasses}>{label}</span>
      <span className={valueClasses}>{value}</span>
    </div>
  )
}

DataWithLabel.defaultProps = {
  className: null,
  labelClassName: null,
  valueClassName: null,
};

DataWithLabel.propTypes = {
  className: string,
  labelClassName: string,
  valueClassName: string,
  label: string.isRequired,
  value: string.isRequired,
};

export default DataWithLabel;