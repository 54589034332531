import { filter, map } from 'ramda';

import { types as sdkTypes } from '../../util/sdkLoader';
import { moment, resetToStartOfDay } from '../../util/dates';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_ACCEPT,
  TRANSITION_INSTANT_ACCEPT,
  TRANSITION_MARK_NON_REFUNDABLE
} from '../../util/transactions';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

import {
  formatAvailabilityException,
  getAvailabilityExceptionsOverlapped,
} from './CalendarPage.helpers';

const { UUID } = sdkTypes;

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const SHOW_LISTING_REQUEST = 'app/CalendarPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/CalendarPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/CalendarPage/SHOW_LISTING_ERROR';

export const FETCH_EXCEPTIONS_REQUEST = 'app/CalendarPage/FETCH_AVAILABILITY_EXCEPTIONS_REQUEST';
export const FETCH_EXCEPTIONS_SUCCESS = 'app/CalendarPage/FETCH_AVAILABILITY_EXCEPTIONS_SUCCESS';
export const FETCH_EXCEPTIONS_ERROR = 'app/CalendarPage/FETCH_AVAILABILITY_EXCEPTIONS_ERROR';

export const ADD_EXCEPTION_REQUEST = 'app/CalendarPage/ADD_AVAILABILITY_EXCEPTION_REQUEST';
export const ADD_EXCEPTION_SUCCESS = 'app/CalendarPage/ADD_AVAILABILITY_EXCEPTION_SUCCESS';
export const ADD_EXCEPTION_ERROR = 'app/CalendarPage/ADD_AVAILABILITY_EXCEPTION_ERROR';

export const DELETE_EXCEPTION_REQUEST = 'app/CalendarPage/DELETE_AVAILABILITY_EXCEPTION_REQUEST';
export const DELETE_EXCEPTION_SUCCESS = 'app/CalendarPage/DELETE_AVAILABILITY_EXCEPTION_SUCCESS';
export const DELETE_EXCEPTION_ERROR = 'app/CalendarPage/DELETE_AVAILABILITY_EXCEPTION_ERROR';

export const UPDATE_AVAILABILITY_PLAN_REQUEST = 'app/CalendarPage/UPDATE_AVAILABILITY_PLAN_REQUEST';
export const UPDATE_AVAILABILITY_PLAN_SUCCESS = 'app/CalendarPage/UPDATE_AVAILABILITY_PLAN_SUCCESS';
export const UPDATE_AVAILABILITY_PLAN_ERROR = 'app/CalendarPage/UPDATE_AVAILABILITY_PLAN_ERROR';

export const FETCH_TRANSACTIONS_REQUEST = 'app/CalendarPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/CalendarPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/CalendarPage/FETCH_TRANSACTIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  listingRef: null,
  showListingsError: null,
  fetchExceptionsError: null,
  fetchExceptionsInProgress: false,
  availabilityExceptions: [],
  addExceptionError: null,
  addExceptionInProgress: false,
  deleteExceptionError: null,
  deleteExceptionInProgress: false,
  updateAvailabilityPlanError: null,
  updateAvailabilityPlanInProgress: false,
  fetchTransactionsError: null,
  fetchTransactionsInProgress: false,
  transactions: []
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_LISTING_REQUEST:
      return { ...state, listingRef: null, showListingsError: null };
    case SHOW_LISTING_SUCCESS:
      const listingRef = { id: payload.data.id, type: 'ownListing' };
      return { ...state, listingRef };

    case SHOW_LISTING_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, showListingsError: payload, redirectToListing: false };

    case FETCH_EXCEPTIONS_REQUEST:
      return {
        ...state,
        availabilityExceptions: [],
        fetchExceptionsError: null,
        fetchExceptionsInProgress: true,
      };
    case FETCH_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        availabilityExceptions: payload,
        fetchExceptionsError: null,
        fetchExceptionsInProgress: false,
      };
    case FETCH_EXCEPTIONS_ERROR:
      return {
        ...state,
        fetchExceptionsError: payload.error,
        fetchExceptionsInProgress: false,
      };

    case ADD_EXCEPTION_REQUEST:
      return {
        ...state,
        addExceptionError: null,
        addExceptionInProgress: true,
      };
    case ADD_EXCEPTION_SUCCESS:
      return {
        ...state,
        availabilityExceptions: [...state.availabilityExceptions, ...payload],
        addExceptionInProgress: false,
      };
    case ADD_EXCEPTION_ERROR:
      return {
        ...state,
        addExceptionError: payload.error,
        addExceptionInProgress: false,
      };

    case DELETE_EXCEPTION_REQUEST:
      return {
        ...state,
        deleteExceptionError: null,
        deleteExceptionInProgress: true,
      };
    case DELETE_EXCEPTION_SUCCESS: {
      const deletedExceptionIds = map(exception => exception.id.uuid, payload);
      const availabilityExceptions = filter(exception => !deletedExceptionIds.includes(exception.id.uuid), state.availabilityExceptions);
      return {
        ...state,
        availabilityExceptions,
        deleteExceptionInProgress: false,
      };
    }
    case DELETE_EXCEPTION_ERROR:
      return {
        ...state,
        deleteExceptionError: payload.error,
        deleteExceptionInProgress: false,
      };

    case UPDATE_AVAILABILITY_PLAN_REQUEST:
      return {
        ...state,
        updateAvailabilityPlanError: null,
        updateAvailabilityPlanInProgress: true,
      };
    case UPDATE_AVAILABILITY_PLAN_SUCCESS: {
      return {
        ...state,
        updateAvailabilityPlanInProgress: false,
      };
    }
    case UPDATE_AVAILABILITY_PLAN_ERROR:
      return {
        ...state,
        updateAvailabilityPlanError: payload.error,
        updateAvailabilityPlanInProgress: false,
      };

    case FETCH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        transactions: [],
        fetchTransactionsError: null,
        fetchTransactionsInProgress: true,
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: payload,
        fetchTransactionsError: null,
        fetchTransactionsInProgress: false,
      };
    case FETCH_TRANSACTIONS_ERROR:
      return {
        ...state,
        fetchTransactionsError: payload.error,
        fetchTransactionsInProgress: false,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

// All the action creators that don't have the {Success, Error} suffix
// take the params object that the corresponding SDK endpoint method
// expects.

// SDK method: ownListings.show
export const showListing = requestAction(SHOW_LISTING_REQUEST);
export const showListingSuccess = successAction(SHOW_LISTING_SUCCESS);
export const showListingError = errorAction(SHOW_LISTING_ERROR);

// SDK method: availabilityExceptions.query
export const fetchAvailabilityExceptionsRequest = requestAction(FETCH_EXCEPTIONS_REQUEST);
export const fetchAvailabilityExceptionsSuccess = successAction(FETCH_EXCEPTIONS_SUCCESS);
export const fetchAvailabilityExceptionsError = errorAction(FETCH_EXCEPTIONS_ERROR);

// SDK method: availabilityExceptions.create
export const addAvailabilityExceptionRequest = requestAction(ADD_EXCEPTION_REQUEST);
export const addAvailabilityExceptionSuccess = successAction(ADD_EXCEPTION_SUCCESS);
export const addAvailabilityExceptionError = errorAction(ADD_EXCEPTION_ERROR);

// SDK method: availabilityExceptions.delete
export const deleteAvailabilityExceptionRequest = requestAction(DELETE_EXCEPTION_REQUEST);
export const deleteAvailabilityExceptionSuccess = successAction(DELETE_EXCEPTION_SUCCESS);
export const deleteAvailabilityExceptionError = errorAction(DELETE_EXCEPTION_ERROR);

// SDK method: ownListings.update
export const updateAvailabilityPlanRequest = requestAction(UPDATE_AVAILABILITY_PLAN_REQUEST);
export const updateAvailabilityPlanSuccess = successAction(UPDATE_AVAILABILITY_PLAN_SUCCESS);
export const updateAvailabilityPlanError = errorAction(UPDATE_AVAILABILITY_PLAN_ERROR);

// SDK method: availabilityExceptions.query
export const fetchTransactionsRequest = requestAction(FETCH_TRANSACTIONS_REQUEST);
export const fetchTransactionsSuccess = successAction(FETCH_TRANSACTIONS_SUCCESS);
export const fetchTransactionsError = errorAction(FETCH_TRANSACTIONS_ERROR);

// ================ Thunk ================ //

export function requestShowListing(actionPayload) {
  return (dispatch, getState, sdk) => {
    dispatch(showListing(actionPayload));
    return sdk.ownListings
      .show(actionPayload)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(showListingSuccess(response));
        return response;
      })
      .catch(e => dispatch(showListingError(storableError(e))));
  };
}

export const requestAddAvailabilityExceptions = (params) => (dispatch, getState, sdk) => {
  dispatch(addAvailabilityExceptionRequest(params));
  const availabilityExceptions = getState().CalendarPage.availabilityExceptions;

  return Promise.all(map(async date => {
    const availabilityException = formatAvailabilityException({ date, ...params });
    const availabilityExceptionsOverlapped = getAvailabilityExceptionsOverlapped(availabilityException, availabilityExceptions);
    
    if (availabilityExceptionsOverlapped.length > 0) {
      await dispatch(requestDeleteAvailabilityExceptions(availabilityExceptionsOverlapped));
    }

    return sdk.availabilityExceptions.create(availabilityException, { expand: true });
  }, params.selectedDates))
    .then(responses => {
      const createdAvailabilityExceptions = map(response => response.data.data, responses);
      return dispatch(addAvailabilityExceptionSuccess({ data: createdAvailabilityExceptions }));
    })
    .catch(e => {
      dispatch(addAvailabilityExceptionError({ error: storableError(e) }));
      throw e;
    })
};

export const requestDeleteAvailabilityException = params => (dispatch, getState, sdk) => {
  dispatch(deleteAvailabilityExceptionRequest(params));

  return sdk.availabilityExceptions
    .delete(params, { expand: true })
    .then(response => {
      const availabilityException = response.data.data;
      return dispatch(deleteAvailabilityExceptionSuccess({ data: availabilityException }));
    })
    .catch(e => {
      dispatch(deleteAvailabilityExceptionError({ error: storableError(e) }));
      throw e;
    });
};

export const requestDeleteAvailabilityExceptions = (exceptionsToDelete) => (dispatch, getState, sdk) => {
  dispatch(deleteAvailabilityExceptionRequest(exceptionsToDelete));

  return Promise.all(map(({ id }) => (
    sdk.availabilityExceptions.delete({ id: new UUID(id) }, { expand: false })
  ), exceptionsToDelete))
    .then(responses => {
      const deletedAvailabilityExceptions = map(response => response.data.data, responses);
      return dispatch(deleteAvailabilityExceptionSuccess({ data: deletedAvailabilityExceptions }));
    })
    .catch(e => {
      dispatch(deleteAvailabilityExceptionError({ error: storableError(e) }));
      throw e;
    })
};

export const requestFetchAvailabilityExceptions = fetchParams => (dispatch, getState, sdk) => {
  dispatch(fetchAvailabilityExceptionsRequest(fetchParams));

  return sdk.availabilityExceptions
    .query(fetchParams, { expand: true })
    .then(response => {
      const availabilityExceptions = denormalisedResponseEntities(response);
      console.log('availabilityExceptions', availabilityExceptions)
      return dispatch(fetchAvailabilityExceptionsSuccess({ data: availabilityExceptions }));
    })
    .catch(e => {
      return dispatch(fetchAvailabilityExceptionsError({ error: storableError(e) }));
    });
};

export const requestUpdateAvailabilityPlan = params => (dispatch, getState, sdk) => {
  dispatch(updateAvailabilityPlanRequest(params));

  return sdk.ownListings
    .update(params, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(updateAvailabilityPlanSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(updateAvailabilityPlanError({ error: storableError(e) }));
      throw e;
    })
}

export const requestFetchTransactions = (fetchParams) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionsRequest(fetchParams));

  return sdk.transactions
    .query(fetchParams, { expand: true })
    .then(response => {
      const transactions = denormalisedResponseEntities(response);
      return dispatch(fetchTransactionsSuccess({ data: transactions }));
    })
    .catch(e => {
      return dispatch(fetchTransactionsError({ error: storableError(e) }));
    });
};

export const loadData = params => (dispatch, getState, sdk) => {
  const { id } = params;

  const payload = {
    id: new UUID(id),
    include: ['author', 'images'],
    'fields.image': ['variants.square-small', 'variants.landscape-crop', 'variants.landscape-crop2x'],
  };

  return Promise.all([dispatch(requestShowListing(payload)), dispatch(fetchCurrentUser())])
    .then(response => {
      // Because of two dispatch functions, response is an array.
      // We are only interested in the response from requestShowListing here,
      // so we need to pick the first one
      if (response[0].data && response[0].data.data) {
        const listing = response[0].data.data;

        // If the listing doesn't have availabilityPlan yet
        // use the defaul timezone
        const availabilityPlan = listing.attributes.availabilityPlan;

        const tz = availabilityPlan
          ? availabilityPlan.timezone
          : 'Etc/UTC';

        const today = new Date();
        const start = resetToStartOfDay(today, tz, 0);
        // Query range: today + 364 days
        const exceptionRange = 364;
        const end = moment(start).add(1, 'months').startOf('month').toISOString();

        // NOTE: in this template, we don't expect more than 100 exceptions.
        // If there are more exceptions, pagination kicks in and we can't use frontend sorting.
        const params = {
          listingId: listing.id,
          start,
          end,
        };
        dispatch(requestFetchAvailabilityExceptions(params));

        const transactionsParams = {
          only: 'sale',
          lastTransitions: [TRANSITION_ACCEPT, TRANSITION_INSTANT_ACCEPT, TRANSITION_MARK_NON_REFUNDABLE],
          include: ['listing'],
          'fields.transaction': ['protectedData.date', 'protectedData.endDate'],
          'fields.listing': []
        };
        dispatch(requestFetchTransactions(transactionsParams))
      }

      return response;
    })
    .catch(e => {
      throw e;
    });
};
