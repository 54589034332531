import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import { IconCheckmarkNew } from '../../components';

import css from './Checkbox.module.css';

const Checkbox = (props) => {
  const {
    className,
    checkedClassName,
    checked,
  } = props;
  const classes = classNames(css.root, className, {
    [checkedClassName || css.checked]: checked,
  });

  return (
    <div className={classes}>
      {checked && <IconCheckmarkNew />}
    </div>
  )
}

Checkbox.defaultProps = {
  className: null,
  checkedClassName: null,
};

Checkbox.propTypes = {
  className: string,
  checked: bool.isRequired,
  checkedClassName: string,
};

export default Checkbox;