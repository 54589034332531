import { filter, find } from 'ramda';

import config from '../../config';
import {
  getStartHours,
  getEndHours,
  isInRange,
  isSameDate,
  resetToStartOfDay,
  dateIsAfter,
  findNextBoundary,
  moment,
  monthIdStringInTimeZone,
} from '../../util/dates';
import { getTimeAsObject } from '../../util/time';

// MAX_TIME_SLOTS_RANGE is the maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged:
// https://stripe.com/docs/connect/account-balances#holding-funds
//
// See also the API reference for querying time slots:
// https://www.sharetribe.com/api-reference/marketplace.html#query-time-slots

const MAX_TIME_SLOTS_RANGE = config.dayCountAvailableForBooking;

export const endOfRange = (date, timeZone) => {
  return resetToStartOfDay(date, timeZone, MAX_TIME_SLOTS_RANGE - 1);
};

export const getAvailableStartTimes = (intl, timeZone, bookingStart, timeSlotsOnSelectedDate, minimumHoursPerBooking) => {
  if (timeSlotsOnSelectedDate.length === 0 || !timeSlotsOnSelectedDate[0] || !bookingStart) {
    return [];
  }
  const bookingStartDate = resetToStartOfDay(bookingStart, timeZone);

  const allHours = timeSlotsOnSelectedDate.reduce((availableHours, t) => {
    const startDate = t.attributes.start;
    const endDate = moment(t.attributes.end).subtract(minimumHoursPerBooking - 1, 'hours').toDate();
    const nextDate = resetToStartOfDay(bookingStartDate, timeZone, 1);

    // If the start date is after timeslot start, use the start date.
    // Otherwise use the timeslot start time.
    const startLimit = dateIsAfter(bookingStartDate, startDate) ? bookingStartDate : startDate;
    const isValidStartLimit = moment(startLimit).hour() > config.custom.minStartHourToBook;
    const validStartLimit = isValidStartLimit ? startLimit : moment(startLimit).set({ hour: config.custom.minStartHourToBook }).toDate();

    // If date next to selected start date is inside timeslot use the next date to get the hours of full day.
    // Otherwise use the end of the timeslot.
    const endLimit = dateIsAfter(endDate, nextDate) ? moment(nextDate).subtract(minimumHoursPerBooking - 1, 'hours').toDate() : endDate;

    const hours = getStartHours(intl, timeZone, validStartLimit, endLimit);
    
    return availableHours.concat(hours);
  }, []);

  return allHours;
};

export const getAvailableEndTimes = (
  intl,
  timeZone,
  bookingStartTime,
  bookingEndDate,
  selectedTimeSlot,
  minimumHoursPerBooking,
) => {
  if (!selectedTimeSlot || !selectedTimeSlot.attributes || !bookingEndDate || !bookingStartTime) {
    return [];
  }

  const startTimeObj = getTimeAsObject(bookingStartTime);
  const endDate = selectedTimeSlot.attributes.end;
  const bookingStartTimeAsDate = moment(bookingEndDate).set({ hour: startTimeObj.hours + minimumHoursPerBooking - 1 }).toDate();

  const dayAfterBookingEnd = resetToStartOfDay(bookingEndDate, timeZone, 1);
  const dayAfterBookingStart = resetToStartOfDay(bookingStartTimeAsDate, timeZone, 1);
  const startOfEndDay = resetToStartOfDay(bookingEndDate, timeZone);

  let startLimit;
  let endLimit;

  if (!dateIsAfter(startOfEndDay, bookingStartTimeAsDate)) {
    startLimit = bookingStartTimeAsDate;
    endLimit = dateIsAfter(dayAfterBookingStart, endDate) ? endDate : dayAfterBookingStart;
  } else {
    // If the end date is on the same day as the selected booking start time
    // use the start time as limit. Otherwise use the start of the selected end date.
    startLimit = dateIsAfter(bookingStartTimeAsDate, startOfEndDay)
      ? bookingStartTimeAsDate
      : startOfEndDay;

    // If the selected end date is on the same day as timeslot end, use the timeslot end.
    // Else use the start of the next day after selected date.
    endLimit = isSameDate(resetToStartOfDay(endDate, timeZone), startOfEndDay)
      ? endDate
      : dayAfterBookingEnd;
  }

  return getEndHours(intl, timeZone, startLimit, endLimit);
};

export const getTimeSlots = (timeSlots, date, timeZone) => {
  return timeSlots && timeSlots[0]
    ? filter(t => isInRange(date, t.attributes.start, t.attributes.end, 'day', timeZone), timeSlots)
    : [];
};

// Use start date to calculate the first possible start time or times, end date and end time or times.
// If the selected value is passed to function it will be used instead of calculated value.
export const getAllTimeValues = (
  intl,
  timeZone,
  timeSlots,
  startDate,
  selectedStartTime,
  selectedEndDate,
  minimumHoursPerBooking
) => {
  const startTimes = selectedStartTime
    ? []
    : getAvailableStartTimes(
        intl,
        timeZone,
        startDate,
        getTimeSlots(timeSlots, startDate, timeZone),
        minimumHoursPerBooking
      );

  // Value selectedStartTime is a string when user has selected it through the form.
  // That's why we need to convert also the timestamp we use as a default
  // value to string for consistency. This is expected later when we
  // want to compare the sartTime and endTime.
  const startTime = selectedStartTime
    ? selectedStartTime
    : startTimes.length > 0 && startTimes[0] && startTimes[0].timeOfDay
    ? startTimes[0].timeOfDay
    : null;

  const startTimeObj = getTimeAsObject(startTime);
  const startTimeAsDate = startTime ? moment(startDate).set({ hour: startTimeObj.hours }).toDate() : null;

  // Note: We need to remove 1ms from the calculated endDate so that if the end
  // date would be the next day at 00:00 the day in the form is still correct.
  // Because we are only using the date and not the exact time we can remove the
  // 1ms.
  const endDate = selectedEndDate
    ? selectedEndDate
    : startTimeAsDate
    ? new Date(findNextBoundary(timeZone, startTimeAsDate).getTime() - 1)
    : null;

  const selectedTimeSlot = find(t => isInRange(startTimeAsDate, t.attributes.start, t.attributes.end), timeSlots);

  const endTimes = getAvailableEndTimes(intl, timeZone, startTime, startDate, selectedTimeSlot, minimumHoursPerBooking);

  // We need to convert the timestamp we use as a default value
  // for endTime to string for consistency. This is expected later when we
  // want to compare the sartTime and endTime.
  const endTime =
    endTimes.length > 0 && endTimes[0] && endTimes[0].timeOfDay
      ? endTimes[0].timeOfDay
      : null;

  return { startTime, endDate, endTime, selectedTimeSlot };
};

export const getMonthlyTimeSlots = (monthlyTimeSlots, date, timeZone) => {
  const monthId = monthIdStringInTimeZone(date, timeZone);

  return !monthlyTimeSlots || Object.keys(monthlyTimeSlots).length === 0
    ? []
    : monthlyTimeSlots[monthId] && monthlyTimeSlots[monthId].timeSlots
    ? monthlyTimeSlots[monthId].timeSlots
    : [];
};
