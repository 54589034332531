import React from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { ensureCurrentUser } from '../../../../util/data';
import { propTypes } from '../../../../util/types';
import { updateProfile, uploadImage } from '../../../../ducks/userSettings.duck';
import ProfileSettingsForm from '../../../../forms/ProfileSettingsForm/ProfileSettingsForm';

import css from './ProfileSettingsTab.module.css';

const onImageUploadHandler = (values, fn) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
};

const ProfileSettingsTab = props => {
  const {
    currentUser,
    image,
    onImageUpload,
    onUpdateProfile,
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
  } = props;
  const handleSubmit = values => {
    const { bio: rawBio } = values;
    const bio = rawBio || '';
    const profile = { bio };
    const uploadedImage = image;
    const updatedValues =
      uploadedImage && uploadedImage.imageId && uploadedImage.file
        ? { ...profile, profileImageId: uploadedImage.imageId }
        : profile;

    onUpdateProfile(updatedValues);
  };
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const { bio } = ensuredCurrentUser.attributes.profile;
  const profileImageId = ensuredCurrentUser.profileImage ? ensuredCurrentUser.profileImage.id : null;
  const profileImage = image || { imageId: profileImageId };

  return (
    <div>
      <ProfileSettingsForm
        className={css.form}
        currentUser={ensuredCurrentUser}
        initialValues={{ bio, profileImage: ensuredCurrentUser.profileImage }}
        profileImage={profileImage}
        onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
        uploadInProgress={uploadInProgress}
        updateInProgress={updateInProgress}
        uploadImageError={uploadImageError}
        updateProfileError={updateProfileError}
        onSubmit={handleSubmit}
      />
    </div>
  )
};

ProfileSettingsTab.defaultProps = {
  currentUser: null,
  uploadImageError: null,
  updateProfileError: null,
  image: null,
};

ProfileSettingsTab.propTypes = {
  currentUser: propTypes.currentUser,
  image: shape({
    id: string,
    imageId: propTypes.uuid,
    file: object,
    uploadedImage: propTypes.image,
  }),
  onImageUpload: func.isRequired,
  onUpdateProfile: func.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  } = state.userSettings;

  return {
    currentUser,
    image,
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onImageUpload: data => dispatch(uploadImage(data)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProfileSettingsTab);
