import React from 'react';
import { string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import config from '../../config';

import { getEventProps } from './EventContactInfo.helpers';
import css from './EventContactInfo.module.css';

const EventContactInfo = (props) => {
  const {
    className,
    eventType,
    intl,
  } = props;
  const eventProps = getEventProps(eventType);
  const infoText = intl.formatMessage({ id: eventProps.id });
  const phoneText = intl.formatMessage({ id: 'EventContactInfo.phone' });
  const mailText = intl.formatMessage({ id: 'EventContactInfo.mail' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <img className={css.img} src={eventProps.img} alt={eventProps.alt} />
      <div className={css.infoContainer}>
        <span className={css.info}>{infoText}</span>
        <div className={css.contactContainer}>
          <div className={css.contactWrapper}>
            <span>{`${mailText} : `}</span>
            <a href={`mailto:${eventProps.mail}`}>{eventProps.mail}</a>
          </div>
          <div className={css.contactWrapper}>
            <span>{`${phoneText} : `}</span>
            <a href={`tel:${config.custom.supportPhoneNumber}`}>{config.custom.supportPhoneNumberReadable}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

EventContactInfo.defaultProps = {
  className: null,
  eventType: null,
};

EventContactInfo.propTypes = {
  className: string,
  eventType: string,
  intl: intlShape.isRequired,
};

export default injectIntl(EventContactInfo);
