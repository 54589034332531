import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { bool, func, string } from 'prop-types';
import classNames from 'classnames';

import { isServer } from '../../../util/sdkLoader';
import { FadeAnimation, SlideAnimation } from '../../../components/Animations';

import css from './ModalSwimmy.module.css';

const KEY_CODE_ESCAPE = 27;

const ModalSwimmy = (props) => {
  const {
    children,
    className,
    handleClickOutside,
    id,
    isOpen,
    onClose,
    onManageDisableScrolling,
  } = props;
  const modalRef = useRef();
  useEffect(() => {
    onManageDisableScrolling(id, isOpen);
    const modalRefCurrent = modalRef.current;
    if (!modalRefCurrent) return;

    modalRefCurrent.focus();
  }, [isOpen]);
  const handleClose = (e) => {
    onManageDisableScrolling(id, false);
    onClose(e);
  };
  const handleCloseByPressingKey = (e) => {
    if (e.keyCode !== KEY_CODE_ESCAPE || !onClose) return;
    handleClose(e);
  };
  const handleCloseByClickingOutside = (e) => {
    if (!handleClickOutside || e.target !== e.currentTarget || !onClose) return;
    handleClose(e);
  };
  const classes = classNames(css.root, className);

  return !isServer() ? ReactDOM.createPortal((
    <FadeAnimation inProps={isOpen} mountOnEnter unmountOnExit>
      <div
        className={classes}
        ref={modalRef}
        onClick={handleCloseByClickingOutside}
        onKeyDown={handleCloseByPressingKey}
        tabIndex={0}
      >
        {children}
      </div>
    </FadeAnimation>
  ), document.body) : null;
};

ModalSwimmy.defaultProps = {
  className: null,
  animationType: 'fade',
  handleClickOutside: true,
};

ModalSwimmy.propTypes = {
  className: string,
  id: string.isRequired,
  animationType: string,
  handleClickOutside: bool,
  isOpen: bool.isRequired,
  onClose: func,
  onManageDisableScrolling: func.isRequired,
};

export default ModalSwimmy;
