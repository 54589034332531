import React, { useEffect } from 'react';
import { func, number, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';

import { IconAdd, IconRemove } from '../../components/Icons';
import { ValidationError } from '../../components';

import css from './FieldNumericStepper.css';

const FieldNumericStepperComponent = props => {
    const {
        rootClassName,
        className,
        inputClassName,
        stepperClassName,
        id,
        input,
        label,
        helper,
        customErrorText,
        meta,
        min,
        max,
        step,
        ...rest
    } = props;

    if (label && !id) {
        throw new Error('id required when a label is given');
    }
    const reduceDisabled = input.value <= min;
    const increaseDisabled = max && input.value >= max;

    useEffect(() => {
        if (input.value === "") {
            input.onChange(min);
        };
    });

    useEffect (() => {
        if (!min || input.value != "") return;
        input.onChange(min);
    }, [min]);

    const handleReduce = (e) => {
      if (reduceDisabled) return;

      input.onChange(input.value - step);
    };
    const handleIncrease = (e) => {
      if (increaseDisabled) return;

      input.onChange(input.value + step);
    };

    const classes = classNames(rootClassName || css.root, className);
    const inputClasses = classNames(css.inputContainer, inputClassName);
    const stepperClasses = classNames(css.stepper, stepperClassName);
    const { invalid, error } = meta;
    const errorText = customErrorText || error;
    const hasError = !!customErrorText || !!(invalid && error);
    const fieldMeta = { touched: hasError, error: errorText };
    const inputProps = {
        className: css.input,
        id,
        type: "number",
        readOnly: "readOnly",
        ...input,
        ...rest
    };
    const reduceButtonClasses = classNames(css.button, { [css.buttonDisabled]: reduceDisabled });
    const increaseButtonClasses = classNames(css.button, { [css.buttonDisabled]: increaseDisabled });

    return (
        <div className={classes}>
            <div className={inputClasses}>
                <div className={css.labelContainer}>
                    {label ? <label className={css.label} htmlFor={id}>{label}</label> : null}
                    {helper ? <span className={css.helper}>{helper}</span> : null}
                </div>
                <div className={stepperClasses}>
                    <button
                    className={reduceButtonClasses}
                    disabled={reduceDisabled}
                    onClick={handleReduce}
                    type="button"
                    >
                        <IconRemove />
                    </button>
                        <input {...inputProps} />
                    <button
                    className={increaseButtonClasses}
                    disabled={increaseDisabled}
                    onClick={handleIncrease}
                    type="button"
                    >
                        <IconAdd />
                    </button>
                </div>
            </div>
            <ValidationError fieldMeta={fieldMeta} />
        </div>
    )
};

FieldNumericStepperComponent.defaultProps = {
    rootClassName: null,
    className: null,
    inputClassName: null,
    stepperClassName: null,
    customErrorText: null,
    id: null,
    label: null,
    min: 0,
    step: 1,
};
  
FieldNumericStepperComponent.propTypes = {
    rootClassName: string,
    className: string,
    inputClassName: string,
    stepperClassName: string,
    helper: string,
    min: number,
    max: number,
    step: number,
  
    // Error message that can be manually passed to input field,
    // overrides default validation message
    customErrorText: string,
  
    // Label is optional, but if it is given, an id is also required so
    // the label can reference the input in the `for` attribute
    id: string,
    label: string,
  
    // Generated by final-form's Field component
    input: shape({
        onChange: func.isRequired,
    }).isRequired,
    meta: object.isRequired,
};

const FieldNumericStepper = props => {
    return <Field component={FieldNumericStepperComponent} {...props} />;
};
  
export default FieldNumericStepper;
