import React from 'react';
import { func, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';

import css from './FieldRadioButton.module.css';

const FieldRadioButton = (props) => (
  <Field
    {...props}
    type="radio"
    render={(fieldRenderProps) => {
      const {
        rootClassName,
        className,
        selectedClassName,
        labelClassName,
        id,
        input,
        onClick,
        label,
      } = fieldRenderProps;
      const handleClick = () => onClick(input);
      const classes = classNames(rootClassName || css.root, className, {
        [selectedClassName || css.selected]: input.checked,
      });

      return (
        <button className={classes} type="button" onClick={handleClick}>
          <label className={labelClassName}>{label}</label>
        </button>
      )
    }}
  />
);

FieldRadioButton.defaultProps = {
  rootClassName: null,
  className: null,
  selectedClassName: null,
  id: null,
};

FieldRadioButton.propTypes = {
  rootClassName: string,
  className: string,
  selectedClassName: string,
  id: string,
  name: string.isRequired,
  onClick: func.isRequired,
  value: string.isRequired,
  label: string.isRequired,
};

export default FieldRadioButton;