export const hourArray = [
  { key: 0, value: '00:00', text: '00:00' },
  { key: 1, value: '01:00', text: '01:00' },
  { key: 2, value: '02:00', text: '02:00' },
  { key: 3, value: '03:00', text: '03:00' },
  { key: 4, value: '04:00', text: '04:00' },
  { key: 5, value: '05:00', text: '05:00' },
  { key: 6, value: '06:00', text: '06:00' },
  { key: 7, value: '07:00', text: '07:00' },
  { key: 8, value: '08:00', text: '08:00' },
  { key: 9, value: '09:00', text: '09:00' },
  { key: 10, value: '10:00', text: '10:00' },
  { key: 11, value: '11:00', text: '11:00' },
  { key: 12, value: '12:00', text: '12:00' },
  { key: 13, value: '13:00', text: '13:00' },
  { key: 14, value: '14:00', text: '14:00' },
  { key: 15, value: '15:00', text: '15:00' },
  { key: 16, value: '16:00', text: '16:00' },
  { key: 17, value: '17:00', text: '17:00' },
  { key: 18, value: '18:00', text: '18:00' },
  { key: 19, value: '19:00', text: '19:00' },
  { key: 20, value: '20:00', text: '20:00' },
  { key: 21, value: '21:00', text: '21:00' },
  { key: 22, value: '22:00', text: '22:00' },
  { key: 23, value: '23:00', text: '23:00' },
  { key: 24, value: '24:00', text: '24:00' },
];

export const hourArray12hour = [
  { key: 0, value: '00:00', text: '12:00 AM' },
  { key: 1, value: '01:00', text: '01:00 AM' },
  { key: 2, value: '02:00', text: '02:00 AM' },
  { key: 3, value: '03:00', text: '03:00 AM' },
  { key: 4, value: '04:00', text: '04:00 AM' },
  { key: 5, value: '05:00', text: '05:00 AM' },
  { key: 6, value: '06:00', text: '06:00 AM' },
  { key: 7, value: '07:00', text: '07:00 AM' },
  { key: 8, value: '08:00', text: '08:00 AM' },
  { key: 9, value: '09:00', text: '09:00 AM' },
  { key: 10, value: '10:00', text: '10:00 AM' },
  { key: 11, value: '11:00', text: '11:00 AM' },
  { key: 12, value: '12:00', text: '12:00 PM' },
  { key: 13, value: '13:00', text: '01:00 PM' },
  { key: 14, value: '14:00', text: '02:00 PM' },
  { key: 15, value: '15:00', text: '03:00 PM' },
  { key: 16, value: '16:00', text: '04:00 PM' },
  { key: 17, value: '17:00', text: '05:00 PM' },
  { key: 18, value: '18:00', text: '06:00 PM' },
  { key: 19, value: '19:00', text: '07:00 PM' },
  { key: 20, value: '20:00', text: '08:00 PM' },
  { key: 21, value: '21:00', text: '09:00 PM' },
  { key: 22, value: '22:00', text: '10:00 PM' },
  { key: 23, value: '23:00', text: '11:00 PM' },
  { key: 24, value: '24:00', text: '12:00 AM' },
];

export const getTimeStrAsObj = (time: string) => {
  const timeArray = time.split(':');

  return {
    hours: parseInt(timeArray[0], 10),
    minutes: parseInt(timeArray[1], 10),
  }
};

export const getTotalHours = (startTime: string, endTime: string) => {
  if (!startTime || !endTime) return;
  
  const startObj = getTimeStrAsObj(startTime);
  const endObj = getTimeStrAsObj(endTime);

  return endObj.hours - startObj.hours;
};
