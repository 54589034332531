import React from 'react';
import { bool, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';

import { PrimaryButton } from '../../../../components';

import css from './ReviewSubmitButton.module.css';

const ReviewSubmitButton = (props) => {
  const { className, formId, intl, reviewSent, sendReviewInProgress, submitDisabled } = props;
  const reviewSubmitMessage = intl.formatMessage({ id: 'ReviewModal.ReviewSubmitButton.text' });
  const classes = classNames(css.root, className);

  return (
    <PrimaryButton
      className={classes}
      form={formId}
      type="submit"
      inProgress={sendReviewInProgress}
      disabled={submitDisabled}
      ready={reviewSent}
    >
      {reviewSubmitMessage}
    </PrimaryButton>
  )
};

ReviewSubmitButton.defaultProps = {};

ReviewSubmitButton.propTypes = {
  formId: string.isRequired,
  intl: intlShape.isRequired,
  reviewSent: bool.isRequired,
  sendReviewInProgress: bool.isRequired,
  submitDisabled: bool.isRequired,
};

export default ReviewSubmitButton;
