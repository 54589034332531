import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaInstagram.css';

const IconSocialMediaInstagram = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.25 2H5.75C3.67893 2 2 3.67893 2 5.75V13.25C2 15.3211 3.67893 17 5.75 17H13.25C15.3211 17 17 15.3211 17 13.25V5.75C17 3.67893 15.3211 2 13.25 2Z" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.5 9.02736C12.5926 9.65154 12.486 10.289 12.1953 10.8491C11.9047 11.4092 11.4449 11.8634 10.8812 12.1471C10.3176 12.4308 9.67886 12.5296 9.05586 12.4293C8.43287 12.3291 7.85734 12.0349 7.41115 11.5887C6.96496 11.1425 6.67082 10.567 6.57058 9.94402C6.47033 9.32102 6.56907 8.68228 6.85277 8.11864C7.13647 7.555 7.59066 7.09517 8.15076 6.80454C8.71086 6.51392 9.34834 6.4073 9.97252 6.49986C10.6092 6.59427 11.1987 6.89096 11.6538 7.34609C12.1089 7.80122 12.4056 8.39067 12.5 9.02736Z" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.6252 5.37476H13.632" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  );
};

IconSocialMediaInstagram.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaInstagram.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaInstagram;
