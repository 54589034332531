import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { length, map, pathOr } from 'ramda';

import config from '../../config';
import { LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  parse,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { getAverageRating } from '../../util/reviews';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer, NotFoundPage } from '../../containers';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  CriteoProduct,
  EventContactInfo,
} from '../../components';

import AdditionalInfo from './AdditionalInfo/AdditionalInfo';
import SectionTitle from './SectionTitle/SectionTitle';
import SectionImages from './SectionImages/SectionImages';
import SectionHeading from './SectionHeading/SectionHeading';
import SectionDescription from './SectionDescription/SectionDescription';
import SectionFeaturesMaybe from './SectionFeaturesMaybe/SectionFeaturesMaybe';
import SectionReviews from './SectionReviews/SectionReviews';
import SectionHostMaybe from './SectionHostMaybe/SectionHostMaybe';
import SectionRulesMaybe from './SectionRulesMaybe/SectionRulesMaybe';
import SectionActions from './SectionActions/SectionActions';
import SectionPricing from './SectionPricing/SectionPricing';
import SectionExtras from './SectionExtras/SectionExtras';
import SectionSimilarListings from './SectionSimilarListings/SectionSimilarListings';
import css from './ListingPage.css';

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price, true);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      shareModalOpen: false,
    };
  }

  render() {
    const {
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      amenitiesConfig,
      providerPresentConfig,
      securityConfig,
      searchParams,
      similarListings,
      searchInProgress,
      searchListingsError,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const currentListing =
      isPendingApprovalVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      isPendingApprovalVariant &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const {
      maxCapacityToGuest,
      isHostedBySuperHost,
      paidExtrasData,
    } = publicData;
    const extras = pathOr([], ['extras'], publicData);
    const hasFeatures = extras.length > 0;
    const hasExtras = length(paidExtrasData) > 0;

    const topbar = <TopbarContainer currentPage='ListingPage' />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const handleShareClick = e => {
      e.stopPropagation();
      this.setState({
        shareModalOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );
    const averageRating = getAverageRating(reviews);

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <CriteoProduct currentUser={currentUser} listingId={listingId} />
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              <SectionTitle className={css.sectionTitleDesktop} title={title} />
              <SectionActions
                className={css.sectionActions}
                currentUser={currentUser}
                listingId={listingId}
                shareModalOpen={this.state.shareModalOpen}
                onShareModalClose={() => this.setState({ shareModalOpen: false })}
                handleShareClick={handleShareClick}
                rating={averageRating}
                totalReviews={reviews.length}
                isHostedBySuperHost={isHostedBySuperHost}
                onManageDisableScrolling={onManageDisableScrolling}
                intl={intl}
              />
              <SectionImages
                title={title}
                listing={currentListing}
                isOwnListing={isOwnListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingType,
                  tab: listingTab,
                }}
                imageCarouselOpen={this.state.imageCarouselOpen}
                onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                handleViewPhotosClick={handleViewPhotosClick}
                onManageDisableScrolling={onManageDisableScrolling}
              />
              <SectionTitle className={css.sectionTitleMobile} title={title} />
              <div className={css.contentContainer}>
                <div className={css.mainContent}>
                  <SectionHeading
                    className={css.sectionHeading}
                    titleClassName={css.sectionTitle}
                    intl={intl}
                    listing={currentListing}
                    maxCapacity={maxCapacityToGuest}
                    params={params}
                    user={currentAuthor}
                  />
                  {hasFeatures && (
                    <SectionFeaturesMaybe
                      className={css.sectionFeaturesMaybe}
                      titleClassName={css.sectionTitle}
                      intl={intl}
                      options={amenitiesConfig}
                      selectedOptions={extras}
                    />
                  )}
                  <SectionDescription
                    className={css.sectionDescription}
                    titleClassName={css.sectionTitle}
                    description={description}
                    intl={intl}
                    providerPresentConfig={providerPresentConfig}
                    publicData={publicData}
                    securityConfig={securityConfig}
                  />
                  {(searchParams.event || searchParams.pub_policyEvent) && (
                    <EventContactInfo
                      className={css.eventContactInfo}
                      eventType={searchParams.event}
                    />
                  )}
                  <SectionRulesMaybe
                    className={css.sectionRulesMaybe}
                    titleClassName={css.sectionTitle}
                    intl={intl}
                    publicData={publicData}
                  />
                  <SectionPricing
                    className={css.sectionRulesMaybe}
                    titleClassName={css.sectionTitle}
                    intl={intl}
                    listing={currentListing}
                  />
                  {hasExtras && (
                    <SectionExtras
                      className={css.sectionRulesMaybe}
                      titleClassName={css.sectionTitle}
                      intl={intl}
                      paidExtrasData={paidExtrasData}
                    />
                  )}
                </div>
                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  rating={averageRating}
                  searchParams={searchParams}
                />
              </div>
              <div className={css.secondaryContentContainer}>
                <AdditionalInfo
                  className={css.additionalInfo}
                  titleClassName={css.sectionTitle}
                  intl={intl}
                  providerPresentConfig={providerPresentConfig}
                  publicData={publicData}
                  securityConfig={securityConfig}
                />
                <SectionReviews
                  className={css.sectionReviews}
                  titleClassName={css.sectionTitle}
                  fetchReviewsError={fetchReviewsError}
                  intl={intl}
                  rating={averageRating}
                  reviews={reviews}
                />
                <SectionHostMaybe
                  className={css.sectionHost}
                  currentUser={currentUser}
                  intl={intl}
                  listing={currentListing}
                />
                {similarListings.length > 0 ? (
                  <SectionSimilarListings
                    className={css.sectionSimilarListings}
                    titleClassName={css.sectionTitle}
                    intl={intl}
                    listings={similarListings}
                    searchInProgress={searchInProgress}
                    searchListingsError={searchListingsError}
                  />
                ) : null}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  currentUser: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  amenitiesConfig: config.custom.amenities,
  providerPresentConfig: config.custom.providerPresentOptions,
  securityConfig: config.custom.securityOptions,
  filterConfig: config.custom.filters,
  similarListings: [],
  searchListingsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  showListingError: propTypes.error,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  amenitiesConfig: array,
  providerPresentConfig: array,
  securityConfig: array,
  filterConfig: array,
  similarListings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
};

const mapStateToProps = (state, props) => {
  const {
    showListingError,
    reviews,
    fetchReviewsError,
  } = state.listing;
  const { currentUser } = state.user;
  const { searchParams } = state.SearchPage;
  const { similarListingIds, searchInProgress, searchListingsError } = state.SearchPage;

  const similarListingsRefs = map(listingId => ({ id: listingId, type: 'listing' }), similarListingIds);
  const similarListings = getMarketplaceEntities(state, similarListingsRefs);

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    showListingError,
    reviews,
    fetchReviewsError,
    searchParams: searchParams || parse(props.location.search),
    similarListings,
    searchInProgress,
    searchListingsError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
