import React from 'react';
import { injectIntl } from 'react-intl';

import config from '../../config';
import {
  IconArrow,
  ExternalLink,
} from '../../components';
import madameImg from '../../assets/pictures/madame.png';
import childImg from '../../assets/pictures/child2.png';

import css from './LandingPage.css';

const SectionContact = props => {
  const { intl } = props;

  const mailContact = config.custom.mailContact;
  const title = intl.formatMessage({ id: 'LandingPage.contactTitle' });
  const ctaText = intl.formatMessage({ id: 'LandingPage.contactCTA' });

  return (
    <div className={css.sectionContact}>
      <div className={css.contactContent}>
        <span className={css.contactTitle}>{title}</span>
        <ExternalLink className={css.contactCTA} href={`mailto:${mailContact}`} >
          <span className={css.buttonText}>{ctaText}</span>
          <IconArrow className={css.buttonIcon} direction='right' />
        </ExternalLink>
        <img className={css.contactImg} src={madameImg} alt='madame' />
        <img className={css.contactImg2} src={childImg} alt='child' />
      </div>
    </div>
  )
}

export default injectIntl(SectionContact);