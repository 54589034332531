export const BASE_PRICING = 'basePricing';
export const INSTANT_BOOKING = 'instantBooking';
export const OVERTIME_DISCOUNT = 'overtimeDiscount';
export const PAID_EXTRAS = 'paidExtras';
export const WEEKDAY_DISCOUNT = 'weekdayDiscount';

export const applyVariation = (amount, variation) => {
  if (!variation) return amount;

  return Math.round(amount + amount * (variation / 100));
};
