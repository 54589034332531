import React, { useEffect, useRef } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { length } from 'ramda';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { ensureTransaction, ensureUser, ensureListing } from '../../util/data';
import { isRelevantPastTransition } from '../../util/transactions';
import { propTypes } from '../../util/types';
import { InlineTextButton } from '../../components';

import { isMessage, organizedItems } from './ActivityFeed.helpers';
import Message from './components/Message/Message';
import OwnMessage from './components/Message/OwnMessage';
import Transition, { EmptyTransition } from './components/Transition/Transition';
import css from './ActivityFeed.module.css';

const MAX_MOBILE_WIDTH = 1279;

const ActivityFeed = props => {
  const {
    rootClassName,
    className,
    messages,
    transaction,
    currentUser,
    hasOlderMessages,
    onOpenReviewModal,
    onShowOlderMessages,
    fetchMessagesInProgress,
    intl,
    viewport,
  } = props;
  const ActivityFeedRef = useRef();
  const { width } = viewport;
  const hasViewport = width > 0;
  const isMobileDevice = hasViewport && width <= MAX_MOBILE_WIDTH;
  useEffect(() => {
    if (length(messages) <= 0) return;

    ActivityFeedRef.current.scrollIntoView();
  }, [messages]);
  const classes = classNames(rootClassName || css.root, className);
  const currentTransaction = ensureTransaction(transaction);
  const transitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);
  const currentListing = ensureListing(currentTransaction.listing);

  const transitionsAvailable = !!(
    currentUser &&
    currentUser.id &&
    currentCustomer.id &&
    currentProvider.id &&
    currentListing.id
  );

  // combine messages and transaction transitions
  const items = organizedItems(messages, transitions, hasOlderMessages || fetchMessagesInProgress);

  const transitionComponent = transition => {
    if (transitionsAvailable) {
      return (
        <Transition
          transition={transition}
          transaction={transaction}
          currentUser={currentUser}
          intl={intl}
          onOpenReviewModal={onOpenReviewModal}
        />
      );
    } else {
      return <EmptyTransition />;
    }
  };

  const messageComponent = (message, prevMessageHasSameAuthor, nextMessageHasSameAuthor) => {
    const isOwnMessage =
      message.sender &&
      message.sender.id &&
      currentUser &&
      currentUser.id &&
      message.sender.id.uuid === currentUser.id.uuid;
    const messageProps = {
      message,
      intl,
      isMobileDevice,
      nextMessageHasSameAuthor,
      prevMessageHasSameAuthor,
    };

    if (isOwnMessage) {
      return <OwnMessage {...messageProps} />;
    }
    return <Message {...messageProps} />;
  };

  const messageListItem = (message, index, array) => {
    const arrayLength = array.length;
    const hasPrevItem = index > 0;
    const hasNextItem = (arrayLength - 1) > index;
    const prevItem = hasPrevItem && array[index - 1];
    const nextItem = hasNextItem && array[index + 1];
    const prevItemIsMessage = prevItem && isMessage(prevItem);
    const nextItemIsMessage = nextItem && isMessage(nextItem);
    const prevMessageHasSameAuthor = prevItemIsMessage && message.sender.id.uuid === prevItem.sender.id.uuid;
    const nextMessageHasSameAuthor = nextItemIsMessage && message.sender.id.uuid === nextItem.sender.id.uuid;
    const messageItemClasses = classNames(css.messageItem, {
      [css.messageItemSameAuthor]: nextMessageHasSameAuthor,
    });

    return (
      <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={messageItemClasses}>
        {messageComponent(message, prevMessageHasSameAuthor, nextMessageHasSameAuthor)}
      </li>
    );
  };

  const transitionListItem = transition => {
    if (transition && isRelevantPastTransition(transition.transition)) {
      return (
        <li key={transition.transition} className={css.transitionItem}>
          {transitionComponent(transition)}
        </li>
      );
    } else {
      return null;
    }
  };

  return (
    <ul className={classes}>
      {hasOlderMessages ? (
        <li className={css.showOlderWrapper} key="show-older-messages">
          <InlineTextButton className={css.showOlderButton} onClick={onShowOlderMessages}>
            <FormattedMessage id="ActivityFeed.showOlderMessages" />
          </InlineTextButton>
        </li>
      ) : null}
      {items.map((item, index, array) => {
        if (isMessage(item)) {
          return messageListItem(item, index, array);
        } else {
          return transitionListItem(item);
        }
      })}
      <div ref={ActivityFeedRef} />
    </ul>
  );
};

ActivityFeed.defaultProps = {
  rootClassName: null,
  className: null,
};

ActivityFeed.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction,
  messages: arrayOf(propTypes.message),
  hasOlderMessages: bool.isRequired,
  onOpenReviewModal: func.isRequired,
  onShowOlderMessages: func.isRequired,
  fetchMessagesInProgress: bool.isRequired,
  intl: intlShape.isRequired,
};

export default withViewport(injectIntl(ActivityFeed));
