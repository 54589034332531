export const getResponseTimeValueTextId = (responseTime) => {
  switch (true) {
    case responseTime < 3600:
      return 'responseTimeLessThanOneHour'
    case responseTime < 21600:
      return 'responseTimeFewHours'
    case responseTime < 86400:
      return 'responseTimeOneDay'
    case responseTime < 172800:
      return 'responseTimeSomeDays'
    default:
      return 'undefined'
  };
};
