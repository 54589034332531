import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconGroup.module.css';

const IconGroup = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.4583 16.625V15.0417C13.4583 14.2018 13.1247 13.3964 12.5308 12.8025C11.937 12.2086 11.1315 11.875 10.2917 11.875H3.95833C3.11848 11.875 2.31303 12.2086 1.71916 12.8025C1.1253 13.3964 0.791666 14.2018 0.791666 15.0417V16.625" strokeWidth="1.58333" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.125 8.70833C8.8739 8.70833 10.2917 7.29057 10.2917 5.54167C10.2917 3.79276 8.8739 2.375 7.125 2.375C5.3761 2.375 3.95833 3.79276 3.95833 5.54167C3.95833 7.29057 5.3761 8.70833 7.125 8.70833Z" strokeWidth="1.58333" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.2083 16.625V15.0417C18.2078 14.34 17.9743 13.6584 17.5444 13.1039C17.1145 12.5494 16.5127 12.1533 15.8333 11.9779" strokeWidth="1.58333" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.6667 2.47791C13.3478 2.65231 13.9516 3.04846 14.3827 3.6039C14.8139 4.15934 15.0479 4.84248 15.0479 5.54561C15.0479 6.24875 14.8139 6.93188 14.3827 7.48733C13.9516 8.04277 13.3478 8.43892 12.6667 8.61332" strokeWidth="1.58333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

IconGroup.defaultProps = {
  rootClassName: null,
  className: null,
};

IconGroup.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconGroup;