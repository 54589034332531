import React, { Fragment } from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { path } from 'ramda';

import { propTypes } from '../../../../util/types';
import { getBookingDatesFromTx, txIsEnquired } from '../../../../util/transactions';
import { getMarketplaceEntities } from '../../../../ducks/marketplaceData.duck';
import { IconArrowHead, IconClose } from '../../../../components';

import { getTxType } from '../../InboxPage.Helpers';
import DetailsActions from './components/DetailsActions/DetailsActions';
import DetailsBookingDate from './components/DetailsBookingDate/DetailsBookingDate';
import DetailsHeader from './components/DetailsHeader/DetailsHeader';
import DetailsListing from './components/DetailsListing/DetailsListing';
import DetailsPrice from './components/DetailsPrice/DetailsPrice';
import DetailsQuantity from './components/DetailsQuantity/DetailsQuantity';
import DetailsOtherUser from './components/DetailsOtherUser/DetailsOtherUser';
import css from './DetailsSection.module.css';

const DetailsSection = props => {
  const {
    className,
    currentUser,
    isMobile,
    setShowDetails,
    transaction,
    intl,
  } = props;
  const txType = getTxType(transaction, currentUser);
  const isEnquiry = txIsEnquired(transaction);
  const isOrder = txType === 'order';
  const showActionButtons = isOrder || (!isOrder && !isEnquiry);
  const bookingDates = getBookingDatesFromTx(transaction);
  const txQuantity = path(['attributes', 'protectedData', 'quantity'], transaction);
  const heading = intl.formatMessage({ id: 'InboxPage.DetailsSection.heading' });

  return (
    <section className={className}>
      <div className={css.header}>
        {isMobile && (
          <button className={css.backButton} onClick={() => setShowDetails(false)}>
            <IconArrowHead className={css.iconArrow} direction="left" />
          </button>
        )}
        <h2 className={css.heading}>{heading}</h2>
        {!isMobile && (
          <button className={css.backButton} onClick={() => setShowDetails(false)}>
            <IconClose className={css.iconClose} />
          </button>
        )}
      </div>
      <div className={css.content}>
        {transaction && (
          <Fragment>
            <DetailsHeader
              className={css.detailsSection}
              currentUser={currentUser}
              transaction={transaction}
            />
            <DetailsListing
              className={css.detailsSection}
              currentUser={currentUser}
              transaction={transaction}
            />
            {bookingDates && <DetailsBookingDate className={css.detailsSection} transaction={transaction} />}
            {txQuantity && <DetailsQuantity className={css.detailsSection} quantity={txQuantity} />}
            <DetailsPrice
              className={css.detailsSection}
              currentUser={currentUser}
              transaction={transaction}
            />
            <DetailsOtherUser
              className={css.detailsSection}
              currentUser={currentUser}
              transaction={transaction}
            />
          </Fragment>
        )}
      </div>
      {transaction && showActionButtons && (
        <DetailsActions
          className={css.detailsActions}
          isEnquiry={isEnquiry}
          isOrder={isOrder}
          transaction={transaction}
        />
      )}
    </section>
  )
};

DetailsSection.defaultProps = {
  currentUser: null,
  transaction: null,
};

DetailsSection.propTypes = {
  className: string.isRequired,
  currentUser: propTypes.currentUser,
  intl: intlShape.isRequired,
  isMobile: bool.isRequired,
  setShowDetails: func.isRequired,
  transaction: object,
};

const mapStateToProps = state => {
  const { transactionRef } = state.transaction;

  return {
    transaction: getMarketplaceEntities(state, transactionRef ? [transactionRef] : [])[0],
  };
}

export default compose(
  connect(
    mapStateToProps,
    null,
  ), 
  injectIntl,
  )(DetailsSection);
