import React from 'react';
import { string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { DATE_TYPE_DATETIME, propTypes } from '../../../../../../util/types';
import { BookingTimeInfo } from '../../../../../../components';

import css from './DetailsBookingDate.module.css';

const DetailsBookingDate = props => {
  const {
    className,
    transaction,
    intl,
  } = props;
  const title = intl.formatMessage({ id: 'InboxPage.DetailsSection.DetailsBookingDate.title' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <span className={css.title}>{title}</span>
      <BookingTimeInfo bookingClassName={css.text} dateType={DATE_TYPE_DATETIME} tx={transaction} intl={intl} />
    </div>
  )
}

DetailsBookingDate.defaultProps = {
  className: null,
};

DetailsBookingDate.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  transaction: propTypes.transaction.isRequired,
};

export default injectIntl(DetailsBookingDate);
