import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import {
  ExternalLink,
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  NamedLink,
} from '../../components';

import css from './Footer.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink
      key="linkToFacebook"
      href={siteFacebookPage}
      className={css.link}
      title={goToFb}
    >
      <IconSocialMediaFacebook className={css.icon} />
      <FormattedMessage id='Footer.facebook' />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.link}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter className={css.icon} />
      <FormattedMessage id='Footer.twitter' />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.link}
      title={goToInsta}
    >
      <IconSocialMediaInstagram className={css.icon} />
      <FormattedMessage id='Footer.instagram' />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  const socialMediaLinks = renderSocialMediaLinks(intl);
  const brandText = intl.formatMessage({ id: 'Footer.brandText' });
  const newsTitle = intl.formatMessage({ id: 'Footer.news' });
  const helpTile = intl.formatMessage({ id: 'Footer.help' });
  const followUsTitle = intl.formatMessage({ id: 'Footer.followUs' });


  return (
    <div className={classes}>
      <div className={css.content}>
        <section className={css.brandContainer}>
          <Logo format='desktop' className={css.brandLogo} />
          <span className={css.brandText}>{brandText}</span>
        </section>
        <section className={css.linkSection}>
          <section className={css.linksContainer}>
            <span className={css.sectionTitle}>{newsTitle}</span>
            <ExternalLink href="https://blog.swimmy.fr/" className={css.link}>
              <FormattedMessage id="Footer.blog"/>
            </ExternalLink>
            <NamedLink name="PressPage" className={css.link}>
              <FormattedMessage id="Footer.media"/>
            </NamedLink>
            <NamedLink name="WhoWeArePage" className={css.link}>
              <FormattedMessage id="Footer.whoWeAre"/>
            </NamedLink>
          </section>
          <section className={css.linksContainer}>
            <span className={css.sectionTitle}>{helpTile}</span>
            <ExternalLink href={config.custom.helpCenterUrlForCustomers} openInSameWindow className={css.link}>
              <FormattedMessage id="Footer.helpCustomer"/>
            </ExternalLink>
            <ExternalLink href={config.custom.helpCenterUrlForProviders} openInSameWindow className={css.link}>
              <FormattedMessage id="Footer.helpProvider"/>
            </ExternalLink>
            <NamedLink name="SimulatorPage" className={css.link}>
              <FormattedMessage id="Footer.rent"/>
            </NamedLink>
            <NamedLink name="HowItWorksPage" className={css.link}>
              <FormattedMessage id="Footer.howItWorks"/>
            </NamedLink>
          </section>
        </section>
        <section className={css.linkSection}>
          <section className={css.linksContainer}>
            <span className={css.sectionTitle}>{helpTile}</span>
            <ExternalLink href={config.custom.helpCenterUrl} openInSameWindow className={css.link}>
              <FormattedMessage id="Footer.helpdesk"/>
            </ExternalLink>
            <NamedLink name="TermsOfServicePage" className={css.link}>
              <FormattedMessage id="Footer.termsOfUse"/>
            </NamedLink>
            <NamedLink name="PrivacyPolicyPage" className={css.link}>
              <FormattedMessage id="Footer.privacyPolicyPage"/>
            </NamedLink>
            <NamedLink name="LegalNoticePage" className={css.link}>
              <FormattedMessage id="Footer.legalNoticePage"/>
            </NamedLink>
          </section>
          <section className={css.linksContainer}>
            <span className={css.sectionTitle}>{followUsTitle}</span>
            {socialMediaLinks}
          </section>
        </section>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
