import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { includes, path, pathOr } from 'ramda';
import { string, func, shape, object } from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';

import { propTypes } from '../../util/types';
import { ensureListing } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { DAYS_CONSIDERED_NEW_LISTING, getDaysSinceCreation } from '../../util/listingHelpers';
import defaultListingImg from '../../assets/default-img-listing/default-img-listing.jpg';
import defaultListingImgCrop from '../../assets/default-img-listing/landscape-crop.webp';
import defaultListingImgCrop2 from '../../assets/default-img-listing/landscape-cropx2.webp';
import {
  FavoritesButton,
  InstantBookingBadge,
  NamedLink,
  ResponsiveImage,
  SuperhostBadge,
} from '../../components';

import GeolocationInfo from './GeolocationInfo/GeolocationInfo';
import ListingPrice from './ListingPrice/ListingPrice';
import ReviewsInfo from './ReviewsInfo/ReviewsInfo';
import css from './ListingCard.css';

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    searchParams,
    setActiveListing,
    to,
    myFavorites,
  } = props;
  const { bookingDate } = searchParams;
  const currentListing = ensureListing(listing);
  const listingUuid = currentListing.id.uuid;
  const listingTitle = pathOr('', ['attributes', 'title'], currentListing);
  const slug = createSlug(listingTitle);
  const createdAt = path(['attributes', 'createdAt'], currentListing);
  const isFavorites = includes(listingUuid, myFavorites);
  const isHostedBySuperHost = path(['attributes', 'publicData', 'isHostedBySuperHost'], listing);
  const instantBookingFrom = path(['attributes', 'publicData', 'instantBookingFrom'], currentListing);
  const firstImage = path(['images', [0]], currentListing);
  const reviewsInfo = pathOr({}, ['attributes', 'publicData', 'reviewsInfo'], currentListing);
  const isNewListing = getDaysSinceCreation(createdAt) <= DAYS_CONSIDERED_NEW_LISTING;
  const showReviewsInfo = isNewListing || reviewsInfo.totalReviews >= 1;
  const defaultListingImgVariants = `${defaultListingImgCrop} 400w, ${defaultListingImgCrop2} 800w`;
  const classes = classNames(rootClassName || css.root, className);
  const favoritesButtonClasses = classNames(css.favoritesButton, isFavorites ? css.removeFavoritesButton : css.addFavoritesButton);

  return (
    <div className={classes}>
      <NamedLink to={to} name="ListingPage" params={{ id: listingUuid, slug }}>
        <div
          className={css.threeToTwoWrapper}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          <div className={css.aspectWrapper}>
            {!firstImage ? (
              <img
                src={defaultListingImg}
                alt={listingTitle}
                className={css.rootForImage}
                srcSet={defaultListingImgVariants}
              />
            ) : (
              <ResponsiveImage
                rootClassName={css.rootForImage}
                alt={listingTitle}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
                sizes={renderSizes}
              />
            )}
          </div>
        </div>
      </NamedLink>
      <NamedLink
        className={css.link}
        to={to}
        name="ListingPage"
        params={{ id: listingUuid, slug }}
      >
        <div className={css.info}>
          <div className={css.titleAndReviewWrapper}>
            <span className={css.title}>{listingTitle}</span>
            {showReviewsInfo && (
              <ReviewsInfo
                className={css.reviewsInfo}
                intl={intl}
                isNewListing={isNewListing}
                reviewsInfo={reviewsInfo}
              />
            )}
          </div>
          <GeolocationInfo
            className={css.geolocationInfo}
            intl={intl}
            listing={currentListing}
            searchParams={searchParams}
          />
          {bookingDate && <ListingPrice className={css.listingPrice} intl={intl} listing={listing} searchParams={searchParams} />}
        </div>
        <FavoritesButton className={favoritesButtonClasses} listingId={listingUuid} />
        <div className={css.badgeContainer}>
          {instantBookingFrom && <InstantBookingBadge className={css.instantBookingBadge} textOnly />}
          {isHostedBySuperHost && <SuperhostBadge className={css.superhostBadge}/>}
        </div>
      </NamedLink>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  search: null,
  searchParams: {},
  to: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  search: object,
  searchParams: object,
  to: shape({ search: string }),

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const myFavorites = pathOr([], ['attributes', 'profile', 'protectedData', 'myFavorites'], currentUser);

  return {
    myFavorites,
    isAuthenticated,
  };
};

const ListingCard = compose(
  connect(
    mapStateToProps,
    null
  ),
  injectIntl
)(ListingCardComponent);

export default ListingCard;
