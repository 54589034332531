import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckbox.css';
import { Emoji, InformationTooltip } from '../../components';

const FieldCheckboxProductComponent = props => {
  const { rootClassName, textClassName, className, price, id, informationTooltipContent, symbol, label, ...rest } = props;

  const classes = classNames(rootClassName || css.root, className);
  const classesText = textClassName || css.text;
  const checkboxProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'checkbox',
    ...rest,
  };

  return (
    <span className={classes}>
      <Field {...checkboxProps} />
      <label htmlFor={id} className={css.label}>
        <div className={css.product}>
          <span className={css.checkboxWrapper}>
            <Emoji className={css.emoji} symbol={symbol} />
          </span>
          <div className={css.productTexts}>
            <span className={classesText}>
              {label}
            </span>
            <span className={css.price}>{price}</span>
          </div>
          <InformationTooltip contentText={informationTooltipContent}/>
        </div>
      </label>
    </span>
  );
};

FieldCheckboxProductComponent.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  label: null,
};

FieldCheckboxProductComponent.propTypes = {
  className: string,
  rootClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,
  price: string,
  informationTooltipContent: string,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldCheckboxProductComponent;
