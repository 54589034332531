import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import { Form, FieldBoolean, FieldTextInput, PrimaryButton } from '../../components';

import css from './EditListingPoliciesForm.module.css';

const EditListingPoliciesFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        intl,
        invalid,
      } = fieldRenderProps;
      const requiredMessage = intl.formatMessage({ id: 'Marketplace.requiredMessage' });
      const rulesLabel = intl.formatMessage({ id: 'EditListingPoliciesForm.rulesLabel' });
      const rulesPlaceholder = intl.formatMessage({ id: 'EditListingPoliciesForm.rulesPlaceholder' });
      const suitableForChildrenLabel = intl.formatMessage({ id: 'EditListingPoliciesForm.suitableForChildrenLabel' });
      const suitableForChildrenInformation = intl.formatMessage({ id: 'EditListingPoliciesForm.suitableForChildrenInformation' });
      const eventLabel = intl.formatMessage({ id: 'EditListingPoliciesForm.eventLabel'});
      const eventInformation =  intl.formatMessage({ id: 'EditListingPoliciesForm.eventInformation' })
      const alcoholLabel = intl.formatMessage({ id: 'EditListingPoliciesForm.alcoholLabel'});
      const smokeLabel = intl.formatMessage({ id: 'EditListingPoliciesForm.smokeLabel'});
      const petsLabel = intl.formatMessage({ id: 'EditListingPoliciesForm.petLabel'});
      const musicLabel = intl.formatMessage({ id: 'EditListingPoliciesForm.musicLabel'});
      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="Marketplace.showListingFailed" />
        </p>
      ) : null;
      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <FieldBoolean
            className={css.policiesItem}
            id="suitableForChildren"
            name="suitableForChildren"
            label={suitableForChildrenLabel}
            informationTooltipContent={suitableForChildrenInformation}
            validate={required(requiredMessage)}
            variant="button"
          />
          <FieldBoolean
            className={css.policiesItem}
            id="policyEvent"
            name="policyEvent"
            label={eventLabel}
            informationTooltipContent={eventInformation}
            variant="button"
          />
          <FieldBoolean
            className={css.policiesItem}
            id="policyAlcohol"
            name="policyAlcohol"
            label={alcoholLabel}
            variant="button"
          />
          <FieldBoolean
            className={css.policiesItem}
            id="policySmoking"
            name="policySmoking"
            label={smokeLabel}
            variant="button"
          />
          <FieldBoolean
            className={css.policiesItem}
            id="policyPets"
            name="policyPets"
            label={petsLabel}
            variant="button"
          />
          <FieldBoolean
            className={css.policiesItem}
            id="policyMusic"
            name="policyMusic"
            label={musicLabel}
            variant="button"
          />
          <FieldTextInput
            id="rules"
            name="rules"
            className={css.policy}
            type="textarea"
            label={rulesLabel}
            placeholder={rulesPlaceholder}
          />
          <div className={css.buttonContainer}>
            <PrimaryButton
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default injectIntl(EditListingPoliciesFormComponent);
