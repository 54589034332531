import React, { Fragment, useEffect, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { map, pathOr } from 'ramda';

import { IconAdd, IconArrowNavigation } from '../../Icons';
import {
  ModalSwimmy,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from '../../ModalSwimmy';
import {
  Divider,
  PrimaryButton,
} from '../../../components';

import HeaderDesktop from '../HeaderDesktop/HeaderDesktop';
import ExtraItem from './ExtraItem/ExtraItem';
import EditExtraModal from './EditExtraModal/EditExtraModal';
import css from './PaidExtrasView.module.css';

const PaidExtrasView = (props) => {
  const {
    className,
    intl,
    isMobile,
    isOpen,
    listing,
    onCloseModal,
    onManageDisableScrolling,
    onSubmit,
    updated,
    updateInProgress,
  } = props;
  const [editExtraIsOpen, setEditExtraIsOpen] = useState(false);
  const [currentExtra, setCurrentExtra] = useState();
  const [editingExtra, setEditingExtra] = useState(false);
  useEffect(() => {
    if (editExtraIsOpen) return;

    setCurrentExtra(null);
    setEditingExtra(false);
  }, [editExtraIsOpen]);
  const handleEditExtra = () => setEditExtraIsOpen(prev => !prev);
  const handleClickExtra = (extraData) => {
    setEditExtraIsOpen(true);
    setCurrentExtra(extraData);
    setEditingExtra(true);
  };
  const listingPublicData = pathOr({}, ['attributes', 'publicData'], listing);
  const { paidExtras, paidExtrasData } = listingPublicData;
  const hasPaidExtras = paidExtras && paidExtras.length > 0;
  const headingText = intl.formatMessage({ id: 'EditListingPricingPanel.PaidExtrasView.title' });
  const noExtraText = intl.formatMessage({ id: 'EditListingPricingPanel.PaidExtrasView.noExtra' });
  const addExtraText = intl.formatMessage({ id: 'EditListingPricingPanel.PaidExtrasView.addExtra' });
  const classes = classNames(css.root, className);
  const addExtraButton = (className) => (
    <PrimaryButton className={className} onClick={handleEditExtra}>
      <IconAdd className={css.iconAdd} />
      <span>{addExtraText}</span>
    </PrimaryButton>
  )
  const content = (
    <Fragment>
      {hasPaidExtras ? (
        <div className={css.itemList}>
          {map((item) => (
            <Fragment key={item.id}>
              <ExtraItem
                className={css.extraItem}
                extraData={item}
                id={item.id}
                intl={intl}
                label={item.label}
                onClick={() => handleClickExtra(item)}
                withEdit
              />
              <Divider />
            </Fragment>
          ), paidExtrasData)}
        </div>
      ) : (
        <Fragment>
          <span className={css.noExtra}>{noExtraText}</span>
          <div className={css.addExtraContainer}>
            {addExtraButton(css.addExtraButtonContent)}
          </div>
        </Fragment>
      )}
      <div className={css.footer}>
        {addExtraButton(css.addExtraButtonFooter)}
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      {isMobile ? (
        <ModalSwimmy
          id="paidExtras"
          animationType="slideRight"
          isOpen={isOpen}
          onClose={onCloseModal}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <ModalOverlay />
          <ModalContent
            className={css.modalContent} 
            animationType="slideRight"
            show={isOpen}
          >
            <ModalHeader className={css.modalHeader}>
              <button className={css.backButton} onClick={onCloseModal}>
                <IconArrowNavigation direction="left" />
              </button>
              <span className={css.heading}>{headingText}</span>
              <div className={css.spacer} />
            </ModalHeader>
            <ModalBody className={css.modalBody}>
              {content}
            </ModalBody>
          </ModalContent>
        </ModalSwimmy>
      ) : isOpen ? (
        <Fragment>
          <HeaderDesktop className={css.headerDesktop} heading={headingText} intl={intl} />
          <div className={css.contentDesktop}>
            {content}
          </div>
        </Fragment>
      ) : null}
      <EditExtraModal
        currentExtra={currentExtra}
        intl={intl}
        isEdit={editingExtra}
        isMobile={isMobile}
        isOpen={editExtraIsOpen}
        listingPublicData={listingPublicData}
        onCloseModal={handleEditExtra}
        onManageDisableScrolling={onManageDisableScrolling}
        onSubmit={onSubmit}
        setCurrentExtra={setCurrentExtra}
        updated={updated}
        updateInProgress={updateInProgress}
      />
    </Fragment>
  );
}

PaidExtrasView.defaultProps = {
  className: null,
  listing: null,
};

PaidExtrasView.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  isMobile: bool.isRequired,
  isOpen: bool.isRequired,
  listing: object,
  onCloseModal: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSubmit: func.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
};

export default PaidExtrasView;