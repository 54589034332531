/**
 * Modal creates popup which on mobile layout fills the entire visible page.
 *
 * Example:
 * <Parent>
 *   <Modal id="UniqueIdForThisModal" isOpen={this.state.modalIsOpen} onClose={handleClose}>
 *     <FormX />
 *   </Modal>
 * </Parent>
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';

import { Button, IconClose, Logo, NamedLink } from '../../components';
import css from './Modal.css';

const KEY_CODE_ESCAPE = 27;

export class ModalComponent extends Component {
  componentDidMount() {
    const { id, isOpen, onManageDisableScrolling } = this.props;
    onManageDisableScrolling(id, isOpen);
    document.body.addEventListener('keyup', this.handleBodyKeyUp);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillReceiveProps(nextProps) {
    const { id, isOpen, onManageDisableScrolling } = this.props;
    if (nextProps.isOpen !== isOpen) {
      onManageDisableScrolling(id, nextProps.isOpen);
    }
  }

  componentWillUnmount() {
    const { id, onManageDisableScrolling } = this.props;
    document.body.removeEventListener('keyup', this.handleBodyKeyUp);
    onManageDisableScrolling(id, false);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleBodyKeyUp = (event) => {
    const { isOpen } = this.props;
    const canCloseByEscape = event.keyCode === KEY_CODE_ESCAPE && isOpen;
    if (!canCloseByEscape) return null;

    this.handleClose(event);
  }

  handleClickOutside = (event) => {
    const { isOpen } = this.props;
    const canCloseByClickOutside = isOpen && this.modalContainer && !this.modalContainer.contains(event.target);
    if (!canCloseByClickOutside) return null;

    this.handleClose(event);
  }

  handleClose = (event) => {
    const { id, onClose, onManageDisableScrolling } = this.props;
    onManageDisableScrolling(id, false);
    onClose(event);
  }

  render() {
    const {
      children,
      className,
      scrollLayerClassName,
      closeButtonMessage,
      containerClassName,
      contentClassName,
      closeButtonClassName,
      lightCloseButton,
      intl,
      isClosedClassName,
      isOpen,
      isMenu,
    } = this.props;

    const closeModalMessage = intl.formatMessage({ id: 'Modal.closeModal' });
    const closeButtonClasses = classNames(css.close, {
      [css.closeLight]: lightCloseButton,
      [closeButtonClassName] : closeButtonClassName,
    });
    const header = isOpen ? (
      <div className={css.header}>
        <Button
          onClick={this.handleClose}
          rootClassName={closeButtonClasses}
          title={closeModalMessage}
        >
          <span className={css.closeText}>
            {closeButtonMessage || <FormattedMessage id="Modal.close" />}
          </span>
          {!isMenu ? (<IconClose rootClassName={css.closeIcon} />) : null}
        </Button>
        {isMenu ? (
          <div>
            <NamedLink
              className={css.home}
              name="LandingPage"
              title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
            >
              <Logo format="desktop" className={css.logo} />
            </NamedLink>
          </div>
        ) : null}
      </div>
      
    ) : null;

    // Modal uses given styles to wrap child components.
    // If props doesn't contain isClosedClassName, styles default to css.isClosed
    // This makes it possible to create ModalInMobile on top of Modal where style modes are:
    // visible, hidden, or none (ModalInMobile's children are always visible on desktop layout.)
    const modalClass = isOpen ? css.isOpen : isClosedClassName;
    const classes = classNames(modalClass, className);
    const scrollLayerClasses = scrollLayerClassName || css.scrollLayer;
    const containerClasses = containerClassName || css.container;
    return (
      <div className={classes}>
        <div className={scrollLayerClasses}>
          <div ref={modalContainer => this.modalContainer = modalContainer} className={containerClasses}>
            {header}
            <div className={classNames(contentClassName || css.content)}>{children}</div>
          </div>
        </div>
      </div>
    );
  }
}

ModalComponent.defaultProps = {
  children: null,
  className: null,
  scrollLayerClassName: null,
  closeButtonMessage: null,
  containerClassName: null,
  contentClassName: null,
  lightCloseButton: false,
  isClosedClassName: css.isClosed,
  isOpen: false,
  onClose: null,
};

const { bool, func, node, string } = PropTypes;

ModalComponent.propTypes = {
  children: node,
  className: string,
  scrollLayerClassName: string,
  closeButtonMessage: node,
  containerClassName: string,
  contentClassName: string,
  lightCloseButton: bool,
  id: string.isRequired,
  intl: intlShape.isRequired,
  isClosedClassName: string,
  isOpen: bool,
  onClose: func.isRequired,

  // eslint-disable-next-line react/no-unused-prop-types
  onManageDisableScrolling: func.isRequired,
};

export default injectIntl(ModalComponent);
