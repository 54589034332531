import React, { useEffect, useState } from 'react';
import { bool, func, node, number, object, shape, string } from 'prop-types';
import moment from 'moment';
import { 
  DayPickerSingleDateController,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from 'react-dates';

import config from '../../../../../../config';
import { withViewport } from '../../../../../../util/contextHelpers';

import css from './DatePicker.module.css';

const VERTICAL_ORIENTATION = 'vertical';

const propTypes = {
  // example props for the demo
  autoFocus: bool,
  initialDate: object,

  keepOpenOnDateSelect: bool,
  isOutsideRange: func,
  isDayBlocked: func,
  isDayHighlighted: func,

  // DayPicker props
  enableOutsideDays: bool,
  numberOfMonths: number,
  withPortal: bool,
  initialVisibleMonth: func,
  renderCalendarInfo: func,

  navPrev: node,
  navNext: node,
  renderNavPrevButton: func,
  renderNavNextButton: func,

  onPrevMonthClick: func,
  onNextMonthClick: func,
  onOutsideClick: func,
  renderCalendarDay: func,
  renderDayContents: func,

  // i18n
  monthFormat: string,

  isRTL: bool,

  input: object.isRequired,
  erase: number.isRequired,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  
};

const defaultProps = {
  // example props for the demo
  autoFocus: false,
  initialDate: null,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  isDayBlocked: () => false,
  isOutsideRange: day => {
    const endOfRange = config.dayCountAvailableForBooking - 1;
    return (
      !isInclusivelyAfterDay(day, moment()) ||
      !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
    );
  },
  isDayHighlighted: () => false,
  enableOutsideDays: false,

  // calendar presentation and interaction related props
  orientation: VERTICAL_ORIENTATION,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 4,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,
  noBorder: true,
  hideKeyboardShortcutsPanel: true,

  // navigation related props
  navPrev: null,
  navNext: null,
  renderNavPrevButton: null,
  renderNavNextButton: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  transitionDuration: 0,

  // internationalization
  monthFormat: 'MMMM YYYY',
};

const DatePicker = (props) => {
  const {
    autoFocus,
    erase,
    initialDate,
    input,
    viewport,
    ...rest
  } = props;
  const [focused, setFocused] = useState(true);
  const [date, setDate] = useState(initialDate);
  const onDateChange = (date) => {
    setDate(date);
    input.onChange(date);
  };
  useEffect(() => {
    if (erase === 0) return;

    onDateChange(undefined);
  }, [erase]);
  const onFocusChange = () => setFocused(true);
  const width = viewport && viewport.width;
  const daySize = width ? Math.floor((width - 58) / 7) : 40;

  return (
    <div className={css.root}>
      <DayPickerSingleDateController
        {...rest}
        onDateChange={onDateChange}
        onFocusChange={onFocusChange}
        focused={focused}
        date={date}
        navPrev={<div />}
        navNext={<div />}
        daySize={daySize}
      />
    </div>
  );
}

DatePicker.propTypes = propTypes;
DatePicker.defaultProps = defaultProps;

export default withViewport(DatePicker);