import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { map } from 'ramda';

import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  closeListing,
  discardDraft,
  openListing,
  getOwnListingsById,
} from '../../ducks/ownListings.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import ManageListingCard from './ManageListingCard/ManageListingCard';
import css from './ManageListingsPage.module.css';

const ManageListingsPage = (props) => {
  const {
    closingListing,
    closingListingError,
    discardingDraft,
    discardingDraftError,
    listings,
    onCloseListing,
    onDiscardDraft,
    onOpenListing,
    openingListing,
    openingListingError,
    pagination,
    queryInProgress,
    queryListingsError,
    scrollingDisabled,
    intl,
  } = props;

  const hasPaginationInfo = !!pagination && pagination.totalItems != null;
  const listingsAreLoaded = !queryInProgress && hasPaginationInfo;
  const loadingText = intl.formatMessage({ id: 'ManageListingsPage.loadingOwnListings' });
  const queryErrorText = intl.formatMessage({ id: 'ManageListingsPage.queryError' });

  const closingErrorListingId = !!closingListingError && closingListingError.listingId;
  const openingErrorListingId = !!openingListingError && openingListingError.listingId;
  const discardingErrorListingId = !!discardingDraftError && discardingDraftError.listingId;

  const title = intl.formatMessage({ id: 'ManageListingsPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ManageListingsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.listingPanel}>
            {queryInProgress && <span>{loadingText}</span>}
            {queryListingsError && <span className={css.error}>{queryErrorText}</span>}
            {listingsAreLoaded && pagination.totalItems > 0 && (
              <span className={css.title}>
                <FormattedMessage
                  id="ManageListingsPage.youHaveListings"
                  values={{ count: pagination.totalItems }}
                />
              </span>
            )}
            <div className={css.listingCards}>
              {map(listing => (
                <ManageListingCard
                  className={css.listingCard}
                  key={listing.id.uuid}
                  actionsInProgressListingId={openingListing || closingListing || discardingDraft}
                  hasClosingError={closingErrorListingId.uuid === listing.id.uuid}
                  hasDiscardingError={discardingErrorListingId.uuid === listing.id.uuid}
                  hasOpeningError={openingErrorListingId.uuid === listing.id.uuid}
                  listing={listing}
                  onCloseListing={onCloseListing}
                  onDiscardDraft={onDiscardDraft}
                  onOpenListing={onOpenListing}
                />
              ), listings)}
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
}

ManageListingsPage.defaultProps = {
  listings: [],
  pagination: null,
  queryListingsError: null,
  closingListing: null,
  closingListingError: null,
  openingListing: null,
  openingListingError: null,
};

ManageListingsPage.propTypes = {
  closingListing: shape({ uuid: string.isRequired }),
  closingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  discardingDraft: shape({ uuid: string.isRequired }),
  discardingDraftError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  listings: arrayOf(propTypes.ownListing),
  onCloseListing: func.isRequired,
  onDiscardDraft: func.isRequired,
  onOpenListing: func.isRequired,
  openingListing: shape({ uuid: string.isRequired }),
  openingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  pagination: propTypes.pagination,
  queryInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    discardingDraft,
    discardingDraftError,
  } = state.ownListings;
  const listings = getOwnListingsById(state, currentPageResultIds);

  return {
    currentPageResultIds,
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    scrollingDisabled: isScrollingDisabled(state),
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    discardingDraft,
    discardingDraftError,
  };
};

const mapDispatchToProps = dispatch => ({
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onDiscardDraft: listingId => dispatch(discardDraft(listingId)),
  onOpenListing: listingId => dispatch(openListing(listingId)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl,
)(ManageListingsPage);
