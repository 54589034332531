import React, { Fragment, useEffect } from 'react';
import { func, number, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';

import { withViewport } from '../../../../util/contextHelpers';
import { DATE, START_TIME } from '../../../../util/search';
import { FieldResetButton } from '../../../../components';

import DatePicker from './components/DatePicker/DatePicker';
import css from './DateInput.module.css';

const DateInput = (props) => {
  const {
    activeField,
    className,
    finalFormApi,
    formValues,
    intl,
    onClick,
    viewport,
  } = props;
  const { bookingDate } = formValues;
  const isActive = activeField === DATE;
  useEffect(() => {
    if (!bookingDate || !activeField) return;
    finalFormApi.blur(DATE);
    finalFormApi.focus(START_TIME);
  }, [bookingDate]);
  const initialDate = bookingDate && moment(bookingDate);
  const numberOfMonths = viewport.width < 800 ? 1 : 2;
  const labelText = intl.formatMessage({ id: 'SearchDesktop.DateInput.label' });
  const placeholderText = intl.formatMessage({ id: 'SearchDesktop.DateInput.placeholder' });
  const classes = classNames(css.root, className);
  const valueClasses = classNames(css.value, {
    [css.placeholder]: !bookingDate,
    [css.active]: isActive,
  });
  const datePickerClasses = isActive ? css.datePicker : css.datePickerHidden;
  
  return (
    <Fragment>
      <div className={classes}>
        <button className={css.button} type="button" onClick={onClick}>
          <label className={css.label}>
            {labelText}
          </label>
          <span className={valueClasses}>{bookingDate ? moment(bookingDate).format('ddd D MMM') : placeholderText}</span>
        </button>
        {bookingDate && (
          <FieldResetButton
            className={css.fieldResetButton}
            fieldName={DATE}
            finalFormApi={finalFormApi}
          />
        )}
      </div>
      <Field
        name={DATE}
        render={({ input }) => {
          return (
            <DatePicker
              className={datePickerClasses}
              initialDate={initialDate}
              input={input}
              numberOfMonths={numberOfMonths}
            />
          )
        }}
      />
    </Fragment>
  )
};

DateInput.defaultProps = {
  className: null,
  activeField: undefined,
};

DateInput.propTypes = {
  className: string,
  onClick: func.isRequired,
  activeField: string,
  finalFormApi: object.isRequired,
  formValues: object.isRequired,
  intl: intlShape.isRequired,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default withViewport(injectIntl(DateInput));
