import omit from 'lodash/omit';
import { path, pathOr } from 'ramda';

import { moment } from '../../util/dates';
import { parse, stringify } from '../../util/urlHelpers';

export const MODAL_BREAKPOINT = 1023;

export const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

export const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

export const getInitialValuesFromTransaction = (transaction) => {
  const startDate = path(['attributes', 'protectedData', 'date'], transaction);
  const endDate = path(['attributes', 'protectedData', 'endDate'], transaction);
  const startTimeMaybe = startDate ? { startTime: moment(startDate).format('HH:mm') } : {};
  const endTimeMaybe = endDate ? { endTime: moment(endDate).format('HH:mm') } : {};

  return {
    ...startTimeMaybe,
    ...endTimeMaybe,
    bookingDate: pathOr(undefined, ['attributes', 'protectedData', 'date'], transaction),
    adults: pathOr(undefined, ['attributes', 'protectedData', 'quantity', 'adults'], transaction) || 1,
    children: pathOr(undefined, ['attributes', 'protectedData', 'quantity','children'], transaction),
    infants: pathOr(undefined, ['attributes', 'protectedData', 'quantity','infants'], transaction),
  }
};
