import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconProfile.module.css';

const IconProfile = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 512.034 512.034" >
      <g>
        <g>
          <g>
            <path d="M481.532,83.149c-1.205-4.55-5.867-7.264-10.419-6.067c-145.604,38.281-203.938-57.455-211.32-70.963
              c-0.227-0.726-0.546-1.419-0.947-2.065c-1.695-2.37-4.429-3.776-7.343-3.776s-5.648,1.406-7.343,3.776
              c-0.484,0.792-0.838,1.656-1.05,2.56c-8.422,15.121-67.063,108.373-211.063,70.502c-4.552-1.197-9.214,1.517-10.419,6.067
              c-0.785,2.97-75.093,298.394,226.21,427.93c1.215,0.603,2.552,0.918,3.908,0.922c1.662-0.001,3.287-0.488,4.676-1.399
              C556.446,380.826,482.317,86.118,481.532,83.149z M251.584,494.08C-8.606,380.339,26.193,143.633,36.237,95.71
              c128.922,30.157,194.278-41.054,215.347-70.963c21.06,29.867,86.383,101.043,215.245,70.972
              C476.07,143.01,508.437,381.713,251.584,494.08z"/>
            <path d="M451.042,118.963c-1.819-1.41-4.13-2.025-6.409-1.707c-12.628,1.858-25.372,2.808-38.135,2.842
              c-4.713,0-8.533,3.82-8.533,8.533s3.82,8.533,8.533,8.533c10.631-0.019,21.253-0.646,31.812-1.877
              c6.938,73.455-1.877,233.148-186.735,321.246c-17.537-8.295-34.469-17.813-50.671-28.484c-2.539-1.793-5.852-2.064-8.648-0.707
              c-2.796,1.357-4.634,4.127-4.796,7.231c-0.162,3.104,1.376,6.051,4.015,7.692c18.025,11.874,36.914,22.383,56.508,31.437
              c2.283,1.063,4.919,1.063,7.202,0c206.123-96.017,207.932-276.07,199.151-349.013
              C454.051,122.416,452.864,120.352,451.042,118.963z"/>
            <path d="M70.601,237.798c-6.749-33.691-8.603-68.18-5.504-102.4c10.481,1.215,21.022,1.834,31.573,1.852
              c57.545,1.164,113.222-20.445,154.914-60.126c23.48,22.578,51.848,39.442,82.901,49.28c2.905,0.927,6.084,0.233,8.34-1.82
              s3.244-5.153,2.594-8.132c-0.65-2.98-2.84-5.386-5.746-6.313c-31.135-9.882-59.299-27.405-81.92-50.97
              c-3.417-3.319-8.854-3.319-12.271,0c-38.97,40.114-92.874,62.203-148.787,60.971c-12.7-0.034-25.382-0.975-37.948-2.816
              c-2.286-0.356-4.617,0.263-6.426,1.707c-1.826,1.408-3.005,3.495-3.268,5.786c-4.451,38.826-2.81,78.109,4.864,116.429
              c10.839,55.538,36.899,106.971,75.273,148.557c3.205,3.459,8.607,3.666,12.066,0.461c3.459-3.205,3.666-8.607,0.461-12.066
              C105.455,338.895,80.832,290.286,70.601,237.798z"/>
            <path d="M72.546,426.667H55.479V409.6c0-4.713-3.82-8.533-8.533-8.533s-8.533,3.82-8.533,8.533v17.067H21.346
              c-4.713,0-8.533,3.82-8.533,8.533s3.821,8.533,8.533,8.533h17.067V460.8c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533
              v-17.067h17.067c4.713,0,8.533-3.82,8.533-8.533S77.259,426.667,72.546,426.667z"/>
            <path d="M490.688,469.333h-17.067v-17.067c0-4.713-3.82-8.533-8.533-8.533s-8.533,3.821-8.533,8.533v17.067h-17.067
              c-4.713,0-8.533,3.82-8.533,8.533s3.82,8.533,8.533,8.533h17.067v17.067c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533
              V486.4h17.067c4.713,0,8.533-3.82,8.533-8.533S495.401,469.333,490.688,469.333z"/>
            <path d="M422.421,34.133h8.533v8.533c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533v-8.533h8.533
              c4.713,0,8.533-3.821,8.533-8.533s-3.82-8.533-8.533-8.533h-8.533V8.533c0-4.713-3.821-8.533-8.533-8.533
              s-8.533,3.82-8.533,8.533v8.533h-8.533c-4.713,0-8.533,3.82-8.533,8.533S417.709,34.133,422.421,34.133z"/>
            <path d="M357.176,336.939c1.502-2.707,1.42-6.015-0.213-8.644c-4.102-6.474-9.838-11.752-16.631-15.3l-38.229-19.84
              c-2.832-1.475-4.618-4.393-4.642-7.586v-13.713c5.413-9.441,9.548-19.559,12.297-30.089c3.081-3.709,5.466-7.944,7.04-12.501
              c0.337-0.933,0.51-1.918,0.512-2.91v-17.203c-0.002-1.739-0.535-3.436-1.527-4.864l-5.12-7.356v-22.374
              c0.79-10.937-2.732-21.75-9.813-30.123c-9.506-10.854-24.661-16.384-45.107-16.435c-20.446-0.051-35.499,5.572-44.979,16.418
              c-7.07,8.387-10.577,19.208-9.771,30.148v22.366l-5.12,7.356c-0.993,1.428-1.526,3.125-1.527,4.864v17.203
              c0.001,1.109,0.218,2.208,0.64,3.234c1.748,4.461,4.241,8.594,7.373,12.22c2.671,10.447,6.648,20.516,11.836,29.969v13.79
              c-0.369,3.091-2.071,5.866-4.659,7.595L171.2,313.045c-6.744,3.549-12.438,8.808-16.512,15.249
              c-1.641,2.639-1.717,5.961-0.2,8.673s4.389,4.384,7.496,4.366h187.733C352.817,341.332,355.672,339.649,357.176,336.939z
              M186.731,324.267l30.72-15.974c8.213-4.69,13.435-13.275,13.824-22.724v-16.094c-0.005-1.588-0.448-3.144-1.28-4.497
              c-5.311-9.267-9.232-19.264-11.639-29.67c-0.404-2.121-1.675-3.977-3.507-5.12c-1.395-1.709-2.544-3.605-3.413-5.632v-12.74
              l5.12-7.356c0.988-1.426,1.518-3.12,1.519-4.855v-25.455c0-0.374-0.025-0.747-0.077-1.118
              c-0.441-6.304,1.578-12.534,5.632-17.382c6.101-6.98,16.922-10.539,32.128-10.581c11.7-1.187,23.359,2.607,32.119,10.453
              c4.161,4.851,6.248,11.143,5.811,17.519c-0.051,0.368-0.077,0.738-0.077,1.109v25.455c0.001,1.735,0.531,3.429,1.519,4.855
              l5.12,7.356v12.928c-0.738,1.852-1.661,3.625-2.756,5.291c-1.874,1.199-3.206,3.082-3.712,5.248
              c-2.457,10.406-6.504,20.37-11.998,29.542c-0.898,1.385-1.378,3-1.382,4.651v16.094c-0.018,9.581,5.33,18.366,13.85,22.75
              l30.771,15.949H186.731z"/>
            <path d="M204.651,358.4c-4.713,0-8.533,3.82-8.533,8.533s3.82,8.533,8.533,8.533h102.4c4.713,0,8.533-3.82,8.533-8.533
              s-3.82-8.533-8.533-8.533H204.651z"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconProfile.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconProfile.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconProfile;