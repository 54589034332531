import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconStar.module.css';

const IconStar = props => {
  const { rootClassName, className, filled } = props;
  const classes = classNames(rootClassName || css.root, className);

  return filled ? (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
    </svg>
  ) : (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"/>
    </svg>
  );
};

IconStar.defaultProps = {
  rootClassName: null,
  className: null,
};

IconStar.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconStar;