import React, { Component, Fragment } from 'react';
import { array, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { without } from 'ramda';

import { hasSecurityLaw, hasInsurance } from '../../marketplace-custom-config';
import { withViewport } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { ensureCurrentUser, ensureListing } from '../../util/data';
import { Modal, NamedRedirect, Tabs, NeedHelpAdCreation } from '../../components';
import { PayoutDetailsForm } from '../../forms';

import EditListingWizardTab, {
  DESCRIPTION,
  FEATURES,
  INFORMATION,
  INVENTORY,
  PHOTOS,
  POLICY,
  PRESENTATION,
  PRICING,
  SECURITY,
} from './EditListingWizardTab';
import css from './EditListingWizard.css';

// You can reorder these panels.
// Note 1: You need to change save button translations for new listing flow
// Note 2: Ensure that draft listing is created after the first panel
// and listing publishing happens after last panel.
export const TABS = [
  DESCRIPTION,
  PRESENTATION,
  FEATURES,
  INFORMATION,
  PHOTOS,
  POLICY,
  PRICING,
  ...(hasSecurityLaw ? [SECURITY] : []),
  ...(hasInsurance ? [INVENTORY] : []),
];

const tabsToNotShowOnNewListing = [INVENTORY];

const getTabs = isNewListingFlow => isNewListingFlow ? without(tabsToNotShowOnNewListing, TABS) : TABS;

// Tabs are horizontal in small screens
const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

const tabLabel = (intl, tab) => {
  let key = null;
  if (tab === DESCRIPTION) {
    key = 'EditListingWizard.tabLabelDescription';
  } else if (tab === PRESENTATION) {
    key = 'EditListingWizard.tabLabelPresentation';
  } else if (tab === FEATURES) {
    key = 'EditListingWizard.tabLabelFeatures';
  } else if (tab === INFORMATION) {
    key = 'EditListingWizard.tabLabelInformation';
  } else if (tab === PHOTOS) {
    key = 'EditListingWizard.tabLabelPhotos';
  } else if (tab === POLICY) {
    key = 'EditListingWizard.tabLabelPolicy';
  } else if (tab === PRICING) {
    key = 'EditListingWizard.tabLabelPricing';
  } else if (tab === SECURITY) {
    key = 'EditListingWizard.tabLabelSecurity';
  } else if (tab === INVENTORY) {
    key = 'EditListingWizard.tabLabelInventory';
  }

  return intl.formatMessage({ id: key });
};

/**
 * Check if a wizard tab is completed.
 *
 * @param tab wizard's tab
 * @param listing is contains some specific data if tab is completed
 *
 * @return true if tab / step is completed.
 */
const tabCompleted = (tab, listing) => {
  const {
    description,
    geolocation,
    price,
    title,
    publicData,
    privateData,
  } = listing.attributes;
  const images = listing.images;

  switch (tab) {
    case DESCRIPTION:
      return !!(description && title);
    case PRESENTATION:
      return !!(geolocation && publicData &&
        privateData.location &&
        privateData.location.address &&
        publicData.maxCapacityToGuest
      );
    case FEATURES:
      return !!(publicData && publicData.extras);
    case INFORMATION:
      return !!(
        publicData &&
        publicData.proprietairesPresents &&
        publicData.poolTreatment
      );
    case PHOTOS:
      return images && publicData && publicData.poolTreatment;
    case POLICY:
      const hasPolicyChildren = publicData && typeof publicData.enfantsBienvenue !== 'undefined';
      const hasPolicyEvent = publicData && typeof publicData.policyEvent !== 'undefined';
      const hasPolicyAlcohol = publicData && typeof publicData.policyAlcohol !== 'undefined';
      const hasPolicySmoking = publicData && typeof publicData.policySmoking !== 'undefined';
      const hasPolicyPets = publicData && typeof publicData.policyPets !== 'undefined';
      const hasPolicyMusic = publicData && typeof publicData.policyMusic !== 'undefined';

      return hasPolicyChildren
        || hasPolicyEvent
        || hasPolicyAlcohol
        || hasPolicySmoking
        || hasPolicyPets
        || hasPolicyMusic
      ;
    case PRICING:
      return !!price;
    case SECURITY:
      return !!(publicData && publicData.security);
    default:
      return false;
  }
};

/**
 * Check which wizard tabs are active and which are not yet available. Tab is active if previous
 * tab is completed. In edit mode all tabs are active.
 *
 * @param isNew flag if a new listing is being created or an old one being edited
 * @param listing data to be checked
 *
 * @return object containing activity / editability of different tabs of this wizard
 */
const tabsActive = (isNewListingFlow, listing) => {
  const tabs = getTabs(isNewListingFlow);
  return tabs.reduce((acc, tab) => {
    const previousTabIndex = tabs.findIndex(t => t === tab) - 1;
    const isActive =
      previousTabIndex >= 0 ? !isNewListingFlow || tabCompleted(tabs[previousTabIndex], listing) : true;
    return { ...acc, [tab]: isActive };
  }, {});
};

const scrollToTab = (tabPrefix, tabId) => {
  const el = document.querySelector(`#${tabPrefix}_${tabId}`);
  if (el) {
    el.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  }
};

// Get attribute: stripeAccountData
const getStripeAccountData = stripeAccount => stripeAccount.attributes.stripeAccountData || null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

// Create a new or edit listing through EditListingWizard
class EditListingWizard extends Component {
  constructor(props) {
    super(props);

    // Having this info in state would trigger unnecessary rerendering
    this.hasScrolledToTab = false;

    this.state = {
      draftId: null,
      showPayoutDetails: false,
    };
    this.handleCreateFlowTabScrolling = this.handleCreateFlowTabScrolling.bind(this);
    this.handlePublishListing = this.handlePublishListing.bind(this);
    this.handlePayoutModalClose = this.handlePayoutModalClose.bind(this);
    this.handlePayoutSubmit = this.handlePayoutSubmit.bind(this);
  }

  componentDidMount() {
    const { stripeOnboardingReturnURL } = this.props;

    if (stripeOnboardingReturnURL != null && !this.showPayoutDetails) {
      this.setState({ showPayoutDetails: true });
    }
  }

  handleCreateFlowTabScrolling(shouldScroll) {
    this.hasScrolledToTab = shouldScroll;
  }

  handlePublishListing(id) {
    const { onPublishListingDraft, currentUser, stripeAccount } = this.props;

    const stripeConnected =
      currentUser && currentUser.stripeAccount && !!currentUser.stripeAccount.id;

    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));

    if (stripeConnected && !requirementsMissing) {
      onPublishListingDraft(id, this.props.history);
    } else {
      this.setState({
        draftId: id,
        showPayoutDetails: true,
      });
    }
  }

  handlePayoutModalClose() {
    this.setState({ showPayoutDetails: false });
  }

  setCNIDocument = cniFormData => {
    this.setState({ cniFormData });
  };

  setProofOfAddressDocument = proofOfAddressFormData => {
    this.setState({ proofOfAddressFormData });
  };

  handlePayoutSubmit(values) {
    const { fname: firstName, lname: lastName, ...rest } = values;
    const { cniFormData, proofOfAddressFormData } = this.state;

    this.props
      .onPayoutDetailsSubmit({ firstName, lastName, cniFormData, proofOfAddressFormData, ...rest })
      .then(() => {
        const { history } = this.props;
        this.setState({ showPayoutDetails: false });
        this.props.onManageDisableScrolling('EditListingWizard.payoutModal', false);
        this.props.onPublishListingDraft(this.state.draftId, history);
      })
      .catch(() => {
        // do nothing
      });
  }

  render() {
    const {
      id,
      className,
      rootClassName,
      params,
      listing,
      viewport,
      intl,
      errors,
      fetchInProgress,
      payoutDetailsSaveInProgress,
      payoutDetailsSaved,
      onManageDisableScrolling,
      onPayoutDetailsFormChange,
      onGetStripeConnectAccountLink,
      getAccountLinkInProgress,
      createStripeAccountError,
      updateStripeAccountError,
      fetchStripeAccountError,
      stripeAccountFetched,
      stripeAccount,
      stripeAccountError,
      stripeAccountLinkError,
      currentUser,
      showBirthdayDateField,
      showListingInProgress,
      ...rest
    } = this.props;

    const selectedTab = params.tab;
    const isNewListingFlow = [LISTING_PAGE_PARAM_TYPE_NEW, LISTING_PAGE_PARAM_TYPE_DRAFT].includes(
      params.type
    );
    const tabs = getTabs(isNewListingFlow);
    const rootClasses = rootClassName || css.root;
    const classes = classNames(rootClasses, className);
    const currentListing = ensureListing(listing);
    const tabsStatus = tabsActive(isNewListingFlow, currentListing);

    // If selectedTab is not active, redirect to the beginning of wizard
    if (!tabsStatus[selectedTab]) {
      const currentTabIndex = tabs.indexOf(selectedTab);
      const nearestActiveTab = tabs.slice(0, currentTabIndex)
        .reverse()
        .find(t => tabsStatus[t]);

      return <NamedRedirect name="EditListingPage" params={{ ...params, tab: nearestActiveTab }} />;
    }

    const { width } = viewport;
    const hasViewport = width > 0;
    const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const isMobileLayout = hasViewport && width < 768;
    const hasFontsLoaded =
      hasViewport && document.documentElement.classList.contains('fontsLoaded');

    // Check if scrollToTab call is needed (tab is not visible on mobile)
    if (hasVerticalTabLayout) {
      this.hasScrolledToTab = true;
    } else if (hasHorizontalTabLayout && !this.hasScrolledToTab && hasFontsLoaded) {
      const tabPrefix = id;
      scrollToTab(tabPrefix, selectedTab);
      this.hasScrolledToTab = true;
    }

    const tabLink = tab => {
      return { name: 'EditListingPage', params: { ...params, tab } };
    };

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!ensuredCurrentUser.id;
    const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;


    return (
      <div className={classes}>
        <Tabs
          rootClassName={css.tabsContainer}
          navRootClassName={css.nav}
          tabRootClassName={css.tab}
          isDisplayingNeedHelp={isMobileLayout ? false : true}
        >
          {tabs.map(tab => {
            return (
              <EditListingWizardTab
                {...rest}
                key={tab}
                tabId={`${id}_${tab}`}
                tabLabel={tabLabel(intl, tab)}
                tabLinkProps={tabLink(tab)}
                selected={selectedTab === tab}
                disabled={isNewListingFlow && !tabsStatus[tab]}
                tab={tab}
                intl={intl}
                params={params}
                listing={listing}
                marketplaceTabs={tabs}
                errors={errors}
                handleCreateFlowTabScrolling={this.handleCreateFlowTabScrolling}
                handlePublishListing={this.handlePublishListing}
                fetchInProgress={fetchInProgress}
                handleIsValidatedPhotos={this.handleIsValidatedPhotos}
                handleIsValidatedFeatures={this.handleIsValidatedFeatures}
                handleIsValidatedPolicies={this.handleIsValidatedPolicies}
                showListingInProgress={showListingInProgress}
              />
            );
          })}
        </Tabs>
        {isMobileLayout && <NeedHelpAdCreation/>}
        <Modal
          id="EditListingWizard.payoutModal"
          isOpen={this.state.showPayoutDetails}
          onClose={this.handlePayoutModalClose}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.modalPayoutDetailsWrapper}>
            <h1 className={css.modalTitle}>
              <FormattedMessage id="EditListingWizard.payoutModalTitleOneMoreThing" />
              <br />
              <FormattedMessage id="EditListingWizard.payoutModalTitlePayoutPreferences" />
            </h1>
          </div>
          {!currentUserLoaded ? (
              <FormattedMessage id="StripePayoutPage.loadingData" />
            ) : (!stripeConnected || stripeAccountLinkError) && (
              <Fragment>
                <p className={css.modalMessage}>
                  <FormattedMessage id="EditListingWizard.payoutModalInfo" />
                </p>
                <PayoutDetailsForm
                  className={css.payoutDetails}
                  inProgress={fetchInProgress}
                  createStripeAccountError={errors ? errors.createStripeAccountError : null}
                  setCNIDocument={this.setCNIDocument}
                  setProofOfAddressDocument={this.setProofOfAddressDocument}
                  onChange={onPayoutDetailsFormChange}
                  onSubmit={this.handlePayoutSubmit}
                  showBirthdayDateField={showBirthdayDateField}
                />
              </Fragment>
            )}
        </Modal>
      </div>
    );
  }
}

EditListingWizard.defaultProps = {
  className: null,
  currentUser: null,
  rootClassName: null,
  listing: null,
  stripeAccount: null,
  stripeAccountFetched: null,
  updateInProgress: false,
  createStripeAccountError: null,
  updateStripeAccountError: null,
  fetchStripeAccountError: null,
  stripeAccountError: null,
  stripeAccountLinkError: null,
};

EditListingWizard.propTypes = {
  id: string.isRequired,
  className: string,
  currentUser: propTypes.currentUser,
  rootClassName: string,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(TABS).isRequired,
  }).isRequired,
  stripeAccount: object,
  stripeAccountFetched: bool,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  errors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,
  createStripeAccountError: propTypes.error,
  updateStripeAccountError: propTypes.error,
  fetchStripeAccountError: propTypes.error,
  stripeAccountError: propTypes.error,
  stripeAccountLinkError: propTypes.error,

  fetchInProgress: bool.isRequired,
  getAccountLinkInProgress: bool.isRequired,
  payoutDetailsSaveInProgress: bool.isRequired,
  payoutDetailsSaved: bool.isRequired,
  onPayoutDetailsFormChange: func.isRequired,
  onGetStripeConnectAccountLink: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withViewport,
  injectIntl
)(EditListingWizard);
