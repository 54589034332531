import { CUSTOMER_COMMISSION_PERCENTAGE } from '@swimmy_/helpers';

import { types as sdkTypes } from '../util/sdkLoader';

const { Money } = sdkTypes;

export const withCustomerFee = (money) => {
  if (!money) return null;

  const amountWithFee = money.amount * (100 + CUSTOMER_COMMISSION_PERCENTAGE) / 100;

  return new Money(amountWithFee, money.currency);
};
