import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';

import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import { isChangePasswordWrongPassword } from '../../util/errors';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './PasswordChangeForm.css';

const RESET_TIMEOUT = 800;

const PasswordChangeForm = (props) => {
  const [restartTimeoutId, setRestartTimeoutId] = useState();
  const [submittedValues, setSubmittedValues] = useState({});
  useEffect(() => {
    return () => window.clearTimeout(restartTimeoutId);
  }, []);

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          changePasswordError,
          currentUser,
          active,
          handleSubmit,
          inProgress,
          intl,
          invalid,
          ready,
          form,
          values,
          visited,
        } = fieldRenderProps;
        const user = ensureCurrentUser(currentUser);

        if (!user.id) return null;

        const passwordLabel = intl.formatMessage({ id: 'PasswordChangeForm.passwordLabel' });
        const passwordRequiredMessage = intl.formatMessage({ id: 'PasswordChangeForm.passwordRequired' });
        const passwordFailedMessage = intl.formatMessage({ id: 'PasswordChangeForm.passwordFailed' });
        const newPasswordLabel = intl.formatMessage({ id: 'PasswordChangeForm.newPasswordLabel' });
        const newPasswordRequiredMessage = intl.formatMessage({ id: 'PasswordChangeForm.newPasswordRequired' });
        const confirmPasswordLabel = intl.formatMessage({ id: 'PasswordChangeForm.confirmPasswordLabel' });
        const confirmPasswordRequiredMessage = intl.formatMessage({ id: 'PasswordChangeForm.confirmPasswordRequired' });
        const confirmPasswordErrorText = intl.formatMessage({ id: 'PasswordChangeForm.confirmPasswordError' });
        const passwordMinLengthMessage = intl.formatMessage(
          { id: 'PasswordChangeForm.passwordTooShort' },
          { minLength: validators.PASSWORD_MIN_LENGTH },
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          { id: 'PasswordChangeForm.passwordTooLong' },
          { maxLength: validators.PASSWORD_MAX_LENGTH },
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const newPasswordRequired = validators.requiredStringNoTrim(newPasswordRequiredMessage);
        const confirmPasswordRequired = validators.requiredStringNoTrim(confirmPasswordRequiredMessage);
        const passwordTouched = submittedValues.currentPassword !== values.currentPassword;
        const passwordErrorText = isChangePasswordWrongPassword(changePasswordError)
          ? passwordFailedMessage
          : null;
        const genericFailure =
          changePasswordError && !passwordErrorText ? (
            <span className={css.error}>
              <FormattedMessage id="PasswordChangeForm.genericFailure" />
            </span>
          ) : null;
        const submittedOnce = Object.keys(submittedValues).length > 0;
        const pristineSinceLastSubmit = submittedOnce && isEqual(values, submittedValues);
        const classes = classNames(rootClassName || css.root, className);
        const submitDisabled = invalid || pristineSinceLastSubmit || inProgress;
        const confirmPasswordError = visited.confirmPassword
          && active !== 'confirmPassword'
          && values.confirmPassword !== values.newPassword;

        return (
          <Form
            className={classes}
            onSubmit={e => {
              setSubmittedValues(values);
              handleSubmit(e)
                .then(() => {
                  setRestartTimeoutId(window.setTimeout(form.restart, RESET_TIMEOUT));
                })
                .catch(() => {
                  // Error is handled in duck file already.
                });
            }}
          >
            <FieldTextInput
              className={css.field}
              type="password"
              id="currentPassword"
              name="currentPassword"
              label={passwordLabel}
              validate={passwordRequired}
              customErrorText={passwordTouched ? null : passwordErrorText}
            />
            <FieldTextInput
              className={css.field}
              type="password"
              id={formId ? `${formId}.newPassword` : 'newPassword'}
              name="newPassword"
              autoComplete="new-password"
              label={newPasswordLabel}
              validate={validators.composeValidators(
                newPasswordRequired,
                passwordMinLength,
                passwordMaxLength
              )}
            />
            <FieldTextInput
              className={css.field}
              type="password"
              id={formId ? `${formId}.confirmPassword` : 'confirmPassword'}
              name="confirmPassword"
              label={confirmPasswordLabel}
              validate={validators.composeValidators(
                confirmPasswordRequired,
              )}
              customErrorText={confirmPasswordError ? confirmPasswordErrorText : null}
            />
            <div className={css.bottomWrapper}>
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={inProgress}
                ready={ready}
                disabled={submitDisabled}
              >
                <FormattedMessage id="PasswordChangeForm.saveChanges" />
              </PrimaryButton>
              {genericFailure}
            </div>
          </Form>
        );
      }}
    />
  );
}

PasswordChangeForm.defaultProps = {
  rootClassName: null,
  className: null,
  changePasswordError: null,
  inProgress: false,
  formId: null,
};

PasswordChangeForm.propTypes = {
  rootClassName: string,
  className: string,
  changePasswordError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
  formId: string,
};

export default injectIntl(PasswordChangeForm);
