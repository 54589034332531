import React from 'react';
import { array, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { length, map } from 'ramda';

import { hoursArray } from '../../../../../util/time';

import {
  addValueToDayPlan,
  removeValueFromDayPlan
} from '../PlanManager.helpers';
import css from './DayGroup.module.css';

const DayGroup = (props) => {
  const {
    className,
    dayString,
    isMobile,
    setValues,
    values,
  } = props;
  const handleDayClick = (e) => {
    if (length(values)) {
      return setValues([])
    } else {
      return setValues([9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23])
    }
  }
  const addTimeClick = (startTime) => {
    setValues(prev => addValueToDayPlan(startTime, prev))
  }
  const deleteTimeClick = (startTime) => {
    setValues(prev => removeValueFromDayPlan(startTime, prev))
  }
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <div className={css.labelContainer}>
        <button
          className={css.buttonLabel}
          onClick={handleDayClick}
        >
          {isMobile ? dayString.short : dayString.long}
        </button>
      </div>
      {map(time => {
        if (time.key < 9 || time.key > 23) return null;

        const isAvailable = values.includes(time.key);
        const timeButtonClasses = classNames(css.timeButton, {
          [css.available]: isAvailable,
        })

        return (
          <button
            key={time.key}
            className={timeButtonClasses}
            onClick={() => isAvailable ? deleteTimeClick(time.key) : addTimeClick(time.key)}
          >

          </button>
        )
      }, hoursArray)}
    </div>
  )
}

DayGroup.defaultProps = {
  className: null,
  isMobile: false,
};

DayGroup.propTypes = {
  className: string,
  dayString: object.isRequired,
  isMobile: bool,
  setValues: func.isRequired,
  values: array.isRequired,
};

export default DayGroup;
