import React, { useEffect } from 'react';
import { path} from 'ramda';
import { func, shape, string } from 'prop-types';
import { intlShape } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';

import {linkHOC} from '../../util/routes';

import css from './styles.css';

const BlogContent = (props) => {
  const { description, content, cover = {}, summary } = props;

  const isServer = typeof window === 'undefined';
  useEffect(() => {
    if (isServer) return;

    const {document} = window;
    const tables = document.getElementsByTagName('table');
    if (tables.length > 0) {
      for (const table of tables) {
        const tableParent = table.closest('div');
        if (!tableParent) return;

        tableParent.style.overflowX = 'auto';
      }
    }
    const buttons = document.getElementsByClassName('buttonPrimary');
    if (buttons.length > 0) {
      for (const button of buttons) {
        button.classList.add(css.buttonPrimary);
      }
    }
    const blueBackgrounds = document.getElementsByClassName('blueBackground');
    if (blueBackgrounds.length > 0) {
      for (const blueBackground of blueBackgrounds) {
        blueBackground.classList.add(css.blueBackground);
      }
    }
  }, []);
  const coverFullUrl = path(['url'], cover);

  return (
    <div>
      <div className={css.padding}>
        <ReactMarkdown
          escapeHtml={false}
          source={description}
          className={classNames(css.markdown, css.markdownDescription)}
          renderers={{
            link: linkHOC,
            table: (props) => (
              <div style={{overflowX: 'auto'}}>
                <table {...props}/>
              </div>
            ),
          }}
        />
      </div>
      {coverFullUrl && (
        <div className={css.centerBlock}>
          <img src={coverFullUrl} alt='cover' className={css.cover}/>
        </div>
      )}
      <div className={css.padding}>
        <ReactMarkdown
          escapeHtml={false}
          source={summary}
          className={css.summaryMarkdown}
          renderers={{
            link: linkHOC,
          }}
        />
      </div>
      <div className={css.padding}>
        <ReactMarkdown
          escapeHtml={false}
          source={content}
          className={css.markdown}
          renderers={{
            link: linkHOC,
            table: (props) => (<div style={{overflowX: 'auto'}}>
              <table {...props}/>
            </div>),
          }}
        />
      </div>
    </div>
  );
};

BlogContent.defaultProps = {};

BlogContent.propTypes = {};

export default BlogContent;
