import React, { useEffect, useRef, useState } from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import { compose } from 'redux';
import { intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';

import config from '../../../config';
import routeConfiguration from '../../../routeConfiguration';
import { withViewport } from '../../../util/contextHelpers';
import { isServer } from '../../../util/sdkLoader';
import { pathByRouteName } from '../../../util/routes';
import { propTypes } from '../../../util/types';
import { FadeAnimation, SlideAnimation } from '../../../components/Animations';
import {
  NavigationDrawer,
  ModalMissingInformation,
  Search,
  SearchDesktop,
  ZendeskWidget,
} from '../../../components';

import {
  pagesWithAddListingLink,
  pagesWithoutPersistentNav,
  pagesWithSearchButton,
} from './utils/pageOptions';
import {
  AddListingLink,
  GenericError,
  LocalizationMenu,
  LogoLink,
  MenuButton,
} from './components';
import css from './Topbar.css';

const MAX_WIDTH_MOBILE = 767;

const Topbar = props => {
  const {
    className,
    rootClassName,
    isAuthenticated,
    authInProgress,
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    currentUserHasPublishedListings,
    currentPage,
    notificationCount,
    messagesNotificationCount,
    location,
    onManageDisableScrolling,
    onResendVerificationEmail,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    showGenericError,
    onLogout,
    viewport,
    history,
    intl,
    onUpdateProfile,
  } = props;
  const [showMenu, setShowMenu] = useState(false);
  const [offSet, setOffSet] = useState(0);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [scrollToTop, setScrollToTop] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const prevOffSet = useRef(offSet);
  const scrolled = scrollToBottom || scrollToTop;
  useEffect(() => {
    if (!showMenu || notificationCount <= 0) return;

    onUpdateProfile({privateData: {badge: 0}});
  }, [showMenu]);
  const isMobile = !isServer() && window.innerWidth <= MAX_WIDTH_MOBILE;
  const handleScroll = () => {
    if (showMenu) return;

    prevOffSet.current = offSet;
    setOffSet(window.pageYOffset);
    if (window.pageYOffset === 0) {
      setScrollToBottom(false);
      setScrollToTop(false);

      return;
    }
    if (!scrollToBottom && window.pageYOffset > offSet) {
      setScrollToBottom(true);
    } else if (scrollToBottom && window.pageYOffset < offSet) {
      setScrollToBottom(false);
    }
    if (!scrollToTop && window.pageYOffset < offSet) {
      setScrollToTop(true);
    } else if (scrollToTop && window.pageYOffset > offSet) {
      setScrollToTop(false);
    }
    const offSetDiff = Math.abs(prevOffSet.current - window.pageYOffset);
    if (offSetDiff > 20 && viewport.width >= 1024) {
      setShowSearch(false);
    }
  }
  useEffect(() => {
    if (isServer()) return;

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll);
  });
  const handleShowSearch = () => setShowSearch(true);
  const handleHideSearch = () => setShowSearch(false);
  const handleLogout = () => {
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  };
  const hasUnpublishedListings = currentUserHasListings && !currentUserHasPublishedListings;
  const isPageWithoutPersistentNav = pagesWithoutPersistentNav.includes(currentPage);
  const isPageWithSearch = pagesWithSearchButton.includes(currentPage);
  const isPageWithAddListingLink = pagesWithAddListingLink.includes(currentPage);
  const showNavContainer = (!isMobile || !scrolled || scrollToTop || !isPageWithoutPersistentNav) || showSearch;
  const showNavContainerBg = scrolled || showSearch || !['LandingPage','SimulatorPage'].includes(currentPage);
  const showSearchForm = showSearch || (currentPage === 'LandingPage' && offSet === 0);
  const showAddListingLink = !showSearch && showNavContainerBg;
  const showLocalizationMenu = !['CalendarPage', 'InboxPage'].includes(currentPage);
  const classes = classNames(rootClassName || css.root, className);
  const navContainerClasses = classNames(css.navContainer, {
    [css.navContainerFixed]: showNavContainerBg || isMobile,
    [css.navContainerWithoutShadow]: isMobile && isPageWithSearch && showNavContainerBg,
    [css.navContainerWithSearch]: showSearch,
  });
  const navWrapperClasses = classNames(css.navWrapper, {
    [css.navWrapperFullWidth]: ['CalendarPage', 'InboxPage', 'SearchPage'].includes(currentPage),
  });
  const menuButtonClasses = classNames(css.menuButton, {
    [css.menuButtonFixed]: showNavContainerBg,
    [css.menuButtonMatterColor]: ['SimulatorPage'].includes(currentPage),
  });
  const localizationMenuClasses = classNames(css.localizationMenu, {
    [css.localizationMenuFixed]: showNavContainerBg,
  });
  const logoLinkClasses = classNames(css.logoLink, {
    [css.logoLinkFixed]: showNavContainerBg,
  });

  return (
    <div className={classes}>
      {!isServer() && config.custom.hasZendeskWidget && (
        <ZendeskWidget currentPage={currentPage} />
      )}
      <FadeAnimation inProps={showSearch} mountOnEnter>
        <div className={css.opacityBackground} onClick={handleHideSearch} />
      </FadeAnimation>
      <SlideAnimation inProps={showNavContainer} from="top">
        <div className={navContainerClasses}>
          <div className={navWrapperClasses}>
            <div className={css.navLeftContainer}>
              <MenuButton
                className={menuButtonClasses}
                currentPage={currentPage}
                currentUser={currentUser}
                notificationCount={notificationCount}
                handleMenuOpen={() => setShowMenu(true)}
                hasUnpublishedListings={hasUnpublishedListings}
                intl={intl}
              />
              {showLocalizationMenu && <LocalizationMenu className={localizationMenuClasses} />}
            </div>
            <div className={css.buttonsContainer}>
              {isMobile && isPageWithSearch && (
                <Search
                  className={css.search}
                  currentPage={currentPage}
                  fixed={showNavContainerBg}
                />
              )}
              {!isMobile && isPageWithSearch && (
                <SearchDesktop
                  currentPage={currentPage}
                  handleHideSearch={handleHideSearch}
                  handleShowSearch={handleShowSearch}
                  showSearch={showSearch}
                  showSearchForm={showSearchForm}
                />
              )}
              {isPageWithAddListingLink && (
                <AddListingLink
                  currentPage={currentPage}
                  intl={intl}
                  isMobile={isMobile}
                  show={showAddListingLink}
                />
              )}
            </div>
            <div className={css.navRightContainer}>
              <LogoLink className={logoLinkClasses} intl={intl} />
            </div>
          </div>
        </div>
      </SlideAnimation>
      {!isServer() && (
        <NavigationDrawer
          id="NavigationDrawer"
          isOpen={showMenu}
          onClose={() => setShowMenu(false)}
          isAuthenticated={isAuthenticated}
          currentPage={currentPage}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasPublishedListings={currentUserHasPublishedListings}
          onLogout={handleLogout}
          messagesNotificationCount={messagesNotificationCount}
          onManageDisableScrolling={onManageDisableScrolling}
          intl={intl}
        />
      )}
      <ModalMissingInformation
        id="MissingInformationReminder"
        containerClassName={css.missingInformationModal}
        currentUser={currentUser}
        currentUserHasListings={currentUserHasListings}
        currentUserHasOrders={currentUserHasOrders}
        currentUserHasPublishedListings={currentUserHasPublishedListings}
        location={location}
        onManageDisableScrolling={onManageDisableScrolling}
        onResendVerificationEmail={onResendVerificationEmail}
        sendVerificationEmailInProgress={sendVerificationEmailInProgress}
        sendVerificationEmailError={sendVerificationEmailError}
      />
      <GenericError show={showGenericError} />
    </div>
  );
}

Topbar.defaultProps = {
  className: null,
  rootClassName: null,
  notificationCount: 0,
  messagesNotificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
};

Topbar.propTypes = {
  className: string,
  rootClassName: string,
  isAuthenticated: bool.isRequired,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentUserHasPublishedListings: bool.isRequired,
  currentPage: string,
  notificationCount: number,
  messagesNotificationCount: number,
  onLogout: func.isRequired,
  onUpdateProfile: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

Topbar.displayName = 'Topbar';

export default compose(withViewport, injectIntl)(Topbar);