import React from 'react';
import { arrayOf, func, node, number, object, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';

import { FieldBoolean, FilterPopup, FilterPlain } from '../../components';

import css from './BooleanFilter.css';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

// Format URI component's query param: { pub_key: 'a,' }
const format = (value, queryParamName) => {
  const queryParam = { [queryParamName]: value === true ? value : null };

  return queryParam;
};

const BooleanFilter = (props) => {
  const {
    rootClassName,
    className,
    id,
    name,
    label,
    initialValues,
    contentPlacementOffset,
    onSubmit,
    queryParamNames,
    intl,
    showAsPopup,
    helper,
    ...rest
  } = props;
  const handleSubmit = values => {
    const usedValue = values ? values[name] : values;
    onSubmit(format(usedValue, queryParamName));
  };
  const classes = classNames(rootClassName || css.root, className);
  const filterWrapperClasses = classNames(css.filtersWrapper, { [css.filtersWrapperPopup]: showAsPopup });
  const queryParamName = getQueryParamName(queryParamNames);
  const hasInitialValues = !!initialValues && !!initialValues[queryParamName];
  const namedInitialValues = { [name]: initialValues[queryParamName] };
  const labelText = intl.formatMessage({ id: label });
  const helperText = intl.formatMessage({ id: helper });

  return showAsPopup ? (
    <FilterPopup
      className={classes}
      rootClassName={rootClassName}
      popupClassName={css.popupSize}
      name={name}
      label={labelText}
      isSelected={hasInitialValues}
      id={`${id}.popup`}
      showAsPopup
      contentPlacementOffset={contentPlacementOffset}
      onSubmit={handleSubmit}
      initialValues={namedInitialValues}
      keepDirtyOnReinitialize
      {...rest}
    >
      <FieldBoolean
        className={filterWrapperClasses}
        id={id}
        name={name}
        label={labelText}
        helper={helperText}
        variant="switch"
      />
    </FilterPopup>
  ) : (
    <FilterPlain
      className={className}
      rootClassName={rootClassName}
      label={labelText}
      isSelected={hasInitialValues}
      id={`${id}.plain`}
      liveEdit
      onSubmit={handleSubmit}
      initialValues={namedInitialValues}
      withoutClear
      {...rest}
    >
      <FieldBoolean
        className={filterWrapperClasses}
        id={id}
        name={name}
        helper={helperText}
        variant="switch"
      />
    </FilterPlain>
  );
}

BooleanFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

BooleanFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSubmit: func.isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(BooleanFilter);
