import config from '../../config';

export const addTransactionTracking = async (transaction) => {
  const {
    transactionId,
    customerId,
    providerId,
    bookingDate,
    followedBy,
    state,
    numberOfParticipants,
  } = transaction;
  if (!config.transactionTrackerServiceUri) return ;

  const url = `${config.transactionTrackerServiceUri}/addTransaction`;
  try {
    return await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        transactionId,
        customerId,
        providerId,
        bookingDate,
        followedBy,
        state,
        numberOfParticipants,
      }),
    });
  }
  catch (e) {
    console.error(e);
  }
};

export const changeStateTransactionTracking = async (transactionId, state) => {
  if (!config.transactionTrackerServiceUri) return ;

  const url = `${config.transactionTrackerServiceUri}/changeStateTransaction`;
  try {
    return await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        transactionId,
        state,
      }),
    });
  }
  catch (e) {
    console.error(e);
  }
};
