import React from 'react';
import { func, object, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { pathOr } from 'ramda';

import {
  ModalContent,
  ModalHeader,
  ModalBody,
} from '../../../../components/ModalSwimmy';
import {
  Emoji,
  IconArrowHead,
} from '../../../../components';

import css from './TypeView.module.css';

const TypeView = (props) => {
  const {
    className,
    formProps,
    formValues,
    handleView,
    intl,
  } = props;
  const handlePrevious = () => {
    formProps.reset();
    handleView('LocationView');
  }
  const handleClick = (input) => {
    input.onChange(input.value);
    handleView('DateView');
  };
  const currentCity = pathOr('', ['location', 'selectedPlace', 'city'], formValues);
  const headingText = intl.formatMessage({ id: 'Search.TypeView.heading' });
  const allText = intl.formatMessage({ id: 'Search.TypeView.all' });
  const winterText = intl.formatMessage({ id: 'Search.TypeView.winter' });
  const eventText = intl.formatMessage({ id: 'Search.TypeView.event' });
  const jacuzziText = intl.formatMessage({ id: 'Search.TypeView.jacuzzi' });
  const saunaText = intl.formatMessage({ id: 'Search.TypeView.sauna' });
  const classes = classNames(css.modalContent, className);

  return (
    <ModalContent className={classes}>
      <ModalHeader className={css.modalHeader}>
        <h1 className={css.heading}>{headingText}</h1>
        <button className={css.backButton} type="button" onClick={handlePrevious}>
          <IconArrowHead className={css.iconArrow} direction="left" />
        </button>
        <span className={css.location}>{currentCity}</span>
      </ModalHeader>
      <ModalBody className={css.modalBody}>
        <Field
          name="typeOfPool"
          type="radio"
          value="offSeason"
          render={({ input }) => {
            return (
              <button className={css.button} type="button" onClick={() => handleClick(input)}>
                {winterText}
                <Emoji className={css.emoji} symbol={0x2744} />
              </button>
            )
          }}
        />
        <Field
          name="typeOfPool"
          type="radio"
          value="event"
          render={({ input }) => {
            return (
              <button className={css.button} type="button" onClick={() => handleClick(input)}>
                {eventText}
                <Emoji className={css.emoji} symbol={0x1F942} />
              </button>
            )
          }}
        />
        <Field
          name="typeOfPool"
          type="radio"
          value="jacuzzi"
          render={({ input }) => {
            return (
              <button className={css.button} type="button" onClick={() => handleClick(input)}>
                {jacuzziText}
                <Emoji className={css.emoji} symbol={0x1F6C1} />
              </button>
            )
          }}
        />
        <Field
          name="typeOfPool"
          type="radio"
          value="sauna"
          render={({ input }) => {
            return (
              <button className={css.button} type="button" onClick={() => handleClick(input)}>
                {saunaText}
                <Emoji className={css.emoji} symbol={0x2668} />
              </button>
            )
          }}
        />
        <Field
          name="typeOfPool"
          type="radio"
          value="all"
          render={({ input }) => {
            return (
              <button className={css.button} type="button" onClick={() => handleClick(input)}>
                {allText}
                <Emoji className={css.emoji} symbol={0x1F3CA} />
              </button>
            )
          }}
        />
      </ModalBody>
    </ModalContent>
  )
}

TypeView.defaultProps = {
  className: null,
  formProps: {},
  formValues: {},
};

TypeView.propTypes = {
  className: string,
  formProps: object,
  formValues: object,
  handleView: func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(TypeView);
