import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconHeart.module.css';

const IconHeart = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.4983 3.64956C16.094 3.24503 15.6139 2.92412 15.0855 2.70517C14.5571 2.48623 13.9907 2.37354 13.4187 2.37354C12.8468 2.37354 12.2804 2.48623 11.752 2.70517C11.2236 2.92412 10.7435 3.24503 10.3392 3.64956L9.5 4.48873L8.66083 3.64956C7.84408 2.83281 6.73632 2.37396 5.58125 2.37396C4.42618 2.37396 3.31842 2.83281 2.50166 3.64956C1.68491 4.46632 1.22606 5.57408 1.22606 6.72915C1.22606 7.88422 1.68491 8.99198 2.50166 9.80873L3.34083 10.6479L9.5 16.8071L15.6592 10.6479L16.4983 9.80873C16.9029 9.40438 17.2238 8.92429 17.4427 8.39589C17.6617 7.86748 17.7744 7.30112 17.7744 6.72915C17.7744 6.15718 17.6617 5.59081 17.4427 5.06241C17.2238 4.534 16.9029 4.05391 16.4983 3.64956V3.64956Z" strokeWidth="1.56091" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
};

IconHeart.defaultProps = {
  rootClassName: null,
  className: null,
};

IconHeart.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconHeart;
