import React from 'react';

import config from '../../config';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import css from './HowItWorksPage.css';

const HowItWorksPage = (props) => {
  const { intl } = props;
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'HowItWorksPage.schema.title' }, { siteTitle });
  const title = intl.formatMessage({ id: 'HowItWorksPage.title' });
  const createListingLink = (
    <NamedLink name="NewListingPage">
      <FormattedMessage id="HowItWorksPage.createListingLink" />
    </NamedLink>
  );

  return (
    <StaticPage
      className={css.root}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="HowItWorksPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.titleContainer}>
            <h1 className={css.title}>{title}</h1>
          </div>
          <div className={css.sectionHowItWorks}>
            <div className={css.providerContainer}>
              <div className={css.providerTitle}>
                <FormattedMessage id="HowItWorksPage.provider.title" />
              </div>
              <div className={css.partsContainer}>
                <div className={css.partContainer}>
                  <div className={css.partTitle}>
                    <FormattedMessage id="HowItWorksPage.provider.part1.title" />
                  </div>
                  <div className={css.partText}>
                    <FormattedMessage id="HowItWorksPage.provider.part1.text" values={{ link: createListingLink }} />
                  </div>
                </div>
                <div className={css.partContainer}>
                  <div className={css.partTitle}>
                    <FormattedMessage id="HowItWorksPage.provider.part2.title" />
                  </div>
                  <div className={css.partText}>
                    <FormattedMessage id="HowItWorksPage.provider.part2.text" />
                  </div>
                </div>
                <div className={css.partContainer}>
                  <div className={css.partTitle}>
                    <FormattedMessage id="HowItWorksPage.provider.part3.title" />
                  </div>
                  <div className={css.partText}>
                    <FormattedMessage id="HowItWorksPage.provider.part3.text" />
                  </div>
                </div>
                <div className={css.partContainer}>
                  <div className={css.partTitle}>
                    <FormattedMessage id="HowItWorksPage.provider.part4.title" />
                  </div>
                  <div className={css.partText}>
                    <FormattedMessage id="HowItWorksPage.provider.part4.text" />
                  </div>
                </div>
                <div className={css.partContainer}>
                  <div className={css.partTitle}>
                    <FormattedMessage id="HowItWorksPage.provider.part5.title" />
                  </div>
                  <div className={css.partText}>
                    <FormattedMessage id="HowItWorksPage.provider.part5.text" />
                  </div>
                </div>
              </div>
              <NamedLink name="NewListingPage" className={css.button}>
                <FormattedMessage id="HowItWorksPage.createListingLink" />
              </NamedLink>
            </div>
            
            <div className={css.mobileBetween} />

            <div className={css.customerContainer}>
              <div className={css.customerTitle}>
                <FormattedMessage id="HowItWorksPage.customer.title" />
              </div>
              <div className={css.partsContainer}>
                <div className={css.partContainer}>
                  <div className={css.partTitle}>
                    <FormattedMessage id="HowItWorksPage.customer.part1.title" />
                  </div>
                  <div className={css.partText}>
                    <FormattedMessage id="HowItWorksPage.customer.part1.text" />
                  </div>
                </div>
                <div className={css.partContainer}>
                  <div className={css.partTitle}>
                    <FormattedMessage id="HowItWorksPage.customer.part2.title" />
                  </div>
                  <div className={css.partText}>
                    <FormattedMessage id="HowItWorksPage.customer.part2.text" />
                  </div>
                </div>
                <div className={css.partContainer}>
                  <div className={css.partTitle}>
                    <FormattedMessage id="HowItWorksPage.customer.part3.title" />
                  </div>
                  <div className={css.partText}>
                    <FormattedMessage id="HowItWorksPage.customer.part3.text" />
                  </div>
                </div>
                <div className={css.partContainer}>
                  <div className={css.partTitle}>
                    <FormattedMessage id="HowItWorksPage.customer.part4.title" />
                  </div>
                  <div className={css.partText}>
                    <FormattedMessage id="HowItWorksPage.customer.part4.text" />
                  </div>
                </div>
                <div className={css.partContainer}>
                  <div className={css.partTitle}>
                    <FormattedMessage id="HowItWorksPage.customer.part5.title" />
                  </div>
                  <div className={css.partText}>
                    <FormattedMessage id="HowItWorksPage.customer.part5.text" />
                  </div>
                </div>
              </div>
              <NamedLink name="LandingPage" className={css.button}>
                <FormattedMessage id="HowItWorksPage.findListingLink" />
              </NamedLink>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default injectIntl(HowItWorksPage);