import React from 'react';
import { string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { map } from 'ramda';

import {
  IconArrow,
  IconStar,
  IconTrash,
} from '../../../../components/Icons';

import css from './Informations.module.css';

const Informations = (props) => {
  const { className, intl } = props;
  const informationText = intl.formatMessage({ id: 'EditListingPhotosPanel.information' });
  const listItems = [
    { key: 1, iconComponent: <IconStar className={css.iconStarFilled} filled />, textId: 'EditListingPhotosPanel.information1' },
    { key: 2, iconComponent: <IconStar className={css.icon} />, textId: 'EditListingPhotosPanel.information2' },
    { key: 3, iconComponent: <IconArrow className={css.icon} direction="top" />, textId: 'EditListingPhotosPanel.information3' },
    { key: 4, iconComponent: <IconArrow className={css.icon} direction="bottom" />, textId: 'EditListingPhotosPanel.information4' },
    { key: 5, iconComponent: <IconTrash className={css.icon} />, textId: 'EditListingPhotosPanel.information5' },
  ];
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <p className={css.information}>{informationText}</p>
      <ul className={css.list}>
        {map((item) => {
          const text = intl.formatMessage({ id: item.textId });
          return (
            <li key={item.key} className={css.listItem}>
              {item.iconComponent}
              <span className={css.listItemText}>{text}</span>
            </li>
          )
        }, listItems)}
      </ul>
    </div>
  )
}

Informations.defaultProps = {
  className: null,
};

Informations.propTypes = {
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Informations);