import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { pathOr } from 'ramda';

import { ListingLink } from '../../components';
import { EditListingInventoryForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';

import css from './EditListingInventoryPanel.module.css';

const EditListingInventoryPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;
  const handlePanelSubmit = (values) => {
    const { objects } = values;
    const inventoryFormatted = {
      inventory: {
        objects,
        updatedAt: new Date().toISOString(),
      },
    };
    const updatedValues = {
      privateData: inventoryFormatted,
    };

    onSubmit(updatedValues);
  };
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const inventory = pathOr({}, ['attributes', 'privateData', 'inventory'], currentListing);
  const panelTitle = (
    <FormattedMessage
      id="EditListingInventoryPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  );
  const form = (
    <EditListingInventoryForm
      className={css.form}
      initialValues={inventory}
      onSubmit={handlePanelSubmit}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      updated={panelUpdated}
      updateError={errors.updateListingError}
      updateInProgress={updateInProgress}
    />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingInventoryPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingInventoryPanel.propTypes = {
  className: string,
  rootClassName: string,
  fetchInProgress: bool,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingInventoryPanel;
