import React, { Component } from 'react';
import { string } from 'prop-types';

import css from './styles.css';
import rightChevron from './right-chevron.png';
import downChevron from './down-arrow.png';
import classNames from 'classnames';

export class CollapseComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    }
  }

  getChevron = () => {
    const { isOpen } = this.state;
    const chevron = isOpen ? downChevron : rightChevron;

    return <img src={chevron} alt="chevron" className={css.chevronIcon}/>;
  };

  getHeader = () => {
    const { header } = this.props;
    const { isOpen } = this.state;

    return (
      <span className={css.header} onClick={this.handleOpeningCollapse}>
        <span className={isOpen ? css.headerText : null}>
          {header}
        </span>
        <span className={css.chevron}>
          {this.getChevron()}
          </span>
      </span>
    );
  };

  getContent = () => {
    const { content } = this.props;
    if (!this.state.isOpen) return null;

    return (
      <div className={css.content}>
        {content}
      </div>
    )
  };

  handleOpeningCollapse = () => {
    this.setState(prev => ({ isOpen: !prev.isOpen }));
  };

  render() {
    const { className } = this.props;
    const classes = classNames(className);

    return (
      <div className={classes}>
        {this.getHeader()}
        {this.getContent()}
        <hr className={css.separator}/>
      </div>
    );
  }
}

CollapseComponent.propTypes = {
  content: string.isRequired,
  header: string.isRequired,
};

export default CollapseComponent;
