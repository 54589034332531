import { mapObjIndexed, path, pathOr, reduce } from 'ramda';

import { addTransactionTracking } from '../../util/transactionTrackerHelpers';

export const getNotAllowedPolicies = (listingPublicData) => {
  const policyAlcohol = pathOr(true, ['policyAlcohol'], listingPublicData);
  const policyEvent = pathOr(true, ['policyEvent'], listingPublicData);
  const policyMusic = pathOr(true, ['policyMusic'], listingPublicData);
  const policyPets = pathOr(true, ['policyPets'], listingPublicData);
  const policySmoking = pathOr(true, ['policySmoking'], listingPublicData);
  
  return {
    ...!policyAlcohol && { policyAlcohol: policyAlcohol },
    ...!policyEvent && { policyEvent: policyEvent },
    ...!policyMusic && { policyMusic: policyMusic },
    ...!policyPets && { policyPets: policyPets },
    ...!policySmoking && { policySmoking: policySmoking },
  };
};

const getPolicyText = (intl, policy) => {
  switch (policy) {
    case 'policyAlcohol':
      return intl.formatMessage({ id: 'ContactPage.InformationPanel.noAlcohol' });
    case 'policyEvent':
      return intl.formatMessage({ id: 'ContactPage.InformationPanel.noEvent' });
    case 'policyMusic':
      return intl.formatMessage({ id: 'ContactPage.InformationPanel.noMusic' });
    case 'policyPets':
      return intl.formatMessage({ id: 'ContactPage.InformationPanel.noPets' });
    case 'policySmoking':
      return intl.formatMessage({ id: 'ContactPage.InformationPanel.noSmoking' });
    
    default:
      return '';
  }
}

export const getNotAllowedText = (intl, notAllowedPolicies) => reduce(
  (acc, value) => `${acc}${value}. `,
  '',
  Object.values(mapObjIndexed((allowed, policy) => getPolicyText(intl, policy), notAllowedPolicies))
);

export const handleTrackingTransaction = (transactionId, currentUser, numberOfParticipants, bookingDate, providerId) => {
  const transactionTracking = {
    transactionId,
    customerId: path(['id', 'uuid'], currentUser),
    providerId,
    bookingDate: bookingDate,
    followedBy: '',
    numberOfParticipants,
  };
  addTransactionTracking({ ...transactionTracking, state: 'message' });
};
