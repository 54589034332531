import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconSecurity.module.css';

const IconSecurity = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <g id="Processor_security" data-name="Processor security">
        <path d="m27 11h2v2h-2z" />
        <path d="m53 35h2v2h-2z" />
        <path d="m35 51h2v2h-2z" />
        <path d="m27 54h2v2h-2z" />
        <path d="m11 31h2v2h-2z" />
        <path d="m35 11h2v2h-2z" />
        <path d="m51 27h2v2h-2z" />
        <path d="m46 17h2v2h-2z" />
        <path d="m16 17h2v2h-2z" />
        <path d="m46 45h2v2h-2z" />
        <path d="m16 45h2v2h-2z" />
        <path d="m31.882 31a2.994 2.994 0 0 0 -.882 5.822v2.178h2v-2.185a2.993 2.993 0 0 0 -1.118-5.815zm1.118 2.961a1 1 0 0 1 -.96 1.039 1 1 0 0 1 -.719-.266 1 1 0 0 1 .639-1.734h.04a1 1 0 0 1 1 .96z" />
        <path d="m37 28.184v-1.184a5 5 0 0 0 -10 0v1.184a3 3 0 0 0 -2 2.816v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-8a3 3 0 0 0 -2-2.816zm-8-1.184a3 3 0 0 1 6 0v1h-6zm8 12a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1-1v-8a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1z" />
        <path d="m59 41a2.951 2.951 0 0 0 -1.285.3l-2.3-2.3h-9.415v-2h5v-2h-5v-2h5.236l5.687-2.844a2.994 2.994 0 1 0 -.923-2.156 2.9 2.9 0 0 0 .037.363l-5.273 2.637h-4.764v-2h3v-2h-3v-2h4.414l3.3-3.3a2.951 2.951 0 0 0 1.286.3 3 3 0 1 0 -3-3 2.951 2.951 0 0 0 .3 1.285l-2.714 2.715h-3.586a5.006 5.006 0 0 0 -5-5v-2.586l5.715-5.714a2.951 2.951 0 0 0 1.285.3 3 3 0 1 0 -3-3 2.951 2.951 0 0 0 .3 1.285l-6.3 6.3v3.415h-2v-3h-2v3h-2v-7.184a3 3 0 1 0 -2 0v7.184h-2v-3h-2v3h-2v-7.414l-4.3-4.3a2.951 2.951 0 0 0 .3-1.286 3 3 0 1 0 -3 3 2.951 2.951 0 0 0 1.285-.3l3.715 3.714v6.586a5.006 5.006 0 0 0 -5 5h-3.586l-2.714-2.715a2.951 2.951 0 0 0 .3-1.285 3 3 0 1 0 -3 3 2.951 2.951 0 0 0 1.285-.3l3.3 3.3h4.415v2h-6.362l-5.238 4.364a2.953 2.953 0 0 0 -1.4-.364 3 3 0 1 0 3 3 2.972 2.972 0 0 0 -.237-1.168l4.599-3.832h5.638v2h-3v2h3v2h-9v2h9v2h-5.535l-4.03 6.044a2.968 2.968 0 0 0 -.435-.044 3.028 3.028 0 1 0 2.21.988l3.325-4.988h4.465a5.006 5.006 0 0 0 5 5v6.586l-3.715 3.714a2.951 2.951 0 0 0 -1.285-.3 3 3 0 1 0 3 3 2.951 2.951 0 0 0 -.3-1.285l4.3-4.3v-7.415h2v6h2v-6h2v8.554l6.074 3.8a3.044 3.044 0 1 0 1.069-1.691l-5.143-3.217v-7.446h2v3h2v-3h2v8h7.184a3 3 0 1 0 0-2h-5.184v-6a5.006 5.006 0 0 0 5-5h8.586l1.714 1.715a2.951 2.951 0 0 0 -.3 1.285 3 3 0 1 0 3-3zm0-14a1 1 0 1 1 -1 1 1 1 0 0 1 1-1zm-4-9a1 1 0 1 1 -1 1 1 1 0 0 1 1-1zm-7-12a1 1 0 1 1 -1 1 1 1 0 0 1 1-1zm-16 1a1 1 0 1 1 -1 1 1 1 0 0 1 1-1zm-15-2a1 1 0 1 1 1 1 1 1 0 0 1 -1-1zm-9 14a1 1 0 1 1 1 1 1 1 0 0 1 -1-1zm-3 16a1 1 0 1 1 1-1 1 1 0 0 1 -1 1zm3 14a1 1 0 1 1 1-1 1 1 0 0 1 -1 1zm10 11a1 1 0 1 1 1-1 1 1 0 0 1 -1 1zm22-2a1 1 0 1 1 -1 1 1 1 0 0 1 1-1zm9-6a1 1 0 1 1 -1 1 1 1 0 0 1 1-1zm-5-11a3 3 0 0 1 -3 3h-18a3 3 0 0 1 -3-3v-18a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3zm15 4a1 1 0 1 1 1-1 1 1 0 0 1 -1 1z" />
      </g>
    </svg>
  );
};

IconSecurity.defaultProps = {
  rootClassName: null,
  className: null,
};

IconSecurity.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSecurity;
