import React, { Fragment } from 'react';
import { array, bool, func, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { path } from 'ramda';

import { propTypes } from '../../../util/types';

import Calendar from './Calendar/Calendar';
import CalendarLegend from './CalendarLegend/CalendarLegend';
import ListingSelector from './ListingSelector/ListingSelector';
import PlanManager from './PlanManager/PlanManager';
import Tutorial from './Tutorial/Tutorial';
import css from './MainContent.module.css';

const MainContent = (props) => {
  const {
    className,
    availabilityExceptions,
    currentListing,
    handleSelectedDates,
    intl,
    isMobile,
    onFetchAvailabilityExceptions,
    onManageDisableScrolling,
    selectedDates,
    transactions
  } = props;
  const listingId = path(['id'], currentListing);
  const availabilityPlan = path(['attributes', 'availabilityPlan'], currentListing);
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <div className={css.container}>
        <ListingSelector
          className={css.listingSelector}
          intl={intl}
          currentListing={currentListing}
        />
        <div className={css.actionWrapper}>
          <Tutorial
            className={css.tutorial}
            intl={intl}
            onManageDisableScrolling={onManageDisableScrolling}
          />
          {!!availabilityPlan && (
            <PlanManager
              className={css.planManager}
              availabilityPlan={availabilityPlan}
              intl={intl}
              isMobile={isMobile}
              listingId={listingId}
              onManageDisableScrolling={onManageDisableScrolling}
            />
          )}
        </div>
      </div>
      {!!availabilityPlan && (
        <Fragment>
          <Calendar
            className={css.calendar}
            availabilityExceptions={availabilityExceptions}
            availabilityPlan={availabilityPlan}
            dates={selectedDates}
            intl={intl}
            listingId={listingId}
            onChange={handleSelectedDates}
            onFetchAvailabilityExceptions={onFetchAvailabilityExceptions}
            transactions={transactions}
          />
          <CalendarLegend className={css.calendarLegend} intl={intl} />
        </Fragment>
      )}
    </div>
  )
}

MainContent.defaultProps = {
  className: null,
  isMobile: false,
};

MainContent.propTypes = {
  className: string,
  availabilityExceptions: array.isRequired,
  currentListing: propTypes.ownListing,
  handleSelectedDates: func.isRequired,
  intl: intlShape.isRequired,
  isMobile: bool,
  onFetchAvailabilityExceptions: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  selectedDates: array.isRequired,
  transactions: array.isRequired
};

export default MainContent;
