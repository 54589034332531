import React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { path } from 'ramda';

import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isServer, isCookieAccepted } from '../../util/cookies';
import config from '../../config';

const CriteoFinishCreateNewProductComponent = props => {
  if (isServer()) return null;
  if (!isCookieAccepted('swimmy_criteoAccepted')) return null;
  
  const {
    currentUser,
    listingId,
  } = props;
  const user = ensureCurrentUser(currentUser);
  const currentEmail = user.attributes.email;
  if (!currentEmail || !listingId) return null;

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="https://static.criteo.net/js/ld/ld.js" async="true"/>
        <script type="text/javascript">
          {`
            window.criteo_q = window.criteo_q || [];
            var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
            //function to return random number equal or greater to min and less than the max
            function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
          }
            window.criteo_q.push(
            { event: "setAccount", account: ${config.criteoPartnerId} },
            { event: "setEmail", email: "${currentEmail}" },
            { event: "setSiteType", type: deviceType},
            { event: "trackTransaction" , id: "${listingId}", user_segment : 1,
              item: [
            { id: getRandomInt(17,25)  , price: 1, quantity: 1}
              ]});
         `}
        </script>
      </Helmet>
    </div>
  );
};

CriteoFinishCreateNewProductComponent.defaultProps = { listingId: null };

const mapStateToProps = state => ({
  currentUser: path(['user', 'currentUser'], state),
});

const CriteoFinishCreateNewProduct = connect(mapStateToProps)(CriteoFinishCreateNewProductComponent);

export default CriteoFinishCreateNewProduct;
