import React, { useEffect, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { path, pathOr } from 'ramda';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { CriteoNewProduct, ListingLink } from '..';
import { EditListingPresentationForm } from '../../forms';

import css from './EditListingPresentationPanel.css';

const EditListingPresentationPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;
  useEffect(() => {
    if (!listing) return;

    setInitialValues(getInitialValues());
  }, [listing]);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const getInitialValues = () => {
    const { geolocation, privateData, publicData } = currentListing.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent = path(['location', 'address'], privateData) && !!geolocation;
    const location = pathOr({}, ['location'], privateData);
    const { address, city, addressDetails } = location;
    const { maxCapacityToGuest } = publicData;

    return {
      maxCapacityToGuest: maxCapacityToGuest ? maxCapacityToGuest : 1,
      location: locationFieldsPresent
        ? {
          search: address,
          selectedPlace: { address, origin: geolocation, city, addressDetails },
        }
        : null,
    };
  };
  const [initialValues, setInitialValues] = useState(getInitialValues());
  const handleSubmit = (values) => {
    const { location, maxCapacityToGuest } = values;
    const {
      selectedPlace: { address, origin, city, addressDetails },
    } = location;
    const publicData = {
      maxCapacityToGuest: parseInt(maxCapacityToGuest, 10),
      minCapacityToGuest: 1,
      location: { city },
    };
    const privateData = {
      location: {
        address,
        addressDetails,
        city,
      },
    };
    const updateValues = {
      geolocation: origin,
      privateData,
      publicData,
    };
    setInitialValues({ ...initialValues, location: { search: address, selectedPlace: { address, origin, city, addressDetails } } });
    onSubmit(updateValues);
  };

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPresentationPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPresentationPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <CriteoNewProduct/>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingPresentationForm
        className={css.form}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingPresentationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPresentationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPresentationPanel;
