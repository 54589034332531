import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';

import { DATE } from '../../../../util/search';
import { isSeason } from '../../../../util/dates';
import {
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '../../../../components/ModalSwimmy';
import {
  IconArrowHead,
  IconClose,
  IconSearch,
  PrimaryButton,
} from '../../../../components';

import DatePicker from './components/DatePicker/DatePicker';
import SlotSelector from './components/SlotSelector/SlotSelector';
import css from './DateView.module.css';

const DateView = (props) => {
  const {
    className,
    formProps,
    formValues,
    handleView,
    intl,
    isSearchPage,
  } = props;
  const [erase, setErase] = useState(0);
  const selectedDay = formValues.bookingDate;
  const handleErase = () => {
    if (!selectedDay) return;

    if (formValues.timeSlot) {
      formProps.change('timeSlot', undefined);
      formProps.resetFieldState('timeSlot');
    };
    setErase(prev => prev + 1);
    formProps.change(DATE, undefined);
    formProps.resetFieldState(DATE);
  };
  const handleNext = () => handleView('ParticipantsView');
  const handlePrevious = () => {
    handleErase();
    handleView(isSeason() ? 'LocationView' : 'TypeView');
  };
  const initialDate = selectedDay && moment(selectedDay);
  const headingText = intl.formatMessage({ id: 'Search.DateView.heading' });
  const eraseText = intl.formatMessage({ id: 'Marketplace.erase' });
  const passText = intl.formatMessage({ id: 'Marketplace.pass' });
  const nextText = intl.formatMessage({ id: isSearchPage ? 'Marketplace.search' : 'Marketplace.next' });
  const classes = classNames(css.modalContent, className);
  const secondaryButtonClasses = classNames(css.secondaryButton, {
    [css.disabled]: !selectedDay,
  });

  return (
    <ModalContent className={classes}>
      <ModalHeader className={css.modalHeader}>
        <button
          className={css.backButton}
          type="button"
          onClick={isSearchPage ? () => handleView() : handlePrevious}
        >
          {isSearchPage ? (
            <IconClose />
          ) :  (
            <IconArrowHead className={css.iconArrow} direction="left" />
          )}
        </button>
        <span className={css.heading}>{headingText}</span>
      </ModalHeader>
      <ModalBody className={css.modalBody}>
        <Field
          name={DATE}
          render={({ input }) => (
            <DatePicker
              erase={erase}
              initialDate={initialDate}
              input={input}
            />
          )}
        />
        {selectedDay && <SlotSelector className={css.slotSelector} finalFormApi={formProps} formValues={formValues} />}
      </ModalBody>
      <ModalFooter className={css.modalFooter}>
        {selectedDay || isSearchPage ? (
          <button className={secondaryButtonClasses} type="button" onClick={handleErase} disabled={!selectedDay}>
            {eraseText}
          </button>
        ) : (
          <button className={css.secondaryButton} type="button" onClick={handleNext}>
            {passText}
          </button>
        )}
        <PrimaryButton
          className={css.nextButton}
          type={isSearchPage ? "submit" : "button"}
          onClick={isSearchPage ? formProps.submit : handleNext}
          disabled={isSearchPage ? false : !selectedDay}
        >
          {isSearchPage && <IconSearch className={css.iconSearch} />}
          <span className={css.buttonText}>{nextText}</span>
        </PrimaryButton>
      </ModalFooter>
    </ModalContent>
  )
}

DateView.defaultProps = {
  className: null,
  formProps: {},
  formValues: {},
};

DateView.propTypes = {
  className: string,
  formProps: object,
  formValues: object,
  handleView: func.isRequired,
  intl: intlShape.isRequired,
  isSearchPage: bool.isRequired,
};

export default injectIntl(DateView);
