import React from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl } from '../../../util/reactIntl';
import classNames from 'classnames';

import config from '../../../config';
import { propTypes } from '../../../util/types';
import * as validators from '../../../util/validators';
import {
  convertUnitToSubUnit,
  convertMoneyToNumber,
  getCurrencySymbol,
  unitDivisor,
} from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { stringValuesToNumbers } from '../../../util/data';
import {
  Form,
  FieldStepperEditable,
  PrimaryButton,
} from '../../../components';

import css from './PricingForm.module.css';

const { Money } = sdkTypes;

export const PricingForm = props => {
  const {
    className,
    fetchErrors,
    intl,
    listing,
    onSubmit,
    saveActionMsg,
    updated,
    updateInProgress,
  } = props;
  const { price } = listing.attributes;

  const unitDivisorValue = unitDivisor(config.currency);
  const handleSubmit = (values) => {
    const valuesAsNumber = stringValuesToNumbers(values);
    const {
      price,
    } = valuesAsNumber;
    const priceWithSubUnit = convertUnitToSubUnit(price, unitDivisorValue);
    const updatedValues = {
      price: new Money(priceWithSubUnit, config.currency),
      publicData: {
        minimumHoursPerBooking: 1,
        pricePerExtraGuest: 100,
        pricingBaseNumberOfPeople: 5
      }
    };
    onSubmit(updatedValues);
  };

  const currencySymbol = getCurrencySymbol(config.currencyConfig);
  const minPriceValue = config.listingMinimumPriceSubUnits / unitDivisorValue;
  const { publishListingError, updateListingError, showListingsError } = fetchErrors || {};
  const hasError = publishListingError || updateListingError || showListingsError;
  const initialValues = {
    price: price ? `${convertMoneyToNumber(price).toString()}${currencySymbol}` : undefined,
  };
  const priceLabel = intl.formatMessage({ id: 'NewListingPricingPanel.PricingForm.priceLabel' });
  const minPriceErrorText = intl.formatMessage(
    { id: 'NewListingPricingPanel.PricingForm.minPriceError' },
    { amount: minPriceValue, currency: currencySymbol },
  );
  const priceValidators = validators.minNumberAsString(2, minPriceErrorText);
  const errorMessage = updateListingError
    ? intl.formatMessage({ id: 'NewListingPricingPanel.PricingForm.updateFailed' })
    : publishListingError
    ? intl.formatMessage({ id: 'Marketplace.publishListingFailed' })
    : intl.formatMessage({ id: 'Marketplace.showListingFailed' });
  const classes = classNames(css.root, className);

  return (
    <FinalForm
      {...props}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={formRenderProps => {
        const {
          disabled,
          ready,
          handleSubmit,
          invalid,
          pristine,
        } = formRenderProps;
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {hasError && <p className={css.error}>{errorMessage}</p>}
            <FieldStepperEditable
              name="price"
              initialValue={initialValues.price}
              label={priceLabel}
              min={minPriceValue}
              placeholder={`00${currencySymbol}`}
              unit={currencySymbol}
              validate={priceValidators}
              withButtons
            />
            <div className={css.buttonContainer}>
              <PrimaryButton
                className={css.submitButton}
                type='submit'
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  )
};

PricingForm.defaultProps = {
  fetchErrors: null,
  listing: null,
};

PricingForm.propTypes = {
  disabled: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  intl: intlShape.isRequired,
  listing: object,
  onSubmit: func.isRequired,
  ready: bool.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
};

export default injectIntl(PricingForm);
