import React from 'react';
import { Helmet } from 'react-helmet-async';

import { isServer, isCookieAccepted } from '../../util/cookies';

const AffilaeTrackingTag = () => {
  if (isServer()) return null;
  if (!isCookieAccepted('swimmy_affilaeAccepted')) return null;

  return (
    <div>
      <Helmet>
        <script type="text/javascript">
          {`
              var _ae = {
                'pid': '5f9a931a5504a53dbcfa7e15',
              };
  
              (function() {
                var element = document.createElement('script'); element.type = 'text/javascript'; element.async = true;
                element.src = '//static.affilae.com/ae-v3.5.js';
                var scr = document.getElementsByTagName('script')[0]; scr.parentNode.insertBefore(element, scr);
              })();
          `}
        </script>
      </Helmet>
    </div>
  )
};


export default AffilaeTrackingTag;
