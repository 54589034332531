import React, { Fragment, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { map } from 'ramda';

import { moment, utcTimeZone } from '../../../../util/dates';
import { hoursArray } from '../../../../util/time';
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalSwimmy
} from '../../../../components/ModalSwimmy';
import { PrimaryButton } from '../../../../components';

import { requestUpdateAvailabilityPlan } from '../../CalendarPage.duck';
import {
  formatPlanInitialValues,
  formatValuesToAvailabilityPlanEntries
} from './PlanManager.helpers';
import DayGroup from './DayGroup/DayGroup';
import css from './PlanManager.module.css';
import InfoContent from './InfoContent/InfoContent';

const PlanManager = (props) => {
  const {
    className,
    availabilityPlan,
    intl,
    isMobile,
    listingId,
    onManageDisableScrolling,
    onUpdateAvailabilityPlan,
    updateAvailabilityPlanInProgress
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [mondayValues, setMondayValues] = useState(formatPlanInitialValues('mon', availabilityPlan));
  const [tuesdayValues, setTuesdayValues] = useState(formatPlanInitialValues('tue', availabilityPlan));
  const [wednesdayValues, setWednesdayValues] = useState(formatPlanInitialValues('wed', availabilityPlan));
  const [thursdayValues, setThursdayValues] = useState(formatPlanInitialValues('thu', availabilityPlan));
  const [fridayValues, setFridayValues] = useState(formatPlanInitialValues('fri', availabilityPlan));
  const [saturdayValues, setSaturdayValues] = useState(formatPlanInitialValues('sat', availabilityPlan));
  const [sundayValues, setSundayValues] = useState(formatPlanInitialValues('sun', availabilityPlan));
  const handleSubmit = () => {
    const availabilityPlanUpdated = {
      type: 'availability-plan/time',
      timezone: utcTimeZone,
      entries: [
        ...formatValuesToAvailabilityPlanEntries('mon', mondayValues),
        ...formatValuesToAvailabilityPlanEntries('tue', tuesdayValues),
        ...formatValuesToAvailabilityPlanEntries('wed', wednesdayValues),
        ...formatValuesToAvailabilityPlanEntries('thu', thursdayValues),
        ...formatValuesToAvailabilityPlanEntries('fri', fridayValues),
        ...formatValuesToAvailabilityPlanEntries('sat', saturdayValues),
        ...formatValuesToAvailabilityPlanEntries('sun', sundayValues),
      ],
    }
    
    onUpdateAvailabilityPlan({
      id: listingId,
      availabilityPlan: availabilityPlanUpdated
    })
  }
  const openButtonText = intl.formatMessage({ id: 'CalendarPage.PlanManager.openButton' });
  const headingText = intl.formatMessage({ id: 'CalendarPage.PlanManager.heading' });
  const submitText = intl.formatMessage({ id: 'CalendarPage.PlanManager.submit' });
  const classes = classNames(css.root, className);

  return (
    <Fragment>
      <PrimaryButton className={classes} onClick={() => setIsOpen(true)}>
        <span>{openButtonText}</span>
      </PrimaryButton>
      <ModalSwimmy
        id="planManager"
        animationType="fade"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ModalOverlay />
        <ModalContent
          className={css.modalContent}
          animationType="slideBottom"
          show={isOpen}
        >
          <ModalHeader className={css.modalHeader}>
            <span className={css.heading}>{headingText}</span>
          </ModalHeader>
          <ModalCloseButton className={css.closeButton} handleClose={() => setIsOpen(false)} />
          <ModalBody className={css.modalBody}>
            <InfoContent className={css.infoContent} intl={intl} />
            <div className={css.planWrapper}>
              <div className={css.labelContainer}>
                {map(time => {
                  if (time.key < 9 || time.key > 24) return null;

                  return (
                    <span key={time.key} className={css.timeLabel}>{`${time.text} -`}</span>
                  )
                }, hoursArray)}
              </div>
              <div className={css.planContainer}>
                <DayGroup
                  className={css.dayGroup}
                  dayString={{ long: moment().weekday(0).format('dddd'), short: moment().weekday(0).format('dd') }}
                  isMobile={isMobile}
                  setValues={setMondayValues}
                  values={mondayValues}
                />
                <DayGroup
                  className={css.dayGroup}
                  dayString={{ long: moment().weekday(1).format('dddd'), short: moment().weekday(1).format('dd') }}
                  isMobile={isMobile}
                  setValues={setTuesdayValues}
                  values={tuesdayValues}
                />
                <DayGroup
                  className={css.dayGroup}
                  dayString={{ long: moment().weekday(2).format('dddd'), short: moment().weekday(2).format('dd') }}
                  isMobile={isMobile}
                  setValues={setWednesdayValues}
                  values={wednesdayValues}
                />
                <DayGroup
                  className={css.dayGroup}
                  dayString={{ long: moment().weekday(3).format('dddd'), short: moment().weekday(3).format('dd') }}
                  isMobile={isMobile}
                  setValues={setThursdayValues}
                  values={thursdayValues}
                />
                <DayGroup
                  className={css.dayGroup}
                  dayString={{ long: moment().weekday(4).format('dddd'), short: moment().weekday(4).format('dd') }}
                  isMobile={isMobile}
                  setValues={setFridayValues}
                  values={fridayValues}
                />
                <DayGroup
                  className={css.dayGroup}
                  dayString={{ long: moment().weekday(5).format('dddd'), short: moment().weekday(5).format('dd') }}
                  isMobile={isMobile}
                  setValues={setSaturdayValues}
                  values={saturdayValues}
                />
                <DayGroup
                  className={css.dayGroup}
                  dayString={{ long: moment().weekday(6).format('dddd'), short: moment().weekday(6).format('dd') }}
                  isMobile={isMobile}
                  setValues={setSundayValues}
                  values={sundayValues}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter className={css.modalFooter}>
            <PrimaryButton
              className={css.submitButton}
              onClick={handleSubmit}
              inProgress={updateAvailabilityPlanInProgress}
              disabled={updateAvailabilityPlanInProgress}
            >
              {submitText}
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </ModalSwimmy>
    </Fragment>
  )
}

PlanManager.defaultProps = {
  className: null,
  isMobile: false,
};

PlanManager.propTypes = {
  className: string,
  availabilityPlan: object.isRequired,
  intl: intlShape.isRequired,
  isMobile: bool,
  listingId: object.isRequired,
  onManageDisableScrolling: func.isRequired,
  onUpdateAvailabilityPlan: func.isRequired,
  updateAvailabilityPlanInProgress: bool.isRequired
};

const mapStateToProps = state => {
  const { updateAvailabilityPlanInProgress } = state.CalendarPage;

  return {
    updateAvailabilityPlanInProgress
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateAvailabilityPlan: (params) => dispatch(requestUpdateAvailabilityPlan(params)),
});

export default compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(PlanManager);
