import React from 'react';
import { string } from 'prop-types';
import { includes, pathOr } from 'ramda';
import classNames from 'classnames';

import {
  FavoritesButton,
  IconReviewStar,
  ShareModal,
  SuperhostBadge,
  TextButton,
} from '../../../components';
import { IconShare } from '../../../components/Icons';

import css from './SectionActions.module.css';

const SectionActions = props => {
  const {
    className,
    wrapperClassName,
    currentUser,
    listingId,
    shareModalOpen,
    onShareModalClose,
    handleShareClick,
    rating,
    totalReviews,
    isHostedBySuperHost,
    onManageDisableScrolling,
    intl,
  } = props;
  const myFavorites = pathOr([], ['attributes', 'profile', 'protectedData', 'myFavorites'], currentUser);
  const isFavorite = includes(listingId.uuid, myFavorites);
  const favoriteText = intl.formatMessage({ id: isFavorite ? 'ListingPage.removeFromMyFavoritesButton' : 'ListingPage.addToMyFavoriteButton' })
  const favoritesButtonClasses = classNames(isFavorite ? css.removeFavorite : css.addFavorite);
  const shareText = intl.formatMessage({ id: 'ListingPage.shareButton' });
  const classes = classNames(css.root, className);
  const wrapperClasses = classNames(css.wrapper, wrapperClassName);

  return (
    <div className={classes}>
      <div className={wrapperClasses}>
        {totalReviews > 2 ? (
          <div className={css.reviewsInfoContainerDesktop}>
            <IconReviewStar className={css.iconReviewStar} isFilled />
            <span className={css.rating}>{rating}</span>
            <span className={css.totalReviews}>({totalReviews})</span>
          </div>
        ) : null}
        {isHostedBySuperHost && <SuperhostBadge className={css.superhostBadge} />}
        <TextButton className={css.shareButton} hideIconOnMobile={true} onClick={handleShareClick}>
          <IconShare />
          <span>{shareText}</span>
        </TextButton>
        <FavoritesButton
          className={favoritesButtonClasses}
          listingId={listingId.uuid}
          text={favoriteText}
        />
        <ShareModal
          shareModalOpen={shareModalOpen}
          onShareModalClose={onShareModalClose}
          onManageDisableScrolling={onManageDisableScrolling}
        />
      </div>
    </div>
  );
};

SectionActions.defaultProps = {
  className: null,
  wrapperClassName: null,
}

SectionActions.propTypes = {
  className: string,
  wrapperClassName: string,
}

export default SectionActions;
