import React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { path, pathOr } from 'ramda';

import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { convertMoneyToNumber } from '../../util/currency';
import { isServer, isCookieAccepted } from '../../util/cookies';
import config from '../../config';

const CriteoBasketCartComponent = props => {
  if (isServer()) return null;
  if (!isCookieAccepted('swimmy_criteoAccepted')) return null;

  const { currentUser, currentListing, currentTransaction } = props;
  const user = ensureCurrentUser(currentUser);
  const currentEmail = pathOr('', ['attributes', 'email'], user);
  const listingId = path(['id', 'uuid'], currentListing);
  const listingPrice = convertMoneyToNumber(path(['attributes', 'payinTotal'], currentTransaction));

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="https://static.criteo.net/js/ld/ld.js" async="true"/>
        <script type="text/javascript">
          {`
            window.criteo_q = window.criteo_q || [];
            var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
            window.criteo_q.push(
              { event: "setAccount", account: ${config.criteoPartnerId}},
              { event: "setEmail", email: "${currentEmail}" },
              { event: "setSiteType", type: deviceType},
              { event: "viewBasket", item: [ {id: "${listingId}", price: ${listingPrice}, quantity:1} ]}
            );
          `}
        </script>
      </Helmet>
    </div>
  );
};

CriteoBasketCartComponent.defaultProps = { currentUser: null };

CriteoBasketCartComponent.propTypes = {
  currentUser: propTypes.currentUser
};

const mapStateToProps = state => ({
  currentUser: pathOr({}, ['user', 'currentUser'], state),
});

const CriteoBasketCart = connect(mapStateToProps)(CriteoBasketCartComponent);

export default CriteoBasketCart;
