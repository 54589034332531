import React from 'react';
import { path } from 'ramda';

import { required } from '../../util/validators';
import { FieldSelect } from '../index';
import config from '../../config';

const Index = props => {
  const { name, id, intl, ...rest } = props;
  const fields = path(['custom', 'howDidYouKnowUsOptions'], config);
  const label = intl.formatMessage({
    id: 'HowDidYouKnowUsSelectField.howDidYouKnowUs.label',
  });
  const placeholder = intl.formatMessage({
    id: 'HowDidYouKnowUsSelectField.howDidYouKnowUs.placeholder',
  });
  const howDidYouKnowUsRequired = required(
    intl.formatMessage({
      id: 'HowDidYouKnowUsSelectField.howDidYouKnowUs.required',
    })
  );

  const selectFieldProps = {
    name,
    id,
    label,
    validate: howDidYouKnowUsRequired,
    ...rest,
  };
  return fields ? (
    <FieldSelect {...selectFieldProps}>
      <option value="">{placeholder}</option>
      {fields.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default Index;
