import { length, map, pathOr, reduce } from 'ramda';

export const MIN_REVIEWS_TO_AVERAGE = 3;

export const canShowAverageRating = (reviews) => (
  length(reviews) >= MIN_REVIEWS_TO_AVERAGE
);

export const getAverageRating = (reviews) => {
  if (length(reviews) < 2) return null;
  
  const ratingsArray = map(review => pathOr(5, ['attributes', 'rating'], review), reviews);
  const averageRating = reduce((a, b) => a + b, 0, ratingsArray) / length(ratingsArray);

  return Math.round(averageRating * 100) / 100;
};
