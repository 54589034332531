import React, { Component } from 'react';
import { func, number, object, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import { map, path } from 'ramda';

import { BOOKING_DATE, END_TIME, START_TIME } from '../../constants/booking';
import { LEFT, RIGHT } from '../../constants/ui';
import {
  isInRange,
  isDayMomentInsideRange,
  resetToStartOfDay,
  timeOfDayFromLocalToTimeZone,
  dateIsAfter,
  findNextBoundary,
  localizeAndFormatTime,
  monthIdStringInTimeZone,
  getMonthStartInTimeZone,
  moment,
  nextMonthFn,
  prevMonthFn,
} from '../../util/dates';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import { IconArrowNavigation } from '../../components/Icons';
import { FieldDateInput, FieldSelect } from '../../components';

import {
  endOfRange,
  getAllTimeValues,
  getAvailableEndTimes,
  getAvailableStartTimes,
  getMonthlyTimeSlots,
  getTimeSlots,
} from './FieldDateAndTimeInput.helpers';
import css from './FieldDateAndTimeInput.module.css';

const TODAY = new Date();

const Nav = (props) => {
  const { direction } = props;
  const classes = classNames(css.navButton, {
    [css.previous]: direction === LEFT,
    [css.next]: direction === RIGHT,
  })

  return (
    <button className={classes}>
      <IconArrowNavigation className={css.iconArrow} direction={direction} />
    </button>
  )
}

const Next = props => {
  const { currentMonth, timeZone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return dateIsAfter(nextMonthDate, endOfRange(TODAY, timeZone)) ? null : <Nav direction={RIGHT} />;
};
const Prev = props => {
  const { currentMonth, timeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? <Nav direction={LEFT} /> : null;
};

/////////////////////////////////////
// FieldDateAndTimeInput component //
/////////////////////////////////////
class FieldDateAndTimeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMonth: getMonthStartInTimeZone(path(['formValues', 'bookingDate'], props) ? new Date(path(['formValues', 'bookingDate'], props)) : TODAY, props.timeZone),
    };

    this.fetchMonthData = this.fetchMonthData.bind(this);
    this.onMonthClick = this.onMonthClick.bind(this);
    this.onBookingStartDateChange = this.onBookingStartDateChange.bind(this);
    this.onBookingStartTimeChange = this.onBookingStartTimeChange.bind(this);
    this.isOutsideRange = this.isOutsideRange.bind(this);
  }

  componentDidUpdate() {
    const { formValues } = this.props;
    const bookingDate = path(['bookingDate'], formValues);

    if (!bookingDate || path(['startTime'], formValues)) return;

    this.onBookingStartDateChange(bookingDate)
  }

  fetchMonthData(date) {
    const { listingId, timeZone, onFetchTimeSlots } = this.props;
    const validDate = moment(date).isBefore(TODAY) ? TODAY : date;
    const endOfRangeDate = endOfRange(TODAY, timeZone);

    // Don't fetch timeSlots for past months or too far in the future
    if (isInRange(date, TODAY, endOfRangeDate) || moment(date).isSame(TODAY, 'month')) {
      // Use "today", if the first day of given month is in the past
      const start = dateIsAfter(TODAY, validDate) ? TODAY : validDate;

      // Use endOfRangeDate, if the first day of the next month is too far in the future
      const nextMonthDate = nextMonthFn(validDate, timeZone);
      const end = dateIsAfter(nextMonthDate, endOfRangeDate)
        ? resetToStartOfDay(endOfRangeDate, timeZone, 0)
        : nextMonthDate;

      // Fetch time slots for given time range
      onFetchTimeSlots(listingId, start, end, timeZone);
    }
  }

  onMonthClick(monthFn) {
    const { onMonthChanged, timeZone } = this.props;

    this.setState(
      prevState => ({ currentMonth: monthFn(prevState.currentMonth, timeZone) }),
      () => {
        // Callback function after month has been updated.
        // react-dates component has next and previous months ready (but inivisible).
        // we try to populate those invisible months before user advances there.
        this.fetchMonthData(monthFn(this.state.currentMonth, timeZone));

        // If previous fetch for month data failed, try again.
        const monthId = monthIdStringInTimeZone(this.state.currentMonth, timeZone);
        const currentMonthData = this.props.monthlyTimeSlots[monthId];
        if (!currentMonthData || (currentMonthData && currentMonthData.fetchTimeSlotsError)) {
          this.fetchMonthData(this.state.currentMonth, timeZone);
        }

        // Call onMonthChanged function if it has been passed in among props.
        if (onMonthChanged) {
          onMonthChanged(monthId);
        }
      }
    );
  }

  onBookingStartDateChange = value => {
    const { monthlyTimeSlots, timeZone, intl, finalFormApi, minimumHoursPerBooking } = this.props;

    if (!value) {
      finalFormApi.batch(() => {
        finalFormApi.blur(BOOKING_DATE);
        finalFormApi.change(START_TIME, null);
        finalFormApi.change(END_TIME, null);
      });
      // Reset the currentMonth too if bookingDate is cleared
      this.setState({ currentMonth: getMonthStartInTimeZone(TODAY, timeZone) });

      return;
    }

    // This callback function (onBookingStartDateChange) is called from react-dates component.
    // It gets raw value as a param - browser's local time instead of time in listing's timezone.
    const startDate = timeOfDayFromLocalToTimeZone(value, timeZone);
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);

    const { startTime, endTime } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      undefined,
      undefined,
      minimumHoursPerBooking
    );

    finalFormApi.batch(() => {
      finalFormApi.blur(BOOKING_DATE);
      finalFormApi.change(START_TIME, startTime);
      finalFormApi.change(END_TIME, endTime);
    });
  };

  onBookingStartTimeChange = value => {
    const { monthlyTimeSlots, timeZone, intl, finalFormApi, formValues, minimumHoursPerBooking } = this.props;
    const startDate = formValues.bookingDate;
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, startDate || this.state.currentMonth, timeZone);
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);

    const { endTime } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      value,
      undefined,
      minimumHoursPerBooking
    );

    finalFormApi.batch(() => {
      finalFormApi.change(END_TIME, endTime);
    });
  };

  isOutsideRange(day, bookingDate, selectedTimeSlot, timeZone) {
    if (!selectedTimeSlot) {
      return true;
    }

    // 'day' is pointing to browser's local time-zone (react-dates gives these).
    // However, bookingDate and selectedTimeSlot refer to times in listing's timeZone.
    const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);
    // Given day (endDate) should be after the start of the day of selected booking start date.
    const startDate = resetToStartOfDay(bookingDate, timeZone);
    // 00:00 would return wrong day as the end date.
    // Removing 1 millisecond, solves the exclusivity issue.
    const inclusiveEnd = new Date(selectedTimeSlot.attributes.end.getTime() - 1);
    // Given day (endDate) should be before the "next" day of selected timeSlots end.
    const endDate = resetToStartOfDay(inclusiveEnd, timeZone, 1);
    return !(dateIsAfter(localizedDay, startDate) && dateIsAfter(endDate, localizedDay));
  }

  render() {
    const {
      rootClassName,
      className,
      activeField,
      finalFormApi,
      formId,
      formValues,
      monthlyTimeSlots,
      timeZone,
      intl,
      minimumHoursPerBooking,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const bookingDate = formValues.bookingDate ? formValues.bookingDate : null;
    const bookingStartTime = formValues.startTime ? formValues.startTime : null;

    const startTimeDisabled = !bookingDate;
    const endTimeDisabled = !bookingDate || !bookingStartTime;

    const timeSlotsOnSelectedMonth = getMonthlyTimeSlots(
      monthlyTimeSlots,
      this.state.currentMonth,
      timeZone
    );
    const timeSlotsOnSelectedMonthFromDate = getMonthlyTimeSlots(
      monthlyTimeSlots,
      bookingDate,
      timeZone
    );
    const timeSlotsOnSelectedDate = getTimeSlots(
      timeSlotsOnSelectedMonthFromDate,
      bookingDate,
      timeZone
    );

    const availableStartTimes = getAvailableStartTimes(
      intl,
      timeZone,
      bookingDate,
      timeSlotsOnSelectedDate,
      minimumHoursPerBooking
    );

    const firstAvailableStartTime =
      availableStartTimes.length > 0 && availableStartTimes[0] && availableStartTimes[0].timeOfDay
        ? availableStartTimes[0].timeOfDay
        : null;

    const { startTime, selectedTimeSlot } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      bookingDate,
      bookingStartTime || firstAvailableStartTime,
      undefined,
      minimumHoursPerBooking
    );

    const availableEndTimes = getAvailableEndTimes(
      intl,
      timeZone,
      bookingStartTime || startTime,
      bookingDate,
      selectedTimeSlot,
      minimumHoursPerBooking
    );

    const isDayBlocked = timeSlotsOnSelectedMonth
      ? day =>
          !timeSlotsOnSelectedMonth.find(timeSlot =>
            isDayMomentInsideRange(
              day,
              timeSlot.attributes.start,
              timeSlot.attributes.end,
              timeZone,
              minimumHoursPerBooking
            )
          )
      : () => false;

    const placeholderTime = localizeAndFormatTime(
      intl,
      timeZone,
      findNextBoundary(timeZone, TODAY)
    );

    const dateLabelText = intl.formatMessage({ id: 'FieldDateAndTimeInput.dateLabel' });
    const dateRequiredText = intl.formatMessage({ id: 'FieldDateAndTimeInput.dateRequired' });
    const startTimeLabel = intl.formatMessage({ id: 'FieldDateAndTimeInput.startTime' });
    const endTimeLabel = intl.formatMessage({ id: 'FieldDateAndTimeInput.endTime' });

    return (
      <div className={classes}>
        <div className={css.formRow}>
          <div className={classNames(css.field, css.bookingDate)}>
            <FieldDateInput
              className={css.fieldDateInput}
              activeField={activeField}
              finalFormApi={finalFormApi}
              formValues={formValues}
              id={formId ? `${formId}.${BOOKING_DATE}` : BOOKING_DATE}
              isDayBlocked={isDayBlocked}
              label={dateLabelText}
              monthlyTimeSlots={monthlyTimeSlots}
              name={BOOKING_DATE}
              navNext={<Next currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              navPrev={<Prev currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              nextFieldName={START_TIME}
              onChange={this.onBookingStartDateChange}
              onClose={event =>
                this.setState({
                  currentMonth: getMonthStartInTimeZone(event?.date ?? TODAY, this.props.timeZone),
                })
              }
              onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
              onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
              showErrorMessage={false}
              validate={required(dateRequiredText)}
            />
          </div>
        </div>
        <div className={css.formRow}>
          <div className={css.field}>
            <FieldSelect
              name={START_TIME}
              icon="time"
              id={formId ? `${formId}.${START_TIME}` : START_TIME}
              label={startTimeLabel}
              disabled={startTimeDisabled}
              onChange={this.onBookingStartTimeChange}
            >
              {bookingDate ? (
                map(p => (
                  <option key={p.timeOfDay} value={p.timeOfDay}>
                    {p.timeOfDay}
                  </option>
                ), availableStartTimes)
              ) : (
                <option>{placeholderTime}</option>
              )}
            </FieldSelect>
          </div>

          <div className={css.field}>
            <FieldSelect
              name={END_TIME}
              icon="time"
              id={formId ? `${formId}.${END_TIME}` : END_TIME}
              label={endTimeLabel}
              disabled={endTimeDisabled}
            >
              {bookingDate && (bookingStartTime || startTime) ? (
                map(p => (
                  <option key={p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay} value={p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}>
                    {p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                  </option>
                ), availableEndTimes)
              ) : (
                <option>{placeholderTime}</option>
              )}
            </FieldSelect>
          </div>
        </div>
      </div>
    );
  }
}

FieldDateAndTimeInput.defaultProps = {
  rootClassName: null,
  className: null,
  activeField: null,
  minimumHoursPerBooking: 1,
  startTimeInputProps: null,
  endTimeInputProps: null,
  listingId: null,
  monthlyTimeSlots: null,
  timeZone: null,
};

FieldDateAndTimeInput.propTypes = {
  rootClassName: string,
  className: string,
  activeField: string,
  finalFormApi: object.isRequired,
  formId: string,
  formValues: object.isRequired,
  minimumHoursPerBooking: number,
  startTimeInputProps: object,
  endTimeInputProps: object,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  timeZone: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(FieldDateAndTimeInput);
