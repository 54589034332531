import React from 'react';
import { bool, number, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { IconBolt } from '../../components/Icons';

import css from './InstantBookingBadge.module.css';

const InstantBookingBadge = (props) => {
  const {
    className,
    iconOnly,
    instantBookingFrom,
    intl,
    textOnly,
    withModal
  } = props;
  const instantBookingText = intl.formatMessage({ id: 'InstantBookingBadge.instantBooking' });
  const classes = classNames(css.root, className, {
    [css.rootTextOnly]: textOnly
  });
  const instantBookingTextClasses = classNames(css.instantBookingText, {
    [css.instantBookingTextTextOnly]: textOnly
  });

  return (
    <div className={classes}>
      {!textOnly && <IconBolt className={css.iconBolt} />}
      {!iconOnly && <span className={instantBookingTextClasses}>{instantBookingText}</span>}
    </div>
  )
}

InstantBookingBadge.defaultProps = {
  className: null,
  iconOnly: false,
  instantBookingFrom: null,
  textOnly: false,
  withModal: false,
};

InstantBookingBadge.propTypes = {
  className: string,
  iconOnly: bool,
  instantBookingFrom: number,
  intl: intlShape.isRequired,
  textOnly: bool,
  withModal: bool,
};

export default injectIntl(InstantBookingBadge);
