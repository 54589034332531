import React from 'react';
import { injectIntl } from 'react-intl';

import { IconArrow, NamedLink } from '../../components';
import providerImg from '../../assets/pictures/womanProvider.png'

import css from './LandingPage.css';

const SectionBecomeHost = props => {
  const { intl } = props;

  const title = intl.formatMessage({ id: 'LandingPage.becomeHostTitle' });
  const subtitle = intl.formatMessage({ id: 'LandingPage.becomeHostSubtitle' });
  const ctaText = intl.formatMessage({ id: 'LandingPage.becomeHostCTA' });

  return (
    <div className={css.sectionBecomeHost}>
      <div className={css.becomeHostContent}>
        <div className={css.becomeHostTitleContainer}>
          <span className={css.becomeHostTitle}>{title}</span>
          <span className={css.becomeHostSubtitle}>{subtitle}</span>
        </div>
        <div className={css.becomeHostCTAContainer}>
          <NamedLink className={css.becomeHostCTA} name='SimulatorPage' >
            <span className={css.buttonText}>{ctaText}</span>
            <IconArrow className={css.buttonIcon} direction='right' />
          </NamedLink>
          <img className={css.becomeHostImg} src={providerImg} />
        </div>
      </div>
    </div>
  )
}

export default injectIntl(SectionBecomeHost);