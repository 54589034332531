import axios from 'axios';
import { path, pathOr } from 'ramda';
import { getCityFromAddress } from './googleMaps';

const getOwnListings = async (sdk) => {
  try {
    const response = await sdk.ownListings.query({});
    const listings = pathOr([], ['data', 'data'], response);

    return listings;
  }
  catch (e) {
    return null
  }
};

export const updateOwnListingsCities = async (sdk) => {
  try {
    const listings = await getOwnListings(sdk);
    
    if (listings.length === 0) return;

    for (const listing of listings) {
      const currentCity = pathOr(null, ['attributes', 'publicData', 'location', 'city'], listing);

      if (currentCity) continue;

      const address = pathOr('', ['attributes', 'privateData', 'location', 'address'], listing);
      const city = await getCityFromAddress(address);
      const params = {
        id: listing.id,
        publicData: {
          location: {
            city,
          },
        },
      }
      
      await sdk.ownListings.update(params);
    }

  }
  catch (e) {
    return null;
  }
};

export const getIpGeolocation = async () => {
  try {
    const url = `https://ipgeolocation.abstractapi.com/v1/?api_key=${process.env.REACT_APP_ABSTRACT_API_KEY}`;
    const response = await axios.get(url);

    return path(['data'], response);
  }
  catch (e) {
    return null
  }
};
