import { types as sdkTypes } from '../../util/sdkLoader';
import { fetchTransaction } from '../../ducks/transaction.duck';

const { UUID } = sdkTypes;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const txId = new UUID(params.id);

  return Promise.all([
    dispatch(fetchTransaction(txId))
  ]);
};
