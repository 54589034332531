import React, { useEffect } from 'react';
import { func, number, object, string } from 'prop-types';
import classNames from 'classnames';

import { getTimeAsObject, getTotalHours } from '../../util/time';
import { FieldTimePicker } from '../../components';

import css from './FieldTimeRangePicker.css';

const FieldTimeRangePicker = props => {
  const {
    className,
    rootClassName,
    currentStartValue,
    finalFormApi,
    idEnd,
    idStart,
    inputEndRef,
    inputStartRef,
    labelEnd,
    labelStart,
    maxHour,
    minHour,
    minHourBetween,
    nameEnd,
    nameStart,
    validateEnd,
    validateStart,
  } = props;
  useEffect(() => {
    const currentEndValue = finalFormApi.getFieldState(nameEnd).value;

    if (!currentEndValue || getTotalHours(currentStartValue, currentEndValue) >= 0 + minHourBetween) return;

    finalFormApi.change(nameEnd, undefined);
  }, [currentStartValue]);
  const startTime = currentStartValue ? getTimeAsObject(currentStartValue) : '';
  const maxHourStart = maxHour - minHourBetween;
  const minHourEnd = currentStartValue ? startTime.hours + minHourBetween : minHour + minHourBetween;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <FieldTimePicker
        id={idStart || nameStart}
        inputRef={inputStartRef}
        label={labelStart}
        name={nameStart}
        minHour={minHour}
        minHourBetween={minHourBetween}
        maxHour={maxHourStart}
        validate={validateStart}
      />
      <FieldTimePicker
        disabled={!currentStartValue}
        id={idEnd || nameEnd}
        inputRef={inputEndRef}
        label={labelEnd}
        name={nameEnd}
        minHour={minHourEnd}
        maxHour={maxHour}
        validate={validateEnd}
      />
    </div>
  )
}

FieldTimeRangePicker.defaultProps = {
  className: null,
  rootClassName: null,
  currentStartValue: null,
  idEnd: null,
  idStart: null,
  maxHour: 24,
  minHourBetween: 1,
  minHour: 9,
  validateEnd: null,
  validateStart: null,
};

FieldTimeRangePicker.propTypes = {
  className: string,
  rootClassName: string,
  currentStartValue: string,
  finalFormApi: object.isRequired,
  idEnd: string,
  idStart: string,
  nameEnd: string.isRequired,
  nameStart: string.isRequired,
  labelEnd: string.isRequired,
  labelStart: string.isRequired,
  maxHour: number,
  minHourBetween: number,
  minHour: number,
  validateEnd: func,
  validateStart: func,
};

export default FieldTimeRangePicker;
