import { find, length, map, path, pathOr, reduce } from 'ramda';
import cookie from 'cookie-cutter';

import { denormalisedResponseEntities } from '../util/data';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import { updateProfile } from '../ducks/userSettings.duck';
import { authInfo } from './Auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import { util as sdkUtil } from '../util/sdkLoader';
import {
  BOOKING_UPCOMING_TRANSITIONS,
  BOOKING_PENDING_TRANSITIONS,
} from '../util/transactions';
import { updateOwnListingsCities } from '../util/swimmyOnLoginActions';
import {
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_PUBLISHED,
} from '../constants/listing';

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST = 'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS = 'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const FETCH_CURRENT_USER_HAS_UPCOMING_ORDERS_REQUEST = 'app/user/FETCH_CURRENT_USER_HAS_UPCOMING_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_UPCOMING_ORDERS_SUCCESS = 'app/user/FETCH_CURRENT_USER_HAS_UPCOMING_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_UPCOMING_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_UPCOMING_ORDERS_ERROR';

export const FETCH_CURRENT_USER_HAS_PENDING_SALES_REQUEST = 'app/user/FETCH_CURRENT_USER_HAS_PENDING_SALES_REQUEST';
export const FETCH_CURRENT_USER_HAS_PENDING_SALES_SUCCESS = 'app/user/FETCH_CURRENT_USER_HAS_PENDING_SALES_SUCCESS';
export const FETCH_CURRENT_USER_HAS_PENDING_SALES_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_PENDING_SALES_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

export const SET_CAN_SHOW_REQUIREMENT_FIELDS = 'app/user/SET_CAN_SHOW_REQUIREMENT_FIELDS';

// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
    ? newCurrentUser
    : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  currentUserLoading: false,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserHasPublishedListings: false,
  currentUserHasListingsError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  currentUserHasPendingSales: false,
  currentUserHasPendingSalesError: null,
  currentUserHasUpcomingOrders: false,
  currentUserHasUpcomingOrdersError: null,
  currentUserListingRefs: [],
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserLoading: true, currentUserShowError: null };
    case CURRENT_USER_SHOW_SUCCESS:
      return {
        ...state,
        currentUser: mergeCurrentUser(state.currentUser, payload),
        currentUserLoading: false,
      };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        currentUserShowError: payload,
        currentUserLoading: false,
      };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasPublishedListings: false,
        currentUserHasListingsError: null,
        currentUserHasPendingSales: false,
        currentUserHasPendingSalesError: null,
        currentUserHasUpcomingOrders: false,
        currentUserHasUpcomingOrdersError: null,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return {
        ...state,
        currentUserHasListings: payload.hasListings,
        currentUserHasPublishedListings: payload.hasPublishedListings,
        currentUserListingRefs: payload.listingRefs,
      };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case FETCH_CURRENT_USER_HAS_UPCOMING_ORDERS_REQUEST:
      return { ...state, currentUserHasUpcomingOrdersError: null };
    case FETCH_CURRENT_USER_HAS_UPCOMING_ORDERS_SUCCESS:
      return { ...state, currentUserHasUpcomingOrders: payload.hasUpcomingOrders };
    case FETCH_CURRENT_USER_HAS_UPCOMING_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasUpcomingOrdersError: payload };

    case FETCH_CURRENT_USER_HAS_PENDING_SALES_REQUEST:
      return { ...state, currentUserHasPendingSalesError: null };
    case FETCH_CURRENT_USER_HAS_PENDING_SALES_SUCCESS:
      return { ...state, currentUserHasPendingSales: payload.hasPendingSales };
    case FETCH_CURRENT_USER_HAS_PENDING_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasPendingSalesError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };

    case SET_CAN_SHOW_REQUIREMENT_FIELDS:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = (listingRefs, hasListings, hasPublishedListings) => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings, hasPublishedListings, listingRefs },
});

const fetchCurrentUserHasListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = hasOrders => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasUpcomingOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_UPCOMING_ORDERS_REQUEST,
});

export const fetchCurrentUserHasUpcomingOrdersSuccess = hasUpcomingOrders => ({
  type: FETCH_CURRENT_USER_HAS_UPCOMING_ORDERS_SUCCESS,
  payload: { hasUpcomingOrders },
});

const fetchCurrentUserHasUpcomingOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_UPCOMING_ORDERS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasPendingSalesRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_PENDING_SALES_REQUEST,
});

export const fetchCurrentUserHasPendingSalesSuccess = hasPendingSales => ({
  type: FETCH_CURRENT_USER_HAS_PENDING_SALES_SUCCESS,
  payload: { hasPendingSales },
});

const fetchCurrentUserHasPendingSalesError = e => ({
  type: FETCH_CURRENT_USER_HAS_PENDING_SALES_ERROR,
  error: true,
  payload: e,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});

export const setCanShowRequirementFields = requirementsToShow => ({
  type: SET_CAN_SHOW_REQUIREMENT_FIELDS,
  payload: { requirementsToShow },
});

// ================ Thunks ================ //

const canShowRequirementsFields = (requirements) => (dispatch, getState, sdk) => {
  const canShowRequirementsFieldsPayload = reduce((acc, requirement) => {
    switch (requirement) {
      case 'individual.verification.additional_document':
        return { ...acc, canShowCNIField: true, canShowProofOfAddress: true };
      case 'individual.verification.document':
        return { ...acc, canShowCNIField: true };
      default:
        return acc;
    }
  }, {}, requirements);
  return dispatch(setCanShowRequirementFields(canShowRequirementsFieldsPayload));
};

const activeStripeCapabilities = () => async (dispatch, getState, sdk) => {
  try {
    const res = await sdk.stripeAccount.fetch();
    const requirements = pathOr([], ['data', 'data', 'attributes', 'stripeAccountData', 'requirements', 'currently_due'], res);
    dispatch(canShowRequirementsFields(requirements));

    const {
      card_payments,
      transfers,
    } = pathOr({}, ['data', 'data', 'attributes', 'stripeAccountData', 'capabilities'], res);
    const inactiveStripe = 'inactive';
    if (card_payments !== inactiveStripe && transfers !== inactiveStripe && transfers) return;

    const requestedCapabilities = [
      'card_payments',
      'transfers',
    ];
    const updateResult = await sdk.stripeAccount.update({
      requestedCapabilities,
    }, {
      expand: true,
    });
    const requirementsAfterUpdate = pathOr([], ['data', 'data', 'attributes', 'stripeAccountData', 'requirements', 'currently_due'], updateResult);
    dispatch(canShowRequirementsFields(requirementsAfterUpdate));
  }catch (e) {}
};

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess([], false, false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    page: 1,
    'fields.ownListing': ['state'],
  };

  return sdk.ownListings
    .query(params)
    .then(response => {
      const listingRefs = map(entity => ({ id: entity.id, type: entity.type }), response.data.data);
      const hasListings = length(listingRefs) > 0;
      const hasPublishedListings = hasListings && find(listing => listing.attributes.state === LISTING_STATE_PUBLISHED, response.data.data);

      dispatch(fetchCurrentUserHasListingsSuccess(listingRefs, !!hasListings, !!hasPublishedListings));
    })
    .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    per_page: 1,
    'fields.transaction': [],
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

export const fetchCurrentUserHasUpcomingOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasUpcomingOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasUpcomingOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    lastTransitions: BOOKING_UPCOMING_TRANSITIONS,
    page: 1,
    per_page: 1,
    'fields.transaction': [],
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasUpcomingOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasUpcomingOrdersSuccess(!!hasUpcomingOrders));
    })
    .catch(e => dispatch(fetchCurrentUserHasUpcomingOrdersError(storableError(e))));
};

export const fetchCurrentUserHasPendingSales = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasPendingSalesRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasPendingSalesSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'sale',
    lastTransitions: BOOKING_PENDING_TRANSITIONS,
    page: 1,
    per_page: 1,
    'fields.transaction': [],
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasPendingSales = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasPendingSalesSuccess(!!hasPendingSales));
    })
    .catch(e => dispatch(fetchCurrentUserHasPendingSalesError(storableError(e))));
};

export const fetchCurrentUser = (params = null) => (dispatch, getState, sdk) => {
  dispatch(currentUserShowRequest());
  const { isAuthenticated } = getState().Auth;

  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  const parameters = params || {
    include: ['profileImage', 'stripeAccount'],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.square-xsmall',
      'variants.square-xsmall2x',
    ],
    'imageVariant.square-xsmall': sdkUtil.objectQueryString({
      w: 40,
      h: 40,
      fit: 'crop',
    }),
    'imageVariant.square-xsmall2x': sdkUtil.objectQueryString({
      w: 80,
      h: 80,
      fit: 'crop',
    }),
  };
  dispatch(activeStripeCapabilities());

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
      }

      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);
      dispatch(currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .then(currentUser => {
      dispatch(fetchCurrentUserHasListings());
      dispatch(fetchCurrentUserHasUpcomingOrders());
      dispatch(fetchCurrentUserHasPendingSales());
      updateOwnListingsReviewsInfo(sdk);
      updateOwnListingsCities(sdk);

      if (!currentUser.attributes.emailVerified) {
        dispatch(fetchCurrentUserHasOrders());
      } else if (!path(['attributes', 'profile', 'publicData', 'emailVerified'], currentUser)) {
        const emailStatus = {
          publicData: {
            emailVerified: true,
          },
        };
        dispatch(updateProfile(emailStatus));
      }

      // Make sure auth info is up to date
      dispatch(authInfo());
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed');
      dispatch(currentUserShowError(storableError(e)));
    });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};

export const getOwnListingIds = async (sdk) => {
  try {
    const response = await sdk.ownListings.query({});
    const listings = pathOr([], ['data', 'data'], response);
    const listingIds = listings.map(listing => listing.id);

    return listingIds;
  } catch (e) {
    return null;
  }
};

export const getReviewsFromListingId = async (sdk, listingId) => {
  try {
    const params = {
      listingId: listingId,
      state: 'public',
    };
    const response = await sdk.reviews.query(params);
    const reviews = pathOr([], ['data', 'data'], response);

    return reviews;
  } catch (e) {
    return null;
  }
};

const calculateReviewsAverageRating = (reviews) => {
  const reviewsRating = reviews.map(review => review.attributes.rating);
  const averageRating = (reviewsRatingArray) => reviewsRatingArray.reduce((a, b) => a + b) / reviewsRatingArray.length;
  return Math.round(averageRating(reviewsRating) * 100) / 100;
};

export const updateOwnListingsReviewsInfo = async (sdk) => {
  try {
    const listingIds = await getOwnListingIds(sdk);

    for (const listingId of listingIds) {
      const reviews = await getReviewsFromListingId(sdk, listingId);
      const totalReviews = length(reviews);
      const averageRating = totalReviews > 0 ? calculateReviewsAverageRating(reviews) : 0;

      const params = {
        id: listingId,
        publicData: {
          reviewsInfo: {
            averageRating: averageRating,
            totalReviews: totalReviews,
          },
        },
      };
      await sdk.ownListings.update(params);
    }

  } catch (e) {
    return null;
  }
};

export const getUserToken = () => {
  const clientId = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;
  const namespace = 'st';
  const key = `${namespace}-${clientId}-token`;
  const tokenRaw = cookie.get(key) || '{}';
  const tokenObj = JSON.parse(tokenRaw);
  if (!tokenObj.access_token) return null;

  return tokenObj;
};
