import React from 'react';
import { string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';

import { Divider } from '../../../../components';

import css from './Footer.module.css';

const Footer = (props) => {
  const {
    className,
    intl,
  } = props;
  const footerText = intl.formatMessage({ id: 'CalendarPage.SidePanel.footer' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <Divider className={css.divider} />
      <span className={css.text}>{footerText}</span>
    </div>
  )
}

Footer.defaultProps = {
  className: null,
};

Footer.propTypes = {
  className: string,
  intl: intlShape.isRequired,
};

export default Footer;