import React from 'react';
import { bool, string } from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import css from './SlideAnimation.module.css';

const topClasses = {
  appear: css.appearTop,
  appearActive: css.appearActiveTop,
  appearDone: css.appearDoneTop,
  enter: css.enterTop,
  enterActive: css.enterActiveTop,
  enterDone: css.enterDoneTop,
  exit: css.exitTop,
  exitActive: css.exitActiveTop,
  exitDone: css.exitDoneTop,
};

const rightClasses = {
  appear: css.appearRight,
  appearActive: css.appearActiveRight,
  appearDone: css.appearDoneRight,
  enter: css.enterRight,
  enterActive: css.enterActiveRight,
  enterDone: css.enterDoneRight,
  exit: css.exitRight,
  exitActive: css.exitActiveRight,
  exitDone: css.exitDoneRight,
};

const bottomClasses = {
  appear: css.appearBottom,
  appearActive: css.appearActiveBottom,
  appearDone: css.appearDoneBottom,
  enter: css.enterBottom,
  enterActive: css.enterActiveBottom,
  enterDone: css.enterDoneBottom,
  exit: css.exitBottom,
  exitActive: css.exitActiveBottom,
  exitDone: css.exitDoneBottom,
};

const leftClasses = {
  appear: css.appearLeft,
  appearActive: css.appearActiveLeft,
  appearDone: css.appearDoneLeft,
  enter: css.enterLeft,
  enterActive: css.enterActiveLeft,
  enterDone: css.enterDoneLeft,
  exit: css.exitLeft,
  exitActive: css.exitActiveLeft,
  exitDone: css.exitDoneLeft,
}

const getTransitionClasses = (from) => {
  switch (from) {
    case 'top':
      return topClasses;
    case 'right':
      return rightClasses;
    case 'bottom':
      return bottomClasses;
    default:
      return leftClasses;
  };
};

const SlideAnimation = (props) => {
  const {
    appear,
    inProps,
    mountOnEnter,
    unmountOnExit,
    from,
    children,
  } = props;

  const transitionClasses = getTransitionClasses(from);

  return (
    <CSSTransition
      in={inProps}
      appear={appear}
      timeout={200}
      classNames={transitionClasses}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
    >
      {children}
    </CSSTransition>
  )
};

SlideAnimation.defaultProps = {
  from: 'left',
  mountOnEnter: false,
  unmountOnExit: false,
}

SlideAnimation.propTypes = {
  from: string,
  inProps: bool.isRequired,
  mountOnEnter: bool,
  unmountOnExit: bool,
}

export default SlideAnimation;
