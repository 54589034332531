import React from 'react';
import { Helmet } from 'react-helmet-async';

import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isServer, isCookieAccepted } from '../../util/cookies';
import config from '../../config';

const CriteoProduct = props => {
  if (isServer()) return null;
  if (!isCookieAccepted('swimmy_criteoAccepted')) return null;

  const {
    currentUser,
    listingId
  } = props;
  const user = ensureCurrentUser(currentUser);
  const currentEmail = user.attributes.email || '';

  if (!listingId) return null;

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="https://static.criteo.net/js/ld/ld.js" async="true"/>
        <script type="text/javascript">
          {`
            window.criteo_q = window.criteo_q || [];
            var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
            window.criteo_q.push(
              { event: "setAccount", account: ${config.criteoPartnerId}},
              { event: "setEmail", email: "${currentEmail}" },
              { event: "setSiteType", type: deviceType},
              { event: "viewItem", item: "${listingId.uuid}" }
            );
          `}
        </script>
      </Helmet>
    </div>
  );
};

CriteoProduct.defaultProps = {
  currentUser: null,
  listingId: null
};

CriteoProduct.propTypes = {
  currentUser: propTypes.currentUser,
  listingId: propTypes.uuid
};

export default CriteoProduct;
