// ================ Action types ================ //

export const SET_LOCATION = 'app/SearchPageResults/SET_LOCATION';

export const SET_SEARCH_RESULT_IDS = 'app/SearchPageResults/SET_SEARCH_RESULTS_IDS';

// ================ Reducer ================ //
const initialState = {
  location: null,
  searchResultIds: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch(type) {
    case SET_LOCATION:
      return { ...state, location: payload };

    case SET_SEARCH_RESULT_IDS:
      return { ...state, searchResultIds: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setLocation = location => ({ type: SET_LOCATION, payload: location });

export const setSearchResultsIds = result => ({ type: SET_SEARCH_RESULT_IDS, payload: result });
