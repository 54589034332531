import React from 'react';
import {array, bool, func, number, object, shape, string} from 'prop-types';
import {compose} from 'redux';
import {pathOr} from 'ramda';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {propTypes} from '../../util/types';
import {sendVerificationEmail, hasCurrentUserErrors} from '../../ducks/user.duck';
import {logout, authenticationInProgress} from '../../ducks/Auth.duck';
import {manageDisableScrolling} from '../../ducks/UI.duck';
import { updateProfile } from '../../ducks/userSettings.duck';

import Topbar from './Topbar/Topbar';

export const TopbarContainerComponent = props => {
  const {
    authInProgress,
    currentPage,
    currentSearchParams,
    currentUser,
    currentUserHasListings,
    currentUserHasPublishedListings,
    currentUserHasOrders,
    history,
    isAuthenticated,
    authScopes,
    hasGenericError,
    location,
    notificationCount,
    messagesNotificationCount,
    bookingsNotificationCount,
    onLogout,
    onManageDisableScrolling,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    ...rest
  } = props;

  return (
    <Topbar
      authInProgress={authInProgress}
      currentPage={currentPage}
      currentSearchParams={currentSearchParams}
      currentUser={currentUser}
      currentUserHasListings={currentUserHasListings}
      currentUserHasPublishedListings={currentUserHasPublishedListings}
      currentUserHasOrders={currentUserHasOrders}
      history={history}
      isAuthenticated={isAuthenticated}
      authScopes={authScopes}
      location={location}
      notificationCount={notificationCount}
      messagesNotificationCount={messagesNotificationCount}
      bookingsNotificationCount={bookingsNotificationCount}
      onLogout={onLogout}
      onManageDisableScrolling={onManageDisableScrolling}
      onResendVerificationEmail={onResendVerificationEmail}
      sendVerificationEmailInProgress={sendVerificationEmailInProgress}
      sendVerificationEmailError={sendVerificationEmailError}
      showGenericError={hasGenericError}
      {...rest}
    />
  );
};

TopbarContainerComponent.defaultProps = {
  currentPage: null,
  currentSearchParams: null,
  currentUser: null,
  currentUserHasOrders: null,
  notificationCount: 0,
  messagesNotificationCount: 0,
  bookingsNotificationCount: 0,
  sendVerificationEmailError: null,
  authScopes: null,
};

TopbarContainerComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentPage: string,
  currentSearchParams: object,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasPublishedListings: bool.isRequired,
  currentUserHasOrders: bool,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  notificationCount: number,
  messagesNotificationCount: number,
  bookingsNotificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  hasGenericError: bool.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({state: object}).isRequired,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const {isAuthenticated, logoutError, authScopes} = state.Auth;
  // Topbar needs user info.
  const {
    currentUser,
    currentUserHasListings,
    currentUserHasPublishedListings,
    currentUserHasOrders,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  } = state.user;
  const notificationCount = pathOr(0, ['attributes', 'profile', 'privateData', 'badge'], currentUser);
  const messagesNotificationCount = pathOr(0, ['attributes', 'profile', 'privateData', 'messagesBadge'], currentUser);
  const bookingsNotificationCount = pathOr(0, ['attributes', 'profile', 'privateData', 'bookingsIncomeBadge'], currentUser) +
    pathOr(0, ['attributes', 'profile', 'privateData', 'bookingsRequestBadge'], currentUser) +
    pathOr(0, ['attributes', 'profile', 'privateData', 'bookingsOthersBadge'], currentUser);
  const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasListings,
    currentUserHasPublishedListings,
    currentUserHasOrders,
    notificationCount,
    messagesNotificationCount,
    bookingsNotificationCount,
    isAuthenticated,
    authScopes,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    hasGenericError,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onUpdateProfile: (...data) => dispatch(updateProfile(...data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TopbarContainerComponent);

export default TopbarContainer;
