import React, { useEffect, useState } from 'react';
import { array, func, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { filter, path } from 'ramda';

import { propTypes } from '../../util/types';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { SlideAnimation } from '../../components/Animations';

import { requestFetchAvailabilityExceptions, requestUpdateAvailabilityPlan } from './CalendarPage.duck';
import MainContent from './MainContent/MainContent';
import SidePanel from './SidePanel/SidePanel';
import css from './CalendarPage.module.css';

const MAX_MOBILE_WIDTH = 1023;

const CalendarPage = (props) => {
  const {
    className,
    availabilityExceptions,
    currentListing,
    intl,
    onFetchAvailabilityExceptions,
    onManageDisableScrolling,
    onUpdateAvailabilityPlan,
    scrollingDisabled,
    transactions,
    viewport,
  } = props;
  const isMobile = viewport && viewport.width <= MAX_MOBILE_WIDTH;
  const [selectedDates, setSelectedDates] = useState([]);
  const [showSidePanel, setShowSidePanel] = useState(true);
  useEffect(() => setShowSidePanel(!isMobile), [viewport.width]);
  useEffect(() => {
    if (!isMobile) return;
    if (selectedDates.length === 0) return setShowSidePanel(false);

    setShowSidePanel(true)
  }, [selectedDates]);
  const handleResetDates = () => {
    setSelectedDates([]);

    if (!isMobile) return;

    setShowSidePanel(false)
  };
  const pageTitleText = intl.formatMessage({ id: 'CalendarPage.pageTitle' });
  const classes = classNames(css.root, className);

  return (
    <Page title={pageTitleText} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="CalendarPage"/>
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={classes}>
            <MainContent
              className={css.mainContent}
              availabilityExceptions={availabilityExceptions}
              currentListing={currentListing}
              handleSelectedDates={setSelectedDates}
              intl={intl}
              isMobile={isMobile}
              onFetchAvailabilityExceptions={onFetchAvailabilityExceptions}
              onManageDisableScrolling={onManageDisableScrolling}
              onUpdateAvailabilityPlan={onUpdateAvailabilityPlan}
              selectedDates={selectedDates}
              transactions={transactions}
            />
            <SlideAnimation inProps={showSidePanel} from="bottom" mountOnEnter>
              <SidePanel
                className={css.sidePanel}
                availabilityExceptions={availabilityExceptions}
                currentListing={currentListing}
                handleResetDates={handleResetDates}
                intl={intl}
                onUpdateAvailabilityPlan={onUpdateAvailabilityPlan}
                selectedDates={selectedDates}
              />
            </SlideAnimation>
          </div>
        </LayoutWrapperMain>
      </LayoutSingleColumn>
    </Page>
  )
}

CalendarPage.defaultProps = {
  className: null,
};

CalendarPage.propTypes = {
  className: string,
  availabilityExceptions: array.isRequired,
  currentListing: propTypes.ownListing,
  intl: intlShape.isRequired,
  onFetchAvailabilityExceptions: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onUpdateAvailabilityPlan: func.isRequired,
  transactions: array.isRequired
};

const mapStateToProps = state => {
  const { availabilityExceptions, listingRef, transactions } = state.CalendarPage;
  
  const ownListings = getMarketplaceEntities(state, listingRef ? [listingRef] : []);
  const currentListing = ownListings.length > 0 ? ownListings[0] : null;
  const transactionsOfCurrentListing = filter(transaction => path(['id', 'uuid'], listingRef) === path(['listing', 'id', 'uuid'], transaction), transactions);

  return {
    availabilityExceptions,
    currentListing,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: transactionsOfCurrentListing,
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchAvailabilityExceptions: (params) => dispatch(requestFetchAvailabilityExceptions(params)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onUpdateAvailabilityPlan: (params) => dispatch(requestUpdateAvailabilityPlan(params)),
});

export default compose(
    withViewport,
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    injectIntl
  )(CalendarPage);
