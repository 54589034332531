import React, { useEffect } from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  NamedLink,
  IconEmailAttention,
  IconEmailSuccess,
  IconSpinner,
  InlineTextButton,
} from '../../components';
import { isTooManyEmailVerificationRequestsError } from '../../util/errors';
import { propTypes } from '../../util/types';
import css from './EmailVerificationAuto.css';

const EmailVerificationAuto = props => {
    const {
        currentUser,
        inProgress,
        resendVerificationEmail,
        verificationSuccess,
        submitVerification,
        verificationError,
        sendVerificationEmailInProgress,
        sendVerificationEmailError,
        verificationToken,
    } = props;
    const { email, emailVerified, pendingEmail, profile } = currentUser.attributes;
    const emailToVerify = <strong>{pendingEmail || email}</strong>;
    const name = profile.firstName;
    useEffect(() => {
        if (verificationError || verificationSuccess || inProgress || (emailVerified && !pendingEmail)) return;
        submitVerification(verificationToken);
    }, [pendingEmail, emailVerified]);

    const errorMessage = (
        <div className={css.error}>
            <FormattedMessage id="EmailVerificationAuto.verificationFailed" />
        </div>
    );

    const resendEmailLink = (
        <InlineTextButton className={css.helperLink} onClick={resendVerificationEmail}>
            <FormattedMessage id="EmailVerificationAuto.resendEmailLinkText" />
        </InlineTextButton>
    );
    const fixEmailLink = (
        <NamedLink className={css.helperLink} name="AccountPersonalInfosPage">
            <FormattedMessage id="EmailVerificationAuto.fixEmailLinkText" />
        </NamedLink>
    );
    const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
        sendVerificationEmailError
    )
        ? 'EmailVerificationAuto.resendFailedTooManyRequests'
        : 'EmailVerificationAuto.resendFailed';
    const resendErrorMessage = sendVerificationEmailError ? (
        <p className={css.error}>
            <FormattedMessage id={resendErrorTranslationId} />
        </p>
    ) : null;
    
    const verifyEmail = (
        <div className={css.root}>
            <div className={css.topWrapper}>
                <IconEmailAttention className={css.modalIcon} />
                <h1 className={css.modalTitle}>
                    {verificationError ? (
                        <FormattedMessage id="EmailVerificationAuto.verifyEmailAddressError" />
                    ) : (
                        <FormattedMessage id="EmailVerificationAuto.verifyEmailAddress" />
                    )}
                </h1>
                <p className={css.modalMessage}>
                    <FormattedMessage
                        id="EmailVerificationAuto.finishAccountSetup"
                        values={{ email: emailToVerify }}
                    />
                </p>
                {verificationError ? errorMessage : null}
            </div>
            <div className={css.bottomWrapper}>
                {inProgress && (<IconSpinner rootClassName={css.spinner} />)}
                {verificationError && (
                    <p className={css.helperText}>
                        {sendVerificationEmailInProgress ? (
                            <FormattedMessage id="EmailVerificationAuto.sendingEmail" />
                            ) : (
                            <FormattedMessage
                                id="EmailVerificationAuto.resendEmail"
                                values={{ resendEmailLink }}
                            />
                        )}
                    </p>
                )}
                {verificationError && (
                    <p className={css.helperText}>
                        <FormattedMessage id="EmailVerificationAuto.fixEmail" values={{ fixEmailLink }} />
                    </p>
                )}
                {resendErrorMessage}
            </div>
        </div>
    );
    
    const alreadyVerified = (
        <div className={css.root}>
            <div className={css.topWrapper}>
                <IconEmailSuccess className={css.modalIcon} />
                <h1 className={css.modalTitle}>
                    <FormattedMessage id="EmailVerificationAuto.successTitle" values={{ name }} />
                </h1>
                <p className={css.modalMessage}>
                    <FormattedMessage id="EmailVerificationAuto.successText" />
                </p>
            </div>
            <div className={css.bottomWrapper}>
                <NamedLink className={css.submitButton} name="LandingPage">
                    <FormattedMessage id="EmailVerificationAuto.successButtonText" />
                </NamedLink>
            </div>
        </div>
    );
    return emailVerified && !pendingEmail ? alreadyVerified : verifyEmail
}

EmailVerificationAuto.defaultProps = {
    currentUser: null,
    inProgress: false,
    verificationError: null,
};

EmailVerificationAuto.propTypes = {
    inProgress: bool,
    currentUser: propTypes.currentUser.isRequired,
    verificationError: propTypes.error,
};

  
export default compose(injectIntl)(EmailVerificationAuto);