import { path, pathOr } from 'ramda';

import { moment } from '../../util/dates';
import { ensureUser } from '../../util/data';
import {
  TRANSITION_ACCEPT,
  TRANSITION_CANCEL_AFTER_DELIVERED,
  TRANSITION_CANCEL_BEFORE_DELIVERED,
  TRANSITION_CANCEL_BY_CUSTOMER,
  TRANSITION_COMPLETE,
  TRANSITION_COMPLETE_BY_OPERATOR,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_INSTANT_ACCEPT,
  TRANSITION_MARK_NON_REFUNDABLE,
  TRANSITION_PAYOUT_AFTER_DELIVERED,
  TRANSITION_PAYOUT_AFTER_REVIEWED_BY_CUSTOMER,
  TRANSITION_PAYOUT_AFTER_REVIEWED_BY_PROVIDER,
  TRANSITION_PAYOUT_AFTER_REVIEWED,
  TRANSITION_REFUND_AFTER_CANCEL,
  TRANSITION_REFUND_BY_CUSTOMER,
  TRANSITION_REFUND_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_CUSTOMER_AFTER_PAYOUT,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_PROVIDER_AFTER_PAYOUT,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER_AFTER_PAYOUT,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER_AFTER_PAYOUT,
  TRANSITION_ENQUIRE,
  TRANSITION_ENQUIRE_NEW_MESSAGE_BY_CUSTOMER,
  TRANSITION_ENQUIRE_NEW_MESSAGE_BY_PROVIDER,
  TRANSITION_ENQUIRE_OPENED_BY_PROVIDER,
  TRANSITION_ENQUIRE_OPENED_BY_CUSTOMER,
  txHasBeenDelivered,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsDelivered,
  txIsEnquired,
  txIsPaidOutAfterDelivered,
  txIsRefunded,
  txIsRequested,
  txIsReviewedByCustomer,
  txIsReviewedByProvider,
  txIsReviewed,
} from '../../util/transactions';

import css from './InboxPage.module.css';

const allTransitions = [
  TRANSITION_ACCEPT,
  TRANSITION_CANCEL_AFTER_DELIVERED,
  TRANSITION_CANCEL_BEFORE_DELIVERED,
  TRANSITION_CANCEL_BY_CUSTOMER,
  TRANSITION_COMPLETE,
  TRANSITION_COMPLETE_BY_OPERATOR,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_INSTANT_ACCEPT,
  TRANSITION_MARK_NON_REFUNDABLE,
  TRANSITION_PAYOUT_AFTER_DELIVERED,
  TRANSITION_PAYOUT_AFTER_REVIEWED_BY_CUSTOMER,
  TRANSITION_PAYOUT_AFTER_REVIEWED_BY_PROVIDER,
  TRANSITION_PAYOUT_AFTER_REVIEWED,
  TRANSITION_REFUND_AFTER_CANCEL,
  TRANSITION_REFUND_BY_CUSTOMER,
  TRANSITION_REFUND_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_CUSTOMER_AFTER_PAYOUT,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_PROVIDER_AFTER_PAYOUT,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER_AFTER_PAYOUT,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER_AFTER_PAYOUT,
  TRANSITION_ENQUIRE,
  TRANSITION_ENQUIRE_NEW_MESSAGE_BY_CUSTOMER,
  TRANSITION_ENQUIRE_NEW_MESSAGE_BY_PROVIDER,
  TRANSITION_ENQUIRE_OPENED_BY_PROVIDER,
  TRANSITION_ENQUIRE_OPENED_BY_CUSTOMER,
];

const bookingTransitions = [
  TRANSITION_ACCEPT,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_INSTANT_ACCEPT,
  TRANSITION_MARK_NON_REFUNDABLE,
];

const informationTransitions = [
  TRANSITION_ENQUIRE,
  TRANSITION_ENQUIRE_NEW_MESSAGE_BY_CUSTOMER,
  TRANSITION_ENQUIRE_NEW_MESSAGE_BY_PROVIDER,
  TRANSITION_ENQUIRE_OPENED_BY_PROVIDER,
  TRANSITION_ENQUIRE_OPENED_BY_CUSTOMER,
];

export const getLastTransitions = filter => {
  switch (filter) {
    case 'booking':
      return bookingTransitions;
    case 'information':
      return informationTransitions;

    default:
      return allTransitions;
  }
}

export const hasNewMessages = (tx, currentUser) => {
  const currentUserIsProvider = tx && currentUser && currentUser.id.uuid === tx.provider.id.uuid;
  return currentUserIsProvider
    ? pathOr(false, ['attributes', 'metadata', 'messageNotRodeByProvider'], tx)
    : pathOr(false, ['attributes', 'metadata', 'messageNotRodeByCustomer'], tx);
};

export const getTxType = (tx, currentUser) => {
  const currentUserId = pathOr(null, ['id', 'uuid'], currentUser);
  const txProviderId = pathOr(null, ['provider', 'id', 'uuid'], tx);
  
  return tx && currentUserId && txProviderId && currentUserId === txProviderId ? 'sale' : 'order';
};

export const txState = (intl, tx, txType, unreadMessages) => {
  const isOrder = txType === 'order';

  if (txIsRequested(tx)) {
    return isOrder ? {
        nameClassName: css.nameNotEmphasized,
        bookingClassName: css.bookingNoActionNeeded,
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({ id: 'InboxPage.stateRequested' }),
      } : {
        nameClassName: css.nameEmphasized,
        bookingClassName: css.bookingActionNeeded,
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({ id: 'InboxPage.statePending' }),
      }
  } else if (txIsDeclined(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({ id: 'InboxPage.stateDeclined' }),
      showSearch: isOrder,
    };
  } else if (txIsAccepted(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      stateClassName: css.stateSuccess,
      state: intl.formatMessage({ id: 'InboxPage.stateAccepted' }),
    };
  } else if (txIsCanceled(tx) || txIsRefunded(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({ id: 'InboxPage.stateCanceled' }),
      showSearch: isOrder,
    };
  } else if (txIsReviewedByCustomer(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: isOrder ? css.bookingNoActionNeeded : css.bookingActionNeeded,
      stateClassName: isOrder ? css.stateNoActionNeeded : css.stateActionNeeded,
      state: isOrder ? intl.formatMessage({ id: 'InboxPage.stateDelivered' }) : intl.formatMessage({ id: 'InboxPage.stateReview' }),
    };
  } else if (txIsReviewedByProvider(tx)) {
    return {
      nameClassName: !isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: !isOrder ? css.bookingNoActionNeeded : css.bookingActionNeeded,
      stateClassName: !isOrder ? css.stateNoActionNeeded : css.stateActionNeeded,
      state: !isOrder ? intl.formatMessage({ id: 'InboxPage.stateDelivered' }) : intl.formatMessage({ id: 'InboxPage.stateReview' }),
    };
  } else if ((txIsDelivered(tx) || txIsPaidOutAfterDelivered(tx)) && !txIsReviewed(tx)) {
    return {
      nameClassName: css.nameEmphasized,
      bookingClassName: css.bookingActionNeeded,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({ id: 'InboxPage.stateReview' }),
    };
  } else if (txHasBeenDelivered(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({ id: 'InboxPage.stateDelivered' }),
    };
  } else if (txIsEnquired(tx)) {
    return {
      nameClassName: unreadMessages ? css.nameEmphasized : css.nameNotEmphasized,
      bookingClassName: unreadMessages ? css.bookingActionNeeded : css.bookingNoActionNeeded,
      stateClassName: unreadMessages ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({ id: 'InboxPage.stateEnquiry' }),
      showSearch: isOrder,
    };
  } else {
    console.warn('This transition is unknown:', tx.attributes.lastTransition);

    return null;
  }
};

export const handleMessageEvent = (tx, currentUser, onUpdateMessageBookingEvent) => {
  const currentUserIsProvider = tx && currentUser && currentUser.id.uuid === tx.provider.id.uuid;
  const unreadMessages = hasNewMessages(tx, currentUser);

  if (!unreadMessages) return;

  onUpdateMessageBookingEvent(currentUserIsProvider, tx.id);
};

export const getSendingMessageNotAllowed = (tx, intl) => {
  const ensuredCustomer = ensureUser(tx.customer);
  const ensuredProvider = ensureUser(tx.provider);

  const isCustomerLoaded = !!ensuredCustomer.id;
  const isCustomerBanned = isCustomerLoaded && ensuredCustomer.attributes.banned;
  const isCustomerDeleted = isCustomerLoaded && ensuredCustomer.attributes.deleted;
  const isProviderLoaded = !!ensuredProvider.id;
  const isProviderBanned = isProviderLoaded && ensuredProvider.attributes.banned;
  const isProviderDeleted = isProviderLoaded && ensuredProvider.attributes.deleted;

  const bookingDate = path(['booking', 'attributes', 'end'], tx) || path(['attributes', 'protectedData', 'date'], tx);
  const dateDiff = bookingDate && moment().diff(moment(bookingDate), 'days');
  const outdated = dateDiff > 10;

  if (isCustomerBanned || isProviderBanned) {
    return intl.formatMessage({ id: 'InboxPage.ActivityFeedSection.userBanned' })
  } else if (isCustomerDeleted || isProviderDeleted) {
    return intl.formatMessage({ id: 'InboxPage.ActivityFeedSection.userDeleted' })
  } else if (outdated) {
    return intl.formatMessage({ id: 'InboxPage.ActivityFeedSection.outdated' });
  } else {
    return null;
  }
};
