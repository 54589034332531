import React from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import { propTypes } from '../../util/types';
import { withViewport } from '../../util/contextHelpers';
import {
  AffilaeTrackingTag,
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  CriteoHomepage,
  HeatmapTag,
} from '../../components';
import { TopbarContainer } from '../../containers';
import facebookImage from '../../assets/swimmyFacebook-1200x630.jpg';
import twitterImage from '../../assets/swimmyTwitter-600x314.jpg';

import SectionBecomeHost from './SectionBecomeHost';
import SectionCTA from './SectionCTA';
import SectionContact from './SectionContact';
import SectionEvent from './SectionEvent';
import SectionFaq from './SectionFaq';
import SectionListings from './SectionListings';
import SectionMain from './SectionMain';
import SectionReviews from './SectionReviews';
import { fetchReviews } from './LandingPage.duck';
import css from './LandingPage.css';

export const LandingPageComponent = props => {
  const {
    scrollingDisabled,
    currentUser,
    onFetchReviews,
    reviews,
    fetchReviewsInProgress,
    isAuthenticated,
    intl,
    viewport,
  } = props;
  const isMobile = viewport && viewport.width < 1024;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage(
    { id: 'LandingPage.schemaDescription' },
    { siteTitle }
  );
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: `${config.canonicalRootURL}${facebookImage}`, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <CriteoHomepage currentUser={currentUser} />
      <HeatmapTag />
      <AffilaeTrackingTag/>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="LandingPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>

          <SectionMain isMobile={isMobile} />
          <SectionCTA isAuthenticated={isAuthenticated} />
          <SectionListings />
          <SectionEvent />
          {config.custom.showReviews ? (
            <SectionReviews
              reviews={reviews}
              fetchReviewsInProgress={fetchReviewsInProgress}
              onFetchReviews={onFetchReviews}
              maxItemsToDisplay={8}
            />
          ) : null}
          <div className={css.container}>
            <SectionFaq />
            <SectionContact />
          </div>
          <SectionBecomeHost />

        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  const { reviews, fetchReviewsInProgress } = state.LandingPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    reviews,
    fetchReviewsInProgress,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchReviews: () => dispatch(fetchReviews()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps, mapDispatchToProps), withViewport, injectIntl)(
  LandingPageComponent
);

export default LandingPage;
