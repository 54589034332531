import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { filter, map } from 'ramda';

import { ExternalLink, Menu, MenuLabel, MenuContent, MenuItem } from '../../../../../components';
import flagBE from '../../../../../assets/flags/be.png';
import flagDE from '../../../../../assets/flags/de.png';
import flagES from '../../../../../assets/flags/es.png';
import flagFR from '../../../../../assets/flags/fr.png';
import flagIT from '../../../../../assets/flags/it.png';
import flagUS from '../../../../../assets/flags/us.png';
import config from '../../../../../config';

import css from './LocalizationMenu.css';

const LocalizationMenu = props => {
  const { className, intl } = props;
  const supportedLocalization = [
    {
      localization: 'fr',
      flag: flagFR,
      link: 'https://www.swimmy.fr',
    },
    {
      localization: 'es',
      flag: flagES,
      link: 'https://swimmy.es',
    },
    {
      localization: 'it',
      flag: flagIT,
      link: 'https://it.swimmy.com',
    },
    {
      localization: 'de',
      flag: flagDE,
      link: 'https://de.swimmy.com',
    },
    {
      localization: 'en',
      flag: flagUS,
      link: 'https://www.swimmy.com',
    },
    {
      localization: 'fr-BE',
      flag: flagBE,
      link: 'https://be.swimmy.com/',
    },
  ];
  const currentLocalization = filter(localization => localization.localization === config.locale, supportedLocalization)[0];
  const localizationToDisplay = filter(localization => localization.localization !== config.locale, supportedLocalization);
  const menuItems = map(localization => {
    const countryText = intl.formatMessage({ id: `LocalizationMenu.${localization.localization}Country` });

    return (
      <MenuItem key={`${localization.localization}localization`}>
        <ExternalLink href={localization.link} className={css.localizationLink}>
          <span className={css.menuItemBorder} />
          <img
            src={localization.flag}
            className={css.localizationFlag}
            alt={intl.formatMessage({id: 'LocalizationMenu.flagIcon'})}
          />
          <div className={css.localizationInfo}>
            <FormattedMessage id={`LocalizationMenu.${localization.localization}Localization`} />
            <span className={css.countryText}>{countryText}</span>
          </div>
        </ExternalLink>
      </MenuItem>
    );
  }, localizationToDisplay);

  return (
    <Menu className={className}>
      <MenuLabel className={css.menuLabelDesktop} isOpenClassName={css.menuIsOpen}>
        <img
          src={currentLocalization.flag}
          className={css.currentLocalizationFlag}
          alt={intl.formatMessage({id: 'LocalizationMenu.currentLocalizationFlag'})}
        />
      </MenuLabel>
      <MenuContent className={css.menuContent}>
        {menuItems}
      </MenuContent>
    </Menu>
  )
};

LocalizationMenu.propTypes = {
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(LocalizationMenu);
