import React, { Fragment } from 'react';
import { string } from 'prop-types';
import { intlShape } from 'react-intl';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { ListingCard, IconSpinner } from '../../../components';

import css from './SectionSimilarListings.module.css';

const SectionMorePools = props => {
  const {
    className,
    titleClassName,
    intl,
    listings,
    searchInProgress,
    searchListingsError,
    searchParams,
  } = props;
  const titleText = intl.formatMessage({ id: 'ListingPage.similarListingsTitle' });
  const errorText = intl.formatMessage({ id: 'ListingPage.morePoolsError' });
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <h2 className={titleClassName}>{titleText}</h2>
      {searchListingsError && <h2 className={css.errorText}>{errorText}</h2>}
      <div className={css.similarListingsContainer}>
        {searchInProgress ? (
          <IconSpinner />
        ) : (
          <Fragment>
            {listings.map(l => (
              <ListingCard
                className={css.similarListingCard}
                key={l.id.uuid}
                listing={l}
                renderSizes={cardRenderSizes}
                searchParams={searchParams}
              />
            ))}
          </Fragment>
        )}
      </div>
    </div>
  );
};

SectionMorePools.defaultProps = {
  className: null,
  titleClassName: null,
}

SectionMorePools.propTypes = {
  className: string,
  titleClassName: string,
  intl: intlShape.isRequired,
} 

const mapStateToProps = (state, props) => {
  const { searchParams } = state.SearchPage;


  return {
    searchParams,
  };
};

export default connect(mapStateToProps, null)(SectionMorePools);
