import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';

// import whySwimmyImgDesktop from '../../assets/why-swimmy.png';
// import whySwimmyImgMobile from '../../assets/why-swimmy-mobile.png';

import css from './WhoWeArePage.css';

const SectionWhySwimmy = (props) => {
  const { isMobile, intl } = props;
  const bottomText = intl.formatMessage({ id : "WhoWeArePage.whyBottomText" });
  const topTextImportant = intl.formatMessage({ id : "WhoWeArePage.whyTopTextImportant" });
  const imgClasses = classNames(css.imgContainer, css.whyImgContainer);

  return (
    <section className={css.section}>
      <div className={css.whyContent}>
        <div className={imgClasses}>
          {/*<img src={isMobile ? whySwimmyImgMobile : whySwimmyImgDesktop} />*/}
        </div>
        <span className={css.textContainer}>
          <FormattedMessage id='WhoWeArePage.whyTopText' values={{ important: <b>{topTextImportant}</b> }} />
        </span>
        <span className={css.textContainer}>{bottomText}</span>
      </div>
    </section>
  );
}

export default injectIntl(SectionWhySwimmy);
