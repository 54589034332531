import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import {
  IconArrow,
  NamedLink,
} from '../../components';

import { linksProps } from './listingsConfig';
import css from './LandingPage.css';

const { Money } = sdkTypes;

const QUERY = config.custom.eventQuery;

const ListingCard = props => {
  const { listing, intl } = props;
  const { id, image, title, price, provider } = listing;
  const { avatar, displayName } = provider;
  
  const priceAsMoney = new Money(price, config.currency);
  const formattedPrice = formatMoney(intl, priceAsMoney);
  const perUnitText = intl.formatMessage({ id: 'LandingPage.listingsPerUnit' });

  return (
    <NamedLink className={css.listingCardContainer} name="ListingPage" params={{ id, slug: 'featured-listing' }} >
      <img className={css.listingCardImg} src={image} alt={title} />
      <span className={css.listingCardTitle}>{title}</span>
      <div className={css.listingCardInfo}>
        <div className={css.listingCardPriceContainer}>
          <span className={css.listingCardPrice}>{formattedPrice}</span>
          <span className={css.listingCardPerUnit}>{perUnitText}</span>
        </div>
        <img className={css.listingCardAvatar} src={avatar} alt={displayName} />
      </div>
    </NamedLink>
  )
};

const SectionListings = props => {
  const { intl } = props;

  const titleHighlight = (
    <span className={css.titleHightlight}>
      <br/>{intl.formatMessage({ id: 'LandingPage.listingsTitleHighlight' })}
    </span>)
  const title = (<FormattedMessage id='LandingPage.listingsTitle' values={{ highlight: titleHighlight }} />);
  const subtitle = intl.formatMessage({ id: 'LandingPage.listingsSubtitle' });
  const ctaText = intl.formatMessage({ id: 'LandingPage.listingsCTA' });

  return (
    <div className={css.sectionListings}>
      <span className={css.listingsTitle}>{title}</span>
      <span className={css.listingsSubtitle}>{subtitle}</span>
      <div className={css.listingsSlider}>
        <ListingCard listing={linksProps.listing1} intl={intl} />
        <ListingCard listing={linksProps.listing2} intl={intl} />
        <ListingCard listing={linksProps.listing3} intl={intl} />
      </div>
      <NamedLink className={css.listingsCTA} name="SearchPage" to={{ search: QUERY }}>
        <span className={css.buttonText}>{ctaText}</span>
        <IconArrow className={css.buttonIcon} direction='right' />
      </NamedLink>
    </div>
  )
}

export default injectIntl(SectionListings);