import React, { Fragment, useState } from 'react';
import { bool, func, number, object, oneOfType, shape, string } from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { path, pathOr } from 'ramda';
import classNames from 'classnames';
import {
  getEstimatedBreakdownMaybe,
  getPricingDataFromEntity
} from '@swimmy_/helpers';

import routeConfiguration from '../../routeConfiguration';
import { CUSTOMER } from '../../constants/booking';
import { LISTING_STATE_CLOSED } from '../../constants/listing';
import { CHECKOUT_EXTRA_PAGE, CHECKOUT_PAGE } from '../../constants/page';
import { types as sdkTypes } from '../../util/sdkLoader';
import { propTypes } from '../../util/types';
import { withViewport } from '../../util/contextHelpers';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { createSlug, parse } from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { formatFormValuesAsBookingData, getInitialValuesFromSearch } from '../../util/booking';
import { withCustomerFee } from '../../util/pricing';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { fetchTimeSlots } from '../../ducks/listing.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  IconReviewStar,
  InstantBookingBadge,
  ModalInMobile,
  PriceBreakdown,
  PrimaryButton,
} from '../../components';

import {
  MODAL_BREAKPOINT,
  closeBookModal,
  openBookModal,
  getInitialValuesFromTransaction,
} from './BookingPanel.helpers';
import BookingForm from './BookingForm/BookingForm';
import css from './BookingPanel.module.css';
import { isInstantBooking } from '../../util/transactions';

const { Money } = sdkTypes;

const TODAY = new Date();

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const BookingPanel = (props) => {
  const {
    rootClassName,
    className,
    callSetInitialValues,
    currentUser,
    intl,
    isOwnListing,
    listing,
    monthlyTimeSlots,
    onFetchTimeSlots,
    onInitializeCardPaymentData,
    onManageDisableScrolling,
    rating,
    searchParams,
    transaction,
    viewport,
    withoutBorder,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const [formValues, setFormValues] = useState({});
  const {
    adults = 1,
    bookingDate,
    children = 0,
    endTime,
    infants = 0,
    startTime,
  } = formValues;
  const timeZone = listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
  const isMobile = viewport.width <= MODAL_BREAKPOINT;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingForm = hasListingState && !isClosed;
  const isBook = !!parse(location.search).book;
  const publicData = pathOr({}, ['attributes', 'publicData'], listing);
  const price = pathOr({}, ['attributes', 'price'], listing);
  const { paidExtrasData = [], pricePerExtraGuest, pricingBaseNumberOfPeople } = publicData;
  const pricingData = getPricingDataFromEntity(listing);
  const canEstimatePrice = bookingDate && startTime && endTime && startTime !== endTime && adults >= 1;
  const bookingData = canEstimatePrice && {
    ...formatFormValuesAsBookingData(formValues),
    price,
    pricePerExtraGuest,
    pricingBaseNumberOfPeople,
    publicData,
  };

  const handleSubmit = () => {
    const initialValues = {
      listing,
      transaction,
      bookingData,
      bookingDates: {
        bookingStart: bookingData.bookingStart.toDate(),
        bookingEnd: bookingData.bookingEnd.toDate(),
      },
      confirmPaymentError: null,
    };
    const saveToSessionStorage = !currentUser;
    const routes = routeConfiguration();
    const listingHasExtras = paidExtrasData.length > 0;
    const nextRouteName = listingHasExtras ? CHECKOUT_EXTRA_PAGE : CHECKOUT_PAGE;
    const { setInitialValues } = findRouteByRouteName(nextRouteName, routes);
    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);
    if (!listingHasExtras) {
      onInitializeCardPaymentData();
    }
    history.push(
      createResourceLocatorString(
        nextRouteName,
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  };
  const estimatedTransaction = canEstimatePrice && getEstimatedBreakdownMaybe({
    ...pricingData,
    bookingStart: bookingData.bookingStart,
    bookingEnd: bookingData.bookingEnd,
    quantity1: bookingData.quantity.adults,
    quantity2: bookingData.quantity.children,
    quantityHours: bookingData.quantityHours,
    publicData,
    protectedData: pricingData,
    canApplyCommissionDiscountOnBigBookings: true,
    isTransactionHourlyPlan: true,
  });
  const { payinTotal } = pathOr({}, ['attributes'], estimatedTransaction);
  const payinTotalAsMoney = canEstimatePrice && new Money(payinTotal.amount, payinTotal.currency);
  const formattedEstimatedPrice = canEstimatePrice ? formatMoney(intl, payinTotalAsMoney, true) : '';
  const formInitialValues = transaction ? getInitialValuesFromTransaction(transaction) : getInitialValuesFromSearch(searchParams);
  const instantBookingFrom = path(['attributes', 'publicData', 'instantBookingFrom'], listing);
  const applyInstantBooking = isInstantBooking(instantBookingFrom, estimatedTransaction);
  const headlineText = intl.formatMessage({ id: `BookingPanel.${isOwnListing ? 'ownListing' : isClosed ? 'closed' : 'headline'}` });
  const priceUnitText = intl.formatMessage({ id: 'Marketplace.perHourPerUnitShort' });
  const bookButtonText = intl.formatMessage({ id: `BookingPanel.${canEstimatePrice ? 'book' : 'bookWithoutData'}` });
  const noPaymentYetText = intl.formatMessage({ id: 'BookingPanel.BookingForm.noPaymentYet' });
  const classes = classNames(css.root, className);
  const containerClasses = classNames(css.modalContainer, { [css.modalContainerBorder]: !withoutBorder });

  return (
    <div className={classes}>
      <ModalInMobile
        hiddenClassName={css.modalInMobileHidden}
        containerClassName={containerClasses}
        contentClassName={css.modalContent}
        id="BookingFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
      <div className={css.header}>
        <div className={css.priceWrapper}>
          <div className={css.basePriceWrapper}>
            <span className={css.price}>{formatMoney(intl, withCustomerFee(price), true)}</span>
            <span className={css.priceUnit}>{priceUnitText}</span>
          </div>
        </div>
        {rating > 4 && (
          <div className={css.reviewWrapper}>
            <IconReviewStar className={css.iconReviewStar} isFilled />
            <span className={css.rating}>{rating}</span>
          </div>
        )}
      </div>
        {showBookingForm ? (
          <Fragment>
            <BookingForm
              className={css.bookingForm}
              exposeFormValues={setFormValues}
              formattedPrice={formattedEstimatedPrice}
              formId="bookingForm"
              initialValues={formInitialValues}
              intl={intl}
              onSubmit={handleSubmit}
              publicData={publicData}
              submitButtonWrapperClassName={css.submitButtonWrapper}
              price={price}
              listingId={listing.id}
              isOwnListing={isOwnListing}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
              endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
              timeZone={timeZone}
            />
            {canEstimatePrice && (
              <Fragment>
                {applyInstantBooking && <InstantBookingBadge className={css.instantBookingBadge} />}
                <span className={css.noPaymentYet}>{noPaymentYetText}</span>
                <PriceBreakdown
                  className={css.priceBreakdown}
                  transaction={estimatedTransaction}
                  userRole={CUSTOMER}
                  expandable
                />
              </Fragment>
            )}
          </Fragment>
        ) : null}
      </ModalInMobile>
      {isMobile && (
        <div className={css.buttonContainer}>
          <span className={css.book}>{headlineText}</span>
          <PrimaryButton
            className={css.bookButton}
            disabled={isOwnListing || !showBookingForm}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            {bookButtonText}
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  isOwnListing: false,
  rating: null,
  searchParams: {},
  monthlyTimeSlots: null,
  transaction: null,
  withoutBorder: false,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  callSetInitialValues: func.isRequired,
  currentUser: propTypes.currentUser,
  intl: intlShape.isRequired,
  titleClassName: string,
  isOwnListing: bool,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  monthlyTimeSlots: object,
  onInitializeCardPaymentData: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  rating: number,
  scrollingDisabled: bool.isRequired,
  searchParams: object,
  transaction: propTypes.transaction,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  withoutBorder: bool,
};

const mapStateToProps = (state) => {
  const { monthlyTimeSlots } = state.listing;
  const { currentUser } = state.user;

  return {
    currentUser,
    monthlyTimeSlots,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withViewport,
  injectIntl
)(BookingPanel);
