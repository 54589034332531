import React, { useEffect } from 'react';
import { arrayOf, number, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';

import { withViewport } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { DATE } from '../../util/search';

import { getDaySize } from './FieldDateInput.helpers';
import DatePicker from './DatePicker/DatePicker';
import css from './FieldDateInput.module.css';

const FieldDateInput = (props) => {
  const {
    activeField,
    className,
    finalFormApi,
    formValues,
    intl,
    label,
    minimumHoursPerBooking,
    name,
    nextFieldName,
    placeholder,
    viewport,
    ...rest
  } = props;
  const date = formValues[name];
  useEffect(() => {
    if (activeField === name) return;
    finalFormApi.blur(name);
  }, [activeField])
  const handleInputClick = () => {
    if (activeField === name) return;
    finalFormApi.focus(name);
  };
  const isActive = activeField === name;
  const daySize = viewport && getDaySize(viewport.width);
  const labelText = label || intl.formatMessage({ id: 'FieldDateInput.label' });
  const placeholderText = placeholder || intl.formatMessage({ id: 'FieldDateInput.placeholder' });
  const inputValueText = date ? moment(date).format('dddd D MMM') : placeholderText;
  const classes = classNames(css.root, className);
  const valueClasses = classNames(css.value, {
    [css.placeholder]: !date,
    [css.active]: isActive,
  });
  const datePickerClasses = classNames(css.datePicker, { [css.datePickerHidden]: !isActive });
  
  return (
    <div className={classes}>
      <label className={css.label}>{labelText}</label>
      <button
        className={css.button}
        type="button"
        onClick={handleInputClick}
      >
        <span className={valueClasses}>{inputValueText}</span>
      </button>
      <Field
        id={name}
        name={name}
        render={({ input, meta }) => (
          <DatePicker
            className={datePickerClasses}
            daySize={daySize}
            input={input}
            meta={meta}
            onOutsideClick={() => finalFormApi.blur(name)}
            {...rest}
          />
        )}
      />
    </div>
  )
};

FieldDateInput.defaultProps = {
  className: null,
  activeField: undefined,
  label: null,
  minimumHoursPerBooking: 1,
  name: DATE,
  nextFieldName: null,
  placeholder: null,
};

FieldDateInput.propTypes = {
  className: string,
  activeField: string,
  finalFormApi: object.isRequired,
  formValues: object.isRequired,
  intl: intlShape.isRequired,
  label: string,
  minimumHoursPerBooking: number,
  name: string,
  nextFieldName: string,
  placeholder: string,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default withViewport(injectIntl(FieldDateInput));
