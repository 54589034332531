import React from 'react';
import { array, func, object, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { length } from 'ramda';

import { SecondaryButton } from '../../../../components';

import {
  dayIsUnavailableInExceptions,
  dayIsAvailableInPlan,
  getAvailabilityExceptionsToDelete,
  getDayExceptions
} from '../../CalendarPage.helpers';
import css from './FullAvailability.module.css';

const FullAvailability = (props) => {
  const {
    className,
    availabilityExceptions,
    availabilityPlan,
    intl,
    listingId,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    selectedDates,
  } = props;
  const startTime = '00:00';
  const endTime = '00:00';
  const handleBlockClick = () => {
    const params = { listingId, selectedDates, seats: 0, startTime, endTime };
    onAddAvailabilityException(params);
  };
  const handleResetClick = () => {
    const params = { selectedDates, startTime, endTime };
    onDeleteAvailabilityException(getAvailabilityExceptionsToDelete(params, availabilityExceptions));
  };
  const currentDate = selectedDates[0];
  const canBeDisabled = length(selectedDates) === 1;
  const dayExceptions = getDayExceptions(currentDate, availabilityExceptions)
  const hasExceptions = length(dayExceptions) > 0;
  const isAvailableInPlan = dayIsAvailableInPlan(currentDate, availabilityPlan);
  const isUnavailableInExceptions = dayIsUnavailableInExceptions(dayExceptions);
  const setUnvailableText = intl.formatMessage({ id: 'CalendarPage.setUnavailable' });
  const resetText = intl.formatMessage({ id: 'CalendarPage.reset' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <SecondaryButton
        className={css.secondaryButton}
        onClick={handleBlockClick}
        disabled={canBeDisabled && (!isAvailableInPlan || !!isUnavailableInExceptions)}
      >
        {setUnvailableText}
      </SecondaryButton>
      <SecondaryButton
        className={css.secondaryButton}
        onClick={handleResetClick}
        disabled={canBeDisabled &&!hasExceptions}
      >
        {resetText}
      </SecondaryButton>
    </div>
  )
}

FullAvailability.defaultProps = {
  className: null,
};

FullAvailability.propTypes = {
  className: string,
  availabilityExceptions: array.isRequired,
  availabilityPlan: object.isRequired,
  intl: intlShape.isRequired,
  listingId: string.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  selectedDates: array.isRequired,
};

export default FullAvailability;