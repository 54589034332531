import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  ExpandableBox,
  ExternalLink,
  IconArrow,
} from '../../components';

import css from './LandingPage.css';
import config from '../../config';

const SectionFaq = props => {
  const { intl } = props;

  const titleHighlight = (
    <span className={css.titleHightlight}>
      <br/>{intl.formatMessage({ id: 'LandingPage.faqTitleHighlight' })}
    </span>)
  const title = (<FormattedMessage id='LandingPage.faqTitle' values={{ highlight: titleHighlight }} />);
  const question1 = intl.formatMessage({ id: 'LandingPage.question1' });
  const question2 = intl.formatMessage({ id: 'LandingPage.question2' });
  const question3 = intl.formatMessage({ id: 'LandingPage.question3' });
  const answer1 = intl.formatMessage({ id: 'LandingPage.answer1' });
  const answer2 = intl.formatMessage({ id: 'LandingPage.answer2' });
  const answer3 = intl.formatMessage({ id: 'LandingPage.answer3' });
  const ctaText = intl.formatMessage({ id: 'LandingPage.faqCTA' });

  return (
    <div className={css.sectionFaq}>
      <span className={css.sectionTitle}>{title}</span>
      <div className={css.faqContent}>
        <ExpandableBox className={css.faqExpandableBox}>
          <span className={css.faqExpandableTitle}>{question1}</span>
          <span className={css.faqExpandableContent}>{answer1}</span>
        </ExpandableBox>
        <ExpandableBox className={css.faqExpandableBox}>
          <span className={css.faqExpandableTitle}>{question2}</span>
          <span className={css.faqExpandableContent}>{answer2}</span>
        </ExpandableBox>
        <ExpandableBox className={css.faqExpandableBox}>
          <span className={css.faqExpandableTitle}>{question3}</span>
          <span className={css.faqExpandableContent}>{answer3}</span>
        </ExpandableBox>
      </div>
      <ExternalLink href={config.custom.helpCenterUrl} openInSameWindow className={css.faqCTA}>
        <span className={css.buttonText}>{ctaText}</span>
        <IconArrow className={css.buttonIcon} direction='right' />
      </ExternalLink>
    </div>
  )
}

export default injectIntl(SectionFaq);
