import Decimal from 'decimal.js';
// @ts-ignore
import {types as sdkTypes} from 'sharetribe-flex-sdk';

import {CUSTOMER_COMMISSION_PERCENTAGE} from '../constants/pricing';
import {IMoney} from '../utils/interface';
import {unitDivisor, convertMoneyToNumber, convertUnitToSubUnit} from '../utils/currency';

import {
    TRANSITION_REQUEST_PAYMENT,
    TX_TRANSITION_ACTOR_CUSTOMER,
} from './types';
import {
    getLineItems,
    calculateTotalForCustomer,
    constructValidLineItems,
    calculateTotalForProvider
} from './lineItemHelpers';

const {Money, UUID} = sdkTypes;

const estimatedCustomerCommission = (unitsTotal: any, percentage: number) => {
    const numericTotal = convertMoneyToNumber(unitsTotal);
    // Divide the commission percentage with 100 to get the
    // commission multiplier and use precision of 2 when
    // rounding the sub units.
    const numericCommission = new Decimal(percentage)
        .div(100)
        .times(numericTotal)
        .toDP(2)
        .toNumber();

    return new Money(
        convertUnitToSubUnit(numericCommission, unitDivisor(unitsTotal.currency)),
        unitsTotal.currency,
    );
};

interface IBookingParams {
    bookingStart: any
    bookingEnd: any
    price: IMoney
    quantity1: number
    quantity2: number
    pricePerExtraGuest: number
    pricingBaseNumberOfPeople: number
    publicData: any
    quantityHours: number
    paidExtrasData: any
    extras: any
    protectedData: any
    canApplyCommissionDiscountOnBigBookings: boolean
    isTransactionHourlyPlan: boolean
}


// When we cannot speculatively initiate a transaction (i.e. logged
// out), we must estimate the booking breakdown. This function creates
// an estimated transaction object for that use case.
export const getEstimatedBreakdownMaybe = (bookingParams: IBookingParams) => {
    if (!bookingParams) return null;

    const {
        bookingStart,
        bookingEnd,
        price,
        quantity1,
        quantity2,
        pricePerExtraGuest,
        pricingBaseNumberOfPeople,
        publicData,
        quantityHours,
        paidExtrasData,
        protectedData,
        extras,
        canApplyCommissionDiscountOnBigBookings,
        isTransactionHourlyPlan
    } = bookingParams;
    const quantityIfUsingUnits = Number.isInteger(quantity1) && Number.isInteger(quantity2);
    const canEstimatePrice = bookingStart && bookingEnd && price && quantityIfUsingUnits;
    if (!canEstimatePrice) {
        return null;
    }

    const now = new Date();
    const lineItems = constructValidLineItems(getLineItems({
        amountPriceSlot: price.amount,
        pricePerExtraGuest,
        pricingBaseNumberOfPeople,
        quantity: {adults: quantity1, children: quantity2},
        quantityHours,
        startDate: bookingStart,
        currency: price.currency,
        extras,
        paidExtrasData,
        canApplyCommissionDiscountOnBigBookings,
        isTransactionHourlyPlan
    }, publicData));
    const payInTotal = calculateTotalForCustomer(lineItems);
    const payoutTotal = calculateTotalForProvider(lineItems);
    return {
        id: new UUID('estimated-transaction'),
        type: 'transaction',
        startAt: bookingStart,
        endAt: bookingEnd,
        attributes: {
            processVersion: 2,
            createdAt: now,
            lastTransitionedAt: now,
            lastTransition: TRANSITION_REQUEST_PAYMENT,
            payinTotal: payInTotal,
            payoutTotal,
            lineItems,
            transitions: [
                {
                    createdAt: now,
                    by: TX_TRANSITION_ACTOR_CUSTOMER,
                    transition: TRANSITION_REQUEST_PAYMENT,
                },
            ],
            protectedData,
        },
        booking: {
            id: new UUID('estimated-booking'),
            type: 'booking',
            attributes: {
                start: bookingStart,
                end: bookingEnd,
            },
        },
    };
};
