import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import {
  DayPickerSingleDateController,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from 'react-dates';
import classNames from 'classnames';

import config from '../../../../../../config';
import { IconArrowHead } from '../../../../../../components';

import css from './DatePicker.module.css';

const HORIZONTAL_ORIENTATION = 'horizontal';

const Nav = (props) => {
  const { direction } = props;
  const classes = classNames(css.navButton, {
    [css.previous]: direction === 'left',
    [css.next]: direction === 'right',
  })

  return (
    <button className={classes}>
      <IconArrowHead className={css.iconArrow} direction={direction} />
    </button>
  )
}

const propTypes = {
  // example props for the demo
  autoFocus: PropTypes.bool,
  initialDate: momentPropTypes.momentObj,
  showInput: PropTypes.bool,

  keepOpenOnDateSelect: PropTypes.bool,
  isOutsideRange: PropTypes.func,
  isDayBlocked: PropTypes.func,
  isDayHighlighted: PropTypes.func,

  // DayPicker props
  enableOutsideDays: PropTypes.bool,
  numberOfMonths: PropTypes.number,
  withPortal: PropTypes.bool,
  initialVisibleMonth: PropTypes.func,
  renderCalendarInfo: PropTypes.func,

  navPrev: PropTypes.node,
  navNext: PropTypes.node,
  renderNavPrevButton: PropTypes.func,
  renderNavNextButton: PropTypes.func,

  onPrevMonthClick: PropTypes.func,
  onNextMonthClick: PropTypes.func,
  onOutsideClick: PropTypes.func,
  renderCalendarDay: PropTypes.func,
  renderDayContents: PropTypes.func,

  // i18n
  monthFormat: PropTypes.string,

  isRTL: PropTypes.bool,
};

const defaultProps = {
  // example props for the demo
  autoFocus: false,
  initialDate: null,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  isDayBlocked: () => false,
  isOutsideRange: day => {
    const endOfRange = config.dayCountAvailableForBooking - 1;
    return (
      !isInclusivelyAfterDay(day, moment()) ||
      !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
    );
  },
  isDayHighlighted: () => false,
  enableOutsideDays: false,

  // calendar presentation and interaction related props
  orientation: HORIZONTAL_ORIENTATION,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,
  hideKeyboardShortcutsPanel: true,

  // navigation related props
  navPrev: null,
  navNext: null,
  renderNavPrevButton: null,
  renderNavNextButton: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // internationalization
  monthFormat: 'MMMM YYYY',
};

const DatePicker = (props) => {
  const { className, autoFocus, initialDate, input, ...rest } = props;
  const [focused, setFocused] = useState(true);
  const [date, setDate] = useState(initialDate);
  const onDateChange = (date) => {
    setDate(date);
    input.onChange(date);
  };
  useEffect(() => {
    onDateChange(input.value);
  }, [input.value])
  const onFocusChange = ({ focused }) => setFocused(focused);
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <DayPickerSingleDateController
        {...rest}
        date={date ? moment(date) : null}
        daySize={48}
        focused={focused}
        navPrev={<Nav direction="left" />}
        navNext={<Nav direction="right" />}
        onDateChange={onDateChange}
        onFocusChange={onFocusChange}
      />
    </div>
  );
}

DatePicker.propTypes = propTypes;
DatePicker.defaultProps = defaultProps;

export default DatePicker;