import React, { Component, useState } from 'react';
import { func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError, ExpandingTextarea } from '../../components';

import css from './FieldTextInput.css';
import informationIcon from '../../assets/information.png';
import eyeIcon from "../../assets/eye.svg";
import eyeOffIcon from "../../assets/eyeOff.svg";
import ReactTooltip from 'react-tooltip';

const CONTENT_MAX_LENGTH = 5000;

const FieldTextInputComponent = props => {
  const {
    rootClassName,
    className,
    inputRootClass,
    inputClassName,
    customErrorText,
    id,
    label,
    type,
    input,
    meta,
    informationTooltipContent,
    onUnmount,
    ...rest
  } = props;

  const [passwordShown, setPasswordShown] = useState(false);

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;
  const isTextarea = type === 'textarea';
  const isPassword = type === 'password';

  const HandlePasswordShown = () => {
    setPasswordShown(!passwordShown);
  };

  const errorText = customErrorText || error;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!customErrorText || !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error: errorText };

  const inputClasses =
    inputRootClass ||
    classNames(
      {
        [css.inputError]: hasError,
        [css.textarea]: isTextarea,
      },
      inputClassName
    );
  const inputProps = isTextarea
    ? { className: inputClasses, id, rows: 1, maxLength: CONTENT_MAX_LENGTH, ...input, ...rest }
    : isPassword
    ? { className: inputClasses, id, type: passwordShown ? "text" : "password", ...input, ...rest }
    : { className: inputClasses, id, type, ...input, ...rest };

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {label && (
        <div className={css.labelContainer}>
          <label htmlFor={id}>{label}</label>
          {informationTooltipContent && (
            <div className={css.information}>
              <a data-tip={informationTooltipContent}>
                <img src={informationIcon} alt='information-about-children' className={css.informationIcon} />
              </a>
              <ReactTooltip place="top" type="info" effect="solid" className={css.informationTooltip} />
            </div>
          )}
        </div>
      )}
      {isTextarea ? <ExpandingTextarea {...inputProps} />
        : isPassword ? (
          <div className={css.passwordContainer}>
            <input {...inputProps} />
            <img
              src={passwordShown ? eyeOffIcon : eyeIcon}
              alt="Show/Hide"
              className={passwordShown ? css.eyeOffIcon : css.eyeIcon}
              onClick={HandlePasswordShown}
            />
          </div>
        )
        : <input {...inputProps} />}
      <ValidationError fieldMeta={fieldMeta} />
    </div>
  );
}

FieldTextInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  inputClassName: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
};

FieldTextInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,
  inputClassName: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Either 'textarea' or something that is passed to the input element
  type: string.isRequired,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
  }).isRequired,
  meta: object.isRequired,
};

class FieldTextInput extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldTextInputComponent} {...this.props} />;
  }
}

export default FieldTextInput;
