import React from 'react';
import { bool, func, string, shape } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { propTypes } from '../../util/types';
import { requiredFieldArrayCheckbox } from '../../util/validators';
import { securityOptions, securityRulesURL } from '../../marketplace-custom-config';
import {
  ExternalLink,
  Form,
  FieldCheckboxGroup,
  PrimaryButton,
} from '../../components';

import css from './EditListingSecurityForm.module.css';

const EditListingSecurityFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        ready,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        intl,
      } = fieldRenderProps;

      const securityRulesLink = (
        <ExternalLink href={securityRulesURL}>
          <FormattedMessage id="EditListingSecurityForm.securityRulesLink" />
        </ExternalLink>
      );
      const securityLabel = intl.formatMessage({ id: 'EditListingSecurityForm.security.label' });
      const securityInfo = (
        <p className={css.securityInfo}>
            <FormattedMessage id="EditListingSecurityForm.security.info" values={{ link: securityRulesLink }} />
        </p>
      );
      const securityRequiredMessage = intl.formatMessage({
        id: 'EditListingSecurityForm.security.requiredMessage',
      });

      const { publishListingError, updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id='EditListingSecurityForm.updateFailed' />
        </p>
      ) : null;
      const publishListingFailed = publishListingError ? (
        <p className={css.error}>
          <FormattedMessage id="Marketplace.publishListingFailed" />
        </p>
      ) : null;
      const showListingFailed = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="Marketplace.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {publishListingFailed}
          {showListingFailed}
          <FieldCheckboxGroup
            className={css.featuresItem}
            id="security"
            name="security"
            label={securityLabel}
            options={securityOptions}
            validate={requiredFieldArrayCheckbox(securityRequiredMessage)}
          />
          {securityInfo}
          <div className={css.buttonContainer}>
            <PrimaryButton
              className={css.submitButton}
              type='submit'
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

EditListingSecurityFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  fetchErrors: null,
};

EditListingSecurityFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  intl: intlShape.isRequired,
};

const EditListingSecurityForm = compose(injectIntl)(EditListingSecurityFormComponent);

export default EditListingSecurityForm;
