import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconEmail.css';

const IconEmail = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.16684 3.16663H15.8335C16.7043 3.16663 17.4168 3.87913 17.4168 4.74996V14.25C17.4168 15.1208 16.7043 15.8333 15.8335 15.8333H3.16684C2.296 15.8333 1.5835 15.1208 1.5835 14.25V4.74996C1.5835 3.87913 2.296 3.16663 3.16684 3.16663Z" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.4168 4.75L9.50017 10.2917L1.5835 4.75" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

IconEmail.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconEmail.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconEmail;
