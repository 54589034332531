import React from 'react';
import { injectIntl } from 'react-intl';

import config from '../../config';
import {
  IconArrow,
  NamedLink,
} from '../../components';
import evjfImg from '../../assets/pictures/evjf.png';
import birthdayImg from '../../assets/pictures/birthday.png';
import babyShowerImg from '../../assets/pictures/babyShower.png';
import poolPartyImg from '../../assets/pictures/poolParty.png';
import teamBuildingImg from '../../assets/pictures/teamBuilding.png';

import css from './LandingPage.css';

const QUERY = config.custom.eventQuery + '&pub_policyEvent=true';

const getEventQueryRedirection = (event) => {
  const baseQuery = `${QUERY}${event && `&event=${event}`}`

  switch (event) {
    case 'birthday':
    case 'pool-party':
      return baseQuery + '&pub_policyMusic=true';
    default:
      return baseQuery;
    
  }
};

const EventCard = props => {
  const { query, image, title } = props;

  return (
    <NamedLink className={css.eventCardContainer} name="SearchPage" to={{ search: query }}>
      <span className={css.eventCardTitle}>{title}</span>
      <IconArrow className={css.eventCardArrow} direction='right' />
      <img className={css.eventCardImg} src={image} alt={title} />
    </NamedLink>
  )
};

const SectionEvent = props => {
  const { intl } = props;

  const title = intl.formatMessage({ id: 'LandingPage.eventTitle' });;
  const subtitle = intl.formatMessage({ id: 'LandingPage.eventSubtitle' });
  const evjfTitle = intl.formatMessage({ id: 'LandingPage.eventEvjf' });
  const birthdayTitle = intl.formatMessage({ id: 'LandingPage.eventBirthday' });
  const babyShowerTitle = intl.formatMessage({ id: 'LandingPage.eventBabyShower' });
  const poolPartyTitle = intl.formatMessage({ id: 'LandingPage.eventPoolParty' });
  const teamBuildingTitle = intl.formatMessage({ id: 'LandingPage.eventTeamBuilding' });
  const bottomText = intl.formatMessage({ id: 'LandingPage.eventBottomText' });
  const ctaText = intl.formatMessage({ id: 'LandingPage.eventCTA' });

  return (
    <div className={css.sectionEvent}>
      <div className={css.eventContainer}>
        <span className={css.eventTitle}>{title}</span>
        <span className={css.eventSubtitle}>{subtitle}</span>
        <div className={css.eventSlider}>
          <EventCard title={evjfTitle} image={evjfImg} query={getEventQueryRedirection('EVJF')} />
          <EventCard title={birthdayTitle} image={birthdayImg} query={getEventQueryRedirection('birthday')} />
          <EventCard title={babyShowerTitle} image={babyShowerImg} query={getEventQueryRedirection('baby-shower')} />
          <EventCard title={poolPartyTitle} image={poolPartyImg} query={getEventQueryRedirection('pool-party')} />
          <EventCard title={teamBuildingTitle} image={teamBuildingImg} query={getEventQueryRedirection('team-building')} />
        </div>
        <span className={css.eventSubtitle}>{bottomText}</span>
        <NamedLink className={css.eventCTA} name="SearchPage" to={{ search: QUERY }}>
          <span className={css.buttonText}>{ctaText}</span>
          <IconArrow className={css.buttonIcon} direction='right' />
        </NamedLink>
      </div>

      <div className={css.eventContainerDesktop}>
        <div className={css.eventRow1}>
          <div className={css.eventTitleContainer}>
            <span className={css.eventTitle}>{title}</span>
            <span className={css.eventSubtitle}>{subtitle}</span>
          </div>
          <EventCard title={poolPartyTitle} image={poolPartyImg} query={getEventQueryRedirection('pool-party')} />
          <EventCard title={teamBuildingTitle} image={teamBuildingImg} query={getEventQueryRedirection('team-building')} />
        </div>
        <div className={css.eventRow2}>
          <EventCard title={evjfTitle} image={evjfImg} query={getEventQueryRedirection('EVJF')} />
          <EventCard title={birthdayTitle} image={birthdayImg} query={getEventQueryRedirection('birthday')} />
          <EventCard title={babyShowerTitle} image={babyShowerImg} query={getEventQueryRedirection('baby-shower')} />
        </div>
        <span className={css.eventSubtitleBottom}>{bottomText}</span>
        <NamedLink className={css.eventCTA} name="SearchPage" to={{ search: QUERY }}>
          <span className={css.buttonText}>{ctaText}</span>
          <IconArrow className={css.buttonIcon} direction='right' />
        </NamedLink>
      </div>
    </div>
  )
}

export default injectIntl(SectionEvent);