import config from '../../config';
import evjfImg from '../../assets/pictures/evjf.png';
import birthdayImg from '../../assets/pictures/birthday.png';
import babyShowerImg from '../../assets/pictures/babyShower.png';
import poolPartyImg from '../../assets/pictures/poolParty.png';
import teamBuildingImg from '../../assets/pictures/teamBuilding.png';

export const getEventProps = (eventType) => {
  switch (eventType) {
    case 'team-building':
      return {
        alt: eventType,
        id: 'EventContactInfo.teamBuildingInfo',
        img: teamBuildingImg,
        mail: config.custom.teamBuildingMail,
      };

    case 'birthday':
      return {
        alt: eventType,
        id: 'EventContactInfo.birthdayInfo',
        img: birthdayImg,
        mail: config.custom.birthdayMail,
      };

    case 'pool-party':
      return {
        alt: eventType,
        id: 'EventContactInfo.commonInfo',
        img: poolPartyImg,
        mail: config.custom.mailContact,
      };

    case 'EVJF':
      return {
        alt: eventType,
        id: 'EventContactInfo.commonInfo',
        img: evjfImg,
        mail: config.custom.mailContact,
      };

    case 'baby-shower':
      return {
        alt: eventType,
        id: 'EventContactInfo.commonInfo',
        img: babyShowerImg,
        mail: config.custom.mailContact,
      };

    default:
      return {
        alt: 'happy women',
        id: 'EventContactInfo.commonInfo',
        img: birthdayImg,
        mail: config.custom.mailContact,
      };
  }
}
