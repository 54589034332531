import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconHomePin.module.css';

const IconHomePin = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M9 13V8L12 6L15 8V13H13V10H11V13ZM12 19.35Q15.05 16.55 16.525 14.262Q18 11.975 18 10.2Q18 7.475 16.262 5.737Q14.525 4 12 4Q9.475 4 7.737 5.737Q6 7.475 6 10.2Q6 11.975 7.475 14.262Q8.95 16.55 12 19.35ZM12 22Q7.975 18.575 5.988 15.637Q4 12.7 4 10.2Q4 6.45 6.413 4.225Q8.825 2 12 2Q15.175 2 17.587 4.225Q20 6.45 20 10.2Q20 12.7 18.013 15.637Q16.025 18.575 12 22Z"/>
    </svg>
  );
};

IconHomePin.defaultProps = {
  rootClassName: null,
  className: null,
};

IconHomePin.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconHomePin;
