import React from 'react';
import { string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { pathOr } from 'ramda';
import { getBasePricePerHourWithVariation, getWeekdayPricingVariation } from '@swimmy_/helpers';

import { formatMoney } from '../../../util/currency';
import { propTypes } from '../../../util/types';
import { withCustomerFee } from '../../../util/pricing';
import { DataWithLabel } from '../../../components';

import css from './SectionPricing.module.css';

const SectionPricing = (props) => {
  const {
    className,
    titleClassName,
    intl,
    listing,
  } = props;
  const { price, publicData } = pathOr({}, ['attributes'], listing);
  const { minimumHoursPerBooking = 1, pricingVariationByPeriod } = publicData;
  const formattedBasePrice = formatMoney(intl, withCustomerFee(price), true);
  const pricingVariationWeekday = getWeekdayPricingVariation(pricingVariationByPeriod);
  const priceWithVariation = getBasePricePerHourWithVariation(listing.attributes, pricingVariationWeekday);
  const formattedPriceWithVariation = formatMoney(intl, withCustomerFee(priceWithVariation), true);
  const titleText = intl.formatMessage({ id: 'ListingPage.SectionPricing.title' });
  const basicPriceLabelText = intl.formatMessage({ id: 'ListingPage.SectionPricing.basicPriceLabel' });
  const weekdayPriceLabelText = intl.formatMessage({ id: 'ListingPage.SectionPricing.weekdayPriceLabel' });
  const basicPriceText = price && intl.formatMessage({ id: 'Marketplace.priceWithUnit' }, { price: formattedBasePrice });
  const weekdayPriceText = intl.formatMessage({ id: 'Marketplace.priceWithUnit' }, { price: formattedPriceWithVariation });
  const minimumHoursPerBookingLabelText = intl.formatMessage({ id: 'ListingPage.SectionPricing.minimumHoursPerBookingLabel' });
  const minimumHoursPerBookingText = intl.formatMessage({ id: 'ListingPage.SectionPricing.minimumHoursPerBooking' }, { count: minimumHoursPerBooking });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <h2 className={titleClassName}>{titleText}</h2>
      <div className={css.pricingContainer}>
        <DataWithLabel
          className={css.dataWithLabel}
          labelClassName={css.label}
          label={basicPriceLabelText}
          value={basicPriceText}
        />
        {priceWithVariation.amount < price.amount && (
          <DataWithLabel
            className={css.dataWithLabel}
            labelClassName={css.label}
            label={weekdayPriceLabelText}
            value={weekdayPriceText}
          />
        )}
        {minimumHoursPerBooking > 1 && (
          <DataWithLabel
            className={css.dataWithLabel}
            labelClassName={css.label}
            label={minimumHoursPerBookingLabelText}
            value={minimumHoursPerBookingText}
          />
        )}
      </div>
    </div>
  )
}

SectionPricing.defaultProps = {
  className: null,
  titleClassName: null,
};

SectionPricing.propTypes = {
  className: string,
  titleClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing,
};

export default SectionPricing;
