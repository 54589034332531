import moment from 'moment';
import { find, path } from 'ramda';

import config from '../config';
import { LINE_ITEM_CUSTOMER_COMMISSION } from './constants';
import { ensureTransaction } from './data';
import { isSeasonalMarketplace } from '../marketplace-custom-config';
import { isSeason } from './dates';

/**
 * Transactions processes updates
 */

// message was reworked test=34 prod=11
export const MESSAGE_UPDATE_PROCESS_VERSION = 11;

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

// When a customer makes a booking to a listing, a transaction is
// created with the initial request-payment transition.
// At this transition a PaymentIntent is created by Marketplace API.
// After this transition, the actual payment must be made on client-side directly to Stripe.
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';

// A customer can also initiate a transaction with an enquiry, and
// then transition that with a request.
export const TRANSITION_ENQUIRE = 'transition/enquire';
export const TRANSITION_ENQUIRE_OPENED_BY_PROVIDER = 'transition/enquire-opened-by-provider';
export const TRANSITION_ENQUIRE_OPENED_BY_CUSTOMER = 'transition/enquire-opened-by-customer';
export const TRANSITION_ENQUIRE_NEW_MESSAGE_BY_PROVIDER = 'transition/enquire-new-message-by-provider';
export const TRANSITION_ENQUIRE_NEW_MESSAGE_BY_CUSTOMER = 'transition/enquire-new-message-by-customer';
export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY = 'transition/request-payment-after-enquiry';

// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.
export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';

// When the provider accepts or declines a transaction from the
// SalePage, it is transitioned with the accept or decline transition.
export const TRANSITION_ACCEPT = 'transition/accept';
export const TRANSITION_INSTANT_ACCEPT = 'transition/instant-accept';
export const TRANSITION_DECLINE = 'transition/decline-by-provider';

// CUSTOMER CANCEL BOOKING BEFORE IT HAS BEEN ACCEPTED OR DECLINED BY PROVIDER
export const TRANSITION_CANCEL_BY_CUSTOMER = 'transition/decline-by-customer';
export const TRANSITION_ACCEPTED_CANCEL_BY_CUSTOMER = 'transition/cancel-by-customer';

// Customer/Buyer can also refund the transition
export const TRANSITION_REFUND_AFTER_CANCEL = 'transition/refund-after-cancel';
export const TRANSITION_REFUND_BY_PROVIDER = 'transition/refund-by-provider';
export const TRANSITION_REFUND_BY_CUSTOMER = 'transition/refund-by-customer';
export const TRANSITION_MARK_NON_REFUNDABLE = 'transition/mark-non-refundable';


// The backend automatically expire the transaction.
export const TRANSITION_EXPIRE = 'transition/expire';

// Admin can also cancel the transition (before payout).
export const TRANSITION_CANCEL_BEFORE_DELIVERED = 'transition/cancel-before-delivered';
export const TRANSITION_CANCEL_AFTER_DELIVERED = 'transition/cancel-after-delivered';

// The backend will mark the transaction completed.
export const TRANSITION_COMPLETE = 'transition/complete';
export const TRANSITION_COMPLETE_BY_OPERATOR = 'transition/complete-by-operator';
export const TRANSITION_PAYOUT_AFTER_DELIVERED = 'transition/payout-after-delivered';
export const TRANSITION_PAYOUT_AFTER_REVIEWED_BY_PROVIDER = 'transition/payout-after-reviewed-by-provider';
export const TRANSITION_PAYOUT_AFTER_REVIEWED_BY_CUSTOMER = 'transition/payout-after-reviewed-by-customer';
export const TRANSITION_PAYOUT_AFTER_REVIEWED = 'transition/payout-after-reviewed';

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_1_BY_PROVIDER_AFTER_PAYOUT = 'transition/review-1-by-provider-after-payout';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER_AFTER_PAYOUT = 'transition/review-2-by-provider-after-payout';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_1_BY_CUSTOMER_AFTER_PAYOUT = 'transition/review-1-by-customer-after-payout';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER_AFTER_PAYOUT = 'transition/review-2-by-customer-after-payout';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_ENQUIRY = 'enquiry';
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PAYMENT_EXPIRED = 'payment-expired';
const STATE_PREAUTHORIZED = 'preauthorized';
const STATE_DECLINED = 'declined';
const STATE_ACCEPTED_NON_REFUNDABLE = 'accepted-non-refundable';
const STATE_CANCELED = 'cancelled';
const STATE_DELIVERED = 'delivered';
const STATE_PAID_OUT_AFTER_DELIVERED = 'paid-out-after-delivered';
const STATE_REVIEWED = 'reviewed';
const STATE_REVIEWED_AND_PAID_OUT = 'reviewed-and-paid-out';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_CUSTOMER_AND_PAID_OUT = 'reviewed-by-customer-and-paid-out';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';
const STATE_REVIEWED_BY_PROVIDER_AND_PAID_OUT = 'reviewed-by-provider-and-paid-out';
const STATE_ACCEPTED_REFUNDABLE = 'accepted-refundable';
const STATE_REFUNDED = 'refunded';


/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: config.bookingProcessAlias,

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_ENQUIRE]: STATE_ENQUIRY,
        [TRANSITION_REQUEST_PAYMENT]: STATE_PENDING_PAYMENT,
      },
    },
    [STATE_ENQUIRY]: {
      on: {
        [TRANSITION_ENQUIRE_OPENED_BY_PROVIDER]: STATE_ENQUIRY,
        [TRANSITION_ENQUIRE_OPENED_BY_CUSTOMER]: STATE_ENQUIRY,
        [TRANSITION_ENQUIRE_NEW_MESSAGE_BY_PROVIDER]: STATE_ENQUIRY,
        [TRANSITION_ENQUIRE_NEW_MESSAGE_BY_CUSTOMER]: STATE_ENQUIRY,
        [TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY]: STATE_PENDING_PAYMENT,
      },
    },

    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_CONFIRM_PAYMENT]: STATE_PREAUTHORIZED,
      },
    },

    [STATE_PAYMENT_EXPIRED]: {},
    [STATE_PREAUTHORIZED]: {
      on: {
        [TRANSITION_DECLINE]: STATE_DECLINED,
        [TRANSITION_CANCEL_BY_CUSTOMER]: STATE_DECLINED,
        [TRANSITION_EXPIRE]: STATE_DECLINED,
        [TRANSITION_ACCEPT]: STATE_ACCEPTED_REFUNDABLE,
        [TRANSITION_INSTANT_ACCEPT]: STATE_ACCEPTED_REFUNDABLE,
      },
    },

    [STATE_ACCEPTED_REFUNDABLE]: {
      on: {
        [TRANSITION_REFUND_BY_PROVIDER]: STATE_REFUNDED,
        [TRANSITION_REFUND_BY_CUSTOMER]: STATE_REFUNDED,
        [TRANSITION_MARK_NON_REFUNDABLE]: STATE_ACCEPTED_NON_REFUNDABLE,
      },
    },

    [STATE_DECLINED]: {},
    [STATE_ACCEPTED_NON_REFUNDABLE]: {
      on: {
        [TRANSITION_CANCEL_BEFORE_DELIVERED]: STATE_CANCELED,
        [TRANSITION_COMPLETE]: STATE_DELIVERED,
        [TRANSITION_COMPLETE_BY_OPERATOR]: STATE_DELIVERED,
      },
    },

    [STATE_CANCELED]: {
      on: {
        [TRANSITION_REFUND_AFTER_CANCEL]: STATE_REFUNDED,
      },
    },
    [STATE_DELIVERED]: {
      on: {
        [TRANSITION_CANCEL_AFTER_DELIVERED]: STATE_CANCELED,
        [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
        [TRANSITION_PAYOUT_AFTER_DELIVERED]: STATE_PAID_OUT_AFTER_DELIVERED,
      },
    },

    [STATE_PAID_OUT_AFTER_DELIVERED]: {
      on: {
        [TRANSITION_REVIEW_1_BY_CUSTOMER_AFTER_PAYOUT]: STATE_REVIEWED_BY_CUSTOMER_AND_PAID_OUT,
        [TRANSITION_REVIEW_1_BY_PROVIDER_AFTER_PAYOUT]: STATE_REVIEWED_BY_PROVIDER_AND_PAID_OUT,
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED_AND_PAID_OUT,
      },
    },

    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_PAYOUT_AFTER_REVIEWED_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER_AND_PAID_OUT,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_PAYOUT_AFTER_REVIEWED_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER_AND_PAID_OUT,
      },
    },

    [STATE_REVIEWED_BY_CUSTOMER_AND_PAID_OUT]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER_AFTER_PAYOUT]: STATE_REVIEWED_AND_PAID_OUT,
      },
    },

    [STATE_REVIEWED_BY_PROVIDER_AND_PAID_OUT]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER_AFTER_PAYOUT]: STATE_REVIEWED_AND_PAID_OUT,
      },
    },

    [STATE_REVIEWED]: {
      on: {
        [TRANSITION_PAYOUT_AFTER_REVIEWED]: STATE_REVIEWED_AND_PAID_OUT,
      },
    },

    [STATE_REVIEWED_AND_PAID_OUT]: { type: 'final' },
  },
};

/**
 * Transactions answered
 *
 * Those values aimed to choose which transition have been answered by user:
 */

export const ANSWERED_BY_PROVIDER = [
  TRANSITION_ACCEPT,
  TRANSITION_INSTANT_ACCEPT,
  TRANSITION_DECLINE,
  TRANSITION_ENQUIRE_NEW_MESSAGE_BY_PROVIDER,
];

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key,
);

const TRANSITIONS_NOT_BOOKED = [
  TRANSITION_ENQUIRE,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_EXPIRE_PAYMENT,
  TRANSITION_ENQUIRE_NEW_MESSAGE_BY_CUSTOMER,
  TRANSITION_ENQUIRE_NEW_MESSAGE_BY_PROVIDER,
  TRANSITION_ENQUIRE_OPENED_BY_CUSTOMER,
  TRANSITION_ENQUIRE_OPENED_BY_PROVIDER,
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT,
];

export const TRANSITIONS_BOOKED = TRANSITIONS.filter(transition =>
  !TRANSITIONS_NOT_BOOKED.includes(transition),
);

export const TRANSITIONS_PAID_OUT = [
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_PAYOUT_AFTER_DELIVERED,
  TRANSITION_PAYOUT_AFTER_REVIEWED,
  TRANSITION_PAYOUT_AFTER_REVIEWED_BY_CUSTOMER,
  TRANSITION_PAYOUT_AFTER_REVIEWED_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER_AFTER_PAYOUT,
  TRANSITION_REVIEW_1_BY_PROVIDER_AFTER_PAYOUT,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER_AFTER_PAYOUT,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER_AFTER_PAYOUT,
];

export const TRANSITIONS_REFUNDED = [
  TRANSITION_REFUND_BY_PROVIDER,
  TRANSITION_REFUND_BY_CUSTOMER,
];

export const TRANSITIONS_TO_MASK = [
  TRANSITION_CANCEL_AFTER_DELIVERED,
  TRANSITION_CANCEL_BEFORE_DELIVERED,
  TRANSITION_DECLINE,
  TRANSITION_ENQUIRE,
  TRANSITION_ENQUIRE_OPENED_BY_PROVIDER,
  TRANSITION_ENQUIRE_OPENED_BY_CUSTOMER,
  TRANSITION_ENQUIRE_NEW_MESSAGE_BY_PROVIDER,
  TRANSITION_ENQUIRE_NEW_MESSAGE_BY_CUSTOMER,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_EXPIRE,
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
];

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

export const BOOKING_PENDING_TRANSITIONS = getTransitionsToState(STATE_PREAUTHORIZED);

export const BOOKING_UPCOMING_TRANSITIONS = [
  ...getTransitionsToState(STATE_ACCEPTED_REFUNDABLE),
  ...getTransitionsToState(STATE_ACCEPTED_NON_REFUNDABLE),
];

export const BOOKING_PAST_TRANSITIONS = [
  ...getTransitionsToState(STATE_DELIVERED),
  ...getTransitionsToState(STATE_PAID_OUT_AFTER_DELIVERED),
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER_AND_PAID_OUT),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER_AND_PAID_OUT),
  ...getTransitionsToState(STATE_REVIEWED),
  ...getTransitionsToState(STATE_REVIEWED_AND_PAID_OUT),
];

export const REVERSAL_TRANSITIONS = [
  TRANSITION_CANCEL_AFTER_DELIVERED,
  TRANSITION_CANCEL_BEFORE_DELIVERED,
  TRANSITION_CANCEL_BY_CUSTOMER,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_REFUND_AFTER_CANCEL,
  TRANSITION_REFUND_BY_CUSTOMER,
  TRANSITION_REFUND_BY_PROVIDER,
];

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

export const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsEnquired = tx =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsEnquireOpened = tx => {
  const lastTransition = txLastTransition(tx);
  return (
    lastTransition === TRANSITION_ENQUIRE_OPENED_BY_PROVIDER ||
    lastTransition === TRANSITION_ENQUIRE_OPENED_BY_CUSTOMER
  );
};

export const txIsEnquireOpenedByProvider = tx => {
  const lastTransition = txLastTransition(tx);
  return (
    lastTransition === TRANSITION_ENQUIRE_OPENED_BY_PROVIDER ||
    lastTransition === TRANSITION_ENQUIRE_OPENED_BY_CUSTOMER ||
    lastTransition === TRANSITION_ENQUIRE_NEW_MESSAGE_BY_PROVIDER
  );
};
export const txIsEnquireOpenedByCustomer = tx => {
  const lastTransition = txLastTransition(tx);
  return (
    lastTransition === TRANSITION_ENQUIRE ||
    lastTransition === TRANSITION_ENQUIRE_OPENED_BY_CUSTOMER ||
    lastTransition === TRANSITION_ENQUIRE_OPENED_BY_PROVIDER ||
    lastTransition === TRANSITION_ENQUIRE_NEW_MESSAGE_BY_CUSTOMER
  );
};

export const txIsEnquireNewMessageByProvider = tx =>
  txLastTransition(tx) === TRANSITION_ENQUIRE_NEW_MESSAGE_BY_PROVIDER;
export const txIsEnquireNewMessageByCustomer = tx => {
  const lastTransition = txLastTransition(tx);
  return (
    lastTransition === TRANSITION_ENQUIRE_NEW_MESSAGE_BY_CUSTOMER ||
    lastTransition === TRANSITION_ENQUIRE
  );
};

export const txIsPaymentPending = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpired = tx =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));

// Note: state name used in Marketplace API docs (and here) is actually preauthorized
// However, word "requested" is used in many places so that we decided to keep it.
export const txIsRequested = tx =>
  getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx));

export const txIsAcceptedRefundable = tx =>
  getTransitionsToState(STATE_ACCEPTED_REFUNDABLE).includes(txLastTransition(tx));

export const txIsAcceptedNonRefundable = tx =>
  getTransitionsToState(STATE_ACCEPTED_NON_REFUNDABLE).includes(txLastTransition(tx));

export const txIsAccepted = tx =>
  getTransitionsToState(STATE_ACCEPTED_REFUNDABLE).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_ACCEPTED_NON_REFUNDABLE).includes(txLastTransition(tx));
  
export const txIsPreauthorized = tx =>
  getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx));

export const txIsDeclinedByCustomer = tx =>
  txLastTransition(tx) === TRANSITION_CANCEL_BY_CUSTOMER;

export const txIsDeclined = tx =>
  getTransitionsToState(STATE_DECLINED).includes(txLastTransition(tx));

export const txIsCanceled = tx =>
  getTransitionsToState(STATE_CANCELED).includes(txLastTransition(tx));

export const txIsRefunded = tx =>
  getTransitionsToState(STATE_REFUNDED).includes(txLastTransition(tx));

export const txIsDelivered = tx =>
  getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));

export const txIsPaidOutAfterDelivered = tx =>
  getTransitionsToState(STATE_PAID_OUT_AFTER_DELIVERED).includes(txLastTransition(tx));

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? (
      getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx)) ||
      getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER_AND_PAID_OUT).includes(txLastTransition(tx))
    ) : (
      getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx)) ||
      getTransitionsToState(STATE_REVIEWED_BY_PROVIDER_AND_PAID_OUT).includes(txLastTransition(tx))
    );

export const txIsReviewedByCustomer = tx =>
  getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER_AND_PAID_OUT).includes(txLastTransition(tx));

export const txIsReviewedByCustomerAndPaidOut = tx =>
  getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER_AND_PAID_OUT).includes(txLastTransition(tx));

export const txIsReviewedByProvider = tx =>
  getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_REVIEWED_BY_PROVIDER_AND_PAID_OUT).includes(txLastTransition(tx));

export const txIsReviewedByProviderAndPaidOut = tx =>
  getTransitionsToState(STATE_REVIEWED_BY_PROVIDER_AND_PAID_OUT).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_REVIEWED_AND_PAID_OUT).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

export const txHasBeenAccepted = hasPassedStateFn(STATE_ACCEPTED_REFUNDABLE);
export const txHasBeenDelivered = hasPassedStateFn(STATE_DELIVERED);

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(STATE_REVIEWED).includes(transition) ||
  getTransitionsToState(STATE_REVIEWED_AND_PAID_OUT).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = (isCustomer, tx) =>
  isCustomer && txIsPaidOutAfterDelivered(tx)
    ? TRANSITION_REVIEW_1_BY_CUSTOMER_AFTER_PAYOUT
    : isCustomer && !txIsPaidOutAfterDelivered(tx)
    ? TRANSITION_REVIEW_1_BY_CUSTOMER
    : !isCustomer && txIsPaidOutAfterDelivered(tx)
      ? TRANSITION_REVIEW_1_BY_PROVIDER_AFTER_PAYOUT
      : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = (isCustomer, tx) =>
  isCustomer && txIsReviewedByProviderAndPaidOut(tx)
    ? TRANSITION_REVIEW_2_BY_CUSTOMER_AFTER_PAYOUT
    : isCustomer && !txIsReviewedByProviderAndPaidOut(tx)
    ? TRANSITION_REVIEW_2_BY_CUSTOMER
    : !isCustomer && txIsReviewedByCustomerAndPaidOut(tx)
      ? TRANSITION_REVIEW_2_BY_PROVIDER_AFTER_PAYOUT
      : TRANSITION_REVIEW_2_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_ACCEPT,
    TRANSITION_CANCEL_AFTER_DELIVERED,
    TRANSITION_CANCEL_BEFORE_DELIVERED,
    TRANSITION_CANCEL_BY_CUSTOMER,
    TRANSITION_COMPLETE,
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_DECLINE,
    TRANSITION_ENQUIRE,
    TRANSITION_EXPIRE,
    TRANSITION_INSTANT_ACCEPT,
    TRANSITION_REFUND_BY_CUSTOMER,
    TRANSITION_REFUND_BY_PROVIDER,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_CUSTOMER_AFTER_PAYOUT,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_1_BY_PROVIDER_AFTER_PAYOUT,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_CUSTOMER_AFTER_PAYOUT,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_PROVIDER_AFTER_PAYOUT,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_CUSTOMER_AFTER_PAYOUT,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_CUSTOMER_AFTER_PAYOUT,
  ].includes(transition);
};

export const isProviderReview = transition => {
  return [
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_1_BY_PROVIDER_AFTER_PAYOUT,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_PROVIDER_AFTER_PAYOUT,
  ].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  // return [TRANSITION_REQUEST_PAYMENT, TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY].includes(
  //   transition
  // );
  return false;
};

export const getGuestCountFromTx = (transaction) => {
  const adultsCount = path(['attributes', 'protectedData', 'quantity', 'adults'], transaction);
  const childrenCount = path(['attributes', 'protectedData', 'quantity', 'children'], transaction);

  if (adultsCount > 0 && childrenCount >= 0) {
    return adultsCount + childrenCount;
  } else {
    return undefined;
  }
}

export const getBookingDatesFromTx = (transaction) => {
  const startFromBooking = path(['booking', 'attributes', 'start'], transaction);
  const startFromTx = path(['attributes', 'protectedData', 'date'], transaction);
  const displayStartFromTx = path(['attributes', 'protectedData', 'bookingStartDisplay'], transaction);
  const endFromBooking = path(['booking', 'attributes', 'end'], transaction);
  const endFromTx = path(['attributes', 'protectedData', 'endDate'], transaction);
  const displayEndFromTx = path(['attributes', 'protectedData', 'bookingEndDisplay'], transaction);
  const hasBookingDates = !!startFromBooking || !!startFromTx || !!displayStartFromTx || !!endFromBooking || !!endFromTx || !!displayEndFromTx;

  return hasBookingDates ? (
    {
      start: displayStartFromTx || startFromBooking || startFromTx,
      end: displayEndFromTx || endFromBooking || endFromTx,
    }
  ) : undefined;
}

export const isInstantBooking = (instantBookingFrom, transaction) => {
  if (!instantBookingFrom || !transaction) return false;
  
  const lineItems = path(['attributes', 'lineItems'], transaction);
  const lineItemCustomerCommission = find(lineItem => lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION, lineItems);
  const amountWithoutFee = path(['unitPrice', 'amount'], lineItemCustomerCommission);
  const instantBookingFromAmount = instantBookingFrom * 100;

  return amountWithoutFee >= instantBookingFromAmount;
}
