import React, { Fragment, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { bool, func, number, string } from 'prop-types';
import { intlShape } from 'react-intl';
import { useSelector } from 'react-redux';
import { length } from 'ramda';
import { useLocation } from 'react-router-dom';

import config from '../../config';
import { propTypes } from '../../util/types';
import { getMissingInfos } from '../../util/user';
import { FadeAnimation, SlideAnimation } from '../Animations';
import {
  Badge,
  Divider,
  ExternalLink,
  IconClose,
  InlineTextButton,
  Logo,
  NamedLink,
} from '..';

import ReservationsLink from './ReservationsLink/ReservationsLink';
import css from './NavigationDrawer.module.css';

const KEY_CODE_ESCAPE = 27;

const NavigationDrawer = (props) => {
  const {
    id,
    isOpen,
    onClose,
    isAuthenticated,
    messagesNotificationCount,
    currentPage,
    currentUser,
    currentUserHasListings,
    currentUserHasPublishedListings,
    onLogout,
    onManageDisableScrolling,
    intl,
  } = props;
  const location = useLocation();
  useEffect(() => onManageDisableScrolling(id, isOpen), [isOpen]);
  const drawerRef = useRef();
  const handleClose = (e) => {
    onManageDisableScrolling(id, false);
    onClose(e)
  };
  useEffect(() => {
    if (!isOpen) return;

    handleClose();
  }, [location]);
  const handleCloseByPressingKey = (e) => {
    if (e.keyCode !== KEY_CODE_ESCAPE) return;
    handleClose(e);
  }
  const handleCloseByClickingOutside = (e) => {
    if (e.target !== e.currentTarget) return;
    handleClose(e);
  };
  const handleFindAPoolClick = (e) => {
    if (typeof window === 'undefined') return;
    handleClose(e);
    if (currentPage === 'LandingPage') {
      setTimeout(() => window.scrollTo({
        top: 0, 
        behavior: 'smooth',
      }), 10);
    }
  };
  const handleLogout = (e) => {
    onLogout();
    handleClose(e);
  };
  const currentUserListingRefs = useSelector(state => state.user.currentUserListingRefs);
  const showCalendarLink = length(currentUserListingRefs) > 0;
  const showInboxBadge = messagesNotificationCount > 0;
  const hasUnpublishedListings = !currentUserHasPublishedListings;
  const userHasMissingInfos = currentUser && getMissingInfos(currentUser);
  const closeText = intl.formatMessage({ id: 'Marketplace.close' });
  const findAPoolText = intl.formatMessage({ id: 'NavigationDrawer.findAPool' });
  const rentMyPoolText = intl.formatMessage({ id: 'NavigationDrawer.rentMyPool' });
  const signupText = intl.formatMessage({ id: 'NavigationDrawer.signup' });
  const loginText = intl.formatMessage({ id: 'NavigationDrawer.login' });
  const inboxText = intl.formatMessage({ id: 'NavigationDrawer.inbox' });
  const favoritesText = intl.formatMessage({ id: 'NavigationDrawer.favorites' });
  const accountText = intl.formatMessage({ id: 'NavigationDrawer.account' });
  const calendarText = intl.formatMessage({ id: 'NavigationDrawer.calendar' });
  const manageListingsText = intl.formatMessage({ id: 'NavigationDrawer.manageListings' });
  const incomeText = intl.formatMessage({ id: 'NavigationDrawer.income' });
  const helpdeskText = intl.formatMessage({ id: 'NavigationDrawer.helpdesk' });
  const blogText = intl.formatMessage({ id: 'NavigationDrawer.blog' });
  const logoutText = intl.formatMessage({ id: 'NavigationDrawer.logout' });

  return ReactDOM.createPortal((
    <Fragment>
      <FadeAnimation inProps={isOpen} mountOnEnter>
        <div
          className={css.background}
          ref={drawerRef}
          onClick={handleCloseByClickingOutside}
          onKeyDown={handleCloseByPressingKey}
          tabIndex={0}
        />
      </FadeAnimation>
      <SlideAnimation inProps={isOpen} from="left" mountOnEnter>
        <div className={css.drawer}>
          <div className={css.header}>
            <button className={css.button} onClick={handleClose}>
              <NamedLink className={css.home} name="LandingPage" title={intl.formatMessage({ id: 'NavigationDrawer.logoIcon' })}>
                <Logo format="desktop" className={css.logo} />
              </NamedLink>
            </button>
            <button className={css.buttonClose} onClick={handleClose} title={closeText}>
              <span className={css.buttonCloseText}>{closeText}</span>
              <IconClose className={css.iconClose} />
            </button>
          </div>
          <div className={css.content}>
            <section className={css.section}>
              <button className={css.button} onClick={handleFindAPoolClick}>
                <NamedLink className={css.mainAction} name="LandingPage">
                  {findAPoolText}
                </NamedLink>
              </button>
              <NamedLink className={css.mainAction} name={isAuthenticated ? 'NewListingPage' : 'SimulatorPage'}>
                  {rentMyPoolText}
              </NamedLink>
            </section>
            <Divider className={css.divider} />
            {isAuthenticated ? (
              <Fragment>
                <section className={css.section}>
                  <NamedLink className={css.mainLink} name="InboxPage">
                    {inboxText}
                    {showInboxBadge && <Badge />}
                  </NamedLink>
                  <ReservationsLink className={css.mainLink} intl={intl} />
                  <NamedLink className={css.mainLink} name="FavoritesPage">
                    {favoritesText}
                  </NamedLink>
                </section>
                {currentUserHasListings && <Divider className={css.divider} />}
                <section className={css.section}>
                  {currentUserHasListings && (
                    <Fragment>
                      {showCalendarLink && (
                        <NamedLink className={css.link} name="CalendarPage" params={{ id: currentUserListingRefs[0].id.uuid }}>
                          {calendarText}
                        </NamedLink>
                      )}
                      <NamedLink className={css.link} name="ManageListingsPage">
                        {manageListingsText}
                        {hasUnpublishedListings && <Badge />}
                      </NamedLink>
                      <NamedLink className={css.link} name="IncomePage">
                        {incomeText}
                      </NamedLink>
                    </Fragment>
                  )}
                  {currentUserHasListings && (
                    <NamedLink className={css.link} name="AccountSettingsPage">
                      {accountText}
                      {userHasMissingInfos && <Badge />}
                    </NamedLink>
                  )}
                </section>
              </Fragment>
            ) : (
              <section className={css.section}>
                <NamedLink className={css.mainLink} name="SignupPage">
                  {signupText}
                </NamedLink>
                <NamedLink className={css.link} name="LoginPage">
                  {loginText}
                </NamedLink>
              </section>
            )}
            <Divider className={css.divider} />
            <section className={css.section}>
              {isAuthenticated && !currentUserHasListings && (
                <NamedLink className={css.link} name="AccountSettingsPage">
                  {accountText}
                  {userHasMissingInfos && <Badge />}
                </NamedLink>
              )}
              <ExternalLink className={css.link} href={config.custom.helpCenterUrl} openInSameWindow>
                {helpdeskText}
              </ExternalLink>
              {config.custom.hasBlog && (
                <ExternalLink className={css.link} href={config.custom.blogUrl}>
                  {blogText}
                </ExternalLink>
              )}
              {isAuthenticated && (
                <InlineTextButton className={css.logoutLink} onClick={handleLogout}>
                  {logoutText}
                </InlineTextButton>
              )}
            </section>
          </div>
        </div>
      </SlideAnimation>
    </Fragment>
  ), document.body);
};

NavigationDrawer.defaultProps = {
  className: null,
  currentPage: null,
  currentUser: null,
  messagesNotificationCount: 0,
};

NavigationDrawer.propTypes = {
  className: string,
  id: string.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  isAuthenticated: bool.isRequired,
  currentPage: string,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasPublishedListings: bool.isRequired,
  messagesNotificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  intl: intlShape.isRequired,
};

export default NavigationDrawer;
