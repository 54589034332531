import React from 'react';
import { string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';

import { IconAttachMoney, IconWarning } from '../../../../components/Icons';
import css from './CalendarLegend.module.css';

const CalendarLegend = (props) => {
  const {
    className,
    intl,
  } = props;
  const availableLegendText = intl.formatMessage({ id: 'CalendarPage.MainContent.availableLegend' });
  const unavailableLegendText = intl.formatMessage({ id: 'CalendarPage.MainContent.unavailableLegend' });
  const hasExceptionLegendText = intl.formatMessage({ id: 'CalendarPage.MainContent.hasExceptionLegend' });
  const hasExceptionHelperText = intl.formatMessage({ id: 'CalendarPage.MainContent.hasExceptionHelper' });
  const hasBookingLegendText = intl.formatMessage({ id: 'CalendarPage.MainContent.hasBookingLegend' });
  const hasBookingHelperText = intl.formatMessage({ id: 'CalendarPage.MainContent.hasBookingHelper' });
  const shortcutHeadingText = intl.formatMessage({ id: 'CalendarPage.MainContent.shortcutHeading' });
  const multiDateShortcutText = intl.formatMessage({ id: 'CalendarPage.MainContent.multiDateShortcut' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <div className={css.legendWrapper}>
        <div className={css.available} />
        <div className={css.legendTextWrapper}>
          <span className={css.legend}>{availableLegendText}</span>
        </div>
      </div>
      <div className={css.legendWrapper}>
        <div className={css.unavailable} />
        <div className={css.legendTextWrapper}>
          <span className={css.legend}>{unavailableLegendText}</span>
        </div>
      </div>
      <div className={css.legendWrapper}>
        <div className={css.iconContainer}>
          <IconWarning className={css.icon} />
        </div>
        <div className={css.legendTextWrapper}>
          <span className={css.legend}>{hasExceptionLegendText}</span>
          <span className={css.helper}>{hasExceptionHelperText}</span>
        </div>
      </div>
      <div className={css.legendWrapper}>
        <div className={css.iconContainer}>
          <IconAttachMoney className={css.icon} />
        </div>
        <div className={css.legendTextWrapper}>
          <span className={css.legend}>{hasBookingLegendText}</span>
          <span className={css.helper}>{hasBookingHelperText}</span>
        </div>
      </div>
      <div className={css.shortcutContainer}>
        <span className={css.shortcutHeading}>{shortcutHeadingText}</span>
        <span className={css.shortcutWrapper}><kbd>Ctrl</kbd> + <kbd>Click</kbd> {multiDateShortcutText}</span>
      </div>
    </div>
  )
}

CalendarLegend.defaultProps = {
  className: null,
};

CalendarLegend.propTypes = {
  className: string,
  intl: intlShape.isRequired,
};

export default CalendarLegend;
