import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { bool, func } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

import copyIcon from '../../assets/copy.svg';
import {
  ModalSwimmy,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '../../components/ModalSwimmy';

import css from './ShareModal.css';

const ShareModal = props => {
  const { intl, onManageDisableScrolling, onShareModalClose, shareModalOpen } = props;
  const [copiedLink, setCopiedLink] = useState(false);

  const handleClickCopyLink = () => {
    setCopiedLink(true);
    setTimeout(() => {
      setCopiedLink(false);
    }, 5000);
    return () => clearTimeout();
  };
  const location = useLocation();
  const { pathname } = location;
  const shareUrl = `${process.env.REACT_APP_CANONICAL_ROOT_URL}${pathname}?share=true`;
  const shareUrlforFacebook = shareUrl.replace('?share=true', '');
  const swimmyPrimaryColorFillIcon = { fill: '#07a0f2' };
  const iconSize = 32;
  const heading = intl.formatMessage({ id: 'ShareModal.heading' });
  const description = intl.formatMessage({ id: 'ShareModal.description' });
  const facebookText = intl.formatMessage({ id: 'ShareModal.facebook' });
  const whatsAppText = intl.formatMessage({ id: 'ShareModal.whatsApp' });
  const emailText = intl.formatMessage({ id: 'ShareModal.email' });
  const copyLinkText = intl.formatMessage({ id: !copiedLink ? 'ShareModal.copyLink' : 'ShareModal.copiedLink' });

  return (
    <ModalSwimmy
      id="ShareModal"
      isOpen={shareModalOpen}
      onClose={onShareModalClose}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <ModalOverlay />
      <ModalContent className={css.modalContent}>
        <ModalHeader className={css.modalHeader} heading={heading} />
        <ModalCloseButton className={css.modalCloseButton} handleClose={onShareModalClose} />
        <ModalBody className={css.modalBody}>
          <p className={css.description}>{description}</p>
          <FacebookShareButton url={shareUrlforFacebook} className={css.shareButton}>
            <FacebookIcon size={iconSize} round bgStyle={swimmyPrimaryColorFillIcon}/>
            <span className={css.shareButtonName}>{facebookText}</span>
          </FacebookShareButton>
          <WhatsappShareButton url={shareUrl} className={css.shareButton}>
            <WhatsappIcon size={iconSize} round bgStyle={swimmyPrimaryColorFillIcon}/>
            <span className={css.shareButtonName}>{whatsAppText}</span>
          </WhatsappShareButton>
          <EmailShareButton url={shareUrl} className={css.shareButton}>
            <EmailIcon size={iconSize} round bgStyle={swimmyPrimaryColorFillIcon}/>
            <span className={css.shareButtonName}>{emailText}</span>
          </EmailShareButton>
          <CopyToClipboard text={shareUrl} onCopy={handleClickCopyLink}>
            <div className={css.shareButton}>
              <img src={copyIcon}/>
              <span className={css.shareButtonName}>{copyLinkText}</span>
            </div>
          </CopyToClipboard>
        </ModalBody>
      </ModalContent>
    </ModalSwimmy>
  )
};

ShareModal.propTypes = {
  shareModalOpen: bool,
  onShareModalClose: func.isRequired,
  onManageDisableScrolling: func,
  intl: intlShape.isRequired,
};

export default injectIntl(ShareModal);
