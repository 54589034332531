import React from 'react';
import { bool, func, string, object } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { map, pathOr } from 'ramda';

import { propTypes } from '../../util/types';
import {
  Form,
  FieldTextInput,
  PrimaryButton,
  Tag,
} from '../../components';
import css from './EditListingInventoryForm.module.css';

const EditListingInventoryFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateError,
        updateInProgress,
        initialValues,
        values,
        form: {
          mutators: { push }
        },
      } = fieldRenderProps;
      const hasObjects = pathOr([], ['objects'], initialValues).length > 0;
      const inventoryInfoText = intl.formatMessage({ id: 'EditListingInventoryForm.info' });
      const inventoryObjectPlaceholder = intl.formatMessage({ id: 'EditListingInventoryForm.placeholder' });
      const listTitle = intl.formatMessage({ id: 'EditListingInventoryForm.listTitle' });
      const noObjectsText = intl.formatMessage({ id: 'EditListingInventoryForm.noObjects' });
      const errorMessage = updateError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingInformationForm.updateFailed" />
        </p>
      ) : null;
      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || !values.inventoryObject;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          <p className={css.info}>{inventoryInfoText}</p>
          <span className={css.listTitle}>{listTitle}</span>
          <div className={css.objectContainer}>
            {!hasObjects ? (
              <span className={css.noObjects}>{noObjectsText}</span>
            ) : (
              <FieldArray name="objects">
                {({ fields }) =>
                  map((name, index) => (
                    <Field name={name} key={name} >
                      {props => (
                        <Tag
                          className={css.tag}
                          name={props.input.name}
                          onChange={props.input.onChange}
                          text={props.input.value}
                          onDelete={() => {
                            fields.remove(index)
                            handleSubmit();
                          }}
                        />
                      )}
                    </Field>
                  ), fields)
                }
              </FieldArray>
            )}
          </div>
          <div className={css.buttonContainer}>
            <FieldTextInput
              className={css.fieldObject}
              id="inventoryObject"
              name="inventoryObject"
              placeholder={inventoryObjectPlaceholder}
              type="text"
            />
            <PrimaryButton
              className={css.submitButton}
              type="submit"
              onClick={() => push('objects', values.inventoryObject)}
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

EditListingInventoryFormComponent.defaultProps = {
  className: null,
  updateError: null,
  initialValues: {},
};

EditListingInventoryFormComponent.propTypes = {
  className: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  initialValues: object,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EditListingInventoryForm = compose(injectIntl)(EditListingInventoryFormComponent);
EditListingInventoryForm.displayName = 'EditListingInventoryForm';

export default EditListingInventoryForm;
