import { path } from 'ramda';

import { types as sdkTypes } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import { fetchMonthlyTimeSlots, fetchReviews, showListing } from '../../ducks/listing.duck';

const { UUID } = sdkTypes;

export const loadData = (params, search) => dispatch => {
  const listingId = new UUID(params.id);

  return Promise.all([dispatch(showListing(listingId)), dispatch(fetchReviews(listingId))]).then(
    responses => {
      if (responses[0] && responses[0].data && responses[0].data.data) {
        const listing = responses[0].data.data;
        const parsedSearch = parse(search);
        const bookingDate = path(['bookingDate'], parsedSearch);

        // Fetch timeSlots.
        // This can happen parallel to loadData.
        // We are not interested to return them from loadData call.
        fetchMonthlyTimeSlots(dispatch, listing, bookingDate);
      }
      return responses;
    }
  );
};

