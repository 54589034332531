import { path } from 'ramda';
import { IEntity } from './interface';

export const getPricingDataFromEntity = (entity: IEntity) => {
  const isListing = entity.type === 'listing';
  const priceMaybe = isListing ? { price: path(['attributes', 'price'], entity) } : {};
  const paidExtrasData = path(['attributes', isListing ? 'publicData' : 'protectedData', 'paidExtrasData'], entity);
  const pricePerExtraGuest = path(['attributes', isListing ? 'publicData' : 'protectedData', 'pricePerExtraGuest'], entity);
  const pricingBaseNumberOfPeople = path(['attributes', isListing ? 'publicData' : 'protectedData', 'pricingBaseNumberOfPeople'], entity);

  return {
    ...priceMaybe,
    paidExtrasData,
    pricePerExtraGuest,
    pricingBaseNumberOfPeople,
  };
}