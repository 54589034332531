import { createResourceLocatorString } from '../../../util/routes';
import {
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../../util/urlHelpers';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
} from '../../../util/types';

export const createListingURL = (routes, listing) => {
  const id = listing.id.uuid;
  const slug = createSlug(listing.attributes.title);
  const isPendingApproval = listing.attributes.state === LISTING_STATE_PENDING_APPROVAL;
  const isDraft = listing.attributes.state === LISTING_STATE_DRAFT;
  const variant = isDraft
    ? LISTING_PAGE_DRAFT_VARIANT
    : isPendingApproval
    ? LISTING_PAGE_PENDING_APPROVAL_VARIANT
    : null;

  const linkProps =
    isPendingApproval || isDraft
    ? {
        name: 'ListingPageVariant',
        params: {
          id,
          slug,
          variant,
        },
      }
    : {
        name: 'ListingPage',
        params: { id, slug },
      };

  return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
};

export const getStateText = (intl, listing) => {
  const { state } = listing.attributes;

  switch (state) {
    case LISTING_STATE_DRAFT:
      return intl.formatMessage({ id: 'ManageListingsPage.ManageListingCard.draft' });

    case LISTING_STATE_CLOSED:
      return intl.formatMessage({ id: 'ManageListingsPage.ManageListingCard.closed' });
  
    default:
      return intl.formatMessage({ id: 'ManageListingsPage.ManageListingCard.opened' });
  }
};

export const getStateInformationText = (intl, listing) => {
  const { state } = listing.attributes;

  switch (state) {
    case LISTING_STATE_DRAFT:
      return intl.formatMessage({ id: 'ManageListingsPage.ManageListingCard.draftInformation' });

    case LISTING_STATE_CLOSED:
      return intl.formatMessage({ id: 'ManageListingsPage.ManageListingCard.closedInformation' });
  
    default:
      return null;
  }
};
