import { length } from 'ramda';

import config from '../../config';

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/BlogArticle/SET_INITIAL_VALUES';

export const GET_ARTICLE_REQUEST = 'app/BlogArticle/GET_ARTICLE_REQUEST';
export const GET_ARTICLE_SUCCESS = 'app/BlogArticle/GET_ARTICLE_SUCCESS';
export const GET_ARTICLE_ERROR = 'app/BlogArticle/GET_ARTICLE_ERROR';

// ================ Reducer ================ //

const initialState = {
  isLoading: false,
  error: false,
  data: null,
};

const ArticleReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case GET_ARTICLE_REQUEST:
      return { ...state, isLoading: true, error: null };
    case GET_ARTICLE_ERROR:
      return { ...state, isLoading: false, error: payload };
    case GET_ARTICLE_SUCCESS:
      return { ...state, isLoading: false, error: null, data: payload };

    default:
      return state;
  }
};

export default ArticleReducer;

// ================ Action creators ================ //

export const getArticleRequest = () => ({
  type: GET_ARTICLE_REQUEST,
});

export const getArticleSuccess = data => ({
  type: GET_ARTICLE_SUCCESS,
  error: false,
  payload: data,
});

export const getArticleError = e => ({
  type: GET_ARTICLE_ERROR,
  error: true,
  payload: e,
});


// ================ Thunks ================ //

export const getArticle = (queryString, keyword) => async (dispatch, getState, sdk) => {
  dispatch(getArticleRequest());
  const url = `${config.internalBlogUri}/${queryString}?keyword=${keyword}`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
    });
    const data = await response.json();
    if (!length(data)) throw {
      status: 404,
      message: 'no data'
    };

    dispatch(getArticleSuccess(data[0]));
    return data[0];
  }
  catch (e) {
    console.error(e);
    dispatch(getArticleError(e));
  }
};

export const loadData = (params, queryString) => dispatch => {
  const keyword = params.slug;

  return dispatch(getArticle(queryString, keyword));
};
