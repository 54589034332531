import React from 'react';
import { bool, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { propTypes } from '../../../../../../util/types';
import { NamedLink } from '../../../../../../components';

import css from './DetailsActions.module.css';

const DetailsActions = props => {
  const {
    className,
    isEnquiry,
    isOrder,
    transaction,
    intl,
  } = props;
  const isBookButton = isOrder && isEnquiry;
  const linkProps = {
    name: isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage',
    params: { id: transaction.id.uuid },
    ...isBookButton && { to: { search: '?book=true' }},
  }
  const bookText = intl.formatMessage({ id: 'InboxPage.book' });
  const moreDetailsText = intl.formatMessage({ id: 'InboxPage.moreDetails' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <NamedLink
        className={css.link}
        {...linkProps}
      >
        {isBookButton ? bookText : moreDetailsText}
      </NamedLink>
    </div>
  );
}

DetailsActions.defaultProps = {
  className: null,
};

DetailsActions.propTypes = {
  className: string,
  isEnquiry: bool.isRequired,
  isOrder: bool.isRequired,
  intl: intlShape.isRequired,
  transaction: propTypes.transaction.isRequired,
};

export default injectIntl(DetailsActions);
