import React from 'react';
import { string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { path } from 'ramda';

import { ensureUser, ensureCurrentUser } from '../../../util/data';
import { ProviderUserCard } from '../../../components';

import ContactButton from './ContactButton/ContactButton';
import ResponseInfo from './ReponseInfo/ResponseInfo';
import css from './SectionHostMaybe.module.css';

const SectionHostMaybe = props => {
  const {
    className,
    currentUser,
    intl,
    listing
  } = props;

  if (!listing.author) {
    return null;
  }

  const listingId = path(['id', 'uuid'], listing);
  const listingTitle = path(['attributes', 'title'], listing);
  const listingAuthor = listing.author;
  const userIsCurrentUser = listingAuthor && listingAuthor.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(listingAuthor) : ensureUser(listingAuthor);
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isCurrentUser = ensuredUser.id && ensuredCurrentUser.id && ensuredUser.id.uuid === ensuredCurrentUser.id.uuid;
  const canContact = listingId && listingTitle && !isCurrentUser;
  const classes = classNames(css.root, className);

  return (
    <div id="host" className={classes}>
      <ProviderUserCard
        className={css.providerUserCard}
        currentUser={currentUser}
        user={listingAuthor}
      />
      <div className={css.container}>
        <ResponseInfo
          className={css.responseInfo}
          intl={intl}
          listing={listing}
        />
        {canContact && (
          <ContactButton
            className={css.contactButton}
            intl={intl}
            listing={listing}
          />
        )}
      </div>
    </div>
  );
};

SectionHostMaybe.defaultProps = {
  className: null,
}

SectionHostMaybe.propTypes = {
  className: string,
  intl: intlShape.isRequired
}

export default SectionHostMaybe;
