import React from 'react';
import { string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { pathOr } from 'ramda';

import { propTypes } from '../../../../../../util/types';
import { NamedLink } from '../../../../../../components';

import { getTxType, txState } from '../../../../InboxPage.Helpers';
import css from './DetailsHeader.module.css';

const DetailsHeader = props => {
  const {
    className,
    currentUser,
    transaction,
    intl,
  } = props;
  const txType = getTxType(transaction, currentUser);
  const txProtectedData = pathOr({}, ['attributes', 'protectedData'], transaction);
  const stateData = txState(intl, transaction, txType);
  const canShowSearch = stateData.showSearch && txProtectedData.fromSearch;
  const searchMoreMessage = intl.formatMessage({ id: 'InboxPage.searchMore' });
  const classes = classNames(css.root, className);
  const stateClasses = classNames(css.text, stateData.stateClassName);

  return (
    <div className={classes}>
      {canShowSearch ? (
        <NamedLink
          className={css.searchMoreLink}
          name='SearchPage'
          to={{ search: txProtectedData.fromSearch }}
        >
          <span>{searchMoreMessage}</span>
        </NamedLink>
      ) : null}
      <span className={stateClasses}>{stateData.state}</span>
    </div>
  )
}

DetailsHeader.defaultProps = {
  className: null,
};

DetailsHeader.propTypes = {
  className: string,
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
  transaction: propTypes.transaction.isRequired,
};

export default injectIntl(DetailsHeader);
