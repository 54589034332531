import intersection from 'lodash/intersection';
import { path, pathOr } from 'ramda';

import config from '../config';
import { locationBounds } from '../util/googleMaps';
import { stringify } from '../util/urlHelpers';

export const LOCATION = 'location';
export const DATE = 'bookingDate';
export const TIME_SLOT = 'timeSlot';
export const START_TIME = 'startTime';
export const END_TIME = 'endTime';
export const FLEXIBLE = 'isFlexible'
export const ADULTS = 'adults';
export const CHILDREN = 'children';
export const INFANTS = 'infants';

const flatten = (acc, val) => acc.concat(val);

/**
 * SelectMultipleFilter needs to parse values from format
 * "has_all:a,b,c,d" or "a,b,c,d"
 */
export const parseSelectFilterOptions = uriComponentValue => {
  const startsWithHasAll = uriComponentValue && uriComponentValue.indexOf('has_all:') === 0;
  const startsWithHasAny = uriComponentValue && uriComponentValue.indexOf('has_any:') === 0;

  if (startsWithHasAll) {
    return uriComponentValue.substring(8).split(',');
  } else if (startsWithHasAny) {
    return uriComponentValue.substring(8).split(',');
  } else {
    return uriComponentValue.split(',');
  }
};

/**
 * Validates a filter search param agains a filters configuration.
 *
 * All invalid param names and values are dropped
 *
 * @param {String} queryParamName Search parameter name
 * @param {Object} paramValue Search parameter value
 * @param {Object} filters Filters configuration
 */
export const validURLParamForExtendedData = (queryParamName, paramValueRaw, filters) => {
  // Resolve configuration for this filter
  const filterConfig = filters.find(f => {
    const isArray = Array.isArray(f.queryParamNames);
    return isArray
      ? f.queryParamNames.includes(queryParamName)
      : f.queryParamNames === queryParamName;
  });

  const paramValue = paramValueRaw.toString();

  if (filterConfig) {
    const { min, max } = filterConfig.config || {};

    if (['SelectSingleFilter', 'SelectMultipleFilter'].includes(filterConfig.type)) {
      // Pick valid select options only
      const allowedValues = filterConfig.config.options.map(o => o.key);
      const valueArray = parseSelectFilterOptions(paramValue);
      const validValues = intersection(valueArray, allowedValues).join(',');

      return validValues.length > 0 ? { [queryParamName]: validValues } : {};
    } else if (filterConfig.type === 'PriceFilter') {
      // Restrict price range to correct min & max
      const valueArray = paramValue ? paramValue.split(',') : [];
      const validValues = valueArray.map(v => {
        return v < min ? min : v > max ? max : v;
      });
      return validValues.length === 2 ? { [queryParamName]: validValues.join(',') } : {};
    } else if (filterConfig) {
      // Generic filter - remove empty params
      return paramValue.length > 0 ? { [queryParamName]: paramValue } : {};
    }
  }
  return {};
};

/**
 * Checks filter param value validity.
 *
 * Non-filter params are dropped.
 *
 * @param {Object} params Search params
 * @param {Object} filters Filters configuration
 */
export const validFilterParams = (params, filters) => {
  const filterParamNames = filters.map(f => f.queryParamNames).reduce(flatten, []);
  const paramEntries = Object.entries(params);

  return paramEntries.reduce((validParams, entry) => {
    const [paramName, paramValue] = entry;

    return filterParamNames.includes(paramName)
      ? {
          ...validParams,
          ...validURLParamForExtendedData(paramName, paramValue, filters),
        }
      : { ...validParams };
  }, {});
};

/**
 * Checks filter param value validity.
 *
 * Non-filter params are returned as they are.
 *
 * @param {Object} params Search params
 * @param {Object} filters Filters configuration
 */
export const validURLParamsForExtendedData = (params, filters) => {
  const filterParamNames = filters.map(f => f.queryParamNames).reduce(flatten, []);
  const paramEntries = Object.entries(params);

  return paramEntries.reduce((validParams, entry) => {
    const [paramName, paramValue] = entry;

    return filterParamNames.includes(paramName)
      ? {
          ...validParams,
          ...validURLParamForExtendedData(paramName, paramValue, filters),
        }
      : { ...validParams, [paramName]: paramValue };
  }, {});
};

/**
 * Check if any of the filters (defined by filterIds) have currently active query parameter in URL.
 */
export const isAnyFilterActive = (filterIds, urlQueryParams, filterConfigs) => {
  const getQueryParamKeysOfGivenFilters = (keys, config) => {
    const isFilterIncluded = filterIds.includes(config.id);
    const addedQueryParamNamesMaybe = isFilterIncluded ? config.queryParamNames : [];
    return [...keys, ...addedQueryParamNamesMaybe];
  };
  const queryParamKeysOfGivenFilters = filterConfigs.reduce(getQueryParamKeysOfGivenFilters, []);

  const paramEntries = Object.entries(urlQueryParams);
  const activeKey = paramEntries.find(entry => {
    const [key, value] = entry;
    return queryParamKeysOfGivenFilters.includes(key) && value != null;
  });
  return !!activeKey;
};

/**
 * Check if the filter is currently active.
 */
export const findOptionsForSelectFilter = (filterId, filters) => {
  const filter = filters.find(f => f.id === filterId);
  return filter && filter.config && filter.config.options ? filter.config.options : [];
};

export const getSuggestionSearchParamsStringify = (listing) => {
  try {
    const listingCity = path(['attributes', 'publicData', 'location', 'city'], listing) ||
      path(['attributes', 'publicData', 'location', 'addressDetails', 'city'], listing);
    const origin = path(['attributes', 'geolocation'], listing);
    const distanceRadius = 100000;
    const bounds = locationBounds(origin, distanceRadius);
    const searchLocationData = {
      origin,
      bounds,
    };
    return stringify({
      address: listingCity || 'suggestion',
      ...searchLocationData,
    });
  }
  catch (e) {
    return null;
  }
}

// extract search parameters, including a custom URL params
// which are validated by mapping the values to marketplace custom config.
export const pickSearchParamsOnly = (params, filters, sortConfig) => {
  const { address, origin, bounds, ...rest } = params || {};
  const boundsMaybe = bounds ? { bounds } : {};
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  const filterParams = validFilterParams(rest, filters);
  const sort = rest[sortConfig.queryParamName];
  const sortMaybe = sort ? { sort } : {};

  return {
    ...boundsMaybe,
    ...originMaybe,
    ...filterParams,
    ...sortMaybe,
  };
};

export const typeOfPoolSearchParams = (typeOfPool) => {
  switch (typeOfPool) {
    case 'offSeason':
      return { pub_extras: 'has_any:piscine_interieure,jacuzzi,piscine_chauffee,sauna' };
    case 'event':
      return { pub_policyEvent: true };
    case 'jacuzzi':
      return { pub_extras: 'has_any:jacuzzi' };
    case 'sauna':
      return { pub_extras: 'has_any:sauna' };

    default:
      return {};
  }
};

export const getSearchParams = (currentSearch, nextSearchValues) => {
  const { address, bounds, mapSearch, origin, page, ...restOfCurrentSearch } = currentSearch;
  const {
    adults,
    bookingDate,
    children,
    endTime,
    infants,
    isFlexible,
    location,
    startTime,
    typeOfPool,
  } = nextSearchValues;

  const search = pathOr('', ['search'], location);
  const selectedPlace = pathOr({}, ['selectedPlace'], location);
  const { city } = selectedPlace;
  const originMaybe = config.sortSearchByDistance
    ? { origin: location ? selectedPlace.origin : origin }
    : {};
  const bookingDateMaybe = bookingDate
    ? { bookingDate: typeof bookingDate !== 'string' ? bookingDate.toISOString() : bookingDate }
    : { bookingDate: undefined };
  const timeSlotMaybe = bookingDate && startTime && endTime
    ? { endTime, isFlexible, startTime }
    : { endTime: undefined, isFlexible: undefined, startTime: undefined };
  const adultsMaybe = adults > 0 ? { adults } : { adults: undefined };
  const childrenMaybe = adults > 0 && children > 0 ? { children } : { children: undefined };
  const infantsMaybe = adults > 0 && infants > 0 ? { infants } : { infants: undefined };
  const mapSearchMaybe = !location && { mapSearch };
  const typeOfPoolMaybe = typeOfPool ? { typeOfPool } : { typeOfPool: undefined };

  return {
    ...restOfCurrentSearch,
    ...adultsMaybe,
    ...childrenMaybe,
    ...bookingDateMaybe,
    ...infantsMaybe,
    ...mapSearchMaybe,
    ...originMaybe,
    ...timeSlotMaybe,
    ...typeOfPoolMaybe,
    address: location ? search : address,
    bounds: location ? locationBounds(location ? selectedPlace.origin : origin, config.maps.search.currentLocationBoundsDistance) : bounds,
    city,
  };
}

export const getInitialSearchValues = (currentSearch) => {
  const {
    address,
    adults,
    bookingDate,
    bounds,
    children,
    city,
    endTime,
    infants,
    isFlexible,
    origin,
    startTime,
    typeOfPool,
  } = currentSearch;
  const hasLocationValue = address && origin && bounds;
  const initialLocation = hasLocationValue && { location: {
    search: address,
    predictions: [],
    selectedPlace: { address, origin, bounds, city },
  }};

  return {
    ...initialLocation,
    ...typeOfPool && { typeOfPool },
    ...bookingDate && { bookingDate },
    ...startTime && { startTime },
    ...endTime && { endTime },
    ...isFlexible && { isFlexible: ['true'] },
    ...adults && { adults },
    ...children && { children },
    ...infants && { infants },
  };
}

export const getTotalGuestsFromSearch = (searchParams) => {
  const { adults, children } = searchParams;
  const adultsValue = adults > 0 ? adults : 0;
  const childrenValue = children > 0 ? children : 0;

  return adultsValue + childrenValue;
}
