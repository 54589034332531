import { find, path, pathOr } from 'ramda';
// @ts-ignore
import { types as sdkTypes } from 'sharetribe-flex-sdk';

import {
  CUSTOMER_COMMISSION_PERCENTAGE,
  DEFAULT_PRICING_BASE_GUEST_COUNT,
  PRICING_VARIATION_WEEKDAYS_ID,
} from '../constants/pricing';
import {
  IListingAttributes,
  IMoney,
  IPricingVariation,
  ISearchParams,
} from '../utils/interface';
import { moment } from '../utils/date';
import { getTotalGuestsFromSearch } from '../utils/search';
import { getTimeStrAsObj } from '../utils/time';

const { Money } = sdkTypes;

interface IBookingParams {
  bookingStart: object,
  bookingEnd: object,
  childrenCount: number,
  guestCount: number,
  totalHours: number
};

export const formatBookingParams = (searchParams: ISearchParams) => {
  const { bookingDate, endTime, startTime } = searchParams;
  const startingHour = startTime ? getTimeStrAsObj(startTime).hours : 0;
  const endingHour = endTime ? getTimeStrAsObj(endTime).hours : 0;
  const bookingStart = moment(bookingDate).hours(startingHour);
  const bookingEnd = moment(bookingDate).hours(endingHour);

  return {
    bookingEnd,
    bookingStart,
    childrenCount: searchParams.children || 0,
    guestCount: getTotalGuestsFromSearch(searchParams),
    totalHours: startTime && endTime ? moment(bookingEnd).diff(moment(bookingStart), 'hours') : 1
  }
}

export const getPriceWithCustomerCommission = (price: IMoney, guestCount: Number) => {
  const customerCommissionValue = guestCount >= 10 ? 10 : CUSTOMER_COMMISSION_PERCENTAGE;

  return new Money(price.amount * (100 + customerCommissionValue) / 100, price.currency);
}

export const getWeekdayPricingVariation = (pricingVariationByPeriod: IPricingVariation[]) => {
  if (!pricingVariationByPeriod) return;

  return find((variation: { id: string }) => variation.id === PRICING_VARIATION_WEEKDAYS_ID, pricingVariationByPeriod);
};

export const getBasePricePerHourWithVariation = (listingAttributes: IListingAttributes, pricingVariation: IPricingVariation) => {
  const { price } = listingAttributes;
  const variationValue = pathOr(0, ['variation'], pricingVariation);
  const priceWithVariationAmount = variationValue ? price.amount * (100 - variationValue) / 100 : price.amount;

  return new Money(priceWithVariationAmount, price.currency);
};

export const getPricingVariationByPeriodValue = (listingAttributes: IListingAttributes, bookingDate: Object) => {
  const pricingVariationByPeriod = pathOr([], ['publicData', 'pricingVariationByPeriod'], listingAttributes);

  if (pricingVariationByPeriod.length === 0) return undefined;

  const bookingDay = moment(bookingDate).day();
  const isWeekdayBooking = bookingDay > 0 && bookingDay < 6;

  switch (true) {
    case isWeekdayBooking:
      const variation = find((variation: IPricingVariation) => variation.id === PRICING_VARIATION_WEEKDAYS_ID, pricingVariationByPeriod);

      return pathOr(0, ['variation'], variation);

    default:
      return undefined;
  }
};

const getExtraGuestsPrice = (listingAttributes: IListingAttributes, guestCount: number) => {
  const pricePerExtraGuestAmount = pathOr(0, ['publicData', 'pricePerExtraGuest'], listingAttributes);
  const currency = path(['price', 'currency'], listingAttributes);

  if (!pricePerExtraGuestAmount) return new Money(0, currency);

  const pricingBaseGuestCount = pathOr(DEFAULT_PRICING_BASE_GUEST_COUNT, ['publicData', 'pricingBaseNumberOfPeople'], listingAttributes);
  const hasExtraGuests = guestCount > pricingBaseGuestCount;
  const extraGuestCount = hasExtraGuests ? guestCount - pricingBaseGuestCount : 0;

  return new Money(extraGuestCount * pricePerExtraGuestAmount, currency);
};

const getPricePerHourOld = (listingAttributes: IListingAttributes, bookingParams: IBookingParams) => {
  const { price } = listingAttributes;
  const { bookingStart, guestCount } = bookingParams;
  const variationByPeriod = getPricingVariationByPeriodValue(listingAttributes, bookingStart);
  const priceWithVariationAmount = variationByPeriod ? price.amount * (100 - variationByPeriod) / 100 : price.amount;

  if (!guestCount) return new Money(priceWithVariationAmount, price.currency);

  const extraGuestsPrice = getExtraGuestsPrice(listingAttributes, guestCount);
  const pricePerHourAmount = priceWithVariationAmount + extraGuestsPrice.amount;

  return new Money(pricePerHourAmount, price.currency);
};

const getTotalPriceOld = (listingAttributes: IListingAttributes, bookingParams: IBookingParams, pricePerHour: IMoney) => {
  const { price } = listingAttributes;
  const pricingExtraHours = path(['publicData', 'pricingExtraHours'], listingAttributes);
  const { totalHours } = bookingParams;

  const fromNbHours = pathOr(3, ['fromNbHours'], pricingExtraHours);
  const hasDiscountedHours = totalHours > fromNbHours;

  if (!pricingExtraHours || !hasDiscountedHours) {
    const totalPriceAmount = pricePerHour.amount * totalHours;

    return new Money(totalPriceAmount, price.currency);
  }

  const variation = pathOr(1, ['variation'], pricingExtraHours);
  const discountedHours = totalHours - fromNbHours;
  const discountPerExtraHour = price.amount * variation / 100;
  const totalPriceAmount = pricePerHour.amount * totalHours - (discountPerExtraHour * discountedHours);

  return new Money(totalPriceAmount, price.currency);
};

const getPricePerHour = (listingAttributes: IListingAttributes, bookingParams: IBookingParams) => {
  const { price } = listingAttributes;
  const { bookingStart } = bookingParams;
  const variationByPeriod = getPricingVariationByPeriodValue(listingAttributes, bookingStart);
  const priceWithVariationAmount = variationByPeriod ? price.amount * (100 - variationByPeriod) / 100 : price.amount;

  return new Money(priceWithVariationAmount, price.currency);
}

const getTotalPrice = (listingAttributes: IListingAttributes, bookingParams: IBookingParams, pricePerHour: IMoney) => {
  const { price } = listingAttributes;
  const pricingExtraHours = path(['publicData', 'pricingExtraHours'], listingAttributes);
  const { childrenCount, guestCount, totalHours } = bookingParams;

  const fromNbHours = pathOr(3, ['fromNbHours'], pricingExtraHours);
  const hasDiscountedHours = totalHours > fromNbHours;
  const childrenDiscountAmount = childrenCount ? (price.amount * childrenCount) * 0.5 * totalHours : 0;

  if (!pricingExtraHours || !hasDiscountedHours) {
    const totalPriceAmount = pricePerHour.amount * totalHours * guestCount;

    return new Money(totalPriceAmount - childrenDiscountAmount, price.currency);
  }

  const variation = pathOr(1, ['variation'], pricingExtraHours);
  const discountedHours = totalHours - fromNbHours;
  const discountPerExtraHour = price.amount * variation / 100;
  const totalPriceAmount = (pricePerHour.amount * totalHours - (discountPerExtraHour * discountedHours)) * guestCount;

  return new Money(totalPriceAmount - childrenDiscountAmount, price.currency);
}

export const getPrices = (listingAttributes: IListingAttributes, searchParams: ISearchParams, isTransactionHourlyPlan: Boolean) => {
  const { price } = listingAttributes;
  const { bookingDate, startTime, endTime, adults } = searchParams;
  const bookingParams = formatBookingParams(searchParams);
  const { guestCount, totalHours } = bookingParams;
  const pricePerHour = isTransactionHourlyPlan ? getPricePerHour(listingAttributes, bookingParams) : getPricePerHourOld(listingAttributes, bookingParams);

  switch (true) {
    case bookingDate && startTime && endTime && !!adults:
    case bookingDate && startTime && !!endTime:
      const totalPrice = isTransactionHourlyPlan ? getTotalPrice(listingAttributes, bookingParams, pricePerHour) : getTotalPriceOld(listingAttributes, bookingParams, pricePerHour);
      const totalPriceWithFee = getPriceWithCustomerCommission(totalPrice, guestCount);

      return {
        mainPrice: new Money(isTransactionHourlyPlan ? (totalPriceWithFee.amount / totalHours / guestCount) : (totalPriceWithFee.amount / totalHours), price.currency),
        secondaryPrice: totalPriceWithFee,
      }

    case !!bookingDate:
      return {
        mainPrice: getPriceWithCustomerCommission(pricePerHour, guestCount),
      }
    
    default:
      return {}
  }
}
