import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconSavings.module.css';

const IconSavings = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M16 11Q16.425 11 16.712 10.712Q17 10.425 17 10Q17 9.575 16.712 9.287Q16.425 9 16 9Q15.575 9 15.288 9.287Q15 9.575 15 10Q15 10.425 15.288 10.712Q15.575 11 16 11ZM9 9H12Q12.425 9 12.713 8.712Q13 8.425 13 8Q13 7.575 12.713 7.287Q12.425 7 12 7H9Q8.575 7 8.288 7.287Q8 7.575 8 8Q8 8.425 8.288 8.712Q8.575 9 9 9ZM5.925 21Q5.35 21 4.812 20.6Q4.275 20.2 4.1 19.625Q3.475 17.525 3.075 15.987Q2.675 14.45 2.438 13.287Q2.2 12.125 2.1 11.225Q2 10.325 2 9.5Q2 7.2 3.6 5.6Q5.2 4 7.5 4H12.5Q13.175 3.1 14.213 2.55Q15.25 2 16.5 2Q17.125 2 17.562 2.438Q18 2.875 18 3.5Q18 3.65 17.962 3.8Q17.925 3.95 17.875 4.075Q17.775 4.35 17.688 4.625Q17.6 4.9 17.55 5.225L19.825 7.5H21Q21.425 7.5 21.712 7.787Q22 8.075 22 8.5V13.75Q22 14.075 21.812 14.325Q21.625 14.575 21.3 14.7L19.175 15.4L17.925 19.575Q17.725 20.225 17.2 20.613Q16.675 21 16 21H14Q13.175 21 12.588 20.413Q12 19.825 12 19H10Q10 19.825 9.413 20.413Q8.825 21 8 21ZM6 19H8Q8 19 8 19Q8 19 8 19V17H14V19Q14 19 14 19Q14 19 14 19H16Q16 19 16 19Q16 19 16 19L17.55 13.85L20 13.025V9.5H19L15.5 6Q15.5 5.5 15.562 5.025Q15.625 4.55 15.75 4.1Q15.025 4.3 14.475 4.787Q13.925 5.275 13.675 6H7.5Q6.05 6 5.025 7.025Q4 8.05 4 9.5Q4 10.525 4.525 13.012Q5.05 15.5 6 19Q6 19 6 19Q6 19 6 19ZM12 11.55Q12 11.55 12 11.55Q12 11.55 12 11.55Q12 11.55 12 11.55Q12 11.55 12 11.55Q12 11.55 12 11.55Q12 11.55 12 11.55Q12 11.55 12 11.55Q12 11.55 12 11.55Q12 11.55 12 11.55Q12 11.55 12 11.55Q12 11.55 12 11.55Q12 11.55 12 11.55Q12 11.55 12 11.55Q12 11.55 12 11.55Q12 11.55 12 11.55Q12 11.55 12 11.55Z"/>
    </svg>
  );
};

IconSavings.defaultProps = {
  rootClassName: null,
  className: null,
};

IconSavings.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSavings;
