import React, { Fragment } from 'react';
import { bool, object, string } from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { map, path, pathOr } from 'ramda';

import { IconArrowNavigation } from '../../../components/Icons';
import { Divider } from '../../../components';

import {
  BASE_PRICING,
  INSTANT_BOOKING,
  OVERTIME_DISCOUNT,
  PAID_EXTRAS,
  WEEKDAY_DISCOUNT,
} from '../EditListingPricingPanel.Helpers';
import css from './TabNavigation.module.css';

const TabNavigation = (props) => {
  const {
    className,
    intl,
    isMobile,
    listing,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const { pathname, state } = location;
  const listingPublicData = pathOr({}, ['attributes', 'publicData'], listing);
  const hasWeekendPricing = listingPublicData.pricingVariationByPeriod;
  const hasOvertimeDiscount = listingPublicData.pricingExtraHours;
  const hasInstantBooking = listingPublicData.instantBookingFrom;
  const handleClick = (modalName) => {
    history.push(`${pathname}?show=${modalName}`, state);
  };
  const editLinkText = intl.formatMessage({ id: 'Marketplace.edit' });
  const classes = classNames(css.root, className);
  const items = [
    {
      key: 1,
      title: intl.formatMessage({ id: 'EditListingPricingPanel.basePricingTitle' }),
      subtitle: intl.formatMessage({ id: 'EditListingPricingPanel.basePricingSubtitle' }),
      show: BASE_PRICING,
    },
    {
      key: 2,
      title: intl.formatMessage({ id: 'EditListingPricingPanel.weekdayDiscountTitle' }),
      subtitle: intl.formatMessage({ id: 'EditListingPricingPanel.weekdayDiscountSubtitle' }),
      state: intl.formatMessage({ id: `Marketplace.${hasWeekendPricing ? 'activated' : 'deactivated'}` }),
      isActive: hasWeekendPricing,
      value: `- ${path([[0], 'variation'], hasWeekendPricing)} %`,
      show: WEEKDAY_DISCOUNT,
    },
    {
      key: 3,
      title: intl.formatMessage({ id: 'EditListingPricingPanel.overtimeDiscountTitle' }),
      subtitle: intl.formatMessage({ id: 'EditListingPricingPanel.overtimeDiscountSubtitle' }),
      state: intl.formatMessage({ id: `Marketplace.${hasOvertimeDiscount ? 'activated' : 'deactivated'}` }),
      isActive: hasOvertimeDiscount,
      value: `- ${path(['variation'], hasOvertimeDiscount)} %`,
      show: OVERTIME_DISCOUNT,
    },
    {
      key: 4,
      title: intl.formatMessage({ id: 'EditListingPricingPanel.instantBookingTitle' }),
      subtitle: intl.formatMessage({ id: 'EditListingPricingPanel.instantBookingSubtitle' }),
      state: intl.formatMessage({ id: `Marketplace.${hasInstantBooking ? 'activated' : 'deactivated'}` }),
      isActive: hasInstantBooking,
      value: `- ${hasInstantBooking} €`,
      show: INSTANT_BOOKING,
    },
    {
      key: 5,
      title: intl.formatMessage({ id: 'EditListingPricingPanel.paidExtrasTitle' }),
      subtitle: intl.formatMessage({ id: 'EditListingPricingPanel.paidExtrasSubtitle' }),
      show: PAID_EXTRAS,
    },
  ];

  return (
    <div className={classes}>
      {map((item) => (
        <Fragment key={item.key}>
          <button className={css.item} onClick={() => handleClick(item.show)}>
            <div className={css.leftContainer}>
              <span className={css.itemTitle}>{item.title}</span>
              <span className={css.itemSubtitle}>{item.subtitle}</span>
              {item.state && (
                <div className={css.stateWrapper}>
                  <span className={item.isActive ? css.itemActive : css.itemInactive}>{item.state}</span>
                  {item.isActive && <span className={css.itemValue}>{item.value}</span>}
                </div>
              )}
            </div>
            <div className={css.rightContainer}>
              {isMobile ? (
                <IconArrowNavigation className={css.iconArrowNavigation} direction="right" />
              ) : (
                <span className={css.editLink}>{editLinkText}</span>
              )}
            </div>
          </button>
          {item.key < 5 && <Divider />}
        </Fragment>
      ), items)}
    </div>
  )
}

TabNavigation.defaultProps = {
  className: null,
  listing: null,
};

TabNavigation.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  isMobile: bool.isRequired,
  listing: object,
};

export default injectIntl(TabNavigation);