import React, { Fragment, useState } from 'react';
import { func, string } from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { parse } from '../../util/urlHelpers';
import {
  getInitialSearchValues,
  getSearchParams,
  validFilterParams,
} from '../../util/search';
import { isSeason } from '../../util/dates';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { Form } from '../../components';
import { ModalSwimmy, ModalOverlay } from '../../components/ModalSwimmy';

import {
  AnimatedView,
  DateView,
  LocationView,
  ParticipantsView,
  SearchButton,
  SearchContainer,
  SearchFilters,
  TypeView,
} from './components';
import css from './Search.module.css';

const Search = (props) => {
  const {
    className,
    currentPage,
    filterConfig,
    fixed,
    onManageDisableScrolling,
  } = props;
  const [showSearchContainer, setShowSearchContainer] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [currentView, setCurrentView] = useState();
  const history = useHistory();
  const location = useLocation();
  const isSearchPage = currentPage === 'SearchPage';
  const parsedSearch = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { mapSearch, page, ...searchInURL } = parsedSearch;
  const handleSearchButtonClick = () => {
    if (isSearchPage) {
      setShowSearchContainer(true);
    } else {
      setCurrentView('LocationView');
    }
  };
  const handleShowFilters = () => {
    setShowSearchContainer(false);
    setShowFilters(true);
  };
  const handleCloseFilters = () => setShowFilters(false);
  const handleSubmit = (values) => {
    setCurrentView();
    setShowSearchContainer(false);
    const searchParams = getSearchParams(parsedSearch, values);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };
  const showLocationView = currentView === 'LocationView';
  const showTypeView = currentView === 'TypeView';
  const showDateView = currentView === 'DateView';
  const showParticipantsView = currentView === 'ParticipantsView';
  const initialValues = getInitialSearchValues(parsedSearch);
  const selectedFilters = validFilterParams(searchInURL, filterConfig);
  const selectedFiltersCount = Object.keys(selectedFilters).length;
  const hasActiveFilters = selectedFiltersCount > 0;
  const classes = classNames(css.form, {
    [css.formFixed]: fixed,
    [css.formSearchPage]: isSearchPage,
    [css.formSearchContainer]: showSearchContainer,
  }, className);

  return (
    <Fragment>
      <FinalForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={formRenderProps => {
          const {
            dirty,
            form,
            handleSubmit,
            values,
          } = formRenderProps;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {showSearchContainer ? (
                <SearchContainer
                  className={css.searchContainer}
                  handleClose={() => setShowSearchContainer(false)}
                  handleShowFilters={handleShowFilters}
                  handleView={setCurrentView}
                  hasActiveFilters={hasActiveFilters}
                  mapSearch={mapSearch}
                  searchParams={searchInURL}
                />
              ) : (
                <SearchButton
                  className={css.searchButton}
                  handleShowFilters={handleShowFilters}
                  hasActiveFilters={hasActiveFilters}
                  isSearchPage={isSearchPage}
                  mapSearch={mapSearch}
                  onClick={handleSearchButtonClick}
                  searchParams={searchInURL}
                />
              )}
              <ModalSwimmy
                id="searchModal"
                isOpen={!!currentView}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <ModalOverlay className={css.modalOverlay} />
                <AnimatedView show={showLocationView}>
                  <LocationView
                    className={css.view}
                    dirty={dirty}
                    formProps={form}
                    formValues={values}
                    handleView={setCurrentView}
                    isSearchPage={isSearchPage}
                  />
                </AnimatedView>
                {!isSeason() && (
                  <AnimatedView show={showTypeView}>
                    <TypeView
                      className={css.view}
                      formProps={form}
                      formValues={values}
                      handleView={setCurrentView}
                    />
                  </AnimatedView>
                )}
                <AnimatedView show={showDateView}>
                  <DateView
                    className={css.view}
                    formProps={form}
                    formValues={values}
                    handleView={setCurrentView}
                    isSearchPage={isSearchPage}
                  />
                </AnimatedView>
                <AnimatedView show={showParticipantsView}>
                  <ParticipantsView
                    className={css.view}
                    formProps={form}
                    formValues={values}
                    handleView={setCurrentView}
                    isSearchPage={isSearchPage}
                  />
                </AnimatedView>
              </ModalSwimmy>
            </Form>
          )
        }}
      />
      <SearchFilters
        mapSearch={mapSearch}
        onClose={handleCloseFilters}
        show={showFilters}
        urlQueryParams={searchInURL}
      />
    </Fragment>
  )
}

Search.defaultProps = {
  className: null,
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
};

Search.propTypes = {
  className: string,
  onManageDisableScrolling: func.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
  };
};

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(Search);
