import React from 'react';
import classNames from 'classnames';

import { IconClose } from '../../components';

import css from './FieldResetButton.module.css';

const FieldResetButton = (props) => {
  const { className, fieldName, finalFormApi, onClick } = props;
  const handleReset = () => {
    if (onClick) return onClick();
    
    finalFormApi.change(fieldName, undefined);
    finalFormApi.focus(fieldName);
  };
  const classes = classNames(css.root, className);

  return (
    <button className={classes} type="button" onClick={handleReset}>
      <IconClose className={css.iconClose} />
    </button>
  )
}

export default FieldResetButton;