import React, { Fragment } from 'react';
import { array, bool, func, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { path } from 'ramda';

import { propTypes } from '../../../util/types';
import { IconClose } from '../../../components/Icons';

import {
  requestAddAvailabilityExceptions,
  requestDeleteAvailabilityExceptions,
} from '../CalendarPage.duck';
import ExceptionManager from './ExceptionManager/ExceptionManager';
import Footer from './Footer/Footer';
import FullAvailability from './FullAvailability/FullAvailability';
import css from './SidePanel.module.css';

const SidePanel = (props) => {
  const {
    className,
    addExceptionInProgress,
    availabilityExceptions,
    currentListing,
    deleteExceptionInProgress,
    handleResetDates,
    intl,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    selectedDates,
    transactions
  } = props;
  const routeParams = useParams();
  const selectedDatesLength = selectedDates.length;
  const hasSelectedDates = selectedDatesLength > 0;
  const availabilityPlan = path(['attributes', 'availabilityPlan'], currentListing);
  const helpStartText = intl.formatMessage({ id: 'CalendarPage.SidePanel.helperStart' });
  const selectedDatesText = hasSelectedDates && selectedDatesLength === 1
    ? selectedDates[0].format('dddd D MMMM')
    : intl.formatMessage({ id: 'CalendarPage.SidePanel.selectedDates' }, { count: selectedDatesLength });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      {hasSelectedDates ? (
        <Fragment>
          <div className={css.header}>
            <button className={css.resetButton} onClick={handleResetDates}>
              <IconClose />
            </button>
            <span className={css.title}>{selectedDatesText}</span>
          </div>
          <div className={css.content}>
            <FullAvailability
              className={css.fullAvailability}
              availabilityExceptions={availabilityExceptions}
              availabilityPlan={availabilityPlan}
              intl={intl}
              listingId={routeParams.id}
              onAddAvailabilityException={onAddAvailabilityException}
              onDeleteAvailabilityException={onDeleteAvailabilityException}
              selectedDates={selectedDates}
            />
            {selectedDatesLength === 1 && (
              <ExceptionManager
                className={css.exceptionManager}
                addExceptionInProgress={addExceptionInProgress}
                availabilityExceptions={availabilityExceptions}
                availabilityPlan={availabilityPlan}
                deleteExceptionInProgress={deleteExceptionInProgress}
                intl={intl}
                listingId={routeParams.id}
                onAddAvailabilityException={onAddAvailabilityException}
                onDeleteAvailabilityException={onDeleteAvailabilityException}
                selectedDates={selectedDates}
                transactions={transactions}
              />
            )}
          </div>
        </Fragment>
      ) : (
        <div className={css.content}>
          <span className={css.helperStart}>{helpStartText}</span>
        </div>
      )}
      <Footer className={css.footer} intl={intl} />
    </div>
  )
}

SidePanel.defaultProps = {
  className: null,
};

SidePanel.propTypes = {
  className: string,
  addExceptionInProgress: bool.isRequired,
  availabilityExceptions: array.isRequired,
  currentListing: propTypes.ownListing,
  deleteExceptionInProgress: bool.isRequired,
  handleResetDates: func.isRequired,
  intl: intlShape.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  selectedDates: array.isRequired,
  transactions: array.isRequired
};

const mapStateToProps = state => {
  const {
    addExceptionInProgress,
    deleteExceptionInProgress,
    transactions
  } = state.CalendarPage;

  return {
    addExceptionInProgress,
    deleteExceptionInProgress,
    transactions
  }
};

const mapDispatchToProps = dispatch => ({
  onAddAvailabilityException: (params) => dispatch(requestAddAvailabilityExceptions(params)),
  onDeleteAvailabilityException: (params) => dispatch(requestDeleteAvailabilityExceptions(params)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SidePanel);