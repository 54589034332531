import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { fetchCurrentUser, sendVerificationEmail } from '../../ducks/user.duck';
import { ContactDetailsForm } from '../../forms/index';

export const ContactDetailsComponent = props => {
  const {
    changeEmailError,
    currentUser,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    phoneNumber,
  } = props;
  const user = ensureCurrentUser(currentUser);
  if (!user.id) return null;

  const currentEmail = user.attributes.email || '';
  const protectedData = user.attributes.profile.protectedData || {};
  const currentPhoneNumber = protectedData.phoneNumber || '';

  return (
    <ContactDetailsForm
      initialValues={{ email: currentEmail, phoneNumber: currentPhoneNumber }}
      saveEmailError={changeEmailError}
      currentUser={currentUser}
      onResendVerificationEmail={onResendVerificationEmail}
      sendVerificationEmailInProgress={sendVerificationEmailInProgress}
      sendVerificationEmailError={sendVerificationEmailError}
      phoneNumber={phoneNumber}
    />
  );
};

ContactDetailsComponent.defaultProps = {
  changeEmailError: null,
  savePhoneNumberError: null,
  currentUser: null,
  sendVerificationEmailError: null,
};

const { bool, func } = PropTypes;

ContactDetailsComponent.propTypes = {
  changeEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  currentUser: propTypes.currentUser,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const {
    changeEmailError,
  } = state.userSettings;

  return {
    changeEmailError,
    currentUser,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  };
};

const mapDispatchToProps = dispatch => ({
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
});

const Index = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(
  ContactDetailsComponent,
);

Index.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default Index;
