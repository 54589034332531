import React from 'react';
import { find, path } from 'ramda';
import {
  LINE_ITEM_CUSTOM_EXTRA,
  LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT,
  LINE_ITEM_PROVIDER_COMMISSION_DISCOUNT,
  LINE_ITEM_UNITS_BASE,
  LINE_ITEM_UNITS_BASE_HOURS_DISCOUNT,
  LINE_ITEM_UNITS_EXTRA_GUESTS,
  LINE_ITEM_UNITS_HOURLY,
  LINE_ITEM_UNITS_HOURLY_CHILDREN,
  LINE_ITEM_WEEK_REDUCTION,
} from '@swimmy_/helpers';

import {
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/constants';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { REVERSAL_TRANSITIONS } from '../../util/transactions';
import {
  IconDiscount,
  IconExtraCustom,
  IconPersonAdd,
  IconPool,
  IconSettings
} from '../../components/Icons';

const { Money } = sdkTypes;

export const isReversalTransaction = (transaction) => {
  const lastTransition = path(['attributes', 'lastTransition'], transaction);
  
  return REVERSAL_TRANSITIONS.includes(lastTransition);
};

export const getLineItemProps = (intl, lineItem, isCustomer, pricingData, isReversal) => {
  const {
    code,
    lineTotal,
    percentage,
    quantity,
    reversal,
    seats,
    units,
    unitPrice
  } = lineItem;
  if (reversal !== isReversal) return null;

  const lineTotalAsMoney = new Money(lineTotal.amount, lineTotal.currency);
  const unitPriceAsMoney = new Money(unitPrice.amount, unitPrice.currency);
  const formattedLineTotal = formatMoney(intl, lineTotalAsMoney);
  const formattedUnitPrice = formatMoney(intl, unitPriceAsMoney);
  const { paidExtrasData, pricingBaseNumberOfPeople } = pricingData;
  const isExtraLineItem = code.startsWith(LINE_ITEM_CUSTOM_EXTRA);

  if (isExtraLineItem) {
    const extraData = find(extra => extra.id === code.split('/')[2], paidExtrasData);
    const isByUnit = extraData.isByUnit;
    return {
      icon: <IconExtraCustom />,
      ...isByUnit ? {
        label: extraData.label,
        sublabel: intl.formatMessage(
          { id: 'PriceBreakdown.lineItemCustomExtraByUnitSublabel' },
          {
            price: formattedUnitPrice,
            count: quantity ? quantity.toString() : 0
          }),
      } : {
        label: extraData.label
      },
      price: formattedLineTotal,
    }
  }

  switch (code) {
    case LINE_ITEM_UNITS_BASE:
      return {
        icon: <IconPool />,
        label: intl.formatMessage(
          { id: 'PriceBreakdown.lineItemUnitsBaseLabel' },
          {
            price: formattedUnitPrice,
            hours: quantity ? quantity.toString() : 0
          }),
        price: formattedLineTotal,
        sublabel: intl.formatMessage(
          { id: 'PriceBreakdown.lineItemUnitsBaseSublabel' },
          { count: pricingBaseNumberOfPeople }),
      }

    case LINE_ITEM_UNITS_HOURLY:
      return {
        icon: <IconPool />,
        label: intl.formatMessage(
          { id: 'PriceBreakdown.lineItemUnitsHourlyLabel' },
          {
            price: formattedUnitPrice,
            hours: units ? units.toString() : 0,
            guests: seats ? seats.toString() : 0
          }),
        price: formattedLineTotal,
      }

    case LINE_ITEM_UNITS_HOURLY_CHILDREN:
      if (isReversal) return null;

      return {
        icon: <IconDiscount />,
        label: intl.formatMessage({ id: 'PriceBreakdown.lineItemUnitsHourlyChildrenLabel' }),
        price: formattedLineTotal,
        sublabel: intl.formatMessage(
          { id: 'PriceBreakdown.lineItemUnitsHourlyChildrenSublabel' },
          { percentage: percentage.toString() }
        ),
      }

    case LINE_ITEM_WEEK_REDUCTION:
      if (isReversal) return null;

      return {
        icon: <IconDiscount />,
        label: intl.formatMessage(
          { id: 'PriceBreakdown.lineItemWeekReductionLabel' }),
        price: formattedLineTotal,
        sublabel: intl.formatMessage(
          { id: 'PriceBreakdown.lineItemWeekReductionSublabel' },
          { percentage: percentage.toString() }),
      }

    case LINE_ITEM_UNITS_BASE_HOURS_DISCOUNT:
      if (isReversal) return null;

      return {
        icon: <IconDiscount />,
        label: intl.formatMessage(
          { id: 'PriceBreakdown.lineItemUnitsBaseHoursDiscountLabel' },
          { percentage: percentage.toString() }),
        price: formattedLineTotal,
        sublabel: intl.formatMessage({ id: 'PriceBreakdown.lineItemUnitsBaseHoursDiscountSublabel' }),
      }

    case LINE_ITEM_UNITS_EXTRA_GUESTS:
      return {
        icon: <IconPersonAdd />,
        label: intl.formatMessage(
          { id: 'PriceBreakdown.lineItemUnitsExtraGuestsLabel' },
          {
            price: formattedUnitPrice,
            count: seats,
            hours: units,
          }),
        price: formattedLineTotal,
        sublabel: intl.formatMessage({ id: 'PriceBreakdown.lineItemUnitsExtraGuestsSublabel' }, { count: pricingBaseNumberOfPeople }),
      }

    case LINE_ITEM_CUSTOMER_COMMISSION:
      return isCustomer && {
        icon: <IconSettings />,
        label: intl.formatMessage({ id: 'PriceBreakdown.lineItemCommissionLabel' }),
        price: formattedLineTotal,
      }
      
    case LINE_ITEM_PROVIDER_COMMISSION:
      return !isCustomer && {
        icon: <IconSettings />,
        label: intl.formatMessage({ id: 'PriceBreakdown.lineItemCommissionLabel' }),
        price: formattedLineTotal,
      }

    case LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT:
      if (isReversal) return null;

      return isCustomer && {
        icon: <IconDiscount />,
        label: intl.formatMessage({ id: 'PriceBreakdown.lineItemCommissionDiscountLabel' }),
        price: formattedLineTotal,
        sublabel: intl.formatMessage({ id: 'PriceBreakdown.lineItemCommissionDiscountSublabel' }),
      }
      
    case LINE_ITEM_PROVIDER_COMMISSION_DISCOUNT:
      if (isReversal) return null;

      return !isCustomer && {
        icon: <IconDiscount />,
        label: intl.formatMessage({ id: 'PriceBreakdown.lineItemCommissionDiscountLabel' }),
        price: formattedLineTotal,
        sublabel: intl.formatMessage({ id: 'PriceBreakdown.lineItemCommissionDiscountSublabel' }),
      }

    default:
      return null;
  }
}
