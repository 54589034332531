import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconBolt.module.css';

const IconBolt = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M10 21 11 14H7.5Q7.125 14 7.037 13.8Q6.95 13.6 7.15 13.25L13 3H14L13 10H16.5Q16.875 10 16.962 10.2Q17.05 10.4 16.85 10.75L11 21Z"/>
    </svg>
  );
};

IconBolt.defaultProps = {
  rootClassName: null,
  className: null,
};

IconBolt.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconBolt;
