import React from 'react';
import { string } from 'prop-types';

import { propTypes } from '../../util/types';
import { getBookingDatesFromTx } from '../../util/transactions';
import { TimeRange } from '../../components';

const BookingTimeInfo = props => {
  const { bookingClassName, tx, dateType, timeZone } = props;
  const { start, end } = getBookingDatesFromTx(tx);

  return (
    <TimeRange
      className={bookingClassName}
      startDate={new Date(start)}
      endDate={new Date(end)}
      dateType={dateType}
      timeZone={timeZone}
    />
  );
};

BookingTimeInfo.defaultProps = { dateType: null, timeZone: null };

BookingTimeInfo.propTypes = {
  tx: propTypes.transaction.isRequired,
  dateType: propTypes.dateType,
  timeZone: string,
};

export default BookingTimeInfo;
