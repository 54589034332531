import React from 'react';
import { number } from 'prop-types';

import { SectionThumbnailLinks } from '../../components';
import css from './SwimmySectionPress.css';

const SwimmySectionPress = props => {
  const { maxItemsToDisplay, pressProps } = props;

  return (
    <SectionThumbnailLinks
      linksPerRow={3}
      maxItemsToDisplay={maxItemsToDisplay}
      imageWrapperClassName={css.linkImageWrapper}
      imageClassName={css.linkImage}
      linkClassName={css.link}
      {...pressProps}
    />
  );
};

SwimmySectionPress.defaultProps = {
  maxItemsToDisplay: 0,
};

SwimmySectionPress.propTypes = {
  maxItemsToDisplay: number,
};

export default SwimmySectionPress;
