import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaFacebook.css';

const IconSocialMediaFacebook = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.2502 1.58325H11.8752C10.8253 1.58325 9.81852 2.00029 9.07619 2.74262C8.33386 3.48495 7.91682 4.49177 7.91682 5.54159V7.91658H5.54182V11.0833H7.91682V17.4166H11.0835V11.0833H13.4585L14.2502 7.91658H11.0835V5.54159C11.0835 5.33162 11.1669 5.13026 11.3154 4.98179C11.4638 4.83333 11.6652 4.74992 11.8752 4.74992H14.2502V1.58325Z" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

IconSocialMediaFacebook.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaFacebook.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaFacebook;
