import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import { SlideAnimation } from '../../../components/Animations';

import css from './ModalContent.module.css';

const ModalContent = (props) => {
  const { children, className, animationType, show } = props;
  const classes = classNames(css.root, className);
  const content = () => {
    switch (animationType) {
      case 'slideBottom':
        return (
          <SlideAnimation inProps={show} appear={show} from="bottom" mountOnEnter>
            <div className={classes}>
              {children}
            </div>
          </SlideAnimation>
        );
      case 'slideRight':
        return (
          <SlideAnimation inProps={show} appear={show} from="right" mountOnEnter>
            <div className={classes}>
              {children}
            </div>
          </SlideAnimation>
        );

      default:
        return (
          <div className={classes}>
            {children}
          </div>
        );
    };
  };

  return content();
};

ModalContent.defaultProps = {
  className: null,
  animationType: null,
  show: false,
};

ModalContent.propTypes = {
  className: string,
  animationType: string,
  show: bool,
};

export default ModalContent;
