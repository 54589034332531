import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { number, shape, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { withViewport } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '../../containers';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  AccountView,
} from '../../components';

import PaymentMethodsTab from './components/PaymentMethodsTab/PaymentMethodsTab';
import PayoutDetailsTab from './components/PayoutDetailsTab/PayoutDetailsTab';
import css from './AccountPaymentsPage.module.css';

const MOBILE_MAX_WIDTH = 767;

const AccountPaymentsPage = (props) => {
  const {
    currentTab,
    currentUser,
    scrollingDisabled,
    viewport,
    intl,
  } = props;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const tabs = {
    'PaymentMethods': {
      tabName: 'PaymentMethods',
      routeName: 'AccountPaymentMethodsPage',
      title: intl.formatMessage({ id: 'AccountPaymentsPage.paymentMethodsTabTitle' }),
      component: <PaymentMethodsTab currentUser={ensuredCurrentUser} />,
      tipTitle: intl.formatMessage({ id: 'AccountPaymentsPage.paymentMethodsTabTipTitle' }),
      tip: intl.formatMessage({ id: 'AccountPaymentsPage.paymentMethodsTabTip' }),
    },
    'PayoutDetails': {
      tabName: 'PayoutDetails',
      routeName: 'AccountPayoutDetailsPage',
      title: intl.formatMessage({ id: 'AccountPaymentsPage.payoutDetailsTabTitle' }),
      component: <PayoutDetailsTab currentUser={ensuredCurrentUser} />,
      tipTitle: intl.formatMessage({ id: 'AccountPaymentsPage.payoutDetailsTabTipTitle' }),
      tip: intl.formatMessage({ id: 'AccountPaymentsPage.payoutDetailsTabTip' }),
    },
  };
  const pageTitle = intl.formatMessage({ id: 'AccountPaymentsPage.pageTitle' });
  const viewTitle = intl.formatMessage({ id: 'AccountPaymentsPage.viewTitle' });
  const isMobile = viewport.width <= MOBILE_MAX_WIDTH;
  
  return (
    <Page title={pageTitle} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="AccountPaymentsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <AccountView
            title={viewTitle}
            tabs={tabs}
            currentTab={currentTab}
            intl={intl}
          />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          {!isMobile && (
            <Footer />
          )}
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  )
};

AccountPaymentsPage.defaultProps = {
};

AccountPaymentsPage.propTypes = {
  currentTab: string.isRequired,
  currentUser: propTypes.currentUser,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

export default compose(connect(mapStateToProps), withViewport, injectIntl)(AccountPaymentsPage);
