import React, { Fragment, useState } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';

import { Avatar, AvatarMedium, ExpandableText, ReviewRating, SecondaryButton } from '../../components';
import { propTypes } from '../../util/types';
import css from './Reviews.css';

const authorDisplayName = (review, intl) => {
  return review.author.attributes.banned
    ? intl.formatMessage({ id: 'Reviews.bannedUserDisplayName' })
    : review.author.attributes.profile.displayName;
};

const MOBILE_BREAKPOINT = 768;
const REVIEW_MAX_LENGTH = 180;

const Review = props => {
  const { review, intl } = props;
  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });
  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < MOBILE_BREAKPOINT;

  return (
    <Fragment>
      <div className={css.reviewInfosContainer}>
        {isMobileLayout ? (
          <Avatar className={css.avatar} user={review.author} />
        ) : (
          <AvatarMedium className={css.avatar} user={review.author} />
        )}
        <div className={css.reviewInfos}>
          <p className={css.authorDisplayName}>
            {authorDisplayName(review, intl)}
          </p>
          <div className={css.reviewedAtAndRating}>
            <ReviewRating
              rating={review.attributes.rating}
              className={css.reviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
            <span className={css.separator}>•</span>
            {dateString}
          </div>
        </div>
      </div>
      <div className={css.reviewContainer}>
        <ExpandableText className={css.reviewContent} text={review.attributes.content} length={REVIEW_MAX_LENGTH} />
      </div>
    </Fragment>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const NUMBER_OF_REVIEWS_TO_DISPLAY = 4;

const ReviewsComponent = props => {
  const { className, rootClassName, reviewItemClassName, reviews, intl } = props;
  const [numberOfItems, setNumberofItems] = useState(NUMBER_OF_REVIEWS_TO_DISPLAY);
  const handleShowMoreClick = () => {
    setNumberofItems(numberOfItems + NUMBER_OF_REVIEWS_TO_DISPLAY);
  };
  const classes = classNames(rootClassName || css.root, className);
  const reviewItemClasses = classNames(css.reviewItem, reviewItemClassName);
  const showMoreButton = (
    <SecondaryButton className={css.showMoreButton} onClick={handleShowMoreClick}>
      <FormattedMessage id="Reviews.showMoreReviews" />
    </SecondaryButton>
  );

  return (
    <Fragment>
      <div className={classes}>
        {reviews.slice(0, numberOfItems).map(r => {
          return (
            <div key={`Review_${r.id.uuid}`} className={reviewItemClasses}>
              <Review review={r} intl={intl} />
            </div>
          );
        })}
      </div>
      {reviews.slice(0, numberOfItems).length < reviews.length && showMoreButton}
    </Fragment>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviewItemClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootClassName: string,
  reviewItemClassName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
