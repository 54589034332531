import React, { useState } from 'react';
import { isAndroid } from 'react-device-detect';
import cookie from 'cookie-cutter';
import { FormattedMessage } from 'react-intl';

import css from './style.css';
import { Button, ExternalLink, IconClose } from '../';
import SwimmyAppLogo from '../../assets/swimmy-app-logo.png';
import ReviewRating from '../ReviewRating/ReviewRating';

const isClient = typeof window !== 'undefined';

const androidAppId = 'com.swimmy.swimmy';

const expiredDateInUTC = additionalDays => {
  const expiredDate = new Date();

  expiredDate.setDate(expiredDate.getDate() + additionalDays);

  return expiredDate.toUTCString();
};

const SmartAppBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const hide = () => {
    setIsBannerVisible(false);
  };
  const handleCloseBanner = () => {
    hide();
    cookie.set('smartbanner-closed', 'true', {
      path: '/',
      expires: expiredDateInUTC(90),
    });
  };
  const handleInstallApp = () => {
    hide();
    cookie.set('smartbanner-installed', 'true', {
      path: '/',
      expires: expiredDateInUTC(90),
    });
  };
  if (
    !isAndroid ||
    !isClient ||
    window.navigator.standalone ||
    cookie.get('smartbanner-closed') ||
    cookie.get('smartbanner-installed') ||
    !isBannerVisible
  ) return null;

  return (
    <section className={css.section}>
      <div className={css.containerSmartApp}>
        <div className={css.close} onClick={handleCloseBanner}>
          <IconClose />
        </div>
        <div>
          <img src={SwimmyAppLogo} className={css.icon} alt={'app logo'} />
        </div>
        <div className={css.content}>
          <div>
            Swimmy
          </div>
          <div>
            <ReviewRating
              rating={5}
              reviewStarClassName={css.reviewRatingStar}
            />
          </div>
          <span className={css.description}>
          <FormattedMessage id='SmartAppBanner.Description' />
        </span>
        </div>
        <div className={css.installBtnContainer}>
          <ExternalLink href={`http://play.google.com/store/apps/details?id=${androidAppId}`}>
            <Button onClick={handleInstallApp} className={css.installBtn}>
              <FormattedMessage id='SmartAppBanner.InstallApp' />
            </Button>
          </ExternalLink>
        </div>
      </div>
    </section>
  );
};

export default SmartAppBanner;
