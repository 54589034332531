import React from 'react';

import { IconCancel, IconPending, IconVerified } from '../../components/Icons';

import css from './VerifiedBadges.module.css';

export const getIdentityData = (identityVerified, intl) => {
  switch (identityVerified) {
    case 'success':
      return {
        icon: (<IconVerified className={css.iconVerified} filled />),
      }
    case 'pending':
      const sublabelPending = intl.formatMessage({ id: 'VerifiedBadges.identityPending' });
      return {
        icon: (<IconPending className={css.iconPending} />),
        sublabel: (<span className={css.sublabel}>{sublabelPending}</span>),
      }
    
    default:
      const sublabelUnverified = intl.formatMessage({ id: 'VerifiedBadges.identityUnverified' });
      return {
        icon: (<IconCancel className={css.iconCancel} />),
        sublabel: (<span className={css.sublabel}>{sublabelUnverified}</span>),
      }
  }
}