import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  BOOKING_UPCOMING_TRANSITIONS,
  BOOKING_PAST_TRANSITIONS,
  BOOKING_PENDING_TRANSITIONS,
  REVERSAL_TRANSITIONS,
} from '../../util/transactions';
import { withEveningModeMaybe } from '../InboxPage/InboxPage.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { othersTab, pastTab, pendingTab, upcomingTab } from './ReservationsPage';

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/ReservationsPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/ReservationsPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/ReservationsPage/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = payload.data.data;
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const UPCOMING_PAGE_SIZE = 100;
const PAST_PAGE_SIZE = 6;
const PENDING_PAGE_SIZE = 100;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;

  const transitionsQuery = {
    [upcomingTab]: BOOKING_UPCOMING_TRANSITIONS,
    [pastTab]: BOOKING_PAST_TRANSITIONS,
    [pendingTab]: BOOKING_PENDING_TRANSITIONS,
    [othersTab]: REVERSAL_TRANSITIONS
  }

  const pageSize = tab === 'upcoming'
  ? UPCOMING_PAGE_SIZE
  : tab === 'past'
  ? PAST_PAGE_SIZE
  : PENDING_PAGE_SIZE;

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  const apiQueryParams = {
    lastTransitions: transitionsQuery[tab],
    include: ['listing.images', 'provider', 'provider.profileImage', 'customer', 'customer.profileImage', 'booking'],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'processVersion',
      'protectedData',
      'lineItems',
      'metadata',
      'createdAt'
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData', 'profile.protectedData'],
    'fields.image': ['variants.square-small', 'variants.landscape-crop'],
    page,
    per_page: pageSize,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      if (response.data.data.length === 0) {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchOrdersOrSalesSuccess(response));
        return response;
      } else {
        const responseWithEveningModeMaybe = withEveningModeMaybe(response);
        dispatch(addMarketplaceEntities(responseWithEveningModeMaybe));
        dispatch(fetchOrdersOrSalesSuccess(responseWithEveningModeMaybe));
        return responseWithEveningModeMaybe;
      }
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
