import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import { isServer } from '../../../../../util/sdkLoader';
import { FadeAnimation, SlideAnimation } from '../../../../../components/Animations';
import { IconAdd, NamedLink } from '../../../../../components';

import css from './AddListingLink.module.css';

const AddListingLink = (props) => {
  const {
    className,
    currentPage,
    intl,
    isMobile,
    show,
  } = props;
  const handleRender = (node) => {
    if (isMobile) {
      return ReactDOM.createPortal((
        <SlideAnimation inProps={show} from="bottom" mountOnEnter unmountOnExit>
          {node}
        </SlideAnimation>
      ), document.body);
    } else {
      return (
        <FadeAnimation inProps={show} mountOnEnter unmountOnExit>
          {node}
        </FadeAnimation>
      )
    }
  };
  const addListingLinkText = intl.formatMessage({
    id: currentPage === 'SimulatorPage' ? 'Topbar.addListingLink' : 'Topbar.simulatorLink'
  });
  const classes = classNames(css.root, className, {
    [css.uniqueButton]: currentPage === 'SimulatorPage',
  });

  return isServer() ? null : handleRender(
    <NamedLink
      className={classes}
      name={currentPage === 'SimulatorPage' ? 'NewListingPage' : 'SimulatorPage'}
    >
      <IconAdd className={css.icon} />
      <span>{addListingLinkText}</span>
    </NamedLink>
  )
};

export default AddListingLink;
