import unionWith from 'lodash/unionWith';
import { filter, omit, path } from 'ramda';

import config from '../../config';
import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { parse } from '../../util/urlHelpers';
import { typeOfPoolSearchParams } from '../../util/search';
import { klaviyoUpdateSearchCity } from '../../util/api';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import {
  getBookingDateSearchParams,
  getParticipantsSearchParams,
  reorderSearchResults,
} from './SearchPage.helpers';

const MAX_SIMILAR_LISTINGS = 3;
const RESULT_PAGE_SIZE = 36;
const RESULT_PAGE_SIZE_WITH_DATE = 100;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const SEARCH_SIMILAR_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_SIMILAR_LISTINGS_SUCCESS';
export const SET_SIMILAR_LISTINGS = 'app/SearchPage/SET_SIMILAR_LISTINGS';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  similarListingIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(reorderSearchResults(payload.data.data)),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(reorderSearchResults(payload.data.data)),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    
    case SEARCH_SIMILAR_LISTINGS_SUCCESS: {
      const resultsIdsWithoutCurrentListing = filter(id => id.uuid !== payload.listingId.uuid, resultIds(payload.data))
      const sortedResultIds = resultsIdsWithoutCurrentListing.sort(() => 0.5 - Math.random());
      const similarListingIds = sortedResultIds.slice(0, MAX_SIMILAR_LISTINGS);
      return {
        ...state,
        similarListingIds: similarListingIds,
        searchInProgress: false,
      };
    }
    case SET_SIMILAR_LISTINGS: {
      const resultsIdsWithoutCurrentListing = filter(id => id.uuid !== payload.listingId.uuid, payload.data)
      const sortedResultIds = resultsIdsWithoutCurrentListing.sort(() => 0.5 - Math.random());
      const similarListingIds = sortedResultIds.slice(0, MAX_SIMILAR_LISTINGS);
      return {
        ...state,
        similarListingIds: similarListingIds,
      };
    }

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchSimilarListingsSuccess = (response, listingId) => ({
  type: SEARCH_SIMILAR_LISTINGS_SUCCESS,
  payload: { data: response.data, listingId: listingId },
});

export const setSimilarListings = (response, listingId) => ({
  type: SET_SIMILAR_LISTINGS,
  payload: { data: response, listingId: listingId },
});

export const searchListings = (searchParams, search) => (dispatch, getState, sdk) => {
  const searchParamsWithOriginMaybe = config.sortSearchByDistance ? searchParams : omit(['origin'], searchParams);
  dispatch(searchListingsRequest(searchParamsWithOriginMaybe));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const {
    adults,
    children,
    endTime,
    isFlexible,
    infants,
    perPage,
    price,
    bookingDate,
    startTime,
    typeOfPool,
    origin,
    ...rest
  } = searchParamsWithOriginMaybe;
  const { address, bounds, city } = rest;
  const priceMaybe = priceSearchParams(price);
  const participantsMaybe = getParticipantsSearchParams(adults, children, infants);
  const typeOfPoolMaybe = typeOfPoolSearchParams(typeOfPool);
  const bookingDateMaybe = getBookingDateSearchParams(bookingDate, startTime, endTime, isFlexible);

  const params = {
    ...rest,
    ...priceMaybe,
    ...bookingDateMaybe,
    ...participantsMaybe,
    ...typeOfPoolMaybe,
    sort: 'meta_score',
    per_page: perPage,
  };

  const userEmail = path(['user', 'currentUser', 'attributes', 'email'], getState());
  if (userEmail && address && bounds && city && origin) {
    const searchParamsStr = `%3Faddress=${address}%26bounds=${bounds.ne.lat},${bounds.ne.lng},${bounds.sw.lat},${bounds.sw.lng}%26city=${city}%26origin=${origin.lat},${origin.lng}`;
    klaviyoUpdateSearchCity({
      email: userEmail,
      searchCity: city,
      searchParams: searchParamsStr
    })
  }

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    sort: 'meta_score',
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const searchSimilarListings = listingId => async (dispatch, getState, sdk) => {
  const state = getState();
  const { currentPageResultIds ,searchParams } = state.SearchPage;
  if (searchParams && searchParams.city) {
    return dispatch(setSimilarListings(currentPageResultIds, listingId));
  } else {
    return null;
  }
};

export const loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, origin, bookingDate, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};

  return searchListings({
    ...rest,
    ...originMaybe,
    ...bookingDate && { bookingDate },
    page,
    perPage: bookingDate ? RESULT_PAGE_SIZE_WITH_DATE : RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'createdAt', 'metadata'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  }, search);
};
