import React from 'react';
import { number, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';

import { IconAdd, IconRemove } from '../../components/Icons';

import css from './FieldStepper.module.css';

const FieldStepper = (props) => (
  <Field
    {...props}
    type="number"
    render={fieldRenderProps => {
      const {
        className,
        input,
        label,
        max,
        min,
        sublabel,
      } = fieldRenderProps;
      const value = typeof input.value === 'number' ? input.value : min;
      const minusDisabled = value <= min;
      const plusDisabled = typeof max === 'number' && value >= max;
      const handleMinus = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (minusDisabled) return;

        input.onChange(value - 1);
      };
      const handlePlus = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (plusDisabled) return;
        
        input.onChange(value + 1);
      };
      const classes = classNames(css.root, className);
      const stepperMinusClasses = classNames(css.stepperButton, {
        [css.minusDisabled]: minusDisabled,
      });
      const stepperPlusClasses = classNames(css.stepperButton, {
        [css.plusDisabled]: plusDisabled,
      });

      return (
        <div className={classes}>
          <div className={css.labelWrapper}>
            <span className={css.label}>{label}</span>
            <span className={css.sublabel}>{sublabel}</span>
          </div>
          <div className={css.stepperWrapper}>
            <button
              className={stepperMinusClasses}
              onClick={handleMinus}
              disabled={minusDisabled}
            >
              <IconRemove className={css.iconRemove} />
            </button>
            <span className={css.stepperValue}>{value}</span>
            <button
              className={stepperPlusClasses}
              onClick={handlePlus}
              disabled={plusDisabled}
            >
              <IconAdd className={css.iconAdd} />
            </button>
          </div>
        </div>
      )
    }}
  />
)

FieldStepper.defaultProps = {
  className: null,
  max: null,
  min: 0,
  sublabel: null,
};

FieldStepper.propTypes = {
  className: string,
  label: string.isRequired,
  max: number,
  min: number,
  sublabel: string,
};

export default FieldStepper;