import React from 'react';
import { string } from 'prop-types';
import { intlShape } from 'react-intl';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { path } from 'ramda';

import { propTypes } from '../../../../util/types';
import { createSlug } from '../../../../util/urlHelpers';
import { NamedLink } from '../../../../components';

import css from './ContactButton.module.css';

const ContactButton = (props) => {
  const {
    className,
    intl,
    listing
  } = props;
  const location = useLocation();
  const listingId = path(['id', 'uuid'], listing);
  const listingTitle = path(['attributes', 'title'], listing);
  const contactText = intl.formatMessage({ id: 'ListingPage.contact' });
  const classes = classNames(css.root, className);

  return (
    <NamedLink
      className={classes}
      name="ContactPage"
      params={{ id: listingId, slug: createSlug(listingTitle) }}
      to={{ search: location.search }}
    >
      {contactText}
    </NamedLink>
  )
}

ContactButton.defaultProps = {
  className: null,
  listing: null
};

ContactButton.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing
};

export default ContactButton;