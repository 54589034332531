import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { path, pathOr } from 'ramda';

import config from '../../../../config';
import {
  convertMoneyToNumber,
  getCurrencySymbol,
} from '../../../../util/currency';
import * as validators from '../../../../util/validators';
import { stringValuesToNumbers } from '../../../../util/data';
import {
  FieldStepperEditable,
  Form,
  PrimaryButton,
} from '../../../../components';

import { applyVariation } from '../../EditListingPricingPanel.Helpers';
import css from './HoursDiscountForm.module.css';

const MIN_VARIATION = 5;
const MAX_VARIATION = 50;

const HoursDiscountForm = (props) => {
  const {
    className,
    intl,
    isMobile,
    listing,
    onSubmit,
    updated,
    updateInProgress,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const handleDeactivate = () => {
    const publicData = { pricingExtraHours: undefined };

    onSubmit({ publicData });
    history.push(`${pathname}`);
  };
  const handleSubmit = (values) => {
    const valuesAsNumber = stringValuesToNumbers(values);
    const { pricingVariation } = valuesAsNumber;
    const publicData = {
      pricingExtraHours: { 
        vartiationType: 'reduction',
        variation: pricingVariation,
        fromNbHours: 3,
      },
    };
    const updatedValues = { publicData };
    onSubmit(updatedValues);
  };
  const listingPublicData = pathOr({}, ['attributes', 'publicData'], listing);
  const listingPrice = pathOr(0, ['attributes', 'price'], listing);
  const currencySymbol = getCurrencySymbol(config.currencyConfig);
  const { pricingExtraHours } = listingPublicData;
  const currentVariation = path(['variation'], pricingExtraHours) || MIN_VARIATION;
  const initialValues = { pricingVariation: `${currentVariation}%` };
  const maxVariationErrorText = intl.formatMessage(
    { id: 'EditListingPricingPanel.HoursDiscountForm.maxVariationError' },
    { percentage: `${MAX_VARIATION}%` },
  );
  const minVariationErrorText = intl.formatMessage(
    { id: 'EditListingPricingPanel.HoursDiscountForm.minVariationError' },
    { percentage: `${MIN_VARIATION}%` },
  );
  const maxValidator = validators.maxNumberAsString(MAX_VARIATION, maxVariationErrorText);
  const minValidator = validators.minNumberAsString(MIN_VARIATION, minVariationErrorText);
  const validate = validators.composeValidators(maxValidator, minValidator);
  const labelText = intl.formatMessage({ id : 'EditListingPricingPanel.HoursDiscountForm.variationLabel' });
  const labelDesktopText = intl.formatMessage({ id : 'EditListingPricingPanel.HoursDiscountForm.variationLabelDesktop' });
  const deactivateText = intl.formatMessage({ id: 'Marketplace.deactivate' });
  const submitText = intl.formatMessage({ id: 'Marketplace.save' });
  const classes = classNames(css.root, className);

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={formRenderProps => {
        const {
          handleSubmit,
          invalid,
          pristine,
          values,
        } = formRenderProps;
        const submitReady = (updated && pristine);
        const submitDisabled = invalid || updateInProgress;
        const valuesAsNumber = stringValuesToNumbers(values);
        const pricingVariationValue = valuesAsNumber.pricingVariation;
        const priceWithVariationApplied = listingPrice
          ? applyVariation(convertMoneyToNumber(listingPrice), -pricingVariationValue, true)
          : 0;
        const sublabelText = intl.formatMessage(
          { id: 'EditListingPricingPanel.HoursDiscountForm.variationSublabel' },
          {
            percentage: values.pricingVariation || '0%',
            amount: `${priceWithVariationApplied}${currencySymbol}`,
          },
        );

        return (
          <Form className={classes} id="weekendPricingForm" onSubmit={handleSubmit}>
            <FieldStepperEditable
              className={css.field}
              name="pricingVariation"
              initialValue={initialValues.pricingVariation}
              label={isMobile ? labelText : labelDesktopText}
              max={MAX_VARIATION}
              min={MIN_VARIATION}
              placeholder="00%"
              step={2}
              sublabel={sublabelText}
              unit="%"
              validate={validate}
              withButtons={isMobile}
            />
            <div className={css.buttonContainer}>
              <button className={css.deactivateButton} onClick={handleDeactivate} type="button">
                <span className={css.deactivateText}>{deactivateText}</span>
              </button>
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                form="weekendPricingForm"
                inProgress={updateInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                <span>{submitText}</span>
              </PrimaryButton>
            </div>
          </Form>
        )
      }}
    />
  )
}

HoursDiscountForm.defaultProps = {
  className: null,
  listing: null,
};

HoursDiscountForm.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  isMobile: bool.isRequired,
  listing: object,
  onSubmit: func.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
};

export default HoursDiscountForm;