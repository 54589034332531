import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconCheck.module.css';

const IconCheck = props => {
  const { rootClassName, className, filled } = props;
  const classes = classNames(rootClassName || css.root, className);

  return filled ? (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
    </svg>
  ) : (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"/>
    </svg>
  );
};

IconCheck.defaultProps = {
  rootClassName: null,
  className: null,
};

IconCheck.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCheck;