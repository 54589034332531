import React from 'react';
import { string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';

import { PropertyGroup } from '../../../components';

import css from './SectionFeaturesMaybe.module.css';

const SectionFeaturesMaybe = props => {
  const { className, titleClassName, intl, options, selectedOptions } = props;
  const titleText = intl.formatMessage({ id: 'ListingPage.featuresTitle' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <h2 className={titleClassName}>{titleText}</h2>
      <PropertyGroup
        id="ListingPage.amenities"
        options={options}
        selectedOptions={selectedOptions}
        twoColumns={true}
      />
    </div>
  );
};

SectionFeaturesMaybe.defaultProps = {
  className: null,
}

SectionFeaturesMaybe.propTypes = {
  className: string,
  titleClassName: string.isRequired,
  intl: intlShape.isRequired,
}

export default SectionFeaturesMaybe;
