import React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { path } from 'ramda';

import { ensureCurrentUser } from '../../util/data';
import { isServer, isCookieAccepted } from '../../util/cookies';
import config from '../../config';

const CriteoNewProductComponent = props => {
  if (isServer()) return null;
  if (!isCookieAccepted('swimmy_criteoAccepted')) return null;
  
  const {
    currentUser,
    currentLocation,
  } = props;
  if (currentLocation !== '/l/draft/ad/new/presentation') return null;

  const user = ensureCurrentUser(currentUser);
  const currentEmail = user.attributes.email || '';

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="https://static.criteo.net/js/ld/ld.js" async="true"/>
        <script type="text/javascript">
          {`
            window.criteo_q = window.criteo_q || [];
            var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
            //function to return random number equal or greater to min and less than the max
            function getRandomInt(min, max) {
              min = Math.ceil(min);
              max = Math.floor(max);
              return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
            }
            window.criteo_q.push(
              { event: "setAccount", account: ${config.criteoPartnerId} },
              { event: "setEmail", email: "${currentEmail}" },
              { event: "setSiteType", type: deviceType },
              { event: "viewItem", item: getRandomInt(17, 25), user_segment : 1  }
            );
          `}
        </script>
      </Helmet>
    </div>
  );
};

const mapStateToProps = state => ({
  currentLocation: path(['Routing', 'currentCanonicalUrl'], state),
  currentUser: path(['user', 'currentUser'], state),
});

const CriteoNewProduct = connect(mapStateToProps)(CriteoNewProductComponent);

export default CriteoNewProduct;
