import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './ModalOverlay.module.css';

const ModalOverlay = (props) => {
  const { className } = props;
  const classes = classNames(css.root, className);

  return (
    <div className={classes} />
  )
};

ModalOverlay.defaultProps = {
  className: null,
};

ModalOverlay.propTypes = {
  className: string,
};

export default ModalOverlay;
