import React, { Component, Fragment } from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';

import config from '../../config';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import {
  Form,
  FieldSelect,
  FieldTextInput,
  ContactDetails,
  ModalPasswordConfirmation,
  PrimaryButton,
} from '../../components';
import HowDidYouKnowUsSelectField from '../../components/HowDidYouKnowUsSelectField';

import css from './PersonalInfosForm.module.css';

class PersonalInfosForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changeEmail: false,
    };
    this.submittedValues = {};
  }

  componentWillUnmount() {
    window.clearTimeout(this.blurTimeoutId);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            changeEmailError,
            changeEmailInProgress,
            formId,
            handleSubmit,
            onManageDisableScrolling,
            updateInProgress,
            updateProfileError,
            intl,
            initialValues,
            invalid,
            pristine,
            form,
            values,
          } = fieldRenderProps;
          const emailHasChanged = values.email !== initialValues.email;
          const handleOpenModal = (e) => {
            this.setState({ changeEmail: true });
          };
          const firstNameLabel = intl.formatMessage({ id: 'PersonalInfosForm.firstNameLabel' });
          const firstNamePlaceholder = intl.formatMessage({ id: 'PersonalInfosForm.firstNamePlaceholder' });
          const firstNameRequiredMessage = intl.formatMessage({ id: 'PersonalInfosForm.firstNameRequired' });
          const firstNameRequired = validators.required(firstNameRequiredMessage);
          const lastNameLabel = intl.formatMessage({ id: 'PersonalInfosForm.lastNameLabel' });
          const lastNamePlaceholder = intl.formatMessage({ id: 'PersonalInfosForm.lastNamePlaceholder' });
          const lastNameRequiredMessage = intl.formatMessage({ id: 'PersonalInfosForm.lastNameRequired' });
          const lastNameRequired = validators.required(lastNameRequiredMessage);
          const submitError = updateProfileError || changeEmailError ? (
            <div className={css.error}>
              <FormattedMessage id="PersonalInfosForm.updateProfileFailed" />
            </div>
          ) : null;
          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress || changeEmailInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const submitErrorDueToInvalidForm = submittedOnce && !pristine && invalid && !emailHasChanged && (
            <div className={css.error}>
              <FormattedMessage id="PersonalInfosForm.updateProfileFailedDueToInvalidForm" />
            </div>
          );
          const pristineSinceLastSubmit = !invalid && !updateProfileError && !changeEmailError && submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =  pristine || pristineSinceLastSubmit || submitInProgress;
          const birthdayLabel = intl.formatMessage({ id: 'PersonalInfosForm.birthdayLabel' });
          const birthdayRequiredMessage = intl.formatMessage({ id: 'PersonalInfosForm.birthdayRequired' });
          const birthdayRequired = validators.required(birthdayRequiredMessage);
          const birthdayMinAge = validators.ageAtLeastSwimmy(
            intl.formatMessage(
              { id: 'PersonalInfosForm.birthdayMinAge' },
              { minAge: config.custom.marketplaceAgeRequirement }
            ),
            config.custom.marketplaceAgeRequirement
          );
          const languageRequiredMessage = intl.formatMessage({ id: 'PersonalInfosForm.languageRequiredMessage' });
          const languagePlaceholder = intl.formatMessage({ id: 'PersonalInfosForm.languagePlaceholder' });
          const languageOptionFr = intl.formatMessage({ id: 'PersonalInfosForm.languageOptionFr' });
          const languageOptionEn = intl.formatMessage({ id: 'PersonalInfosForm.languageOptionEn' });
          const languageOptionEs = intl.formatMessage({ id: 'PersonalInfosForm.languageOptionEs' });
          const languageOptionDe = intl.formatMessage({ id: 'PersonalInfosForm.languageOptionDe' });
          const languageOptionIt = intl.formatMessage({ id: 'PersonalInfosForm.languageOptionIt' });
          const languageOptionFrBe = intl.formatMessage({ id: 'PersonalInfosForm.languageOptionFrBe' });
          const languageLabel = intl.formatMessage({ id: 'PersonalInfosForm.languageLabel' });
          const languageHelper = intl.formatMessage({ id: 'PersonalInfosForm.languageHelper' });
          const modalInfosText = intl.formatMessage({ id: 'PersonalInfosForm.modalInfos' });
          
          return (
            <Fragment>
              <Form
                className={classes}
                id={formId}
                onSubmit={e => {
                  this.submittedValues = values;
                  this.setState({
                    changeEmail: false,
                  })
                  
                  handleSubmit(e);
                }}
              >
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.firstName}
                    type="text"
                    id="firstName"
                    name="firstName"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                  />
                  <FieldTextInput
                    className={css.lastName}
                    type="text"
                    id="lastName"
                    name="lastName"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                  />
                </div>
                <FieldTextInput
                  className={css.field}
                  name="birthdayDate"
                  id={'birthdayDate'}
                  label={birthdayLabel}
                  type="date"
                  validate={validators.composeValidators(birthdayRequired, birthdayMinAge)}
                />
                <div className={css.contactDetailsContainer}>
                  <ContactDetails />
                </div>
                {!initialValues.howDidYouKnowUs && (
                  <HowDidYouKnowUsSelectField
                    className={css.field}
                    id="howDidYouKnowUs"
                    name="howDidYouKnowUs"
                    intl={intl}
                  />
                )}
                <div className={css.languageContainer}>
                  <FieldSelect
                    id="language"
                    name="language"
                    label={languageLabel}
                    validate={validators.required(languageRequiredMessage)}
                  >
                    <option value="">{languagePlaceholder}</option>
                    <option value="fr">{languageOptionFr}</option>
                    <option value="fr-BE">{languageOptionFrBe}</option>
                    <option value="en">{languageOptionEn}</option>
                    <option value="es">{languageOptionEs}</option>
                    <option value="de">{languageOptionDe}</option>
                    <option value="it">{languageOptionIt}</option>
                  </FieldSelect>
                  <p className={css.helper}>{languageHelper}</p>
                </div>
                {submitError}
                {submitErrorDueToInvalidForm}
                <div className={css.buttonContainer}>
                  <PrimaryButton
                    className={css.submitButton}
                    type={emailHasChanged ? 'button' : 'submit'}
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={pristineSinceLastSubmit}
                    {...emailHasChanged && {
                      onClick: handleOpenModal,
                    }}
                  >
                    <FormattedMessage id="PersonalInfosForm.saveChanges" />
                  </PrimaryButton>
                </div>
              </Form>
              <ModalPasswordConfirmation
                infosText={modalInfosText}
                isOpen={this.state.changeEmail}
                onClose={() => this.setState({ changeEmail: false })}
                onManageDisableScrolling={onManageDisableScrolling}
                submitButtonProps={{
                  inProgress: submitInProgress,
                  disabled: submitDisabled,
                  ready: pristineSinceLastSubmit,
                  form: formId,
                }}
              />
            </Fragment>
          );
        }}
      />
    );
  }
}

PersonalInfosForm.defaultProps = {
  rootClassName: null,
  className: null,
  updateProfileError: null,
  updateProfileReady: false,
};

PersonalInfosForm.propTypes = {
  rootClassName: string,
  className: string,
  onManageDisableScrolling: func.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,
  intl: intlShape.isRequired,
};

export default injectIntl(PersonalInfosForm);
