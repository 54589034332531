import React from 'react';
import classNames from 'classnames';

import { IconClose } from '../../components';

import css from './Tag.module.css';

const Tag = (props) => {
  const { className, text, onDelete, ...rest } = props;

  const classes = classNames(css.container, className);

  return (
    <div className={classes} {...rest}>
      <span className={css.text}>{text}</span>
      <button type="button" className={css.button} onClick={onDelete}>
        <IconClose className={css.icon} />
      </button>
    </div>
  );
};

export default Tag;