import React from 'react';
import { bool, func, node, string } from 'prop-types';
import classNames from 'classnames';

import { IconArrowNavigation } from '../../../../components/Icons';

import css from './ManageButton.module.css';

const ManageButton = (props) => {
  const {
    className,
    disabled,
    iconComponent,
    onClick,
    text,
  } = props;
  const classes = classNames(css.root, className);

  return (
    <button
      className={classes}
      onClick={onClick}
      disabled={disabled}
    >
      {iconComponent}
      <span>{text}</span>
      <IconArrowNavigation />
    </button>
  )
};

ManageButton.defaultProps = {
  className: null,
  disabled: false,
};

ManageButton.propTypes = {
  className: string,
  disabled: bool,
  iconComponent: node.isRequired,
  onClick: func.isRequired,
  text: string.isRequired,
};

export default ManageButton;
