import React from 'react';
import { string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { path } from 'ramda';
import moment from 'moment';

import { propTypes } from '../../util/types';
import { AvatarMedium } from '../../components';

import css from './UserCard.module.css';

const UserCard = (props) => {
  const { className, intl, user } = props;
  const displayName = path(['attributes', 'profile', 'displayName'], user);
  const createdAt = path(['attributes', 'createdAt'], user);
  const createdAtText = intl.formatMessage({ id: 'UserCard.createdAt' }, { createdAt: moment(createdAt).year() });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <AvatarMedium className={css.avatar} user={user} disableProfileLink />
      <div className={css.infoWrapper}>
        <span className={css.displayName}>{displayName}</span>
        <span className={css.createdAt}>{createdAtText}</span>
      </div>
    </div>
  )
}

UserCard.defaultProps = {
  className: null,
}

UserCard.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  user: propTypes.user.isRequired,
}

export default injectIntl(UserCard);
