import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconExpand.module.css';

const IconExpand = props => {
  const { rootClassName, className, direction } = props;
  const classes = classNames(rootClassName || css.root, className);

  return direction === 'down' ? (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/>
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/>
    </svg>
  ) : (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z"/>
    </svg>
  );
};

IconExpand.defaultProps = {
  rootClassName: null,
  className: null,
  direction: 'down',
};

IconExpand.propTypes = {
  rootClassName: string,
  className: string,
  direction: string,
};

export default IconExpand;