import React from 'react';
import { bool, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';

import { CUSTOMER } from '../../constants/booking';
import { propTypes } from '../../util/types';
import {
  BookingParticipantBreakdown,
  BookingTimeBreakdown,
  Divider,
  ListingInfo,
  PriceBreakdown,
} from '../../components';

import css from './BookingInfoPanel.module.css';

const BookingInfoPanel = (props) => {
  const {
    className,
    intl,
    listing,
    priceBreakdownExpandable,
    transaction,
  } = props;
  const bookingHeadingText = intl.formatMessage({ id: 'BookingInfoPanel.bookingHeading' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <ListingInfo className={css.listingInfo} listing={listing} />
      <Divider className={css.divider} />
      <div className={css.bookingWrapper}>
        <span className={css.bookingHeading}>{bookingHeadingText}</span>
        <BookingTimeBreakdown className={css.bookingTimeBreakdown} transaction={transaction} withTitle />
        <BookingParticipantBreakdown className={css.bookingParticipantBreakdown} transaction={transaction} />
      </div>
      <Divider className={css.divider} />
      <PriceBreakdown
        className={css.priceBreakdown}
        expandable={priceBreakdownExpandable}
        transaction={transaction}
        userRole={CUSTOMER}
      />
    </div>
  )
}

BookingInfoPanel.defaultProps = {
  className: null,
  priceBreakdownExpandable: false,
};

BookingInfoPanel.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  priceBreakdownExpandable: bool,
  transaction: propTypes.transaction.isRequired,
};

export default BookingInfoPanel;