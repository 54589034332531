
import React from 'react';
import { string } from 'prop-types';
import { intlShape } from 'react-intl';
import { useSelector } from 'react-redux';

import { Badge, NamedLink } from '../../../components';

const ReservationsLink = (props) => {
  const { className, intl } = props;
  const currentUserHasUpcomingOrders = useSelector(state => state.user.currentUserHasUpcomingOrders);
  const currentUserHasPendingSales = useSelector(state => state.user.currentUserHasPendingSales);
  const showBadge = currentUserHasUpcomingOrders || currentUserHasPendingSales;
  const reservationsText = intl.formatMessage({ id: 'NavigationDrawer.reservations' });

  return (
    <NamedLink className={className} name="ReservationsPage" params={{ tab: currentUserHasUpcomingOrders ? 'upcoming' : 'pending' }}>
      {reservationsText}
      {showBadge && <Badge />}
    </NamedLink>
  )
};

ReservationsLink.defaultProps = {
  className: null,
}

ReservationsLink.propTypes = {
  className: string,
  intl: intlShape.isRequired,
}

export default ReservationsLink;