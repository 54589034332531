import React from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { propTypes } from '../../util/types';
import { verify } from '../../ducks/EmailVerification.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { parse } from '../../util/urlHelpers';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  EmailVerificationAuto,
} from '../../components';
import { TopbarContainer } from '../../containers';
import css from './EmailVerificationPage.css';

/**
  Parse verification token from URL

  Returns stringified token, if the token is provided.

  Returns `null` if verification token is not provided.

  Please note that we need to explicitely stringify the token, because
  the unwanted result of the `parse` method is that it automatically
  parses the token to number.
*/
const parseVerificationToken = location => {
  const urlParams = parse(location.search);
  const verificationToken = urlParams.t;

  if (verificationToken) {
    return `${verificationToken}`;
  }

  return null;
};

export const EmailVerificationPageComponent = props => {
  const {
    currentUser,
    intl,
    isVerified,
    scrollingDisabled,
    submitVerification,
    emailVerificationInProgress,
    onResendVerificationEmail,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    verificationError,
    location,
  } = props;
  const emailVerified = pathOr(false, ['attributes', 'emailVerified'], currentUser);
  const pendingEmail = pathOr(null, ['attributes', 'pendingEmail'], currentUser);
  const title = isVerified || (emailVerified && !pendingEmail) ?
    intl.formatMessage({ id: 'EmailVerificationPage.titleVerified' }) :
    intl.formatMessage({ id: 'EmailVerificationPage.title' });
  const verificationToken = { verificationToken: parseVerificationToken(location) };

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.root}>
            <div className={css.content}>
              {currentUser ? (
                <EmailVerificationAuto
                  verificationToken={verificationToken}
                  submitVerification={submitVerification}
                  currentUser={currentUser}
                  inProgress={emailVerificationInProgress}
                  resendVerificationEmail={onResendVerificationEmail}
                  sendVerificationEmailInProgress={sendVerificationEmailInProgress}
                  sendVerificationEmailError={sendVerificationEmailError}
                  verificationSuccess={isVerified}
                  verificationError={verificationError}
                />
              ) : (
                <FormattedMessage id="EmailVerificationPage.loadingUserInformation" />
              )}
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

EmailVerificationPageComponent.defaultProps = {
  currentUser: null,
  verificationError: null,
  sendVerificationEmailError: null,
};

const { bool, func, shape, string } = PropTypes;

EmailVerificationPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  isVerified: bool.isRequired,
  scrollingDisabled: bool.isRequired,
  submitVerification: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  emailVerificationInProgress: bool.isRequired,
  verificationError: propTypes.error,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,

  // from withRouter
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentUser,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  } = state.user;
  const { isVerified, verificationError, verificationInProgress } = state.EmailVerification;
  return {
    isVerified,
    verificationError,
    emailVerificationInProgress: verificationInProgress,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  submitVerification: ({ verificationToken }) => {
    return dispatch(verify(verificationToken));
  },
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const EmailVerificationPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(EmailVerificationPageComponent);

export default EmailVerificationPage;
