import React, { Component } from 'react';
import { compose } from 'redux';
import { bool, func, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import { isChangeEmailTakenError, isTooManyEmailVerificationRequestsError, } from '../../util/errors';
import { FieldPhoneNumberInput, FieldTextInput } from '../../components';
import css from './ContactDetailsForm.css';

const SHOW_EMAIL_SENT_TIMEOUT = 2000;

class ContactDetailsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { showVerificationEmailSentMessage: false };
    this.emailSentTimeoutId = null;
    this.handleResendVerificationEmail = this.handleResendVerificationEmail.bind(this);
    this.submittedValues = {};
  }

  componentWillUnmount() {
    window.clearTimeout(this.emailSentTimeoutId);
  }

  handleResendVerificationEmail() {
    this.setState({ showVerificationEmailSentMessage: true });

    this.props.onResendVerificationEmail().then(() => {
      // show "verification email sent" text for a bit longer.
      this.emailSentTimeoutId = window.setTimeout(() => {
        this.setState({ showVerificationEmailSentMessage: false });
      }, SHOW_EMAIL_SENT_TIMEOUT);
    });
  }

  render() {
    const {
      saveEmailError,
      currentUser,
      intl,
      sendVerificationEmailError,
      sendVerificationEmailInProgress,
      initialValues = {},
      phoneNumber,
    } = this.props;
    const { email } = initialValues;

    const user = ensureCurrentUser(currentUser);

    if (!user.id) {
      return null;
    }

    const { email: currentEmail, emailVerified, pendingEmail } = user.attributes;
    // email

    // has the email changed
    const emailChanged = currentEmail !== email;

    const emailLabel = intl.formatMessage({
      id: 'ContactDetailsForm.emailLabel',
    });

    const emailPlaceholder =
      currentEmail || intl.formatMessage({ id: 'ContactDetailsForm.emailPlaceholder' });

    const emailRequiredMessage = intl.formatMessage({
      id: 'ContactDetailsForm.emailRequired',
    });
    const emailRequired = validators.required(emailRequiredMessage);
    const emailInvalidMessage = intl.formatMessage({
      id: 'ContactDetailsForm.emailInvalid',
    });
    const emailValid = validators.emailFormatValid(emailInvalidMessage);

    const tooManyVerificationRequests = isTooManyEmailVerificationRequestsError(
      sendVerificationEmailError
    );

    const emailTouched = this.submittedValues.email !== initialValues.email;
    const emailTakenErrorText = isChangeEmailTakenError(saveEmailError)
      ? intl.formatMessage({ id: 'ContactDetailsForm.emailTakenError' })
      : null;

    let resendEmailMessage = null;
    if (tooManyVerificationRequests) {
      resendEmailMessage = (
        <span className={css.tooMany}>
          <FormattedMessage id="ContactDetailsForm.tooManyVerificationRequests" />
        </span>
      );
    } else if (
      sendVerificationEmailInProgress ||
      this.state.showVerificationEmailSentMessage
    ) {
      resendEmailMessage = (
        <span className={css.emailSent}>
          <FormattedMessage id="ContactDetailsForm.emailSent" />
        </span>
      );
    } else {
      resendEmailMessage = (
        <span
          className={css.helperLink}
          onClick={this.handleResendVerificationEmail}
          role="button"
        >
          <FormattedMessage id="ContactDetailsForm.resendEmailVerificationText" />
        </span>
      );
    }

    // Email status info: unverified, verified and pending email (aka changed unverified email)
    let emailVerifiedInfo = null;

    if (emailVerified && !pendingEmail && !emailChanged) {
      // Current email is verified and there's no pending unverified email
      emailVerifiedInfo = (
        <span className={css.emailVerified}>
          <FormattedMessage id="ContactDetailsForm.emailVerified" />
        </span>
      );
    } else if (!emailVerified && !pendingEmail) {
      // Current email is unverified. This is the email given in sign up form

      emailVerifiedInfo = (
        <span className={css.emailUnverified}>
          <FormattedMessage
            id="ContactDetailsForm.emailUnverified"
            values={{ resendEmailMessage }}
          />
        </span>
      );
    } else if (pendingEmail) {
      // Current email has been tried to change, but the new address is not yet verified

      const pendingEmailStyled = <span className={css.emailStyle}>{pendingEmail}</span>;
      const pendingEmailCheckInbox = (
        <span className={css.checkInbox}>
          <FormattedMessage
            id="ContactDetailsForm.pendingEmailCheckInbox"
            values={{ pendingEmail: pendingEmailStyled }}
          />
        </span>
      );

      emailVerifiedInfo = (
        <span className={css.pendingEmailUnverified}>
          <FormattedMessage
            id="ContactDetailsForm.pendingEmailUnverified"
            values={{ pendingEmailCheckInbox, resendEmailMessage }}
          />
        </span>
      );
    }

    // phone
    const phonePlaceholder = intl.formatMessage({
      id: 'ContactDetailsForm.phonePlaceholder',
    });
    const phoneLabel = intl.formatMessage({ id: 'ContactDetailsForm.phoneLabel' });
    const phoneRequiredMessage = intl.formatMessage({ id: 'SignupForm.phoneRequired' });
    const phoneInvalidMessage = intl.formatMessage({ id: 'SignupForm.phoneInvalid' });
    const phoneRequired = validators.required(phoneRequiredMessage);
    const phoneValid = validators.phoneValid(phoneInvalidMessage);

    return (
        <div className={css.contactDetailsSection}>
          <FieldTextInput
            type="email"
            name="email"
            id={'email'}
            label={emailLabel}
            placeholder={emailPlaceholder}
            validate={validators.composeValidators(emailRequired, emailValid)}
            customErrorText={emailTouched ? null : emailTakenErrorText}
          />
          {emailVerifiedInfo}
          <FieldPhoneNumberInput
            className={css.field}
            name="phoneNumber"
            id={'phoneNumber'}
            label={phoneLabel}
            placeholder={phonePlaceholder}
            value={phoneNumber}
            validate={validators.composeValidators(phoneRequired, phoneValid)}
          />
        </div>
    );
  }
}

ContactDetailsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  saveEmailError: null,
  sendVerificationEmailError: null,
  sendVerificationEmailInProgress: false,
  email: null,
  phoneNumber: null,
};

ContactDetailsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  saveEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailError: propTypes.error,
  sendVerificationEmailInProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ContactDetailsForm = compose(injectIntl)(ContactDetailsFormComponent);

ContactDetailsForm.displayName = 'ContactDetailsForm';

export default ContactDetailsForm;
