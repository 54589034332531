export const pageWithWidget = [
  'AccountPage',
  'AccountPaymentsPage',
  'AccountPersonalInfosPage',
  'AccountPrivacyPage',
  'AccountProfilePage',
  'AccountSecurityPage',
  'CalendarPage',
  'EditListingPage',
  'HowItWorksPage',
  'IncomePage',
  'LandingPage',
  'ManageListingsPage',
  'ReservationsPage',
  'SimulatorPage',
];

export const handleIFrameVisibility = (iFrame, currentPage) => {
  if (!pageWithWidget.includes(currentPage)) {
    iFrame.style.pointerEvents = 'none';
    iFrame.style.visibility = 'hidden';
  } else {
    iFrame.style.visibility = 'visible';
    iFrame.style.pointerEvents = 'auto';
    iFrame.style.zIndex = '99';
    iFrame.style.margin = '16px';
  }
};
