import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  SwimmySectionPress,
} from '../../components';
import { pressPageProps } from '../../components/SwimmySectionPress/pressConfig';
import config from '../../config';
import css from './styles.css';

const PressPage = (props) => {
  const { intl } = props;
  const schemaTitle = intl.formatMessage({ id: 'PressPage.schemaTitle' });
  const title = intl.formatMessage({ id: 'PressPage.title' });

  return (
    <StaticPage
        title={schemaTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'PressPage',
          description: 'Ils parlent de nous',
          name: 'Presse',
        }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="PressPage" />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          {config.custom.showPressArticles ? (
            <Fragment>
              <div className={css.mainTitleContainer}>
                <h1 className={css.mainTitle}>{title}</h1>
              </div>
              <section className={css.staticPageWrapper}>
                <div className={css.contentWrapper}>
                  <div className={css.sectionContent}>
                    <SwimmySectionPress pressProps={pressPageProps}/>
                  </div>
                </div>
              </section>
            </Fragment>
          ) : null}
          <div className={css.container}>
            <h2 className={css.title}>
              <FormattedMessage id="PressPage.contactTitle" />
            </h2>
            <span className={css.description}>
              <FormattedMessage id="PressPage.contactDescription" />
            </span>
            <a className={css.buttonContactLink} href={`mailto:${config.custom.pressMail}`}>
              <FormattedMessage id="RentMyPoolPage.contactButton" />
            </a>
            <h3 className={css.alignTextCenter}>
              <FormattedMessage id="PressPage.contactDescriptionNumberPhone" />
              <a href={`tel:${config.custom.pressPhoneNumber}`}>{config.custom.pressPhoneNumberReadable}</a>
            </h3>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
}

export default injectIntl(PressPage);
