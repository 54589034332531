import React from 'react';
import { object, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { IconSearch } from '../../../../components';

import css from './SubmitButton.module.css';

const SubmitButton = (props) => {
  const { className, formValues, intl } = props;
  const hasLocation = formValues.location;
  const searchText = intl.formatMessage({ id: 'SearchDesktop.SubmitButton.search' });
  const classes = classNames(css.root, {
    [css.withText]: hasLocation,
  }, className);

  return (
    <button className={classes} type="submit">
      <IconSearch className={css.iconSearch} />
      {hasLocation && <span className={css.text}>{searchText}</span>}
    </button>
  )
}

SubmitButton.defaultProps = {
  className: null,
};

SubmitButton.propTypes = {
  className: string,
  formValues: object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SubmitButton);