import React from 'react';

const Emoji = props => {
  const { className, label, symbol } = props;

  return (
    <span className={className} role="img" aria-label={label}>
        {String.fromCodePoint(symbol)}
    </span>
  )
};
  
export default Emoji;
