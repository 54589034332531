import React from 'react';
import { string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';

import { canShowAverageRating } from '../../../util/reviews';
import { IconReviewStar, Reviews } from '../../../components';
import css from './SectionReviews.module.css';

const SectionReviews = props => {
  const { className, titleClassName, fetchReviewsError, intl, rating, reviews } = props;
  const totalReviews = reviews.length;
  const showAverageRating = canShowAverageRating(reviews);
  const titleText = showAverageRating ? rating : intl.formatMessage(
    { id: 'ListingPage.SectionReviews.title' },
    { count: totalReviews },
  );
  const subtitleText = intl.formatMessage({ id: 'ListingPage.SectionReviews.subtitle' }, { count: totalReviews });
  const errorText = intl.formatMessage({ id: 'ListingPage.SectionReviews.error' });
  const classes = classNames(css.root, className);
  const titleClasses = classNames(css.titleContainer, titleClassName);

  return (
    <div className={classes}>
      <div className={titleClasses}>
        <IconReviewStar className={css.iconReviewStar} isFilled />
        <h2 className={css.title}>{titleText}</h2>
        {showAverageRating && <span className={css.subtitle}>{subtitleText}</span>}
      </div>
      {fetchReviewsError && <h2 className={css.errorText}>{errorText}</h2>}
      <Reviews className={css.reviewsContainer} reviewItemClassName={css.reviewItem} reviews={reviews} />
    </div>
  );
};

SectionReviews.dfaultProps = {
  className: null,
  titleClassName: null,
}

SectionReviews.propTypes = {
  className: string,
  titleClassName: string,
  intl: intlShape.isRequired,
}

export default SectionReviews;
