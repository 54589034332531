import React from 'react';
import classNames from 'classnames';

import { IconMenu } from '../../../../../components/Icons';
import { Avatar, Button, NotificationBadge } from '../../../../../components';

import MenuIcon from './MenuIcon/MenuIcon';
import css from './MenuButton.module.css';

const MenuButton = (props) => {
  const {
    className,
    currentPage,
    currentUser,
    notificationCount,
    handleMenuOpen,
    hasUnpublishedListings,
    intl,
  } = props;
  const menuText = intl.formatMessage({ id: 'Topbar.menu' });
  const classes = classNames(css.root, className);
  const showDraftNotification = !['EditListingPage', 'ManageListingsPage'].includes(currentPage) && hasUnpublishedListings;
  const totalNotifications = notificationCount + (showDraftNotification ? 1 : 0);
  const notificationDot = totalNotifications > 0 && <NotificationBadge className={css.badgeOnMenu} count={totalNotifications} />;
  
  return (
    <div className={classes}>
      <Button
        rootClassName={css.button}
        onClick={handleMenuOpen}
        title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
      >
        {currentUser ? (
          <Avatar className={css.avatar} user={currentUser} disableProfileLink/>
        ) : (
          <MenuIcon />
        )}
        {notificationDot}
        <IconMenu className={css.iconMenu} />
        <span className={css.text}>{menuText}</span>
      </Button>
    </div>
  )
}

export default MenuButton;
