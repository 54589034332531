import React from 'react';
import { node, object, string } from 'prop-types';
import classNames from 'classnames';

import { IconArrowNavigation } from '../../../../components/Icons';
import { NamedLink } from '../../../../components';

import css from './ManageLink.module.css';

const ManageLink = (props) => {
  const { className, iconComponent, pageName, pageParams, text } = props;
  const classes = classNames(css.root, className);

  return (
    <NamedLink
      className={classes}
      name={pageName}
      params={pageParams}
    >
      {iconComponent}
      <span>{text}</span>
      <IconArrowNavigation direction='right' />
    </NamedLink>
  )
};

ManageLink.defaultProps = {
  className: null,
  pageParams: null,
};

ManageLink.propTypes = {
  className: string,
  iconComponent: node.isRequired,
  pageName: string.isRequired,
  pageParams: object,
  text: string.isRequired,
};

export default ManageLink;
