import React from 'react';
import classNames from 'classnames';

import { Emoji } from '../../../../components';

import css from './PolicyItem.module.css'

const PolicyItem = props => {
  const { symbol, helper, text, isForbidden } = props;

  const emojiClasses = classNames(css.emoji, isForbidden && css.emojiForbidden);
  const policiesTextClasses = classNames(css.policiesText, isForbidden && css.policiesTextForbidden);

  return (
    <div className={css.root}>
      <Emoji className={emojiClasses} symbol={symbol} />
      <div className={css.policiesTextContainer}>
        <span className={policiesTextClasses}>{text}</span>
        {helper && !isForbidden ? (
          <span className={css.policiesHelper}>{helper}</span>
        ) : null}
      </div>
    </div>
  );
};

export default PolicyItem;
