import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { IconDiscount } from '../../components/Icons';

import css from './DiscountBanner.module.css';

const DiscountBanner = (props) => {
  const { className, text } = props;
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <IconDiscount className={css.iconDiscount} />
      <span className={css.text}>{text}</span>
    </div>
  )
}

DiscountBanner.defaultProps = {
  className: null,
};

DiscountBanner.propTypes = {
  className: string,
  text: string.isRequired,
};

export default DiscountBanner;