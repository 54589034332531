import Decimal from 'decimal.js';
import {find, pathOr, reduce, length, split, path} from 'ramda';
// @ts-ignore
import {types} from 'sharetribe-flex-sdk';

import {
    LINE_ITEM_UNITS_BASE,
    LINE_ITEM_UNITS_EXTRA_GUESTS,
    LINE_ITEM_WEEK_REDUCTION,
    LINE_ITEM_CUSTOM_EXTRA,
    LINE_ITEM_UNITS_BASE_HOURS_DISCOUNT,
    LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT,
    LINE_ITEM_PROVIDER_COMMISSION_DISCOUNT,
    LINE_ITEM_UNITS_HOURLY,
    LINE_ITEM_UNITS_HOURLY_CHILDREN,
} from './types';
import {moment} from '../utils/date';
import {convertDecimalJSToNumber, getAmountAsDecimalJS} from '../utils/currency';
import {
    CHILDREN_DISCOUNT_PERCENTAGE,
    CUSTOMER_COMMISSION_DISCOUNT_PERCENTAGE,
    CUSTOMER_COMMISSION_PERCENTAGE, PROVIDER_COMMISSION_DISCOUNT_PERCENTAGE,
    PROVIDER_COMMISSION_PERCENTAGE
} from "../constants";

const {Money} = types;

/** Helper functions for constructing line items*/

/**
 * Calculates lineTotal for lineItem based on quantity.
 * The total will be `unitPrice * quantity`.
 *
 * @param {Money} unitPrice
 * @param unitCount
 *
 * @returns {Money} lineTotal
 */
export const calculateTotalPriceFromQuantity = (unitPrice: any, unitCount: number) => {
    const amountFromUnitPrice = getAmountAsDecimalJS(unitPrice);

    // NOTE: We round the total price to the nearest integer.
    //       Payment processors don't support fractional subunits.
    const totalPrice = amountFromUnitPrice.times(unitCount).toNearest(1, Decimal.ROUND_HALF_UP);
    // Get total price as Number (and validate that the conversion is safe)
    const numericTotalPrice = convertDecimalJSToNumber(totalPrice);

    return new Money(numericTotalPrice, unitPrice.currency);
};

/**
 * Calculates lineTotal for lineItem based on percentage.
 * The total will be `unitPrice * (percentage / 100)`.
 *
 * @param {Money} unitPrice
 * @param {int} percentage
 *
 * @returns {Money} lineTotal
 */
export const calculateTotalPriceFromPercentage = (unitPrice: any, percentage: number) => {
    const amountFromUnitPrice = getAmountAsDecimalJS(unitPrice);

    // NOTE: We round the total price to the nearest integer.
    //       Payment processors don't support fractional subunits.
    const totalPrice = amountFromUnitPrice
        .times(percentage)
        .dividedBy(100)
        .toNearest(1, Decimal.ROUND_HALF_UP);

    // Get total price as Number (and validate that the conversion is safe)
    const numericTotalPrice = convertDecimalJSToNumber(totalPrice);

    return new Money(numericTotalPrice, unitPrice.currency);
};

/**
 * Calculates lineTotal for lineItem based on seats and units.
 * The total will be `unitPrice * units * seats`.
 *
 * @param {Money} unitPrice
 * @param {int} unitCount
 * @param {int} seats
 *
 * @returns {Money} lineTotal
 */
export const calculateTotalPriceFromSeats = (unitPrice: any, unitCount: number, seats: number) => {
    if (seats < 0) {
        throw new Error(`Value of seats can't be negative`);
    }

    const amountFromUnitPrice = getAmountAsDecimalJS(unitPrice);

    // NOTE: We round the total price to the nearest integer.
    //       Payment processors don't support fractional subunits.
    const totalPrice = amountFromUnitPrice
        .times(unitCount)
        .times(seats)
        .toNearest(1, Decimal.ROUND_HALF_UP);

    // Get total price as Number (and validate that the conversion is safe)
    const numericTotalPrice = convertDecimalJSToNumber(totalPrice);

    return new Money(numericTotalPrice, unitPrice.currency);
};


/**
 *
 *  `lineTotal` is calculated by the following rules:
 * - If `quantity` is provided, the line total will be `unitPrice * quantity`.
 * - If `percentage` is provided, the line total will be `unitPrice * (percentage / 100)`.
 * - If `seats` and `units` are provided the line item will contain `quantity` as a product of `seats` and `units` and the line total will be `unitPrice * units * seats`.
 *
 * @param {Object} lineItem
 * @return {Money} lineTotal
 *
 */
export const calculateLineTotal = (lineItem: any) => {
    const {code, unitPrice, quantity, percentage, seats, units} = lineItem;

    if (quantity) {
        return calculateTotalPriceFromQuantity(unitPrice, quantity);
    } else if (percentage) {
        return calculateTotalPriceFromPercentage(unitPrice, percentage);
    } else if (seats && units) {
        return calculateTotalPriceFromSeats(unitPrice, units, seats);
    } else {
        throw new Error(
            `Can't calculate the lineTotal of lineItem: ${code}. Make sure the lineItem has quantity, percentage or both seats and units`
        );
    }
};

/**
 * Calculates the total sum of lineTotals for given lineItems
 *
 * @param {Array} lineItems
 * @retuns {Money} total sum
 */
export const calculateTotalFromLineItems = (lineItems: any) => {
    const totalPrice = lineItems.reduce((sum: number, lineItem: any) => {
        const lineTotal = calculateLineTotal(lineItem);
        return getAmountAsDecimalJS(lineTotal).add(sum);
    }, 0);

    // Get total price as Number (and validate that the conversion is safe)
    const numericTotalPrice = convertDecimalJSToNumber(totalPrice);
    const unitPrice = lineItems[0].unitPrice;

    return new Money(numericTotalPrice, unitPrice.currency);
};

/**
 * Calculates the total sum of lineTotals for given lineItems where `includeFor` includes `provider`
 * @param {*} lineItems
 * @returns {Money} total sum
 */
export const calculateTotalForProvider = (lineItems: any) => {
    const providerLineItems = lineItems.filter((lineItem: any) => lineItem.includeFor.includes('provider'));
    return calculateTotalFromLineItems(providerLineItems);
};

/**
 * Calculates the total sum of lineTotals for given lineItems where `includeFor` includes `customer`
 * @param {*} lineItems
 * @returns {Money} total sum
 */
export const calculateTotalForCustomer = (lineItems: any) => {
    const providerLineItems = lineItems.filter((lineItem: any) => lineItem.includeFor.includes('customer'));
    return calculateTotalFromLineItems(providerLineItems);
};

/**
 * Constructs lineItems that can be used directly in FTW.
 * This function checks lineItem code and adds attributes like lineTotal and reversal
 * which are added in API response and some FTW components are expecting.
 *
 * This can be used when user is not authenticated and we can't call speculative API endpoints directly
 *
 * @param {Array} lineItems
 * @returns {Array} lineItems with lineTotal and reversal info
 *
 */
export const constructValidLineItems = (lineItems: any) => {
    const lineItemsWithTotals = lineItems.map((lineItem: any) => {
        const {code, quantity, percentage} = lineItem;

        if (!/^line-item\/.+/.test(code)) {
            throw new Error(`Invalid line item code: ${code}`);
        }

        // lineItems are expected to be in similar format as when they are returned from API
        // so that we can use them in e.g. BookingBreakdown component.
        // This means we need to convert quantity to Decimal and add attributes lineTotal and reversal to lineItems
        const lineTotal = calculateLineTotal(lineItem);
        return {
            ...lineItem,
            lineTotal,
            quantity: quantity ? new Decimal(quantity) : null,
            percentage: percentage ? new Decimal(percentage) : null,
            reversal: false,
        };
    });
    return lineItemsWithTotals;
};

interface IPaidExtrasData {
    label: string
    isByUnit: boolean
    id: string
}

type IGetLineItemLabel =
    (isByUnit: boolean, extraLabel: string, numberOfPeople: number, formattedPrice: string) => string;

export const getValidatedExtrasLineItems = (
    paidExtrasData: IPaidExtrasData[] = [],
    lineItems: any[],
    formatMoney = Function,
    getLineItemLabel: IGetLineItemLabel,
) => reduce((acc: any[], item: any) => {
    const extraMaybe = split(LINE_ITEM_CUSTOM_EXTRA, item.code);
    if (length(extraMaybe) <= 1 || item.reversal) return acc;

    const extraId = extraMaybe[1];
    if (!extraId) return acc;

    const extra = find(({id}) => id === extraId, paidExtrasData);
    if (!extra) return acc;

    const {label, isByUnit} = extra;
    const lineTotal = item.lineTotal;
    const formattedLineTotal = lineTotal ? formatMoney(lineTotal) : null;
    const formattedLineUnitPrice = item.unitPrice ? formatMoney(item.unitPrice) : null;
    const isNotSerialized = path(['quantity', '_sdkType'], item) === "BigDecimal";
    const numberOfPeople = isNotSerialized ? new Decimal(item.quantity.value) : item.quantity;

    return [...acc, {
        ...item,
        // @ts-ignore
        label: getLineItemLabel(isByUnit, label, numberOfPeople, formattedLineUnitPrice),
        formattedLineTotal,
    }];
}, [], lineItems);

interface IExtra {
    amount: number
    id: string
    isByUnit: boolean
}

const getLineItemsCustomExtras = (extrasId: string[] = [], guestsCount: number, paidExtrasData: IExtra[] = [], currency: string) => {
    return reduce((acc: any, curr) => {
        const {amount, id, isByUnit} = curr;
        if (!extrasId.includes(id)) return acc;
        const lineItem = {
            code: `${LINE_ITEM_CUSTOM_EXTRA}${id}`,
            includeFor: ['customer', 'provider'],
            unitPrice: new Money(amount, currency),
            quantity: isByUnit ? guestsCount : 1
        }
        return [...acc, lineItem]
    }, [], paidExtrasData);
}

interface IPricingVariationByPeriod {
    variation: number
    variationType: string
    periodType: string
    values: any
}

const getLineItemsWithVariationByPeriod = (pricingVariationByPeriod: IPricingVariationByPeriod[], startDate: any, unitPrice: any) => {
    const getLineItemReduction = (variationPayload: IPricingVariationByPeriod, lineItemCode: string) => {
        const {variation, variationType} = variationPayload;
        const variationToApply = variationType === 'reduction' ? -variation : variation;
        return {
            code: lineItemCode,
            includeFor: ['customer', 'provider'],
            unitPrice,
            percentage: variationToApply
        };
    };
    return reduce((acc: any, curr) => {
            const {periodType, values} = curr;
            switch (periodType) {
                case 'day': {
                    const dayDate = startDate.locale('en').format('dddd').toLowerCase();
                    const applyThisVariation = find((day) => day === dayDate, values);
                    if (!applyThisVariation) return acc;

                    //TODO: save directly lineitemcode in variationByPeriod data in listing publicData
                    const lineItem = getLineItemReduction(curr, LINE_ITEM_WEEK_REDUCTION);
                    return [...acc, lineItem];
                }
                case 'dates': {
                    const range = moment.range(values[0], values[1]);
                    const applyThisVariation = range.contains(startDate);
                    if (!applyThisVariation) return acc;

                    const lineItem = getLineItemReduction(curr, LINE_ITEM_WEEK_REDUCTION);
                    return [...acc, lineItem];
                }
                default:
                    return acc;
            }
        },
        [],
        pricingVariationByPeriod
    );
};


const getLineItemUnitsHourly = (numberOfPeople: number, quantityHours: number, amountPriceSlot: number, currency: string) => (
    {
        code: LINE_ITEM_UNITS_HOURLY,
        includeFor: ['customer', 'provider'],
        unitPrice: new Money(amountPriceSlot, currency),
        units: quantityHours,
        seats: numberOfPeople,
    }
);

const getLineItemWithChildrenDiscount = (children: number, quantityHours: number, amountPriceSlot: number, currency: string) => {
    if (!children || children <= 0) return [];

    return [{
        code: LINE_ITEM_UNITS_HOURLY_CHILDREN,
        includeFor: ['customer'],
        unitPrice: new Money(amountPriceSlot * children * quantityHours, currency),
        percentage: -CHILDREN_DISCOUNT_PERCENTAGE,
    }]
}

interface IPriceExtraHours {
    variation: number
    variationType: string
    fromNbHours: number
}

const getLineItemWithExtraHoursDiscountHourlyPlan = (priceExtraHours: IPriceExtraHours | null, quantityHours: number, amountPriceSlot: number, currency: string, adults: number, children: number) => {
    if (!priceExtraHours) return [];

    const minNumberOfHoursToHaveDiscount = pathOr(3, ['fromNbHours'], priceExtraHours);
    const variation = pathOr(1, ['variation'], priceExtraHours);
    const variationType = pathOr('reduction', ['variationType'], priceExtraHours);
    const variationToApply = variationType === 'reduction' ? -variation : variation;
    if (quantityHours <= minNumberOfHoursToHaveDiscount) return [];

    const quantityHoursExtra = quantityHours - minNumberOfHoursToHaveDiscount;
    const unitPrice = calculateTotalFromLineItems([
        getLineItemUnitsHourly(adults + children, quantityHoursExtra, amountPriceSlot, currency),
        ...getLineItemWithChildrenDiscount(children, quantityHoursExtra, amountPriceSlot, currency)
    ]);
    return [{
        code: LINE_ITEM_UNITS_BASE_HOURS_DISCOUNT,
        includeFor: ['customer', 'provider'],
        unitPrice: unitPrice,
        percentage: variationToApply,
    }];
};

const getLineItemWithExtraHoursDiscount = (priceExtraHours: IPriceExtraHours | null, quantityHours: number, amountPriceSlot: number, currency: string) => {
    if (!priceExtraHours) return [];

    const minNumberOfHoursToHaveDiscount = pathOr(3, ['fromNbHours'], priceExtraHours);
    const variation = pathOr(1, ['variation'], priceExtraHours);
    const variationType = pathOr('reduction', ['variationType'], priceExtraHours);
    const variationToApply = variationType === 'reduction' ? -variation : variation;
    if (quantityHours <= minNumberOfHoursToHaveDiscount) return [];

    const quantityHoursExtra = quantityHours - minNumberOfHoursToHaveDiscount;
    return [{
        code: LINE_ITEM_UNITS_BASE_HOURS_DISCOUNT,
        includeFor: ['customer', 'provider'],
        unitPrice: new Money(amountPriceSlot * quantityHoursExtra, currency),
        percentage: variationToApply,
    }];
};

const getLineItemWithPlatformFees = (numberOfPeople: number, totalWithoutFees: number, currency: string, canApplyCommissionDiscountOnBigBookings: boolean) => {
    const customerPlatformFees = {
        code: 'line-item/customer-commission',
        unitPrice: totalWithoutFees,
        percentage: CUSTOMER_COMMISSION_PERCENTAGE,
        includeFor: ['customer'],
    };
    const providerPlatformFees = {
        code: 'line-item/provider-commission',
        unitPrice: totalWithoutFees,
        percentage: -PROVIDER_COMMISSION_PERCENTAGE,
        includeFor: ['provider'],
    };
    const lineItemWithPlatformFeesDiscountMaybe = (!canApplyCommissionDiscountOnBigBookings || !numberOfPeople || numberOfPeople < 10) ? [] : [
        {
            code: LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT,
            unitPrice: calculateLineTotal(customerPlatformFees),
            percentage: -CUSTOMER_COMMISSION_DISCOUNT_PERCENTAGE,
            includeFor: ['customer'],
        },
        {
            code: LINE_ITEM_PROVIDER_COMMISSION_DISCOUNT,
            unitPrice: calculateLineTotal(providerPlatformFees),
            percentage: -PROVIDER_COMMISSION_DISCOUNT_PERCENTAGE,
            includeFor: ['provider'],
        },
    ];

    return [
        customerPlatformFees,
        providerPlatformFees,
        ...lineItemWithPlatformFeesDiscountMaybe,
    ];
};

interface ILineItemsParams {
    amountPriceSlot: number
    pricePerExtraGuest: number
    pricingBaseNumberOfPeople: number
    quantity: {
        adults: number
        children: number
    }
    quantityHours: number
    currency: string
    startDate: any
    extras: any
    paidExtrasData: any
    canApplyCommissionDiscountOnBigBookings: boolean
    isTransactionHourlyPlan: boolean
}

interface ILineItemsHourlyPlanParams {
    amountPriceSlot: number
    quantity: {
        adults: number
        children: number
    }
    quantityHours: number
    currency: string
    startDate: any
    extras: any
    paidExtrasData: any
    canApplyCommissionDiscountOnBigBookings: boolean
}

export const getLineItems = (params: ILineItemsParams, publicData: any) => {
    const {
        amountPriceSlot,
        pricePerExtraGuest,
        pricingBaseNumberOfPeople,
        quantity,
        quantityHours,
        currency,
        startDate,
        extras,
        paidExtrasData = [],
        canApplyCommissionDiscountOnBigBookings,
        isTransactionHourlyPlan,
    } = params;
    if (isTransactionHourlyPlan) return getLineItemsHourlyPlan(params, publicData);

    const {adults, children = 0} = quantity;
    const numberOfPeople = adults + children;
    const numberOfPeopleInExtra = numberOfPeople - pricingBaseNumberOfPeople;
    const pricingVariationByPeriod = pathOr([], ['pricingVariationByPeriod'], publicData);
    const pricingExtraHours = pathOr(null, ['pricingExtraHours'], publicData);
    const lineItemUnitsBase = {
        code: LINE_ITEM_UNITS_BASE,
        includeFor: ['customer', 'provider'],
        unitPrice: new Money(amountPriceSlot, currency),
        quantity: quantityHours
    };
    const lineItemUnitsExtraGuests = numberOfPeopleInExtra > 0 ? [{
        code: LINE_ITEM_UNITS_EXTRA_GUESTS,
        includeFor: ['customer', 'provider'],
        unitPrice: new Money(pricePerExtraGuest, currency),
        units: quantityHours,
        seats: numberOfPeopleInExtra,
    }] : [];
    const lineItemsWithVariationByPeriod = getLineItemsWithVariationByPeriod(pricingVariationByPeriod, startDate, calculateLineTotal(lineItemUnitsBase));
    const lineItemsCustomExtra = getLineItemsCustomExtras(extras, numberOfPeople, paidExtrasData, currency);
    const lineItemWithExtraHoursDiscount = getLineItemWithExtraHoursDiscount(pricingExtraHours, quantityHours, amountPriceSlot, currency);
    const lineItemsWithoutFees = [
        lineItemUnitsBase,
        ...lineItemsWithVariationByPeriod,
        ...lineItemWithExtraHoursDiscount,
        ...lineItemUnitsExtraGuests,
        ...lineItemsCustomExtra,
    ];

    const lineItemWithPlatformFees = getLineItemWithPlatformFees(numberOfPeople, calculateTotalFromLineItems(lineItemsWithoutFees), currency, canApplyCommissionDiscountOnBigBookings);
    return [
        ...lineItemsWithoutFees,
        ...lineItemWithPlatformFees,
    ];
};

export const getLineItemsHourlyPlan = (params: ILineItemsHourlyPlanParams, publicData: any) => {
    const {
        amountPriceSlot,
        quantity,
        quantityHours,
        currency,
        startDate,
        extras,
        paidExtrasData = [],
        canApplyCommissionDiscountOnBigBookings,
    } = params;
    const {adults = 1, children = 0} = quantity;
    const numberOfPeople = adults + children;
    const pricingVariationByPeriod = pathOr([], ['pricingVariationByPeriod'], publicData);
    const pricingExtraHours = pathOr(null, ['pricingExtraHours'], publicData);
    const lineItemUnitsBase = {
        code: LINE_ITEM_UNITS_HOURLY,
        includeFor: ['customer', 'provider'],
        unitPrice: new Money(amountPriceSlot, currency),
        units: quantityHours,
        seats: numberOfPeople,
    };
    const lineItemWithChildrenDiscount = getLineItemWithChildrenDiscount(children, quantityHours, amountPriceSlot, currency);
    const totalUnits = [
        lineItemUnitsBase,
        ...lineItemWithChildrenDiscount
    ];
    const lineItemsWithVariationByPeriod = getLineItemsWithVariationByPeriod(pricingVariationByPeriod, startDate, calculateTotalFromLineItems(totalUnits));
    const lineItemsCustomExtra = getLineItemsCustomExtras(extras, numberOfPeople, paidExtrasData, currency);
    const lineItemWithExtraHoursDiscount = getLineItemWithExtraHoursDiscountHourlyPlan(pricingExtraHours, quantityHours, amountPriceSlot, currency, adults, children);
    const lineItemsWithoutFees = [
        ...totalUnits,
        ...lineItemsWithVariationByPeriod,
        ...lineItemWithExtraHoursDiscount,
        ...lineItemsCustomExtra,
    ];

    const lineItemWithPlatformFees = getLineItemWithPlatformFees(numberOfPeople, calculateTotalFromLineItems(lineItemsWithoutFees), currency, canApplyCommissionDiscountOnBigBookings);
    return [
        ...lineItemsWithoutFees,
        ...lineItemWithPlatformFees,
    ];
};
