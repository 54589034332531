import React, { Fragment, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { bool, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import config from '../../../../../../config';
import { manageDisableScrolling, isScrollingDisabled } from '../../../../../../ducks/UI.duck';
import {
  Button,
  ExternalLink,
  IconEmail,
  IconPhone,
  ModalContactUs,
} from '../../../../../../components';
import madameImg from '../../../../../../assets/pictures/madame.png';
import childImg from '../../../../../../assets/pictures/child2.png';

import css from './SectionContact.module.css';

const SectionContact = props => {
  const { className, intl, onManageDisableScrolling } = props;
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const mailContact = config.custom.mailContact;
  const title = intl.formatMessage({ id: 'SimulatorPage.contactTitle' });
  const ctaMail = intl.formatMessage({ id: 'SimulatorPage.contactMail' });
  const ctaPhone = intl.formatMessage({ id: 'SimulatorPage.contactPhone' });
  const startSupportHours = config.custom.supportHours.start; 
  const endSupportHours = config.custom.supportHours.end; 
  const bottomText1 = intl.formatMessage(
    { id: 'SimulatorPage.contactBottomText1' },
    { start: startSupportHours, end: endSupportHours },
  );
  const bottomText2 = intl.formatMessage({ id: 'SimulatorPage.contactBottomText2' });
  const classes = classNames(css.root, className);
  
  return (
    <Fragment>
      <section className={classes}>
        <div className={css.contactContent}>
          <span className={css.contactTitle}>{title}</span>
          <ExternalLink className={css.contactCTA} href={`mailto:${mailContact}`} >
            <span>{ctaMail}</span>
            <IconEmail />
          </ExternalLink>
          <Button className={css.contactCTA} onClick={handleShowModal} >
            <span>{ctaPhone}</span>
            <IconPhone />
          </Button>
          <div className={css.contactBottomText}>
            <span>{bottomText1}</span>
            <span>{bottomText2}</span>
          </div>
          <img className={css.contactImg} src={madameImg} alt='madame' />
          <img className={css.contactImg2} src={childImg} alt='child' />
        </div>
      </section>
      <ModalContactUs
        isOpen={showModal}
        onClose={handleCloseModal}
        onManageDisableScrolling={onManageDisableScrolling}
      />
    </Fragment>
  )
};

SectionContact.defaultProps = {
  className: string,
};

SectionContact.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SectionContact);