import React from 'react';
import { string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { intlShape } from 'react-intl';
import classNames from 'classnames';

import { IconArrowNavigation } from '../../../components/Icons';

import css from './HeaderDesktop.module.css';

const HeaderDesktop = (props) => {
  const {
    className,
    heading,
    intl,
  } = props;
  const history = useHistory();
  const handleBack = () => history.goBack();
  const backButtonText = intl.formatMessage({ id: 'EditListingPricingPanel.title' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <span className={css.heading}>{heading}</span>
      <div className={css.navigation}>
        <button className={css.backButton} onClick={handleBack}>
          {backButtonText}
        </button>
        <IconArrowNavigation className={css.iconArrowNavigation} />
        <span className={css.currentView}>{heading}</span>
      </div>
    </div>
  )
}

HeaderDesktop.defaultProps = {
  className: null,
};

HeaderDesktop.propTypes = {
  className: string,
  heading: string.isRequired,
  intl: intlShape.isRequired,
};

export default HeaderDesktop;