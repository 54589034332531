import { path } from 'ramda';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { getTxId } from '../../util/urlHelpers';
import { fetchTransaction } from '../../ducks/transaction.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const FETCH_TRANSACTION_PRICE_REQUEST = 'app/CongratPurchaseBookingPage/FETCH_TRANSACTION_PRICE_REQUEST';
export const FETCH_TRANSACTION_PRICE_SUCCESS = 'app/CongratPurchaseBookingPage/FETCH_TRANSACTION_PRICE_SUCCESS';
export const FETCH_TRANSACTION_PRICE_ERROR = 'app/CongratPurchaseBookingPage/FETCH_TRANSACTION_PRICE_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchTransactionPriceError: null,
  price: null,
};

export default function congratPurchaseBookingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch(type) {
    case FETCH_TRANSACTION_PRICE_REQUEST:
      return { ...state, fetchInProgress: true, fetchTransactionPriceError: null };
    case FETCH_TRANSACTION_PRICE_SUCCESS:
      return { ...state, fetchInProgress: false, price: payload };
    case FETCH_TRANSACTION_PRICE_ERROR:
      return { ...state, fetchInProgress: false, fetchTransactionPriceError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchTransactionPriceRequest = () => ({ type: FETCH_TRANSACTION_PRICE_REQUEST });
export const fetchTransactionPriceSuccess = price => ({
  type: FETCH_TRANSACTION_PRICE_SUCCESS,
  payload: price,
});
export const fetchTransactionPriceError = e => ({
  type: FETCH_TRANSACTION_PRICE_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const txId = new UUID(getTxId(search));

  dispatch(fetchTransactionPriceRequest);

  return sdk.transactions
  .show({
    id: txId
  })
  .then(response => {
    const { payinTotal } = path(['attributes'], response.data.data);
    dispatch(fetchTransactionPriceSuccess(payinTotal));
    dispatch(fetchTransaction(txId));
    return response;
  })
  .catch(e => {
    dispatch(fetchTransactionPriceError(storableError(e)));
    throw e;
  });
};
