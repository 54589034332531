import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconInfo.module.css';

const IconInfo = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">
      <path d="M501.9 482.8c-6.3-3.7-44.7-27.5-65.3-70.5 48-44.9 75.3-107.4 75.3-171.3 0-132.3-115.4-241-256-241S0 108.7 0 241s115.4 241 256 241c18.9 0 39.1-1.8 67.1-7.6 73.5 39.1 139.5 38 164.6 37.6 11.2-0.1 19.3 1.9 23.5-9.7C513.9 494.5 509.8 485.5 501.9 482.8zM285.9 362c0 16.6-13.4 30-30 30 -16.6 0-30-13.4-30-30V241c0-16.6 13.4-30 30-30 16.6 0 30 13.4 30 30V362zM256 165c-16.6 0-30-13.4-30-30 0-16.6 13.4-30 30-30 16.6 0 30 13.4 30 30C285.9 151.6 272.5 165 256 165z" fill="#4a4a4a" />
    </svg>
  )
};

IconInfo.defaultProps = {
  rootClassName: null,
  className: null,
};

IconInfo.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconInfo;
