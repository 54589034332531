import React from 'react';
import { Helmet } from 'react-helmet-async';

import { isServer, isCookieAccepted } from '../../util/cookies';
import config from '../../config';

const CriteoRentPool = () => {
  if (isServer()) return null;
  if (!isCookieAccepted('swimmy_criteoAccepted')) return null;

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="http://static.criteo.net/js/ld/ld.js" async="true"/>
        <script type="text/javascript">
          {`
            window.criteo_q = window.criteo_q || [];
            window.criteo_q.push(
                { event: "setAccount", account: ${config.criteoPartnerId}},
                { event: "setSiteType", type: /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d"},
                { event: "viewHome", user_segment: 1}
            );
            `}
        </script>
      </Helmet>
    </div>
  )
};

CriteoRentPool.defaultProps = {};

CriteoRentPool.propTypes = {};

export default CriteoRentPool;
