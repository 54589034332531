import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconCheckmark.module.css';

const IconCheckmark = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
      <polyline points="20 6 9 17 4 12"></polyline>
    </svg>
  );
};

IconCheckmark.defaultProps = {
  rootClassName: null,
  className: null,
};

IconCheckmark.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCheckmark;
