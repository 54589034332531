import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconVerified.module.css';

const IconVerified = props => {
  const { rootClassName, className, filled } = props;
  const classes = classNames(rootClassName || css.root, className);

  return filled ? (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-2 16l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"/>
    </svg>
  ) : (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm7 10c0 4.52-2.98 8.69-7 9.93-4.02-1.24-7-5.41-7-9.93V6.3l7-3.11 7 3.11V11zm-11.59.59L6 13l4 4 8-8-1.41-1.42L10 14.17z"/>
    </svg>
  );
};

IconVerified.defaultProps = {
  rootClassName: null,
  className: null,
};

IconVerified.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconVerified;