import React from 'react';
import { object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import css from './DetailsQuantity.module.css';

const DetailsQuantity = props => {
  const {
    className,
    intl,
    quantity,
  } = props;
  const { adults, children, infants } = quantity;
  const title = intl.formatMessage({ id: 'InboxPage.DetailsSection.DetailsQuantity.title' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <span className={css.title}>{title}</span>
      <div className={css.participants}>
        <FormattedMessage id={'Marketplace.adultCount'} values={{ count: adults }} />
        {!!children && <FormattedMessage id={'Marketplace.childCount'} values={{ count: children }} />}
        {!!infants && <FormattedMessage id={'Marketplace.infantCount'} values={{ count: infants }} />}
      </div>
    </div>
  );
}

DetailsQuantity.defaultProps = {
  className: null,
};

DetailsQuantity.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  quantity: object.isRequired,
};

export default injectIntl(DetailsQuantity);
