import React, { useState } from 'react';
import { array, bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import range from 'lodash/range';
import moment from 'moment';

import { propTypes } from '../../util/types';
import config from '../../config';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { filterBasedOnTransitionsByYear, getIncomeByYear } from './IncomePage.helper';
import {
  TRANSITION_ACCEPT,
  TRANSITIONS_BOOKED,
  TRANSITION_CANCEL_BY_CUSTOMER,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_INSTANT_ACCEPT,
  TRANSITIONS_REFUNDED,
} from '../../util/transactions';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { loadData } from './IncomePage.duck';
import css from './IncomePage.css';

export const IncomePageComponent = props => {
  const {
    fetchBookedSalesError,
    fetchInProgress,
    scrollingDisabled,
    transactions,
    currentUserHasListings,
    intl,
  } = props;
  const [selectedYear, setSelectedYear] = useState(moment().year());

  const handleSelectedYear = event => {
    const SelectedYearToNumber = parseInt(event.target.value, 10);
    setSelectedYear(SelectedYearToNumber);
  };

  const yearAbleToBeSelected = range(moment().year(), 2018 - 1);
  const selectOptions = yearAbleToBeSelected.map((year, key) => <option key={key} value={year}>{year}</option>);
  const hasCompletedSales = transactions.length > 0;
  const income = hasCompletedSales ? getIncomeByYear(transactions, selectedYear) : 0;
  const currencyConfig = config.currencyConfig;
  const formattedIncome = intl.formatNumber(income, currencyConfig);
  const totalBookingAccepted = hasCompletedSales ? filterBasedOnTransitionsByYear(transactions, selectedYear, [TRANSITION_ACCEPT, TRANSITION_INSTANT_ACCEPT]).length : 0;
  const totalBookingCancelled = hasCompletedSales ? filterBasedOnTransitionsByYear(transactions, selectedYear, TRANSITION_CANCEL_BY_CUSTOMER).length : 0;
  const totalBookingDeclined = hasCompletedSales ? filterBasedOnTransitionsByYear(transactions, selectedYear, TRANSITION_DECLINE).length : 0;
  const totalBookingExpired = hasCompletedSales ? filterBasedOnTransitionsByYear(transactions, selectedYear, TRANSITION_EXPIRE).length : 0;
  const totalBookingRefunded = hasCompletedSales ? filterBasedOnTransitionsByYear(transactions, selectedYear, TRANSITIONS_REFUNDED).length : 0;
  const totalBookingRequest = hasCompletedSales ? filterBasedOnTransitionsByYear(transactions, selectedYear, TRANSITIONS_BOOKED).length : 0;

  const title = intl.formatMessage({ id: 'IncomePage.title' }, { siteTitle: config.siteTitle });
  const mainTitle = intl.formatMessage({ id: 'IncomePage.heading' });
  const errorMessage = <FormattedMessage id="IncomePage.errorMessage" />;
  const incomeTitle = <FormattedMessage id="IncomePage.incomeTitle" values={{ year: selectedYear }} />;
  const bookingAcceptedMessage = <FormattedMessage id="IncomePage.bookingAcceptedMessage" values={{ number: totalBookingAccepted }} />;
  const bookingCancelledMessage = <FormattedMessage id="IncomePage.bookingCancelledMessage" values={{ number: totalBookingCancelled }} />;
  const bookingDeclinedMessage = <FormattedMessage id="IncomePage.bookingDeclinedMessage" values={{ number: totalBookingDeclined }} />;
  const bookingExpiredMessage = <FormattedMessage id="IncomePage.bookingExpiredMessage" values={{ number: totalBookingExpired }} />;
  const bookingRequestMessage = <FormattedMessage id="IncomePage.bookingRequestMessage" values={{ number: totalBookingRequest }} />;
  const bookingRefundedMessage = <FormattedMessage id="IncomePage.bookingRefundedMessage" values={{ number: totalBookingRefunded }} />;

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="IncomePage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.mainTitleContainer}>
            <h1 className={css.mainTitle}>{mainTitle}</h1>
          </div>
          <div className={css.content}>
            {fetchBookedSalesError && (
              <div className={css.error}>
                {errorMessage}
              </div>
            )}
            <select className={css.selectedYear} value={selectedYear} onChange={handleSelectedYear}>
              {selectOptions}
            </select>
            <div className={css.incomeContainer}>
              <div className={css.income}>
                {fetchInProgress ? (
                  <IconSpinner />
                ) : (
                  formattedIncome
                )}
              </div>
              <div className={css.incomeTitle}>
                {incomeTitle}
              </div>
              <div className={css.infos}>
                <div className={css.mainInfos}>
                  {bookingRequestMessage}
                  {bookingAcceptedMessage}
                  {bookingDeclinedMessage}
                </div>
                <div className={css.otherInfos}>
                  {bookingExpiredMessage}
                  {bookingCancelledMessage}
                  {bookingRefundedMessage}
                </div>
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
      </LayoutSingleColumn>
    </Page>
  );
};

IncomePageComponent.defaultProps = {
  fetchCompletedSalesError: null,
  fetchInProgress: false,
  pagination: null,
  sendVerificationEmailError: null,
  transactions: [],
};

IncomePageComponent.propTypes = {
  fetchInProgress: bool.isRequired,
  fetchBookedSalesError: propTypes.error,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  transactions: array.isRequired,
  currentUserHasListings: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchBookedSalesError, pagination, bookedSales } = state.IncomePage;
  const { currentUserHasListings } = state.user;
  return {
    fetchBookedSalesError,
    fetchInProgress,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: bookedSales,
    currentUserHasListings,
  };
};

const IncomePage = compose(
  connect(mapStateToProps),
  injectIntl
)(IncomePageComponent);

IncomePage.loadData = loadData;

export default IncomePage;