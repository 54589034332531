import React, { Fragment, useState } from 'react';
import { arrayOf, bool, func, number, object, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { path, pathOr } from 'ramda';

import { DATE_TYPE_DATETIME, propTypes } from '../../../../util/types';
import { ensureTransaction } from '../../../../util/data';
import { moment } from '../../../../util/dates';
import { getBookingDatesFromTx, getUserTxRole } from '../../../../util/transactions';
import { createSlug } from '../../../../util/urlHelpers';
import { manageDisableScrolling } from '../../../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../../../ducks/marketplaceData.duck';
import { fetchMoreMessages, sendMessage, sendReview } from '../../../../ducks/transaction.duck';
import {
  ActivityFeed,
  BookingTimeInfo,
  Error,
  IconArrowHead,
  IconSpinner,
  NamedLink,
  ReviewModal,
  SecondaryButton,
  UserDisplayName,
} from '../../../../components';
import { SendMessageForm } from '../../../../forms';

import { getSendingMessageNotAllowed, getTxType } from '../../InboxPage.Helpers';
import css from './ActivityFeedSection.module.css';

const ActivityFeedSection = props => {
  const {
    className,
    currentUser,
    fetchMessagesError,
    fetchMessagesInProgress,
    fetchTransactionError,
    fetchTransactionInProgress,
    isMobile,
    isTablet,
    messages,
    oldestMessagePageFetched,
    onManageDisableScrolling,
    onSendMessage,
    onSendReview,
    onShowMoreMessages,
    sendMessageError,
    sendMessageInProgress,
    sendReviewError,
    sendReviewInProgress,
    setShowDetails,
    showDetails,
    totalMessagePages,
    transaction,
    intl,
  } = props;
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [reviewSubmitted, setReviewSubmitted] = useState(false);
  const [sendMessageFormFocused, setSendMessageFormFocused] = useState(false);
  const history = useHistory();
  const onOpenReviewModal = () => setIsReviewModalOpen(true);
  const onCloseReviewModal = () => setIsReviewModalOpen(false);
  const onSendMessageFormFocus = () => setSendMessageFormFocused(true);
  const onSendMessageFormBlur = () => setSendMessageFormFocused(false);
  const scrollToMessage = (messageId) => {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  };
  const onMessageSubmit = (values, form) => {
    const message = values.message ? values.message.trim() : null;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) return;
    
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        scrollToMessage(messageId);
      })
      .catch(e => {});
  };
  const onSubmitReview = (values) => {
    const ensuredTransaction = ensureTransaction(transaction);
    const transactionRole = getUserTxRole(currentUser.id, ensuredTransaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, ensuredTransaction, rating, reviewContent)
      .then(r => {
        setIsReviewModalOpen(false);
        setReviewSubmitted(true);
      })
      .catch(e => {});
  };
  const txType = getTxType(transaction, currentUser);
  const isOrder = txType === 'order';
  const { customer, booking, listing, provider } = transaction;
  const otherUser = isOrder ? provider : customer;
  const hasOlderMessages = totalMessagePages > oldestMessagePageFetched;
  const hasErrors = fetchTransactionError || fetchMessagesError;
  const isLoading = fetchTransactionInProgress || fetchMessagesInProgress;
  const sendingMessageNotAllowed = getSendingMessageNotAllowed(transaction, intl);
  const bookingDate = path(['attributes', 'end'], booking) || path(['attributes', 'protectedData', 'date'], transaction);
  const bookingDates = getBookingDatesFromTx(transaction);
  const dateDiff = bookingDate && moment().diff(moment(bookingDate), 'days');
  const outdated = dateDiff > 10;
  const listingId = path(['id', 'uuid'], listing);
  const listingTitle = pathOr('', ['attributes', 'title'], listing);
  const slug = createSlug(listingTitle);
  const outdatedLinkText = intl.formatMessage({ id: 'InboxPage.ActivityFeedSection.outdatedLink' });
  const errorText = intl.formatMessage({ id: 'InboxPage.ActivityFeedSection.error' });
  const sendMessagePlaceholder = intl.formatMessage({ id: 'InboxPage.sendMessagePlaceholder' });
  const detailsButtonText = intl.formatMessage({ id: isMobile
    ? 'InboxPage.ActivityFeedSection.details'
    : showDetails
    ? 'InboxPage.ActivityFeedSection.hideDetails'
    : 'InboxPage.ActivityFeedSection.showDetails'
  });
  const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;

  return (
    <section className={className}>
      <div className={css.header}>
        {isMobile && (
          <button className={css.backButton} onClick={() => history.goBack()}>
            <IconArrowHead className={css.iconArrow} direction="left" />
          </button>
        )}
        {transaction.id && (
          <Fragment>
            <div className={css.headingWrapper}>
              <h2 className={css.heading}>
                {otherUserDisplayName}
              </h2>
              {isMobile && bookingDates && (
                <div className={css.bookingInfoWrapper}>
                  <BookingTimeInfo
                    dateType={DATE_TYPE_DATETIME}
                    tx={transaction}
                    intl={intl}
                  />
                </div>
              )}
            </div>
            <SecondaryButton
              className={css.detailsButton}
              onClick={() => setShowDetails(prev => !prev)}
            >
              {detailsButtonText}
            </SecondaryButton>
          </Fragment>
        )}
      </div>
      <div className={css.content}>
        {isLoading && <IconSpinner />}
        {!isLoading && hasErrors && (
          <Error
            className={css.error}
            errorText={errorText}
          />
        )}
        {!isLoading && transaction.id && (
          <div className={css.wrapper}>
            <div className={css.activityFeedContainer}>
              <div className={css.activityFeedWrapper}>
                <ActivityFeed
                  className={css.activityFeed}
                  messages={messages}
                  transaction={transaction}
                  currentUser={currentUser}
                  hasOlderMessages={hasOlderMessages && !fetchMessagesInProgress}
                  onOpenReviewModal={onOpenReviewModal}
                  onShowOlderMessages={onShowMoreMessages}
                  fetchMessagesInProgress={fetchMessagesInProgress}
                />
              </div>
            </div>
            <div className={css.sendMessageFormContainer}>
              {sendingMessageNotAllowed ? (
                <div className={css.sendingMessageNotAllowed}>
                  <span className={css.notAllowedText}>{sendingMessageNotAllowed}</span>
                  {outdated && (
                    <NamedLink
                      className={css.outdatedLink}
                      name="ListingPage"
                      params={{ id: listingId, slug }}
                    >
                      {outdatedLinkText}
                    </NamedLink>
                  )}
                </div>
              ) : (
                <SendMessageForm
                  formId='InboxPage.SendMessageForm'
                  rootClassName={css.sendMessageForm}
                  messagePlaceholder={sendMessagePlaceholder}
                  inProgress={sendMessageInProgress}
                  isMobile={isMobile || isTablet}
                  sendMessageError={sendMessageError}
                  onFocus={onSendMessageFormFocus}
                  onBlur={onSendMessageFormBlur}
                  onSubmit={onMessageSubmit}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <ReviewModal
        id='ReviewModal'
        isOpen={isReviewModalOpen}
        onCloseModal={onCloseReviewModal}
        onManageDisableScrolling={onManageDisableScrolling}
        onSubmit={onSubmitReview}
        revieweeName={otherUserDisplayName}
        reviewSent={reviewSubmitted}
        sendReviewInProgress={sendReviewInProgress}
        sendReviewError={sendReviewError}
      />
    </section>
  )
};

ActivityFeedSection.defaultProps = {
  currentUser: null,
  fetchMessagesError: null,
  fetchTransactionError: null,
  oldestMessagePageFetched: 0,
  sendMessageError: null,
  sendReviewError: null,
  totalMessagePages: 0,
  transaction: {},
};

ActivityFeedSection.propTypes = {
  className: string.isRequired,
  currentUser: propTypes.currentUser,
  fetchMessagesError: propTypes.error,
  fetchMessagesInProgress: bool.isRequired,
  fetchTransactionError: propTypes.error,
  fetchTransactionInProgress: bool.isRequired,
  isMobile: bool.isRequired,
  isTablet: bool.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  oldestMessagePageFetched: number,
  onManageDisableScrolling: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onShowMoreMessages: func.isRequired,
  sendMessageError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  setShowDetails: func.isRequired,
  showDetails: bool.isRequired,
  totalMessagePages: number,
  transaction: object,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchMessagesError,
    fetchMessagesInProgress,
    fetchTransactionError,
    fetchTransactionInProgress,
    messages,
    oldestMessagePageFetched,
    sendMessageError,
    sendMessageInProgress,
    sendReviewError,
    sendReviewInProgress,
    totalMessagePages,
    transactionRef,
  } = state.transaction;

  return {
    fetchMessagesError,
    fetchMessagesInProgress,
    fetchTransactionError,
    fetchTransactionInProgress,
    messages,
    oldestMessagePageFetched,
    sendMessageError,
    sendMessageInProgress,
    sendReviewError,
    sendReviewInProgress,
    totalMessagePages,
    transaction: getMarketplaceEntities(state, transactionRef ? [transactionRef] : [])[0],
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onSendMessage: (txId, message) => dispatch(sendMessage(txId, message)),
    onSendReview: (role, tx, reviewRating, reviewContent) =>
      dispatch(sendReview(role, tx, reviewRating, reviewContent)),
    onShowMoreMessages: txId => dispatch(fetchMoreMessages(txId)),}
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl,
)(ActivityFeedSection);
