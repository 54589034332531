import React, { Fragment } from 'react';
import { bool, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { path, pathOr } from 'ramda';

import { propTypes } from '../../../util/types';
import { createSlug } from '../../../util/urlHelpers';
import { txIsPreauthorized } from '../../../util/transactions';
import {
  IconArrowNavigation,
  IconCall,
  IconDone,
  IconHomePin,
  IconInfo,
  IconMail,
  IconPool
} from '../../../components/Icons';
import { Divider, NamedLink } from '../../../components';

import css from './ContactActions.module.css';

const ContactActions = (props) => {
  const {
    className,
    intl,
    isOrder,
    showPrivateInfo,
    transaction,
  } = props;
  const listing = path(['listing'], transaction);
  const listingAddress = isOrder && path(['attributes', 'protectedData', 'address'], transaction);
  const listingGeolocation = path(['attributes', 'geolocation'], listing);
  const phoneNumber = isOrder
    ? path(['attributes', 'protectedData', 'providerPhoneNumber'], transaction)
    : path(['attributes', 'protectedData', 'customerPhoneNumber'], transaction);
  const listingUuid = pathOr('', ['id', 'uuid'], listing);
  const listingTitle = pathOr('', ['attributes', 'title'], listing);
  const slug = createSlug(listingTitle);
  const messageText = intl.formatMessage({ id: 'ReservationsPage.ContactActions.message' });
  const addressText = intl.formatMessage({ id: 'ReservationsPage.ContactActions.address' });
  const callText = intl.formatMessage({ id: `ReservationsPage.ContactActions.call${isOrder ? 'Provider' : 'Customer'}` });
  const listingText = intl.formatMessage({ id: 'ReservationsPage.ContactActions.listing' });
  const detailsProviderText = intl.formatMessage({ id: `ReservationsPage.ContactActions.${txIsPreauthorized(transaction) ? 'accept' : 'details'}` });
  const detailsCustomerText = intl.formatMessage({ id: `ReservationsPage.ContactActions.details` });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      {showPrivateInfo && phoneNumber && (
        <Fragment>
          {listingAddress && (
            <Fragment>
              <a className={css.link} href={`https://www.google.com/maps/search/?api=1&query=${listingGeolocation.lat},${listingGeolocation.lng}`} target="_blank">
                <IconHomePin className={css.icon} />
                <div className={css.textWrapper}>
                  <span className={css.text}>{addressText}</span>
                  <span className={css.subtext}>{listingAddress}</span>
                </div>
                <IconArrowNavigation className={css.iconArrowNavigation} direction='right' />
              </a>
              <Divider className={css.divider} />
            </Fragment>
          )}
          {phoneNumber && (
            <Fragment>
              <a className={css.link} href={`tel:${phoneNumber}`}>
                <IconCall className={css.icon} />
                <div className={css.textWrapper}>
                  <span className={css.text}>{callText}</span>
                  <span className={css.subtext}>{phoneNumber}</span>
                </div>
                <IconArrowNavigation className={css.iconArrowNavigation} direction='right' />
              </a>
              <Divider className={css.divider} />
            </Fragment>
          )}
        </Fragment>
      )}
      <NamedLink className={css.linkSingleLine} name='InboxPage' params={{ id: transaction.id.uuid }}>
        <IconMail className={css.icon} />
        <span className={css.text}>{messageText}</span>
        <IconArrowNavigation className={css.iconArrowNavigation} direction='right' />
      </NamedLink>
      <Divider className={css.divider} />
      <NamedLink
        className={css.linkSingleLine}
        name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
        params={{ id: transaction.id.uuid }}
      >
        <IconInfo className={css.icon} />
        <span className={css.text}>{isOrder ? detailsCustomerText : detailsProviderText}</span>
        <IconArrowNavigation className={css.iconArrowNavigation} direction='right' />
      </NamedLink>
    </div>
  )
}

ContactActions.defaultProps = {
  className: null,
  showPrivateInfo: false,
};

ContactActions.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  isOrder: bool.isRequired,
  showPrivateInfo: bool,
  transaction: propTypes.transaction.isRequired,
};

export default ContactActions;