import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';

import config from '../../config';
import { isSeason } from '../../util/dates';
import { IconAdd, NamedLink } from '../../components';
import titleImgLogo from '../../assets/pictures/mainTitleImg.svg'

import css from './LandingPage.css';

const locale = config.locale;
const imgTitlePosition = classNames(css.mainTitleImg, {
  [css.mainTitleImgES]: locale === 'es',
  [css.mainTitleImgDE]: locale === 'de',
  [css.mainTitleImgIT]: locale === 'it',
  [css.mainTitleImgUS]: locale === 'en',
});

const SectionMain = props => {
  const { isMobile, intl } = props;
  const { hasInsurance, insuranceLogo } = config.custom;

  const titleImg = (
    <div className={css.mainTitleImgContainer}>
      <img className={imgTitlePosition} src={titleImgLogo} alt='smile' />
    </div>)
  const title = (<FormattedMessage id='LandingPage.mainTitle' values={{ img: titleImg }} />);
  const subtitle = intl.formatMessage({ id: `LandingPage.${isSeason() ? 'mainSubtitle' : 'mainSubtitleOffSeason'}` });
  const ctaText = intl.formatMessage({ id: 'LandingPage.mainCTA' });
  const insuranceText = intl.formatMessage({ id: 'LandingPage.mainInsurance' });

  return (
    <div className={css.sectionMain}>
      <div className={css.mainContainer}>
        <div className={css.mainHeroContainer}>
          <div className={css.mainTitleContainer}>
            <span className={css.mainTitle}>{title}</span>
            <h1 className={css.mainSubtitle}>{subtitle}</h1>
          </div>
          <div className={css.mainBackground}>
            <div className={css.mainWomanImg} />
            <div className={css.mainManImg} />
            <div className={css.mainChildImg} />
            <NamedLink
              className={css.mainCTA}
              name='SimulatorPage'
            >
              <span className={css.buttonText}>{ctaText}</span>
              <IconAdd className={css.buttonIcon} />
            </NamedLink>
          </div>
        </div>
        {hasInsurance ? (
          <div className={css.mainInsurance}>
            <span className={css.mainInsuranceText}>{insuranceText}</span>
            <img className={css.mainInsuranceImg} src={insuranceLogo} alt='Insurance' />
          </div>
        ) : null}
      </div>

      <div className={css.mainContainerDesktop}>
        <div className={css.mainLeftContainer}>
          <div className={css.mainHeroContainerDesktop}>
            <div className={css.mainTitleContainer}>
              <span className={css.mainTitle}>{title}</span>
              <h1 className={css.mainSubtitle}>{subtitle}</h1>
            </div>
            <NamedLink
              className={css.mainCTA}
              name='SimulatorPage'
            >
              <span className={css.buttonText}>{ctaText}</span>
              <IconAdd className={css.buttonIcon} />
            </NamedLink>
          </div>
          {hasInsurance ? (
            <div className={css.mainInsurance}>
              <span className={css.mainInsuranceText}>{insuranceText}</span>
              <img className={css.mainInsuranceImg} src={insuranceLogo} alt='Insurance' />
            </div>
          ) : null}
        </div>
        <div className={css.mainRightContainer}>
          <div className={css.mainWomanImg} />
          <div className={css.mainManImg} />
          <div className={css.mainChildImg} />
        </div>
      </div>
    </div>
  )
}

export default injectIntl(SectionMain);