import React, { useEffect, useRef, useState } from 'react';
import { func, object, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { getTotalHours } from '../../../../util/time';
import {
  ADULTS,
  END_TIME,
  FLEXIBLE,
  START_TIME,
} from '../../../../util/search';
import {
  FieldCheckbox,
  FieldResetButton,
  FieldTimeRangePicker,
  PrimaryButton,
} from '../../../../components';

import { blurAllFields } from '../../SearchDesktop.helpers';
import css from './TimeSlotInput.module.css';

const TimeSlotInput = (props) => {
  const {
    className,
    onClick,
    activeField,
    finalFormApi,
    formValues,
    intl,
  } = props;
  const [showPopUp, setShowPopUp] = useState(false);
  const ref = useRef();
  const handleClickOutside = (e) => {
    if (!ref.current) return;
    if (ref.current.contains(e.target)) return;

    blurAllFields(finalFormApi);
    setShowPopUp(false);
  };
  useEffect(() => {
    if (activeField !== START_TIME) return;

    setShowPopUp(true);
  }, [activeField])
  useEffect(() => {
    if (!showPopUp) return;

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    };
  }, [showPopUp]);
  const handleInputClick = (e) => {
    onClick();
    setShowPopUp(prev => !prev);
  }
  const handleReset = (e) => {
    finalFormApi.change(START_TIME, undefined);
    finalFormApi.change(END_TIME, undefined);
    finalFormApi.change(FLEXIBLE, undefined);
    finalFormApi.focus(START_TIME);
  }
  const handleConfirm = (e) => {
    setShowPopUp(false);
    finalFormApi.focus(ADULTS);
  };
  const value = getTotalHours(formValues.startTime, formValues.endTime);
  const labelText = intl.formatMessage({ id: 'SearchDesktop.TimeSlotInput.label' });
  const placeholderText = intl.formatMessage({ id: 'SearchDesktop.TimeSlotInput.placeholder' });
  const valueText = value ? intl.formatMessage({ id: 'SearchDesktop.TimeSlotInput.value' }, { hours: value }) : placeholderText;
  const startTimeLabelText = intl.formatMessage({ id: 'SearchDesktop.TimeSlotInput.startTimeLabel' });
  const endTimeLabelText = intl.formatMessage({ id: 'SearchDesktop.TimeSlotInput.endTimeLabel' });
  const flexibleLabelText = intl.formatMessage({ id: 'SearchDesktop.TimeSlotInput.flexibleLabel' });
  const nextButtonText = intl.formatMessage({ id: 'Marketplace.next' });
  const classes = classNames(css.root, className);
  const valueClasses = classNames(css.value, {
    [css.placeholder]: !value,
    [css.active]: showPopUp,
  });
  const popUpClasses = showPopUp ? css.popUp : css.popUpHidden;
  
  return (
    <div className={classes}>
      <button className={css.button} type="button" onClick={handleInputClick}>
        <label className={css.label}>{labelText}</label>
        <span className={valueClasses}>{valueText}</span>
      </button>
      {value && ( 
        <FieldResetButton
          className={css.fieldResetButton}
          fieldName={START_TIME}
          finalFormApi={finalFormApi}
          onClick={handleReset}
        />
      )}
      <div className={popUpClasses} ref={ref}>
        <FieldTimeRangePicker
          finalFormApi={finalFormApi}
          idStart={START_TIME}
          idEnd={END_TIME}
          labelEnd={endTimeLabelText}
          labelStart={startTimeLabelText}
          nameStart={START_TIME}
          nameEnd={END_TIME}
          maxHour={24}
          currentStartValue={formValues.startTime}
        />
        <div className={css.flexibleInput}>
          <FieldCheckbox
            textClassName={css.flexibleText}
            id={FLEXIBLE}
            label={flexibleLabelText}
            name={FLEXIBLE}
            value="true"
          />
        </div>
        <PrimaryButton
          className={css.nextButton}
          type="button"
          onClick={handleConfirm}
        >
          {nextButtonText}
        </PrimaryButton>
      </div>
    </div>
  )
};

TimeSlotInput.defaultProps = {
  className: null,
  activeField: undefined,
};

TimeSlotInput.propTypes = {
  className: string,
  onClick: func.isRequired,
  activeField: string,
  finalFormApi: object.isRequired,
  formValues: object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(TimeSlotInput);