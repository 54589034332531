export const linksProps = {
  review1: {
    id: '5d42ec42-f845-41fa-bae2-2b83dbf344da',
    videoLink: 'https://youtu.be/G_-bgJT_9JQ',
    thumbnail: 'https://i.ibb.co/JcN1rxN/SWIMMY-ROSELYNE.jpg',
    reviewContent: "On fait venir des gens inconnus dans son environnement personnel donc oui au début j'avais quelques appréhensions. Tous les gens qui sont venus sont super sympas, respectueux donc depuis un an et demi je n'ai plus d'appréhensions !",
    provider: {
      displayName: 'Roselyne',
    },
  },
  review2: {
    id: '5eeb2825-87ae-4e9c-bf1f-23b2f81d4acf',
    videoLink: 'https://youtu.be/Y5SmhLiwuCM',
    thumbnail: 'https://i.ibb.co/zXVSdkv/SWIMMY-MATTHIEU.jpg',
    reviewContent: "Ce que j'aime le plus dans ce concept c'est la rencontre. Il peut y avoir des petits clins d'oeil de la journée quand les locataires nous invitent à la fin de la location pour partager un apéritif ! Par ailleurs en cas de problème, le service client de Swimmy est vraiment joignable rapidement par téléphone, c'est rassurant.",
    provider: {
      displayName: 'Matthieu',
    },
  },
  review3: {
    id: '5d168440-3886-4c7d-894f-26e1b9e0aa78',
    videoLink: 'https://youtu.be/0fGUluPdqLo',
    thumbnail: 'https://i.ibb.co/brQxBjP/SWIMMY-DOMINIQUE.jpg',
    reviewContent: "Le résultat a été supérieur à ce que l'on pensait ! Les gens sont très bienveillants et respectueux. Je reçois tout plein de famille, parceque c'est de la joie et ça fait vivre aussi ma propriété.",
    provider: {
      displayName: 'Dominique',
    },
  },
};
