import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconArrow.css';

const UP = 'up';
const DOWN = 'down';
const LEFT = 'left';
const RIGHT = 'right';

const IconArrow = props => {
  const { rootClassName, className, direction } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (direction === UP) {
    return (
      <svg className={classes} width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.50011 15.0416L9.50011 3.95825" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.95852 9.49992L9.50019 3.95825L15.0419 9.49992" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  } else if (direction === DOWN) {
    return (
      <svg className={classes} width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.50027 3.95829L9.50027 15.0416" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.0418 9.49996L9.50018 15.0416L3.95852 9.49996" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  } else if (direction === LEFT) {
    return (
      <svg className={classes} width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.0417 9.5H3.95833" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.5 15.0417L3.95833 9.50004L9.5 3.95837" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  } else if (direction === RIGHT) {
    return (
      <svg className={classes} width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.95848 9.49988L15.0418 9.49988" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.50014 3.95829L15.0418 9.49996L9.50014 15.0416" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  }
};

IconArrow.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconArrow.propTypes = {
  rootClassName: string,
  className: string,
  direction: string.isRequired,
};

export default IconArrow;
