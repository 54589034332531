import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { isBooleanString, stringToBoolean } from '../../util/data';
import { IconCheckmark, IconClose } from '../../components';

import css from './Switch.css';

const Switch = props => {
  const { value, onClick, highlight } = props;
  const [booleanValue, setBooleanValue] = useState();
  useEffect(() => {
    if (isBooleanString(value)) {
      const valueAsBoolean = stringToBoolean(value);
      setBooleanValue(valueAsBoolean);
    } else {
      setBooleanValue(value)
    };
  }, [value])
  const isFalse = value === false;
  const buttonClasses = classNames(css.button, {
    [css.buttonFalse]: highlight && isFalse,
    [css.buttonTrue]: booleanValue,
    [css.buttonDisabled]: !onClick,
  });
  const switchClasses = classNames(css.switch, {
    [css.switchFalse]: highlight && isFalse,
    [css.switchTrue]: booleanValue,
  });

  return (
    <button className={buttonClasses} type="button" onClick={onClick}>
      <div className={switchClasses}>
        { highlight && booleanValue && <IconCheckmark size='small' />}
        { highlight && isFalse && <IconClose />}
      </div>
    </button>
  )
};

export default Switch;