import React from 'react';
import { bool, number, object, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';

import { getNotAllowedText } from '../../ContactPage.helpers';
import css from './DetailsAndPolicies.module.css';

const DetailsAndPolicies = (props) => {
  const {
    className,
    headingClassName,
    listClassName,
    childrenAllowed,
    hasToilet,
    intl,
    minimumHoursPerBooking,
    notAllowedPolicies,
  } = props;
  const hasNotAllowed = Object.keys(notAllowedPolicies).length > 0;
  const headingText = intl.formatMessage({ id: 'ContactPage.InformationPanel.detailsHeading' });
  const minimumHoursPerBookingText = intl.formatMessage({ id: 'ContactPage.InformationPanel.minimumHoursPerBooking' }, { count: minimumHoursPerBooking });
  const availabilityText = intl.formatMessage({ id: 'ContactPage.InformationPanel.availability' });
  const noToiletText = intl.formatMessage({ id: 'ContactPage.InformationPanel.noToilet' });
  const noChildrenText = intl.formatMessage({ id: 'ContactPage.InformationPanel.noChildren' });
  const notAllowedText = getNotAllowedText(intl, notAllowedPolicies);
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <span className={headingClassName}>{headingText}</span>
      <ul className={listClassName}>
        {minimumHoursPerBooking > 1 && <li>{minimumHoursPerBookingText}</li>}
        <li>{availabilityText}</li>
        {!hasToilet && <li>{noToiletText}</li>}
        {!childrenAllowed && <li>{noChildrenText}</li>}
        {hasNotAllowed && <li>{notAllowedText}</li>}
      </ul>
    </div>
  )
}

DetailsAndPolicies.defaultProps = {
  className: null,
  minimumHoursPerBooking: 1,
  notAllowedPolicies: {},
};

DetailsAndPolicies.propTypes = {
  className: string,
  headingClassName: string.isRequired,
  listClassName: string.isRequired,
  childrenAllowed: bool.isRequired,
  hasToilet: bool.isRequired,
  intl: intlShape.isRequired,
  minimumHoursPerBooking: number,
  notAllowedPolicies: object,
};

export default DetailsAndPolicies;