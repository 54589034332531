import React from 'react';
import { string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { IconSearch } from '../../../../components';

import css from './SearchButton.module.css';

const SearchButton = (props) => {
  const {
    className,
    intl,
    ...buttonProps
  } = props;
  const buttonText = intl.formatMessage({ id: 'SearchDesktop.SearchButton.text' });
  const classes = classNames(css.root, className);

  return (
    <button className={classes} type="button" {...buttonProps}>
      <span className={css.text}>{buttonText}</span>
      <div className={css.iconSearchContainer}>
        <IconSearch className={css.iconSearch} />
      </div>
    </button>
  )
};

SearchButton.defaultProps = {
  className: null,
};

SearchButton.propTypes = {
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(SearchButton);