import { convertDistance, getDistance } from 'geolib';
import moment from 'moment';
import config from '../config';

export const MIN_REVIEWS_TO_SHOW_AVERAGE_RATE = 3;
export const DAYS_CONSIDERED_NEW_LISTING = 60;

export const getDistanceBetweenCities = (start, end) => {
  return Math.round(convertDistance(getDistance(start, end) * 1.15, config.custom.distanceUnit) * 10) / 10;
};

export const getLatLngFromOrigin = (origin) => {
  if (!origin) return null;

  if (typeof origin === 'object') {
    return { latitude: origin.lat, longitude: origin.lng };
  }

  return { latitude: parseFloat(origin.split(',')[0]), longitude: parseFloat(origin.split(',')[1]) };
}

export const getDaysSinceCreation = (createdAt) => {
  return moment().diff(createdAt, 'days');
}
