import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { pathOr } from 'ramda';

import { NamedLink, PrimaryButton, SecondaryButton } from '../../components';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    acceptInProgress,
    declineInProgress,
    cancelInProgress,
    acceptSaleError,
    declineSaleError,
    cancelBookingError,
    onAcceptSale,
    onDeclineSale,
    onCancelBooking,
    stateData,
    transaction,
    intl,
  } = props;

  const txProtectedData = pathOr({}, ['attributes', 'protectedData'], transaction);
  const canShowSearchButton = stateData.showSearchButton && txProtectedData.fromSearch;
  const searchMoreMessage = intl.formatMessage({ id: 'TransactionPanel.searchMore' });

  const buttonsDisabled = acceptInProgress || declineInProgress || cancelInProgress;
  const acceptButtonDisabled = buttonsDisabled;

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;
  const cancelErrorMessage = cancelBookingError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.cancelBookingFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return stateData.showSaleButtons || stateData.showCancelButton || stateData.showSearchButton ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptErrorMessage}
        {declineErrorMessage}
        {cancelErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        {stateData.showSaleButtons ? (
          <Fragment>
            <SecondaryButton
              inProgress={declineInProgress}
              disabled={buttonsDisabled}
              onClick={onDeclineSale}
            >
              <FormattedMessage id="TransactionPanel.declineButton" />
            </SecondaryButton>
            <PrimaryButton
              inProgress={acceptInProgress}
              disabled={acceptButtonDisabled}
              onClick={onAcceptSale}
            >
              <FormattedMessage id="TransactionPanel.acceptButton" />
            </PrimaryButton>
          </Fragment>
        ) : null}
        {stateData.showCancelButton ? (
          <SecondaryButton
            inProgress={cancelInProgress}
            disabled={buttonsDisabled}
            onClick={() => onCancelBooking()}
          >
            <FormattedMessage id="TransactionPanel.cancelButton" />
          </SecondaryButton>
        ) : null}
        {canShowSearchButton ? (
          <NamedLink
            className={css.searchMoreLink}
            name="SearchPage"
            to={{ search: txProtectedData.fromSearch }}
          >
            <span>{searchMoreMessage}</span>
          </NamedLink>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default injectIntl(SaleActionButtonsMaybe);
