import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';

import {
  IconCalendar,
  IconClose,
  IconFilter,
  IconGroup,
  IconSearch,
} from '../../../../components';

import css from './SearchContainer.module.css';

const SearchContainer = (props) => {
  const {
    className,
    handleClose,
    handleShowFilters,
    handleView,
    hasActiveFilters,
    intl,
    mapSearch,
    searchParams,
  } = props;
  const { address, adults, children, city, bookingDate, infants, timeSlot } = searchParams;
  const totalParticipants = adults + (children || 0);
  const hasParticipants = totalParticipants > 0;
  const headingText = intl.formatMessage({ id: 'Search.SearchContainer.heading' });
  const locationText = intl.formatMessage({ id: 'Search.SearchContainer.location' });
  const datePlaceholderText = intl.formatMessage({ id: 'Search.SearchContainer.datePlaceholder' });
  const getTimeSlotTextId = (timeSlot) => {
    switch (timeSlot) {
      case 'morning':
        return 'Marketplace.morning';
      case 'afternoon':
        return 'Marketplace.afternoon';
      case 'evening':
        return 'Marketplace.evening';

      default:
        return 'Marketplace.fullday';
    }
  };
  const timeSlotText = intl.formatMessage({ id: getTimeSlotTextId(timeSlot) });
  const participantsPlaceholderText = intl.formatMessage({ id: 'Search.SearchContainer.participantsPlaceholder' });
  const totalParticipantsText = intl.formatMessage({ id: 'Search.totalParticipants' }, { count: totalParticipants });
  const infantsCountText = intl.formatMessage({ id: 'Search.infants' }, { count: infants });
  const classes = classNames(css.root, className);
  const dateClasses = classNames(css.date, {
    [css.placeholder]: !bookingDate,
  });
  const participantsClasses = classNames(css.date, {
    [css.placeholder]: !hasParticipants,
  });
  const filterButtonClasses = classNames(css.filterButton, {
    [css.activeFilters]: hasActiveFilters,
  });

  return (
    <div className={classes}>
      <div className={css.header}>
        <button className={css.closeButton} type="button" onClick={handleClose}>
          <IconClose />
        </button>
        <span className={css.heading}>{headingText}</span>
        <button className={filterButtonClasses} type="button" onClick={handleShowFilters}>
          <IconFilter />
        </button>
      </div>
      <div className={css.buttonWrapper}>
        <button className={css.locationButton} type="button" onClick={() => handleView('LocationView')}>
          <IconSearch className={css.icon} />
          <span className={css.location}>{mapSearch ? locationText : (city || address)}</span>
        </button>
        <button className={css.dateButton} type="button" onClick={() => handleView('DateView')}>
          <IconCalendar className={css.icon} />
          <span className={dateClasses}>
            {bookingDate ? moment(bookingDate).format('D MMM') : datePlaceholderText}
            {timeSlot && ` • ${timeSlotText}`}
          </span>
        </button>
        <button className={css.participantsButton} type="button" onClick={() => handleView('ParticipantsView')}>
          <IconGroup className={css.icon} />
          <span className={participantsClasses}>
            {hasParticipants ? totalParticipantsText : participantsPlaceholderText}
            {infants > 0 && ', '}
            {infants > 0 && infantsCountText}
          </span>
        </button>
      </div>
    </div>
  )
}

SearchContainer.defaultProps = {
  className: null,
  mapSearch: false,
  searchParams: {},
};

SearchContainer.propTypes = {
  className: string,
  handleClose: func.isRequired,
  handleShowFilters: func.isRequired,
  handleView: func.isRequired,
  intl: intlShape.isRequired,
  mapSearch: bool,
  searchParams: object,
};

export default injectIntl(SearchContainer);