import React from 'react';
import classNames from 'classnames';

import { NamedLink, ExternalLink } from '../../components';

import css from './Card.css';

const Card = (props) => {
  const {
    className,
    rootClassName,
    imgClassName,
    titleClassName,
    textClassName,
    linkClassName,
    img,
    title,
    subtitle,
    text,
    linkProps,
    linkText,
    isExternalLink,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const titleWrapperClasses = classNames(css.titleWrapper, titleClassName);
  const textClasses = classNames(css.text, textClassName);
  const linkClasses = classNames(css.link, linkClassName);

  return (
    <div className={classes}>
      {img ? (
        <div className={css.imgContainer}>
          <img className={imgClassName} src={img}/>
        </div>
      ) : null}
      {title ? (
        <div className={titleWrapperClasses}>
          <span className={css.title}>{title}</span>
          {subtitle ? (<span className={css.subtitle}>{subtitle}</span>) : null}
      </div>
      ) : null}
      {text ? (<p className={textClasses}>{text}</p>) : null}
      {linkProps && !isExternalLink ? (<NamedLink className={linkClasses} {...linkProps}>{linkText}</NamedLink>) : null}
      {linkProps && isExternalLink ? (<ExternalLink className={linkClasses} {...linkProps}>{linkText}</ExternalLink>) : null}
    </div>
  );
}

export default Card;