import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconGroup.module.css';

const IconGroup = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M2 20Q1.575 20 1.288 19.712Q1 19.425 1 19V17.2Q1 16.35 1.438 15.637Q1.875 14.925 2.6 14.55Q4.15 13.775 5.75 13.387Q7.35 13 9 13Q10.65 13 12.25 13.387Q13.85 13.775 15.4 14.55Q16.125 14.925 16.562 15.637Q17 16.35 17 17.2V19Q17 19.425 16.712 19.712Q16.425 20 16 20ZM18.525 20Q18.75 19.825 18.875 19.562Q19 19.3 19 18.975V17Q19 15.9 18.388 14.887Q17.775 13.875 16.65 13.15Q17.925 13.3 19.05 13.662Q20.175 14.025 21.15 14.55Q22.05 15.05 22.525 15.662Q23 16.275 23 17V19Q23 19.425 22.712 19.712Q22.425 20 22 20ZM9 12Q7.35 12 6.175 10.825Q5 9.65 5 8Q5 6.35 6.175 5.175Q7.35 4 9 4Q10.65 4 11.825 5.175Q13 6.35 13 8Q13 9.65 11.825 10.825Q10.65 12 9 12ZM19 8Q19 9.65 17.825 10.825Q16.65 12 15 12Q14.725 12 14.3 11.938Q13.875 11.875 13.6 11.8Q14.275 11 14.637 10.025Q15 9.05 15 8Q15 6.95 14.637 5.975Q14.275 5 13.6 4.2Q13.95 4.075 14.3 4.037Q14.65 4 15 4Q16.65 4 17.825 5.175Q19 6.35 19 8ZM3 18H15V17.2Q15 16.925 14.863 16.7Q14.725 16.475 14.5 16.35Q13.15 15.675 11.775 15.337Q10.4 15 9 15Q7.6 15 6.225 15.337Q4.85 15.675 3.5 16.35Q3.275 16.475 3.138 16.7Q3 16.925 3 17.2ZM9 10Q9.825 10 10.413 9.412Q11 8.825 11 8Q11 7.175 10.413 6.588Q9.825 6 9 6Q8.175 6 7.588 6.588Q7 7.175 7 8Q7 8.825 7.588 9.412Q8.175 10 9 10ZM9 8Q9 8 9 8Q9 8 9 8Q9 8 9 8Q9 8 9 8Q9 8 9 8Q9 8 9 8Q9 8 9 8Q9 8 9 8ZM9 15Q9 15 9 15Q9 15 9 15Q9 15 9 15Q9 15 9 15Q9 15 9 15Q9 15 9 15Q9 15 9 15Q9 15 9 15Z"/>
    </svg>
  );
};

IconGroup.defaultProps = {
  rootClassName: null,
  className: null,
};

IconGroup.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconGroup;