import React from 'react';
import { string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import css from './SuperhostBadge.module.css';

const SuperhostBadge = (props) => {
  const {
    className,
    intl,
  } = props;
  const superhostText = intl.formatMessage({ id: 'Marketplace.superhost' });
  const classes = classNames(css.root, className);

  return (
    <span className={classes}>{superhostText}</span>
  )
}

SuperhostBadge.defaultProps = {
  className: null,
};

SuperhostBadge.propTypes = {
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(SuperhostBadge);
