import { path } from 'ramda';

import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import config from '../../config';

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/ProfileSettingsPage/CLEAR_UPDATED_FORM';

export const UPLOAD_IMAGE_REQUEST = 'app/ProfileSettingsPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/ProfileSettingsPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/ProfileSettingsPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_PROFILE_REQUEST = 'app/ProfileSettingsPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/ProfileSettingsPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/ProfileSettingsPage/UPDATE_PROFILE_ERROR';

export const UPLOAD_CNI_DOCUMENT_SUCCESS = 'app/ProfileSettingsPage/UPLOAD_CNI_DOCUMENT_SUCCESS';

export const SAVE_EMAIL_ERROR = 'app/ProfileSettingsPage/SAVE_EMAIL_ERROR';

// ================ Reducer ================ //

const initialState = {
  image: null,
  uploadImageError: null,
  uploadInProgress: false,
  updateInProgress: false,
  updateProfileError: null,
  saveEmailError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_IMAGE_REQUEST:
      // payload.params: { id: 'tempId', file }
      return {
        ...state,
        image: { ...payload.params },
        uploadInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS: {
      // payload: { id: 'tempId', uploadedImage }
      const { id, uploadedImage } = payload;
      const { file } = state.image || {};
      const image = { id, imageId: uploadedImage.id, file, uploadedImage };
      return { ...state, image, uploadInProgress: false };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, image: null, uploadInProgress: false, uploadImageError: payload.error };
    }

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateProfileError: null,
        saveEmailError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        image: null,
        updateInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        image: null,
        updateInProgress: false,
        updateProfileError: payload,
      };

    case UPLOAD_CNI_DOCUMENT_SUCCESS:
      return {
        updateInProgress: false,
        updateProfileError: null,
      };

    case SAVE_EMAIL_ERROR:
      return { ...state, updateInProgress: false, saveEmailError: payload };

    case CLEAR_UPDATED_FORM:
      return { ...state, updateProfileError: null, uploadImageError: null };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const clearUpdatedForm = () => ({
  type: CLEAR_UPDATED_FORM,
});

// SDK method: images.upload
export const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = result => ({ type: UPLOAD_IMAGE_SUCCESS, payload: result.data });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR,
  payload: error,
  error: true,
});

export const updateCNIDocumentSuccess = () => ({
  type: UPLOAD_CNI_DOCUMENT_SUCCESS,
});

// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = params => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: { params },
});
export const updateProfileSuccess = result => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: result.data,
});
export const updateProfileError = error => ({
  type: UPDATE_PROFILE_ERROR,
  payload: error,
  error: true,
});
export const saveEmailError = error => ({
  type: SAVE_EMAIL_ERROR,
  payload: error,
  error: true,
});

// ================ Thunk ================ //

export const getStipeFileUploadedId = async data => {
  if (!data) return;

  const cniFileResult = await fetch('https://uploads.stripe.com/v1/files', {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${config.stripe.publishableKey}` },
    body: data,
  });
  const fileData = await cniFileResult.json();
  const { id } = fileData;
  return id;
};

const uploadCNIDocument = async (documents, dispatch, sdk) => {
  const { cniFormData, proofOfAddressFormData } = documents;
  if (!cniFormData && !proofOfAddressFormData) return;

  try {
    if (typeof window === 'undefined' || !window.Stripe) {
      throw new Error('Stripe must be loaded to upload identity documents');
    }

    const stripe = window.Stripe(config.stripe.publishableKey);
    dispatch(updateProfileRequest());
    const cniFileId = await getStipeFileUploadedId(cniFormData);
    const proofOfAddressFileId = await getStipeFileUploadedId(proofOfAddressFormData);
    console.log('cniFileId id', cniFileId);
    console.log('proofOfAddressFileId id', proofOfAddressFileId);
    if (!cniFileId && !proofOfAddressFileId) {
      throw new Error('Documents: cannot upload files with stripe');
    }

    const cniDocumentParams = cniFileId ? {
      document: {
        front: cniFileId,
      },
    } : {};
    const additionalDocumentParams = proofOfAddressFileId ? {
      additional_document: {
        front: proofOfAddressFileId,
      }
    } : {};
    const params = {
      business_type: 'individual',
      individual: {
        verification: {
          ...cniDocumentParams,
          ...additionalDocumentParams,
        },
      },
      tos_shown_and_accepted: true,
    };
    const tokenResult = await stripe.createToken('account', params);
    const tokenID = path(['token', 'id'], tokenResult);
    if (!tokenID) {
      throw new Error('CNI: No token id to update stripe account');
    }

   await sdk.stripeAccount.update({
      accountToken: tokenID,
    }, {
      expand: true
    });
    dispatch(updateCNIDocumentSuccess());
    dispatch(fetchCurrentUser());
  }
  catch (e) {
    dispatch(updateProfileError({error: storableError(e) }));
    return {
      error: e,
    };
  }
};

// Images return imageId which we need to map with previously generated temporary id
export function uploadImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImageRequest(actionPayload));

    const bodyParams = {
      image: actionPayload.file,
    };
    const queryParams = {
      expand: true,
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.images
      .upload(bodyParams, queryParams)
      .then(resp => {
        const uploadedImage = resp.data.data;
        dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export const updateProfile = (actionPayload, documents, redirectionPayload = {}) => {
  return (dispatch, getState, sdk) => {
    dispatch(updateProfileRequest());

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };
    return sdk.currentUser
      .updateProfile(actionPayload, queryParams)
      .then(async response => {
        if (documents) {
          await uploadCNIDocument(documents, dispatch, sdk)
        }
        dispatch(updateProfileSuccess(response));

        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
        const { redirectOnValidate, history } = redirectionPayload;
        if (redirectOnValidate && history) {
          history.push(redirectOnValidate);
        }
      })
      .catch(e => {
        dispatch(updateProfileError(storableError(e)));
        return {
          error: e,
        };
      });
  };
};

/**
 * Make a email update request to the API and return the current user.
 */
const requestSaveEmail = params => (dispatch, getState, sdk) => {
  const { email, currentPassword } = params;

  return sdk.currentUser
    .changeEmail(
      { email, currentPassword },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveEmailError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Save email and update the current user.
 */
export const saveEmail = (params = {}, redirectionPayload = {}) => (dispatch, getState, sdk) => {
  return (
    dispatch(requestSaveEmail(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        const { redirectOnValidate, history } = redirectionPayload;
        if (redirectOnValidate && history) {
          history.push(redirectOnValidate);
        }
      })
      // error action dispatched in requestSaveEmail
      .catch(e => null)
  );
};
