import React, { Fragment, useEffect } from 'react';
import { func, object, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { pathOr } from 'ramda';

import { ADULTS, CHILDREN, INFANTS } from '../../../../util/search';
import { FieldResetButton, FieldStepper } from '../../../../components';

import css from './ParticipantsInput.module.css';

const ParticipantsInput = (props) => {
  const {
    activeField,
    className,
    finalFormApi,
    formValues,
    intl,
    onClick,
  } = props;
  const { adults, children, infants } = formValues;
  const isActive = [ADULTS, CHILDREN, INFANTS].includes(activeField);
  const adultsCount = pathOr(0, [], adults);
  const childrenCount = pathOr(0, [], children);
  const totalParticipants = adultsCount + childrenCount;
  const hasParticipants = totalParticipants > 0;
  const infantsCount = pathOr(0, [], infants);
  const hasInfants = infantsCount > 0;
  useEffect(() => {
    if (adultsCount > 0 || (childrenCount === 0 && infantsCount === 0)) return;

    finalFormApi.change(ADULTS, 1);
  }, [childrenCount, infantsCount]);
  const handleReset = () => {
    if (!hasParticipants) return;

    finalFormApi.change(ADULTS, undefined);
    finalFormApi.change(CHILDREN, undefined);
    finalFormApi.change(INFANTS, undefined);
  };
  const minAdults = childrenCount > 0 || hasInfants ? 1 : 0;
  const labelText = intl.formatMessage({ id: 'SearchDesktop.ParticipantsInput.label' });
  const placeholderText = intl.formatMessage({ id: 'SearchDesktop.ParticipantsInput.placeholder' });
  const totalParticipantsText = intl.formatMessage({ id: 'Search.totalParticipants' }, { count: totalParticipants });
  const infantsCountText = intl.formatMessage({ id: 'Search.infants' }, { count: infantsCount });
  const adultsLabelText = intl.formatMessage({ id: 'Search.ParticipantsView.adultsLabel' });
  const adultsSublabelText = intl.formatMessage({ id: 'Search.ParticipantsView.adultsSublabel' });
  const childrenLabelText = intl.formatMessage({ id: 'Search.ParticipantsView.childrenLabel' });
  const childrenSublabelText = intl.formatMessage({ id: 'Search.ParticipantsView.childrenSublabel' });
  const infantsLabelText = intl.formatMessage({ id: 'Search.ParticipantsView.infantsLabel' });
  const infantsSublabelText = intl.formatMessage({ id: 'Search.ParticipantsView.infantsSublabel' });
  const classes = classNames(css.root, className);
  const valueClasses = classNames(css.value, {
    [css.placeholder]: !hasParticipants,
    [css.active]: isActive,
  });
  const popUpClasses = isActive ? css.popUp : css.popUpHidden;
  
  return (
    <Fragment>
      <div className={classes}>
        <button className={css.button} type="button" onClick={onClick}>
          <label className={css.label}>{labelText}</label>
          <span className={valueClasses}>
            {hasParticipants ? totalParticipantsText : placeholderText}
            {hasInfants && `, ${infantsCountText}`}
          </span>
        </button>
        {hasParticipants && (
          <FieldResetButton
            className={css.fieldResetButton}
            onClick={handleReset}
          />
        )}
      </div>
      <div className={popUpClasses}>
        <FieldStepper
          className={css.fieldStepper}
          label={adultsLabelText}
          min={minAdults}
          name={ADULTS}
          sublabel={adultsSublabelText}
        />
        <FieldStepper
          className={css.fieldStepper}
          label={childrenLabelText}
          min={0}
          name={CHILDREN}
          sublabel={childrenSublabelText}
        />
        <FieldStepper
          className={css.fieldStepper}
          label={infantsLabelText}
          min={0}
          name={INFANTS}
          sublabel={infantsSublabelText}
        />
      </div>
    </Fragment>
  )
};

ParticipantsInput.defaultProps = {
  className: null,
  activeField: undefined,
};

ParticipantsInput.propTypes = {
  className: string,
  onClick: func.isRequired,
  activeField: string,
  finalFormApi: object.isRequired,
  formValues: object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ParticipantsInput);