import React from 'react';
import { string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';

import css from './InfoContent.module.css';

const InfoContent = (props) => {
  const {
    className,
    intl,
  } = props;
  const infoText = intl.formatMessage({ id: 'CalendarPage.MainContent.PlanManager.info' });
  const tipsText = intl.formatMessage({ id: 'CalendarPage.MainContent.PlanManager.tips' });
  const availableLegendText = intl.formatMessage({ id: 'CalendarPage.MainContent.availableLegend' });
  const unavailableLegendText = intl.formatMessage({ id: 'CalendarPage.MainContent.unavailableLegend' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <span className={css.info}>{infoText}</span>
      <span className={css.tips}>{tipsText}</span>
      <div className={css.legendContainer}>
        <div className={css.legendWrapper}>
          <div className={css.available} />
          <div className={css.legendTextWrapper}>
            <span className={css.legend}>{availableLegendText}</span>
          </div>
        </div>
        <div className={css.legendWrapper}>
          <div className={css.unavailable} />
          <div className={css.legendTextWrapper}>
            <span className={css.legend}>{unavailableLegendText}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

InfoContent.defaultProps = {
  className: null,
};

InfoContent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
};

export default InfoContent;
