import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconShare.module.css';

const IconShare = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px">
      <g>
        <rect fill="none" height="24" width="24"/>
      </g>
      <g>
        <path d="M16,5l-1.42,1.42l-1.59-1.59V16h-1.98V4.83L9.42,6.42L8,5l4-4L16,5z M20,10v11c0,1.1-0.9,2-2,2H6c-1.11,0-2-0.9-2-2V10 c0-1.11,0.89-2,2-2h3v2H6v11h12V10h-3V8h3C19.1,8,20,8.89,20,10z"/>
      </g>
    </svg>
  );
};

IconShare.defaultProps = {
  rootClassName: null,
  className: null,
};

IconShare.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconShare;
