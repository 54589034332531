const isValidChar = (char, regExp) => new RegExp(`${regExp}`).test(char);

const removeLeadingZeros = (str) => str.replace(/^[0]*/, '');

const hasUnit = (value, unit) => value.split('').includes(unit);

export const getUpdatedValueWithoutUnit = (currentValue, nextValue, min) => {
  const regExp = new RegExp('^[1-9][0-9]*$');
  const valueWithoutLeadingZeros = removeLeadingZeros(nextValue);

  if (min === 0 && !valueWithoutLeadingZeros) return '0';
  if (!valueWithoutLeadingZeros) return '';
  if (regExp.test(valueWithoutLeadingZeros)) return valueWithoutLeadingZeros;

  return currentValue;
};

export const getUpdatedValueWithUnit = (currentValue, nextValue, unit, min) => {
  const regExp = new RegExp(`^[1-9][0-9]*[${unit}]$`);
  const valueWithoutLeadingZeros = removeLeadingZeros(nextValue);
  
  if (nextValue === '0') return `0${unit}`;
  if (valueWithoutLeadingZeros === unit) return '';
  if (!currentValue && isValidChar(valueWithoutLeadingZeros, '[0-9]')) return `${valueWithoutLeadingZeros}${unit}`;

  const valueLength = valueWithoutLeadingZeros.length;
  const lastChar = valueWithoutLeadingZeros.slice(-1);
  const valueHasUnit = hasUnit(valueWithoutLeadingZeros, unit);
  const splittedValue = valueWithoutLeadingZeros.split('');

  if (regExp.test(valueWithoutLeadingZeros)) return valueWithoutLeadingZeros;
  if (isValidChar(lastChar, '[0-9]') && valueHasUnit) {
    splittedValue.splice(-2);

    return `${splittedValue.join('')}${lastChar}${unit}`;
  };
  if (currentValue !== valueWithoutLeadingZeros && !valueHasUnit && valueLength > 1) {
    splittedValue.splice(-1);

    return `${splittedValue.join('')}${unit}`;
  };
  if (currentValue !== valueWithoutLeadingZeros && (valueWithoutLeadingZeros === unit || !valueHasUnit)) return '';

  return currentValue;
};

export const getValueAsNumber = (value, unit) => {
  const valueWithoutUnit = unit ? value.slice(0, -1) : value;

  return parseInt(valueWithoutUnit, 10);
};

export const getIncreasedValue = (valueAsNumber, min, max, step, unit) => {
  const increasedValue = valueAsNumber + step;

  if (!unit) return `${increasedValue}`;
  if (min > increasedValue) return `${min}${unit}`;
  if (max && increasedValue > max) return `${max}${unit}`;

  return `${increasedValue}${unit}`;
}

export const getReducedValue = (valueAsNumber, min, step, unit) => {
  const reducedValue = valueAsNumber - step;
  
  if (!unit) return `${reducedValue}`;
  if (reducedValue < min) return `${min}${unit}`;

  return `${reducedValue}${unit}`;
}
