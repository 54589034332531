/**
 * Creates a sortable image grid with children added to the end of the created grid.
 *
 * Example:
 * // images = [{ id: 'tempId', imageId: 'realIdFromAPI', file: File }];
 * <AddImages images={images}>
 *   <input type="file" accept="images/*" onChange={handleChange} />
 * </AddImages>
 */
import React from 'react';
import { array, bool, func, node, object, string } from 'prop-types';
import classNames from 'classnames';

import { ThumbnailWrapper } from './components';
import css from './AddImages.css';

const AddImages = props => {
  const {
    children,
    className,
    thumbnailClassName,
    formApi,
    images,
    isNew,
    savedImageAltText,
    onRemoveImage,
  } = props;
  const classes = classNames(css.root, className);
  
  return (
    <div className={classes}>
      {images.map((image, index) => {
        return (
          <ThumbnailWrapper
            formApi={formApi}
            image={image}
            images={images}
            index={index}
            isNew={isNew}
            key={image.id.uuid || image.id}
            className={thumbnailClassName}
            savedImageAltText={savedImageAltText}
            onRemoveImage={onRemoveImage}
          />
        );
      })}
      {children}
    </div>
  );
};

AddImages.defaultProps = { className: null, thumbnailClassName: null, images: [] };

AddImages.propTypes = {
  formApi: object.isRequired,
  images: array,
  isNew: bool.isRequired,
  children: node.isRequired,
  className: string,
  thumbnailClassName: string,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
};

export default AddImages;
