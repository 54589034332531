import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import {
  LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT,
  LINE_ITEM_PROVIDER_COMMISSION_DISCOUNT,
  LINE_ITEM_UNITS_BASE_HOURS_DISCOUNT,
  LINE_ITEM_UNITS_HOURLY_CHILDREN,
  LINE_ITEM_WEEK_REDUCTION
} from '@swimmy_/helpers';

import css from './LineItem.module.css';

const LineItem = (props) => {
  const {
    className,
    code,
    icon,
    label,
    price,
    sublabel,
  } = props;
  const isReduction = [
    LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT,
    LINE_ITEM_PROVIDER_COMMISSION_DISCOUNT,
    LINE_ITEM_UNITS_BASE_HOURS_DISCOUNT,
    LINE_ITEM_UNITS_HOURLY_CHILDREN,
    LINE_ITEM_WEEK_REDUCTION
  ].includes(code);
  const classes = classNames(css.root, className);
  const labelClasses = classNames(css.label, { [css.reduction]: isReduction });
  const priceClasses = classNames(css.price, { [css.reduction]: isReduction });

  return (
    <div className={classes}>
      {icon}
      <div className={css.labelContainer}>
        <span className={labelClasses}>{label}</span>
        {sublabel && <span className={css.sublabel}>{sublabel}</span>}
      </div>
      <span className={priceClasses}>{price}</span>
    </div>
  )
}

LineItem.defaultProps = {
  className: null,
  sublabel: null,
};

LineItem.propTypes = {
  className: string,
  code: string.isRequired,
  icon: node.isRequired,
  label: string.isRequired,
  price: string.isRequired,
  sublabel: string,
};

export default LineItem;