import React from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { Modal, SecondaryButton } from '../../components';

import css from './styles.css';

const CancelBookingModal = props => {
  const {
    className,
    rootClassName,
    id,
    idDescriptionText,
    intl,
    isOpen,
    onOkModal,
    onCloseModal,
    onManageDisableScrolling,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'Actions.cancel' });

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      closeButtonMessage={closeButtonMessage}
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="CancelBookingModal.title" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id={idDescriptionText} />
      </p>
      <SecondaryButton className={css.modalButtonOk} onClick={onOkModal}>
        <FormattedMessage id="TransactionPanel.confirmCancelation" />
      </SecondaryButton>
    </Modal>
  );
};

CancelBookingModal.defaultProps = {
  className: null,
  rootClassName: null,
  id: null,
  idDescriptionText: null,
  isOpen: false,
  onOkModal: Function.prototype,
  onCloseModal: Function.prototype,
};

CancelBookingModal.propTypes = {
  className: string,
  rootClassName: string,
  id: string,
  idDescriptionText: string,
  isOpen: bool,
  onOkModal: func,
  onCloseModal: func,
  intl: intlShape.isRequired,
};

export default injectIntl(CancelBookingModal);
