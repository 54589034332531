import React from 'react';
import { bool, func, string } from 'prop-types'
import classNames from 'classnames';

import { IconArrow, IconStar } from '../../../../components/Icons';
import { IconButton } from '../../../../components';

import css from './ReorderActions.module.css';

const ReorderActions = (props) => {
  const {
    className,
    handleFirstImage,
    handleHigher,
    handleLower,
    isFirstImage,
    isLastImage,
  } = props;
  const classes = classNames(css.root, className);
  const iconArrowTopClasses = isFirstImage ? css.iconDisabled : '';
  const iconArrowBottomClasses = isLastImage ? css.iconDisabled : '';

  return (
    <div className={classes}>
      <IconButton
        type="submit"
        onClick={handleFirstImage}
        disabled={isFirstImage}
      >
        <IconStar className={isFirstImage ? css.iconStarFilled : ''} filled={isFirstImage} />
      </IconButton>
      <IconButton
        type="submit"
        onClick={handleHigher}
        disabled={isFirstImage}
      >
        <IconArrow className={iconArrowTopClasses} direction="top" />
      </IconButton>
      <IconButton
        type="submit"
        onClick={handleLower}
        disabled={isLastImage}
      >
        <IconArrow className={iconArrowBottomClasses} direction="bottom" />
      </IconButton>
    </div>
  )
}

ReorderActions.defaultProps = {
  className: null,
  isFirstImage: false,
  isLastImage: false,
};

ReorderActions.propTypes = {
  className: string,
  handleFirstImage: func.isRequired,
  handleHigher: func.isRequired,
  handleLower: func.isRequired,
  isFirstImage: bool,
  isLastImage: bool,
};

export default ReorderActions;