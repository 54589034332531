import React, { useState } from 'react';
import { bool, func, object } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import omit from 'lodash/omit';
import { map } from 'ramda';

import config from '../../../../config';
import routeConfiguration from '../../../../routeConfiguration';
import { propTypes } from '../../../../util/types';
import { createResourceLocatorString } from '../../../../util/routes';
import { isAnyFilterActive } from '../../../../util/search';
import { manageDisableScrolling } from '../../../../ducks/UI.duck';
import { FilterComponent, IconClose, PrimaryButton } from '../../../../components';
import {
  ModalSwimmy,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '../../../../components/ModalSwimmy';

import css from './SearchFilters.module.css';

const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

const SearchFilters = (props) => {
  const {
    filterConfig,
    intl,
    mapSearch,
    onClose,
    onManageDisableScrolling,
    show,
    sortConfig,
    urlQueryParams,
  } = props;
  const [currentQueryParams, setCurrentQueryParams] = useState(urlQueryParams);
  const history = useHistory();
  const initialValues = (queryParamNames) => {

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';

      return hasQueryParamInState ? currentQueryParam : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
          return { ...acc, [paramName]: getInitialValue(paramName) };
        }, {})
      : {};
  };

  // Apply the filters by redirecting to SearchPage with new filters.
  const applyFilters = () => {
    const { address, bounds, origin, city } = urlQueryParams;
    const searchParams = {
      ...urlQueryParams,
      ...currentQueryParams,
      mapSearch,
      address,
      bounds,
      origin,
      city,
    };
    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
    onClose();
  }

  const getHandleChangedValueFn = () => {
    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, origin, city } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        return {
          ...mergedQueryParams,
          ...updatedURLParams,
          address,
          bounds,
          origin,
          city,
        };
      };

      setCurrentQueryParams(updater);
    };
  };

  // Reset all filter query parameters
  const resetAll = (e) => {
    const filterQueryParamNames = filterConfig.map(f => f.queryParamNames);
    setCurrentQueryParams({});
    const queryParams = omit({ ...urlQueryParams, mapSearch }, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
    onClose();
  };
  const headingText = intl.formatMessage({ id: 'Search.SearchFilters.heading' });
  const resetButtonText = intl.formatMessage({ id: 'Search.SearchFilters.resetButton' });
  const showListingsText = intl.formatMessage({ id: 'Search.SearchFilters.showListings' });

  return (
    <ModalSwimmy
      id="searchFiltersModal"
      isOpen={show}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <ModalOverlay />
      <ModalContent className={css.modalContent}>
        <ModalHeader className={css.modalHeader}>
          <button className={css.backButton} onClick={onClose}>
            <IconClose />
          </button>
          <span className={css.heading}>{headingText}</span>
        </ModalHeader>
        <ModalBody className={css.modalBody}>
          {map(config => {
            return (
              <FilterComponent
                key={`SearchFilters.${config.id}`}
                idPrefix="SearchFilters"
                filterConfig={config}
                urlQueryParams={urlQueryParams}
                initialValues={initialValues}
                getHandleChangedValueFn={getHandleChangedValueFn}
              />
            );
          }, filterConfig)}
        </ModalBody>
        <ModalFooter className={css.modalFooter}>
          <button className={css.resetButton} onClick={resetAll}>
            <span className={css.resetButtonText}>{resetButtonText}</span>
          </button>
          <PrimaryButton className={css.showListingsButton} onClick={applyFilters}>
            {showListingsText}
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </ModalSwimmy>
  )
}

SearchFilters.defaultProps = {
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
};

SearchFilters.propTypes = {
  filterConfig: propTypes.filterConfig,
  intl: intlShape.isRequired,
  mapSearch: bool,
  onClose: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  show: bool.isRequired,
  sortConfig: propTypes.sortConfig,
  urlQueryParams: object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  injectIntl,
)(SearchFilters);