import React, { useState } from 'react';
import { bool, func, node, number, string } from 'prop-types';
import {
  DayPickerSingleDateController,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from 'react-dates';
import classNames from 'classnames';
import moment from 'moment';

import config from '../../../config';

import css from './DatePicker.module.css';

const HORIZONTAL_ORIENTATION = 'horizontal';

const propTypes = {
  keepOpenOnDateSelect: bool,
  isOutsideRange: func,
  isDayBlocked: func,
  isDayHighlighted: func,

  // DayPicker props
  enableOutsideDays: bool,
  numberOfMonths: number,
  withPortal: bool,
  initialVisibleMonth: func,
  renderCalendarInfo: func,

  navPrev: node,
  navNext: node,
  renderNavPrevButton: func,
  renderNavNextButton: func,

  onPrevMonthClick: func,
  onNextMonthClick: func,
  onOutsideClick: func,
  renderCalendarDay: func,
  renderDayContents: func,

  // i18n
  monthFormat: string,
  monthFormat: string,

  isRTL: bool,
};

const defaultProps = {
  // calendar presentation and interaction related props
  orientation: HORIZONTAL_ORIENTATION,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 1,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,
  hideKeyboardShortcutsPanel: true,

  // navigation related props
  navPrev: null,
  navNext: null,
  renderNavPrevButton: null,
  renderNavNextButton: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  onClose() {},
  transitionDuration: 200, // milliseconds between next month changes etc.

  // day presentation and interaction related props
  renderCalendarDay: undefined, // If undefined, renders react-dates/lib/components/CalendarDay
  // day presentation and interaction related props
  renderDayContents: day => {
    return <span className="renderedDay">{day.format('D')}</span>;
  },
  enableOutsideDays: false,
  isDayBlocked: () => false,

  // outside range -><- today ... today+available days -1 -><- outside range
  isOutsideRange: day => {
    const endOfRange = config.dayCountAvailableForBooking - 1;
    return (
      !isInclusivelyAfterDay(day, moment()) ||
      !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
    );
  },
  isDayHighlighted: () => {},

  // internationalization
  monthFormat: 'MMMM YYYY',
  weekDayFormat: 'ddd',
};

const DatePicker = (props) => {
  const {
    className,
    id,
    input,
    meta,
    monthlyTimeSlots,
    onChange,
    showErrorMessage,
    validate,
    ...rest
  } = props;
  const [focused, setFocused] = useState(true);
  const [date, setDate] = useState(meta.initial);
  const onDateChange = (newDate) => {
    if (moment(newDate).isSame(date)) return;

    input.onChange(newDate);
    setDate(newDate);
    onChange(newDate);
  };
  const onFocusChange = ({ focused }) => setFocused(focused);
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <DayPickerSingleDateController
        {...rest}
        date={date && moment(date)}
        focused={focused}
        onDateChange={onDateChange}
        onFocusChange={onFocusChange}
      />
    </div>
  );
}

DatePicker.propTypes = propTypes;
DatePicker.defaultProps = defaultProps;

export default DatePicker;