import React, { Fragment } from 'react';
import { func, object, string, bool } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import config from '../../config';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { IconSpinner } from '../../components';

import PricingForm from './PricingForm/PricingForm';
import css from './NewListingPricingPanel.module.css';

const { Money } = sdkTypes;

const NewListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    showListingInProgress,
  } = props;
  const currentListing = ensureOwnListing(listing);
  const { price } = currentListing.attributes;
  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
  const titleText = intl.formatMessage({ id: 'NewListingPricingPanel.title' });
  const informationText = intl.formatMessage({ id: 'NewListingPricingPanel.information' });
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {showListingInProgress ? (
        <IconSpinner />
      ) : (
        <Fragment>
          <h1 className={css.title}>{titleText}</h1>
          <span className={css.information}>{informationText}</span>
          {priceCurrencyValid ? (
            <PricingForm
              className={css.form}
              listing={currentListing}
              onSubmit={onSubmit}
              onChange={onChange}
              saveActionMsg={submitButtonText}
              disabled={disabled}
              ready={ready}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
            />
          ) : (
            <div className={css.priceCurrencyInvalid}>
              <FormattedMessage id="NewListingPricingPanel.listingPriceCurrencyInvalid" />
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

NewListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

NewListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,
  fetchInProgress: bool,
  newListingCreated: bool,
  intl: intlShape.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default injectIntl(NewListingPricingPanel);
