import React from 'react';

import {
  AccountPage,
  AccountPaymentsPage,
  AccountPersonalInfosPage,
  AccountPrivacyPage,
  AccountProfilePage,
  AccountSecurityPage,
  AuthenticationPage,
  BlogArticlePage,
  BlogPage,
  CalendarPage,
  CheckoutExtraPage,
  CheckoutPage,
  CongratPoolPage,
  CongratPurchaseBookingPage,
  CongratsEnquiryPage,
  ContactPage,
  EditListingPage,
  EmailVerificationPage,
  HowItWorksPage,
  InboxPage,
  IncomePage,
  LandingPage,
  ListingPage,
  ManageListingsPage,
  FavoritesPage,
  NotFoundPage,
  PasswordRecoveryPage,
  PasswordResetPage,
  PressPage,
  ProfilePage,
  ReservationsPage,
  SearchPage,
  SecurityRulesPage,
  SimulatorPage,
  StyleguidePage,
  TaxationPage,
  TransactionPage,
  WhoWeArePage,
} from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { ExternalRoute, NamedRedirect, SimulatorStandalone } from './components';
import config from './config';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';

const pageDataLoadingAPI = getPageDataLoadingAPI();

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const privacySlug = `roots?slug=privacy-${config.locale}`;

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: props => <LandingPage {...props} />,
    },
    {
      path: '/faq',
      name: 'FAQPage',
      component: () => <ExternalRoute url={config.custom.helpCenterUrl} />,
    },
    {
      path: '/press',
      name: 'PressPage',
      component: PressPage,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: props => <SearchPage {...props} />,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/s/filters',
      name: 'SearchFiltersPage',
      component: props => <SearchPage {...props} tab="filters" />,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/s/listings',
      name: 'SearchListingsPage',
      component: props => <SearchPage {...props} tab="listings" />,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/s/map',
      name: 'SearchMapPage',
      component: props => <SearchPage {...props} tab="map" />,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/congratulation-after-ad-creation',
      name: 'CongratPoolPage',
      component: props => <CongratPoolPage {...props} />,
    },
    {
      path: '/congratulation-after-booking',
      name: 'CongratPurchaseBookingPage',
      component: props => <CongratPurchaseBookingPage {...props} />,
      loadData: CongratPurchaseBookingPage.loadData,
    },
    {
      path: '/enquiry/:id/congrats',
      name: 'CongratsEnquiryPage',
      auth: true,
      component: props => <CongratsEnquiryPage {...props} />,
      loadData: pageDataLoadingAPI.CongratsEnquiryPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: props => <ListingPage {...props} />,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
      setInitialValues: pageDataLoadingAPI.ListingPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/checkout/extra',
      name: 'CheckoutExtraPage',
      auth: true,
      component: CheckoutExtraPage,
      setInitialValues: pageDataLoadingAPI.CheckoutExtraPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/contact',
      name: 'ContactPage',
      auth: true,
      component: ContactPage,
      loadData: pageDataLoadingAPI.ContactPage.loadData,
      setInitialValues: pageDataLoadingAPI.ContactPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ListingPage {...props} />,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: props => <ListingPage {...props} />,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: props => <ProfilePage {...props} />,
      loadData: ProfilePage.loadData,
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: props => <AuthenticationPage {...props} tab="login" />,
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: props => <AuthenticationPage {...props} tab="signup" />,
      loadData: () => AuthenticationPage.loadData(),
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: props => <PasswordRecoveryPage {...props} />,
    },
    {
      path: '/simulator',
      name: 'SimulatorPage',
      component: props => <SimulatorPage {...props} />,
    },
    {
      path: '/messages/:id?',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <InboxPage {...props} />,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="customer" />,
      loadData: pageDataLoadingAPI.TransactionPage.loadData,
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="provider" />,
      loadData: pageDataLoadingAPI.TransactionPage.loadData,
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageListingsPage {...props} />,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/favorites',
      name: 'FavoritesPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <FavoritesPage {...props} />,
      loadData: pageDataLoadingAPI.FavoritesPage.loadData,
    },
    {
      path: '/account-settings',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <AccountPage {...props} />,
      loadData: pageDataLoadingAPI.AccountPage.loadData,
    },
    {
      path: '/account-settings/security',
      name: 'AccountSecurityPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="AccountPasswordChangePage" />,
    },
    {
      path: '/account-settings/security/change-password',
      name: 'AccountPasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <AccountSecurityPage currentTab="PasswordChange" {...props} />,
    },
    {
      path: '/account-settings/profile',
      name: 'AccountProfilePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <AccountProfilePage currentTab="ProfileSettings" {...props} />,
    },
    {
      path: '/account-settings/personal-infos',
      name: 'AccountPersonalInfosPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <AccountPersonalInfosPage currentTab="PersonalInfos" {...props} />,
      loadData: pageDataLoadingAPI.AccountPersonalInfosPage.loadData,
    },
    {
      path: '/account-settings/payments',
      name: 'AccountPaymentsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="AccountPaymentMethodsPage" />,
    },
    {
      path: '/account-settings/payments/payment-methods',
      name: 'AccountPaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <AccountPaymentsPage currentTab="PaymentMethods" {...props} />,
      loadData: pageDataLoadingAPI.AccountPaymentMethodsPage.loadData,
    },
    {
      path: '/account-settings/payments/payout-details',
      name: 'AccountPayoutDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <AccountPaymentsPage currentTab="PayoutDetails" {...props} />,
      loadData: pageDataLoadingAPI.AccountPayoutDetailsPage.loadData,
    },
    {
      path: '/account-settings/privacy',
      name: 'AccountPrivacyPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <AccountPrivacyPage currentTab="Cookies" {...props} />,
    },
    {
      path: '/income',
      name: 'IncomePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <IncomePage {...props} />,
      loadData: IncomePage.loadData,
    },
    {
      path: '/security-rules',
      name: 'SecurityRulesPage',
      component: props => <SecurityRulesPage {...props} />,
    },
    {
      path: '/fiscalité',
      name: 'TaxationPage',
      component: props => <TaxationPage {...props} />,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: props => <StyleguidePage raw {...props} />,
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },
    {
      path: '/who-we-are',
      name: 'WhoWeArePage',
      component: WhoWeArePage,
    },
    {
      path: '/how-it-works',
      name: 'HowItWorksPage',
      component: HowItWorksPage,
    },

    // Blog paths
    {
      path: '/amateurs-de-baignade',
      name: 'SwimmingEnthusiastsPage',
      component: props => <BlogPage slug='amateursdebaignade' {...props} />,
      loadData: () => BlogPage.loadData('amateursdebaignades'),
    },
    {
      path: '/amateurs-de-baignade/:slug',
      name: 'SwimmingEnthusiastsArticlePage',
      component: props => <BlogArticlePage {...props} />,
      loadData: params => BlogArticlePage.loadData(params, 'amateursdebaignades'),
    },
    {
      path: '/entretien-piscine',
      name: 'CleaningPoolPage',
      component: props => <BlogPage slug='entretien-piscine' {...props} />,
      loadData: () => BlogPage.loadData('entretien-piscine'),
    },
    {
      path: '/entretien-piscine/:slug',
      name: 'CleaningPoolArticlePage',
      component: props => <BlogArticlePage {...props} />,
      loadData: (params) => BlogArticlePage.loadData(params, 'cleaningarticles'),
    },
    {
      path: '/locations',
      name: 'LocationsPage',
      component: props => <BlogPage slug='locations' {...props} />,
      loadData: () => BlogPage.loadData('locations'),
    },
    {
      path: '/locations/:slug',
      name: 'LocationsPage',
      component: props => <BlogArticlePage {...props} />,
      loadData: (params) => BlogArticlePage.loadData(params, 'locations'),
    },
    {
      path: '/insurance',
      name: 'InsurancePage',
      component: props => <BlogPage slug={config.custom.insuranceSlug} {...props} />,
      loadData: () => BlogPage.loadData(config.custom.insuranceSlug),
    },
    {
      path: '/legal-notice',
      name: 'LegalNoticePage',
      component: props => <BlogPage slug={config.custom.legalNoticeSlug} {...props} />,
      loadData: () => BlogPage.loadData(config.custom.legalNoticeSlug),
    },
    {
      path: '/terms-of-use',
      name: 'TermsOfServicePage',
      component: props => <BlogPage slug={config.custom.termsOfUseSlug} {...props} />,
      loadData: () => BlogPage.loadData(config.custom.termsOfUseSlug),
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: props => <BlogPage slug={privacySlug} {...props} />,
      loadData: () => BlogPage.loadData(privacySlug),
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: props => <PasswordResetPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <EmailVerificationPage {...props} />,
    },
    {
      path: '/reservations',
      name: 'ReservationsBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ReservationsPage" params={{ tab: 'pending' }} />,
    },
    {
      path: '/reservations/:tab',
      name: 'ReservationsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ReservationsPage {...props} />,
      loadData: ReservationsPage.loadData,
    },
    {
      path: '/calendar/:id',
      name: 'CalendarPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <CalendarPage {...props} />,
      loadData: pageDataLoadingAPI.CalendarPage.loadData,
    },
    {
      path: '/simulator/standalone',
      name: 'SimulatorStandalonePage',
      component: props => <SimulatorStandalone {...props} />,
    },
  ];
};

export default routeConfiguration;
