import React from 'react';
import { FormattedMessage, injectIntl} from 'react-intl';

import storyPoolImgDesktop from '../../assets/swimmy-story-pool.png';
import storyPoolImgMobile from '../../assets/swimmy-story-pool-mobile.png';
import storyAnneLaureImgDesktop from '../../assets/anne-laure-conversation.png';
import storyAnneLaureImgMobile from '../../assets/anne-laure-conversation-mobile.png';
import storyCreationMailImgDesktop from '../../assets/swimmy-creation.png';
import storyCreationMailImgMobile from '../../assets/swimmy-creation-mobile.png';
import buoyIcon from '../../assets/pictures/buoy.png';

import css from './WhoWeArePage.css'

const SectionSwimmyStory = (props) => {
	const { isMobile, intl } = props;
	const firstText = intl.formatMessage({ id : 'WhoWeArePage.storyFirstText' });
	const secondText = intl.formatMessage({ id: 'WhoWeArePage.storySecondText' });
	const thirdText = intl.formatMessage({ id: 'WhoWeArePage.storyThirdText' });
	const fourthText = intl.formatMessage({ id: 'WhoWeArePage.storyFourthText' });
	const goalsText = intl.formatMessage({ id: 'WhoWeArePage.storyGoals' });
	const firstGoalImportant = intl.formatMessage({ id: 'WhoWeArePage.storyFirstGoalImportant' });
	const secondGoalImportant = intl.formatMessage({ id: 'WhoWeArePage.storySecondGoalImportant' });
	const thirdGoalImportant = intl.formatMessage({ id: 'WhoWeArePage.storyThirdGoalImportant' });
	
	return(
		<section className={css.section}>
			<div className={css.storyContent}>
				<p className={css.textContainer}>{firstText}</p>
				<p className={css.textContainer}>{secondText}</p>
				<div className={css.imgContainer}>
					<img src={isMobile ? storyPoolImgMobile : storyPoolImgDesktop} className={css.image}/>
				</div>
				<p className={css.textContainer}>{thirdText}</p>
				<div className={css.imgContainer}>
					<img src={isMobile ? storyCreationMailImgMobile : storyCreationMailImgDesktop} className={css.image}/>
				</div>
				<p className={css.textContainer}>{fourthText}</p>
				<div className={css.imgContainer}>
					<img src={isMobile ? storyAnneLaureImgMobile : storyAnneLaureImgDesktop} className={css.image}/>
				</div>
				<div className={css.listContainer}>
					<span>{goalsText}</span>
					<ul className={css.list}>
						<li className={css.listItem}>
							<img className={css.listIcon} src={buoyIcon} />
							<FormattedMessage id='WhoWeArePage.storyFirstGoal' values={{ important: <b>{firstGoalImportant}</b> }} />
						</li>
						<li className={css.listItem}>
							<img className={css.listIcon} src={buoyIcon} />
							<FormattedMessage id='WhoWeArePage.storySecondGoal' values={{ important: <b>{secondGoalImportant}</b> }} />
						</li>
						<li className={css.listItem}>
							<img className={css.listIcon} src={buoyIcon} />
							<FormattedMessage id='WhoWeArePage.storyThirdGoal' values={{ important: <b>{thirdGoalImportant}</b> }} />
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
};

export default injectIntl(SectionSwimmyStory);