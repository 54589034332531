export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';

export const LINE_ITEM_UNITS_BASE = 'line-item/units-base';
export const LINE_ITEM_UNITS_HOURLY = 'line-item/units-hourly';
export const LINE_ITEM_UNITS_HOURLY_CHILDREN = 'line-item/units-hourly-children';

export const LINE_ITEM_UNITS_EXTRA_GUESTS = 'line-item/units-extra-guests';
export const LINE_ITEM_WEEK_REDUCTION = 'line-item/week-reduction';
export const LINE_ITEM_CUSTOM_EXTRA = 'line-item/custom-extra/';
export const LINE_ITEM_UNITS_BASE_HOURS_DISCOUNT = 'line-item/units-base-hours-discount';
export const LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT = 'line-item/customer-commission-discount';
export const LINE_ITEM_PROVIDER_COMMISSION_DISCOUNT = 'line-item/provider-commission-discount';
