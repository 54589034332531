import React, { Fragment } from 'react';
import { bool, func, number, object, shape, string } from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import { MAX_MOBILE_WIDTH } from '../../util/constants';
import { withViewport } from '../../util/contextHelpers';
import { parse } from '../../util/urlHelpers';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { ListingLink } from '../../components';

import {
  BASE_PRICING,
  INSTANT_BOOKING,
  OVERTIME_DISCOUNT,
  PAID_EXTRAS,
  WEEKDAY_DISCOUNT,
} from './EditListingPricingPanel.Helpers';
import BasePricingView from './BasePricingView/BasePricingView';
import HoursDiscountView from './HoursDiscountView/HoursDiscountView';
import PaidExtrasView from './PaidExtrasView/PaidExtrasView';
import TabNavigation from './TabNavigation/TabNavigation';
import WeekdayDiscountView from './WeekdayDiscountView/WeekdayDiscountView';
import css from './EditListingPricingPanel.module.css';
import InstantBookingView from './InstantBookingView/InstantBookingView';

const EditListingPricingPanel = (props) => {
  const {
    className,
    rootClassName,
    intl,
    onManageDisableScrolling,
    onSubmit,
    listing,
    disabled,
    ready,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    showListingInProgress,
    viewport,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const parsedSearch = parse(search);
  const handleBack = () => history.goBack();
  const isMobile = viewport && viewport.width > 0 && viewport.width <= MAX_MOBILE_WIDTH;
  const currentModal = parsedSearch.show;
  const titleText = intl.formatMessage({ id: 'EditListingPricingPanel.title' });
  const listingText = intl.formatMessage({ id: 'EditListingPricingPanel.listing' });
  const desktopInfoText = intl.formatMessage({ id: 'EditListingPricingPanel.desktopInformation' });
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {!currentModal && (
        <Fragment>
          <span className={css.panelTitle}>{titleText}</span>
          <div className={css.listingLinkWrapper}>
            <span className={css.listing}>{listingText}</span>
            <ListingLink className={css.listingLink} listing={listing} />
          </div>
          {!isMobile && <span className={css.desktopInformation}>{desktopInfoText}</span>}
          <TabNavigation className={css.tabNavigation} isMobile={isMobile} listing={listing} />
        </Fragment>
      )}
      <BasePricingView
        intl={intl}
        isMobile={isMobile}
        isOpen={currentModal === BASE_PRICING}
        listing={listing}
        onCloseModal={handleBack}
        onManageDisableScrolling={onManageDisableScrolling}
        onSubmit={onSubmit}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
      />
      <WeekdayDiscountView
        intl={intl}
        isMobile={isMobile}
        isOpen={currentModal === WEEKDAY_DISCOUNT}
        listing={listing}
        onCloseModal={handleBack}
        onManageDisableScrolling={onManageDisableScrolling}
        onSubmit={onSubmit}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
      />
      <HoursDiscountView
        intl={intl}
        isMobile={isMobile}
        isOpen={currentModal === OVERTIME_DISCOUNT}
        listing={listing}
        onCloseModal={handleBack}
        onManageDisableScrolling={onManageDisableScrolling}
        onSubmit={onSubmit}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
      />
      <InstantBookingView
        intl={intl}
        isMobile={isMobile}
        isOpen={currentModal === INSTANT_BOOKING}
        listing={listing}
        onCloseModal={handleBack}
        onManageDisableScrolling={onManageDisableScrolling}
        onSubmit={onSubmit}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
      />
      <PaidExtrasView
        intl={intl}
        isMobile={isMobile}
        isOpen={currentModal === PAID_EXTRAS}
        listing={listing}
        onCloseModal={handleBack}
        onManageDisableScrolling={onManageDisableScrolling}
        onSubmit={onSubmit}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
      />
    </div>
  )
}

EditListingPricingPanel.defaultProps = {
  isMobile: true,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,
  disabled: bool.isRequired,
  errors: object.isRequired,
  fetchInProgress: bool,
  intl: intlShape.isRequired,
  isMobile: bool,
  listing: object,
  newListingCreated: bool,
  onManageDisableScrolling: func.isRequired,
  onSubmit: func.isRequired,
  panelUpdated: bool.isRequired,
  ready: bool.isRequired,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(
  withViewport,
  injectIntl,
  connect(
    null,
    mapDispatchToProps
  )
)(EditListingPricingPanel);