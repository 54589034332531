import React, { useRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactPlayer from 'react-player/youtube';

import config from '../../config';
import { IconArrow, NamedLink, SwimmySlider } from '../../components';

import { linksProps } from './reviewsConfig';
import css from './styles.css';

const ProviderReviewCard = props => {
  const { review, sliderRef } = props;
  const { id, image, reviewContent, provider, videoLink, thumbnail } = review;
  const { avatar, displayName } = provider;
  const locale = config.locale;
  const showVideo = locale === 'fr' || locale === 'fr-BE';
  const reviewContentFormatted = "“" + reviewContent + "“";

  return (
    <div className={css.reviewCardContainer}>
      {showVideo ? (
        <div className={css.reviewCardVideoContainer}>
          <ReactPlayer
            className={css.reviewCardVideo}
            url={videoLink}
            light={thumbnail}
            height='100%'
            width='100%'
            controls
            config={{
              youtube: {
                playerVars: {
                  autoplay: 1,
                  modestbranding: 1,
                  rel: 0
                }
              }
            }}
          />
        </div>
      ) : (
        <img className={css.reviewCardImg} src={image} alt='pool' />
      )}
      <div className={css.reviewCardInfoContainer}>
        <div className={css.arrowContainerMobile}>
          <div className={css.prevArrow} onClick={() => sliderRef.current.slickPrev()}>
            <IconArrow className={css.sliderArrows} direction='left' />
          </div>
          <div className={css.nextArrow} onClick={() => sliderRef.current.slickNext()}>
            <IconArrow className={css.sliderArrows} direction='right' />
          </div>
        </div>
        {!showVideo && <img className={css.reviewCardAvatar} src={avatar} alt={displayName} />}
        <span className={css.reviewCardReview}>{reviewContentFormatted}</span>
        <span className={css.reviewCardAuthor}>{displayName}</span>
        <NamedLink className={css.reviewCardLink} name="ListingPage" params={{ id, slug: 'featured-listing' }} >
          <FormattedMessage id='SimulatorPage.reviewsCardLink' />
        </NamedLink>
      </div>
    </div>
  )
}

const SectionReviews = props => {
  const { intl } = props;
  const sliderRef = useRef();

  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
  };

  const titleHighlight = (
    <span className={css.titleHightlight}>
      <br/>{intl.formatMessage({ id: 'SimulatorPage.reviewsTitleHighlight' })}
    </span>)
  const title = (<FormattedMessage id='SimulatorPage.reviewsTitle' values={{ highlight: titleHighlight }} />);

  return (
    <div className={css.sectionReviews}>
      <span className={css.sectionTitle}>{title}</span>
      <div className={css.reviewsSlider}>
        <div className={css.arrowContainer}>
          <div className={css.prevArrow} onClick={() => sliderRef.current.slickPrev()}>
            <IconArrow className={css.sliderArrows} direction='left' />
          </div>
          <div className={css.nextArrow} onClick={() => sliderRef.current.slickNext()}>
            <IconArrow className={css.sliderArrows} direction='right' />
          </div>
        </div>
        <SwimmySlider settings={sliderSettings} sliderRef={sliderRef}>
          <ProviderReviewCard review={linksProps.review1} sliderRef={sliderRef} />
          <ProviderReviewCard review={linksProps.review2} sliderRef={sliderRef} />
          <ProviderReviewCard review={linksProps.review3} sliderRef={sliderRef} />
        </SwimmySlider>
      </div>
    </div>
  )
}

export default injectIntl(SectionReviews);