import React from 'react';
import { func } from 'prop-types';
import { intlShape } from 'react-intl';

import { propTypes } from '../../../../util/types';
import { ensureTransaction } from '../../../../util/data';
import { formatDate } from '../../../../util/dates';
import {
  getUserTxRole,
  isCustomerReview,
  isProviderReview,
  transitionIsReviewed,
  txRoleIsProvider,
} from '../../../../util/transactions';
import { IconInfo, UserDisplayName } from '../../../../components';

import { resolveTransitionMessage, reviewByAuthorId } from '../../ActivityFeed.helpers';
import Review from '../Review/Review';
import css from './Transition.module.css';

const Transition = props => {
  const { transition, transaction, currentUser, intl, onOpenReviewModal } = props;
  const currentTransaction = ensureTransaction(transaction);
  const customer = currentTransaction.customer;
  const provider = currentTransaction.provider;
  const deletedListing = intl.formatMessage({
    id: 'ActivityFeed.deletedListing',
  });
  const listingTitle = currentTransaction.listing.attributes.deleted
    ? deletedListing
    : currentTransaction.listing.attributes.title;
  const lastTransition = currentTransaction.attributes.lastTransition;
  const ownRole = getUserTxRole(currentUser.id, currentTransaction);
  const otherUsersName = txRoleIsProvider(ownRole) ? (
    <UserDisplayName user={customer} intl={intl} />
  ) : (
    <UserDisplayName user={provider} intl={intl} />
  );
  const transitionMessage = resolveTransitionMessage(
    transaction,
    transition,
    listingTitle,
    ownRole,
    otherUsersName,
    intl,
    onOpenReviewModal
  );
  const currentTransition = transition.transition;
  const deletedReviewContent = intl.formatMessage({ id: 'ActivityFeed.deletedReviewContent' });
  let reviewComponent = null;
  if (transitionIsReviewed(lastTransition)) {
    if (isCustomerReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, customer.id);
      reviewComponent = review ? (
        <Review content={review.attributes.content} rating={review.attributes.rating} />
      ) : (
        <Review content={deletedReviewContent} />
      );
    } else if (isProviderReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, provider.id);
      reviewComponent = review ? (
        <Review content={review.attributes.content} rating={review.attributes.rating} />
      ) : (
        <Review content={deletedReviewContent} />
      );
    }
  }
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  return (
    <div className={css.transition}>
      <IconInfo className={css.transitionImg} />
      <div>
        <p className={css.transitionContent}>{transitionMessage}</p>
        <span className={css.transitionDate}>{formatDate(intl, todayString, transition.createdAt)}</span>
        {reviewComponent}
      </div>
    </div>
  );
};

Transition.propTypes = {
  transition: propTypes.transition.isRequired,
  transaction: propTypes.transaction.isRequired,
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
  onOpenReviewModal: func.isRequired,
};

export const EmptyTransition = () => {
  return (
    <div className={css.transition}>
      <IconInfo className={css.transitionImg} />
      <div>
        <p className={css.transitionContent} />
        <span className={css.transitionDate} />
      </div>
    </div>
  );
};

export default Transition;
