import React, { useEffect, useState } from 'react';
import { bool, number, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';

import { IconAdd, IconRemove } from '../../components/Icons';
import { ValidationError } from '../../components';

import {
  getIncreasedValue,
  getReducedValue,
  getUpdatedValueWithoutUnit,
  getUpdatedValueWithUnit,
  getValueAsNumber,
} from './FieldStepperEditable.Helpers';
import css from './FieldStepperEditable.module.css';

const FieldStepperEditable = (props) => {
  const { initialValue, unit } = props;
  const initialvalueAsString = `${initialValue}`;
  const [currentValue, setCurrentValue] = useState(initialvalueAsString);
  useEffect(() => setCurrentValue(initialvalueAsString), [initialValue]);

  return (
    <Field
      {...props}
      initialValue={initialvalueAsString}
      render={fieldRenderProps => {
        const {
          className,
          inputClassName,
          input,
          label,
          max,
          meta,
          min,
          placeholder,
          step,
          sublabel,
          withButtons,
        } = fieldRenderProps;
        const valueAsNumber = input.value ? getValueAsNumber(input.value, unit) : 0;
        const reduceDisabled = valueAsNumber <= min;
        const increaseDisabled = max && valueAsNumber >= max;
        const handleReduce = (e) => {
          if (reduceDisabled) return;

          const reducedValue = getReducedValue(valueAsNumber, min, step, unit);
          setCurrentValue(reducedValue);
          input.onChange(reducedValue);
        };
        const handleIncrease = (e) => {
          if (increaseDisabled) return;

          const increasedValue = getIncreasedValue(valueAsNumber, min, max, step, unit);
          setCurrentValue(increasedValue);
          input.onChange(increasedValue);
        };
        const { onChange, ...restInputProps } = input; 
        const handleChange = (e) => {
          const nextValue = e.target.value;
          const updatedValue = unit ? getUpdatedValueWithUnit(currentValue, nextValue, unit, min) : getUpdatedValueWithoutUnit(currentValue, nextValue, min);
          setCurrentValue(updatedValue);
          input.onChange(updatedValue);
        };
        const { error, invalid, touched } = meta;
        const hasError = !!(touched && invalid && error);
        const classes = classNames(css.root, className);
        const reduceButtonClasses = classNames(css.button, { [css.buttonDisabled]: reduceDisabled });
        const inputWrapperClasses = classNames(css.inputWrapper, { [css.inputWrapperWithButtons]: withButtons }, inputClassName);
        const inputClasses = classNames({ [css.inputError]: hasError });
        const increaseButtonClasses = classNames(css.button, { [css.buttonDisabled]: increaseDisabled });
        const sublabelClasses = classNames(css.sublabel, { [css.sublabelWithButtons]: withButtons });

        return (
          <div className={classes}>
            {!withButtons && <label className={css.label} htmlFor={input.name}>{label}</label>}
            <div className={css.inputContainer}>
              {withButtons && (
                <button
                  className={reduceButtonClasses}
                  disabled={reduceDisabled}
                  onClick={handleReduce}
                  type="button"
                >
                  <IconRemove />
                </button>
              )}
              <div className={inputWrapperClasses}>
                <input
                  className={inputClasses}
                  autoComplete="off"
                  id={input.name}
                  inputMode="numeric"
                  onChange={handleChange}
                  placeholder={placeholder}
                  type="text"
                  {...restInputProps}
                  />
              </div>
              {withButtons && (
                <button
                  className={increaseButtonClasses}
                  disabled={increaseDisabled}
                  onClick={handleIncrease}
                  type="button"
                >
                  <IconAdd />
                </button>
              )}
            </div>
            {withButtons && <label className={css.labelWithButtons} htmlFor={input.name}>{label}</label>}
            {sublabel && <span className={sublabelClasses}>{sublabel}</span>}
            <ValidationError className={css.validationError} fieldMeta={meta} />
          </div>
        )
      }}
    />
  )
}

FieldStepperEditable.defaultProps = {
  className: null,
  inputClassName: null,
  max: null,
  min: 0,
  placeholder: '0',
  step: 1,
  sublabel: null,
  unit: null,
  withButtons: false,
};

FieldStepperEditable.propTypes = {
  className: string,
  inputClassName: string,
  label: string.isRequired,
  max: number,
  min: number,
  placeholder: string,
  step: number,
  sublabel: string,
  unit: string,
  withButtons: bool,
};

export default FieldStepperEditable;
