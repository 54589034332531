import * as custom from './marketplace-custom-config.js';
import defaultLocationSearches from './default-location-searches';

const env = process.env.REACT_APP_ENV;
const dev = process.env.REACT_APP_ENV === 'development';

// If you want to change the language, remember to also change the
// locale data and the messages in the app.js file.
const locale = 'de';
const i18n = {
  /*
    0: Sunday
    1: Monday
    ...
    6: Saturday
  */
  firstDayOfWeek: 1,
};

// Should search results be ordered by distance to origin.
const sortSearchByDistance = true;

// API supports custom processes to be used in booking process.
// We need to specify it when we are initiating a new order
// (or fetching price information by calling 'initiateSpeculative' endpoint).
//
// In a way, 'processAlias' defines which transaction process (or processes)
// this particular web application is able to handle.
const bookingProcessAlias = 'time-bookings-with-commission-from-both/with-hourly-booking';

// The transaction line item code for the main unit type in bookings.
//
// Possible values: ['line-item/night', 'line-item/day', 'line-item/units';]
//
// Note: if you change this, many of the generic translations will
// still show information about nights. Make sure to go through the
// translations when the unit is changed.
const bookingUnitType = 'line-item/units';

// Should the application fetch available time slots (currently defined as
// start and end dates) to be shown on listing page.
const fetchAvailableTimeSlots = true;

// A maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged. Also note that available time slots can only
// be fetched for 180 days in the future.
const dayCountAvailableForBooking = 90;

// To pass environment variables to the client app in the build
// script, react-scripts (and the sharetribe-scripts fork of
// react-scripts) require using the REACT_APP_ prefix to avoid
// exposing server secrets to the client side.
const sdkClientId = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;
const sdkBaseUrl = process.env.REACT_APP_SHARETRIBE_SDK_BASE_URL;
const sdkTransitVerbose = process.env.REACT_APP_SHARETRIBE_SDK_TRANSIT_VERBOSE === 'true';

const currency = process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY;

// Listing minimum price in currency sub units, e.g. cents.
// 0 means no restriction to the price
const listingMinimumPriceSubUnits = 200;

// Sentry DSN (Data Source Name), a client key for authenticating calls to Sentry
const sentryDsn = process.env.REACT_APP_PUBLIC_SENTRY_DSN;

// If webapp is using SSL (i.e. it's behind 'https' protocol)
const usingSSL = process.env.REACT_APP_SHARETRIBE_USING_SSL === 'true';

// Currency formatting options.
// See: https://github.com/yahoo/react-intl/wiki/API#formatnumber
//
// TODO: Remove this and hide formating within the util/currency module
const currencyConfig = {
  style: 'currency',
  currency,
  currencyDisplay: 'symbol',
  useGrouping: true,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const morningDisplaySlot = {
  start: { hour: 9, minute: 0 },
  end: { hour: 13, minute: 0 },
};
const afternoonDisplaySlot = {
  start: { hour: 14, minute: 0 },
  end: { hour: 18, minute: 0 },
};
const eveningDisplaySlot = {
  start: { hour: 20, minute: 0 },
  end: { hour: 24, minute: 0 },
};
export const defaultDisplaySlots = {
  morningSlot: morningDisplaySlot,
  afternoonSlot: afternoonDisplaySlot,
  eveningSlot: eveningDisplaySlot,
}

const morningBookingSlot = {
  start: { hour: 9, minute: 0 },
  end: { hour: 11, minute: 0 },
};
const afternoonBookingSlot = {
  start: { hour: 14, minute: 0 },
  end: { hour: 18, minute: 0 },
};
const eveningBookingSlot = {
  start: { hour: 20, minute: 0 },
  end: { hour: 24, minute: 0 },
};
export const defaultBookingSlots = {
  morningSlot: morningBookingSlot,
  afternoonSlot: afternoonBookingSlot,
  eveningSlot: eveningBookingSlot,
}

// Stripe only supports payments in certain countries, see full list
// at https://stripe.com/global
//
// We currently only support EU countries, US, and AU.
const stripeSupportedCountries = [
  {
    // Australia
    code: 'AU',
    currency: 'AUD',
    payoutAddressRequired: false,
    accountConfig: {
      bsb: true,
      accountNumber: true,
    },
  },
  {
    // Austria
    code: 'AT',
    currency: 'EUR',
    payoutAddressRequired: true,
    accountConfig: {
      iban: true,
    },
  },
  {
    // Belgium
    code: 'BE',
    currency: 'EUR',
    payoutAddressRequired: true,
    accountConfig: {
      iban: true,
    },
  },
  {
    // Denmark
    code: 'DK',
    currency: 'DKK',
    payoutAddressRequired: true,
    accountConfig: {
      iban: true,
    },
  },
  {
    // Finland
    code: 'FI',
    currency: 'EUR',
    payoutAddressRequired: true,
    accountConfig: {
      iban: true,
    },
  },
  {
    // France
    code: 'FR',
    currency: 'EUR',
    payoutAddressRequired: true,
    accountConfig: {
      iban: true,
    },
  },
  {
    // Germany
    code: 'DE',
    currency: 'EUR',
    payoutAddressRequired: true,
    accountConfig: {
      iban: true,
    },
  },
  {
    // Ireland
    code: 'IE',
    currency: 'EUR',
    payoutAddressRequired: true,
    accountConfig: {
      iban: true,
    },
  },
  {
    // Italy
    code: 'IT',
    currency: 'EUR',
    payoutAddressRequired: true,
    accountConfig: {
      iban: true,
    },
  },
  {
    // Luxembourg
    code: 'LU',
    currency: 'EUR',
    payoutAddressRequired: true,
    accountConfig: {
      iban: true,
    },
  },
  {
    // Netherlands
    code: 'NL',
    currency: 'EUR',
    payoutAddressRequired: true,
    accountConfig: {
      iban: true,
    },
  },
  {
    // Portugal
    code: 'PT',
    currency: 'EUR',
    payoutAddressRequired: true,
    accountConfig: {
      iban: true,
    },
  },
  {
    // Spain
    code: 'ES',
    currency: 'EUR',
    payoutAddressRequired: true,
    accountConfig: {
      iban: true,
    },
  },
  {
    // Sweden
    code: 'SE',
    currency: 'SEK',
    payoutAddressRequired: true,
    accountConfig: {
      iban: true,
    },
  },
  {
    // United Kingdom
    code: 'GB',
    currency: 'GBP',
    payoutAddressRequired: true,
    accountConfig: {
      sortCode: true,
      accountNumber: true,
    },
  },
  {
    // United States
    code: 'US',
    currency: 'USD',
    payoutAddressRequired: false,
    accountConfig: {
      routingNumber: true,
      accountNumber: true,
    },
  },
];

// Address information is used in SEO schema for Organization (http://schema.org/PostalAddress)
const addressCountry = 'FR';
const addressRegion = 'Paris';
const postalCode = '75014';
const streetAddress = '15 rue Jacquier';

// Canonical root url is needed in social media sharing and SEO optimization purposes.
const canonicalRootURL = process.env.REACT_APP_CANONICAL_ROOT_URL;

// Site title is needed in meta tags (bots and social media sharing reads those)
const siteTitle = 'Swimmy';

// Twitter handle is needed in meta tags (twitter:site). Start it with '@' character
const siteTwitterHandle = null;

// Instagram page is used in SEO schema (http://schema.org/Organization)
const siteInstagramPage = 'https://www.instagram.com/swimmy_de/';

// Facebook page is used in SEO schema (http://schema.org/Organization)
const siteFacebookPage = 'https://www.facebook.com/Swimmy-Mieten-Sie-ein-privates-Schwimmbad-102088912020611';

// Facebook counts shares with app or page associated by this id
// Currently it is unset, but you can read more about fb:app_id from
// https://developers.facebook.com/docs/sharing/webmasters#basic
// You should create one to track social sharing in Facebook
const facebookAppId = '1558615417781182';

const maps = {
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  googleMapsAPIKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,

  // The location search input can be configured to show default
  // searches when the user focuses on the input and hasn't yet typed
  // anything. This reduces typing and avoids too many Geolocation API
  // calls for common searches.
  search: {
    // When enabled, the first suggestion is "Current location" that
    // uses the browser Geolocation API to query the user's current
    // location.
    suggestCurrentLocation: process.env.REACT_APP_DEFAULT_SEARCHES_ENABLED === 'true',

    // Distance in meters for calculating the bounding box around the
    // current location.
    currentLocationBoundsDistance: 60000,

    // Example location can be edited in the
    // `default-location-searches.js` file.
    defaults: defaultLocationSearches,

    // Limit location autocomplete to a one or more countries
    // using ISO 3166 alpha 2 country codes separated by commas.
    // If you want to limit the autocomplete, uncomment this value:
    // countryLimit: ['FR', 'BE', 'LU', 'ES', 'DE', 'IT'],
  },

  // When fuzzy locations are enabled, coordinates on maps are
  // obfuscated randomly around the actual location.
  //
  // NOTE: This only hides the locations in the UI level, the actual
  // coordinates are still accessible in the HTTP requests and the
  // Redux store.
  fuzzy: {
    enabled: true,

    // Amount of maximum offset in meters that is applied to obfuscate
    // the original coordinates. The actual value is random, but the
    // obfuscated coordinates are withing a circle that has the same
    // radius as the offset.
    offset: 500,

    // Default zoom level when showing a single circle on a Map. Should
    // be small enough so the whole circle fits in.
    defaultZoomLevel: 13,

    // Color of the circle on the Map component.
    circleColor: '#07a0f2',
  },

  // Custom marker image to use in the Map component.
  //
  // NOTE: Not used if fuzzy locations are enabled.
  customMarker: {
    enabled: false,

    // Publicly accessible URL for the custom marker image.
    //
    // The easiest place is /public/static/icons/ folder, but then the
    // marker image is not available while developing through
    // localhost.
    url: encodeURI(`${canonicalRootURL}/static/icons/map-marker-32x32.png`),

    // Dimensions of the marker image.
    width: 32,
    height: 32,

    // Position to anchor the image in relation to the coordinates,
    // ignored when using Mapbox.
    anchorX: 16,
    anchorY: 32,
  },
};

const appsScriptBaseUrlToGetFAQ = process.env.REACT_APP_APPS_SCRIPT_BASE_URL_TO_GET_FAQ;
const appsScriptUrlToGetReviews = process.env.REACT_APP_APPS_SCRIPT_URL_TO_GET_REVIEWS;
const smsServiceUri = process.env.REACT_APP_APPS_SMS_SERVICE_URI;
const transactionTrackerServiceUri = process.env.REACT_APP_APPS_TRANSACTION_TRACKER_SERVICE_URI;
const criteoPartnerId = process.env.REACT_APP_APPS_CRITEO_PARTNER_ID;
const internalBlogUri = process.env.REACT_APP_INTERNAL_BLOG;
const eventServiceUri = process.env.REACT_APP_EVENT_SERVICE_URI;
const bookingsServiceUri = process.env.REACT_APP_BOOKINGS_SERVICE_URI;

const features = {
  isOwnerCalendarRepeatFeatureEnabled: false,
};

// NOTE: only expose configuration that should be visible in the
// client side, don't add any server secrets in this file.
const config = {
  env,
  dev,
  locale,
  internalBlogUri,
  bookingProcessAlias,
  bookingUnitType,
  fetchAvailableTimeSlots,
  dayCountAvailableForBooking,
  i18n,
  sdk: {
    clientId: sdkClientId,
    baseUrl: sdkBaseUrl,
    transitVerbose: sdkTransitVerbose,
  },
  sortSearchByDistance,
  currency,
  listingMinimumPriceSubUnits,
  currencyConfig,
  stripe: { publishableKey: stripePublishableKey, supportedCountries: stripeSupportedCountries },
  canonicalRootURL,
  address: {
    addressCountry,
    addressRegion,
    postalCode,
    streetAddress,
  },
  siteTitle,
  siteFacebookPage,
  siteInstagramPage,
  siteTwitterHandle,
  smsServiceUri,
  eventServiceUri,
  bookingsServiceUri,
  transactionTrackerServiceUri,
  facebookAppId,
  sentryDsn,
  usingSSL,
  custom,
  defaultDisplaySlots,
  defaultBookingSlots,
  appsScriptBaseUrlToGetFAQ,
  appsScriptUrlToGetReviews,
  features,
  criteoPartnerId,
  maps,
};

export default config;
