import React from 'react';
import { useHistory } from 'react-router-dom';
import { string } from 'prop-types';
import classNames from 'classnames';

import { IconArrowNavigation } from '../../components/Icons';

import css from './HeadingWithBackButton.module.css';

const HeadingWithBackButton = (props) => {
  const {
    className,
    headingClassName,
    heading,
  } = props;
  const history = useHistory();
  const classes = classNames(css.root, className);
  const headingClasses = classNames(css.heading, headingClassName);

  return (
    <div className={classes}>
      <button className={css.backButton} onClick={history.goBack}>
        <IconArrowNavigation className={css.iconArrowNavigation} direction='left' />
      </button>
      <h1 className={headingClasses}>{heading}</h1>
    </div>
  )
}

HeadingWithBackButton.defaultProps = {
  className: null,
  headingClassName: null,
};

HeadingWithBackButton.propTypes = {
  className: string,
  headingClassName: string,
  heading: string.isRequired,
};

export default HeadingWithBackButton;