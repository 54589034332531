import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { number, shape } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import { slickNext, slickPrev} from 'react-slick';

import { StaticPage, TopbarContainer } from '..';
import { withViewport } from '../../util/contextHelpers';
import {
	LayoutSingleColumn,
	LayoutWrapperTopbar,
	LayoutWrapperMain,
	LayoutWrapperFooter,
	Footer,
	IconArrow,
	SwimmySlider,
} from '../../components';

import SectionSwimmyStory from './SectionSwimmyStory';
import SectionSponsors from './SectionSponsor';
import SectionWhySwimmy from './SectionWhySwimmy';
import SectionVideoPresentation from './SectionVideoPresentation';
import SectionTeamSwimmy from './SectionTeamSwimmy';
import SectionAssociations from './SectionAssociations';
import SectionCTA from './SectionCTA';
import css from './WhoWeArePage.css';

const MAX_MOBILE_WIDTH = 767;
const MAX_TABLET_WIDTH = 1279;

const WhoWeArePage = (props) => {
	const { intl, viewport } = props;
	const [sliderNav, setSliderNav] = useState();
	const [sliderContent, setSliderContent] = useState();
	const [activeSlideNav, setActiveSlideNav] = useState(0);
  const navRef = useRef();
  const contentRef = useRef();
	useEffect(() => {
		setSliderNav(navRef.current);
		setSliderContent(contentRef.current);
	}, [navRef, contentRef]);
	const { width } = viewport;
	const hasViewport = width > 0;
	const isMobile = hasViewport && width <= MAX_MOBILE_WIDTH;
	const isTablet = hasViewport && width > MAX_MOBILE_WIDTH && width <= MAX_TABLET_WIDTH;
	const title = intl.formatMessage({ id: 'WhoWeArePage.mainTitle' });
	const centerPadding = isMobile ? "0"
		: isTablet ? "25%"
		: "30%";
	const navSettings = {
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: false,
		arrows: false,
		centerMode: true,
		centerPadding,
		focusOnSelect: true,
		beforeChange: (current, next) => setActiveSlideNav(next),
	};
	const contentSettings = {
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: false,
		speed: 1000,
		arrows: false,
	};
	const sectionStoryTitle = intl.formatMessage({ id: 'WhoWeArePage.storyTitle' });
	const sectionSponsorTitle = intl.formatMessage({ id: 'WhoWeArePage.sponsorTitle' });
	const sectionWhyTitle = intl.formatMessage({ id: 'WhoWeArePage.whyTitle' });
	const sectionVideoTitle = intl.formatMessage({ id: 'WhoWeArePage.videoSectionTitle' });
	const sectionTeamTitle = intl.formatMessage({ id: 'WhoWeArePage.teamTitle' });
	const sectionAssociationTitle = intl.formatMessage({ id: 'WhoWeArePage.associationTitle' });
	const sectionCTATitle = intl.formatMessage({ id: 'WhoWeArePage.ctaTitle' });
	const getNavTitleClasses = (slideIndex) => {
		if (activeSlideNav !== slideIndex) {
			switch (true) {
				case ([0, 3, 6].includes(slideIndex)):
					return css.navTitle1;
				case ([1, 4, 7].includes(slideIndex)):
					return css.navTitle2;
				default:
					return css.navTitle3;
			}
		} else {
			switch (true) {
				case ([0, 3, 6].includes(slideIndex)):
					return classNames(css.navTitle1, css.navActiveTitle);
				case ([1, 4, 7].includes(slideIndex)):
					return classNames(css.navTitle2, css.navActiveTitle);
				default:
					return classNames(css.navTitle3, css.navActiveTitle);
			}
		};
	};

	return (
		<StaticPage
			className={css.root}
			title={title}
		>
			<LayoutSingleColumn>
				<LayoutWrapperTopbar>
					<TopbarContainer currentPage='WhoWeArePage' />
				</LayoutWrapperTopbar>
				<LayoutWrapperMain className={css.layoutWrapperMain}>
					<div className={css.mainTitleContainer}>
						<h1 className={css.mainTitle}>{title}</h1>
					</div>
					<div className={css.navSliderContainer}>
						<SwimmySlider
							settings={navSettings}
							asNavFor={sliderContent}
							sliderRef={navRef}
						>
							<div className={css.navTitleContainer}>
								<span className={getNavTitleClasses(0)}>{sectionStoryTitle}</span>
							</div>
							<div className={css.navTitleContainer}>
								<span className={getNavTitleClasses(1)}>{sectionSponsorTitle}</span>
							</div>
							<div className={css.navTitleContainer}>
								<span className={getNavTitleClasses(2)}>{sectionWhyTitle}</span>
							</div>
							<div className={css.navTitleContainer}>
								<span className={getNavTitleClasses(3)}>{sectionTeamTitle}</span>
							</div>
							<div className={css.navTitleContainer}>
								<span className={getNavTitleClasses(4)}>{sectionVideoTitle}</span>
							</div>
							<div className={css.navTitleContainer}>
								<span className={getNavTitleClasses(5)}>{sectionAssociationTitle}</span>
							</div>
							<div className={css.navTitleContainer}>
								<span className={getNavTitleClasses(6)}>{sectionCTATitle}</span>
							</div>
						</SwimmySlider>
					</div>
					<div className={css.sliderContainer}>
						<div className={css.arrowContainer}>
							{activeSlideNav !== 0 ? (
								<div className={css.prevArrow} onClick={() => contentRef.current.slickPrev()}>
									<IconArrow className={css.sliderArrows} direction='left' />
								</div>
							) : null }
							{activeSlideNav !== 6 ? (
								<div className={css.nextArrow} onClick={() => contentRef.current.slickNext()}>
									<IconArrow className={css.sliderArrows} direction='right' />
								</div>
							) : null }
						</div>
						<SwimmySlider
							settings={contentSettings}
							asNavFor={sliderNav}
							sliderRef={contentRef}
							nextArrowClassName={css.nextArrow}
							prevArrowClassName={css.prevArrow}
						>
							<SectionSwimmyStory isMobile={isMobile} />
							<SectionSponsors />
							<SectionWhySwimmy isMobile={isMobile} />
							<SectionTeamSwimmy />
							<SectionVideoPresentation />
							<SectionAssociations/>
							<SectionCTA/>
						</SwimmySlider>
					</div>
				</LayoutWrapperMain>
				<LayoutWrapperFooter>
					<Footer />
				</LayoutWrapperFooter>
			</LayoutSingleColumn>
		</StaticPage>
	);
};
	
WhoWeArePage.propTypes = {
	viewport: shape({
		width: number.isRequired,
		height: number.isRequired,
	}).isRequired,
};

export default compose(injectIntl, withViewport)(WhoWeArePage);