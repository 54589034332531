import pick from 'lodash/pick';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutExtraPage/SET_INITIAL_VALUES';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  bookingData: null,
  bookingDates: null,
  transaction: null,
};

export default function checkoutExtraPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});
