/*
 * Marketplace specific configuration.
 */

import insuranceLogo from '../src/assets/insuranceLogo.svg';

export const mailContact = 'hallo@swimmy.com';

export const pressMail = 'cedric@swimmy.com';
export const pressPhoneNumber = "+4932222005969";
export const pressPhoneNumberReadable = "+49 32 222005969";

export const supportPhoneNumber = '+33186476130';
export const supportPhoneNumberReadable = '01 86 47 61 30';

export const teamBuildingMail = 'hallo@swimmy.com';
export const birthdayMail = 'hallo@swimmy.com';

export const supportHours = {
  start: '9:00',
  end: '18:00',
};

export const hasBlog = false;
export const blogUrl = 'https://blog.swimmy.fr/';

export const hasZendeskWidget = false;

export const hasInsurance = true;
export { insuranceLogo };

export const hasSecurityLaw = false;
export const securityRulesURL = "https://www.de.swimmy.com/security-rules";
export const securityOptions = [
  { key: 'barriereDeProtection', label: "Schutzbarriere" },
  { key: 'bacheDeSecurite', label: "Sicherheitsplane" },
  { key: 'abriDePiscine', label: "Überdachung" },
  { key: 'alarmeDeSecurite', label: "Alarmanlage" },
];

// this is used to toggle reviews on LandingPage/SimulatorPage
export const showReviews = false;

export const showPressArticles = true;

export const eventQuery = '?address=Germany&bounds=55.08149999602162%2C15.04189619759281%2C47.27011146235569%2C5.86634248758437&origin=51.165691%2C10.451526&country=DE';

export const amenities = [
  { key: 'piscine_interieure', label: '🏊 Innenpool' },
  { key: 'piscine_chauffee', label: '🌡️ Beheizter pool' },
  { key: 'douche', label: '🚿 Dusche' },
  { key: 'toilette', label: '🚽 Toilette' },
  { key: 'jacuzzi', label: '🛁 Whirlpool' },
  { key: 'sauna', label: '🧖 Sauna' },
  { key: 'jardin', label: '☀️ Garten' },
  { key: 'transats', label: '⛱️ Liegestühle' },
  { key: 'table_et_chaises', label: '🪑 Tisch und Stühle' },
  { key: 'bbq', label: '🍖 Grillen' },
  { key: 'bouées', label: '🤽 Aufblasbare Schwimmkörper' },
  { key: 'jeux', label: '🥏 Outdoor-Spiele' },
  { key: 'tennis', label: '🎾 Tennisplatz' },
  { key: 'petanque', label: '🎯 Tischtennisplatte' },
  { key: 'wifi', label: '💻 Wi-Fi' },
];

export const validExtras = [
  'barbecueFee',
  'towelsFee',
  'jacuzziFee',
  'snackFee',
]

export const paidExtras = [
  {
    data: {
      amount: 1000, currency: 'EUR', id: 'barbecueFee', isByUnit: false,
      information: 'Sie können Ihren Grill für 10€ pro Vermietung anbieten. Sie müssen lediglich den Brennstoff bereitstellen.',
    },
    key: 'barbecueFee',
    label: '🍖 Grillen • 10€',
  },
  {
    data: {
      amount: 300, currency: 'EUR', id: 'towelsFee', isByUnit: true,
      information: 'Sie können ein Handtuch für 3€/Stück anbieten.',
    },
    key: 'towelsFee',
    label: '🧺 Handtuch • 3€/Person',
  },
  {
    data: { amount: 1000, currency: 'EUR', id: 'jacuzziFee', isByUnit: true },
    key: 'jacuzziFee',
    label: '🛁 Spa / Whirlpool • 10€/Person',
  },
  {
    data: {
      amount: 400, currency: 'EUR', id: 'snackFee', isByUnit: true,
      information: 'Sie können einen Snack für 4€/Person anbieten. Die Beschreibung fügen Sie bitte Ihrer Anzeige hinzu.',
    },
    key: 'snackFee',
    label: '🥤 Süßer Snack • 4€/Person',
  },
];

export const providerPresentOptions = [
  { key: 'oui', label: 'Ja' },
  { key: 'non', label: 'Nein' },
  { key: 'occasionnellement', label: 'Gelegentlich' },
];

export const howDidYouKnowUsOptions = [
  { key: 'wordOfMouth', label: 'Vom Hörensagen' },
  { key: 'medias', label: 'Medien' },
  { key: 'bySwimmyTeamContact', label: 'Ich bin vom Swimmy Team kontaktiert worden' },
  { key: 'byFacebook', label: 'Facebook' },
  { key: 'instagram', label: 'Instagram' },
  { key: 'irrijardin', label: 'Irrijardin' },
  { key: 'byAds', label: 'Ich habe eine Werbung im Internet gesehen' },
  { key: 'bySwimmyBlog', label: 'Der Swimmy Blog' },
];

/**
 * Customer commission percentage which is added
 * to the total price of every booking.
 */
export const customerCommissionPercentage = 20;

/**
 * Discount multiplier for children.
 */
export const childrenDiscountMultiplier = 0.5;

/**
 * Custom multipliers to be used with bounds expansion
 * to run location searches on larger areas.
 *
 * The key musy match the address value of a place
 * returned by the Google Maps API.
 */
export const customBoundsMultiplier = {
  'Paris, France': 4.5,
};

export const filters = [
  {
    id: 'price',
    label: 'Preis',
    type: 'PriceFilter',
    group: 'secondary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 1,
      max: 500,
      step: 1,
    },
  },
  {
    id: 'superHost',
    label: 'Filter.superHostLabel',
    type: 'BooleanFilter',
    group: 'secondary',
    queryParamNames: ['pub_isHostedBySuperHost'],
    config: {
      helper: 'Filter.superHostHelper',
    },
  },
  {
    id: 'policyEvent',
    label: 'Filter.policyEventLabel',
    type: 'BooleanFilter',
    group: 'secondary',
    queryParamNames: ['pub_policyEvent'],
    config: {
      helper: 'Filter.policyEventHelper',
    },
  },
  {
    id: 'policyMusic',
    label: 'Filter.policyMusicLabel',
    type: 'BooleanFilter',
    group: 'secondary',
    queryParamNames: ['pub_policyMusic'],
    config: {
      helper: 'Filter.policyMusicHelper',
    },
  },
  {
    id: 'policyPets',
    label: 'Filter.policyPetsLabel',
    type: 'BooleanFilter',
    group: 'secondary',
    queryParamNames: ['pub_policyPets'],
    config: {
      helper: 'Filter.policyPetsHelper',
    },
  },
  {
    id: 'amenities',
    label: 'Ausstattung',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_extras'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: amenities,
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: false,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

// 'km' for kilometers or 'mi' for miles
export const distanceUnit = 'km';
/**
 * If isSeasonalMarketplace is true both seasonStart and
 * seasonEnd must have a value
 *
 * Months have to be set using those values :
 * January = 0
 * ...
 * December = 11
 */

export const isSeasonalMarketplace = true;
export const seasonStart = {
  month: "Mars",
  value: 2,
};
export const seasonEnd = {
  month: "Oktober",
  value: 9,
};

// By default the age requirement is 18
export const marketplaceAgeRequirement = 18;

export const minStartHourToBook = 9;

// key is for average cost in the labeled region
export const simulatorFormLocations = [
  { key: 3600, label: `Baden-Württemberg` },
  { key: 3600, label: 'Bayern' },
  { key: 3200, label: 'Berlin' },
  { key: 2800, label: 'Niedersachsen' },
  { key: 3200, label: 'Nordrhein-Westfalen' },
  { key: 2800, label: 'Rheinland-Pfalz' },
  { key: 2400, label: 'Sachsen-Anhalt' },
  { key: 2800, label: 'Schleswig-Holstein' },
  { key: 2400, label: 'Brandenburg' },
  { key: 3200, label: 'Bremen' },
  { key: 3600, label: 'Hamburg' },
  { key: 3600, label: 'Hessen' },
  { key: 2400, label: 'Mecklenburg-Vorpommern' },
  { key: 2800, label: 'Sachsen' },
  { key: 2400, label: 'Thüringen' },
  { key: 2800, label: 'Saarland' },
];

export const withEveningBookingModeDate = '2021-04-13T15:30:00.000Z';

export const insuranceSlug = 'roots?slug=insurance-de';

export const termsOfUseSlug = 'roots?slug=terms-of-use-de';

export const legalNoticeSlug = 'roots?slug=legal-notice-de';

export const helpCenterUrl = 'https://help.swimmy.com/hc/de';
export const helpCenterUrlForCustomers = 'https://help.swimmy.com/hc/de/categories/360002484820-Ich-bin-ein-Mieter';
export const helpCenterUrlForProviders = 'https://help.swimmy.com/hc/de/categories/360002499219-Ich-bin-ein-gastgeber';

export const country = 'de';

export const stripeIdentityDocuments = [
  'Reisepass',
  'Führerschein - Vorder- UND Rückseite',
  'Personalausweis - Vorder- UND Rückseite',
];
export const stripeAddressDocuments = [
  'Telefonrechnung - weniger als 6 Monate datiert',
  'Strom-, Gas- oder Wasserrechnung - weniger als 6 Monate datiert',
  'Wohnversicherungsbescheinigung oder Rechnung - weniger als 6 Monate datiert',
  'Eigentums Titel',
];
