import React from 'react';
import { func, object, string, bool } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { EditListingInformationForm } from '../../forms';
import { ListingLink } from '../../components';

import css from './EditListingInformationPanel.module.css';

const EditListingInformationPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;
  const handleSubmit = (values) => {
    const {
      providerPresent: proprietairesPresents,
      poolTreatment,
      poolTreatmentOther,
    } = values;
    const updatedValues = {
      publicData: {
        proprietairesPresents,
        poolTreatment,
        poolTreatmentOther,
      }
    };
    onSubmit(updatedValues);
  };
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingInformationPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingInformationPanel.createListingTitle" />
  );
  const {
    proprietairesPresents: providerPresent,
    poolTreatment,
    poolTreatmentOther,
  } = publicData;
  const initialValues = {
    providerPresent: typeof providerPresent === 'object' ? providerPresent[0] : providerPresent,
    poolTreatment,
    poolTreatmentOther,
  };

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingInformationForm
        className={css.form}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingInformationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingInformationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingInformationPanel;
