import React, { Fragment } from 'react';
import { func, shape, string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import classNames from 'classnames';

import routeConfiguration from '../../../routeConfiguration';
import {
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  propTypes,
} from '../../../util/types';
import { ensureOwnListing } from '../../../util/data';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../../util/urlHelpers';
import {
  IconAttachMoney,
  IconEdit,
  IconEditCalendar,
  IconPowerSettings,
  IconTrash,
} from '../../../components/Icons';
import { Divider, ResponsiveImage, TextContainer } from '../../../components';

import ListingInformations from './ListingInformations/ListingInformations';
import ManageButton from './ManageButton/ManageButton';
import ManageLink from './ManageLink/ManageLink';
import {
  createListingURL,
  getStateInformationText,
  getStateText,
} from './ManageListingCard.helpers';
import css from './ManageListingCard.module.css';

export const ManageListingCardComponent = props => {
  const {
    className,
    rootClassName,
    actionsInProgressListingId,
    intl,
    listing,
    onCloseListing,
    onDiscardDraft,
    onOpenListing,
  } = props;
  const history = useHistory();
  const currentListing = ensureOwnListing(listing);
  if (!currentListing.attributes) return null;
  const listingId = currentListing.id;
  const listingUuid = listingId.uuid;
  const { title = '', state } = currentListing.attributes;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const handleListingClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isDraft) return;

    history.push(createListingURL(routeConfiguration(), listing));
  };
  const firstImage = currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const stateText = getStateText(intl, currentListing);
  const stateInformationText = getStateInformationText(intl, currentListing);
  const photosMissingText = intl.formatMessage({ id: 'ManageListingsPage.ManageListingCard.photosMissing' });
  const editText = intl.formatMessage({ id: 'ManageListingsPage.ManageListingCard.edit' });
  const calendarText = intl.formatMessage({ id: 'ManageListingsPage.ManageListingCard.calendar' });
  const pricingText = intl.formatMessage({ id: 'ManageListingsPage.ManageListingCard.pricing' });
  const discardText = intl.formatMessage({ id: 'ManageListingsPage.ManageListingCard.discard' });
  const closeText = intl.formatMessage({ id: `ManageListingsPage.ManageListingCard.${isClosed ? 'open' : 'close' }`});

  const thisListingInProgress = actionsInProgressListingId && actionsInProgressListingId.uuid === listingUuid;

  const slug = createSlug(title);
  const linkType = isDraft
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;

  const classes = classNames(rootClassName || css.root, className);
  const stateClasses = classNames(css.state, {
    [css.stateDraft]: isDraft,
    [css.stateClosed]: isClosed,
  });

  return (
    <div className={classes}>
      <div className={css.infosContainer}>
        {!isDraft && !isClosed && !firstImage && <TextContainer className={css.photosMissingTextContainer} text={photosMissingText} />}
        {(isDraft || isClosed) && <TextContainer className={css.informationsTextContainer} text={stateInformationText} />}
        <div className={css.mainInfosContainer} onClick={handleListingClick}>
          {firstImage && (
            <ResponsiveImage
              className={css.responsiveImage}
              alt={title}
              image={firstImage}
              variants={['square-small']}
            />
          )}
          <div className={css.titleWrapper}>
            <span className={css.title}>{title}</span>
            <span className={stateClasses}>{stateText}</span>
          </div>
        </div>
        {!isDraft && (
          <ListingInformations
            className={css.listingInformations}
            intl={intl}
            listing={currentListing}
          />
        )}
      </div>
      <div className={css.actionsContainer}>
        <ManageLink
          className={css.action}
          iconComponent={<IconEdit />}
          pageName="EditListingPage"
          pageParams={{ id: listingUuid, slug, type: linkType, tab: 'description' }}
          text={editText}
        />
        {!isDraft && (
          <Fragment>
            <Divider className={css.divider} />
            <ManageLink
              className={css.action}
              iconComponent={<IconEditCalendar />}
              pageName="CalendarPage"
              pageParams={{ id: listingUuid }}
              text={calendarText}
            />
            <Divider className={css.divider} />
            <ManageLink
              className={css.action}
              iconComponent={<IconAttachMoney />}
              pageName="EditListingPage"
              pageParams={{ id: listingUuid, slug, type: linkType, tab: 'pricing' }}
              text={pricingText}
            />
          </Fragment>
        )}
        {isDraft && (
          <Fragment>
          <Divider className={css.divider} />
            <ManageButton
              className={css.action}
              disabled={thisListingInProgress}
              iconComponent={<IconTrash />}
              onClick={() => onDiscardDraft(listingId)}
              text={discardText}
            />
          </Fragment>
        )}
        {!isDraft && (
          <Fragment>
            <Divider className={css.divider} />
            <ManageButton
              className={css.action}
              disabled={thisListingInProgress}
              iconComponent={<IconPowerSettings />}
              onClick={() => isClosed ? onOpenListing(listingId) : onCloseListing(listingId)}
              text={closeText}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

ManageListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  actionsInProgressListingId: null,
};

ManageListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.ownListing.isRequired,
  actionsInProgressListingId: shape({ uuid: string.isRequired }),
  onCloseListing: func.isRequired,
  onDiscardDraft: func.isRequired,
  onOpenListing: func.isRequired,
};

export default injectIntl(ManageListingCardComponent);