import React from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { path, pathOr } from 'ramda';

import { ensureCurrentUser } from '../../../../util/data';
import { propTypes } from '../../../../util/types';
import { manageDisableScrolling } from '../../../../ducks/UI.duck';
import { sendVerificationEmail } from '../../../../ducks/user.duck';
import { savePersonalInfos, changeEmailClear } from '../../../../ducks/userSettings.duck';
import { PersonalInfosForm } from '../../../../forms';

import css from './PersonalInfosTab.module.css';

const PersonalInfosTab = props => {
  const {
    changeEmailError,
    changeEmailInProgress,
    currentUser,
    onChange,
    onResendVerificationEmail,
    onSubmitPersonalInfos,
    personalInfosChanged,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    updateInProgress,
    updateProfileError,
    onManageDisableScrolling,
  } = props;
  const user = ensureCurrentUser(currentUser);
  const currentEmail = pathOr('', ['attributes', 'email'], user);
  const { firstName, lastName, protectedData, privateData } = pathOr({}, ['attributes', 'profile'], user);
  const birthdayDate = pathOr('', ['birthdayDate'], protectedData);
  const currentPhoneNumber = pathOr('', ['phoneNumber'], protectedData);
  const currentBirthdayDate = pathOr('', ['birthdayDate'], protectedData);
  const currentLanguage = pathOr('', ['language'], privateData);
  const howDidYouKnowUs = path(['howDidYouKnowUs'], protectedData);
  const handleSubmit = (values) => {
    const formattedValues = {
      ...values,
      currentFirstName: firstName,
      currentLastName: lastName,
      currentBirthdayDate,
      currentEmail,
      currentPhoneNumber,
      currentLanguage,
    };

    onSubmitPersonalInfos(formattedValues);
  };
  const initialValues = {
    firstName,
    lastName,
    email: currentEmail,
    phoneNumber: currentPhoneNumber,
    birthdayDate,
    language: currentLanguage,
    howDidYouKnowUs,
  };

  return (
    <PersonalInfosForm
      className={css.form}
      changeEmailError={changeEmailError}
      changeEmailInProgress={changeEmailInProgress}
      formId="PersonalInfosForm"
      onManageDisableScrolling={onManageDisableScrolling}
      onResendVerificationEmail={onResendVerificationEmail}
      sendVerificationEmailError={sendVerificationEmailError}
      sendVerificationEmailInProgress={sendVerificationEmailInProgress}
      updateInProgress={updateInProgress}
      updateProfileError={updateProfileError}
      initialValues={initialValues}
      onChange={onChange}
      onSubmit={handleSubmit}
      ready={personalInfosChanged}
    />
  )
};

PersonalInfosTab.defaultProps = {
  changeEmailError: null,
  currentUser: null,
  sendVerificationEmailError: null,
};

PersonalInfosTab.propTypes = {
  changeEmailError: propTypes.error,
  changeEmailInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  onChange: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSubmitPersonalInfos: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  personalInfosChanged: bool.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
};

const mapStateToProps = state => {
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const {
    changeEmailError,
    changeEmailInProgress,
    personalInfosChanged,
    updateInProgress,
    updateProfileError,
  } = state.userSettings;

  return {
    changeEmailError,
    changeEmailInProgress,
    currentUser,
    personalInfosChanged,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    updateInProgress,
    updateProfileError,
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: () => dispatch(changeEmailClear()),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onSubmitPersonalInfos: values => dispatch(savePersonalInfos(values)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PersonalInfosTab);
