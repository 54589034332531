import listingImage1 from './images/featured_listing_1.jpg';
import listingImage2 from './images/featured_listing_2.jpg';
import listingImage3 from './images/featured_listing_3.jpg';
import avatarImage1 from './images/avatar_1.jpg';
import avatarImage2 from './images/avatar_2.jpg';
import avatarImage3 from './images/avatar_3.jpg';

export const linksProps = {
  listing1: {
    id: '60c36807-ba2e-4046-a03f-c8a5f066609d',
    title: 'Naturpool​,​ Sonnendeck & Jacuzzi nahe Berlin',
    price: '3000',
    image: listingImage1,
    provider: {
      avatar: avatarImage1,
      displayName: 'M. R',
      abbreviatedName: 'MR',
    },
  },
  listing2: {
    id: '60afd12e-80bf-4fe0-9276-bc56e4fb21dc',
    title: 'Naturteich im (Traum-) Garten um Ruhe-Momente zu genießen',
    price: '2500',
    image: listingImage2,
    provider: {
      avatar: avatarImage2,
      displayName: 'Armand H',
      abbreviatedName: 'AH',
    },
  },
  listing3: {
    id: '606d7039-6641-4e5a-b13b-5f2e7779618a',
    title: 'Schöner beheizter Pool in grüner Umgebung',
    price: '2000',
    image: listingImage3,
    provider: {
      avatar: avatarImage3,
      displayName: 'Nancy U',
      abbreviatedName: 'NU',
    },
  },
};
