import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './styles.css';

const SecurityRules = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>

      <h2>Note sur les dispositifs de sécurité des piscines privées</h2>

      <h2>Rappel du texte de loi</h2>

      <p><a href="https://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000000776783&categorieLien=id"
            target="_blank">
        <b>LOI n° 2003-9 du 3 janvier 2003 relative à la sécurité des piscines</b></a></p>
      <div>
        <p>
          <i>(Vérifié le 09 février 2018 - Direction de l'information légale et administrative (Premier ministre))</i>
        </p>
        <p>
          Depuis le 3 janvier 2003 les piscines privées à usage familiale ou collectif, sont désormais soumises à une
          loi et à de nombreuses normes françaises ou européennes. Certaines piscines privées doivent être équipées d'un
          dispositif de sécurité afin de prévenir les risques de noyade, notamment de jeunes enfants ( en dessous de 5
          ans)
        </p>

        <h2>De quoi s'agit-il ?</h2>
        <p>
          Si vous êtes propriétaire d'une piscine privée à usage individuel ou collectif (piscines familiales ou
          réservées à des résidents, piscines d'hôtels, de campings, de gîtes ruraux...), vous devez
          installer <b>AU MOINS UN DE CES QUATRES EQUIPEMENTS</b> :
        </p>
        <ul className={css.indentedBullet}>
          <li><b>Barrière de protection</b></li>
          <li><b>Système d'alarme sonore</b> (alarme d'immersion informant de la chute d'un enfant dans l'eau ou
            alarme périmétrique informant de l'approche d'un enfant du bassin)
          </li>
          <li><b>Couverture de sécurité</b> (bâche)</li>
          <li><b>Abri de type véranda recouvrant intégralement le bassin</b></li>
        </ul>

        <h2>Piscines concernées</h2>

        <p>
          La « loi sécurité » concerne : les piscines situées en totalité ou partiellement, en plein air, qu’elle
          soit entièrement ou en partie enterrée, qu’elle soit privée familiale, ou réservée à des résidents,
          des centres de vacances, des club de loisirs, des hôtels et restaurants, des gîtes ruraux, des
          campings …..
        </p>
        <p>
          Ne sont pas concernées : Les piscines gonflables ou démontable totalement hors sol et posées dessus, ni les «
          établissements de natation » payant, surveillé par un maître-nageur (piscines visées par la loi du
          24 mai 1951), ni les spa (le spa de nage selon les situations peut-être concerné
          par la loi sécurité), ni les bassins d’agréments, ni les piscines d’intérieures (situées entièrement
          dans un bâtiment).
        </p>

        <h2>Si vous possédez un système de sécurité antérieur à la loi</h2>

        <p>
          Il vous faut faire attester la conformité de votre installation soit par un contrôleur technique agréé
          par l’Etat, la liste disponible à la DDE (ou consultable sur le site
          http://www.sante.gouv.fr/IMG/pdf/pisc09.pdf visé à l'article L. 111-23 du code de la construction),
          soit sous votre responsabilité, en fournissant tous justificatifs techniques de vérification. Vous
          serez dans l’obligation de réaliser des travaux si votre dispositif venait à ne pas répondre aux
          normes en vigueur ou d’acquérir un moyen de protection conforme pour votre piscine. En cas de
          non-respect de la loi, le propriétaire de la piscine encourt une peine de 45 000 € d’amende ou/et
          des sanctions pénales.
        </p>


        <h2>Conseils de prévention</h2>

        <p>Quelques conseils pour éviter les noyades proposés par la Coordination syndicale
          interprofessionnelle de la piscine (CSIP).</p>

        <ol className={css.indentedBullet}>
          <li><b>Ne laissez jamais un enfant accéder ou rester seul près d'un point d'eau.</b></li>
          <li><b>La surveillance des enfants doit être rapprochée et constante.</b></li>
          <li><b>Désignez un seul responsable de la sécurité.</b></li>
          <li><b>Équipez vos enfants de brassards, maillots flotteurs, bouées adaptées.</b></li>
          <li><b>Apprenez à nager à vos enfants le plus tôt possible.</b></li>
          <li><b>Ayez toujours à côté du bassin une perche, une bouée, un téléphone portable.</b></li>
          <li><b>Apprenez les gestes qui sauvent.</b></li>
          <li><b>Ne laissez pas de jouets dans l'eau après la baignade.</b></li>
          <li><b>Équipez votre bassin d'un dispositif de protection et n'oubliez pas de le remettre en place après la
            baignade.</b></li>
          <li><b>Stockez les produits de traitement de l'eau hors de portée des enfants.</b></li>
          <li><b>La meilleure sécurité reste votre vigilance et votre surveillance constante de parent ou d’adulte
            responsable. Un enfant peut se noyer dans 20 cm d’eau en moins de trois minutes.</b></li>
        </ol>


        <h2>Choix de l'équipement de sécurité</h2>

        <p>
          L'équipement doit être conforme aux normes édictées par l'Association française de normalisation (Afnor).

        </p>
        <p>
          Les équipements suivants sont conformes à ces exigences :
        </p>
        <ul className={css.indentedBullet}>
          <li><b>NF P90-306 pour les barrières de protection</b></li>
          <li><b>NF P90-307 pour les systèmes d'alarmes</b></li>
          <li><b>NF P90-308 pour les couvertures de sécurité</b></li>
          <li><b>NF P90-309 pour les abris</b></li>
        </ul>

        <h2>NORME DE SÉCURITÉ NF P90-306 pour les barrières de protection</h2>

        <p>
          <b>Présentation de la norme NF P90-306 concernant les piscine avec barrière de protection</b>
        </p>
        <p>
          La norme NF P90-306 fait partie d’une série de 4 normes publiée en mai 2004 qui viennent à l’appui de la loi
          2003-9 du 3 janvier 2003 relative à la sécurité des piscines, entrée en application le 1er janvier 2004.
          Celle-ci fait obligation aux propriétaires d'une piscine privée enterrée ou semi enterrée de s'équiper d'un
          dispositif de sécurité.
        </p>
        <p>
          Norme NF P90 306 : barrières de protection
        </p>
        <p>
          Cette norme s’applique aux barrières de protection destinées à limiter l’accès des piscines enterrées non
          closes privatives à usage individuel ou collectif aux enfants.
        </p>
        <p>
          Une barrière de protection doit obligatoirement empêcher le passage d’enfants de moins de cinq ans par
          enjambement/escalade ou par ouverture non intentionnelle des moyens d’accès mis en place.
        </p>
        <p>
          Pour permettre son franchissement sans risque par les utilisateurs plus âgés, la barrière de protection doit
          comporter un moyen d’accès à l’épreuve des enfants de moins de cinq ans et être sans danger pour tous les
          utilisateurs, qu’ils soient adultes ou enfants.
        </p>
        <p>
          Les principaux points abordés par la norme NF P90-308 sont les suivants : Barrière de sécurité pour piscine
          privée > <b>La hauteur minimale entre deux points d’appui, ou entre le point d’appui le plus haut et la partie
          la plus basse du niveau supérieur de la barrière ou du moyen d’accès, doit toujours être supérieure ou égale à
          1,10 m.
          Les barreaux ne doivent comporter aucune aspérité en relief.</b>
        </p>
        <p>
          Les bords, arêtes, éléments saillants et angles accessibles ne doivent pas présenter de risque de blessure.
          Les bords sont considérés comme potentiellement dangereux s'ils sont coupants.Les risques de coincement
          doivent être évités. La barrière de protection et son moyen d’accès ne doivent pas blesser les enfants qui
          cherchent à les franchir.
        </p>
        <p>
          Pour prévenir le risque de déverrouillage par les enfants de moins de cinq ans ou un
          déverrouillage non intentionnel, le système de déverrouillage doit nécessiter au moins deux
          actions sur le système pour le libérer. Le système doit pouvoir être manœuvré sans difficulté par
          un adulte.
        </p>
        <p>
          La barrière, les poteaux et les moyens d'accès doivent résister aux chocs équivalant à un poids de
          50 Kg.
        </p>
        <p>
          <b>Recommandations</b>
        </p>
        <p>
          Il est impératif de vérifier régulièrement le bon fonctionnement du système utilisé pour éviter tout
          risque d’accidents. Si vous notez une défaillance du système, entrez en contact avec le magasin
          Irrijardin le plus proche de chez vous afin de déterminer si des éléments de votre installation sont à
          changer, et cela, dans les plus brefs délais.
        </p>
        <p>
          Pendant ce temps, il est important de faire le nécessaire pour empêcher l’accès du bassin aux
          jeunes enfants et ce, jusqu’à la réparation de la barrière ou du moyen d’accès lors d’un constat de
          dysfonctionnement ou lors du démontage de la barrière qui empêche la sécurisation du bassin.
        </p>
        <p>
          <b>Dans quel cas utiliser une barrière de protection ?</b>
        </p>
        <p>
          Si votre coin baignade est facilement accessible aux jeunes enfants, les barrières ou clôtures (NF
          P 90-306) sont idéales pour limiter l’accès de votre piscine.
        </p>
        <p>
          Si vous possédez déjà un mur à la hauteur requise par la norme de sécurité, vous pouvez le
          compléter par une barrière de protection.
        </p>
        <p>
          La mise en place de ce type de système associé à la surveillance d’un adulte diminueconsidérablement le risque
          d’accidents.
        </p>
        <p>
          Pour plus de précision, téléchargez le document PDF du texte de loi concernant la norme NF P 90-306 élaboré
          par la commission de normalisation AFNOR :
          http://www.afnor.org/fiches/faqreglementation/piscines-privees-fabricants/
        </p>
        <p>
          <b>Installation de l'équipement</b>
        </p>
        <p>
          Le dispositif de sécurité peut être installé par vous-même ou par le vendeur ou installateur de votre
          choix.
        </p>
        <p>
          Le vendeur ou l'installateur doit vous fournir une note technique d'information indiquant : Les
          caractéristiques, les conditions de fonctionnement et d'entretien du dispositif de sécurité choisi, et les
          mesures générales de prévention et de recommandation pour éviter les risques de noyade.
        </p>

        <h2>NF P90-307 pour les systèmes d’alarmes</h2>

        <p>
          Si vous optez pour un système d'alarme, celle-ci doit être conforme à la norme NF P 90-307. Cette norme
          définit certaines règles précises en matière d’alarme de piscine.
        </p>
        <p>
          Pour être conforme à cette norme, un système d'alarme doit :
        </p>
        <p>
          <ul className={css.indentedBullet}>
            <li>Disposer d'une puissance sonore suffisante pour être entendue depuis la maison,</li>
            <li>Pouvoir fonctionner 24h/24 quelques soient les conditions atmosphériques : elle doit résister à une
              chaleur sèche de 70°C et à une température de -25°C,
            </li>
            <li>Se réactiver automatiquement après vos baignades,</li>
            <li>Etre inaccessible aux enfants : ils ne peuvent pas l’activer ou la désactiver,</li>
            <li>Etre équipée de moyens d’avertissement en cas de défaillance : batteries déchargées, problème technique,
              etc.
            </li>
          </ul>
        </p>
        <p>
          <b>le cas des alarmes immergées</b>
          En plus des critères généraux, une alarme de piscine immergée homologuée n’est pas mobile mais fixée
          définitivement à la piscine. Elle peut détecter la chute d’un enfant à partir de 6kg. Par ailleurs, l’alarme
          immergée n’est pas sujet à des déclenchements intempestifs pour cause de vents violents, voire fonctionnement
          du système de filtration ou d’un robot de piscine.
        </p>
        <p>
          <b>le cas des alarmes infrarouges</b>
          Les alarmes infrarouges de piscine sont aussi soumises à certaines règles particulières. Les bornes
          infrarouges ont une hauteur minimum pour ne pas que les enfants montent dessus, et sont placées à une certaine
          distance les unes des autres. La borne maîtresse possède une batterie pour que le système fonctionne toujours
          en cas de coupure d’électricité.
        </p>

        <h2>NORME NF P90-308 : LES COUVERTURES DE SÉCURITÉ</h2>

        <p>
          Les couvertures de sécurité sont l'une des solutions proposées par nos législateurs pour protéger les enfants
          de moins de 5 ans concernant les risques de noyade que peut représenter les piscines
        </p>
        <div>
          <b>Les produits concernés</b>
          <ul className={css.indentedBullet}>
            <li>Les bâches à barres ou bâches de sécurité. (Manuelle ou motorisée)</li>
            <li>Les volets roulants automatiques. (Manuel, électrique ou solaire, immergée, hors d’eau, avec
              banc…)
            </li>
            <li>Les autres types de couvertures. (Les Bâches hivernage sécurisées, les filets sécurisés)</li>
            <li>Sont exclues les couvertures destinées à la seule protection de l'eau, type bâche à bulles, bâches
              flottantes, filets…
            </li>
          </ul>

          <p><b>Les critères</b></p>
          <p>
            Voici un aperçu général des exigences en matière de sécurité mises en place par la norme. Ici, plus
            particulièrement pour les piscines familiales à usage privatif. Les principaux points qui font qu’une
            couverture peut être considérée comme étant « de sécurité ».
          </p>
          <p><b>Bâches et volets</b></p>

          <ul className={css.indentedBullet}>
            <li>Elle doit être conçue de manière à empêcher l’immersion d’un enfant de moins de 5 ans lorsqu’elle
              est installée
            </li>
            <li>Un enfant ne doit pas pouvoir s'introduire sous la couverture. Une bâche ne doit pas pouvoir se
              soulever de plus de 10 cm entre chaque élément de fixation
            </li>
            <li>Elle ne doit pas comporter d'éléments susceptibles de blesser les enfants qui chercheraient à la
              manipuler, que ce soit par coupure, piqûre, cisaillement, coincement, suffocation ou étranglement.
            </li>
            <li>Elle doit résister au passage d’un adulte de 100 kg sans que soient constatées de déchirures ou
              de désolidarisations des systèmes de fixation.
            </li>
            <li>Les systèmes de fixation de la couverture sur la plage ne doivent pas être sources de blessures ou
              de chutes pour un enfant. Ils ne doivent pas dépasser de plus de 25 mm au-dessus de la plage.
              (Les attaches type « sardines» ne sont pas sécuritaires pour la norme)
            </li>
            <li>Après avoir subi un choc d'un poids de 50 kg, le dispositif d'ancrage sur la plage ne doit pas
              êtredétérioré.
            </li>
          </ul>
          <p><b>Volets</b></p>

          <ul className={css.indentedBullet}>
            <li>Pour les volets automatiques, le dispositif de mise en fonctionnement ne doit pas être accessible,
              et ne doit pas être utilisable par un enfant. Le bouton de commande doit donc être assez haut (au
              moins 1,25m du sol) et la clé non visible pour l’enfant. La manœuvre de fermeture sera faite avec
              un contact maintenu, si la clé est relâchée, la couverture s'arrête.
            </li>
            <li>L’emplacement de la commande de fonctionnement de la couverture doit permettre de s'assurer que personne
              ne pénètre dans le bassin pendant la manœuvre de fermeture. Il faut donc que lecommutateur soit proche de
              la piscine et positionné de façon qu'il y ait une visibilité totale dubassin
            </li>
            <li>D’une manière générale, non seulement le matériel doit répondre à des normes strictes, mais
              aussi tous les matériaux constitutifs de la couverture (bâche ou volet), les systèmes d'ancrage et
              les systèmes de fixation doivent être conformes aux normes françaises et européennes en
              vigueur.
            </li>
            <li>De ce fait, les couvertures doivent correspondre à des critères précis pour constituer un véritable
              équipement de sécurité. Toutes les couvertures (bâche ou volet) ne sont pas « de sécurité »! Il
              convient donc de vérifier au moment de l'achat que la couverture ou la bâche soit certifiée en tant
              que dispositif de sécurité homologué. Un document accompagnera le produit, note technique
              précisant la norme, les caractéristiques, les conditions de fonctionnement et l’entretien du produit.
              Si vous confiez la réalisation de votre bassin à un constructeur, il doit vous remettre ce certificat au
              plus tard à la mise en eau de votre piscine.
            </li>
          </ul>

          <h4>Avantages des couvertures de sécurité</h4>

          <p>
            <b>Parmi les dispositifs de sécurité certifiés, les couvertures sont particulièrement attractives.</b>
          </p>
          <p>
            Contrairement aux alarmes ou aux barrières, elles ne se contentent pas que de la « sécurité », elles jouent
            aussi un rôle dans la préservation de la qualité de l'eau, et fait également fonction de protection
            iso-thermique. Elle protège le bassin pendant la période de vos absences.
          </p>
          <p>
            En choisissant une couverture, vous optez pour une solution multifonction. Une couverture protège l’eau de
            votre piscine des pollutions extérieures comme les feuilles, insectes. Ainsi votre consommation de produits
            de traitement de l'eau diminuera. Elle atténue le phénomène d'évaporation, une économie en matière de
            consommation d'eau. Elle limite la déperdition de chaleur. Pour les piscines chauffées, elle aide à réduire
            l'utilisation du chauffage et donc vous réalisez une économie de plus ! </p>
          <p>Elle s'intègre à votre bassin et est beaucoup plus discrète qu'un abri ou qu'une barrière de protection.
          </p>

          <h4>Inconvénients des couvertures de sécurité</h4>

          <p>
            Le seul inconvénient des bâches de sécurité piscines réside principalement dans leur
            manipulation. L'ouverture et la fermeture d'une bâche peut-être fastidieuse, plus particulièrement
            pour les modèles non motorisés. Les opérations d’ouverture et de fermeture, à chaque fois que
            vous vous absentez, peuvent devenir lassantes et conduire à des négligences.
          </p>
          <p>
            Le volet a tous les avantages d’une bâche, et le confort d’ouverture et de fermeture, est tout ce
            qu’il y a de plus facile! Le principal « frein » sera sans doute, d’ordre budgétaire, même si les
            modèles les plus simples restent tout à fait abordables.
          </p>


          <h2>La norme NF P90-309 pour les abris de piscine</h2>

          <p>
            La norme française NF P90-309 encadre plus spécifiquement la fabrication et les méthodes
            d’essai réalisés sur les abris de piscine. Ou, comme ils sont décrits par la norme, les « éléments
            de protection pour les piscines enterrées non closes privatives à usage individuel ou collectif ».
          </p>
          <p><b>Que dit la norme NF P90-309 pour les abris de piscine ?</b></p>
          <p>
            Créée en octobre 2007, cette norme française encadre les abris de piscine. Ou, plus exactement,
            sont concernées par cette norme les structures légères et/ou vérandas. La norme décrit les
            exigences de sécurité relatives à ces abris homologués ainsi que les méthodes d’essai afin de
            réduire le nombre de noyade des enfants de moins de 5 ans aux abords des piscines.
          </p>
          <p>
            L’abri doit empêcher un enfant de s’introduire dans les abords de la piscine. Il ne doit pouvoir ni
            l’enjamber ni l’escalader, et les entrées et moyens d’accès doivent être pour lui infranchissables
            (mais doivent permettre pour autant une ouverture facile pour les autres utilisateurs, sans risque).
          </p>
          <p>
            Les exigences qualité, à respecter par les fabricants d’abris de piscine, énoncent notamment que
            les structures doivent résister à un vent de 120 km/h, au minimum, et à un poids de neige de 60 kg
            au m². Les essais de conformité, selon cette norme, doivent être réalisés sur au moins 3 éléments
            dont 2 éléments consécutifs. Autre point : les profils PVC, utilisés pour la construction des abris,
            doivent avoir la marque NF (Norme Française).
          </p>

          <h2>Sanctions</h2>

          <p>En cas de non-respect de l'obligation d'équiper votre piscine d'un dispositif de sécurité, vous vous
            exposez à une amende pouvant aller jusqu'à 45 000 €.</p>

          <p>Le vendeur ou l'installateur qui ne vous fournit pas la note technique prévue risque une amende du
            même montant.</p>
        </div>
      </div>
    </div>
  );
};

SecurityRules.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

SecurityRules.propTypes = {
  rootClassName: string,
  className: string,
};

export default SecurityRules;
