import React from 'react';
import { func, string } from 'prop-types';
import { intlShape } from 'react-intl';

import { propTypes } from '../../../../util/types';
import { isTransactionsTransitionAlreadyReviewed } from '../../../../util/errors';
import { required } from '../../../../util/validators';
import { FieldReviewRating, FieldTextInput, Form } from '../../../../components';

import css from './ReviewForm.module.css';

const ReviewForm = props => {
  const {
    className,
    handleSubmit,
    intl,
    formId,
    sendReviewError,
  } = props;
  const reviewRating = intl.formatMessage({ id: 'ReviewForm.reviewRatingLabel' });
  const reviewRatingRequiredMessage = intl.formatMessage({ id: 'ReviewForm.reviewRatingRequired' });
  const reviewContent = intl.formatMessage({ id: 'ReviewForm.reviewContentLabel' });
  const reviewContentPlaceholderMessage = intl.formatMessage({ id: 'ReviewForm.reviewContentPlaceholder' });
  const reviewContentRequiredMessage = intl.formatMessage({ id: 'ReviewForm.reviewContentRequired' });
  const errorMessage = isTransactionsTransitionAlreadyReviewed(sendReviewError) ? (
    <p className={css.error}>
      {intl.formatMessage({ id: 'ReviewForm.reviewSubmitAlreadySent' })}
    </p>
  ) : (
    <p className={css.error}>
      {intl.formatMessage({ id: 'ReviewForm.reviewSubmitFailed' })}
    </p>
  );
  const errorArea = sendReviewError ? errorMessage : null;

  return (
    <Form className={className} id={formId} onSubmit={handleSubmit}>
      <FieldReviewRating
        className={css.reviewRating}
        id={formId ? `${formId}.starRating` : 'starRating'}
        name="reviewRating"
        label={reviewRating}
        validate={required(reviewRatingRequiredMessage)}
      />

      <FieldTextInput
        className={css.reviewContent}
        type="textarea"
        id={formId ? `${formId}.reviewContent` : 'reviewContent'}
        name="reviewContent"
        label={reviewContent}
        placeholder={reviewContentPlaceholderMessage}
        validate={required(reviewContentRequiredMessage)}
      />

      {errorArea}
    </Form>
  )
};

ReviewForm.defaultProps = {
  className: null,
  formId: null,
  sendReviewError: null,
};

ReviewForm.propTypes = {
  className: string,
  handleSubmit: func.isRequired,
  intl: intlShape.isRequired,
  formId: string,
  sendReviewError: propTypes.error,
};

export default ReviewForm;
