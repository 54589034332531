import React from 'react';
import PropTypes from 'prop-types';

const ExternalRoute = props => {
  const { url } = props;
  if (url) window.location.href = url;
  return null;
};

ExternalRoute.defaultProps = { url: null };

const { string } = PropTypes;

ExternalRoute.propTypes = { url: string };

export default ExternalRoute;
