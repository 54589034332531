import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import config from '../../config';

import css from './style.css';

const Taxation = props => {
  const { rootClassName, className } = props;
  const mailContact = config.custom.mailContact;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>

      <h2>Fiscalité des propriétaires sur Swimmy</h2>
      <p>
        Merci d'utiliser Swimmy ! Voici le détails de vos obligations fiscales et de celles de Swimmy. Pour toute
        question relative à ce sujet, <b>n'hésitez pas à nous écrire : {mailContact}</b>
      </p>

      <h2>Préambule important</h2>
      <p>
      Nous abordons ici un sujet qui concerne tous nos propriétaires : la déclaration des revenus générés via Swimmy aux impôts. 
      Avant de rentrer dans le détail des questions que cela pose, il est essentiel de préciser que les textes de lois sur la fiscalité des plateformes collaboratives sont très récents, 
      leur application encore plus, et que les interprétations sur ce sujet varient beaucoup.
      Vous trouverez à la fin de cette note un tour d'horizon des principaux textes produits par le gouvernement sur ce sujet.
      </p>
      <p>
      Nous vous souhaitons une bonne lecture.
      </p>

      <h2>Quelles sont les informations remontées par Swimmy à l'administration fiscale ?</h2>

      <p>
        Depuis le 1er janvier 2020, les plateformes sont tenues de déclarer à l'administration fiscale <b>les revenus de
        leurs abonnés qui ont générés plus de 3000€ TTC / an ou plus de 20 locations / an.</b>
      </p>
      <p>
      (Nous précisons ici qu'il y a un petit débat sur la notion du 3000€ ou 20 locations, 
      versus 3000€ et 20 locations. Les textes de lois semblent néanmoins plutôt clairs sur le fait qu'il s'agit bien de "3000€ ou 20 locations / an")
      </p>
      <p>
      Pour ces propriétaires, nous devons communiquer annuellement à l’administration fiscale un document de synthèse comportant un certain nombre d’informations : 
      Nom de famille ou d’usage, Prénom, Adresse de résidence, Numéro de téléphone, Adresse électronique, Date de naissance, Procédure de fiabilisation de l’identité, 
      Statut de l’utilisateur (particulier / professionnel), Nombre et montant de transactions réalisées.
      </p>

      <h2>A partir de quel montant doit-on déclarer les revenus générés via Swimmy ?</h2>

      <p>
      Légalement, à partir du premier euro : toute activité commerciale réalisée sur le territoire 
      français est censée être déclarée aux impôts.
      </p>
      <p>
      Dans la pratique, vous êtes exonéré d'impôts si votre piscine est dans votre résidence principale et que vous réalisez des recettes inférieures à 760€ TTC / an
      <a target="_blank" href="https://www.impots.gouv.fr/portail/particulier/questions/je-souhaite-louer-une-partie-de-ma-residence-principale-en-location-meublee">(source)</a>.
      </p>
      <p>
      La conclusion des deux points ci-dessus est la suivante : dans la pratique, en dessous de 760€ TTC / an, 
      la déclaration des revenues est plutôt de nature informative (de notre compréhension).
      </p>


      <h2>Comment déclarer ses revenus ?</h2>

      <p>
      Notre recommandation est d'utiliser le formulaire 2042 C Pro - à la ligne 5NP de la rubrique REVENUS INDUSTRIELS ET COMMERCIAUX NON PROFESSIONNELS. 
      Vous trouverez ici <a target="_blank" href="https://www.impots.gouv.fr/portail/files/formulaires/2042/2020/2042_3042.pdf">un lien vers le formulaire
        2042 C Pro</a>.
      </p>
      <p>
        Deux régimes fiscaux sont possibles :
        <ul className={css.indentedBullet}>
          <li><b>le régime micro bic</b> (vous permettant de bénéficier d'un abattement automatique de 50% au delà du
            plancher de 760€ TTC mentionné plus haut) - c'est le régime le plus simple et que nous vous recommandons
          </li>
          <li><b>le régime réel</b> (vous permettant de déduire vos charges réelles : investissements réalisés pour
            l'exploitation de votre piscine). Petite précision si vous optez pour le régime réel : cela nécessitera de
            tenir une comptabilité simplifiée et de conserver tous les justificatifs de achats liés à l'exploitation de
            votre piscine.
          </li>
        </ul>
      </p>
      <p>
        Pour le régime social : tant que vos recettes sont inférieures à 20% du PASS soit 8.227€ en 2020 vous n'êtes pas
        tenus de régler des cotisations sociales <a target="_blank"
        href="https://www.urssaf.fr/portail/home/espaces-dedies/activites-relevant-de-leconomie/quelles-activites/la-location-de-biens.html">(source)</a>.
      </p>


      <h2>A quel taux serai-je imposé ?</h2>

      <p>
      En optant pour le formulaire 2042 C Pro, les recettes que vous avez généré grâce à Swimmy seront ajoutée à celle 
      de votre foyer fiscal, vous serez imposé selon votre barème de l'impôt sur le revenu.
      </p>


      <h2>Quel montant dois-je déclarer aux impôts ?</h2>

      <p>
      Le montant exact des revenus que vous avez obtenus en louant votre piscine via Swimmy. Si vous avez perçu 1000€ en 2020 
      grâce à Swimmy, vous devez déclarer ce montant.
      </p>


      <h2>Comment puis-je connaître le montant annuel de mes recettes sur Swimmy ?</h2>

      <p>
      Pour cela, rien de plus simple, connectez-vous à votre compte Swimmy, <b>cliquez sur la rubrique "Revenus".</b> au travers de Swimmy.
      </p>

      <p>
      Vous y trouverez, pour chaque année depuis la création de votre compte, les revenus que vous avez réalisé, 
      ainsi que le nombre de locations acceptées / refusées / annulées, et le nombre de demandes de réservations reçues.
      </p>

      <h2>Liens utiles</h2>

      <ul className={css.indentedBullet}>
        <li><a target="_blank" href="https://www.impots.gouv.fr/portail/node/10841">https://www.impots.gouv.fr/portail/node/10841</a>
        </li>
        <li><a target="_blank"
          href="https://www.impots.gouv.fr/portail/files/media/1_metier/1_particulier/EV/1_declarer/141_autres_revenus/eco-collabo-fiscal-location-biens.pdf">https://www.impots.gouv.fr/portail/files/media/1_metier/1_particulier/EV/1_declarer/141_autres_revenus/eco-collabo-fiscal-location-biens.pdf</a>
        </li>
        <li><a target="_blank"
          href="https://www.impots.gouv.fr/portail/economie-collaborative-et-plateformes-numeriques">https://www.impots.gouv.fr/portail/economie-collaborative-et-plateformes-numeriques</a>
        </li>
        <li><a target="_blank"
          href="https://www.economie.gouv.fr/particuliers/location-biens-declaration-revenus">hhttps://www.economie.gouv.fr/particuliers/location-biens-declaration-revenus</a>
        </li>
        <li><a target="_blank"
          href="https://www.urssaf.fr/portail/home/espaces-dedies/activites-relevant-de-leconomie.html">https://www.urssaf.fr/portail/home/espaces-dedies/activites-relevant-de-leconomie.html</a>
        </li>
        <li><a target="_blank"
          href="https://bofip.impots.gouv.fr/bofip/11791-PGP.html/identifiant%3DBOI-BIC-DECLA-30-70-40-20-20200221">https://bofip.impots.gouv.fr/bofip/11791-PGP.html/identifiant%3DBOI-BIC-DECLA-30-70-40-20-20200221</a>
        </li>
      </ul>
    </div>
  );
};

Taxation.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

Taxation.propTypes = {
  rootClassName: string,
  className: string,
};

export default Taxation;
