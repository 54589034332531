import {
  ADULTS,
  CHILDREN,
  DATE,
  END_TIME,
  INFANTS,
  LOCATION,
  START_TIME,
} from '../../util/search';

export const blurAllFields = (formApi, exception = null) => {
  if (exception !== LOCATION) formApi.blur(LOCATION);
  if (exception !== DATE) formApi.blur(DATE);
  if (exception !== START_TIME) formApi.blur(START_TIME);
  if (exception !== END_TIME) formApi.blur(END_TIME);
  if (exception !== ADULTS) formApi.blur(ADULTS);
  if (exception !== CHILDREN) formApi.blur(CHILDREN);
  if (exception !== INFANTS) formApi.blur(INFANTS);
};