import React from 'react';
import { string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import includes from 'lodash/includes';

import config from '../../../config';
import { Emoji } from '../../../components';

import css from './AdditionalInfo.module.css';

const checkSelected = (options, selectedOptions) => {
  return options.map(option => ({
    key: option.key,
    label: option.label,
    isSelected: includes(selectedOptions, option.key),
  }));
};

const SecurityItem = props => {
  const { label } = props;
  return (
    <li className={css.securityItem}>
      <span className={css.securityItemLabel}>{label}</span>
    </li>
  );
};

const AdditionalInfo = (props) => {
  const {
    className,
    titleClassName,
    intl,
    providerPresentConfig,
    publicData,
    securityConfig,
  } = props;
  const { poolTreatment, poolTreatmentOther, proprietairesPresents, security } = publicData;
  const hasSecurityLaw = config.custom.hasSecurityLaw;
  const titleText = intl.formatMessage({ id: 'ListingPage.AdditionalInfo.title' });
  const poolTreatments = {
    bromine: intl.formatMessage({ id: 'ListingPage.bromine' }),
    chlorine: intl.formatMessage({ id: 'ListingPage.chlorine' }),
    salt: intl.formatMessage({ id: 'ListingPage.salt' }),
    other: poolTreatmentOther,
    undefined: intl.formatMessage({ id: 'ListingPage.poolTreatmentUndefined' }),
  };

  const poolTreatmentLabel = poolTreatments[poolTreatment];
  const poolTreatmentText = intl.formatMessage({ id: 'ListingPage.poolTreatmentText' }, { cleaner: poolTreatmentLabel });
  const providerPresence = checkSelected(providerPresentConfig, proprietairesPresents);
  const providerPresenceValue = proprietairesPresents && providerPresence.filter(option => (option.isSelected))[0].label;
  const providerPresenceText = intl.formatMessage({ id: 'ListingPage.providerPresenceText' }, { value: providerPresenceValue });
  const securityText = intl.formatMessage({ id: 'ListingPage.securityText' });
  const securityId = "ListingPage.security";
  const securityChecked = checkSelected(securityConfig, security);
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <h2 className={titleClassName}>{titleText}</h2>
      {proprietairesPresents ? (
        <div className={css.additionalInfoItem}>
          <Emoji className={css.emoji} symbol={0x1F93F} />
          <div className={css.additionalInfoTextContainer}>
            <span className={css.additionalInfoText}>{providerPresenceText}</span>
          </div>
        </div>
      ) : null}
      <div className={css.additionalInfoItem}>
        <Emoji className={css.emoji} symbol={0x1F4A7} />
        <div className={css.additionalInfoTextContainer}>
          <span className={css.additionalInfoText}>{poolTreatmentText}</span>
        </div>
      </div>
      {hasSecurityLaw && security ? (
        <div className={css.additionalInfoItem}>
          <Emoji className={css.emoji} symbol={0x1F3CA} />
          <div className={css.additionalInfoTextContainer}>
            <span className={css.additionalInfoText}>{securityText}</span>
            <ul className={css.securityList}>
              {securityChecked.filter(option => (option.isSelected))
                .map(option => (
                  <SecurityItem key={`${securityId}.${option.key}`} label={option.label} />
                ))}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  )
}

AdditionalInfo.defaultProps = {
  className: null,
};

AdditionalInfo.propTypes = {
  className: string,
  titleClassName: string.isRequired,
  intl: intlShape.isRequired,
};

export default AdditionalInfo;