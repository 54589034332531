import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconGite.module.css';

const IconGite = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M2 19V10L6 6H7V4H9V6H18L22 10V19ZM16 17H20V10.825L18 8.825L16 10.825ZM4 17H14V12H4Z"/>
    </svg>
  );
};

IconGite.defaultProps = {
  rootClassName: null,
  className: null,
};

IconGite.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconGite;