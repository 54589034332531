import React from 'react';
import { bool, func, string } from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';

import config from '../../config';
import {
  ExternalLink,
  IconPhone,
  Modal,
} from '../../components';

import css from './ModalContactUs.css';

const ModalContactUs = props => {
  const {
    isOpen,
    onClose,
    onManageDisableScrolling,
    intl,
  } = props;

  const title = intl.formatMessage({ id: 'ModalContactUs.title' });
  const phoneMessage = intl.formatMessage({ id: 'ModalContactUs.phoneMessage' });
  const phoneNumber = config.custom.supportPhoneNumber;
  const phoneNumberReadable = config.custom.supportPhoneNumberReadable;
  const startSupportHours = config.custom.supportHours.start; 
  const endSupportHours = config.custom.supportHours.end; 
  const helperMessageTop = intl.formatMessage(
    { id: 'ModalContactUs.helperMessageTop' },
    {
      start: startSupportHours,
      end: endSupportHours,
    });
  const helperMessageBottom = intl.formatMessage({ id: 'ModalContactUs.helperMessageBottom' });
  
  return (
    <Modal
      id='ModalContactUs'
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <span className={css.title}>{title}</span>
      <span className={css.contactUs}>{phoneMessage}</span>
      <ExternalLink className={css.contactButton} href={`tel:${phoneNumber}`} >
        <span className={css.buttonText}>{phoneNumberReadable}</span>
        <IconPhone className={css.buttonIcon} />
      </ExternalLink>
      <div className={css.helperContainer}>
        <span>{helperMessageTop}</span>
        <span>{helperMessageBottom}</span>
      </div>
    </Modal>
  );
};

ModalContactUs.defaultProps = {
  isOpen: false,
};

ModalContactUs.propTypes = {
  isOpen: bool,
  onCloseModal: func,
  intl: intlShape.isRequired,
};

export default injectIntl(ModalContactUs);
