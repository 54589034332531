import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import classNames from 'classnames';

import { richText } from '../../../util/richText';
import { maskContactDetails } from '../../../util/contactDetails';
import { ExpandableText } from '../../../components';

import css from './SectionDescription.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;
const DESCRIPTION_MAX_LENGTH = 360;

const SectionDescription = props => {
  const {
    className,
    titleClassName,
    description,
    intl,
  } = props;
  const formattedText = richText(maskContactDetails(description, intl), {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
    longWordClass: css.longWord,
  });
  const titleText = intl.formatMessage({ id: 'ListingPage.descriptionTitle' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <h2 className={titleClassName}>{titleText}</h2>
      <ExpandableText className={css.description} text={formattedText} length={DESCRIPTION_MAX_LENGTH} />
    </div>
  );
};

SectionDescription.defaultProps = {
  className: null,
}

SectionDescription.propTypes = {
  className: string,
  titleClassName: string.isRequired,
  description: string.isRequired,
  intl: intlShape.isRequired,
}

export default SectionDescription;
