
import axios from 'axios';
import { path } from 'ramda';

import config from '../../config';
import { getUserToken } from '../../ducks/user.duck';

export const sendDeclineBookingsOnSameTimeslot = async (transactionId) => {
  if (!config.bookingsServiceUri) return ;

  const url = `${config.bookingsServiceUri}/api/accept`;
  const token = path(['access_token'], getUserToken());
  try {
    return await axios({
      method: 'POST',
      url,
      headers: {
        "authorization": `Bearer ${token}`,
        "content-type": "application/x-www-form-urlencoded",
      },
      data: `transactionId=${transactionId}`
    });
  } catch (e) {
    console.error(e);
  }
};

export const acceptPrivilegedIfInstantBookingType = async (transactionId) => {
  if (!config.bookingsServiceUri) return;

  try {
    const url = `${config.bookingsServiceUri}/api/accept-privileged`;
    await axios({
      method: 'POST',
      url,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      data: `transactionId=${transactionId}`
    });
  } catch (e) {
    console.error(e);
  }
};
