import React from 'react';
import { array, object, string } from 'prop-types';
import classNames from 'classnames';
import { length } from 'ramda';

import {
  IconAttachMoney,
  IconWarning
} from '../../../../../components/Icons';

import {
  dayIsAvailableInExceptions,
  dayIsAvailableInPlan,
  dayIsUnavailableInExceptions,
  getDayExceptions,
  getDayTransactions,
  isOutsideRange
} from '../../../CalendarPage.helpers';
import css from './DayContent.module.css';

const DayContent = (props) => {
  const {
    className,
    availabilityExceptions,
    availabilityPlan,
    day,
    transactions
  } = props;

  const dayExceptions = getDayExceptions(day, availabilityExceptions);
  const hasExceptions = length(dayExceptions) > 0;
  const dayTransactions = getDayTransactions(day, transactions);
  const hasTransactions = length(dayTransactions) > 0;
  const showCustom = !isOutsideRange(day);
  const classes = classNames(css.root, className, {
    [css.unavailable]: showCustom && (!dayIsAvailableInPlan(day, availabilityPlan) || dayIsUnavailableInExceptions(dayExceptions)),
    [css.availableException]: showCustom && dayIsAvailableInExceptions(dayExceptions),
  });

  return (
    <div className={classes}>
      <span className={css.date}>{day.date()}</span>
      {(hasExceptions || hasTransactions) && (
        <div className={css.iconWrapper}>
          {hasTransactions && <IconAttachMoney className={css.icon} />}
          {hasExceptions && <IconWarning className={css.icon} />}
        </div>
      )}
    </div>
  )
}

DayContent.defaultProps = {
  className: null,
};

DayContent.propTypes = {
  className: string,
  availabilityExceptions: array.isRequired,
  availabilityPlan: object.isRequired,
  transactions: array.isRequired
};

export default DayContent;