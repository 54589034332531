import React from 'react';
import { func, object, string, bool } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { booleanValuesToStrings, ensureOwnListing, stringValuesToBooleans } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingPoliciesForm } from '../../forms';

import css from './EditListingPoliciesPanel.module.css';

const EditListingPoliciesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;
  const handleSubmit = (values) => {
    const formattedValues = stringValuesToBooleans(values);
    const {
      policyAlcohol,
      policyEvent,
      policyMusic,
      policyPets,
      policySmoking,
      rules = '',
      suitableForChildren: enfantsBienvenue,
    } = formattedValues;
    const updatedValues = {
      publicData: {
        policyAlcohol,
        policyEvent,
        policyMusic,
        policyPets,
        policySmoking,
        rules: rules,
        enfantsBienvenue,
      },
    };
    onSubmit(updatedValues);
  };
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const {
    policyAlcohol,
    policyEvent,
    policyMusic,
    policyPets,
    policySmoking,
    enfantsBienvenue: suitableForChildren,
    rules,
  } = publicData;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPoliciesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPoliciesPanel.createListingTitle" />
  );
  const currentValues = {
    policyAlcohol,
    policyEvent,
    policyMusic,
    policyPets,
    policySmoking,
    rules,
    suitableForChildren,
  };
  const initialValues = booleanValuesToStrings(currentValues);

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingPoliciesForm
        className={css.form}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingPoliciesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPoliciesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPoliciesPanel;
