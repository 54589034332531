import React from 'react';
import { string } from 'prop-types';
import { intlShape } from 'react-intl';
import { path, pathOr } from 'ramda';
import classNames from 'classnames';

import { IconBolt } from '../../../components/Icons';
import { Emoji } from '../../../components';

import HostAvatar from './HostAvatar/HostAvatar';
import css from './SectionHeading.module.css';

const SectionHeading = props => {
  const {
    className,
    titleClassName,
    intl,
    listing,
    maxCapacity,
    params,
    user,
  } = props;
  const city = pathOr(null, ['attributes', 'publicData', 'location', 'city'], listing);
  const instantBookingFrom = path(['attributes', 'publicData', 'instantBookingFrom'], listing);
  const cityText = intl.formatMessage({ id: 'ListingPage.cityText' }, { city: city });
  const capacityText = intl.formatMessage({ id: 'ListingPage.capacityText' }, { max: maxCapacity });
  const instantBookingText = intl.formatMessage({ id: 'ListingPage.instantBooking' }, { amount: instantBookingFrom });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <div className={css.listingInfosContainer}>
        {city && <span className={titleClassName}>{cityText}</span>}
        <div className={css.priceContainer}>
        </div>
        <div className={css.capacityContainer}>
          <Emoji className={css.emoji} symbol={0x1F46A} />
          <span className={css.capacityText}>{capacityText}</span>
        </div>
        {instantBookingFrom && (
          <div className={css.instantBookingWrapper}>
            <IconBolt className={css.iconBolt} />
            <span className={css.instantBookingText}>{instantBookingText}</span>
          </div>
        )}
      </div>
      <HostAvatar className={css.hostAvatar} params={params} user={user} />
    </div>
  );
};

SectionHeading.defaultProps = {
  className: null,
}

SectionHeading.propTypes = {
  className: string,
  titleClassName: string.isRequired,
  intl: intlShape.isRequired,
}

export default SectionHeading;
