import React from 'react';
import { node, oneOfType, string } from 'prop-types';
import classNames from 'classnames';

import css from './ModalHeader.module.css';

const ModalHeader = (props) => {
  const { className, children, heading } = props;
  const classes = classNames(css.root, className);

  return (
    <header className={classes}>
      {heading && <h1 className={css.heading}>{heading}</h1>}
      {children}
    </header>
  )
};

ModalHeader.defaultProps = {
  className: null,
  heading: null,
};

ModalHeader.propTypes = {
  className: string,
  heading: oneOfType([node, string]),
};

export default ModalHeader;