import { pathOr } from 'ramda';

// ================ Action types ================ //

export const SET_ENQUIRY_DATE = 'app/PoolsProposition/SET_ENQUIRY_DATE';

export const SET_POOL_PROPOSITION_ENQUIRED = 'app/PoolsProposition/SET_POOL_PROPOSITION_ENQUIRED';

// ================ Reducer ================ //

const initialState = {
  enquiryDates: {},
  enquired: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_ENQUIRY_DATE: {
      console.log(payload.date)
      return {
        ...state,
        enquiryDates: {
          ...state.enquiryDates,
          [payload.txId]: payload.date,
        }
      };
    }

    case SET_POOL_PROPOSITION_ENQUIRED: {
      const asKey = pathOr(null, ['enquired', payload.enquiryDate], state);
      if (asKey) {
        return {
          ...state,
          enquired: {
            ...state.enquired,
            [payload.enquiryDate]: [...state.enquired[payload.enquiryDate], payload.listingId],
          },
        };
      } else {
        return {
          ...state,
          enquired: {
            ...state.enquired,
            [payload.enquiryDate]: [payload.listingId],
          },
        };
      }
    }

    default:
      return state;
  }
};

// ================ Action creators ================ //

export const setEnquiryDate = data => ({
  type: SET_ENQUIRY_DATE,
  payload: data,
});

export const setPoolPropositionEnquired = data => ({
  type: SET_POOL_PROPOSITION_ENQUIRED,
  payload: data,
});
