import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactPlayer from 'react-player/youtube';

import css from './WhoWeArePage.css';

const SectionVideoPresentation = (props) => {
  const { intl } = props;
  const videoNote = intl.formatMessage({ id : "WhoWeArePage.videoNote" });

  return (
    <section className={css.section}>
      <div className={css.videoContent}>
        <div className={css.videoTitle}>
          <FormattedMessage id='WhoWeArePage.videoTitle' values={{ lineBreak: <br /> }} />
        </div>
        <div className={css.videoContainer}>
          <div className={css.videoWrapper}>
            <ReactPlayer
              className={css.video}
              url='https://youtu.be/nT0xXlx0USo'
              light
              height='100%'
              width='100%'
              controls
              config={{
                youtube: {
                  playerVars: {
                    autoplay: 1,
                    modestbranding: 1,
                    rel: 0
                  }
                }
              }}
            />
          </div>
        </div>
        <span className={css.videoNote}>{videoNote}</span>
      </div>
    </section>
  );
}

export default injectIntl(SectionVideoPresentation);