import { addIndex, flatten, map } from 'ramda';

import { storableError } from '../../util/errors';
import { TRANSITIONS_BOOKED } from '../../util/transactions';

// ================ Action types ================ //

export const FETCH_BOOKED_SALES_REQUEST = 'app/IncomePage/FETCH_BOOKED_SALES_REQUEST';
export const FETCH_BOOKED_SALES_SUCCESS = 'app/IncomePage/FETCH_BOOKED_SALES_SUCCESS';
export const FETCH_BOOKED_SALES_ERROR = 'app/IncomePage/FETCH_BOOKED_SALES_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchBookedSalesError: null,
  bookedSales: [],
  pagination: null,
};
  
export default function incomePageReducer(state = initialState, action = {}) {
  const { type, payload, bookedSales } = action;
  switch (type) {
    case FETCH_BOOKED_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchBookedSalesError: null };
    case FETCH_BOOKED_SALES_SUCCESS:
      return {
        ...state,
        fetchInProgress: false,
        bookedSales,
        pagination: payload.data.meta,
      };
    case FETCH_BOOKED_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchBookedSalesError: payload };

    default:
      return state;
  };
};

// ================ Action creators ================ //

const fetchBookedSalesRequest = () => ({ type: FETCH_BOOKED_SALES_REQUEST });
const fetchBookedSalesSuccess = (response, bookedSales) => ({
  type: FETCH_BOOKED_SALES_SUCCESS,
  payload: response,
  bookedSales,
});
const fetchBookedSalesError = e => ({
  type: FETCH_BOOKED_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const BOOKED_SALES_PAGE_SIZE = 100;

const fetchAllBookedSales = async (sdk, totalPages) => {
  try  {
    const mapIndexed = addIndex(map);
    const bookedSales = await Promise.all(mapIndexed(async (val, i) => {
      const apiQueryParams = {
        only: "sale",
        lastTransitions: TRANSITIONS_BOOKED,
        'fields.transaction': [
          'transitions',
          'payoutTotal',
        ],
        page: i + 1,
        per_page: BOOKED_SALES_PAGE_SIZE,
      };
      const response = await sdk.transactions.query(apiQueryParams);

      return response.data.data;
    }, new Array(totalPages)));
    
    return flatten(bookedSales);
  }
  catch (e) {
    throw e;
  }
};

export const loadData = () => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchBookedSalesRequest());

    const apiQueryParams = {
      only: "sale",
      lastTransitions: TRANSITIONS_BOOKED,
      'fields.transaction': [],
      per_page: BOOKED_SALES_PAGE_SIZE,
    };

    const response = await sdk.transactions.query(apiQueryParams);
    const bookedSales = await fetchAllBookedSales(sdk, response.data.meta.totalPages)
    dispatch(fetchBookedSalesSuccess(response, bookedSales));
  }
  catch (e) {
    dispatch(fetchBookedSalesError(storableError(e)));
    throw e;
  }
};
