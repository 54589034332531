import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { func, string } from 'prop-types';
import classNames from 'classnames';

import css from './styles.css';

class UploadFileField extends PureComponent {
  constructor(props) {
    super(props);
    const { getFileDocumentName } = props;

    this.state = {
      fileDocumentName: getFileDocumentName(),
    };
  }

  handleOnChangeFileDocument = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    const { handleChangeDocumentFile } = this.props;

    this.setState({ fileDocumentName: file.name });
    handleChangeDocumentFile(file);
  };

  getChoosePhotoTextComponent = () => {
    const { fileDocumentName } = this.state;
    const { fileDescription, name } = this.props;
    const isCNI = name === "documentCNI";
    if (!fileDocumentName) {
      return (
        <span className={css.choosePhotoText}>
        <span className={css.choosePhotoText}>
          <FormattedMessage id={fileDescription}/>
        </span>
        <span className={css.imageTypes}>
          <FormattedMessage id={isCNI ? "UploadCNIField.imageTypes" : "UploadProofOfAddressField.imageTypes"}/>
        </span>
      </span>
      );
    }

    return (
      <span className={css.choosePhotoText}>
        {fileDocumentName}
      </span>
    );
  };

  render() {
    const { label, name, warningContent, className, acceptedFormat } = this.props;

    return (
      <div className={classNames(className, css.field, css.changeCNIField)}>
        <label>{label}</label>
        {warningContent &&
          <div className={css.cautionMessage}>
            {warningContent}
          </div>
        }
        <div className={css.addCNIWrapper}>
          <div className={css.aspectRatioWrapper}>
            <input
              accept={acceptedFormat}
              name={name}
              id={name}
              type="file"
              onChange={this.handleOnChangeFileDocument}
              className={css.addCNIInput}
            />
            <label htmlFor={name} className={css.addCNI}>
              {this.getChoosePhotoTextComponent()}
            </label>
          </div>
        </div>
      </div>
    );
  }
}

UploadFileField.propTypes = {
  handleChangeDocumentFile: func.isRequired,
  getFileDocumentName: func.isRequired,
  name: string.isRequired,
  label: string.isRequired,
  warningContent: string,
  acceptedFormat: string,
};

export default UploadFileField;
