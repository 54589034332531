import React, { Fragment, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import { isServer } from '../../../../util/sdkLoader';
import { Button, CookieConsent } from '../../../../components';

import css from './CookiesTab.module.css';

const CookiesTab = props => {
  const { intl } = props;
  const [showCookieSettings, setShowCookieSettings] = useState(false);
  const handleClick = () => {
    setShowCookieSettings(prev => !prev);
  };
  const buttonText = intl.formatMessage({ id: 'CookiesTab.updateConsent' })

  return (
    <Fragment>
      <Button onClick={handleClick}>
        <span>{buttonText}</span>
      </Button>
      {!isServer() && showCookieSettings && (
        <CookieConsent className={css.cookieModal} onClose={handleClick} intl={intl} showSettings />
      )}
    </Fragment>
  )
};

CookiesTab.defaultProps = {
};

CookiesTab.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CookiesTab);
