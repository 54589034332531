import React from 'react';
import { func, object, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { path, pathOr } from 'ramda';

import { propTypes } from '../../../util/types';
import { fetchTimeSlots } from '../../../ducks/listing.duck';

import ContactForm from './ContactForm/ContactForm';
import css from './EnquiryPanel.module.css';

const TODAY = new Date();

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const EnquiryPanel = (props) => {
  const {
    className,
    intl,
    listing,
    monthlyTimeSlots,
    onFetchTimeSlots
  } = props;
  const formInitialValues = {

  };
  const availabilityPlan = path(['attributes', 'availabilityPlan'], listing)
  const timeZone = availabilityPlan && availabilityPlan.timezone;
  const listingPublicData = pathOr({}, ['attributes', 'publicData'], listing);
  const listingAuthor = pathOr({}, ['author'], listing);
  const headingText = intl.formatMessage({ id: 'ContactPage.EnquiryPanel.panelHeading' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <h1 className={css.heading}>{headingText}</h1>
      <ContactForm        
        className={css.contactForm}
        author={listingAuthor}
        formId="contactForm"
        initialValues={formInitialValues}
        intl={intl}
        publicData={listingPublicData}
        submitButtonWrapperClassName={css.submitButtonWrapper}
        listingId={listing.id}
        monthlyTimeSlots={monthlyTimeSlots}
        onFetchTimeSlots={onFetchTimeSlots}
        startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
        endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
        timeZone={timeZone}
      />
    </div>
  )
}

EnquiryPanel.defaultProps = {
  className: null,
  monthlyTimeSlots: null,
};

EnquiryPanel.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
};

const mapStateToProps = (state) => {
  const { monthlyTimeSlots } = state.listing;

  return {
    monthlyTimeSlots,
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnquiryPanel);