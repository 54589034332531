import { path, pathOr } from 'ramda';

import { storableError } from '../../util/errors';
import { denormalisedResponseEntities } from '../../util/data';
import config from '../../config';
import { parse } from '../../util/urlHelpers';

// ================ Action types ================ //

export const FETCH_REVIEW_REQUEST = 'app/LandingPage/FETCH_REVIEW_REQUEST';
export const FETCH_REVIEW_SUCCESS = 'app/LandingPage/FETCH_REVIEW_SUCCESS';
export const FETCH_REVIEW_ERROR = 'app/LandingPage/FETCH_REVIEW_ERROR';

export const SET_TRACKING_DATA_SESSION = 'app/LandingPage/SET_TRACKING_DATA_SESSION';

// ================ Reducer ================ //

const initialState = {
  reviews: [],
  fetchReviewsInProgress: false,
  fetchReviewsError: null,
};

export default function LandingPageReducer(state = initialState, action = {}) {
  const { type, payload, trackingPayload } = action;
  switch (type) {

    case FETCH_REVIEW_REQUEST:
      return { ...state, fetchReviewsInProgress: true, fetchReviewsError: null };
    case FETCH_REVIEW_SUCCESS: {
      const { reviews } = payload;
      return { ...state, fetchReviewsInProgress: false, reviews };
    }
    case FETCH_REVIEW_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchReviewsInProgress: false, fetchReviewsError: payload };
    case SET_TRACKING_DATA_SESSION:
      return { ...state, trackingPayload };

    default:
      return state;
  }
}

// ================ Action creators ================ //
const setTrackingDataSessionSuccess = trackingPayload => ({ type: SET_TRACKING_DATA_SESSION, trackingPayload });

const fetchReviewRequest = () => ({ type: FETCH_REVIEW_REQUEST });
const fetchReviewSuccess = response => ({
  type: FETCH_REVIEW_SUCCESS,
  payload: response,
});
const fetchReviewError = e => ({ type: FETCH_REVIEW_ERROR, error: true, payload: e });

const mapReviewsFromSheet = async (reviewFromSheet, sdk) => {
  const review = await sdk.reviews.show({
    id: reviewFromSheet.id,
    include: ['author', 'author.profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  });
  const listing = await sdk.listings.show({
    id: reviewFromSheet.listingId,
  });
  const { title } = pathOr({}, ['data', 'data', 'attributes'], listing);
  const entities = denormalisedResponseEntities(review);
  const { content, createdAt, rating } = pathOr({}, [0, 'attributes'], entities);

  return {
    review: {
      content,
      createdAt,
      rating,
      id: reviewFromSheet.id,
    },
    author: pathOr({}, [0, 'author'], entities),
    listing: {
      title,
      id: reviewFromSheet.listingId,
    }
  }
};

export const fetchReviews = () => async (dispatch, getState, sdk) => {
  dispatch(fetchReviewRequest());

  try {
    if (!config.appsScriptUrlToGetReviews) return ;
    const result = await fetch(config.appsScriptUrlToGetReviews, { method: 'GET' });
    const reviewsFromSheet = await result.json();
    const reviews = reviewsFromSheet.map((reviewFromSheet) => mapReviewsFromSheet(reviewFromSheet, sdk));

    return Promise.all(reviews).then((reviewsCompleted) => dispatch(fetchReviewSuccess({ reviews: reviewsCompleted })));
  }
  catch (e) {
    dispatch(fetchReviewError(storableError(e)));
    throw e;
  }
};

export const setTrackingDataSession = () => async (dispatch, getState, sdk) => {
  try {
    if (!window || !window.location) return;

    const query = parse(window.location.href);
    const { utm_term } = query;
    if (!utm_term) return;

    dispatch(setTrackingDataSessionSuccess(query))
  }
  catch (e) {
  }
};
