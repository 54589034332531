import React from 'react';
import { bool, func, string, object } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { propTypes } from '../../util/types';
import {
  required,
} from '../../util/validators';
import {
  Form,
  FieldSelect,
  FieldTextInput,
  PrimaryButton,
} from '../../components';
import css from './EditListingInformationForm.module.css';
import config from '../../config';

const EditListingInformationFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        ready,
        handleSubmit,
        intl,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        values,
        fetchErrors,
        invalid,
      } = formRenderProps;
      if (values.poolTreatment !== 'other') {
        delete values.poolTreatmentOther;
      };
      const requiredMessage = intl.formatMessage({ id: 'Marketplace.requiredMessage' });
      const providerPresentLabel = intl.formatMessage({ id: 'EditListingInformationForm.providerPresentLabel' });
      const providerPresentInformation = intl.formatMessage({ id: 'EditListingInformationForm.providerPresentInformation' });
      const poolTreatmentLabel = intl.formatMessage({ id: 'EditListingInformationForm.poolTreatmentLabel' });
      const chlorine = intl.formatMessage({ id: 'EditListingInformationForm.chlorine' });
      const salt = intl.formatMessage({ id: 'EditListingInformationForm.salt' });
      const bromine = intl.formatMessage({ id: 'EditListingInformationForm.bromine' });
      const otherText = intl.formatMessage({ id: 'EditListingInformationForm.other' });
      const yes = intl.formatMessage({ id: 'Marketplace.yes' });
      const no = intl.formatMessage({ id: 'Marketplace.no' });
      const selectPlaceholder = intl.formatMessage({ id: 'EditListingInformationForm.selectPlaceholder' });
      const treatmentOtherLabel = intl.formatMessage({ id: 'EditListingInformationForm.poolTreatmentOtherLabel' });
      const treatmentOtherPlaceholder = intl.formatMessage({ id: 'EditListingInformationForm.poolTreatmentOtherPlaceholder' });
      const {providerPresentOptions} = config.custom;
      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingInformationForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="Marketplace.showListingFailed" />
        </p>
      ) : null;
      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <FieldSelect
            className={css.featuresItem}
            id="providerPresent"
            name="providerPresent"
            label={providerPresentLabel}
            informationTooltipContent={providerPresentInformation}
            validate={required(requiredMessage)}
          >
            <option value="">{selectPlaceholder}</option>
            <option value="oui">{yes}</option>
            <option value="non">{no}</option>
            <option value="occasionnellement">{providerPresentOptions[2].label}</option>
          </FieldSelect>
          <FieldSelect
            className={css.poolTreatment}
            id="poolTreatment"
            name="poolTreatment"
            label={poolTreatmentLabel}
            validate={required(requiredMessage)}
          >
            <option value="">{selectPlaceholder}</option>
            <option value="chlorine">{chlorine}</option>
            <option value="salt">{salt}</option>
            <option value="bromine">{bromine}</option>
            <option value="other">{otherText}</option>
          </FieldSelect>
          {values.poolTreatment === 'other' ? (
            <FieldTextInput
              className={css.poolTreatmentOther}
              id="poolTreatmentOther"
              name="poolTreatmentOther"
              label={treatmentOtherLabel}
              placeholder={treatmentOtherPlaceholder}
              type="textarea"
              validate={required(requiredMessage)}
            />
          ) : null}
          <div className={css.buttonContainer}>
            <PrimaryButton
              className={css.submitButton}
              type='submit'
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

EditListingInformationFormComponent.defaultProps = {
  className: null,
  updateError: null,
  initialValues: {},
};

EditListingInformationFormComponent.propTypes = {
  className: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  initialValues: object,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EditListingInformationForm = EditListingInformationFormComponent;

export default injectIntl(EditListingInformationForm);
