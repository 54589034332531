import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSearch.css';

const IconSearch = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2917 15.0417C6.79386 15.0417 3.95833 12.2061 3.95833 8.70833C3.95833 5.21053 6.79386 2.375 10.2917 2.375C13.7895 2.375 16.625 5.21053 16.625 8.70833C16.625 12.2061 13.7895 15.0417 10.2917 15.0417Z" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.37498 16.625L5.81873 13.1813" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  );
};

IconSearch.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconSearch.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSearch;
