import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { FieldCheckbox } from '../../../components';

import css from './OneTimePaymentWithCardElement.module.css';

const OneTimePaymentWithCardElement = props => {
  const { cardClasses, formId, handleStripeElementRef, hasCardError, error, label, intl } = props;
  const labelText = label || intl.formatMessage({ id: 'StripePaymentForm.saveAfterOnetimePayment' });

  return (
    <Fragment>
      <label className={css.paymentLabel} htmlFor={`${formId}-card`}>
        <FormattedMessage id="StripePaymentForm.paymentCardDetails" />
      </label>
      <div className={cardClasses} id={`${formId}-card`} ref={handleStripeElementRef} />
      {hasCardError ? <span className={css.error}>{error}</span> : null}
      <div className={css.saveForLaterUse}>
        <FieldCheckbox
          className={css.saveForLaterUseCheckbox}
          textClassName={css.saveForLaterUseLabel}
          id="saveAfterOnetimePayment"
          name="saveAfterOnetimePayment"
          label={labelText}
          value="saveAfterOnetimePayment"
        />
        <span className={css.saveForLaterUseLegalInfo}>
          <FormattedMessage id="StripePaymentForm.saveforLaterUseLegalInfo" />
        </span>
      </div>
    </Fragment>
  );
};

export default OneTimePaymentWithCardElement;
