import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { map } from 'ramda';

import { stringToBoolean } from '../../util/data';
import {
  IconArrow,
  NamedLink,
  PrimaryButton,
  SecondaryButton,
  Switch,
} from '../../components';

import css from './CookieConsent.css';

const CookieItem = props => {
  const { cookie } = props;
  const { name, value, handler } = cookie;

  return (
    <li className={css.cookieItem}>
      <span className={css.paragraph}>{name}</span>
      <Switch value={value} onClick={handler} />
    </li>
  )
};

const CookieSection = props => {
  const { title, infoText, cookies } = props;
  
  return (
    <section className={css.cookieSection}>
      <h2 className={css.sectionTitle}>{title}</h2>
      <p className={css.paragraph}>{infoText}</p>
      <ul className={css.cookieList}>
        {map((cookie) => (
          <CookieItem key={cookie.name} cookie={cookie} />
        ), cookies)}
      </ul>
    </section>
  )
};

const CookieConsent = props => {
  const { className, rootClassName, onClose, showSettings, intl } = props;

  const cookies = document.cookie.split('; ').reduce((acc, c) => {
    const [name, value] = c.split('=');
    return { ...acc, [name]: decodeURIComponent(value) };
  }, {});
  const [show, setShow] = useState(showSettings || cookies.swimmy_consentGiven !== 'true');
  
  const [showCustom, setShowCustom] = useState(showSettings);
  const [googleAnalytics, setGoogleAnalytics] = useState(false);
  const [criteo, setCriteo] = useState(false);
  const [hotjar, setHotjar] = useState(false);
  const [affilae, setAffilae] = useState(false);
  useEffect(() => {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});
    setCriteo(stringToBoolean(cookies.swimmy_criteoAccepted) || false);
    setHotjar(stringToBoolean(cookies.swimmy_hotjarAccepted) || false);
    setAffilae(stringToBoolean(cookies.swimmy_affilaeAccepted) || false);
  }, []);
  
  if (!show) return null;
  
  const date = new Date();
  const expirationDate = new Date(date.setMonth(date.getMonth() + 6));
  const dateRemove = new Date(date.setFullYear(2020));

  const onAcceptAllCookies = () => {
    document.cookie = 'swimmy_googleAccepted=; path=/; expires=' + dateRemove.toGMTString();
    document.cookie = 'swimmy_hotjarAccepted=true; path=/; expires=' + expirationDate.toGMTString();
    document.cookie = 'swimmy_criteoAccepted=true; path=/; expires=' + expirationDate.toGMTString();
    document.cookie = 'swimmy_affilaeAccepted=true; path=/; expires=' + expirationDate.toGMTString();
    document.cookie = 'swimmy_consentGiven=true; path=/; expires=' + expirationDate.toGMTString();

    setShow(false);
  };

  const handleCustomClick = () => {
    if (onClose) return onClose();

    setShowCustom(prev => !prev);
  }

  const onAcceptCustomCookies = () => {
    document.cookie = 'swimmy_googleAccepted=; path=/; expires=' + dateRemove.toGMTString();
    document.cookie = 'swimmy_hotjarAccepted=' + hotjar + '; path=/; expires=' + expirationDate.toGMTString();
    document.cookie = 'swimmy_criteoAccepted=' + criteo + '; path=/; expires=' + expirationDate.toGMTString();
    document.cookie = 'swimmy_affilaeAccepted=' + affilae + '; path=/; expires=' + expirationDate.toGMTString();
    document.cookie = 'swimmy_consentGiven=true; path=/; expires=' + expirationDate.toGMTString();
    
    setShow(false);
    if (onClose) {
      onClose();
    }
  };

  const handleCriteo = () => {
    setCriteo(prev => !prev);
  };
  const handleHotjar = () => {
    setHotjar(prev => !prev);
  };
  const handleAffilae = () => {
    setAffilae(prev => !prev);
  };

  const privacyLink = (
    <NamedLink name='PrivacyPolicyPage' className={css.privacyLink}>
      <FormattedMessage id="CookieConsent.privacyLink" />
    </NamedLink>
  );
  
  const mainTitle = intl.formatMessage({ id: 'CookieConsent.mainTitle' });
  const buttonCustomText = intl.formatMessage({ id: 'CookieConsent.customCookies' });
  const buttonAcceptText = intl.formatMessage({ id: 'CookieConsent.acceptCookies' });
  const buttonAcceptCustomText = intl.formatMessage({ id: 'CookieConsent.acceptCustomCookies' });
  const customTitle = intl.formatMessage({ id: 'CookieConsent.customTitle' });
  const explanationText = intl.formatMessage({ id: 'CookieConsent.explanation' });
  const mandatoryTitle = intl.formatMessage({ id: 'CookieConsent.mandatoryTitle' });
  const mandatoryText = intl.formatMessage({ id: 'CookieConsent.mandatory' });
  const optionalTitle = intl.formatMessage({ id: 'CookieConsent.optionalTitle' });
  const optionalText = intl.formatMessage({ id: 'CookieConsent.optional' });

  const cookieDictionary = [
    {
      title: mandatoryTitle,
      infoText: mandatoryText,
      cookies: [
        {
          name: 'Swimmy',
          value: true,
        },
        {
          name: 'Stripe',
          value: true,
        },
        {
          name: 'Sharetribe',
          value: true,
        },
        {
          name: 'Cloudflare',
          value: true,
        },
        {
          name: 'Google Analytics',
          value: true,
        },
      ],
    },
    {
      title: optionalTitle,
      infoText: optionalText,
      cookies: [
        {
          name: 'Hotjar',
          value: hotjar,
          handler: handleHotjar,
        },
        {
          name: 'Criteo',
          value: criteo,
          handler: handleCriteo,
        },
        {
          name: 'Affilae',
          value: affilae,
          handler: handleAffilae,
        }
      ],
    },
  ]
  
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {!showCustom ? (
        <div className={css.container}>
          <div className={css.titleContainer}>
            <h2 className={css.mainTitle}>{mainTitle}</h2>
            <p className={css.paragraph}>
              <FormattedMessage id="CookieConsent.message" values={{ privacyLink }} />
            </p>
          </div>
          <div className={css.buttonsContainer}>
            <SecondaryButton className={css.buttonCustomCookies} onClick={handleCustomClick}>{buttonCustomText}</SecondaryButton>
            <PrimaryButton className={css.buttonAcceptCookies} onClick={onAcceptAllCookies}>{buttonAcceptText}</PrimaryButton>
          </div>
        </div>
      ) : (
        <div className={css.customContainer}>
          <div className={css.topContainer}>
            <button className={css.backButton} onClick={handleCustomClick}>
              <IconArrow className={css.iconArrow} direction='left' />
            </button>
            <span className={css.title}>{customTitle}</span>
          </div>
          <div className={css.content}>
            <p className={css.paragraph}>{explanationText}</p>
            {map(section => (
                <CookieSection
                  key={section.title}
                  title={section.title}
                  infoText={section.infoText}
                  cookies={section.cookies}
                />
              ), cookieDictionary)}
          </div>
          <div className={css.bottomContainer}>
            <PrimaryButton onClick={onAcceptCustomCookies}>{buttonAcceptCustomText}</PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
}

const { string } = PropTypes;

CookieConsent.defaultProps = {
  className: null,
  rootClassName: null,
};

CookieConsent.propTypes = {
  className: string,
  rootClassName: string,

  intl: intlShape.isRequired,
};

export default injectIntl(CookieConsent);
