import React from 'react';
import { injectIntl } from 'react-intl';

import { Card } from '../../components';
import raphaelleImg from '../../assets/team-swimmy-raphaelle.png';
import raidouaneImg from '../../assets/team-swimmy-raidouane.png';
import thibautImg from '../../assets/team-swimmy-thibaut.png';
import diegoImg from '../../assets/team-swimmy-diego.png';

import css from './WhoWeArePage.css';

const SectionTeamSwimmy = (props) => {
  const { intl } = props;
  const raphaelleTitle = intl.formatMessage({ id : "WhoWeArePage.teamRaphaelleTitle" });
  const raphaelleSubtitle = intl.formatMessage({ id : "WhoWeArePage.teamRaphaelleSubtitle" });
  const raphaelleText = intl.formatMessage({ id : "WhoWeArePage.teamRaphaelleText" });
  const diegoTitle = intl.formatMessage({ id : "WhoWeArePage.teamDiegoTitle" });
  const diegoSubtitle = intl.formatMessage({ id : "WhoWeArePage.teamDiegoSubtitle" });
  const diegoText = intl.formatMessage({ id : "WhoWeArePage.teamDiegoText" });
  const raidouaneTitle = intl.formatMessage({ id : "WhoWeArePage.teamRaidouaneTitle" });
  const raidouaneSubtitle = intl.formatMessage({ id : "WhoWeArePage.teamRaidouaneSubtitle" });
  const raidouaneText =  intl.formatMessage({ id : "WhoWeArePage.teamRaidouaneText" });
  const thibautTitle = intl.formatMessage({ id : "WhoWeArePage.teamThibautTitle" });
  const thibautSubtitle = intl.formatMessage({ id : "WhoWeArePage.teamThibautSubtitle" });
  const thibautText = intl.formatMessage({ id : "WhoWeArePage.teamThibautText" });

  return (
    <section className={css.section}>
      <div className={css.teamContent}>
        <Card
          className={css.teamCard}
          imgClassName={css.teamCardImg}
          titleClassName={css.teamCardTitle}
          img={raphaelleImg}
          title={raphaelleTitle}
          subtitle={raphaelleSubtitle}
          text={raphaelleText}
        />
        <Card
          className={css.teamCard}
          imgClassName={css.teamCardImg}
          titleClassName={css.teamCardTitle}
          img={diegoImg}
          title={diegoTitle}
          subtitle={diegoSubtitle}
          text={diegoText}
        />
        <Card
          className={css.teamCard}
          imgClassName={css.teamCardImg}
          titleClassName={css.teamCardTitle}
          img={raidouaneImg}
          title={raidouaneTitle}
          subtitle={raidouaneSubtitle}
          text={raidouaneText}
        />
        <Card
          className={css.teamCard}
          imgClassName={css.teamCardImg}
          titleClassName={css.teamCardTitle}
          img={thibautImg}
          title={thibautTitle}
          subtitle={thibautSubtitle}
          text={thibautText}
        />
      </div>
    </section>
  );
}

export default injectIntl(SectionTeamSwimmy);