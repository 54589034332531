import sueddeutscheImg from './images/sueddeutsche.svg';
import weltImg from './images/welt.svg';
import internetWorldImg from './images/internetWorld.svg';
import ganzHamburgImg from './images/ganzHamburg.png';
import wvImg from './images/w&v.svg';
import businessOnImg from './images/businessOn.png';
import generalAnzeigerImg from './images/generalAnzeiger.svg';
import ohMyMagImg from './images/ohMyMag.jpg';
import reiseLustImg from './images/reiseLust.png';
import urlaubsImg from './images/urlaubs.png';

export const pressPageProps = {
  links: [
    {
      imageUrl: sueddeutscheImg,
      imageAltText: 'Süddeutsche',
      linkProps: {
        type: 'ExternalLink',
        href: 'https://www.sueddeutsche.de/wirtschaft/pool-mieten-corona-1.5290220',
      },
      text: 'Garten statt Mallorca - Miet-Pool für alle. Viele Menschen träumen von einem eigenen Pool. Eine französische Firma hat daraus ein Geschäftsmodell gemacht: Wer einen Pool besitzt, kann ihn vermieten - und damit viel Geld verdienen.',
    },
    {
      imageUrl: weltImg,
      imageAltText: 'Welt',
      linkProps: {
        type: 'ExternalLink',
        href: 'https://www.welt.de/icon/partnerschaft/article230950661/High-5-Das-sind-die-Stil-Highlights-der-Woche.html',
      },
      text: 'Pools kann man neuerdings auch mieten, neue Schmuckstücke regen nicht nur zum Konsum, sondern auch zum Appetit an – und außerdem erscheint bald eine Serie, die Mode- und Partyhasen nicht verpassen sollten. Das sind die Stil-Highlights der Woche.',
    },
    {
      imageUrl: internetWorldImg,
      imageAltText: 'Internet World',
      linkProps: {
        type: 'ExternalLink',
        href: 'https://www.internetworld.de/plattformen/sharing-economy/jetzt-kommt-airbnb-swimmingpools-2659413.html',
      },
      text: 'Ein neues Start-up der Sharing Economy betritt den deutschen Markt: Ab sofort können auf der Plattform Swimmy private Swimmingpools vermietet und gebucht werden.',
    },
    {
      imageUrl: ganzHamburgImg,
      imageAltText: 'Ganz Hamburg',
      linkProps: {
        type: 'ExternalLink',
        href: 'https://www.ganz-hamburg.de/stadtleben/its-more-fun-to-share-deutschlandstart-fuer-swimmy-dem-airbnb-fuer-private-swimmingpools.html',
      },
      text: 'It’s more fun to share: Deutschlandstart für Swimmy, dem Airbnb für private Swimmingpools',
    },
    {
      imageUrl: wvImg,
      imageAltText: 'W&V',
      linkProps: {
        type: 'ExternalLink',
        href: 'https://www.wuv.de/tech/apple_baut_werbung_im_app_store_aus2',
      },
      text: 'Keine Lust auf volle Freibäder, wenn die öffentlichen Schwimmanlagen nach Eindämmung der Pandemie wieder öffnen? Dann lohnt ein Blick auf das neue Airbnb für Swimming-Pools. Während das große Vorbild Wohnungen auf Zeit vermietet, bietet Swimmy Pools in der Nähe zum Mieten an.',
    },
    {
      imageUrl: businessOnImg,
      imageAltText: 'Business On',
      linkProps: {
        type: 'ExternalLink',
        href: 'https://www.business-on.de/ruhr/sharing-plattform-fuer-private-swimmingpools.html',
      },
      text: 'Die Plattform Swimmy, die bereits seit 2017 Frankreich und seit 2019 Spanien auf dem Markt ist, bietet nun auch in Deutschland eine Poolvermietung zwischen Privatpersonen an.',
    },
    {
      imageUrl: generalAnzeigerImg,
      imageAltText: 'General Anzeiger',
      linkProps: {
        type: 'ExternalLink',
        href: 'https://ga.de/news/wirtschaft/regional/swimmy-start-up-vermittelt-private-pools-zur-miete_aid-58265685',
      },
      text: 'Das französische Startup Swimmy will Badevergnügen im heimischen Garten stundenweise vermieten.',
    },
    {
      imageUrl: ohMyMagImg,
      imageAltText: 'Oh! my mag',
      linkProps: {
        type: 'ExternalLink',
        href: 'https://www.ohmymag.de/tipp/plitsch-platsch-jetzt-kommt-das-neue-airbnb-fur-swimming-pools_art16489.html',
      },
      text: 'Eine neue Erfindung aus Frankreich soll uns nun dabei helfen, den Urlaub zu Hause noch luxuriöser zu gestalten.',
    },
    {
      imageUrl: reiseLustImg,
      imageAltText: 'ReiseLust',
      linkProps: {
        type: 'ExternalLink',
        href: 'https://www.dieneuereiselust.de/artikel-service-ansehen/die-neueste-innovation-der-sharing-economy-swimmy.html',
      },
      text: 'Ab sofort bietet die Plattform SWIMMY, die bereits in Frankreich und Spanien große Erfolge verbucht, auch in Deutschland eine Poolvermietung zwischen Privatpersonen an. So teilen künftig auch deutsche glückliche Poolbesitzer ihr Eigentum mit Urlaubern oder Einheimischen...',
    },
    {
      imageUrl: urlaubsImg,
      imageAltText: 'Urlaubs',
      linkProps: {
        type: 'ExternalLink',
        href: 'https://www.urlaubs-reisetipps.de/swimmingpool-sharing-privat--6355',
      },
      text: 'Poolbesitzer teilen Ihren privaten Pool mit Urlaubern oder Einheimischen. Swimmy, das Portal für die Swimmingpool-Vermietung.',
    },
  ],
};
