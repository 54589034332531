import React from 'react';
import { bool, object, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';

import { IconStar } from '../../../components/Icons';

import css from './ReviewsInfo.module.css';

const ReviewsInfo = (props) => {
  const {
    className,
    intl,
    isNewListing,
    reviewsInfo
  } = props;
  const averageRating = reviewsInfo.averageRating;
  const showNewText = isNewListing && averageRating < 4;
  const newText = intl.formatMessage({ id: 'ListingCard.newListing' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      {showNewText ? (
        <span className={css.new}>{newText}</span>
      ) : (
        <div className={css.averageRating}>{averageRating}</div>
      )}
      <IconStar className={css.iconStar} filled />
    </div>
  )
}

ReviewsInfo.defaultProps = {
  className: null,
};

ReviewsInfo.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  isNewListing: bool.isRequired,
  reviewsInfo: object.isRequired,
};

export default ReviewsInfo;
