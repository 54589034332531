import { length } from 'ramda';

import config from '../../config';

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/BlogPage/SET_INITIAL_VALUES';

export const GET_BLOG_PAGE_REQUEST = 'app/BlogPage/GET_BLOG_PAGE_REQUEST';
export const GET_BLOG_PAGE_SUCCESS = 'app/BlogPage/GET_BLOG_PAGE_SUCCESS';
export const GET_BLOG_PAGE_ERROR = 'app/BlogPage/GET_BLOG_PAGE_ERROR';


// ================ Reducer ================ //

const initialState = {
  isLoading: false,
  error: false,
  data: null,
};

const BlogPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case GET_BLOG_PAGE_REQUEST:
      return { ...state, isLoading: true, error: null };
    case GET_BLOG_PAGE_SUCCESS:
      return { ...state, isLoading: false, error: null, data: payload };
    case GET_BLOG_PAGE_ERROR:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
};

export default BlogPageReducer;

// ================ Action creators ================ //

export const getBlogPageRequest = slug => ({
  type: GET_BLOG_PAGE_REQUEST,
  payload: { slug },
});

export const getBlogPageSuccess = data => ({
  type: GET_BLOG_PAGE_SUCCESS,
  error: false,
  payload: data,
});

export const getBlogPageError = e => ({
  type: GET_BLOG_PAGE_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const getBlogPage = (slug) => async (dispatch, getState, sdk) => {
  dispatch(getBlogPageRequest(slug));
  const url = `${config.internalBlogUri}/${slug}`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
    });
    const data = await response.json();
    if (!length(data)) throw {
      status: 404,
      message: 'no data'
    };

    dispatch(getBlogPageSuccess(data[0]));
    return data[0];
  }
  catch (e) {
    console.error(e);
    dispatch(getBlogPageError(e));
  }
};

export const loadData = (slug) => (dispatch, getState) => {
  return dispatch(getBlogPage(slug));
};
