import { addIndex, filter, length, map, reduce, sort } from 'ramda';

import { getTimeAsObject } from '../../../../util/time';

import { filteredAvailabilityPlan } from '../../CalendarPage.helpers';

const mapIndexed = addIndex(map);
const filterIndexed = addIndex(filter);
const diffSort = (a, b) => a - b;

export const formatPlanInitialValues = (dayString, availabilityPlan) => {
  const dayEntries = filteredAvailabilityPlan(dayString, availabilityPlan);

  return reduce((acc, entry) => {
    const { endTime, seats, startTime } = entry;

    if (!seats) return acc;

    const startTimeObj = getTimeAsObject(startTime);
    const endTimeObj = getTimeAsObject(endTime === '00:00' ? '24:00' : endTime);

    if (startTimeObj.hours < 9) return acc;

    const timeArray = new Array(endTimeObj.hours - startTimeObj.hours);

    return [...acc, ...mapIndexed((val, idx) => startTimeObj.hours + idx, timeArray)];
  }, [], dayEntries)
}

export const addValueToDayPlan = (timeInt, previousValues) =>
  sort(diffSort, [...previousValues, ...[timeInt]]);

export const removeValueFromDayPlan = (timeInt, previousValues) =>
  filter(value => value !== timeInt, previousValues);

const reducedValues = (dayValues) =>
  reduce((acc, val) => {
    const accLength = length(acc);

    if (!accLength) return [...acc, ...[[val]]];

    const lastArray = acc[accLength - 1];
    const lastArrayLength = length(lastArray);
    const lastValue = lastArray[lastArrayLength - 1];
    
    if (lastValue + 1 !== val) return [...acc, ...[[val]]];

    const accWithoutLastItem = filterIndexed((item, idx) => idx < accLength - 1, acc)

    return [...accWithoutLastItem, [...lastArray, ...[val]]];
  }, [] ,dayValues)

export const formatValuesToAvailabilityPlanEntries = (dayOfWeek, dayValues) => {
  const reducedDayValues = reducedValues(dayValues);

  return map(values => {
    const startTime = values[0] < 10 ? `0${values[0]}:00` : `${values[0]}:00`;
    const endTime = values[length(values) - 1] === 23 ? '00:00' : `${values[length(values) - 1] + 1}:00`;

    return {
      dayOfWeek,
      endTime,
      seats: 1,
      startTime
    }
  }, reducedDayValues);
}
