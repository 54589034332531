import React, { Component } from 'react';
import { bool, node } from 'prop-types';
import classNames from 'classnames';

import css from './styles.css';
import rightChevron from './right-chevron.png';
import downChevron from './down-arrow.png';

export class CollapseComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    }
  }

  getChevron = () => {
    const { isOpen } = this.state;
    const chevron = isOpen ? downChevron : rightChevron;

    return <img src={chevron} alt="chevron" />;
  };

  getHeader = () => {
    const { header } = this.props;
    const { isOpen } = this.state;

    return (
      <div className={css.header} onClick={this.handleOpeningCollapse}>
        <div className={isOpen ? css.headerText : null}>
          {header}
        </div>
        <div className={css.chevron}>
          {this.getChevron()}
          </div>
      </div>
    );
  };

  getContent = () => {
    const { content, contentClassName } = this.props;
    const classes = classNames(contentClassName, css.content);
    if (!this.state.isOpen) return null;

    return (
      <div className={classes}>
        {content}
      </div>
    )
  };

  handleOpeningCollapse = () => {
    this.setState(prev => ({ isOpen: !prev.isOpen }));
  };

  render() {
    const { className, isWithoutSeparator } = this.props;
    const classes = classNames(className);

    return (
      <div className={classes}>
        {!isWithoutSeparator && <hr className={css.separator}/>}
        {this.getHeader()}
        {this.getContent()}
      </div>
    );
  }
}

CollapseComponent.defaultProps = {
  isWithoutSeparator: false,
  content: null,
};

CollapseComponent.propTypes = {
  content: node,
  header: node.isRequired,
  isWithoutSeparator: bool,
};

export default CollapseComponent;
