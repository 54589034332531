import React from 'react';
import { bool } from 'prop-types';

import { SlideAnimation } from '../../../../components/Animations';

const AnimatedView = (props) => {
  const { children, show } = props;

  return (
    <SlideAnimation inProps={show} appear={show} from="bottom" mountOnEnter unmountOnExit>
      {children}
    </SlideAnimation>
  )
};

AnimatedView.defaultProps = {};

AnimatedView.propTypes = {
  show: bool.isRequired,
};

export default AnimatedView;