export const pagesWithoutPersistentNav = [
  'EditListingPage',
  'FavoritesPage',
  'LandingPage',
  'SearchPage',
  'SimulatorPage',
];

export const pagesWithSearchButton = [
  'LandingPage',
  'SearchPage',
];

export const pagesWithAddListingLink = [
  'LandingPage',
  'SimulatorPage',
];
