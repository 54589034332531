import React from 'react';
import { string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { path, pathOr } from 'ramda';

import { propTypes } from '../../../util/types';
import { Divider, ListingInfo } from '../../../components';

import { getNotAllowedPolicies } from '../ContactPage.helpers';
import DetailsAndPolicies from './DetailsAndPolicies/DetailsAndPolicies';
import PriceAndCancellation from './PriceAndCancellation/PriceAndCancellation';
import css from './InformationPanel.module.css';

const InformationPanel = (props) => {
  const {
    className,
    intl,
    listing,
  } = props;
  const listingPublicData = pathOr({}, ['attributes', 'publicData'], listing);
  const hasToilet = path(['accesToilettes'], listingPublicData) || pathOr([], ['extras'], listingPublicData).includes('toilette');
  const childrenAllowed = path(['enfantsBienvenue'], listingPublicData);
  const notAllowedPolicies = getNotAllowedPolicies(listingPublicData);
  const minimumHoursPerBooking = pathOr(1, ['minimumHoursPerBooking'], listingPublicData);
  const headingText = intl.formatMessage({ id: 'ContactPage.InformationPanel.heading' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <ListingInfo className={css.listingInfo} listing={listing} />
      <Divider className={css.divider} />
      <div className={css.infoWrapper}>
        <span className={css.infoHeading}>{headingText}</span>
        <DetailsAndPolicies
          className={css.detailsAndPolicies}
          headingClassName={css.sectionHeading}
          listClassName={css.sectionList}
          childrenAllowed={childrenAllowed}
          hasToilet={hasToilet}
          intl={intl}
          minimumHoursPerBooking={minimumHoursPerBooking}
          notAllowedPolicies={notAllowedPolicies}
        />
        <PriceAndCancellation
          className={css.priceAndCancellation}
          headingClassName={css.sectionHeading}
          listClassName={css.sectionList}
          intl={intl}
          listingPublicData={listingPublicData}
        />
      </div>
    </div>
  )
}

InformationPanel.defaultProps = {
  className: null,
};

InformationPanel.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
};

export default InformationPanel;