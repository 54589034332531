import React from 'react';
import { object, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { END_TIME, FLEXIBLE, START_TIME } from '../../../../../../util/search';
import { FieldCheckbox, FieldTimeRangePicker } from '../../../../../../components';

import css from './SlotSelector.module.css';

const SlotSelector = (props) => {
  const { className, finalFormApi, formValues, intl } = props;
  const startTimeLabelText = intl.formatMessage({ id: 'SearchDesktop.TimeSlotInput.startTimeLabel' });
  const endTimeLabelText = intl.formatMessage({ id: 'SearchDesktop.TimeSlotInput.endTimeLabel' });
  const flexibleLabelText = intl.formatMessage({ id: 'SearchDesktop.TimeSlotInput.flexibleLabel' });
  const classes = classNames(css.slotContainer, className);

  return (
    <div className={classes}>
      <FieldTimeRangePicker
        currentStartValue={formValues.startTime}
        finalFormApi={finalFormApi}
        idStart={START_TIME}
        idEnd={END_TIME}
        labelEnd={endTimeLabelText}
        labelStart={startTimeLabelText}
        nameStart={START_TIME}
        nameEnd={END_TIME}
        maxHour={24}
      />
      <div className={css.flexibleInput}>
        <FieldCheckbox
          textClassName={css.flexibleText}
          id={FLEXIBLE}
          label={flexibleLabelText}
          name={FLEXIBLE}
          value="true"
        />
      </div>
    </div>
  );
};

SlotSelector.defaultProps = {
  className: null,
};

SlotSelector.propTypes = {
  className: string,
  finalFormApi: object.isRequired,
  formValues: object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SlotSelector);