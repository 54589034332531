import React from 'react';
import { string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { pathOr } from 'ramda';

import { propTypes } from '../../util/types';
import { DataWithLabel } from '../../components';

import css from './BookingParticipantBreakdown.module.css';

const BookingParticipantBreakdown = (props) => {
  const {
    className,
    intl,
    transaction,
  } = props;
  const adultCount = pathOr(0, ['attributes', 'protectedData', 'quantity', 'adults'], transaction);
  const childCount = pathOr(0, ['attributes', 'protectedData', 'quantity', 'children'], transaction);
  const infantCount = pathOr(0, ['attributes', 'protectedData', 'quantity', 'infants'], transaction);
  const totalParticipants = adultCount + childCount;
  const participantsLabelText = intl.formatMessage({ id: 'BookingParticipantBreakdown.participantsLabel' });
  const participantCountText = intl.formatMessage({ id: 'Marketplace.participantCount' }, { count: totalParticipants });
  const infantCountText = intl.formatMessage({ id: 'Marketplace.infantCount' }, { count: infantCount });
  const infantInfoText = intl.formatMessage({ id: 'BookingParticipantBreakdown.infantInfo' });
  const participantsText = `${participantCountText}${infantCount > 0 ? ', ' + infantCountText : ''}`;
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <DataWithLabel className={css.date} label={participantsLabelText} value={participantsText} />
      <span className={css.info}>{infantInfoText}</span>
    </div>
  )
}

BookingParticipantBreakdown.defaultProps = {
  className: null,
};

BookingParticipantBreakdown.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  transaction: propTypes.transaction.isRequired,
};

export default injectIntl(BookingParticipantBreakdown);