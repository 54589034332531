import React from 'react';
import { array, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { map } from 'ramda';

import ExtraItem from './ExtraItem/ExtraItem';
import css from './SectionExtras.module.css';

const SectionExtras = (props) => {
  const {
    className,
    intl,
    paidExtrasData,
    titleClassName,
  } = props;
  const titleText = intl.formatMessage({ id: 'ListingPage.SectionExtras.title' });
  const infoText = intl.formatMessage({ id: 'ListingPage.SectionExtras.info' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <h2 className={titleClassName}>{titleText}</h2>
      <span className={css.info}>{infoText}</span>
      <div className={css.extraList}>
        {map((extraData) => {
          return (
            <ExtraItem
              key={extraData.id}
              className={css.extraItem}
              extraData={extraData}
              intl={intl}
            />
          )
        }, paidExtrasData)}
      </div>
    </div>
  )
}

SectionExtras.defaultProps = {
  className: null,
  titleClassName: null,
};

SectionExtras.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  paidExtrasData: array.isRequired,
  titleClassName: string,
};

export default SectionExtras;
