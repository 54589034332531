import React from 'react';
import classNames from 'classnames';

import { Logo, NamedLink } from '../../../../../components';

import css from './LogoLink.module.css';

const LogoLink = (props) => {
  const { className, intl } = props;
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <NamedLink
        name="LandingPage"
        title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
      >
        <Logo format="desktop" className={css.logo} />
      </NamedLink>
    </div>
  )
}

export default LogoLink;
