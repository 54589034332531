import React from 'react';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';

import {
  IconAdd,
  NamedLink,
  SimulatorForm,
} from '../../components';
import providerImg from '../../assets/pictures/womanProvider.png';
import childImg from '../../assets/pictures/child2.png';

import css from './SimulatorBanner.css';

const SimulatorBanner = props => {
  const { intl } = props;
  const title = <FormattedHTMLMessage id='SimulatorPage.SimulatorBannerContentTitle' />;
  const subtitle = <FormattedHTMLMessage id='SimulatorPage.SimulatorBannerContentText' />;
  const ctaText = intl.formatMessage({ id: 'SimulatorPage.SimulatorBannerContentLink' });

  return (
    <section className={css.sectionMain}>
      <div className={css.mainContainer}>
        <div className={css.mainHeroContainer}>
          <div className={css.mainTitleContainer}>
            <h1 className={css.mainTitle}>{title}</h1>
            <span className={css.mainSubtitle}>{subtitle}</span>
          </div>
          <div className={css.mainBackground}>
            <img className={css.providerImg} src={providerImg} alt='provider' />
            <img className={css.childImg} src={childImg} alt='child' />
            <NamedLink
              className={css.mainCTA}
              name='NewListingPage'
            >
              <span className={css.buttonText}>{ctaText}</span>
              <IconAdd className={css.buttonIcon} />
            </NamedLink>
          </div>
        </div>
      </div>

      <div className={css.mainContainerDesktop}>
        <div className={css.mainLeftContainer}>
          <div className={css.background}>
            <div className={css.mainHeroContainerDesktop}>
              <div className={css.mainTitleContainer}>
                <h1 className={css.mainTitle}>{title}</h1>
                <span className={css.mainSubtitle}>{subtitle}</span>
              </div>
              <NamedLink
                className={css.mainCTA}
                name='NewListingPage'
              >
                <span className={css.buttonText}>{ctaText}</span>
                <IconAdd className={css.buttonIcon} />
              </NamedLink>
            </div>
          </div>
        </div>
        <div className={css.mainRightContainer}>
          <SimulatorForm className={css.simulator} />
          <img className={css.providerImg} src={providerImg} alt='provider' />
          <img className={css.childImg} src={childImg} alt='child' />
        </div>
      </div>
    </section>
  )
}

export default injectIntl(SimulatorBanner);
