import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { number, string, array, oneOfType } from 'prop-types';
import truncate from 'lodash/truncate';

import { InlineTextButton } from '../../components';
import css from './ExpandableText.css'

const truncated = (s, length) => {
  return truncate(s, {
    length: length,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

const ExpandableText = props => {
    const { className, text, length } = props;
    const [expand, setExpand] = useState(false);
    const textIsArray = Array.isArray(text);
    const formattedText = textIsArray ? text.join("") : text;
    const truncatedText = truncated(formattedText, length);
    const handleShowMoreClick = () => {
        setExpand(true);
    };
    const showMore = (
        <InlineTextButton className={css.showMore} onClick={handleShowMoreClick}>
          <FormattedMessage id="ExpandableText.showFullTextLink" />
        </InlineTextButton>
      );
  
    return (
      <p className={className}>
        {expand ? text : truncatedText}
        {formattedText !== truncatedText && !expand ? showMore : null}
      </p>
    );
}

ExpandableText.defaultProps = { className: null };

ExpandableText.propTypes = {
  className: string,
  text: oneOfType([string, array]).isRequired,
  length: number.isRequired,
};

export default ExpandableText;