import React from 'react';
import { addIndex, compose, map, mapObjIndexed, values } from 'ramda';
import classNames from 'classnames';

import { IconWarning, NamedLink } from '../../../../components';

import css from './MissingInfos.module.css';

const MissingInfos = (props) => {
  const { className, missingInfos, intl } = props;
  const mapIndexed = addIndex(map);
  const listText = intl.formatMessage({ id: 'MissingInfos.list' });
  const emailInfoText = intl.formatMessage({ id: 'MissingInfos.emailMissing' });
  const profileInfosText = intl.formatMessage({ id: 'MissingInfos.profileInfos' });
  const personalInfosText = intl.formatMessage({ id: 'MissingInfos.personalInfos' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <div className={css.iconWrapper}>
        <IconWarning className={css.icon} />
      </div>
      <div className={css.textWrapper}>
        <span className={css.listHeader}>{`${listText} :`}</span>
        <ul>
          {compose(
            values,
            mapObjIndexed((infos, key, obj) => {
              if (infos.length === 0) return null;
              
              switch (key) {
                case 'emailInfo':
                  return (
                    <li key="emailInfo">
                      <NamedLink className={css.link} name="AccountPersonalInfosPage">
                        {emailInfoText}
                      </NamedLink>
                    </li>
                  );
                case 'profileInfos':
                  return (
                    <li key="profileInfos">
                      <NamedLink className={css.link} name="AccountProfilePage">
                        {profileInfosText}
                        {' ('}
                        {mapIndexed((info, index) => {
                            const formattedInfo = intl.formatMessage({ id: `MissingInfos.${info}` });
                            if (index === 0) {
                              return formattedInfo;
                            } else {
                              return `, ${formattedInfo}`;
                            }
                          } , infos)
                        }
                        {')'}
                      </NamedLink>
                    </li>
                  );
                case 'personalInfos':
                  return (
                    <li key="personalInfos">
                      <NamedLink className={css.link} name="AccountPersonalInfosPage">
                        {personalInfosText}
                        {' ('}
                        {mapIndexed((info, index) => {
                            const formattedInfo = intl.formatMessage({ id: `MissingInfos.${info}` });
                            if (index === 0) {
                              return formattedInfo;
                            } else {
                              return `, ${formattedInfo}`;
                            }
                          } , infos)
                        }
                        {')'}
                      </NamedLink>
                    </li>
                  );
                default:
                  return null;
              }
            })
          )(missingInfos)}
        </ul>
      </div>
    </div>
  )
};

export default MissingInfos;
