import React, { useEffect, useState } from 'react';
import { array, func, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { filter, map } from 'ramda';

import ExtraItem from './ExtraItem/ExtraItem';
import css from './ExtraPanel.module.css';
import { PrimaryButton } from '../../../components';

const ExtraPanel = (props) => {
  const {
    className,
    exposeSelectedExtras,
    handleSubmit,
    intl,
    paidExtrasData,
  } = props;
  const [selectedExtras, setSelectedExtras] = useState([]);
  useEffect(() => exposeSelectedExtras(selectedExtras), [selectedExtras]);
  const handleClickOnExtra = (extraId) => {
    if (selectedExtras.includes(extraId)) {
      setSelectedExtras(filter(id => id !== extraId));
    } else {
      setSelectedExtras(prev => [...prev, extraId]);
    };
  };
  const headingText = intl.formatMessage({ id: 'CheckoutExtraPage.ExtraPanel.heading' });
  const panelInfoText = intl.formatMessage({ id: 'CheckoutExtraPage.ExtraPanel.panelInfo' });
  const submitText = intl.formatMessage({ id: 'Marketplace.continue' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <h1 className={css.heading}>{headingText}</h1>
      <span className={css.panelInfo}>{panelInfoText}</span>
      <div className={css.extraList}>
        {map((extraData) => {
          return (
            <ExtraItem
              key={extraData.id}
              className={css.extraItem}
              extraData={extraData}
              intl={intl}
              isSelected={selectedExtras.includes(extraData.id)}
              onClick={() => handleClickOnExtra(extraData.id)}
            />
          )
        }, paidExtrasData)}
      </div>
      <PrimaryButton className={css.submitButton} onClick={handleSubmit}>
        {submitText}
      </PrimaryButton>
    </div>
  )
}

ExtraPanel.defaultProps = {
  className: null,
};

ExtraPanel.propTypes = {
  className: string,
  exposeSelectedExtras: func.isRequired,
  handleSubmit: func.isRequired,
  intl: intlShape.isRequired,
  paidExtrasData: array.isRequired,
};

export default ExtraPanel;