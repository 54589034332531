import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { map } from 'ramda';

import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { parse } from '../../util/urlHelpers';
import { typeOfPoolSearchParams } from '../../util/search';
import { isSeason } from '../../util/dates';
import { Checkbox, Emoji, Switch } from '../../components';

import css from './SearchFiltersPrimary.css';

const SearchFiltersPrimaryComponent = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    intl,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    isSecondaryFiltersOpen,
    toggleSecondaryFiltersOpen,
    selectedSecondaryFiltersCount,
    handleShowSearchMap,
    showSearchMap,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = classNames(rootClassName || css.root, className);
  const toggleSecondaryFiltersOpenButtonClasses =
    isSecondaryFiltersOpen || selectedSecondaryFiltersCount > 0
      ? css.searchFiltersPanelOpen
      : css.searchFiltersPanelClosed;
  const toggleSecondaryFiltersOpenButton = toggleSecondaryFiltersOpen ? (
    <button
      className={toggleSecondaryFiltersOpenButtonClasses}
      onClick={() => {
        toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
      }}
    >
      <FormattedMessage
        id="SearchFiltersPrimary.moreFiltersButton"
        values={{ count: selectedSecondaryFiltersCount }}
      />
    </button>
  ) : null;
  const openMapText = <FormattedMessage id="SearchFiltersPrimary.showMap" />
  const typeOfPoolFilters = [
    ...isSeason() ? [] : [{ value: 'offSeason', text: intl.formatMessage({ id: 'SearchFiltersPrimary.offSeason' }), symbol: '0x2744' }],
    { value: 'event', text: intl.formatMessage({ id: 'SearchFiltersPrimary.event' }), symbol: '0x1F942' },
    { value: 'jacuzzi', text: intl.formatMessage({ id: 'SearchFiltersPrimary.jacuzzi' }), symbol: '0x1F6C1' },
    { value: 'sauna', text: intl.formatMessage({ id: 'SearchFiltersPrimary.sauna' }), symbol: '0x2668' },
  ];
  const parsedSearch = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const applyTypeOfPoolFilter = (value) => {
    const { pub_extras, pub_policyEvent, typeOfPool, ...restOfSearchParams } = parsedSearch;
    const clearFilter = typeOfPool === value;
    const searchParams = {
      ...restOfSearchParams,
      ...clearFilter ? {} : typeOfPoolSearchParams(value),
      ...clearFilter ? {} : { typeOfPool: value },
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        {listingsAreLoaded ? (
          <div className={css.searchResultSummary}>
            <span className={css.resultsFound}>
              <FormattedMessage
                id="SearchFiltersPrimary.foundResults"
                values={{ count: resultsCount }}
              />
            </span>
          </div>
        ) : null}
        {sortByComponent}
      </div>

      <div className={css.filters}>
        <div className={css.typeOfPoolFilters}>
          {map((filter) => {
            const isActive = filter.value === parsedSearch.typeOfPool;
            const buttonClasses = classNames(css.typeOfPoolButton, {
              [css.active]: isActive,
            });

            return (
              <button key={filter.value} className={buttonClasses} onClick={() => applyTypeOfPoolFilter(filter.value)}>
                <Emoji className={css.emoji} symbol={filter.symbol} />
                <span className={css.buttonText}>{filter.text}</span>
                <Checkbox className={css.checkbox} checked={isActive} />
              </button>
            )
          }, typeOfPoolFilters)}
        </div>
        <div className={css.otherFilters}>
          {children}
          {toggleSecondaryFiltersOpenButton}
          <div className={css.map}>
            <div className={css.box}>
              <span className={css.text}>{openMapText}</span>
              <Switch onClick={handleShowSearchMap} value={showSearchMap} />
            </div>
          </div>
        </div>
      </div>

      {hasNoResult ? (
        <div className={css.noSearchResults}>
          <FormattedMessage id="SearchFiltersPrimary.noResults" />
        </div>
      ) : null}

      {searchInProgress ? (
        <div className={css.loadingResults}>
          <FormattedMessage id="SearchFiltersPrimary.loadingResults" />
        </div>
      ) : null}
    </div>
  );
};

SearchFiltersPrimaryComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  isSecondaryFiltersOpen: false,
  toggleSecondaryFiltersOpen: null,
  selectedSecondaryFiltersCount: 0,
  sortByComponent: null,
};

SearchFiltersPrimaryComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  isSecondaryFiltersOpen: bool,
  toggleSecondaryFiltersOpen: func,
  selectedSecondaryFiltersCount: number,
  sortByComponent: node,
};

const SearchFiltersPrimary = SearchFiltersPrimaryComponent;

export default injectIntl(SearchFiltersPrimary);
