import React, { Fragment } from 'react';
import { bool, number, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { ADULTS, CHILDREN, INFANTS } from '../../constants/booking';
import { FieldStepper, TextContainer } from '../../components';

import css from './FieldParticipants.module.css';

const FieldParticipants = (props) => {
  const {
    className,
    adultsValue,
    childrenAllowed,
    childrenValue,
    intl,
    maxParticipants,
  } = props;
  const maxAdults = maxParticipants - childrenValue;
  const maxChildren = maxParticipants - adultsValue;
  const adultsLabelText = intl.formatMessage({ id: 'FieldParticipants.adultsLabel' });
  const adultsSublabelText = intl.formatMessage({ id: 'FieldParticipants.adultsSublabel' });
  const childrenLabelText = intl.formatMessage({ id: 'FieldParticipants.childrenLabel' });
  const childrenSublabelText = intl.formatMessage({ id: 'FieldParticipants.childrenSublabel' });
  const infantsLabelText = intl.formatMessage({ id: 'FieldParticipants.infantsLabel' });
  const infantsSublabelText = intl.formatMessage({ id: 'FieldParticipants.infantsSublabel' });
  const childrenNotAllowedText = intl.formatMessage({ id: 'FieldParticipants.childrenNotAllowed' });
  const classes = classNames(css.root, className);
  
  return (
    <div className={classes}>
      <FieldStepper
        className={css.fieldStepper}
        label={adultsLabelText}
        max={maxAdults}
        min={1}
        name={ADULTS}
        sublabel={adultsSublabelText}
      />
      {childrenAllowed ? (
        <Fragment>
          <FieldStepper
            className={css.fieldStepper}
            label={childrenLabelText}
            max={maxChildren}
            min={0}
            name={CHILDREN}
            sublabel={childrenSublabelText}
          />
          <FieldStepper
            className={css.fieldStepper}
            label={infantsLabelText}
            min={0}
            name={INFANTS}
            sublabel={infantsSublabelText}
          />
        </Fragment>
      ) : (
        <TextContainer className={css.childrenNotAllowed} text={childrenNotAllowedText} />
      )}
    </div>
  )
};

FieldParticipants.defaultProps = {
  className: null,
  adultsValue: 0,
  childrenAllowed: true,
  childrenValue: 0,
  maxParticipants: null,
};

FieldParticipants.propTypes = {
  className: string,
  adultsValue: number,
  childrenAllowed: bool,
  childrenValue: number,
  intl: intlShape.isRequired,
  maxParticipants: number,
};

export default injectIntl(FieldParticipants);