import React from 'react';
import { injectIntl} from 'react-intl';

import { Card } from '../../components';
import rentMyPoolImg from '../../assets/pictures/womanProvider.png';
import faqImg from '../../assets/pictures/child2-cta.png';
import findPoolImg from '../../assets/pictures/woman-cta.png';

import css from './WhoWeArePage.css';

const SectionCTA = (props) => {
  const { intl } = props;
  const rentMyPoolLinkProps = { name: "SimulatorPage" };
  const findPoolLinkProps = { name: "SearchPage" };
  const faqLinkProps = { name: "FAQPage" };
  const rentMyPoolTxt = intl.formatMessage({ id: "WhoWeArePage.rentMyPool" });
  const rentMyPoolButton = intl.formatMessage({ id: "WhoWeArePage.rentMyPoolButton" });
  const findPoolTxt = intl.formatMessage({ id: "WhoWeArePage.findPool" });
  const findPoolButton = intl.formatMessage({ id: "WhoWeArePage.findPoolButton" });
  const faqTxt = intl.formatMessage({ id : "WhoWeArePage.faq" });
  const faqButton = intl.formatMessage({ id: "WhoWeArePage.faqButton" });

  return (
    <section className={css.section}>
      <div className={css.ctaContent}>
        <Card
          className={css.CTACard}
          imgClassName={css.CTAImg}
          textClassName={css.ctaText}
          img={rentMyPoolImg}
          text={rentMyPoolTxt}
          linkProps={rentMyPoolLinkProps}
          linkText={rentMyPoolButton}
        />
        <Card
          className={css.CTACard}
          imgClassName={css.CTAImg}
          textClassName={css.ctaText}
          img={findPoolImg}
          text={findPoolTxt}
          linkProps={findPoolLinkProps}
          linkText={findPoolButton}
        />
        <Card
          className={css.CTACard}
          imgClassName={css.CTAImg}
          textClassName={css.ctaText}
          img={faqImg}
          text={faqTxt}
          linkProps={faqLinkProps}
          linkText={faqButton}
        />
      </div>
    </section>
  );
}

export default injectIntl(SectionCTA);