import React from 'react';
import { func, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { IconClose } from '../../../components';

import css from './ModalCloseButton.module.css';

const ModalCloseButton = (props) => {
  const { className, handleClose, intl } = props;
  const closeText = intl.formatMessage({ id: 'Modal.ModalCloseButton.close' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <span className={css.closeText}>{closeText}</span>
      <button className={css.closeButton} type="button" onClick={handleClose}>
        <IconClose className={css.iconClose} />
      </button>
    </div>
  )
};

ModalCloseButton.defaultProps = {
  className: null,
};

ModalCloseButton.propTypes = {
  className: string,
  handleClose: func.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(ModalCloseButton);
