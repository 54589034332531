import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import * as validators from '../../util/validators';
import { Button, FieldTextInput, ModalSwimmy } from '../../components';

import css from './ModalPasswordConfirmation.module.css';

const ModalPasswordConfirmation = (props) => {
  const {
    infosText,
    isOpen,
    onClose,
    onManageDisableScrolling,
    submitButtonProps,
    intl,
  } = props;
  const headingText = intl.formatMessage({ id: 'ModalPasswordConfirmation.heading' });
  const inputLabel = intl.formatMessage({ id: 'ModalPasswordConfirmation.inputLabel' });
  const inputPlaceholder = intl.formatMessage({ id: 'ModalPasswordConfirmation.inputPlaceholder' });
  const passwordRequiredText = intl.formatMessage({ id: 'ModalPasswordConfirmation.passwordRequired' });
  const submitButtonText = intl.formatMessage({ id: 'ModalPasswordConfirmation.submitButton' });
  const passwordRequired = validators.requiredStringNoTrim(passwordRequiredText);

  return (
    <ModalSwimmy
      className={css.modalContainer}
      id="ModalPasswordConfirmation"
      key="ModalPasswordConfirmation"
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
      heading={<span>{headingText}</span>}
      content={(
        <div className={css.content}>
          <p className={css.infos}>{infosText}</p>
          <FieldTextInput
            className={css.input}
            type="password"
            id="currentPassword"
            name="currentPassword"
            autoComplete="current-password"
            label={inputLabel}
            placeholder={inputPlaceholder}
            validate={passwordRequired}
          />
        </div>
      )}
      footer={(
        <Button type="submit" {...submitButtonProps}>
          <span>{submitButtonText}</span>
        </Button>
      )}
    />
  )
};

ModalPasswordConfirmation.defaultProps = {};

ModalPasswordConfirmation.propTypes = {
  infosText: string.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  submitButtonProps: object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ModalPasswordConfirmation);
