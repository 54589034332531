import React, { Fragment, useEffect, useState } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { length, map, path, pathOr } from 'ramda';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useLocation } from 'react-router-dom';

import { propTypes } from '../../../../util/types';
import { manageDisableScrolling } from '../../../../ducks/UI.duck';
import { getOwnListingsById, queryOwnListings } from '../../../../ducks/ownListings.duck';
import { IconClose } from '../../../../components/Icons';
import { 
  ModalSwimmy,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from '../../../../components/ModalSwimmy';
import {
  IconSpinner,
  NamedLink,
  ResponsiveImage,
} from '../../../../components';

import css from './ListingSelector.module.css';

const ListingSelector = (props) => {
  const {
    className,
    currentListing,
    intl,
    listings,
    onManageDisableScrolling,
    onQueryOwnListings,
    queryInProgress,
  } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (!modalIsOpen) return;

    setModalIsOpen(false);
  }, [location]);
  const handleOpenButton = (e) => {
    onQueryOwnListings({
      include: ['images'],
      'fields.image': ['variants.square-small'],
      'limit.images': 1,
    });
    setModalIsOpen(true)
  };
  const listingTitle = pathOr('', ['attributes', 'title'], currentListing);
  const listingImages = path(['images'], currentListing);
  const listingLabelText = intl.formatMessage({ id: 'CalendarPage.MainContent.listingLabel' });
  const headingText = intl.formatMessage({ id: 'CalendarPage.MainContent.ListingSelector.heading' });
  const classes = classNames(css.root, className);

  return (
    <Fragment>
      <button className={classes} onClick={handleOpenButton}>
        {listingImages && length(listingImages) > 0 ? (
          <ResponsiveImage
            className={css.responsiveImage}
            alt={currentListing.attributes.title}
            image={listingImages[0]}
            variants={['square-small']}
          />
        ) : (
          <div className={css.noImg} />
        )}
        <div className={css.titleWrapper}>
          <span className={css.listingLabel}>{listingLabelText}</span>
          <span className={css.listingTitle}>{listingTitle}</span>
        </div>
      </button>
      <ModalSwimmy
        id="ListingSelector"
        animationType="fade"
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ModalOverlay />
        <ModalContent
          className={css.modalContent}
          animationType="slideBottom"
          show={modalIsOpen}
        >
          <ModalHeader className={css.modalHeader}>
            <button className={css.closeButton} onClick={() => setModalIsOpen(false)}>
              <IconClose />
            </button>
            <span className={css.heading}>{headingText}</span>
            <div className={css.spacer} />
          </ModalHeader>
          <ModalBody className={css.modalBody}>
            {queryInProgress ? (
              <IconSpinner />
            ) : (
              <div className={css.listingsWrapper}>
                {map(listing => (
                  <NamedLink
                    key={listing.attributes.title}
                    className={css.listingLink}
                    name="CalendarPage"
                    params={{ id: listing.id.uuid }}
                  >
                    {listing.images && length(listing.images) > 0 ? (
                      <ResponsiveImage
                        className={css.responsiveImageInModal}
                        alt={listing.attributes.title}
                        image={listing.images[0]}
                        variants={['square-small']}
                      />
                    ) : (
                      <div className={css.noImgInModal} />
                    )}
                    <span className={css.listingTitleInModal}>{listing.attributes.title}</span>
                  </NamedLink>
                ), listings)}
              </div>
            )}
          </ModalBody>
        </ModalContent>
      </ModalSwimmy>
    </Fragment>
  )
}

ListingSelector.defaultProps = {
  className: null,
  currentListing: null,
  listings: [],
  pagination: null,
  queryListingsError: null,
};

ListingSelector.propTypes = {
  className: string,
  currentListing: propTypes.ownListing,
  intl: intlShape.isRequired,
  listings: arrayOf(propTypes.ownListing),
  onManageDisableScrolling: func.isRequired,
  onQueryOwnListings: func.isRequired,
  pagination: propTypes.pagination,
  queryInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
  } = state.ownListings;
  const listings = getOwnListingsById(state, currentPageResultIds);

  return {
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onQueryOwnListings: (queryParams) => dispatch(queryOwnListings(queryParams)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ListingSelector);