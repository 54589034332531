import React from 'react';
import { bool } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';

import { propTypes } from '../../../../util/types';
import { replaceTextMask } from '../../../../util/contactDetails';
import { formatDate } from '../../../../util/dates';
import { Avatar, UserDisplayName } from '../../../../components';

import css from './Message.module.css';

export const OwnMessage = props => {
  const {
    message,
    intl,
    isMobileDevice,
    nextMessageHasSameAuthor,
    prevMessageHasSameAuthor,
  } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  const messageText = replaceTextMask(message.attributes.content, intl);
  const contentClasses = classNames(css.ownMessageContentWrapper, {
    [css.ownMessagePrevMessageHasSameAuthor]: prevMessageHasSameAuthor,
    [css.ownMessageNextMessageHasSameAuthor]: nextMessageHasSameAuthor,
  });

  return (
    <div className={css.ownMessage}>
      {!isMobileDevice && <Avatar className={css.avatar} user={message.sender} />}
      <div>
        {!isMobileDevice && (
          <div className={css.headerDesktop}>
            <UserDisplayName className={css.senderName} user={message.sender} intl={intl} />
            <span className={css.ownMessageDate}>
              {formatDate(intl, todayString, message.attributes.createdAt)}
            </span>
          </div>
        )}
        <div className={contentClasses}>
          <p className={css.ownMessageContent}>{messageText}</p>
          {isMobileDevice && (
            <span className={css.ownMessageDate}>
              {formatDate(intl, todayString, message.attributes.createdAt)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

OwnMessage.defaultProps = {
  isMobileDevice: false,
  nextMessageHasSameAuthor: false,
  prevMessageHasSameAuthor: false,
};

OwnMessage.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
  isMobileDevice: bool,
  nextMessageHasSameAuthor: bool,
  prevMessageHasSameAuthor: bool,
};

export default OwnMessage;