import React, { Fragment } from 'react';
import { bool, func, object, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';

import { propTypes } from '../../util/types';
import {
  ModalSwimmy,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '../../components/ModalSwimmy';

import ReviewForm from './components/ReviewForm/ReviewForm';
import ReviewSubmitButton from './components/ReviewSubmitButton/ReviewSubmitButton';
import css from './ReviewModal.css';

const ReviewModal = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        disabled,
        handleSubmit,
        id,
        intl,
        invalid,
        isOpen,
        onCloseModal,
        onManageDisableScrolling,
        revieweeName,
        reviewSent,
        sendReviewInProgress,
        sendReviewError,
      } = fieldRenderProps;
      const submitDisabled = invalid || disabled || sendReviewInProgress;
      const formId = 'reviewForm';
      const heading = intl.formatMessage({ id: 'ReviewModal.heading' });
      const reviewee = <span className={css.reviewee}>{revieweeName}</span>;
      const modalDescription = <FormattedMessage id="ReviewModal.description" values={{ revieweeName: reviewee }} />

      return (
        <ModalSwimmy
          id={id}
          isOpen={isOpen}
          onClose={onCloseModal}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <ModalOverlay />
          <ModalContent className={css.modalContent}>
            <ModalHeader className={css.modalHeader} heading={heading} />
            <ModalCloseButton className={css.modalCloseButton} handleClose={onCloseModal} />
            <ModalBody>
              <p className={css.modalMessage}>{modalDescription}</p>
              <ReviewForm
                className={css.form}
                formId={formId}
                intl={intl}
                handleSubmit={handleSubmit}
                sendReviewError={sendReviewError}
              />
            </ModalBody>
            <ModalFooter className={css.modalFooter}>
              <ReviewSubmitButton
                className={css.submitButton}
                formId={formId}
                intl={intl}
                reviewSent={reviewSent}
                sendReviewInProgress={sendReviewInProgress}
                submitDisabled={submitDisabled}
              />
            </ModalFooter>
          </ModalContent>
        </ModalSwimmy>
      );
    }}
  />
);

ReviewModal.defaultProps = {
  reviewSent: false,
  sendReviewInProgress: false,
  sendReviewError: null,
};

ReviewModal.propTypes = {
  id: string.isRequired,
  intl: intlShape.isRequired,
  isOpen: bool.isRequired,
  onCloseModal: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSubmit: func.isRequired,
  revieweeName: object.isRequired,
  reviewSent: bool,
  sendReviewInProgress: bool,
  sendReviewError: propTypes.error,
};

export default injectIntl(ReviewModal);
