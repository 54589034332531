import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-hamburg',
    predictionPlace: {
      address: 'Hamburg, Deutschland',
      origin: new LatLng(53.5510846, 9.9936818),
      bounds: new LatLngBounds(new LatLng(54.66067537706632, 11.333663215905482), new LatLng(52.4411288930797, 8.592423713799025)),
      city: 'Hamburg',
    },
  },
  {
    id: 'default-berlin',
    predictionPlace: {
      address: 'Berlin, Deutschland',
      origin: new LatLng(52.52000659999999, 13.404954),
      bounds: new LatLngBounds(new LatLng(53.66347379003598, 15.842525139414281), new LatLng(51.30440318548903, 11.13078545359709)),
      city: 'Berlin',
    },
  },
  {
    id: 'default-munich',
    predictionPlace: {
      address: 'München, Deutschland',
      origin: new LatLng(48.1351253, 11.5819806),
      bounds: new LatLngBounds(new LatLng(48.80113945287701, 12.825058201353182), new LatLng(47.49503405541607, 10.29021649128714)),
      city: 'München',
    },
  },
];
