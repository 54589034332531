import React, { PureComponent } from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { path, pathOr } from 'ramda';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import config from '../../config';
import { TRANSITION_INSTANT_ACCEPT, TRANSITION_MARK_NON_REFUNDABLE } from '../../util/transactions';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  NamedLink,
  CriteoSales,
} from '../../components';
import { TopbarContainer } from '../../containers';

import { loadData } from './CongratPurchaseBookingPage.duck';
import css from './styles.css';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { parse } from '../../util/urlHelpers';

class CongratPurchaseBookingPageComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
    };
  }

  componentDidMount() {
    const { search } = this.props.location;
    const { id } = parse(search);
    this.setState({
      id,
    });
  }

  getRedirectLink = () => {
    const { id } = this.state;
    const redirectLinkProps = id ? ({
      name: 'OrderDetailsPage',
      params: {
        id,
      },
    }) : ({
      name: 'LandingPage',

    });

    return (
      <NamedLink {...redirectLinkProps}>
        <FormattedMessage id="word.here"/>
      </NamedLink>
    );
  };

  render() {
    const { intl, scrollingDisabled, currentUser, transaction, price } = this.props;
    const { id } = this.state;
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'CongratPurchaseBookingPage.schemaTitle' }, { siteTitle });
    const authorDisplayName = pathOr(intl.formatMessage({ id: 'CongratPurchaseBooking.provider' }), ['provider', 'attributes', 'profile', 'displayName'], transaction);
    const lastTransition = path(['attributes', 'lastTransition'], transaction);
    const isInstantBooking = lastTransition === TRANSITION_INSTANT_ACCEPT || lastTransition === TRANSITION_MARK_NON_REFUNDABLE;
    const content = isInstantBooking
      ? intl.formatMessage({ id: 'CongratPurchaseBooking.contentInstantBooking' }, { provider: authorDisplayName })
      : intl.formatMessage({ id: 'CongratPurchaseBooking.content' }, { provider: authorDisplayName });
    const details = isInstantBooking
      ? intl.formatMessage({ id: 'CongratPurchaseBooking.detailsInstantBooking' })
      : intl.formatMessage({ id: 'CongratPurchaseBooking.details' })

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
        }}
      >
        <CriteoSales currentUser={currentUser} poolId={id} price={price} />
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="CongratPurchaseBookingPage"/>
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            <div className={css.root}>
              <div className={css.container}>
                <h1>
                  {content}
                </h1>
                <h3>
                  {details}
                  {this.getRedirectLink()}.
                </h3>
              </div>
            </div>
          </LayoutWrapperMain>
        </LayoutSingleColumn>
      </Page>
    );
  };
};

CongratPurchaseBookingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { transactionRef } = state.transaction;
  const { price } = state.CongratPurchaseBookingPage;
  const { currentUser } = state.user;

  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    price,
    transaction,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const CongratPurchaseBookingPage = compose(
  connect(mapStateToProps),
  injectIntl
)(CongratPurchaseBookingPageComponent);

CongratPurchaseBookingPage.loadData = loadData;

export default CongratPurchaseBookingPage;
