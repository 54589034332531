import React, { Component, Fragment } from 'react';
import { bool, object, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { map, path } from 'ramda';
import { isMobile } from "react-device-detect";

import config from '../../config';
import {
  ExternalLink,
  StripeBankAccountTokenInputField,
  FieldSelect,
  FieldTextInput,
  Form,
  PrimaryButton,
  UploadFileField,
} from '../../components';
import * as validators from '../../util/validators';
import { isStripeInvalidPostalCode } from '../../util/errors';
import css from './PayoutDetailsForm.css';

const getCniFormData = (cniDocument) => {
  if (!cniDocument) return null;

  const formData = new FormData();
  formData.append('file', cniDocument);
  formData.append('purpose', 'identity_document');
  return formData;
};

const supportedCountries = config.stripe.supportedCountries.map(c => c.code);
const acceptableIdentityDocuments = config.custom.stripeIdentityDocuments;
const acceptableAddressDocuments = config.custom.stripeAddressDocuments;

export const stripeCountryConfigs = countryCode => {
  const country = config.stripe.supportedCountries.find(c => c.code === countryCode);

  if (!country) {
    throw new Error(`Country code not found in Stripe config ${countryCode}`);
  }
  return country;
};

const requiresAddress = countryCode => {
  const country = stripeCountryConfigs(countryCode);
  return country.payoutAddressRequired;
};

const countryCurrency = countryCode => {
  const country = stripeCountryConfigs(countryCode);
  return country.currency;
};

class PayoutDetailsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canRender: false,
      hasEnteredIdentityDocument: false,
      hasEnteredProofOfAddressDocument: false,
    };
  }

  componentDidMount() {
    this.setState({canRender: true});
  }

  getCNIDocumentName = () => {
    const { cniFormData } = this.state;
    if (!cniFormData) return;

    const file = cniFormData.get('file');
    return path(['name'], file);
  };

  handleChangeDocumentCNI = (file) => {
    if (!file) return;

    const fileSize = ((file.size / 1024) / 1024).toFixed(4);
    // Block Size to 5MB
    if (fileSize > 5) return;

    const { setCNIDocument } = this.props;
    setCNIDocument(getCniFormData(file));
    this.setState({ hasEnteredIdentityDocument: true });
  };

  handleChangeDocumentProofOfAddress = (file) => {
    if (!file) return;

    const fileSize = ((file.size / 1024) / 1024).toFixed(4);
    // Block Size to 5MB
    if (fileSize > 5) return;

    const { setProofOfAddressDocument } = this.props;
    console.log('doc ', getCniFormData(file))
    setProofOfAddressDocument(getCniFormData(file));
    this.setState({ hasEnteredProofOfAddressDocument: true });
  };

  render() {
    const { canRender } = this.state;
    if (!canRender) return null;
    
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            createStripeAccountError,
            disabled,
            form,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            pristine,
            ready,
            submitButtonText,
            showBirthdayDateField,
            values,
          } = fieldRenderProps;
          const { country } = values;

          const countryLabel = intl.formatMessage({ id: 'PayoutDetailsForm.countryLabel' });
          const countryPlaceholder = intl.formatMessage({
            id: 'PayoutDetailsForm.countryPlaceholder',
          });
          const countryRequired = validators.required(
            intl.formatMessage({
              id: 'PayoutDetailsForm.countryRequired',
            }),
          );

          const streetAddressLabel = intl.formatMessage({
            id: 'PayoutDetailsForm.streetAddressLabel',
          });
          const streetAddressPlaceholder = intl.formatMessage({
            id: 'PayoutDetailsForm.streetAddressPlaceholder',
          });
          const streetAddressRequired = validators.required(
            intl.formatMessage({
              id: 'PayoutDetailsForm.streetAddressRequired',
            }),
          );

          const postalCodeLabel = intl.formatMessage({ id: 'PayoutDetailsForm.postalCodeLabel' });
          const postalCodePlaceholder = intl.formatMessage({
            id: 'PayoutDetailsForm.postalCodePlaceholder',
          });
          const postalCodeRequired = validators.required(
            intl.formatMessage({
              id: 'PayoutDetailsForm.postalCodeRequired',
            }),
          );

          const cityLabel = intl.formatMessage({ id: 'PayoutDetailsForm.cityLabel' });
          const cityPlaceholder = intl.formatMessage({ id: 'PayoutDetailsForm.cityPlaceholder' });
          const cityRequired = validators.required(
            intl.formatMessage({
              id: 'PayoutDetailsForm.cityRequired',
            }),
          );

          const showAddressFields = country && requiresAddress(country);

          // StripeBankAccountTokenInputField handles the error messages
          // internally, we just have to make sure we require a valid token
          // out of the field. Therefore the empty validation message.
          const bankAccountRequired = validators.required(' ');

          const classes = classNames(css.root, className, {
            [css.disabled]: disabled,
          });
          const submitInProgress = inProgress;
          const { hasEnteredIdentityDocument, hasEnteredProofOfAddressDocument } = this.state;
          const hasEnteredDocuments = hasEnteredIdentityDocument && hasEnteredProofOfAddressDocument;
          const submitDisabled = !hasEnteredDocuments || pristine || invalid || disabled || submitInProgress;

          let error = null;

          if (isStripeInvalidPostalCode(createStripeAccountError)) {
            error = (
              <div className={css.error}>
                <FormattedMessage id="PayoutDetailsForm.createStripeAccountFailedInvalidPostalCode"/>
              </div>
            );
          } else if (createStripeAccountError) {
            error = (
              <div className={css.error}>
                <FormattedMessage id="PayoutDetailsForm.createStripeAccountFailed"/>
              </div>
            );
          }

          const stripeConnectedAccountTermsLink = (
            <ExternalLink href="https://stripe.com/connect-account/legal" className={css.termsLink}>
              <FormattedMessage id="PayoutDetailsForm.stripeConnectedAccountTermsLink"/>
            </ExternalLink>
          );
          const uploadCNILabel = intl.formatMessage({
            id: 'UploadCNIField.label',
          });
          const uploadCNIWarningContent = intl.formatMessage({
            id: 'UploadCNIField.warningContent',
          });

          const uploadProofOfAddressLabel = intl.formatMessage({
            id: 'UploadProofOfAddressField.label',
          });
          const uploadProofOfAddressWarningContent = intl.formatMessage({
            id: 'UploadProofOfAddressField.warningContent',
          });
          const birthdayLabel = intl.formatMessage({ id: 'ContactDetailsForm.birthdayLabel' });
          const birthdayRequiredMessage = intl.formatMessage({ id: 'ContactDetailsForm.birthdayRequired' });
          const birthdayRequired = validators.required(birthdayRequiredMessage);
          const birthdayMinAge = validators.ageAtLeastSwimmy(
            intl.formatMessage(
              {
                id: 'PayoutDetailsForm.birthdayMinAge',
              },
              {
                minAge: config.custom.marketplaceAgeRequirement,
              }
            ),
            config.custom.marketplaceAgeRequirement
          );
          const acceptableDocumentsHeading = intl.formatMessage({ id: 'PayoutDetailsForm.acceptableDocumentsHeading' });
          const warningWord = intl.formatMessage({
            id: 'UploadCNIField.warning',
          });
          const warningContent = (acceptableDocuments, warningMessage) => (
            <Fragment>
              <span>{acceptableDocumentsHeading}</span>
              <ul className={css.list}>
                {map((item) => (<li key={item}>{item}</li>),acceptableDocuments)}
              </ul>
              <span><b>{warningWord}</b>, {warningMessage}</span>
            </Fragment>
          );

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.sectionContainer}>
                <h3 className={css.subTitle}>
                  <FormattedMessage id="PayoutDetailsForm.personalDetailsTitle"/>
                </h3>
                <UploadFileField
                  name="documentCNI"
                  label={uploadCNILabel}
                  fileDescription={isMobile ? "UploadCNIField.mobile.choosePhoto" : "UploadCNIField.desktop.choosePhoto"}
                  warningContent={warningContent(acceptableIdentityDocuments, uploadCNIWarningContent)}
                  handleChangeDocumentFile={this.handleChangeDocumentCNI}
                  getFileDocumentName={this.getCNIDocumentName}
                  acceptedFormat=".jpg, .jpeg, .png"
                />
                <UploadFileField
                  name="documentProofOfAddress"
                  label={uploadProofOfAddressLabel}
                  fileDescription={isMobile ? "UploadProofOfAddressField.mobile.choosePhoto" : "UploadProofOfAddressField.desktop.choosePhoto"}
                  warningContent={warningContent(acceptableAddressDocuments, uploadProofOfAddressWarningContent)}
                  handleChangeDocumentFile={this.handleChangeDocumentProofOfAddress}
                  getFileDocumentName={this.getCNIDocumentName}
                  acceptedFormat=".jpg, .jpeg, .png, .pdf"
                />
              </div>
              {showBirthdayDateField && (
                <div className={css.sectionContainer}>
                  <FieldTextInput
                    className={css.field}
                    name="birthdayDate"
                    id={'birthdayDate'}
                    label={birthdayLabel}
                    type="date"
                    validate={validators.composeValidators(birthdayRequired, birthdayMinAge)}
                  />
                </div>
              )}
              <div className={css.sectionContainer}>

                <h3 className={css.subTitle}>
                  <FormattedMessage id="PayoutDetailsForm.addressTitle"/>
                </h3>
                <FieldSelect
                  id="country"
                  name="country"
                  disabled={disabled}
                  className={css.selectCountry}
                  autoComplete="country"
                  label={countryLabel}
                  validate={countryRequired}
                >
                  <option value="">{countryPlaceholder}</option>
                  {supportedCountries.map(c => (
                    <option key={c} value={c}>
                      {intl.formatMessage({ id: `PayoutDetailsForm.countryNames.${c}` })}
                    </option>
                  ))}
                </FieldSelect>
                {showAddressFields ? (
                  <div>
                    <FieldTextInput
                      id="streetAddress"
                      name="streetAddress"
                      disabled={disabled}
                      className={css.field}
                      type="text"
                      autoComplete="street-address"
                      label={streetAddressLabel}
                      placeholder={streetAddressPlaceholder}
                      validate={streetAddressRequired}
                      onUnmount={() => form.change('streetAddress', undefined)}
                    />
                    <div className={css.formRow}>
                      <FieldTextInput
                        id="postalCode"
                        name="postalCode"
                        disabled={disabled}
                        className={css.postalCode}
                        type="text"
                        autoComplete="postal-code"
                        label={postalCodeLabel}
                        placeholder={postalCodePlaceholder}
                        validate={postalCodeRequired}
                        onUnmount={() => form.change('postalCode', undefined)}
                      />
                      <FieldTextInput
                        id="city"
                        name="city"
                        disabled={disabled}
                        className={css.city}
                        type="text"
                        autoComplete="address-level2"
                        label={cityLabel}
                        placeholder={cityPlaceholder}
                        validate={cityRequired}
                        onUnmount={() => form.change('city', undefined)}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              {country ? (
                <div className={css.sectionContainer}>
                  <h3 className={css.subTitle}>
                    <FormattedMessage id="PayoutDetailsForm.bankDetails"/>
                  </h3>
                  <StripeBankAccountTokenInputField
                    disabled={disabled}
                    name="bankAccountToken"
                    formName="PayoutDetailsForm"
                    country={country}
                    currency={countryCurrency(country)}
                    validate={bankAccountRequired}
                  />
                </div>
              ) : null}
              {error}
              <p className={css.termsText}>
                <FormattedMessage
                  id="PayoutDetailsForm.stripeToSText"
                  values={{ stripeConnectedAccountTermsLink }}
                />
              </p>
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={ready}
              >
                {submitButtonText ? (
                  submitButtonText
                ) : (
                  <FormattedMessage id="PayoutDetailsForm.submitButtonText"/>
                )}
              </PrimaryButton>
            </Form>
          );
        }}
      />
    );
  };
}

PayoutDetailsFormComponent.defaultProps = {
  className: null,
  country: null,
  createStripeAccountError: null,
  disabled: false,
  inProgress: false,
  showBirthdayDateField: false,
  ready: false,
  submitButtonText: null,
};

PayoutDetailsFormComponent.propTypes = {
  className: string,
  createStripeAccountError: object,
  disabled: bool,
  inProgress: bool,
  ready: bool,
  submitButtonText: string,
  showBirthdayDateField: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const PayoutDetailsForm = compose(injectIntl)(PayoutDetailsFormComponent);

export default PayoutDetailsForm;
