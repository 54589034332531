import React, { useEffect, useState } from 'react';
import { map, pathOr } from 'ramda';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import AmountEstimatedTop from '../../assets/pictures/fins.png';
import AmountEstimatedBottom from '../../assets/pictures/buoy2.png';

import css from './SimulatorStandalone.module.css';

const { Money } = sdkTypes;

const SimulatorStandalone = props => {
  const { className, intl } = props;
  const [location, setLocation] = useState(0);
  const [capacityToGuest, setCapacityToGuest] = useState(0);
  const [rentalsNumberEnvisioned, setRentalsNumberEnvisioned] = useState(0);
  const [monthlyIncome, setMonthlyIncome] = useState(0);

  useEffect(() => {
    const amountEstimated = !location ? 0 : location * capacityToGuest * rentalsNumberEnvisioned;
    setMonthlyIncome(amountEstimated);
  }, [location, capacityToGuest, rentalsNumberEnvisioned])

  const handleRentalsNumberEnvisioned = (value) => {
    setRentalsNumberEnvisioned(value);
  };
  const handleCapacityToGuest = (value = 0) => {
    setCapacityToGuest(value);
  };
  const handleChangeLocation = event => {
    setLocation(pathOr(0, ['target', 'value'], event));
  };

  const capacityToGuestLabel = intl.formatMessage({ id: 'SimulatorForm.selectCapacityToGuest' });
  const toWord = intl.formatMessage({ id: 'SimulatorForm.to' });
  const capacitiesToGuest = [
    { key: 4, label: `1 ${toWord} 5` },
    { key: 8, label: `5 ${toWord} 10` },
    { key: 15, label: `10+` },
  ];
  const locationLabel = intl.formatMessage({ id: 'SimulatorForm.selectLocationLabel' });
  const simulatorFormLocations = [
    {
      key: 0,
      label: intl.formatMessage({ id: 'SimulatorForm.selectLocationPlaceholder' }),
    },
    ...config.custom.simulatorFormLocations,
  ];

  const amountMoney = new Money(monthlyIncome, config.currency);
  const amountFormatted = formatMoney(intl, amountMoney);
  const amountEstimatedLabel = intl.formatMessage({ id: 'SimulatorForm.amountEstimated' });
  const intensityLabelText = intl.formatMessage({ id: 'SimulatorForm.intensityLabel' });
  const intensityLowLabelText = intl.formatMessage({ id: 'SimulatorForm.intensityLowLabel' });
  const intensityMediumLabelText = intl.formatMessage({ id: 'SimulatorForm.intensityMediumLabel' });
  const intensityHighLabelText = intl.formatMessage({ id: 'SimulatorForm.intensityHighLabel' });
  const locationIntensity = [
    { key: 3, value: 4, label: intensityLowLabelText },
    { key: 8, value: 7, label: intensityMediumLabelText },
    { key: 15, value: 20, label: intensityHighLabelText },
  ];
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <div className={css.form}>
        <div className={css.locationSimulatorField}>
          <label className={css.label} htmlFor="location-simulator">{locationLabel}</label>
          <select id="location-simulator" onChange={handleChangeLocation} className={classNames(css.select, css.locationSimulatorSelect)}>
            {map(({ key, label } = {}) => (
              <option key={label} value={key}>
                {label}
              </option>
            ), simulatorFormLocations)}
          </select>
        </div>
        <div className={classNames(css.locationSimulatorField, css.locationSimulatorMarginField)}>
          <label className={css.label}>{capacityToGuestLabel}</label>
          <div className={css.locationSimulatorRadioToolbar}>
            {map(({ key, label }) => (
              <div className={css.locationSimulatorRadioItem} key={key} onClick={() => handleCapacityToGuest(key)}>
                <label className={classNames(css.locationSimulatorRadioLabel, { [css.locationSelected]: key === capacityToGuest })}>{label}</label>
              </div>
            ), capacitiesToGuest)}
          </div>
        </div>
        <div className={classNames(css.locationSimulatorField, css.locationSimulatorMarginField)}>
          <label className={css.label}>{intensityLabelText}</label>
          <div className={css.locationSimulatorIntensityInput}>
            {map(({ key, label, value, symbol }) => (
              <div className={css.locationSimulatorIntensityItem} key={key} onClick={() => handleRentalsNumberEnvisioned(value)}>
                <label className={classNames(css.locationSimulatorIntensityLabel, { [css.locationSelected]: value === rentalsNumberEnvisioned })}>{label}</label>
              </div>
            ), locationIntensity)}
          </div>
        </div>
      </div>
      <div className={css.amountEstimatedContainer}>
        <img src={AmountEstimatedTop} className={css.pictoTop} alt='fins' />
        <h2 className={css.amountEstimatedLabel}>{amountEstimatedLabel}</h2>
        <h1 className={css.amountEstimated}>{amountFormatted}</h1>
        <img src={AmountEstimatedBottom} className={css.pictoBottom} alt='buoy' />
      </div>
    </div>
  );
}

const Index = injectIntl(SimulatorStandalone);

export default Index;
