import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './ModalFooter.module.css';

const ModalFooter = (props) => {
  const { children, className } = props;
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      {children}
    </div>
  )
};

ModalFooter.defaultProps = {
  className: null,
};

ModalFooter.propTypes = {
  className: string,
};

export default ModalFooter;
