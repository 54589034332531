import React from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { length, map } from 'ramda';

import { getTimeAsObject, hoursArray } from '../../../../util/time';

import {
  getAvailabilityExceptionsToDelete,
  getDayExceptions,
  getDayTransactions,
  getTimeException,
  timeIsAvailableInExceptions,
  timeIsAvailableInPlan,
  timeIsBooked,
  timeIsUnavailableInExceptions
} from '../../CalendarPage.helpers';
import css from './ExceptionManager.module.css';

const ExceptionManager = (props) => {
  const {
    className,
    addExceptionInProgress,
    availabilityExceptions,
    availabilityPlan,
    deleteExceptionInProgress,
    intl,
    listingId,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    selectedDates,
    transactions
  } = props;
  const currentDate = selectedDates[0];
  const handleTimeButtonClick = (startTime) => {
    const startTimeObj = getTimeAsObject(startTime)
    const endTime = `${startTimeObj.hours + 1}:00`;
    const seats = timeIsAvailableInPlan(currentDate, startTime, availabilityPlan) ? 0 : 1;
    const params = { listingId, selectedDates, seats, startTime, endTime };
    const exceptionsToDelete = getAvailabilityExceptionsToDelete(params, availabilityExceptions);

    if (length(exceptionsToDelete)) return onDeleteAvailabilityException(exceptionsToDelete);

    onAddAvailabilityException(params);
  };
  const dayExceptions = getDayExceptions(currentDate, availabilityExceptions);
  const dayTransactions = getDayTransactions(currentDate, transactions);
  const timeButtonDisabled = addExceptionInProgress || deleteExceptionInProgress;
  const exceptionText = intl.formatMessage({ id: 'CalendarPage.exception' });
  const bookedText = intl.formatMessage({ id: 'CalendarPage.booked' });
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <div className={css.labelContainer}>
        {map(time => {
          if (time.key < 9 || time.key > 24) return null;

          return (
            <span key={time.key} className={css.timeLabel}>{`${time.text} -`}</span>
          )
        }, hoursArray)}
      </div>
      <div className={css.buttonContainer}>
        {map(time => {
          if (time.key < 9 || time.key > 23) return null;

          const isBooked = timeIsBooked(currentDate, time.value, dayTransactions);
          const isAvailableInExceptions = timeIsAvailableInExceptions(currentDate, time.value, dayExceptions);
          const isUnavailableInExceptions = timeIsUnavailableInExceptions(currentDate, time.value, availabilityExceptions);
          const timeButtonClasses = classNames(css.timeButton, {
            [css.available]: timeIsAvailableInPlan(currentDate, time.value, availabilityPlan),
            [css.availableException]: !isBooked && isAvailableInExceptions,
            [css.unavailableException]: !isBooked && isUnavailableInExceptions,
            [css.booked]: isBooked,
          })

          return (
            <button
              key={time.key}
              className={timeButtonClasses}
              onClick={() => handleTimeButtonClick(time.value)}
              disabled={timeButtonDisabled || isBooked}
            >
              {!isBooked && (isUnavailableInExceptions || isAvailableInExceptions) && (
                <span className={css.timeButtonText}>{exceptionText}</span>
              )}
              {isBooked && <span className={css.timeButtonText}>{bookedText}</span>}
            </button>
          )
        }, hoursArray)}
      </div>
    </div>
  )
}

ExceptionManager.defaultProps = {
  className: null,
};

ExceptionManager.propTypes = {
  className: string,
  addExceptionInProgress: bool.isRequired,
  availabilityExceptions: array.isRequired,
  availabilityPlan: object.isRequired,
  deleteExceptionInProgress: bool.isRequired,
  intl: intlShape.isRequired,
  listingId: string.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  selectedDates: array.isRequired,
  transactions: array.isRequired
};

export default ExceptionManager;
