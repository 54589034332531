import React from 'react';
import { bool, object, oneOf, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { map, pathOr } from 'ramda';
import { getPricingDataFromEntity } from '@swimmy_/helpers';

import { CUSTOMER, PROVIDER } from '../../constants/booking';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { useToggle } from '../../hooks/useToggle';
import { IconExpand, IconReceipt } from '../../components/Icons';

import { getLineItemProps, isReversalTransaction } from './PriceBreakdown.helpers';
import LineItem from './LineItem/LineItem';
import css from './PriceBreakdown.module.css';

const { Money } = sdkTypes;

const PriceBreakdown = (props) => {
  const {
    className,
    expandable,
    intl,
    transaction,
    userRole,
  } = props;
  const [showBreakdown, toggleBreakdown] = useToggle(!expandable);
  const isCustomer = userRole === CUSTOMER;
  const pricingData = getPricingDataFromEntity(transaction);
  const lineItems = pathOr([], ['attributes', 'lineItems'], transaction);
  const payinTotal = pathOr([], ['attributes', 'payinTotal'], transaction);
  const payoutTotal = pathOr([], ['attributes', 'payoutTotal'], transaction);
  const payinTotalAsMoney = new Money(payinTotal.amount, payinTotal.currency);
  const payoutTotalAsMoney = new Money(payoutTotal.amount, payoutTotal.currency);
  const formattedPayinTotal = formatMoney(intl, payinTotalAsMoney);
  const formattedPayoutTotal = formatMoney(intl, payoutTotalAsMoney);
  const formattedTotal = isCustomer ? formattedPayinTotal : formattedPayoutTotal;
  const isReversal = isReversalTransaction(transaction);
  const buttonShowText = intl.formatMessage({ id: 'PriceBreakdown.buttonShow' });
  const buttonHideText = intl.formatMessage({ id: 'PriceBreakdown.buttonHide' });
  const buttonText = showBreakdown ? buttonHideText : buttonShowText;
  const titleText = intl.formatMessage({ id: 'PriceBreakdown.title' });
  const totalLabelText = intl.formatMessage({ id: 'PriceBreakdown.totalLabel' });
  const serviceFeeText = intl.formatMessage({ id: 'PriceBreakdown.serviceFee' });
  const refundedText = intl.formatMessage({ id: `PriceBreakdown.${isCustomer ? 'refunded' : 'refundedProvider' }` });
  const classes = classNames(css.root, className);
  const expandButtonClasses = classNames(css.expandButton, { [css.expandButtonExpanded]: showBreakdown });
  const iconExpandClasses = classNames(css.iconExpand, { [css.iconExpanded]: showBreakdown });
  const contentClasses = classNames(css.breakdown, { [css.contentExpanded]: showBreakdown });

  return (
    <div className={classes}>
      {expandable ? (
        <button
          className={expandButtonClasses}
          type="button"
          onClick={toggleBreakdown}
        >
          <span className={css.buttonText}>{buttonText}</span>
          <IconExpand className={iconExpandClasses} direction={showBreakdown ? 'up' : 'down'} />
        </button>
      ) : (
        <span className={css.title}>{titleText}</span>
      )}
      {showBreakdown && (
        <div className={contentClasses}>
          {map((lineItem) => {
            const props = getLineItemProps(intl, lineItem, isCustomer, pricingData, isReversal);

            return props && <LineItem key={lineItem.code} className={css.lineItem} code={lineItem.code} {...props} />;
          }, lineItems)}
          <LineItem className={css.lineItemTotal} code="total" icon={<IconReceipt />} label={totalLabelText} price={formattedTotal} />
          <span className={css.serviceFee}>{serviceFeeText}</span>
        </div>
      )}
      {isReversal && <span className={css.refundInfo}>{refundedText}</span>}
    </div>
  )
}

PriceBreakdown.defaultProps = {
  className: null,
  expandable: false,
};

PriceBreakdown.propTypes = {
  className: string,
  expandable: bool,
  intl: intlShape.isRequired,
  transaction: object.isRequired,
  userRole: oneOf([CUSTOMER, PROVIDER]).isRequired,
};

export default injectIntl(PriceBreakdown);