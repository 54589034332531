import React from 'react';
import { number, oneOfType, string } from 'prop-types';
import classNames from 'classnames';

import css from './Badge.module.css';

const Badge = (props) => {
  const {
    className,
    content,
  } = props;
  const classes = classNames(css.root, className, {
    [css.withContent]: !!content,
  });

  return (
    <div className={classes}>
      {content && <span className={css.content}>{content}</span>}
    </div>
  )
}

Badge.defaultProps = {
  className: null,
};

Badge.propTypes = {
  className: string,
  content: oneOfType([string, number]),
};

export default Badge;