export const getCountryValue = (language) => {
  switch (language) {
    case 'fr':
      return 'FR'
    case 'es':
      return 'ES'
    case 'it':
      return 'IT'
    case 'de':
      return 'DE'
    case 'en':
      return 'US'
    case 'fr-BE':
      return 'BE'
    
    default:
      return undefined;
  }
};