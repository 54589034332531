import React, { Fragment } from 'react';
import { bool, object, string } from 'prop-types';
import { intlShape } from 'react-intl';

import { IconArrowHead, NamedLink } from '../../components';

import AccountTabNav from './AccountTabNav/AccountTabNav';
import css from './AccountView.module.css';

const AccountView = props => {
  const {
    currentTab,
    tabs,
    title,
    withoutNav,
    intl,
  } = props;
  const accountPageLinkText = intl.formatMessage({ id: 'AccountView.accountPageLink' });

  return (
    <Fragment>
      <div className={css.header}>
        <div className={css.headerContent}>
          <div className={css.historyNavigation}>
            <NamedLink className={css.historyLink} name="AccountSettingsPage">{accountPageLinkText}</NamedLink>
            <IconArrowHead className={css.iconArrowHead} direction="right" />
            <span>{title}</span>
          </div>
          <h1 className={css.pageTitle}>{title}</h1>
        </div>
      </div>
      <div className={css.mainContent}>
        <div className={css.tabContainer}>
          {!withoutNav && (
            <div className={css.tabNavigation}>
              <AccountTabNav tabs={tabs} currentTab={currentTab} />
            </div>
          )}
          {tabs[currentTab].component}
        </div>
        <div className={css.tipContainer}>
          <span className={css.tipTitle}>{tabs[currentTab].tipTitle}</span>
          <p className={css.tip}>{tabs[currentTab].tip}</p>
        </div>
      </div>
    </Fragment>
  )
};

AccountView.defaultProps = {
  withoutNav: false,
};

AccountView.propTypes = {
  currentTab: string.isRequired,
  tabs: object.isRequired,
  title: string.isRequired,
  withoutNav: bool,
  intl: intlShape.isRequired,
};

export default AccountView;