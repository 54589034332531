import React, { Component } from 'react';
import { bool, func, instanceOf, number, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import { pathOr } from 'ramda';
import { getEstimatedBreakdownMaybe, getPricingDataFromEntity } from '@swimmy_/helpers';

import routeConfiguration from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';
import { withViewport } from '../../util/contextHelpers';
import {
  ensureBooking,
  ensureListing,
  ensureTransaction,
  ensureUser,
} from '../../util/data';
import { moment } from '../../util/dates';
import { storeData, storedData } from '../../util/checkoutSessionHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  BookingInfoPanel,
  Divider,
  DummyTopbar,
  HeadingWithBackButton,
  NamedRedirect,
  Page,
} from '../../components';

import { setInitialValues } from './CheckoutExtraPage.duck';
import ExtraPanel from './ExtraPanel/ExtraPanel';
import css from './CheckoutExtraPage.module.css';

const STORAGE_KEY = 'CheckoutExtraPage';
const MOBILE_BREAKPOINT = 1023

export class CheckoutExtraPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      selectedExtras: [],
    };

    this.loadInitialData = this.loadInitialData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (window) {
      this.loadInitialData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedExtras.length !== this.state.selectedExtras.length) {
      const { bookingStart, bookingEnd, quantity, quantityHours } = this.state.pageData.bookingData;
      const listingPublicData = pathOr({}, ['attributes', 'publicData'], this.state.pageData.listing);
      const currentTransaction = this.state.pageData.transaction;
      const pricingData = getPricingDataFromEntity(this.state.pageData.listing);
      const estimatedTransaction = getEstimatedBreakdownMaybe({
        ...pricingData,
        bookingStart: moment(bookingStart),
        bookingEnd: moment(bookingEnd),
        quantity1: quantity.adults,
        quantity2: quantity.children,
        quantityHours: quantityHours,
        publicData: listingPublicData,
        extras: this.state.selectedExtras,
        protectedData: {
          ...pricingData,
          quantity
        },
        canApplyCommissionDiscountOnBigBookings: true,
        isTransactionHourlyPlan: true,
      });
      const txIdMaybe = currentTransaction ? { id: currentTransaction.id } : {};

      this.setState({ pageData:  { ...this.state.pageData, transaction: { ...estimatedTransaction, ...txIdMaybe } } });
    }
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutExtraPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutExtraPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  loadInitialData() {
    const {
      bookingData,
      bookingDates,
      listing,
      transaction,
      history,
    } = this.props;

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

    const hasDataInProps = !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
    if (hasDataInProps) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(bookingData, bookingDates, listing, transaction, STORAGE_KEY);
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps
      ? { bookingData, bookingDates, listing, transaction }
      : storedData(STORAGE_KEY);

    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;

    const shouldFetchSpeculatedTransaction =
      pageData &&
      pageData.listing &&
      pageData.listing.id &&
      pageData.bookingData &&
      pageData.bookingDates &&
      pageData.bookingDates.bookingStart &&
      pageData.bookingDates.bookingEnd &&
      pageData.bookingData.quantity &&
      pageData.bookingData.quantityHours &&
      !isBookingCreated;

    if (shouldFetchSpeculatedTransaction) {
      const { bookingStart, bookingEnd, quantity, quantityHours } = pageData.bookingData;
      const listingPublicData = pathOr({}, ['attributes', 'publicData'], pageData.listing);
      const pricingData = getPricingDataFromEntity(pageData.listing);
      const estimatedTransaction = getEstimatedBreakdownMaybe({
        ...pricingData,
        bookingStart: moment(bookingStart),
        bookingEnd: moment(bookingEnd),
        quantity1: quantity.adults,
        quantity2: quantity.children,
        quantityHours: quantityHours,
        publicData: listingPublicData,
        protectedData: {
          ...pricingData,
          quantity
        },
        isTransactionHourlyPlan: true,
        canApplyCommissionDiscountOnBigBookings: true,
      });
      const txIdMaybe = tx ? { id: tx.id } : {};

      this.setState({ pageData: { ...pageData, transaction: { ...estimatedTransaction, ...txIdMaybe } } || {}, dataLoaded: true });
    } else {
      this.setState({ pageData: pageData || {}, dataLoaded: true });
    }
  }

  handleSubmit() {
    const {
      callSetInitialValues,
      currentUser,
      history,
      onInitializeCardPaymentData,
    } = this.props;
    const { bookingData, bookingDates, listing, transaction } = this.state.pageData;
    const initialValues = {
      listing,
      transaction : {
        ...transaction,
        booking: null,
      },
      bookingData: {
        ...bookingData,
        extras: this.state.selectedExtras,
      },
      bookingDates,
      confirmPaymentError: null,
    };
    const saveToSessionStorage = !currentUser;
    const routes = routeConfiguration();
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);
    onInitializeCardPaymentData();
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculatedTransaction: speculatedTransactionMaybe,
      intl,
      params,
      currentUser,
      viewport,
    } = this.props;

    const isLoading = !this.state.dataLoaded || speculateTransactionInProgress;
    const isMobile = viewport.width <= MOBILE_BREAKPOINT;

    const { listing, bookingDates, transaction } = this.state.pageData;
    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentListing = ensureListing(listing);
    const currentAuthor = ensureUser(currentListing.author);
    const listingPublicData = pathOr({}, ['attributes', 'publicData'], currentListing);
    const { paidExtrasData } = listingPublicData;
    const title = intl.formatMessage({ id: 'CheckoutExtraPage.heading' });
    const pageProps = { title, scrollingDisabled };

    if (isLoading) {
      return (
        <Page {...pageProps}>
          <DummyTopbar />
        </Page>
      );
    }

    const isOwnListing =
      currentUser &&
      currentUser.id &&
      currentAuthor &&
      currentAuthor.id &&
      currentAuthor.id.uuid === currentUser.id.uuid;

    const hasListingAndAuthor = !!(currentListing.id && currentAuthor.id);
    const hasBookingDates = !!(
      bookingDates &&
      bookingDates.bookingStart &&
      bookingDates.bookingEnd
    );
    const hasRequiredData = hasListingAndAuthor && hasBookingDates;
    const canShowPage = hasRequiredData && !isOwnListing;
    const shouldRedirect = !isLoading && !canShowPage;

    // Redirect back to ListingPage if data is missing.
    // Redirection must happen before any data format error is thrown (e.g. wrong currency)
    if (shouldRedirect) {
      // eslint-disable-next-line no-console
      console.error('Missing or invalid data for checkout, redirecting back to listing page.', {
        transaction: speculatedTransaction,
        bookingDates,
        listing,
      });
      return <NamedRedirect name="ListingPage" params={params} />;
    }

    // Show breakdown only when speculated transaction and booking are loaded
    // (i.e. have an id)
    const tx = existingTransaction.booking ? existingTransaction : speculatedTransaction;
    const txBooking = ensureBooking(tx.booking);

    return (
      <Page className={css.root} {...pageProps}>
        <DummyTopbar className={css.dummyTopbar} />
        <div className={css.mainContent}>
          <HeadingWithBackButton className={css.headingWithBackButton} heading={title} />
          <div className={css.panelContainer}>
            {tx.id && txBooking.id && (
              <BookingInfoPanel
                className={css.bookingInfoPanel}
                intl={intl}
                listing={currentListing}
                priceBreakdownExpandable={isMobile}
                transaction={tx}
              />
            )}
            <div className={css.mainPanelContainer}>
              <Divider className={css.divider} />
              <ExtraPanel
                className={css.extraPanel}
                intl={intl}
                exposeSelectedExtras={(value) => this.setState({ selectedExtras: value })}
                handleSubmit={this.handleSubmit}
                paidExtrasData={paidExtrasData}
              />
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

CheckoutExtraPageComponent.defaultProps = {
  listing: null,
  bookingData: {},
  bookingDates: null,
  transaction: null,
  currentUser: null,
};

CheckoutExtraPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    listing,
    bookingData,
    bookingDates,
    transaction,
  } = state.CheckoutExtraPage;
  const { currentUser } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    bookingData,
    bookingDates,
    transaction,
    listing,
  };
};

const mapDispatchToProps = dispatch => ({
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
});

const CheckoutExtraPage = compose(
  withRouter,
  withViewport,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(CheckoutExtraPageComponent);

CheckoutExtraPage.setInitialValues = (initialValues, saveToSessionStorage = false) => {
  if (saveToSessionStorage) {
    const { listing, bookingData, bookingDates } = initialValues;
    storeData(bookingData, bookingDates, listing, null, STORAGE_KEY);
  }

  return setInitialValues(initialValues);
};

CheckoutExtraPage.displayName = 'CheckoutExtraPage';

export default CheckoutExtraPage;
