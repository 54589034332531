import moment from 'moment';

import { TRANSITIONS_PAID_OUT } from '../../util/transactions';

const filterBasedOnTransitions = (transactions, transitionsToFilter) =>
  transactions.filter(transaction =>
    transaction.attributes.transitions.find(currentTransition =>
      transitionsToFilter.includes(currentTransition.transition)
    )
  );

export const filterBasedOnTransitionsByYear = (transactions, year, transitionsToFilter) => {
  const filteredTransactions = filterBasedOnTransitions(transactions, transitionsToFilter);
  return filteredTransactions.filter(transaction => {
    const filteredTransition = transaction.attributes.transitions.find(currentTransition => {
      return transitionsToFilter.includes(currentTransition.transition);
    });
    return moment(filteredTransition.createdAt).year() === year;
  });
};

export const getIncomeByYear = (transactions, year) => {
  const paidOutTransactions = filterBasedOnTransitionsByYear(transactions, year, TRANSITIONS_PAID_OUT);
  if (paidOutTransactions <= 0) return 0;
  const transactionsPayout = paidOutTransactions.map(transaction => transaction.attributes.payoutTotal.amount);
  return transactionsPayout.reduce((accumulator, currentValue) => accumulator + currentValue) / 100;
};
