import React from 'react';
import { object, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import { path, pathOr } from 'ramda';
import { getPrices } from '@swimmy_/helpers';

import { propTypes } from '../../../util/types';
import { formatMoney } from '../../../util/currency';

import css from './ListingPrice.module.css';

const ListingPrice = (props) => {
  const {
    className,
    intl,
    listing,
    searchParams,
  } = props;

  if (!path(['attributes', 'price'], listing)) return null;

  const { bookingDate, endTime, startTime } = pathOr({}, [], searchParams);
  const canShowTotalPrice = bookingDate && startTime && !!endTime;
  const speculativePrices = getPrices(listing.attributes, searchParams, true);
  const { mainPrice, secondaryPrice } = speculativePrices;
  const formattedMainPrice = formatMoney(intl, mainPrice, true);
  const formattedSecondaryPrice = formatMoney(intl, secondaryPrice, true);
  const unitText = intl.formatMessage({ id: 'ListingCard.ListingPrice.perHour' });
  const unitShortText = intl.formatMessage({ id: 'ListingCard.ListingPrice.perHourShort' });
  const totalText = intl.formatMessage(
    { id: 'ListingCard.ListingPrice.total' },
    { price: formattedSecondaryPrice }
  );
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <div className={css.pricePerHourWrapper}>
        <span className={css.price} title={formattedMainPrice}>{formattedMainPrice}</span>
        <span className={css.unitShort}>{unitShortText}</span>
        <span className={css.unit}>{unitText}</span>
      </div>
      {canShowTotalPrice && <span className={css.totalPrice}>{totalText}</span>}
    </div>
  )
}

ListingPrice.defaultProps = {
  className: null,
  searchParams: {},
};

ListingPrice.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  searchParams: object,
};

export default ListingPrice;