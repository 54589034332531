import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCalendar.css';

const IconCalendar = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0417 3.16663H3.95833C3.08388 3.16663 2.375 3.87551 2.375 4.74996V15.8333C2.375 16.7077 3.08388 17.4166 3.95833 17.4166H15.0417C15.9161 17.4166 16.625 16.7077 16.625 15.8333V4.74996C16.625 3.87551 15.9161 3.16663 15.0417 3.16663Z" strokeWidth="1.58333" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.6667 1.58337V4.75004" strokeWidth="1.58333" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.33334 1.58337V4.75004" strokeWidth="1.58333" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.375 7.91663H16.625" strokeWidth="1.58333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

IconCalendar.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCalendar.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCalendar;
