import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, FormattedHTMLMessage } from '../../util/reactIntl';

import config from '../../config';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';

import css from './EditListingDescriptionPanel.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    isNew,
  } = props;
  const handleSubmit = (values) => {
    const { title, description } = values;
    const updateValues = {
      title: title.trim(),
      description,
    };

    onSubmit(updateValues);
  };
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );
  const topAdviceText = <FormattedHTMLMessage id="EditListingPresentationPanel.topAdvice"/>;
  const firstAdviceText = <FormattedMessage id="EditListingPresentationPanel.firstAdvice" />;
  const secondAdviceText = <FormattedMessage id="EditListingPresentationPanel.secondAdvice" />;
  const thirdAdviceText = <FormattedMessage id="EditListingPresentationPanel.thirdAdvice" />;
  const fourthAdviceText = <FormattedMessage id="EditListingPresentationPanel.fourthAdvice" />;
  const supportPhoneNumber = config.custom.supportPhoneNumberReadable;
  const bottomAdviceText = <FormattedHTMLMessage id="EditListingPresentationPanel.bottomAdvice" values={{ phoneNumber: supportPhoneNumber }} />;

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {isNew && (
        <div className={css.cautionMessage}>
          {topAdviceText}
          <ul className={css.advicesList}>
            <li>{firstAdviceText}</li>
            <li>{secondAdviceText}</li>
            <li>{thirdAdviceText}</li>
            <li>{fourthAdviceText}</li>
          </ul>
          {bottomAdviceText}
        </div>
      )}
      <EditListingDescriptionForm
        className={css.form}
        initialValues={{ title, description }}
        saveActionMsg={submitButtonText}
        onSubmit={handleSubmit}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
