import React, { Fragment } from 'react';
import { arrayOf, bool } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { map, pathOr } from 'ramda';

import { getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { propTypes } from '../../util/types';
import { isNilOrEmpty } from '../../util/validators';
import { TopbarContainer } from '../../containers';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
  ListingCard,
} from '../../components';

import css from './FavoritesPage.module.css';

const { UUID } = sdkTypes;

const FavoritesPage = (props) => {
  const {
    listings,
    fetchFavoritesInProgress,
    fetchFavoritesError,
    fetchFavoritesSuccess,
    scrollingDisabled,
    intl,
  } = props;
  const listingsAreLoaded = !fetchFavoritesInProgress && fetchFavoritesSuccess;

  const queryError = (
    <h2 className={css.error}>
      <FormattedMessage id="ManageListingsPage.queryError"/>
    </h2>
  );
  const noResults =
    listingsAreLoaded && isNilOrEmpty(listings) ? (
      <div className={css.rootNoResults}>
        <div className={css.contentNoResults}>
          <h1 className={css.headingNoResults}>
            <FormattedMessage id="FavoritesPage.noResultsHeading" />
          </h1>
          <p className={css.descriptionNoResults}>
            <FormattedMessage id="FavoritesPage.noResultsDescription" />
          </p>
        </div>
      </div>
    ) : null;
  const title = intl.formatMessage({ id: 'FavoritesPage.title' });

  const panelWidth = 62.5;
  // Render hints for responsive image
  const renderSizes = [
    `(max-width: 767px) 100vw`,
    `(max-width: 1920px) ${panelWidth / 2}vw`,
    `${panelWidth / 3}vw`,
  ].join(', ');

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="FavoritesPage"/>
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.header}>
            <h1 className={css.heading}>{title}</h1>
          </div>
          <div className={css.content}>
            {fetchFavoritesInProgress && isNilOrEmpty(listings) && <IconSpinner />}
            {fetchFavoritesError && queryError}
            {!isNilOrEmpty(listings) && !fetchFavoritesError && (
              <Fragment>
                {noResults}
                <div className={css.listingCards}>
                  {listings.map(l => (
                    <ListingCard
                      className={css.listingCard}
                      key={l.id.uuid}
                      listing={l}
                      renderSizes={renderSizes}
                    />
                  ))}
                </div>
              </Fragment>
            )}
          </div>
        </LayoutWrapperMain>
      </LayoutSingleColumn>
    </Page>
  );
};


FavoritesPage.defaultProps = {
  listings: [],
  fetchFavoritesError: null,
};

FavoritesPage.propTypes = {
  fetchFavoritesError: propTypes.error,
  fetchFavoritesInProgress: bool.isRequired,
  fetchFavoritesSuccess: bool.isRequired,
  intl: intlShape.isRequired,
  listings: arrayOf(propTypes.listing),
  scrollingDisabled: bool.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    currentPageResultIds,
    fetchFavoritesInProgress,
    fetchFavoritesError,
    fetchFavoritesSuccess,
  } = state.FavoritesPage;
  const myFavorites = pathOr([], ['attributes', 'profile', 'protectedData', 'myFavorites'], currentUser);
  const listings = getListingsById(state, map((fav) => new UUID(fav), myFavorites));

  return {
    currentPageResultIds,
    listings,
    fetchFavoritesInProgress,
    fetchFavoritesError,
    fetchFavoritesSuccess,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    null
  ),
  injectIntl
)(FavoritesPage);
