import React, { useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import fr from 'react-phone-number-input/locale/fr';
import de from 'react-phone-number-input/locale/de';
import es from 'react-phone-number-input/locale/es';
import it from 'react-phone-number-input/locale/it';
import en from 'react-phone-number-input/locale/en';

import config from '../../config';
import { ValidationError } from '../../components';

import css from './FieldPhoneNumberInput.css';

const selectTranslation = {
  'fr': fr,
  'fr-BE': fr,
  'es': es,
  'de': de,
  'it': it,
  'en': en,
};
const defaultCountries = {
  'fr': 'FR',
  'fr-BE': 'BE',
  'es': 'ES',
  'de': 'DE',
  'it': 'IT',
  'en': 'US',
};

const FieldPhoneNumberInputComponent = props => {
  const {
    rootClassName,
    className,
    id,
    onUnmount,
    placeholder, 
    label,
    input,
    meta,
    customErrorText,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }
  const { valid, invalid, touched, error } = meta;
  const errorText = customErrorText || error;
  const hasError = !!customErrorText || !!(touched && invalid && error);
  const fieldMeta = { touched: hasError, error: errorText };
  const inputClasses =
    classNames(
      {
        [css.inputSuccess]: valid,
        [css.inputError]: hasError,
      },
    );
  const locale = config.locale;
  const inputProps = {className: inputClasses, id, ...input, ...rest};
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.labelContainer}>
        {label ? <label>{label}</label> : null}
      </div>
      <PhoneInput
        placeholder={placeholder}
        labels={selectTranslation[locale]}
        defaultCountry={defaultCountries[locale]}
        countrySelectProps={{ unicodeFlags: true }}
        value={input.value}
        onChange={value => input.onChange(value)}
        {...inputProps}
      />
      <ValidationError fieldMeta={fieldMeta} />
    </div>
  );
};

const FieldPhoneNumberInput = props => {
  useEffect(()=> {
    return () => {
      if (props.onUnmount) {
        props.onUnmount();
      }
    }
  }, []);

  return (<Field component={FieldPhoneNumberInputComponent} {...props} />);
};

export default FieldPhoneNumberInput;