import React from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { injectIntl, intlShape } from 'react-intl';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import config from '../../config';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '../../containers';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  NamedLink,
} from '../../components';

import css from './CongratsEnquiryPage.module.css';

const CongratsEnquiryPage = (props) => {
  const { intl, params, scrollingDisabled, transaction } = props;
  const getRedirectLink = () => {
    const { id } = params;
    const redirectLinkProps = id ? ({
      name: 'InboxPage',
      params: {
        id,
      },
    }) : ({
      name: 'LandingPage',

    });
    const hereText = intl.formatMessage({ id: 'Marketplace.here' });

    return (
      <NamedLink {...redirectLinkProps}>
        {hereText}
      </NamedLink>
    );
  };
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'CongratsEnquiryPage.schemaTitle' }, { siteTitle });
  const authorDisplayName = pathOr(intl.formatMessage({ id: 'CongratsEnquiryPage.Provider' }), ['provider', 'attributes', 'profile', 'displayName'], transaction);
  const content = intl.formatMessage({ id: 'CongratsEnquiryPage.Content' }, { provider: authorDisplayName } );
  const details = intl.formatMessage({ id: 'CongratsEnquiryPage.Details' });

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="CongratsEnquiryPage"/>
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.root}>
            <h1 className={css.mainText}>{content}</h1>
            <span className={css.secondaryText}>
              {details}
              {getRedirectLink()}.
            </span>
          </div>
        </LayoutWrapperMain>
      </LayoutSingleColumn>
    </Page>
  );
};

CongratsEnquiryPage.propTypes = {
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { transactionRef } = state.transaction;
  const { currentUser } = state.user;

  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    transaction,
  };
};

export default compose(
  connect(
    mapStateToProps
  ),
  injectIntl
)(CongratsEnquiryPage);;
