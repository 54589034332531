import React from 'react';
import { path } from 'ramda';
import { func, shape, string } from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer, Loader,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';

import { loadData } from './BlogPage.duck';
import css from './BlogPage.css';
import BlogContent from '../../components/BlogContent';

const BlogPage = (props) => {
  const {
    intl,
    scrollingDisabled,
    error,
    title,
    metaDescription,
    description,
    cover = {},
    isLoading,
    slug,
  } = props;
  if (!isLoading && error && error.status === 404) return <NotFoundPage/>;

  const loadingTitle = intl.formatMessage({ id: 'BlogPage.loadingPageTitle' });
  const errorTitle = intl.formatMessage({ id: 'BlogPage.errorLoadingPageTitle' });
  const errorLoadingText = intl.formatMessage({ id: 'BlogPage.errorLoadingPageMessage' });
  const pageTitles = {
    'amateursdebaignade': intl.formatMessage({ id: 'BlogPage.swimmingEnthusiastsTitle' }),
    'entretien-piscine': intl.formatMessage({ id: 'BlogPage.cleaningPoolTitle' }),
    'locations': intl.formatMessage({ id: 'BlogPage.locationsTitle' }),
  };
  const pageTitle = pageTitles[slug] || (title  + ' | Swimmy');
  const schemaTitle = isLoading ? loadingTitle : error ? errorTitle : pageTitle;
  const coverFullUrl = path(['url'], cover);
  const socialNetworkImage = {
    url: coverFullUrl || '',
    width: 400,
    height: 200,
  };

  const pageProps = {
    contentType: "website",
    description: metaDescription || description,
    facebookImages: [socialNetworkImage || {}],
    twitterImages: [socialNetworkImage || {}],
    schema: {
      '@context': 'http://schema.org',
      '@type': 'BlogPage',
      description: 'descr1',
      title: schemaTitle,
      image: [coverFullUrl],
    },
  };
  const isLoaded = !error && !isLoading;

  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled} {...isLoaded && pageProps}>
      <LayoutSingleColumn className={css.pageRoot}>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="BlogPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.mainTitleContainer}>
            {isLoading && <Loader light />}
            {isLoaded && <h1 className={css.mainTitle}>{title}</h1>}
          </div>
          <div className={css.contentContainer}>
            {error && <p className={css.errorText}>{errorLoadingText}</p>}
            {isLoaded && <BlogContent {...props} />}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer/>
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  )
};

BlogPage.defaultProps = {};

BlogPage.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    error,
    isLoading,
    data,
  } = state.BlogPage || {};
  const {
    title,
    description,
    metaDescription,
    content,
    created_at,
    updated_at,
    cover,
    summary,
  } = data || {};

  return {
    scrollingDisabled: isScrollingDisabled(state),
    error,
    isLoading,
    title,
    metaDescription,
    description,
    content,
    created_at,
    updated_at,
    cover,
    summary,
  };
};

BlogPage.loadData = slug => loadData(slug);

export default compose(withRouter, connect(mapStateToProps, null), injectIntl)(BlogPage);
