import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconMinus.css';

const IconMinus = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="14" height="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M492 236H20c-11 0-20 9-20 20 0 11 9 20 20 20h472c11 0 20-9 20-20S503 236 492 236z"/>
    </svg>
  );
};

IconMinus.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconMinus.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconMinus;
