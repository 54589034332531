import React from 'react';
import { string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { propTypes } from '../../../../../../util/types';
import { getUserTxRole, txRoleIsProvider } from '../../../../../../util/transactions';

import css from './DetailsOtherUser.module.css';
import { UserDisplayName, VerifiedBadges } from '../../../../../../components';

const DetailsOtherUser = (props) => {
  const { className, currentUser, intl, transaction } = props;
  const { customer } = transaction;
  const transactionRole = getUserTxRole(currentUser.id, transaction);
  const isProvider = txRoleIsProvider(transactionRole);
  const title = intl.formatMessage({ id: 'InboxPage.DetailsSection.DetailsOtherUser.title' });
  const classes = classNames(css.root, className);

  return isProvider ? (
    <div className={classes}>
      <span className={css.title}>{title}</span>
      <UserDisplayName className={css.text} user={customer} intl={intl} />
      <VerifiedBadges
        className={css.verifiedUserContainer}
        user={customer}
        withEmail
        withTooltip
      />
    </div>
  ) : null;
}

DetailsOtherUser.defaultProps = {
  className: null,
};

DetailsOtherUser.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  transaction: propTypes.transaction.isRequired,
};

export default injectIntl(DetailsOtherUser);