import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { path, pathOr } from 'ramda';

import { propTypes } from '../../../../../../util/types';
import { getUserTxRole, txRoleIsCustomer } from '../../../../../../util/transactions';
import { createSlug } from '../../../../../../util/urlHelpers';
import { NamedLink, ResponsiveImage } from '../../../../../../components';

import css from './DetailsListing.module.css';

const DetailsListing = props => {
  const {
    className,
    currentUser,
    transaction,
  } = props;
  const listing = transaction.listing;
  const listingId = path(['id', 'uuid'], listing);
  const listingTitle = pathOr('', ['attributes', 'title'], listing);
  const image = pathOr(null, ['images', 0], listing);
  const transactionRole = getUserTxRole(currentUser.id, transaction);
  const isCustomer = txRoleIsCustomer(transactionRole);
  const slug = createSlug(listingTitle);
  const classes = classNames(css.root, className);

  return listingTitle ? (
    <div className={classes}>
      <NamedLink
        className={css.link}
        name='ListingPage'
        params={{ id: listingId, slug }}
      >
        {isCustomer && (
          <ResponsiveImage
            className={css.image}
            alt={listingTitle}
            image={image}
            variants={['landscape-crop']}
          />
        )}
        <span className={css.text}>{listingTitle}</span>
      </NamedLink>
    </div>
  ) : null;
}

DetailsListing.defaultProps = {
  className: null,
};

DetailsListing.propTypes = {
  className: string,
  currentUser: propTypes.currentUser.isRequired,
  transaction: propTypes.transaction.isRequired,
};

export default DetailsListing;
