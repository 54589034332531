export const entriesAvailabilityPlanTimeBased =[
  {
    dayOfWeek: "mon",
    seats: 1,
    startTime: "09:00",
    endTime: "00:00",
  },
  {
    dayOfWeek: "tue",
    seats: 1,
    startTime: "09:00",
    endTime: "00:00",
  },
  {
    dayOfWeek: "wed",
    seats: 1,
    startTime: "09:00",
    endTime: "00:00",
  },
  {
    dayOfWeek: "thu",
    seats: 1,
    startTime: "09:00",
    endTime: "00:00",
  },
  {
    dayOfWeek: "fri",
    seats: 1,
    startTime: "09:00",
    endTime: "00:00",
  },
  {
    dayOfWeek: "sat",
    seats: 1,
    startTime: "09:00",
    endTime: "00:00",
  },
  {
    dayOfWeek: "sun",
    seats: 1,
    startTime: "09:00",
    endTime: "00:00",
  },
];
