import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { useLocation } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { parse } from '../../../../../../util/urlHelpers';
import {
  IconFilter,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
} from '../../../../../../components';

import css from './TransactionFilter.module.css';

const TransactionFilter = props => {
  const { className, intl } = props;
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const onToggleActive = isOpen => {
    setIsOpen(isOpen);
  };
  const { filter } = parse(location.search);
  useEffect(() => {
    onToggleActive(false);
  }, [filter]);
  const allText = intl.formatMessage({ id: 'InboxPage.TransactionsSection.TransactionFilter.all' });
  const bookingText = intl.formatMessage({ id: 'InboxPage.TransactionsSection.TransactionFilter.booking' });
  const informationText = intl.formatMessage({ id: 'InboxPage.TransactionsSection.TransactionFilter.information' });
  const filterClasses = classNames(css.menuLabel, {
    [css.filterActive]: filter && filter !== 'all',
  });
  const allLinkClasses = classNames(css.link, {
    [css.disabledLink]: filter === 'all',
  });
  const bookingLinkClasses = classNames(css.link, {
    [css.disabledLink]: filter === 'booking',
  });
  const informationLinkClasses = classNames(css.link, {
    [css.disabledLink]: filter === 'information',
  });

  return (
    <Menu
      className={className}
      contentPosition="left"
      contentPlacementOffset={-24}
      isOpen={isOpen}
      onToggleActive={onToggleActive}
    >
      <MenuLabel className={filterClasses}>
        <IconFilter className={css.icon} />
      </MenuLabel>
      <MenuContent className={css.menuContent} withoutArrow>
        <MenuItem key="all" className={css.menuItem}>
          <NamedLink
            className={allLinkClasses}
            name="InboxPage"
            to={{ search: "?filter=all" }}
          >
            {allText}
          </NamedLink>
        </MenuItem>
        <MenuItem key="booking" className={css.menuItem}>
          <NamedLink
            className={bookingLinkClasses}
            name="InboxPage"
            to={{ search: "?filter=booking" }}
          >
            {bookingText}
          </NamedLink>
        </MenuItem>
        <MenuItem key="information" className={css.menuItem}>
          <NamedLink
            className={informationLinkClasses}
            name="InboxPage"
            to={{ search: "?filter=information" }}
          >
            {informationText}
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  )
};

TransactionFilter.defaultProps = {
  className: null,
};

TransactionFilter.propTypes = {
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(TransactionFilter);
