import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import defaultListingImg from '../../../assets/default-img-listing/default-img-listing.jpg';
import defaultListingImgCrop from '../../../assets/default-img-listing/landscape-crop.webp';
import defaultListingImgCrop2 from '../../../assets/default-img-listing/landscape-cropx2.webp';
import defaultListingImgCrop4 from '../../../assets/default-img-listing/landscape-cropx4.webp';
import defaultListingImgCrop6 from '../../../assets/default-img-listing/landscape-cropx6.webp';
import { ResponsiveImage, Modal, ImageCarousel } from '../../../components';

import ActionBarMaybe from './ActionBarMaybe/ActionBarMaybe';
import css from './SectionImages.module.css';

const SectionImages = props => {
  const {
    className,
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const defaultListingImgVariants = `${defaultListingImgCrop} 400w, ${defaultListingImgCrop2} 800w, ${defaultListingImgCrop4} 1600w, ${defaultListingImgCrop6} 2400w`;
  const classes = classNames(css.root, className);
  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  return (
    <div className={classes}>
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper} onClick={firstImage !== null ? handleViewPhotosClick : null}>
          {actionBar}
          {firstImage === null && !isOwnListing
            ? <img src={defaultListingImg} alt={title} className={css.rootForImage} srcSet={defaultListingImgVariants}/>
            : <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={[
                'landscape-crop',
                'landscape-crop2x',
                'landscape-crop4x',
                'landscape-crop6x',
              ]}
            />}
          {viewPhotosButton}
        </div>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={listing.images} />
      </Modal>
    </div>
  );
};

SectionImages.defaultProps = {
  className: null,
}

SectionImages.propTypes = {
  className: string,
}

export default SectionImages;
