import React, { Fragment } from 'react';
import { bool, func, object, string } from 'prop-types';
import { intlShape } from 'react-intl';
import classNames from 'classnames';

import { IconArrowNavigation } from '../../Icons';
import {
  ModalSwimmy,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from '../../ModalSwimmy';

import HeaderDesktop from '../HeaderDesktop/HeaderDesktop';
import HoursDiscountForm from './HoursDiscountForm/HoursDiscountForm';
import css from './HoursDiscountView.module.css';

const HoursDiscountView = (props) => {
  const {
    className,
    intl,
    isMobile,
    isOpen,
    listing,
    onCloseModal,
    onManageDisableScrolling,
    onSubmit,
    updated,
    updateInProgress,
  } = props;
  const headingText = intl.formatMessage({ id: 'EditListingPricingPanel.HoursDiscountView.title' });
  const informationText = intl.formatMessage({ id: 'EditListingPricingPanel.HoursDiscountView.information' });
  const classes = classNames(css.root, className);
  const content = (
    <Fragment>
      <div className={css.information}>
        <span>{informationText}</span>
      </div>
      <HoursDiscountForm
        className={css.hoursDiscountForm}
        onSubmit={onSubmit}
        intl={intl}
        isMobile={isMobile}
        listing={listing}
        updated={updated}
        updateInProgress={updateInProgress}
      />
    </Fragment>
  )

  return isMobile ? (
    <ModalSwimmy
      id="weekendPricing"
      animationType="slideRight"
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <ModalOverlay />
      <ModalContent
        className={css.modalContent} 
        animationType="slideRight"
        show={isOpen}
      >
        <ModalHeader className={css.modalHeader}>
          <button className={css.backButton} onClick={onCloseModal}>
            <IconArrowNavigation direction="left" />
          </button>
          <span className={css.heading}>{headingText}</span>
          <div className={css.spacer} />
        </ModalHeader>
        <ModalBody className={css.modalBody}>
          {content}
        </ModalBody>
      </ModalContent>
    </ModalSwimmy>
  ) : isOpen ? (
    <Fragment>
      <HeaderDesktop className={css.headerDesktop} heading={headingText} intl={intl} />
      <div className={css.contentDesktop}>
        {content}
      </div>
    </Fragment>
  ) : null;
}

HoursDiscountView.defaultProps = {
  className: null,
  listing: null,
};

HoursDiscountView.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  isMobile: bool.isRequired,
  isOpen: bool.isRequired,
  listing: object,
  onCloseModal: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSubmit: func.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
};

export default HoursDiscountView;