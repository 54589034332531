import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { path } from 'ramda';

import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ensureListing, ensureUser } from '../../util/data';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  Divider,
  DummyTopbar,
  HeadingWithBackButton,
  IconSpinner,
  Page,
} from '../../components';

import EnquiryPanel from './EnquiryPanel/EnquiryPanel';
import InformationPanel from './InformationPanel/InformationPanel';
import css from './ContactPage.module.css';

const { UUID } = sdkTypes;

const ContactPage = (props) => {
  const {
    fetchReviewsError,
    getListing,
    intl,
    params,
    reviews,
    scrollingDisabled,
    showListingError,
  } = props;
  const listingId = new UUID(params.id);
  const currentListing = ensureListing(getListing(listingId));
  const isLoading = !currentListing.id;
  const currentAuthor = ensureUser(currentListing.author);
  const currentAuthorDisplayName = path(['attributes', 'profile', 'displayName'], currentAuthor);
  const canShowPage = !showListingError;
  const loadingTitleText = intl.formatMessage({ id: 'Marketplace.loading' });
  const headingText = intl.formatMessage({ id: `ContactPage.${canShowPage ? 'heading' : 'headingError'}` }, { name: currentAuthorDisplayName });
  const pageProps = { title: isLoading ? loadingTitleText : headingText, scrollingDisabled };
  const loadingErrorText = intl.formatMessage({ id: 'ContactPage.loadingError' });

  if (isLoading) {
    return (
      <Page {...pageProps}>
        <DummyTopbar />
        <IconSpinner className={css.iconSpinner} />
      </Page>
    );
  }

  return (
    <Page className={css.root} {...pageProps}>
      <DummyTopbar className={css.dummyTopbar} />
      <div className={css.mainContent}>
        <HeadingWithBackButton className={css.headingWithBackButton} heading={headingText} />
        {canShowPage ? (
          <div className={css.panelContainer}>
            <InformationPanel
              className={css.informationPanel}
              intl={intl}
              listing={currentListing}
            />
            <div className={css.mainPanelContainer}>
              <Divider className={css.divider} />
              <EnquiryPanel
                className={css.enquiryPanel}
                intl={intl}
                listing={currentListing}
              />
            </div>
          </div>
        ) : (
          <span className={css.error}>{loadingErrorText}</span>
        )}
      </div>
    </Page>
  );
}

ContactPage.defaultProps = {
  currentUser: null,
};

ContactPage.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const {
    fetchReviewsError,
    reviews,
    showListingError,
  } = state.listing;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    getListing,
    fetchReviewsError,
    reviews,
    scrollingDisabled: isScrollingDisabled(state),
    showListingError,
  };
};

export default compose(
  injectIntl,
  connect(
    mapStateToProps
  ),
)(ContactPage);
