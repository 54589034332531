import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import config from '../../config';
import {
  Button,
  ExpandableBox,
  IconAdd,
  IconArrow,
  NamedLink,
} from '../../components';
import buoyImg from '../../assets/pictures/buoy2.png';
import finsImg from '../../assets/pictures/fins.png';

import css from './LandingPage.css';

const SectionCTA = props => {
  const { isAuthenticated, intl } = props;

  const handleShowSearch = () => {
    if (typeof window === 'undefined') return;
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  const titleHighlight = (
    <span className={css.titleHightlight}>
      <br/>{intl.formatMessage({ id: 'LandingPage.ctaTitleHighlight' })}
    </span>)
  const title = (<FormattedMessage id='LandingPage.ctaTitle' values={{ highlight: titleHighlight }} />);
  const providerTitle = intl.formatMessage({ id: 'LandingPage.ctaProviderTitle' });
  const customerTitle = intl.formatMessage({ id: 'LandingPage.ctaCustomerTitle' });
  const providerContentTitle = intl.formatMessage({ id: 'LandingPage.ctaProviderContentTitle' });
  const customerContentTitle = intl.formatMessage({ id: 'LandingPage.ctaCustomerContentTitle' });
  const providerFirstParagraph = intl.formatMessage({ id: 'LandingPage.ctaProviderFirstParagraph' });
  const customerFirstParagraph = intl.formatMessage({ id: 'LandingPage.ctaCustomerFirstParagraph' });
  const providerSecondParagraph = intl.formatMessage({ id: 'LandingPage.ctaProviderSecondParagraph' });
  const customerSecondParagraph = intl.formatMessage({ id: 'LandingPage.ctaCustomerSecondParagraph' });
  const providerCtaText = intl.formatMessage({ id: 'LandingPage.ctaProviderCTA' });
  const customerCtaText = intl.formatMessage({ id: 'LandingPage.ctaCustomerCTA' });

  return (
    <div className={css.sectionCta}>
      <span className={css.ctaTitle}>{title}</span>
      <div className={css.ctaContent}>
        <ExpandableBox className={css.ctaExpandableBox}>
          <div className={css.ctaExpandableTitleContainer}>
            <img className={css.ctaExpandableImgFins} src={finsImg} alt='fins' />
            <span className={css.ctaExpandableTitle}>{providerTitle}</span>
          </div>
          <div className={css.ctaExpandableContentContainer}>
            <span className={css.ctaExpandableContentTitle}>{providerContentTitle}</span>
            <span className={css.ctaExpandableParagraph}>{providerFirstParagraph}</span>
            {config.custom.hasInsurance ? (
              <span className={css.ctaExpandableParagraph}>{providerSecondParagraph}</span>
            ) : null}
            <NamedLink
              className={css.ctaCTA}
              name='SimulatorPage'
            >
              <span className={css.buttonText}>{providerCtaText}</span>
              <IconAdd className={css.buttonIcon} />
            </NamedLink>
          </div>
        </ExpandableBox>
        <ExpandableBox className={css.ctaExpandableBox}>
          <div className={css.ctaExpandableTitleContainer}>
            <img className={css.ctaExpandableImg} src={buoyImg} alt='buoy' />
            <span className={css.ctaExpandableTitle}>{customerTitle}</span>
          </div>          
          <div className={css.ctaExpandableContentContainer}>
            <span className={css.ctaExpandableContentTitle}>{customerContentTitle}</span>
            <span className={css.ctaExpandableParagraph}>{customerFirstParagraph}</span>
            <span className={css.ctaExpandableParagraph}>{customerSecondParagraph}</span>
            
            {isAuthenticated ? (
              <Button
                rootClassName={css.ctaCTA}
                onClick={handleShowSearch}
              >
                <span className={css.buttonText}>{customerCtaText}</span>
                <IconArrow className={css.buttonIcon} direction='right' />
              </Button>
            ): (
              <NamedLink name="SignupPage" className={css.ctaCTA}>
                <span className={css.buttonText}>{customerCtaText}</span>
                <IconArrow className={css.buttonIcon} direction='right' />
              </NamedLink>
            )}
          </div>
        </ExpandableBox>
      </div>
    </div>
  )
}

export default injectIntl(SectionCTA);