import React from 'react';
import { bool } from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import css from './FadeAnimation.module.css';

const FadeAnimation = (props) => {
  const {
    inProps,
    mountOnEnter,
    unmountOnExit,
    children,
  } = props;

  return (
    <CSSTransition
      in={inProps}
      timeout={200}
      classNames={{
        enter: css.enter,
        enterActive: css.enterActive,
        enterDone: css.enterDone,
        exit: css.exit,
        exitActive: css.exitActive,
        exitDone: css.exitDone,
      }}
      unmountOnExit={unmountOnExit}
      mountOnEnter={mountOnEnter}
    >
      {children}
    </CSSTransition>
  )
};

FadeAnimation.defaultProps = {
  mountOnEnter: false,
  unmountOnExit: false,
}

FadeAnimation.propTypes = {
  inProps: bool.isRequired,
  mountOnEnter: bool,
  unmountOnExit: bool,
}

export default FadeAnimation;
