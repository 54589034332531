import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { isNilOrEmpty } from '../../util/validators';
import { Loader, ReviewCard, SwimmySlider } from '../../components';

import css from './LandingPage.css';

const SectionReviews = props => {
  const { fetchReviewsInProgress, maxItemsToDisplay, onFetchReviews, reviews, intl } = props;

  useEffect(() => {
    if (reviews.length === 0 && !fetchReviewsInProgress) {
      onFetchReviews();
    }
  }, []);

  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const reviewsToDisplay = !isNilOrEmpty(maxItemsToDisplay) ? reviews.slice(0, maxItemsToDisplay) : reviews;
  const isRenderingLoader = fetchReviewsInProgress || isNilOrEmpty(reviewsToDisplay);

  const titleHighlight = (
    <span className={css.titleHightlight}>
      <br/>{intl.formatMessage({ id: 'LandingPage.reviewsTitleHighlight' })}
    </span>)
  const title = (<FormattedMessage id='LandingPage.reviewsTitle' values={{ highlight: titleHighlight }} />);

  return (
    <div className={css.sectionReviews}>
      <span className={css.sectionTitle}>{title}</span>
      <div className={css.reviewsSlider}>
        {isRenderingLoader ? (
          <Loader/>
          ) : (
          <SwimmySlider settings={sliderSettings}>
            {reviewsToDisplay.map((review, i) => (
              <ReviewCard
                key={i}
                review={review}
              />
            ))}
          </SwimmySlider>
        )}
      </div>
    </div>
  )
}

export default injectIntl(SectionReviews);